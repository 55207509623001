import React from 'react';
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';
import { Grid, Box, Typography, makeStyles } from "@material-ui/core"
import { appTheme, itemDetailsStyles } from 'theme';
import useTranslateHook from "i18n/useTranslateHook";

const useStyles = makeStyles(itemDetailsStyles)

let options = {
    headerStyle: {
        backgroundColor: appTheme?.palette.menu.base,
        color: appTheme?.palette.white,
        fontSize: "14px",
        whiteSpace: 'nowrap',
        textTransform: "uppercase",
    },
    loadingType: "linear",
    draggable: false,
    sorting: false,
    search: false,
    paging: false,
    pageSize: 5,
    showTitle: false,
    showFirstLastPageButtons: true,
    pagination: false,
    toolbar: false,
    maxBodyHeight: "30vh"
}

export const DetailsTable = ({ data, columns }) => {
    const classes = useStyles();
    const { t } = useTranslation();


    return (
        <Box
            data-testid={"details-table"}
            justifyContent="center"
            className={classes.tablebody}
        >
            <Typography
                variant="h3"
            >
                {t('product_details')}
            </Typography>
            <Grid className={classes.table}>
                <MaterialTable
                    localization={{
                        body: {
                            emptyDataSourceMessage: t("no_records_to_display")
                        }
                    }}
                    columns={useTranslateHook(columns.columnOrder)}
                    options={options}
                    style={{ width: 1200 }}
                    data={data}
                />
            </Grid>
        </Box>
    )
}

export default DetailsTable;
