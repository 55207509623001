import { emeaTableConstants } from "reduxLib/constants/EMEA/emeaTableConstants";
import get from "lodash/get";

const emeaTableReducer = (state = {}, action) => {
    const { variables } = action;
    const { type, tableName } = get(variables, "body", {});
    switch (action.type) {
        // We are storing the table data in corrosponding order Type/TableName data and loaders to retrieve later

        case emeaTableConstants.EMEA_TABLE_SUCCESS:
        case emeaTableConstants.EMEA_TABLE_FAILED:
            return {
                ...state,
                [type]: {
                    ...(get(state, [type], {})),
                    [tableName]: {
                        ...(get(state, [type, tableName], {})),
                        data: action.payload,
                        payload: variables,
                        status: action.status,
                        loading: false
                    }
                }
            };

        case emeaTableConstants.EMEA_TABLE_LOADING:
            return {
                ...state,
                [type]: {
                    ...(get(state, [type], {})),
                    [tableName]: {
                        ...(get(state, [type, tableName], {})),
                        loading: action.payload,
                    }
                }
            };

        default:
            return state
    }
}

export default emeaTableReducer;
