import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {
  makeStyles, Typography, Grid, Card,
  CardHeader, CardActions, CardContent, IconButton, Button, useTheme, Radio,
  RadioGroup, FormControlLabel
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import clsx from "clsx";
import { getGuardrailsService as getGuardrailsServiceAction } from "../../reduxLib/services/getChartsService";
import { get, isEmpty } from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
// import Card from "./Card"
import ErrorBox from "./ErrorBox";
import { chartElementStyles } from "../../theme"
import SkeletonLoader from "./SkeletonLoader";
import Legend from "./Legend";
import { useAppContext } from "context/AppContext";

const useStyles = makeStyles(chartElementStyles)

export const GuardrailChartComponent = ({ sitesKey, showLegends,
  showViewDetails, innerCard, divider,
  BuilderComponent, params, name, type, dummy,
  getGuardrailsService, charttype = "vertical",
  showGuardrailsUpdateDialog,
  ...rest }) => {
  const classes = useStyles();
  const { height, chartConfig } = rest;
  const margins = { ...rest.margins, top: 20, left: 50 };
  const horizontalMargins = { ...rest.margins, left: 100 }

  const { globalFilterSelection, refresh } = useAppContext();

  const businessUnits = useSelector(({ favorites }) => favorites?.tempBusinessUnit || []);
  const guardrailLoading = useSelector(({ charts: { loaders } }) => loaders?.guardrails?.loading);
  const guardrailData = useSelector(({ charts: { charts } }) => charts?.guardrails || {});


  const theme = useTheme();
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [unit, setUnit] = useState(guardrailData?.unit);
  const [viewDetails, setviewDetails] = useState(false);
  const [chartType, setChartType] = React.useState("outbound");
  const [chartData, setChartData] = React.useState(() => guardrailData?.weekdays)
  const body = {}
  const { t } = useTranslation();

  if (!isEmpty(globalFilterSelection?.siteNums)) {
    body[sitesKey ? sitesKey : "originSites"] = globalFilterSelection.siteNums
  }

  if (!isEmpty(globalFilterSelection?.customerOrSalesOffice)) {
    body["customerOrSalesOffice"] = globalFilterSelection.customerOrSalesOffice
  }

  const getCharts = () => {
    getGuardrailsService({
      body: {
        siteNum: type
      }
    }, chartType);
  }

  const setHeaderUnit = () => {
    setUnit(unit)
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth - 100,
          height: targetRef.current.offsetHeight - 100
        });
      }
    }
  }, []);

  useEffect(() => {
    if (!rest.blockApi) { getCharts() }
  }, [refresh, businessUnits, localStorage.getItem('i18nextLng'), chartType]);

  useEffect(() => {
    setUnit(guardrailData?.unit);
    setChartData(guardrailData?.weekdays);
  }, [guardrailData]);


  const ActionItems = () => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
        spacing={2}
        style={{ padding: theme.spacing(1) }}
      >
        <Grid item >
          {
            chartType === "outbound" && 
            <Typography
              data-testid="viewdetails"
              className={classes.link}
              variant="subtitle1"
              color="primary"
              onClick={() =>
                setviewDetails(!viewDetails)
              }>
                {viewDetails ? t("view_less") : t("view_more")}
            </Typography>
          }
        </Grid>

        <Grid item >
          {!isEmpty(guardrailData) && <Typography data-testid={'timeZone'} variant='subtitle1' className={classes.label} >
            {t("time_zone")}: {guardrailData.siteTimezone}
          </Typography>}
        </Grid>
      </Grid>
    )
  }

  const Title = () => {
    const subtitle = {
      HOURS: t('in_hours'),
      LOADS: `${t("in_truck_loads")} (1 TL = 3,150 CU. FT.)`
    }
    return (
      <CardHeader
        data-testid='guardrailTitle'
        action={<>
          <Grid container 
          alignItems="center"
          justifyContent="center"
          spacing={2}
          >
            <Grid item>
            <RadioGroup
              row
              name="charttype"
              defaultValue={chartType}
              onChange={({target:{ value }}) => {
                if (value === "inbound") {
                  setviewDetails(false)
                }
                setChartType(value);
              }}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                data-testid="inbound"
                label="Inbound"
                value={"inbound"}
                labelPlacement="start"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                data-testid="outbound"
                label="Outbound"
                value={"outbound"}
                labelPlacement="start"
              />
            </RadioGroup>
          </Grid>
          <Grid item>
          <Button
            size="small"
            data-testid='button_fetch'
            className={classes.notransform}
            onClick={showGuardrailsUpdateDialog}
            color='primary' variant='outlined'>{t("edit_update_guardrails")}</Button>
            </Grid>
            <Grid>
              <IconButton>
                <RefreshIcon data-testid='refresh_icon' className={guardrailLoading ? classes.spining : classes.spin} onClick={() => getCharts()} />
              </IconButton>
            </Grid>
          </Grid>
        </>}
        title={<Typography data-testid='title' variant="h3">{name}</Typography>}
        subheader={<Typography data-testid="unit" variant='subtitle1' className={classes.label} >{subtitle[unit] || "-"}</Typography>}
      />
    )
  }

  return (
    <Grid ref={targetRef} className={clsx(classes.root, classes.minHeight)}>
      <Card
        className={classes.card}
      >
        <Title />
        <CardContent>
          {
            guardrailLoading ? <SkeletonLoader dimensions={dimensions} /> :
              !guardrailData ? <ErrorBox message={t("unable_to_load")} dimensions={dimensions} /> :
                <BuilderComponent {...{
                  charttype,
                  margins,
                  horizontalMargins,
                  height,
                  type,
                  guardrailData: chartData,
                  dimensions,
                  setHeaderUnit,
                  viewDetails,
                  chartConfig,
                  isNotALink: chartType === "inbound",
                  hideRefLine: chartType === "inbound"
                }} />
          }
        </CardContent>
        <CardActions disableSpacing className={classes.pbZero}>
          <ActionItems />
        </CardActions>
        <CardActions className={classes.ptZero}>
          {showLegends && <Legend 
            isGuardrail={rest.guardrails} 
            isInbound={chartType === "inbound"} 
          />}
        </CardActions>
      </Card>
    </Grid>
  );
}

const mapDispatchToProps = {
  getGuardrailsService: getGuardrailsServiceAction
}

export default connect(null, mapDispatchToProps)(GuardrailChartComponent);