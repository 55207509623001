import React from 'react';
import moment from "moment-timezone";
import { shortTitleCellStyle, longTitleCellStyle } from 'helpers/tableStyleOverride';
import { formatToSentenceCase } from "helpers";
import { get, isEmpty } from "lodash";
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";
import CustomCellRender from "../../components/common/CustomCellRender";
import CustomRender from "../../components/common/CustomRender";
import FourKitesLink from 'components/common/Elements/FourKitesLink';

export const defaultTransportColumns = {
	columnOrder: [
		{
			title: 'load_#', field: "shipmentNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
			}
		},
		{
			title: 'delivery_#', field: "deliveryNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
			}
		},
		{
			title: 'order_#', field: "orderNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <PromotionalOrder data={rowData} />
			}
		},
		{
			title: 'promotionalOrder', field: "promotionalOrder", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="promotionalOrder" value={rowData.promotionalOrder} />
			}
		},
		{
			title: 'collectiveNumber', field: "collectiveNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
			}
		},
		{
			title: 'carrier', field: "tariffServiceCode",
			render: rowData => {
				return <CustomCellRender id="tariffServiceCode" value={rowData.tariffServiceCode} />
			}
		},
		{
			title: 'shipment_status', field: "orderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="orderExecutionBucketDesc" value={rowData.orderExecutionBucketDesc} />
			}
		},
		{
			title: 'fourkites_status', field: "fourkitesShipmentStatus", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="fourkitesShipmentStatus" value={rowData.fourkitesShipmentStatus} />
			}
		},
		{
			title: 'late_reason_code', field: "lateReasonCode", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="lateReasonCode" value={rowData.lateReasonCode} />
			}
		},
		{
			title: 'tm_status', field: "tmsShipmentStatus", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="tmsShipmentStatus" value={rowData.tmsShipmentStatus} />
			}
		},
		{
			title: 'pickup_appt_date_&_time', field: "loadReadyDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="loadReadyDateTime" value={rowData.loadReadyDateTime} />
			}
		},
		{
			title: 'origin_id', field: "originId",
			render: rowData => {
				return <CustomCellRender id="originId" value={rowData.originId} />
			}
		},
		{
			title: 'origin', field: "origin", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="origin" value={rowData.origin} />
			}
		},
		{
			title: 'customer', field: "customer", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="customer" value={rowData.customer} />
			}
		},
		{
			title: 'live_load', field: 'liveLoadInd',
			render: rowData => {
				return <div data-testid="liveLoadInd"><CustomRender value={rowData.liveLoadInd} /></div>
			}
		},
		{
			title: 'order_on_hold', field: "orderOnHoldInd",
			render: rowData => {
				return <div data-testid="orderOnHoldInd"><CustomRender value={rowData.orderOnHoldInd} /></div>
			}
		},
		{
			title: 'confirmed_delivery_appt_reschedule_count', field: "confirmedDeliveryApptRescheduleCount", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="confirmedDeliveryApptRescheduleCount" value={rowData.confirmedDeliveryApptRescheduleCount} />
			}
		},
		{
			title: 'delivery_appt_date_&_time', field: "deliverApptDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="deliverApptDateTime" value={rowData.deliverApptDateTime} />
			}
		},
		{
			title: 'confirmed_original_delivery_appt_date_time', field: "confirmedOriginalDeliveryApptDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="confirmedOriginalDeliveryApptDateTime" value={rowData.confirmedOriginalDeliveryApptDateTime} />
			}
		},
		{
			title: 'actual_arrival_at_origin', field: "originActualArrivalDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="originActualArrivalDateTime" value={rowData.originActualArrivalDateTime} />
			}
		},
		{
			title: 'actual_departure_at_origin', field: "originActualDepartureDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="originActualDepartureDateTime" value={rowData.originActualDepartureDateTime} />
			}
		},
		{
			title: 'actual_arrival_at_destination', field: "destnActualArrivalDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="destnActualArrivalDateTime" value={rowData.destnActualArrivalDateTime} />
			}
		},
		{
			title: 'actual_departure_at_destination', field: "destnActualDepartureDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="destnActualDepartureDateTime" value={rowData.destnActualDepartureDateTime} />
			}
		},
		{
			title: 'dest_city', field: "destinationCity",
			render: rowData => {
				return <CustomCellRender id="destinationCity" value={rowData.destinationCity} />
			}
		},
		{
			title: 'dest_state', field: "destinationState",
			render: rowData => {
				return <CustomCellRender id="destinationState" value={rowData.destinationState} />
			}
		},
		{
			title: 'ship_condition', field: "shippingCondition",
			render: rowData => {
				return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
			}
		},
		{
			title: 'appt_type', field: "appointmentType",
			render: rowData => {
				return <CustomCellRender id="appointmentType" value={rowData.appointmentType} />
			}
		},
		{
			title: 'appt_req_d', field: "appointmentRequired",
			render: rowData => {
				return <div data-testid="appointmentRequired"><CustomRender value={rowData.appointmentRequired} /></div>
			}
		},
		{
			title: 'appt_status', field: "appointmentStatusDesc",
			render: rowData => {
				return <CustomCellRender id="appointmentStatusDesc" value={rowData.appointmentStatusDesc} />
			}
		},
		{
			title: 'suspended_status', field: "shipmentSuspendedStatus", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="shipmentSuspendedStatus" value={rowData.shipmentSuspendedStatus} />
			}
		},
		{
			title: 'short_lead', field: "tmsRushIndicator", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <div data-testid="tmsRushIndicator"><CustomRender value={rowData.tmsRushIndicator} /></div>
			}
		},
		{
			title: 'reason_for_alert', field: "reasonCodeString", cellStyle: longTitleCellStyle,
			render: rowData => {
				const codeString = rowData?.orderExecutionReasonCodes?.map((code) => <div><CustomRender value={orderHealthReasonCodeMap[code]} /></div>);
				return <div data-testid="reasonCodeString">{!isEmpty(codeString) ? codeString : "-"}</div>
			}
		},
		{
			title: 'origin_eta', field: "originExpectedArrivalDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="originExpectedArrivalDateTime" value={rowData.originExpectedArrivalDateTime} />
			}
		},
		{
			title: 'destination_eta',
			field: "expectedDeliveryDateTime",
			cellStyle: shortTitleCellStyle,
			disableClick: true,
			render: rowData => <FourKitesLink data={rowData} />
		},
		{
			title: 'delivered_date_&_time', field: "actualDeliveredDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="actualDeliveredDateTime" value={rowData.actualDeliveredDateTime} />
			}
		},
		{
			title: 'loading_completion_date_time', field: "loadingCompletionDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="loadingCompletionDateTime" value={rowData.loadingCompletionDateTime} />
			}
		},
		{
			title: 'loading_completed_on_time', field: 'loadingCompletedOnTime',
			render: rowData => {
				return <div data-testid="loadingCompletedOnTime"><CustomRender value={rowData.loadingCompletedOnTime} /></div>
			}
		},
		{
			title: 'hours_in_ready_for_pickup', field: "hoursInReadyForPickup", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="hoursInReadyForPickup" value={rowData.hoursInReadyForPickup} />
			}
		},
	],
	columnConfiguration: (d) => {
		return {
			origin: d?.orderOrigin?.name ? get(d, "orderOrigin.name", "-") : "-",
			originId: get(d, "orderOrigin.id", "-"),
			liveLoadInd: d.liveLoadInd ? d.liveLoadInd === "Y" ? "yes" : "no" : "-",
			loadingCompletedOnTime: d.loadingCompletedOnTime ? d.loadingCompletedOnTime === "Y" ? "yes" : "no" : "-",
			appointmentRequired: d.appointmentRequired ? d.appointmentRequired === "Y" ? "yes" : "no" : "-",
			orderOnHoldInd: d.orderOnHoldInd ? d.orderOnHoldInd === "Y" ? "yes" : "no" : "-",
			deliverApptDateTime: (d?.deliverApptDateTime && d?.destinationTimeZone) ? moment(d.deliverApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			confirmedOriginalDeliveryApptDateTime: (d?.confirmedOriginalDeliveryApptDateTime && d?.destinationTimeZone) ? moment(d.confirmedOriginalDeliveryApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			expectedDeliveryDateTime: (d?.expectedDeliveryDateTime && d?.destinationTimeZone) ? moment(d.expectedDeliveryDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			originExpectedArrivalDateTime: (d?.originExpectedArrivalDateTime && d?.originTimeZone) ? moment(d.originExpectedArrivalDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			loadReadyDateTime: (d?.loadReadyDateTime && d?.originTimeZone) ? moment(d?.loadReadyDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			actualDeliveredDateTime: (d?.actualDeliveredDateTime && d?.destinationTimeZone) ? moment(d.actualDeliveredDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			loadingCompletionDateTime: (d?.loadingCompletionDateTime && d?.originTimeZone) ? moment(d?.loadingCompletionDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			customer: formatToSentenceCase(get(d, "orderDestination.name", "-")),
			appointmentStatusDesc: formatToSentenceCase(get(d, "appointmentStatusDesc", "-")),
			appointmentType: formatToSentenceCase(get(d, "appointmentType", "-")),
			destinationCity: formatToSentenceCase(get(d, "orderDestination.city", "-")),
			destinationState: get(d, "orderDestination.state", "-"),
			shipmentSuspendedStatus: get(d, "shipmentSuspendedStatus", "-"),
			tmsRushIndicator: d.tmsRushIndicator ? d.tmsRushIndicator === "Y" ? "yes" : "no" : "-",
			reasonCodeString: d?.orderExecutionReasonCodes?.reduce((codeString, code, index) => index === (d.orderExecutionReasonCodes.length - 1) ? `${codeString} ${orderHealthReasonCodeMap[code]}` : `${codeString} ${orderHealthReasonCodeMap[code]} |`, "") || "-",
			originActualArrivalDateTime: (d?.originActualArrivalDateTime && d?.originTimeZone) ? moment(d.originActualArrivalDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			originActualDepartureDateTime: (d?.originActualDepartureDateTime && d?.originTimeZone) ? moment(d.originActualDepartureDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			destnActualArrivalDateTime: (d?.destnActualArrivalDateTime && d?.destinationTimeZone) ? moment(d.destnActualArrivalDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			destnActualDepartureDateTime: (d?.destnActualDepartureDateTime && d?.destinationTimeZone) ? moment(d.destnActualDepartureDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
		}
	}
}