import React from "react";
import { Grid } from '@material-ui/core';
import { getChartSegmentTotal } from "./ChartComponent";
import BarChart from "components/D3Charts/BarChart";

export const BlockedTmsPlannedChartBuilder = (props) => {

  // React.useEffect(() => {
  //   rest.setRefresh({
  //     refresh: !rest.refresh,
  //     siteNums,
  //     time: new Date()
  //   })
  // }, []);

  const { margins, height, type, subtype, chartsData, keys, setDetails, setHeaderTotalCount } = props
  
  const chart1Columns = ["SO_BLOCKED_TMS_PLANNED_VISIBLE", "SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE"];
  const chart2Data = [];
  const barData = chartsData ? chartsData.map(d => {
        return { ...d, blueCount: d.greenCount }
    }) : [];
  barData.forEach(d => {
      if (chart1Columns.includes(d.state)) {
          chart2Data.push(d);
      }
  })

  setHeaderTotalCount(getChartSegmentTotal({keys,data:chart2Data}))
  
  return (
      <Grid container data-testid="blockedTmsPlanned">
        <Grid item xs={12}>
            <BarChart keys={keys} wrapit data={chart2Data} type={type} subtype={subtype} id={`${subtype}_tmsorderchart`} setDetails={setDetails} xKey="stateDesc" margins={margins} height={height} />
        </Grid>
      </Grid>
  );
}
