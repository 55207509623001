import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { generatePath, useParams } from "react-router-dom";
import { useRouteContext } from 'context/RouteContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { breadCrumbStyles } from 'theme';

const useStyles = makeStyles(breadCrumbStyles);

const SFBreadCrumb = ({ title }) => {
    const classes = useStyles();
    const { selfalertpath, mainPath } = useRouteContext();
    const { t } = useTranslation();
    const params = useParams();

    const gotopage = (page) => {
        const path = page === "alerts" ? selfalertpath : mainPath;
        return `${generatePath(
            path, {
            ...params,
        })}?showDefault=true`
    }

    return (
        <div className={classes.root} data-testid="breadcrumb-alert">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link data-testid='breadcrumb_home' to={gotopage("home")} className={classes.link}>
                    {t('home')}
                </Link>
                {
                    title ?
                        <Link data-testid="breadcrumb_go_sf" className={classes.link} to={gotopage("alerts")} >
                            Alerts
                        </Link> :
                        <Typography className={classes.activeLink}>Alerts</Typography>
                }
                {title && <Typography className={classes.activeLink}>{title}</Typography>}
            </Breadcrumbs>
        </div>
    );
}
export default SFBreadCrumb;