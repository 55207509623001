import React from 'react'
import { scaleBand, scaleLinear, range, extent, axisLeft, axisTop, max, min } from 'd3'
import { makeStyles } from '@material-ui/core/styles';
import Axes from './Axes'
import DivergingBars from './DivergingBars'
import ResponsiveWrapper from '../charts/ResponsiveWrapper'


const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.menu.base
    }
}));

const DivergingBarChart = ({ setDetails, wrapit, horizontal, xKey, header, total, type, data, subtype, ...props }) => {

    const margin = ({ top: 30, right: 60, bottom: 10, left: 60 })

    const barHeight = 32.60

    data = data.map((site) => ({ ...site, name: site.shortName, value: site.actualHours }))

    const height = Math.ceil((data.length + 0.1) * barHeight) + margin.top + margin.bottom

    const svgDimensions = {
        width: props.parentWidth,
        height
    }

    let xScale = data.length == 1 ? 
        max(data, d => d.value) < 0 ?
            scaleLinear()
                .domain([max(data, d => d.value), 0])
                .rangeRound([margin.left, props.parentWidth - margin.right]) :
            scaleLinear()
                .domain([0, max(data, d => d.value)])
                .rangeRound([margin.left, props.parentWidth - margin.right]) :
        scaleLinear()
            .domain(extent(data, d => d.value))
            .rangeRound([margin.left, props.parentWidth - margin.right]) 

    let yScale = scaleBand()
        .domain(range(data.length))
        .rangeRound([margin.top, height - margin.bottom])
        .paddingInner(0.3)


    const divergingY = axisLeft(yScale).tickFormat(i => data[i].name).tickSize(0).tickPadding(6)
    
    const divergingX = axisTop(xScale).ticks(props.parentWidth / 80)

    return (
        <span data-testid='barChart' style={{ textAlign: 'center' }}>
            <svg width={svgDimensions.width} height={svgDimensions.height}>
                {
                    <g>

                        <DivergingBars
                            scales={{ xScale, yScale }}
                            data={data}
                        />
                        <Axes
                            horizontal={horizontal}
                            wrapit={wrapit}
                            scales={{ xScale, yScale }}
                            margins={margin}
                            svgDimensions={svgDimensions}
                            diverging="true"
                            margin={margin}
                            divergingScales={{ divergingX, divergingY }}
                        />
                    </g>
                }

            </svg>
        </span>
    )
}

export default ResponsiveWrapper(DivergingBarChart)
