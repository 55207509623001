import React from "react";
import SearchBar from "../SearchBar"
import { get } from "lodash";
import { connect } from "react-redux";
import { saveFilters } from "../../../reduxLib/services";
import { stringToArray, stringToArrayV2 } from 'helpers';
import { useTranslation } from "react-i18next";

const SearchFilter = (props) => {
        const { filterKey, filters, saveFilters, type, subtype, customSaveFilters } = props;
        const filter = get(filters, `${filterKey}.data`, null);
        const { t } = useTranslation();
        const [search, setSearch] = React.useState(filter);
        const submitSearch = (e) => {
            e.preventDefault();
            setSearch(filters[filterKey]?.region === 'lao' ? stringToArrayV2(search, filterKey) : stringToArray(search));
            const savePayload = {
                filter: {
                    [filterKey]: {
                        ...filters[filterKey],
                        data: filters[filterKey]?.region === 'lao' ? stringToArrayV2(search, filterKey) : stringToArray(search) || null
                    }
                }, type, subtype
            };
            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayload);
                return;
            }
            saveFilters(savePayload);
        }


        React.useEffect(() => {
            setSearch(filter);
        }, [filter])

        return (
            <div data-testid="searchBarFilter">
                <SearchBar
                    handleSubmit={submitSearch}
                    searchPhrase={search}
                    handleChange={(value) => setSearch(value)}
                    placeholder={t("search")}
                />
            </div>
        )
}

const mapStatetoProps = (state, ownProps) => {
        const { subtype } = ownProps;
        return {
            filters: get(state, `options.filters.${subtype}`, {})
        }
};

const mapDispatchToProps = {
     saveFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(SearchFilter);
