import { getLocationsConstants } from '../constants';
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

const mapData = (data) => {
    return sortBy([...data.mill, ...data.dc], "shortName");
}

const getLocationsReducer = (state = { loading: false, locations: [], performance_loading: false, salesOffice:[], customer:{ noData:false, data:[]} }, action) => {
    switch (action.type) {
        case getLocationsConstants.LOCATION_FETCH_SUCCESS:
            return { ...state, locations: mapData(action.payload) }

        case getLocationsConstants.CUSTOMER_FETCH_SUCCESS:
            return { ...state, customer: { noData: isEmpty(action.payload) ? true : false, data:action.payload } }

        case getLocationsConstants.SALESOFC_FETCH_SUCCESS:
            return { ...state, salesOffice: action.payload }

        case getLocationsConstants.LOCATION_FETCH_FAILED:
            return { ...state, locations: [] }

        case getLocationsConstants.CUSTOMER_FETCH_FAILED:
            return { ...state, customer: { noData:true, data:[] } }
        
            case getLocationsConstants.SALESOFC_FETCH_FAILED:
            return { ...state, salesOffice: [] }

        case getLocationsConstants.LOADING:
            return { ...state, loading: action.payload, customer:{ noData:false, data:[]} }

        case getLocationsConstants.PERFORMANCE_FETCH_SUCCESS:
        case getLocationsConstants.PERFORMANCE_FETCH_FAILED:
            return { ...state, performance: action.payload }

        case getLocationsConstants.PEFRORMANCE_LOADING:
            return { ...state, performance_loading: action.payload }

        default:
            return state
    }
}

export default getLocationsReducer;