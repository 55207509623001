import React from "react";
import {
  useLocation,
  useHistory,
  generatePath,
  useRouteMatch,
  useParams
} from "react-router-dom";
import qs from 'query-string';
import { isJsonParsable } from "helpers";
import { businessUnits } from "configs/appConstants";
import { isEmpty } from "lodash";

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const useSetQuery = () => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const { tab } = useParams();
  const queryParams = qs.parse(location.search);
  const parsedParams = {};

  Object.keys(queryParams).map(d => {
    parsedParams[d] = isJsonParsable(queryParams[d]) || queryParams[d];
    if (d === "BU") {
      parsedParams[d] = isJsonParsable(queryParams[d]) || businessUnits
    }
    return d;
  })

  const setParams = ({ key, value }, customTab) => {
    history.push({
      ...location,
      pathname: generatePath(match.path, { tab: customTab || tab }),
      search: qs.stringify({
        ...queryParams,
        [key]: typeof value === "object" ? JSON.stringify(value) : value
      })
    });
  }

  return [parsedParams, setParams];
}

export const useViewBy = () => {
  const location = useLocation();
  const [parsedParams] = useSetQuery();
  const match = useRouteMatch();
  const history = useHistory();
  const queryParams = qs.parse(location.search);

  const setViewBy = (viewby) => {
    let options = parsedParams[viewby === "Sofc" ? "sofcNSG" : viewby] || [];
    let newtab = options.length > 1 ? "myselections" : options.length === 1 ? options[0] : "network";

    history.push({
      ...location,
      pathname: generatePath(match.path, { tab: newtab }),
      search: qs.stringify({
        ...queryParams,
        viewby: viewby || "sites"
      })
    });
  }
  return setViewBy;
}

export const useMultiSetQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = qs.parse(location.search);

  const setMultiParams = (params, pathname=undefined) => {
    history.push({
      ...location,
      pathname: pathname || location.pathname,
      search: qs.stringify({
        ...queryParams,
        ...params
      })
    });
  }

  return setMultiParams;
}

export const useUpdateTabWitOptions = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  // const [parsedParams] = useSetQuery();
  const queryParams = qs.parse(location.search);
  // const { tab } = useParams();
  // const { viewby } = parsedParams;

  // const generateTab = ({ value }) => {
  //   if (value?.length === 1) {
  //     return value[0];
  //   } else if (value?.length === 0) {
  //     return "network";
  //   }
  //   return "network";
  // }

  // const findActiveTab = (value, viewby) => {
  //   switch (viewby) {
  //     case "sites": {
  //       return generateTab({ optionsKey: "sites", value });
  //     }
  //     case "Cust": {
  //       return generateTab({ optionsKey: "Cust", value });
  //     }
  //     case "Sofc": {
  //       return generateTab({ optionsKey: "sofcNSG", value });
  //     }
  //     default: {
  //       return "network";
  //     }
  //   }
  // }

  const updateTabWitOptions = ({ key, value }, otherParams = {}, sites=[], cust=[], sofc=[]) => {
    let newtab = (isEmpty(sites) && isEmpty(cust) && isEmpty(sofc)) ? "network" : "myselections";
    // if (value?.length > 0) {
    //   newtab = "myselections";
    // } else {
    //   newtab = findActiveTab(value, viewby)
    // }
    
    history.push({
      ...location,
      pathname: generatePath(match.path, { tab: newtab }),
      search: qs.stringify({
        ...queryParams,
        [key]: typeof value === "object" ? JSON.stringify(value) : value,
        ...otherParams
      })
    });
  }

  return updateTabWitOptions;
}

export default useSetQuery;