import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useThemeContext } from 'context/ThemeContext';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
// import { useRouteContext } from 'context/RouteContext';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { viewLSRService } from 'reduxLib/services/osmLsrStackDetailsService';
import { osmLsrStyles } from "theme";
import Osm2DTrailerVisual from "theme/layouts/Osm2DTrailerVisual";
import LoadingPage from "../common/LoadingPage";
import ErrorHandlerPage from "../common/ErrorHandlerPage";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import BackToTopIcon from "../../assets/images/back_to_top.svg";
import RotateIcon from "../../assets/images/rotate-stack.svg";
import HazardIcon from "assets/images/hazard_icon.svg";
import LightTabularIcon from "../../assets/images/stack_table_view_light.svg";
import DarkTabularIcon from "../../assets/images/stack_table_view_dark.svg";
import LightTrailerIcon from "../../assets/images/trailer_view_light.svg";
import DarkTrailerIcon from "../../assets/images/trailer_view_dark.svg";

const useStyles = makeStyles(osmLsrStyles);

const StyledHeaderTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette?.background?.primaryBase,
    color: theme.palette.white,
    fontSize: 13,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
  body: {
    fontSize: 13,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  },
}))(TableCell);

const OsmLsrReport = () => {
  const classes = useStyles();
  moment.locale(localStorage.getItem('i18nextLng'));
  const { light, dark, setTheme } = useThemeContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const envArray = ["local", "development", "test"];
  // const { osmPath } = useRouteContext();
  // const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));
  const { origin, shipmentNum } = params;
  const { lsr, lsr_loading, lsr_error } = useSelector(({ osmLsrStackDetail }) => osmLsrStackDetail);
  const diagramType = lsr?.diagramType || "CSR";
  const [stackView, setStackView] = useState('tabular');
  const rightStackArray = lsr?.rightStackInformation?.length ? lsr.rightStackInformation : [];
  const leftStackArray = lsr?.leftStackInformation?.length ? lsr.leftStackInformation : [];

  useEffect(() => {
    const body = {
      originRegion: 'NA',
      siteNum: origin,
      shipmentNum: shipmentNum
    }
    dispatch(viewLSRService(body));
    
  }, [shipmentNum, origin])

  const backNavigation = (e) => {
    e.preventDefault();
    history.goBack();
  }

  const handleStackView = (event, viewType) => {
    setStackView(viewType);
  };

  const StackTable = ({ rowData, rightPadding }) => {
    return (
      <Box pt={2.5} pr={rightPadding} pb={2} >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="stack-details-table" data-testid="stackTableVisual" >
            <TableHead>
              <TableRow>
                <StyledHeaderTableCell> {t('stack')} </StyledHeaderTableCell>
                <StyledHeaderTableCell> {t('load_seq')} </StyledHeaderTableCell>
                <StyledHeaderTableCell> {t('order_qty')} </StyledHeaderTableCell>
                <StyledHeaderTableCell> {t('product_code')} </StyledHeaderTableCell>
                <StyledHeaderTableCell> S/P </StyledHeaderTableCell>
                <StyledHeaderTableCell> {t('placement')} </StyledHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { rowData.length ? rowData.map( row => (
                <>
                  <TableRow >
                    <StyledHeaderTableCell rowSpan={row?.materialsInfo?.length}> {row?.rowID} {row?.rotateInd === "Y" ? <img src={RotateIcon} className={classes.rotateIcon} alt="Rotate icon" /> : null} </StyledHeaderTableCell>
                    <StyledHeaderTableCell> {row?.materialsInfo[0]?.wmsSequence} </StyledHeaderTableCell>
                    <StyledHeaderTableCell> {row?.materialsInfo[0]?.orderQty} </StyledHeaderTableCell>
                    <StyledHeaderTableCell> {row?.materialsInfo[0]?.materialNumber} {row?.materialsInfo[0]?.hazIndForMaterial === "Y" ? <img src={HazardIcon} className={classes.rotateIcon} alt="hazard icon" /> : null} </StyledHeaderTableCell>
                    <StyledHeaderTableCell> {row?.materialsInfo[0]?.slipsheetPalletInd} </StyledHeaderTableCell>
                    <StyledHeaderTableCell> {row?.materialsInfo[0]?.placement} </StyledHeaderTableCell>
                  </TableRow>
                  { row?.materialsInfo?.length > 1 ?
                      row?.materialsInfo.map((value, index) => (
                        index != 0 && <TableRow>
                          <StyledHeaderTableCell> {value?.wmsSequence} </StyledHeaderTableCell>
                          <StyledHeaderTableCell> {value?.orderQty} </StyledHeaderTableCell>
                          <StyledHeaderTableCell> {value?.materialNumber} {value?.hazIndForMaterial === "Y" ? <img src={HazardIcon} className={classes.rotateIcon} alt="hazard icon" /> : null} </StyledHeaderTableCell>
                          <StyledHeaderTableCell> {value?.slipsheetPalletInd} </StyledHeaderTableCell>
                          <StyledHeaderTableCell> {value?.placement} </StyledHeaderTableCell>
                        </TableRow>
                      ))
                    : null 
                  }
                </>
              )) :
                <Typography variant='body1' className={classes.noTableData}> {t('no_data_found')} </Typography>
              } 
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
  
  useEffect(() => {
    setTheme(light);
    return () => {
      setTheme(dark);
    }
  }, []);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

  return (
    <Box mt={6.5} data-testid="osmViewLsr">
      <Grid data-testid="viewLsrBackNav" className={classes.prevNavigator} onClick={(e) => backNavigation(e)}>
        <NavigateBeforeIcon />
        <Typography variant='body1'> {t('back_to_docc_results')} </Typography>
      </Grid>
      { !lsr_loading ? 
        (!lsr_loading && lsr_error) ? 
        <ErrorHandlerPage errMessage={ t(lsr_error, { shipmentNum: shipmentNum }) } errTagline={ lsr_error === "osm_restricted" ? t("we_are_sorry") : null } />
        :
        <>
        <Grid >
          <Typography variant='h3' className={classes.title}> {t('view_lsr_for_shipment_num')} - {shipmentNum} </Typography>
        </Grid>

        {/* Loading Instructions  */}
        <Grid container className={classes.container} data-testid="lsrLoadingInstructions">
          <Grid item xs={6} md={4}>
            <Typography variant='h4' className={classes.spacer}> Kimberly-Clark Corporation </Typography>
            <Typography variant='h4' className={classes.spacer}> { t('loading_instructions') } </Typography>
          </Grid>
          <Grid item xs={6} md={4} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('submitted_from')} </Typography>
            <div className={classes.textfield}> {lsr?.submittedFrom || '-'} </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('run_date_time')} </Typography>
            <div className={classes.textfield}> 
              { (lsr?.runDateTime) ? ( lsr?.timezone) ? moment(lsr.runDateTime)?.tz(lsr.timezone)?.format(`MMM DD YYYY HH:mm z`) : moment(lsr.runDateTime)?.tz('UTC')?.format(`MMM DD YYYY HH:mm z`) : "-"} 
            </div>
          </Grid>
        </Grid>

        {/* Order Details  */}
        <Grid container className={classes.container} data-testid="lsrOrderDetails">
          <Grid container item className={classes.spacer}>
            <Grid item xs={12} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('shipment_num_freight_order')} </Typography>
              <div className={classes.textfield}> {shipmentNum} </div>
            </Grid>
            <Grid item xs={12} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('scac')}/{t('tariff')} </Typography>
              <div className={classes.textfield}> {lsr?.scacCode || '-'} </div>
            </Grid>
            <Grid item xs={6} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('vehicle_id')} </Typography>
              <div className={classes.textfield}> {lsr?.vehicleId || '-'} </div>
            </Grid>
            <Grid item xs={6} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('ship_method')} </Typography>
              <div className={classes.textfield}> {lsr?.shipMethod || '-'} </div>
            </Grid>
          </Grid>
          <Grid container item className={classes.spacer}>
            <Grid item xs={12} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('warehouse_ready_date_time')} </Typography>
              <div className={classes.textfield}> 
                { (lsr?.warehouseReadyDateTime) ? ( lsr?.timezone) ? moment(lsr.warehouseReadyDateTime)?.tz(lsr.timezone)?.format(`MMM DD YYYY HH:mm z`) : moment(lsr.warehouseReadyDateTime)?.tz('UTC')?.format(`MMM DD YYYY HH:mm z`) : "-"} 
              </div>
            </Grid>
            <Grid item xs={12} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('latest_start_date_time')} </Typography>
              <div className={classes.textfield}> 
                { (lsr?.latestStartDateTime) ? ( lsr?.timezone) ? moment(lsr.latestStartDateTime)?.tz(lsr.timezone)?.format(`MMM DD YYYY HH:mm z`) : moment(lsr.latestStartDateTime)?.tz('UTC')?.format(`MMM DD YYYY HH:mm z`) : "-"} 
              </div>
            </Grid>
            <Grid item xs={12} md={3} className={classes.inlineCenter}>
              <Typography variant='h4'> {t('seal_number')} </Typography>
              <div className={classes.textfield}> {lsr?.sealNumber || '-'} </div>
            </Grid>
          </Grid>
        </Grid>

        {/* Warning Message  */}
        { lsr?.warningsList && lsr?.warningsList.map(item =>
          <Grid className={classes.warning} data-testid="lsrWarning">
            <ReportProblemOutlinedIcon /> &nbsp;
            <Typography variant='body1'> {item?.warningMessage} : {item.warningDescription} </Typography>
          </Grid>
        )}

        {/* Stop Information  */}
        <Grid>
          <Typography variant='h3' className={classes.title}> {t('stop_related_info')} </Typography>
        </Grid>
        { lsr?.stopInformationList?.length ? lsr.stopInformationList.map( (item) =>
          <Grid container className={classes.container} data-testid="lsrStopInfo">
            <Grid container item className={classes.spacer}>
              <Grid item xs={6} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('load_sequence')} </Typography>
                <div className={classes.textfield}> { item?.loadSequence?.join(', ') || 'N/A' } </div>
              </Grid>
              <Grid item xs={6} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('delivery_num')} </Typography>
                <div className={classes.textfield}> { item?.deliveryNumList?.join(', ') || 'N/A' } </div>
              </Grid>
              <Grid item xs={6} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('order_num')} </Typography>
                <div className={classes.textfield}> { item?.orderNumList?.join(', ') || 'N/A' } </div>
              </Grid>
              <Grid item xs={6} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('customer_po')} </Typography>
                <div className={classes.textfield}> { item?.customerPOList?.join(', ') || 'N/A' } </div>
              </Grid>
            </Grid>
            <Grid container item className={classes.spacer}>
              <Grid item xs={12} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('ship_to')} </Typography>
                <div className={classes.textfield}> { item?.shipTOAdress || 'N/A' } </div>
              </Grid>
              <Grid item xs={12} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('load_codes')} </Typography>
                <div className={classes.textfield}> { item?.loadCodesList?.join(', ') || 'N/A' } </div>
              </Grid>
              <Grid item xs={12} md={3} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('pallet_info')} </Typography>
                <div className={classes.textfield}> { item?.palletSlipsheetNumList?.join('\n') || 'N/A' } </div>
              </Grid>
            </Grid>
            <Grid container item className={classes.spacer}>
              <Grid item xs={12} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('material_handling_comments')} </Typography>
                <div className={classes.textfield}> { item?.deliveryComemntsList?.join('\n') || t('no_material_handling_comments_found') } </div>
              </Grid>
            </Grid>
          </Grid>)
        : 
          <Grid container className={classes.container} data-testid="lsrNoStopInfo">
            <Grid className={classes.noInfo}>
              <ReportProblemOutlinedIcon />
              <Typography variant='body1'> {t('no_info_found')} </Typography>
            </Grid>
          </Grid>    
        }

        {/* Ship Weight Information  */}
        <Grid className={classes.shipInfo}>
          <Typography variant='h3' className={classes.title}> {t('ship_weight_info')} {t('and')} {t('stack_details')} </Typography>
          {diagramType === 'CSR' ? 
            <ToggleButtonGroup value={stackView} exclusive onChange={handleStackView} aria-label="stack view type" className={classes.toggleButton}>
              <ToggleButton value="trailer" aria-label="trailer visual" data-testid="trailerVisualButton" >
                <img src={stackView === 'trailer' ? LightTrailerIcon : DarkTrailerIcon} />
                <Typography variant='body1'> &nbsp; {t('trailer_visual')} </Typography>
              </ToggleButton>
              <ToggleButton value="tabular" aria-label="stack table" data-testid="tableVisualButton">
                <img src={stackView === 'trailer' ? DarkTabularIcon : LightTabularIcon} />
                <Typography variant='body1'> &nbsp; {t('table_view')} </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
            : null
          }
        </Grid>

        {stackView === 'trailer' && diagramType === 'CSR' ? 
          <Osm2DTrailerVisual lsrData={lsr} leftStackArray={leftStackArray} rightStackArray={rightStackArray} /> :
          <>
            <Grid container className={classes.container} data-testid="lsrWeightInfo">
              <Grid container item className={classes.spacer}>
                <Grid item xs={12} md={4} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('net_weight')} </Typography>
                  <span className={classes.subtitle}> {'(lb)'} </span>
                  <div className={classes.textfield}> 
                    { lsr?.netWeight ? (Number(lsr.netWeight) < 28000) ? "NET WEIGHT IS UNDER SAFE WEIGHT LIMIT OF 28000" : lsr.netWeight : "N/A" }
                  </div>
                </Grid>
                <Grid item xs={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('steer_axle_weight')} </Typography>
                  <span className={classes.subtitle}> {'(lb)'} </span>
                  <div className={classes.textfield}> { lsr?.steerAxleWegiht || 'N/A' } </div>
                </Grid>
                <Grid item xs={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('drive_axle_weight')} </Typography>
                  <span className={classes.subtitle}> {'(lb)'} </span>
                  <div className={classes.textfield}> { lsr?.driveAxleWeight || 'N/A' } </div>
                </Grid>
              </Grid>
              <Grid container item className={classes.spacer}>
                <Grid item xs={12} sm={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('rear_axle_min_max_setting')} </Typography>
                  <span className={classes.subtitle}> {'(in)'} </span>
                  <div className={classes.textfield}> { lsr?.rearAxleMinSetting || 'N/A' } : { lsr?.rearAxleMaxSetting || 'N/A' } </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('rear_axle_min_max_weight')} </Typography>
                  <span className={classes.subtitle}> {'(lb)'} </span>
                  <div className={classes.textfield}> { lsr?.rearAxleMinWeight || 'N/A' } : { lsr?.rearAxleMaxWeight || 'N/A' } </div>
                </Grid>
              </Grid>
            </Grid>

            {/* Trailer Dimensions  */}
            <Grid container className={classes.container} data-testid="lsrTrailerDimension">
              <Grid xs={12} className={classes.inlineCenter}>
                <Typography variant='h4'> {t('trailer_dimensions')} </Typography>
                <span className={classes.subtitle}> {'(INCHES)'} </span>
              </Grid>
              <table className={classes.dimensionTable}>
                <tr className={classes.dimensionRow}>
                  <th> <div className={classes.dimensionHeader}> {t('compartmentID')} </div> </th>
                  <th> <div className={classes.dimensionHeader}> {t('length')} </div> </th>
                  <th> <div className={classes.dimensionHeader}> {t('frontHeight')} </div> </th>
                  <th> <div className={classes.dimensionHeader}> {t('width')} </div> </th>
                </tr>
                { lsr?.trailerDimension?.length && lsr?.trailerDimension.map(item => 
                  item?.LOADABLE_LENGTH != '0' &&
                  <tr>
                    <td className={classes.dimensionRow}> 
                      <div className={classes.dimensionCell}> { item?.id || 'N/A' } </div> 
                    </td>
                    <td className={classes.dimensionRow}> 
                      <div className={classes.dimensionCell}> { item?.LOADABLE_LENGTH || 'N/A' } </div> 
                    </td>
                    <td className={classes.dimensionRow}> 
                      <div className={classes.dimensionCell}> { item?.LOADABLE_FRONT_HEIGHT || 'N/A' } </div> 
                    </td>
                    <td className={classes.dimensionRow}> 
                      <div className={classes.dimensionCell}> { item?.LOADABLE_WIDTH || 'N/A' } </div> 
                    </td>
                  </tr>
                )}
              </table>
              <Grid container item >
                <Grid item xs={12} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('status')} </Typography>
                  <div className={classes.textfield}> { lsr?.loadingInstruction || 'N/A' } </div>
                </Grid>
                <Grid item xs={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('remaining_gap_left')} </Typography>
                  <div className={classes.textfield}> { lsr?.remainingGapLeft || 'N/A' } </div>
                </Grid>
                <Grid item xs={6} md={3} className={classes.inlineCenter}>
                  <Typography variant='h4'> {t('remaining_gap_right')} </Typography>
                  <div className={classes.textfield}> { lsr?.remainingGapRight || 'N/A' } </div>
                </Grid>
              </Grid>
            </Grid>

            {/* Stack Details  */}
            {/* <Grid>
              <Typography variant='h3' className={classes.title}> {t('stack_details')} </Typography>
            </Grid> */}
            <Grid container className={classes.container} data-testid="lsrStackDetails">
              <Grid container item className={classes.tableDiv}>
              {diagramType === 'CSR' ? 
                <Grid item xs={12} md={6} >
                  <Typography variant='h4'> {t('left')} </Typography>
                  <StackTable rowData={leftStackArray} rightPadding={isMobile ? 0 : 3} />
                </Grid>
                : null
              }
              <Grid item xs={12} md={6} >
                <Typography variant='h4'> { diagramType === 'CSR' ? t('right') : diagramType } </Typography>
                <StackTable rowData={rightStackArray} rightPadding={0} />
              </Grid>
              </Grid>
            </Grid>
          </>
        }

        {/* Shipment Totals  */}
        <Grid>
          <Typography variant='h3' className={classes.title}> {t('ship_totals')} </Typography>
        </Grid>
        <Grid container className={classes.container} data-testid="lsrShipTotals">
          <Grid item xs={6} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('ordered_qty')} </Typography>
            <div className={classes.textfield}> { lsr?.orderedQty || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('shipped_qty')} </Typography>
            <div className={classes.textfield}> { lsr?.shippedQty || 'N/A' } </div>
          </Grid>
          <Grid item xs={12} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('difference')} </Typography>
            <div className={classes.textfield}> { lsr?.differenceQty || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} md={3} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('beginning_cube')} </Typography>
            <div className={classes.textfield}> { lsr?.beginningCube || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} md={3} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('ending_cube')} </Typography>
            <div className={classes.textfield}> { lsr?.endingCube || 'N/A' } </div>
          </Grid>
        </Grid>

        {/* Adjusted Material Information */}
        <Grid>
          <Typography variant='h3' className={classes.title}> {t('adjusted_material_info')} </Typography>
        </Grid>
        <Grid container className={classes.container} data-testid="lsrMatInfo">
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('adjust_code')} </Typography>
            <div className={classes.textfield}> { lsr?.adjMaterialNum || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('qty_ordered')} </Typography>
            <div className={classes.textfield}> { lsr?.adjOrderedQty || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('qty_shipped')} </Typography>
            <div className={classes.textfield}> { lsr?.adjShippedQty || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('difference')} </Typography>
            <div className={classes.textfield}> { lsr?.adjCaseCut || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('max_add')} </Typography>
            <div className={classes.textfield}> { lsr?.adjMaxAdd || 'N/A' } </div>
          </Grid>
          <Grid item xs={6} sm={4} md={2} className={classes.inlineCenter}>
            <Typography variant='h4'> {t('max_cut')} </Typography>
            <div className={classes.textfield}> { lsr?.adjMaxCut || 'N/A' } </div>
          </Grid>
        </Grid>

        {/* Packaging Material / Pallet Information  */}
        <Grid>
          <Typography variant='h3' className={classes.title}> {t('packaging_material')}/{t('pallet_info')} </Typography>
        </Grid>
        { lsr?.stopInformationList?.length ? lsr.stopInformationList.map((ele, index) => 
              <Grid container className={classes.container} data-testid="lsrPackageInfo">
                { ele?.packagingInfo?.length ? <Grid xs={12} className={classes.inlineCenter}>
                    <Typography variant='h4'> {t('stop')} {index + 1} : </Typography>
                  </Grid>
                  : null
                }
                { ele?.packagingInfo?.length ? ele?.packagingInfo.map( (item) =>
                  <Grid container item className={classes.spacer}>
                    <Grid item sm={12} md={3} className={classes.inlineCenter}>
                      <Typography variant='h4'> 
                        { (item?.palletSlipSheetInd === "P") ? t('loose_pallets') :  t('loose_slip_sheet') }
                      </Typography>
                      <div className={classes.textfield}> {item?.palletSlipsheetNum || 'N/A'} {item?.palletDesc} </div>
                    </Grid>
                    <Grid item sm={6} md={2} className={classes.inlineCenter}>
                      <Typography variant='h4'> {t('qty_ordered')} </Typography>
                      <div className={classes.textfield}> {item?.orderedQty || 'N/A'} </div>
                    </Grid>
                    <Grid item sm={6} md={2} className={classes.inlineCenter}>
                      <Typography variant='h4'> {t('qty_shipped')} </Typography>
                      <div className={classes.textfield}> {item?.shippedQty || 'N/A'} </div>
                    </Grid>
                    <Grid item sm={6} md={2} className={classes.inlineCenter}>
                      <Typography variant='h4'> {t('qty_difference')} </Typography>
                      <div className={classes.textfield}> {item?.differenceQty || 'N/A'} </div>
                    </Grid>
                    <Grid item sm={6} md={3} className={classes.inlineCenter}>
                      <Typography variant='h4'> {t('load_seq')} </Typography>
                      <div className={classes.textfield}> {item?.loadSeq || 'N/A'} </div>
                    </Grid>
                  </Grid>)
                  :
                  <Grid container className={classes.container} data-testid="lsrNoPackageInfo">
                    <Grid className={classes.noInfo}>
                      <ReportProblemOutlinedIcon />
                      <Typography variant='body1'> {t('no_info_found')} </Typography>
                    </Grid>
                  </Grid>
                }
              </Grid>
              )
        : 
          <Grid container className={classes.container} data-testid="lsrNoPackageInfo">
            <Grid className={classes.noInfo}>
              <ReportProblemOutlinedIcon />
              <Typography variant='body1'> {t('no_info_found')} </Typography>
            </Grid>
          </Grid>    
        } 

        <div className={classes.topDiv} onClick={goToTop} data-testid="back_to_top">
          <div className={classes.top} > 
            <img src={BackToTopIcon} alt="Back to top" />
            <Typography variant='body1'> {t('back_to_top')} </Typography>
          </div>
        </div>
        </> 
      : 
      <LoadingPage />
    }
    </Box>
  )
}

export default OsmLsrReport;