export default {
  OSM_SITES_FETCH_SUCCESS: 'OSM_SITES_FETCH_SUCCESS',
  OSM_SITES_FETCH_FAILED: 'OSM_SITES_FETCH_FAILED',
  OSM_SITES_LOADING: 'OSM_SITES_LOADING',

  OSM_MAP_REGIONS: 'OSM_MAP_REGIONS',
  OSM_MAP_COUNTRIES: 'OSM_MAP_COUNTRIES',
  OSM_MAP_SITES: 'OSM_MAP_SITES',

  OSM_CURRENT_REGIONS: 'OSM_CURRENT_REGIONS',
  OSM_CURRENT_COUNTRIES: 'OSM_CURRENT_COUNTRIES',
  OSM_CURRENT_SITES: 'OSM_CURRENT_SITES',
  OSM_CURRENT_BUSINESS: 'OSM_CURRENT_BUSINESS',

}