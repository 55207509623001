import React from 'react';
import { withStyles, Radio } from '@material-ui/core';

export default withStyles(theme => ({
    root: {
        color: theme?.palette.primary.contrastText,
        '&$checked': {
            color: theme?.palette.primary.contrastText,
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);
