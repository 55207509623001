import React, {useState, useEffect} from "react"
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles, Box } from "@material-ui/core"
import FavTickElement from "components/common/FavUnfavElement"
import { find, isEmpty, concat } from "lodash"
import { addItemSalesGroup, addItemSalesGroupSF, removeItemSalesGroup, removeItemSalesGroupSF, setFavCUSOService } from "reduxLib/services"
import { CTLink } from "components/common/CTLink"
import { locationfilterlayoutStyles } from "theme";
import CustomersChipComponent from "./CustomersChipComponent";
import { useSelector } from "react-redux";

const useStyles = makeStyles(locationfilterlayoutStyles);

export default({salesGroups,handleSalesOfficeLink, selfalert})=>{

    const classes = useStyles()   
    const { t } = useTranslation();
    const { favCuso } = useSelector(({favorites}) =>  favorites);
    const [favElementKeys, setFavElementKeys] = useState([]);

    let addSalesGroup = addItemSalesGroup;
    let removeSalesGroup = removeItemSalesGroup;

    if(selfalert) {
        addSalesGroup = addItemSalesGroupSF;
        removeSalesGroup = removeItemSalesGroupSF;
    }

    const getValue = (data = []) => {
        const values = [];
        data.map(d => values.push(d[0]));
        return values;
    }

    useEffect(() => {
        const values = getValue(favCuso.FAV_SALES_OFFICE);
        setFavElementKeys(values);
    }, [favCuso]);

    return (
        <>
        <Box data-testid='SGListing' mb={1} >
            <CTLink className={classes.link} onClick={()=>handleSalesOfficeLink({open: false})}>
               {`${t('sales_office')} ${salesGroups.reducerObject.salesOffice}(${salesGroups.reducerObject.salesOrg})`}
            </CTLink>
            <Typography variant='span' >{` > ${t('sales_groups')}`}</Typography>
        </Box>
        { !isEmpty(salesGroups.fromState?.salesGroup) && <Grid xs={12}><CustomersChipComponent action={removeItemSalesGroup}  chipsObjectArray={salesGroups.fromState?.salesGroup.map((value) =>( { value, reducerObject: salesGroups.reducerObject}))} /></Grid>}
        <Grid xs={12}><Box mb={2} data-testid="SGListTitle"><Typography variant='h6'>{`${t(`list_of_sales_group_for`)} ${salesGroups.reducerObject.salesOffice}`}</Typography></Box></Grid>
        <Grid container alignContent="flex-start" className={{
            [classes.overflowLocations]:true, 
        }}>
        { !isEmpty(salesGroups.list) && salesGroups.list.map((value)=>
            {
                return <FavTickElement 
                key={value}
                selfalert={selfalert}
                favElements={favElementKeys} 
                tooltipTitle={value}
                data={{
                    shortName:value,
                    reducerObject:salesGroups.reducerObject,
                    state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE),
                    type: "SALES_GROUP"
                }}
                checkClick={addSalesGroup}
                unCheckClick={removeSalesGroup}
                favClick={setFavCUSOService} 
                unfavClick={setFavCUSOService} 
                tick={find(salesGroups.fromState?.salesGroup,(n)=> n=== value)}
                fav={find(salesGroups.fromFavState?.salesGroup,(n)=> n=== value)}/>
            }
        )}
        </Grid>
        </>
    )
}