import React from 'react';
import moment from "moment-timezone";
import { shortTitleCellStyle, longTitleCellStyle } from 'helpers/tableStyleOverride';
import { formatToSentenceCase } from "helpers";
import { get, isEmpty, isArray } from "lodash";
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";
import CustomRender from "../../components/common/CustomRender";
import CustomCellRender from "../../components/common/CustomCellRender";
import FourKitesLink from 'components/common/Elements/FourKitesLink';


const orderStatusBucket = {
  SO_COMPLETELY_CONFIRMED_CUBE: "100_%_confirmed_cube",
  SO_NOT_COMPLETELY_CONFIRMED_CUBE: "less_than_100%_confirmed_cube",
  SO_BLOCKED_TMS_PLANNED_VISIBLE: "Visible In tms",
  SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE: "not_visible_in_tms",
  SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED: "pickup_not_scheduled",
  SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK: "pickup_package_multi_block",
  SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION: "immediate_action",
  SO_BACK_ORDER_BLOCK_FREE: "back_orders",
  SO_BACK_ORDER_BLOCKED: "back_orders"
}

export const defaultOrderColumns = {
  columnOrder: [
    {
      title: 'order_#', field: "orderNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <PromotionalOrder data={rowData} />
      }
    },
    {
      title: 'promotionalOrder', field: "promotionalOrder", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="promotionalOrder" value={rowData.promotionalOrder} />
      }
    },
    {
      title: 'collectiveNumber', field: "collectiveNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
      }
    },
    {
      title: 'customer', field: "customer", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="customer" value={rowData.customer} />
      }
    },
    {
      title: 'customer_po_#', field: "customerPoNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="customerPoNum" value={rowData.customerPoNum} />
      }
    },
    {
      title: 'leq', field: "loadEquivCube",
      render: rowData => {
        return <CustomCellRender id="loadEquivCube" value={rowData.loadEquivCube} />
      }
    },
    {
      title: 'confirmed_leq', field: "confLoadEquivCube",
      render: rowData => {
        return <CustomCellRender id="confLoadEquivCube" value={rowData.confLoadEquivCube} />
      }
    },
    {
      title: 'allocated_leq', field: "allocEquivCube",
      render: rowData => {
        return <CustomCellRender id="allocEquivCube" value={rowData.allocEquivCube} />
      }
    },
    {
      title: 'origin_id', field: "originId",
      render: rowData => {
        return <CustomCellRender id="originId" value={rowData.originId} />
      }
    },
    {
      title: 'origin', field: "origin", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="origin" value={rowData.origin} />
      }
    },
    {
      title: 'mad', field: "matAvailDate", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="matAvailDate" value={rowData.matAvailDate} />
      }
    },
    {
      title: 'original_rdd', field: "originalRequestedDeliveryDatetime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="originalRequestedDeliveryDatetime" value={rowData.originalRequestedDeliveryDatetime} />
      }
    },
    {
      title: 'rdd', field: "requestedDeliveryDate", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="requestedDeliveryDate" value={rowData.requestedDeliveryDate} />
      }
    },
    {
      title: 'ship_condition', field: "shippingCondition",
      render: rowData => {
        return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
      }
    },
    {
      title: 'delivery_block', field: "deliveryBlocksString", cellStyle: shortTitleCellStyle,
      render: rowData => {
        const codeString = isArray(rowData?.deliveryBlocks) ? rowData?.deliveryBlocks?.map((bc, index) => <span>{bc}{rowData?.deliveryBlocks.length - 1 !== index ? ', ' : ""}</span>) : rowData?.deliveryBlocks;
        return <div data-testid="deliveryBlocksString">{!isEmpty(codeString) ? codeString : "-"}</div>
      }
    },
    {
      title: 'shipment_#', field: "shipmentNum",
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },
    {
      title: 'delivery_#', field: "deliveryNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    {
      title: 'tracking_#', field: "trackingNumber", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="trackingNumber" value={rowData.trackingNumber} />
      }
    },
    {
      title: 'pickup_appt_date_&_time', field: "loadReadyDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadReadyDateTime" value={rowData.loadReadyDateTime} />
      }
    },
    {
      title: 'dest_city', field: "destinationCity",
      render: rowData => {
        return <CustomCellRender id="destinationCity" value={rowData.destinationCity} />
      }
    },
    {
      title: 'dest_state', field: "destinationState",
      render: rowData => {
        return <CustomCellRender id="destinationState" value={rowData.destinationState} />
      }
    },
    {
      title: 'trailer_#', field: "trailerNum",
      render: rowData => {
        return <CustomCellRender id="trailerNum" value={rowData.trailerNum} />
      }
    },
    {
      title: 'shipment_status', field: "orderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderExecutionBucketDesc" value={rowData.orderExecutionBucketDesc} />
      }
    },
    {
      title: 'fourkites_status', field: "fourkitesShipmentStatus", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="fourkitesShipmentStatus" value={rowData.fourkitesShipmentStatus} />
      }
    },
    {
      title: 'late_reason_code', field: "lateReasonCode", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="lateReasonCode" value={rowData.lateReasonCode} />
      }
    },
    {
      title: 'inbound_shipment_status', field: "inboundOrderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="inboundOrderExecutionBucketDesc" value={rowData.inboundOrderExecutionBucketDesc} />
      }
    },
    {
      title: 'order_status', field: "orderStatus", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderStatus" value={rowData.orderStatus} />
      }
    },
    {
      title: 'order_sub_status', field: "orderStatusBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderStatusBucketDesc" value={rowData.orderStatusBucketDesc} />
      }
    },
    {
      title: 'order_type', field: "orderType",
      render: rowData => {
        return <CustomCellRender id="orderType" value={rowData.orderType} />
      }
    },
    {
      title: 'business', field: "orderBusinessUnit",
      render: rowData => {
        const codeString = isArray(rowData?.orderBusinessUnit) ? rowData?.orderBusinessUnit?.map((bu) => <div>{bu}</div>) : rowData?.orderBusinessUnit;
        return <div data-testid="orderBusinessUnit">{!isEmpty(codeString) ? codeString : "-"}</div>
      }
    },
    {
      title: 'reason_for_alert', field: "reasonCodeString", cellStyle: longTitleCellStyle,
      render: rowData => {
        const codeString = rowData?.orderStatusReasonCodes?.map((code) => <div><CustomRender value={orderHealthReasonCodeMap[code]} /></div>);
        return <div data-testid="reasonCodeString">{!isEmpty(codeString) ? codeString : "-"}</div>
      }
    },
    // { title: 'destination_eta', field: "expectedDeliveryDateTime", cellStyle: shortTitleCellStyle, disableClick: true }
    {
      title: 'destination_eta',
      field: "expectedDeliveryDateTime",
      cellStyle: shortTitleCellStyle,
      disableClick: true,
      render: rowData => <FourKitesLink data={rowData} />
    },
    {
      title: 'sales_org', field: "salesOrg", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesOrg" value={rowData.salesOrg} />
      }
    },
    {
      title: 'distribution_channel', field: "distributionChannel", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="distributionChannel" value={rowData.distributionChannel} />
      }
    },
    {
      title: 'sales_office', field: "salesOffice", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesOffice" value={rowData.salesOffice} />
      }
    },
    {
      title: 'sales_groups', field: "salesGroup", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesGroup" value={rowData.salesGroup} />
      }
    },
    {
      title: 'delivered_date_&_time', field: "actualDeliveredDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="actualDeliveredDateTime" value={rowData.actualDeliveredDateTime} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
      // liveLoadInd: d.liveLoadInd ? d.liveLoadInd === "Y" ? "yes" : "no" : "-",
      // appointmentRequired: d.appointmentRequired ? d.appointmentRequired === "Y" ? "yes" : "no" : "-",
      // orderOnHoldInd: d.orderOnHoldInd ? d.orderOnHoldInd === "Y" ? "yes" : "no" : "-",
      deliverApptDateTime: (d?.deliverApptDateTime && d?.destinationTimeZone) ? moment(d.deliverApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      expectedDeliveryDateTime: (d?.expectedDeliveryDateTime && d?.destinationTimeZone) ? moment(d.expectedDeliveryDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      loadReadyDateTime: (d?.loadReadyDateTime && d?.originTimeZone) ? moment(d?.loadReadyDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      actualDeliveredDateTime: (d?.actualDeliveredDateTime && d?.destinationTimeZone) ? moment(d.actualDeliveredDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      customer: formatToSentenceCase(get(d, "orderDestination.name", "-")),
      origin: d?.orderOrigin?.name ? get(d, "orderOrigin.name", "-") : "-",
      originId: get(d, "orderOrigin.id", "-"),
      destinationCity: formatToSentenceCase(get(d, "orderDestination.city", "-")),
      destinationState: get(d, "orderDestination.state", "-"),
      orderStatusBucket: orderStatusBucket[d.orderStatusBucket] || "-",
      orderType: get(d, "orderType", "-"),
      reasonCodeString: d?.orderStatusReasonCodes?.reduce((codeString, code, index) => index === (d.orderStatusReasonCodes.length - 1) ? `${codeString} ${orderHealthReasonCodeMap[code]}` : `${codeString} ${orderHealthReasonCodeMap[code]} |`, "") || "-",
      deliveryBlocksString: (isArray(d?.deliveryBlocks) ?
        d?.deliveryBlocks?.reduce((codeString, code, index) => index === (d.deliveryBlocks.length - 1) ? `${codeString} ${code}` : `${codeString} ${code} |`, "") :
        d?.deliveryBlocks) || "-",
      salesOrg: get(d, "salesOrg", "-"),
      distributionChannel: get(d, "distributionChannel", "-"),
      salesOffice: get(d, "salesOffice", "-"),
      salesGroup: get(d, "salesGroup", "-")
    }
  }
}