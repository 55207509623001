import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveWrapper from "../charts/ResponsiveWrapper";
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    skeleton: {
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(2)
    },
    loader: {
    }
}));

const ErrorBox = ({ dimensions, parentWidth }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <span data-testid='errorBox' className={classes.loader} style={{ height: dimensions.height + 6 }}>
            <Typography color="error">
                {t("something_went_wrong")}
          </Typography>
        </span>
    );
}

export default ResponsiveWrapper(ErrorBox);
