import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";
import { shortTitleCellStyle } from 'helpers/tableStyleOverride';

export const OSMStackHeaderColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNum",  
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },{
      title: 'stack_header_id', field: "stackHeaderId",  
      render: rowData => {
        return <CustomCellRender id="stackHeaderId" value={rowData.stackHeaderId} />
      }
    },{
      title: 'delivery', field: "deliveryNum",  
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },{
      title: 'load_sequence', field: "sequenceNum",  
      render: rowData => {
        return <CustomCellRender id="sequenceNum" value={rowData.sequenceNum} />
      }
    },{
      title: 'wms_rp_sequence_num', field: "wmsSequenceNum",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="wmsSequenceNum" value={rowData.wmsSequenceNum} />  
      }
    },{
      title: 'physical_stop_sequence_num', field: "stopSequnceNum",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="stopSequnceNum" value={rowData.stopSequnceNum} />
      }
    },{
      title: 'stack_row_id_for_stock', field: "rowId", cellStyle: shortTitleCellStyle, 
      render: rowData => {
        return <CustomCellRender id="rowId" value={rowData.rowId} />
      }
    },{
      title: 'stack_row_id_for_column', field: "columnId",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="columnId" value={rowData.columnId} />
      }
    },{
      title: 'header_stack_type', field: "headerStackType",  
      render: rowData => {
        return <CustomCellRender id="headerStackType" value={rowData.headerStackType} />
      }
    },{
      title: 'placement_value', field: "placement",  
      render: rowData => {
        return <CustomCellRender id="placement" value={rowData.placement} />
      }
    },{
      title: 'pallet_slipsheet_ind', field: "palletSlipsheetInd",  
      render: rowData => {
        return <CustomCellRender id="palletSlipsheetInd" value={rowData.palletSlipsheetInd} />
      }
    },{
      title: 'packaging_material', field: "packagingMaterialNum",  
      render: rowData => {
        return <CustomCellRender id="packagingMaterialNum" value={rowData.packagingMaterialNum} />    
      }
    },{
      title: 'num_of_pallets_slipsheets', field: "noOfPalletsOrSlipsheet",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="noOfPalletsOrSlipsheet" value={rowData.noOfPalletsOrSlipsheet} />
      }
    },{
      title: 'target_qty_uom', field: "unitOfMeasurement",  
      render: rowData => {
        return <CustomCellRender id="unitOfMeasurement" value={rowData.unitOfMeasurement} />
      }
    },{
      title: 'rotate', field: "rotate",  
      render: rowData => {
        return <CustomCellRender id="rotate" value={rowData.rotate} />
      }
    },{
      title: 'adjacency_violation_ind', field: "adjViolationInd",  
      render: rowData => {
        return <CustomCellRender id="adjViolationInd" value={rowData.adjViolationInd} />
      }
    },{
      title: 'height_violation_ind', field: "heightViolationInd",  
      render: rowData => {
        return <CustomCellRender id="heightViolationInd" value={rowData.heightViolationInd} />
      }
    },{
      title: 'hand_stack_ind', field: "handStackInd",  
      render: rowData => {
        return <CustomCellRender id="handStackInd" value={rowData.handStackInd} />
      }
    },{
      title: 'cube_of_stack', field: "cubeOfStack",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="cubeOfStack" value={rowData.cubeOfStack} />
      }
    },{
      title: 'volume_unit', field: "volumeUnit",  
      render: rowData => {
        return <CustomCellRender id="volumeUnit" value={rowData.volumeUnit} />
      }
    },{
      title: 'trailer_sequence', field: "trailerSeq",  
      render: rowData => {
        return <CustomCellRender id="trailerSeq" value={rowData.trailerSeq} />
      }
    },{
      title: 'created_on', field: "createdOn",  
      render: rowData => {
        return <CustomCellRender id="createdOn" value={rowData.createdOn} />
      }
    // },{
    //   title: 'time_created', field: "createdAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="createdAt" value={rowData.createdAt} />
    //   }
    },{
      title: 'changed_on', field: "changedOn",  
      render: rowData => {
        return <CustomCellRender id="changedOn" value={rowData.changedOn} />
      }
    // },{
    //   title: 'last_changed_at', field: "changedAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="changedAt" value={rowData.changedAt} />
    //   }
    },{
      title: 'stack_length', field: "stackLength",  
      render: rowData => {
        return <CustomCellRender id="stackLength" value={rowData.stackLength} />
      }
    },{
      title: 'stack_width', field: "stackWidth",  
      render: rowData => {
        return <CustomCellRender id="stackWidth" value={rowData.stackWidth} />
      }
    },{
      title: 'stack_height', field: "stackHeight",  
      render: rowData => {
        return <CustomCellRender id="stackHeight" value={rowData.stackHeight} />
      }
    },{
      title: 'stack_weight', field: "stackWeight",  
      render: rowData => {
        return <CustomCellRender id="stackWeight" value={rowData.stackWeight} />
      }
    },{
      title: 'palletized_sku', field: "palletizedSku",  
      render: rowData => {
        return <CustomCellRender id="palletizedSku" value={rowData.palletizedSku} />
      }
    },{
      title: 'packaging_material_two', field: "packagingMaterialNum2",  
      render: rowData => {
        return <CustomCellRender id="packagingMaterialNum2" value={rowData.packagingMaterialNum2} />
      }
    },{
      title: 'num_of_pallets_slipsheets_two', field: "noOfPalletsOrSlipsheet2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="noOfPalletsOrSlipsheet2" value={rowData.noOfPalletsOrSlipsheet2} />
      }
    },{
      title: 'multiple_platform_ind', field: "multPlatInd",  
      render: rowData => {
        return <CustomCellRender id="multPlatInd" value={rowData.multPlatInd} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}