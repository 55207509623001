import { businessUnits } from "configs/appConstants";
import isEmpty from 'lodash/isEmpty';

export const locationsMapping = (loctionsArray, bu) => {
    const result = loctionsArray.filter(item => {
        if(bu?.length === 2 || bu?.length === 0) {
            return true;
        } else {
            return item.businessUnits.includes(bu[0]);
        }
    }).reduce((acc, item) => {
        const subRegion = item.subRegion;
        if (!acc[subRegion]) {
            acc[subRegion] = [];
        }
        acc[subRegion].push(item);
        return acc;
    }, {});
    const finalResult = Object.entries(result).map(([subRegion, data]) => {
        return { [subRegion]: data };
    });

    return finalResult;
}

export const generateChipsData = ({ sites, viewby, tab, locations }) => {
    let loc = sites;
    if (tab === "network") {
        loc = ["All"];
    } else {
        if (tab !== "myselections") {
            if (viewby === "sites") {
                loc = [tab]
            }
        }
    }
    
    return {
        locations: loc?.map(d => {
            let siteName = locations.find(item => item.siteNum === d)?.shortName
            return {
                name: tab === "network" ? "all" : `${siteName} (${d})`,
                id: d,
                siteName: siteName,
                onClose: tab !== "network"
            }
        })
    }
}
export const chipsMain = ({ BU }) => {
    let business = BU;
    if (!business?.length) {
        business = businessUnits
    }
    return {
        region: [{ name: "LAO", onClose: false }],
        business: business.map(d => {
            return {
                name: d,
                id: d,
                onClose: business.length === 2
            }
        })
    }
}

export const getLAOFilterArgs = (filtersLao) => {
    if (!isEmpty(filtersLao)) {
        let dataLao = {}
        for (const [key, val] of Object.entries(filtersLao)) {
            if (val?.data) {
                // if (Array.isArray(val.data) && !val.stringToArray) {
                //     const mappedValues = val.data.filter(({ checked }) => checked)
                //         .map(({ value }) => value);
                //     if (!isEmpty(mappedValues)) {
                //         dataLao[key] = mappedValues
                //     } else {
                //         dataLao[key] = undefined;
                //     }
                // } else {
                    dataLao[key] = val.data;
                // }
            } else {
                dataLao[key] = undefined;
            }
        }
        return dataLao;
    }
    return {}
}

// export const generateFilterPayload = (filters) => {
//     if (!isEmpty(filters)) {
//         let data = {}
//         for (const [key, value] of Object.entries(filters)) {
//             if (value?.data) {
//                 if (Array.isArray(value.data) && !value.stringToArray) {
//                     const mappedvals = value.data.filter(({ checked }) => checked).map(({ value }) => value);
//                     if (!isEmpty(mappedvals)) {
//                         data[key] = mappedvals
//                     }
//                 } else {
//                     data[key] = value.data;
//                 }
//             } else {
//                 data[key] = value.data;
//             }
//         }
//         return data;
//     }
//     return {}
// }
