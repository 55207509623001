import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { businessUnits } from "configs/appConstants";
import { emeaBusinessFavService, emeaUpdateFavs } from 'reduxLib/services/EmeaServices/emeaFavoriteService';
import Snack from "components/common/Helpers/Snack";
import { isJsonParsable } from 'helpers';
import qs from 'query-string';
import FavoriteIcon from 'assets/images/favorite.svg';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';

const useStyles = makeStyles((theme) => ({
  businessFilterGrid: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing(1.5)}px 0px`
  }, 
  business: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.secondary?.pressed
    }
  }
}));

const EmeaBusinessUnits = ({ region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(location.search);

  const { bu_fav_loading, bu_fav_err, all_favs } = useSelector(({ emeaGlobalFavs }) => emeaGlobalFavs);

  const [BUSelected, setBUSelected] = useState(() => isJsonParsable(queryParams?.BU) || businessUnits);
  const [BUFavs, setBUFavs] = useState(all_favs?.favouriteBusinessUnits || []);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });

  const handleBUSelect = (event, bu) => {
    if (event.target.checked === false && BUSelected.length === 1) {
      setSnack({
        open: true,
        severity: "warning",
        message: t("atleast_one_business_unit_needs_to_selected")
      });
      return;
    }

    if (event.target.checked) {
      setBUSelected([...BUSelected, bu]);
    } else {
      const x = BUSelected.filter(d => d !== bu);
      setBUSelected(x);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnack({
        ...snack,
        open: false
    });
  };

  const handleBUFav = (e, bu) => {
    if(BUFavs.includes(bu)) {
      const y = BUFavs.filter(x => x !== bu);
      setBUFavs(y);
      dispatch(emeaBusinessFavService({ region: region, favouriteBusinessUnits: y }))
      if (BUSelected.includes(bu)) {
        const x = BUSelected.filter(d => d !== bu);
        setBUSelected(x);
      }
    } else {
      const z = [...BUFavs, bu];
      setBUFavs(z);
      dispatch(emeaBusinessFavService({ region: region, favouriteBusinessUnits: z }))
      if (BUSelected.includes(bu) === false) setBUSelected([...BUSelected, bu]);
    }
  }

  useEffect(() => {
    if(BUSelected.length === 0) setBUSelected(businessUnits);
    history.push({
      ...location,
      search: qs.stringify({ ...queryParams, BU: JSON.stringify(BUSelected) })
    });
  }, [BUSelected])

  useEffect(() => {
    if(!bu_fav_loading && bu_fav_err && bu_fav_err !== 'success') {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: t(bu_fav_err)
      });
    }
    if(!bu_fav_loading && bu_fav_err === 'success') {
      const body = {...all_favs, favouriteBusinessUnits: BUFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [bu_fav_err, bu_fav_loading])

  return (
    <Grid container data-testid='businessfilter' className={classes.businessFilterGrid}>
      { businessUnits.map(bu => {
        return (
          <Grid container item sm={8} md={6} lg={4} className={classes.business} data-testid='business-unit-container'>
            <Typography variant='body1'> { bu } </Typography>
            <Grid item>
              <Checkbox name={ bu } checked={BUSelected.indexOf( bu ) > -1} onChange={ (e) => handleBUSelect(e, bu) } className={classes.checkbox}
                inputProps={{'data-testid': `select-${bu}`}} />
              <IconButton aria-label="fav-button" onClick={(e) => handleBUFav(e, bu)} disabled={bu_fav_loading} data-testid={`fav-${bu}`} >
                <img alt="fav-icon" src={BUFavs.includes(bu) ? FavoriteIcon : NotFavoriteIcon} />
              </IconButton>
            </Grid>
          </Grid>
        )
        })
      }
      <Snack {...snack} handleClose={handleClose} />
    </Grid>
  )

}

export default EmeaBusinessUnits;