import React from 'react'
import { appTheme } from 'theme';
import { get } from "lodash";
import { ItemDetailsTable } from "components/common/ItemDetailsTable"
import useTranslateHook from "i18n/useTranslateHook";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";

const rowStyle = (d) => {
    return {
        backgroundColor: appTheme.palette.secondary.dark,
        flexGrow: 1,
        whiteSpace: 'nowrap',
        borderBottom: 'red',
        borderLeft: ["OVERAGE", "SHORTAGE"].includes(d.qtyDiscrepancy) ? '5px solid #FF4081' : ''
    }
}

export const promotionalColumns = [
    {
        title: 'material_id',
        field: "materialNum",
        emptyValue: "-",
        render: rowData => {
            return <PromotionalOrder data={rowData} textkey={"materialNum"} tagkey={"promotionalItem"} />
        }
    },
    {
        title: 'promotional_material', field: "promotionalItem",
        render: rowData => {
            if(rowData.promotionalItem){
                return <div data-testid="promotionalItem" >{rowData.promotionalItem}</div>
            } else {
                return <div data-testid="promotionalItem" >{"-"}</div>
            }
        }
    },
];

export const columns = {
    columnOrder: [
        {
            title: 'sl_no', field: 'slno', emptyValue: "-",
            render: rowData => {
                return <div data-testid="slno" >{rowData.slno}</div>
            }
        },
        ...promotionalColumns,
        {
            title: 'customer_material_id', field: 'custMaterialNum',
            render: rowData => {
                return <div data-testid="custMaterialNum" >{rowData.custMaterialNum}</div>
            }
        },
        {
            title: 'material_description', field: 'itemDesc',
            render: rowData => {
                return <div data-testid="itemDesc">{rowData.itemDesc}</div>
            }
        },
        {
            title: 'ordered_qty', render: (d) => {
                if (d.orderedQty >= 0) {
                    return <div data-testid="orderedQty">{`${d.orderedQty} ${d.qtyUom}`}</div>
                } else {
                    return <div data-testid="orderedQty">{"-"}</div>
                }
            }, field: 'orderedQty',
        },
        {
            title: 'confirmed_qty', render: (d) => {
                if (d.confirmedQty >= 0) {
                    return <div data-testid="confirmedQty">{`${d.confirmedQty} ${d.qtyUom}`}</div>
                } else {
                    return <div data-testid="confirmedQty">{"-"}</div>
                }
            }, field: 'confirmedQty',
        },
        {
            title: 'load_qty', render: (d) => {
                if (d.loadedQty >= 0) {
                    return <div data-testid="loadedQty">{`${d.loadedQty} ${d.qtyUom}`}</div>
                } else {
                    return <div data-testid="loadedQty">{"-"}</div>
                }
            }, field: 'loadedQty',
        },
        {
            title: 'difference_qty', render: (d) => {
                if (!isNaN(d.differenceQty)) {
                    return <div data-testid="differenceQty">{`${d.differenceQty} ${d.qtyUom}`}</div>
                } else {
                    return <div data-testid="differenceQty">{"-"}</div>
                }
            }, field: 'differenceQty'
        },
        {
            title: 'delivery_block', field: "deliveryBlockCode",
            render: rowData => {
                    return <div data-testid="deliveryBlockCode" >{rowData.deliveryBlockCode || "-"}</div>
            }
        },
        {
            title: 'mad', field: "matAvailDate",
            render: rowData => {
                return <div data-testid="matAvailDate" >{rowData.matAvailDate}</div>

            }
        },
    ],
    columnConfiguration: (d) => {
        return {
            materialNum: get(d, "materialNum", "-"),
            custMaterialNum: get(d, "custMaterialNum", "-"),
            itemDesc: get(d, "itemDesc", "-"),
            orderedQty: d.orderedQty >= 0 ? `${d.orderedQty} ${d.qtyUom}` : '-',
            confirmedQty: d.confirmedQty >= 0 ? `${d.confirmedQty} ${d.qtyUom}` : '-',
            loadedQty: d.loadedQty >= 0 ? `${d.loadedQty} ${d.qtyUom}` : '-',
            differenceQty: !isNaN(d.differenceQty) ? `${d.differenceQty} ${d.qtyUom}` : '-',
            deliveryBlockCode: get(d, "deliveryBlockCode", "-"),
            matAvailDate: get(d, "matAvailDate", "-"),
        }
    }
}

const ItemDetailsTableOutBound = ({ data: { orderStatusTableRowId } }) => {

    return (
        <ItemDetailsTable testId="itemdetailsoutbound" orderStatusTableRowId={orderStatusTableRowId} columns={useTranslateHook(columns.columnOrder)} rowStyle={rowStyle} />
    )
}

export default ItemDetailsTableOutBound;

