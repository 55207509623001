export const emeaSalesOfficeGroupConstants = {
  EMEA_FILTER_BY_SO_SG_LOADING: 'EMEA_FILTER_BY_SO_SG_LOADING',
  EMEA_FILTER_BY_SO_SG_SUCCESS: 'EMEA_FILTER_BY_SO_SG_SUCCESS',
  EMEA_FILTER_BY_SO_SG_FAILED: 'EMEA_FILTER_BY_SO_SG_FAILED',
  EMEA_FILTER_BY_SO_SG_RESET: 'EMEA_FILTER_BY_SO_SG_RESET',

  EMEA_SO_SG_FAV_LOADING: 'EMEA_SO_SG_FAV_LOADING',
  EMEA_SO_SG_FAV_SUCCESS: 'EMEA_SO_SG_FAV_SUCCESS',
  EMEA_SO_SG_FAV_FAILED: 'EMEA_SO_SG_FAV_FAILED',

}