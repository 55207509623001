import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: `1px solid ${theme.palette.white}`,
        fontSize: theme.spacing(2),
        padding: `${theme.spacing(1.25)}px ${theme.spacing(3)}px ${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: theme.spacing(.5),
        },
    },
}))(InputBase);


export default BootstrapInput;
