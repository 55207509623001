import { osmLsrStackDetailsConstants } from "../constants";

const initialState = {
  lsr_loading: false,
  lsr: {},
  lsr_error: null,
  stack_loading: false,
  stack: {},
  stack_error: null,
  ld_download_loading: false,
  ld_download_error: null,
  ld_download : null
}

const osmLsrStackDetailsReducer = (state = initialState, action) => {
  switch (action.type) {

    case osmLsrStackDetailsConstants.OSM_VIEW_LSR_LOADING:
      return { ...state, lsr_loading: action.payload };

    case osmLsrStackDetailsConstants.OSM_VIEW_LSR_SUCCESS:
    case osmLsrStackDetailsConstants.OSM_VIEW_LSR_FAILED:
      return { ...state, lsr: action.payload, lsr_error: action.error };

    case osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_LOADING:
      return { ...state, stack_loading: action.payload };

    case osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_SUCCESS:
    case osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_FAILED:
      return { ...state, stack: action.payload, stack_error: action.error };

    case osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_LOADING:
      return { ...state, ld_download_loading: action.payload };

    case osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_SUCCESS:
    case osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_FAILED:
    case osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_RESET:
      return { ...state, ld_download: action.payload };

    default:
      return state;
  }
}

export default osmLsrStackDetailsReducer;