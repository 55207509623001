const osmFilters = {
  searchStringList: {
    type: "text",
    name: "search",
    stringToArray: true,
    data: null
  },
  documentTypes: {
    type: "checkbox",
    name: "sales_document_type",
    data: [
      { name: "standard_orders", value: "ZSTD", checked: false },
      { name: "multi_plant_orders", value: "ZMPF", checked: false },
      { name: "vmi_orders", value: "ZVMI", checked: false },
      { name: "merged_orders", value: "ZMER", checked: false },
      { name: "foc_orders", value: "ZFOC", checked: false },
      { name: "non_finished_gds", value: "ZNFG", checked: false },
      { name: "third_party", value: "Z3PO", checked: false },
      { name: "dot_com_order", value: "ZCOM", checked: false },
      { name: "foc_mult_plant", value: "ZFOM", checked: false },
      { name: "replacement_ord", value: "ZREP", checked: false },
      { name: "scrap_sales", value: "ZSEC", checked: false },
      { name: "standard_order", value: "ZOR", checked: false },
      { name: "foc_non_finished_zrfn", value: "ZRFN", checked: false },
      { name: "fg_salvage", value: "ZFGS", checked: false },
      { name: "foc_non_finished_zfnf", value: "ZFNF", checked: false },
      // { name: "otc_intraco_sto_apo", value: "ZUB", checked: false },
      // { name: "otc_interco_sto_apo", value: "ZNB", checked: false },
      // { name: "two_intraco_sto_ph", value: "ZPH", checked: false },
      // { name: "otc_intraco_sto_manl", value: "ZUM", checked: false },
      // { name: "otc_interco_sto_manl", value: "ZNM", checked: false },
      // { name: "otc_intraco_sto_imprt", value: "ZUI", checked: false },
      // { name: "otc_interco_sto_imprt", value: "ZNI", checked: false },
      // { name: "otc_intraco_sto_spcmk", value: "ZUSM", checked: false },
      // { name: "otc_intercosto_spcmk", value: "ZNSM", checked: false },
      // { name: "standard_po", value: "NB", checked: false },
      // { name: "stock_transport_order", value: "UB", checked: false }
    ]
  },
  salesOrgList: {
    type: "checkbox",
    name: "sales_org",
    data: [
      { name: "2810_us_consumer", value: "2810", checked: false },
      { name: "2811_us_professional", value: "2811", checked: false },
      { name: "2820_canada_consumer", value: "2820", checked: false },
      { name: "2821_canada_professional", value: "2821", checked: false },
    ]
  },
  distributionChannel: {
    type: "checkboxradio",
    name: "distribution_channel",
    defaultValue: null,
    data: [
      { name: "80_domestic", shortName: "Dist. Channel", value: "80", checked: false },
      { name: "90_international", shortName: "Dist. Channel", value: "90", checked: false },
    ]
  },
  shippingConditionList: {
    type: "checkbox",
    name: "shipping_condition",
    data: [
      { name: "truck_load_tl", value: "TL", checked: false },
      { name: "intermodal_tf", value: "TF", checked: false },
      { name: "open_op", value: "OP", checked: false },
      { name: "package_pg", value: "PG", checked: false },
      { name: "customer_pickup_pu", value: "PU", checked: false }
    ]
  },
  osmShipmentExecutionBucket: {
    //If there is an addition in this option ,there is another object below  add in that too
    type: "checkbox",
    name: "status",
    data: [
      { name: "ld_pending", value: "OSM_LOAD_DIAGRAM_SUBMISSION_PENDING", checked: true },
      { name: "ld_submitted", value: "OSM_LOAD_DIAGRAM_SUBMITTED", checked: true },
      { name: "print_ucl", value: "OSM_UCC_LABEL_PRINTED", checked: true },
      { name: "print_addr_label", value: "OSM_ADDRESS_LABEL_PRINTED", checked: true },
      { name: "DL to WMS", value: "OSM_DOWNLOADED_TO_WMS", checked: true },
      { name: "load_start", value: "OSM_LOADING_STARTED", checked: true },
      { name: "load_end", value: "OSM_LOADING_ENDED", checked: false },
      { name: "ship_comp", value: "OSM_SHIPMENT_COMPLETION", checked: false },
      { name: "ship_start", value: "OSM_SHIPMENT_START", checked: false },
      { name: "ship_end", value: "OSM_SHIPMENT_END", checked: false },
    ]
  },
  salesOfficeList: {
    type: "text",
    name: "sales_office",
    stringToArray: true,
    data: null
  },
  sites: {
    type: "text",
    name: "plant",
    stringToArray: true,
    data: null
  },
  shippingPoint: {
    type: "text",
    name: "shipping_point",
    data: null
  },
  receivingPoint: {
    type: "text",
    name: "receiving_point",
    data: null
  },
  shipTo: {
    type: "text",
    name: "ship_to",
    data: null
  },
  destName: {
    type: "text",
    name: "ship_to_name",
    data: null
  },
  destCity: {
    type: "text",
    name: "ship_to_city",
    data: null
  },
  destState: {
    type: "text",
    name: "ship_to_region",
    data: null
  },
  shipToPostalCode: {
    type: "text",
    name: "ship_to_postalcode",
    data: null
  },
  warehouseReadyDateTimeOriginTZCustom: {
    type: "customdaterange",
    name: "warehouse_ready_date",
    shortName: 'Warehouse Ready Date',
    data: null
  },
  warehouseReadyDateTimeOriginTZ: {
    type: "customdaterange",
    dummytime: true,
    timeformat: "T",
    startTime: "[00:00:00.000]",
    endTime: "[23:59:59.999]",
    name: "warehouse_ready_date",
    shortName: 'Warehouse Ready Date',
    data: null
  },
  matAvailDateCustom: {
    type: "customdaterange",
    name: "material_availability_date",
    shortName: 'Mat.Avail Date',
    data: null
  },
  matAvailDate: {
    type: "customdaterange",
    name: "material_availability_date",
    shortName: 'Mat.Avail Date',
    data: null
  },
  requestedDeliveryDateCustom: {
    type: "customdaterange",
    name: "requested_delivery_date",
    shortName: 'Req. Delivery Date',
    data: null
  },
  requestedDeliveryDate: {
    type: "customdaterange",
    name: "requested_delivery_date",
    shortName: 'Req. Delivery Date',
    data: null
  },
  loadReadyDateTimeOriginTZ: {
    type: "daterange",
    dummytime: true,
    timeformat: "T",
    startTime: "[00:00:00.000]",
    endTime: "[23:59:59.999]",
    name: "carrier_ready_date",
    shortName: 'Carr. Ready Date',
    data: null
  },
  loadStartDateTimeOriginTZCustom: {
    type: "customdaterange",
    name: "load_start_date",
    shortName: 'Load Start Date',
    data: null
  },
  loadStartDateTimeOriginTZ: {
    type: "customdaterange",
    dummytime: true,
    timeformat: "T",
    startTime: "[00:00:00.000]",
    endTime: "[23:59:59.999]",
    name: "load_start_date",
    shortName: 'Load Start Date',
    data: null
  },
  // directLoadInd: { //to be taken in next iteration
  //   type: "radio",
  //   name: "direct_load_indicator",
  //   data: null
  // },
  finishedGoodsInd: {
    type: "radio",
    name: "finished_goods",
    data: null
  },
  poType: {
    type: "text",
    name: "po_type",
    data: null
  },
  billingBlockCodeList: {
    type: "checkbox",
    name: "header_billing_block",
    data: [
      { name: "calculation_missing", value: "01", checked: false },
      { name: "compl_confirm_mising", value: "02", checked: false },
      { name: "prices_incomplete", value: "03", checked: false },
      { name: "check_terms_of_payment", value: "04", checked: false },
      { name: "check_terms_of_dlv", value: "05", checked: false },
      { name: "check_credit_memo", value: "08", checked: false },
      { name: "check_debit_memo", value: "09", checked: false },
      { name: "refrb_price_check", value: "Z1", checked: false },
      { name: "denied_party", value: "Z8", checked: false },
      { name: "denied_credit", value: "Z9", checked: false },
      { name: "missing_freight_fee", value: "ZF", checked: false },
      { name: "military_price_diff", value: "ZP", checked: false }
    ]
  },
  derivedOverAllCreditStatus: {
    type: "checkbox",
    name: "credit_status",
    data: [
      { name: "credit_check_not_executed", value: "", checked: false },
      { name: "credit_check_was_executed_doc_ok", value: "A", checked: false },
      { name: "credit_check_was_executed_doc_not_ok", value: "B", checked: false },
      { name: "credit_check_was_executed_doc_not_ok_partial", value: "C", checked: false },
      { name: "doc_released_by_credit_representative", value: "D", checked: false }
    ]
  },
  goodsIssueStatusList: {
    type: "checkbox",
    name: "goods_issue_status",
    data: [
      { name: "not_relevant", value: "", checked: false },
      { name: "not_yet_processed", value: "A", checked: false },
      { name: "partially_processed", value: "B", checked: false },
      { name: "completely_processed", value: "C", checked: false }
    ]
  },
  osmLdStatusList: {
    type: "checkbox",
    name: "load_diagram_status",
    data: [
      { name: "not_submitted", value: "NOT_SUBMITTED", checked: false },
      { name: "success", value: "SUCCESS", checked: false },
      { name: "error", value: "ERROR", checked: false }
    ]
  },
  osmWmsStatusList: {
    type: "checkbox",
    name: "red_praire_wms_status",
    data: [
      { name: "not_submitted", value: "NOT_SUBMITTED", checked: false },
      { name: "success", value: "SUCCESS", checked: false },
      { name: "error", value: "ERROR", checked: false }
    ]
  },
  osmEdiStatusList: {
    type: "checkbox",
    name: "edi_status",
    data: [
      { name: "not_submitted", value: "NOT_SUBMITTED", checked: false },
      { name: "successful", value: "SUCCESS", checked: false },
      { name: "not_successful", value: "ERROR", checked: false }
    ]
  },
  stoReceiptStatus: {
    type: "radio",
    name: "sto_receipt_status",
    data: null
  },
  soldTo: {
    type: "text",
    name: "sold_to",
    data: null
  },
  soldToName: {
    type: "text",
    name: "sold_to_name",
    data: null
  },
  overallRejectionStatusList: {
    type: "checkbox",
    name: "header_reject_status",
    data: [
      { name: "not_relevant", value: "", checked: false },
      { name: "not_yet_processed", value: "A", checked: false },
      { name: "partially_processed", value: "B", checked: false },
      { name: "completely_processed", value: "C", checked: false }
    ]
  },
  billingStatusList: {
    type: "checkbox",
    name: "header_order_related_billing_status",
    data: [
      { name: "not_relevant", value: "", checked: false },
      { name: "not_yet_processed", value: "A", checked: false },
      { name: "partially_processed", value: "B", checked: false },
      { name: "completely_processed", value: "C", checked: false }
    ]
  },
  uccLabelRequired: {
    type: "radio",
    name: "ucl_label_required",
    data: null
  },
  uccLabelPrintStatusList: {
    type: "checkbox",
    name: "ucl_label_printed",
    defaultValue: null,
    data: [
      { name: "not_submitted", value: "NOT_SUBMITTED", checked: false },
      { name: "success", value: "SUCCESS", checked: false },
      { name: "error", value: "ERROR", checked: false }
    ]
  },
  addrLabelRequired: {
    type: "radio",
    name: "addr_label_required",
    data: null
  },
  liveLoad: {
    type: "radio",
    name: "live_load",
    data: null
  },
  totalGMStatusList: {
    type: "checkbox",
    name: "total_gm_status",
    data: [
      { name: "not_relevant", value: "", checked: false },
      { name: "not_yet_processed", value: "A", checked: false },
      { name: "partially_processed", value: "B", checked: false },
      { name: "completely_processed", value: "C", checked: false }
    ]
  },
  fullyPickedStatus: {
    type: "radio",
    name: "fully_picked",
    data: null
  },
  i2TriggerFlag: {
    type: "checkboxradio",
    name: "i2_flag",
    defaultValue: null,
    data: [
      { name: "send_order_to_i2_consumer", value: "O", checked: false },
      { name: "send_delivery_to_i2_hc", value: "D", checked: false },
    ]
  },
  hdrParcelLabelPrintStatus: {
    type: "checkboxradio",
    name: "hdr_parcel_label_print_status",
    defaultValue: null,
    data: [
      { name: "printed", shortName: "HDR.Parcel Lbl Prnt Stat", value: "SUCCESS", checked: false },
      { name: "not_printed", shortName: "HDR.Parcel Lbl Prnt Stat", value: "FAIL", checked: false },
    ]
  },
  osmShipmentExecutionHealth: {
    type: "checkbox",
    name: "health",
    data: [
      { name: "unhealthy", value: "RED", checked: false },
      { name: "healthy", value: "GREEN", checked: false }
    ]
  },
}

export const osmShipmentExecutionBucket = {
  type: "checkbox",
  name: "status",
  data: [
    { name: "ld_pending", value: "OSM_LOAD_DIAGRAM_SUBMISSION_PENDING", checked: false },
    { name: "ld_submitted", value: "OSM_LOAD_DIAGRAM_SUBMITTED", checked: false },
    { name: "print_ucl", value: "OSM_UCC_LABEL_PRINTED", checked: false },
    { name: "print_addr_label", value: "OSM_ADDRESS_LABEL_PRINTED", checked: false },
    { name: "DL to WMS", value: "OSM_DOWNLOADED_TO_WMS", checked: false },
    { name: "load_start", value: "OSM_LOADING_STARTED", checked: false },
    { name: "load_end", value: "OSM_LOADING_ENDED", checked: false },
    { name: "ship_comp", value: "OSM_SHIPMENT_COMPLETION", checked: false },
    { name: "ship_start", value: "OSM_SHIPMENT_START", checked: false },
    { name: "ship_end", value: "OSM_SHIPMENT_END", checked: false },
  ]
}
export const osmFiltersModifiedObject = {
  ...osmFilters,
  osmShipmentExecutionBucket
}

export default osmFilters;