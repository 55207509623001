import { selfAlertingConstants } from "../constants"
import { endpoints } from "helpers";
import fetch from "./serviceHelpers";

export const saveSelfAlertFilters = variables => dispatch => {
    dispatch({
        type: selfAlertingConstants.SAVE_SLEF_ALERTING_FILTERS,
        payload: variables
    })
}

export const addItemsCusMultipleSF = variables => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_ADD_CUSO_MULTIPLE_SUCCESS,
        payload: variables
    })
}

export const addItemCusoSF = variables => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_ADD_CUSO_SUCCESS,
        payload: variables.reducerObject
    })
}

export const removeItemCusoSF = variables => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_REMOVE_CUSO_SUCCESS,
        payload: variables.reducerObject
    })
}

export const addItemSalesGroupSF = ({ shortName, reducerObject }) => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_ADD_SG_SUCCESS,
        payload: { shortName, reducerObject }
    })
}

export const removeItemSalesGroupSF = ({ shortName, reducerObject }) => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_REMOVE_SG_SUCCESS,
        payload: { shortName, reducerObject }
    })
}

export const clearAllCusoSF = () => dispatch => {
    dispatch({
        type: selfAlertingConstants.SF_CLEAR_CUSO,
        payload: []
    });
}

export const resetSavedState = () => dispatch => {
    dispatch({
        type: selfAlertingConstants.SAVE_ALERT_SUCCESS,
        payload: {
            alert: {},
            status: null,
            message: null
        }
    });
}

export const resetSelfAlertState = () => dispatch => {
    dispatch({
        type: selfAlertingConstants.RESET_SELF_ALERT_STATE,
        payload: {}
    });
}

export const loadSelfAlertingMetadata = () => dispatch => {
    dispatch({
        type: selfAlertingConstants.LOADING,
        payload: true
    });

    return fetch(endpoints.selfalerting.metadata, {
        method: "GET",
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: selfAlertingConstants.LOAD_COMPARISION_METADATA,
                payload: {
                    data: d,
                    loading: false
                }
            });
        })
        .catch(err => {
            dispatch({
                type: selfAlertingConstants.LOAD_COMPARISION_METADATA,
                payload: {
                    data: {},
                    loading: false
                }
            });
        })
}

export const loadAllAlerts = () => dispatch => {
    dispatch({
        type: selfAlertingConstants.ALERTS_LOADING,
        payload: { loading: true }
    });

    return fetch(endpoints.selfalerting.alerts, {
        method: "GET",
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: selfAlertingConstants.GET_SAVED_ALERTS,
                payload: {
                    data: d,
                    loading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: selfAlertingConstants.GET_SAVED_ALERTS,
                payload: {
                    data: {},
                    loading: false
                }
            });
        })
}

export const deleteSelfAlert = variables => dispatch => {
    return fetch(`${endpoints.selfalerting.alerts}/${variables.alertId}`, {
        method: "DELETE",
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: selfAlertingConstants.DELETE_SELF_ALERT,
                    payload: variables
                });
            }
        }).catch(e => {
            dispatch({
                type: selfAlertingConstants.DELETE_SELF_ALERT,
                payload: {}
            });
        })

}

export const saveAlertService = (variables) => dispatch => {
    dispatch({
        type: selfAlertingConstants.SAVE_ALERT_LOADER,
        payload: true
    });
    return fetch(endpoints.selfalerting.alerts, {
        method: "PUT",
        body: variables,
    })
        .then(d => {
            if (d.status === 200) {
                d.json()
                    .then(response => {
                        dispatch({
                            type: selfAlertingConstants.SAVE_ALERT_SUCCESS,
                            payload: {
                                alert: {
                                    ...response,
                                    isEditableByCurrentUser: true
                                },
                                status: "success",
                                message: "Successfully Saved"
                            }
                        });
                        dispatch({
                            type: selfAlertingConstants.SAVE_ALERT_LOADER,
                            payload: false
                        });
                    })
            } else {
                throw new Error();
            }
        })
        .catch(() => {
            dispatch({
                type: selfAlertingConstants.SAVE_ALERT_FAILED,
                payload: {
                    alert: undefined,
                    status: "error",
                    message: "Failed to Save Alert"
                }
            });
            dispatch({
                type: selfAlertingConstants.SAVE_ALERT_LOADER,
                payload: false
            });
        })
}