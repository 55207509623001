import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, makeStyles, Collapse, Hidden } from '@material-ui/core';
import Card from "components/common/Card";
import { PlanningDetailsChartBuilder } from 'components/common/PlanningDetailsChartBuilder';
import { AppointmentDetailsChartBuilder } from 'components/common/AppointmentDetailsChartBuilder';
import { SuspendedDetailsChartBuilder } from 'components/common/SuspendedDetailsChartBuilder';
import { DeliveryDetailsChartBuilder } from 'components/common/DeliveryDetailsChartBuilder';
import transportFilters from 'reduxLib/constdata/transportFilters';
import { defaultTransportFilterArgs } from "reduxLib/constdata/filters"
import { tableoptions } from 'helpers/tableStyleOverride';
import TableComponent from "components/common/TableComponent";
import TransportDetails, { columns as transportDetailsColumns } from "components/common/Table/TransportDetails";
import { viewStyles } from "theme"
import ChartComponent from "components/common/ChartComponent";
import { defaultTransportColumns } from "reduxLib/constdata/transportColumns"
import ChartCarouselComponent from "components/common/ChartCarouselComponent";
import { ShipmentProcessingChartBuilder } from 'components/common/ShipmentProcessingChartBuilder';
import { CarrierCommittedChartBuilder } from 'components/common/CarrierCommittedChartBuilder';
import Expander from 'components/common/Elements/Expander';
import { endpoints, defaultShowTabsBy } from "helpers";
import { get, uniq } from "lodash";
import { connect } from "react-redux";
import BreadCrumb from 'components/common/Elements/BreadCrumb';

const useStyles = makeStyles(viewStyles);

export const conditionalFilterReplacement = (newfilters) => {
  if (get(newfilters, "orderExecutionBucket", []).length === 0) {
    newfilters.orderExecutionBucket = defaultTransportFilterArgs.orderExecutionBucket;
  }

  if (newfilters?.orderExecutionBucket?.includes("TRANS_PLAN_PROCESSING")) {
    const transporcessing = ["TRANS_PLAN_PLANNED", "TRANS_PLAN_TENDERED", "TRANS_PLAN_TENDER_REJECTED"]
    newfilters.orderExecutionBucket = uniq([...newfilters.orderExecutionBucket, ...transporcessing]).filter(d => d !== "TRANS_PLAN_PROCESSING");
  }
}

export const tableStyleConfig = {
  ...tableoptions,
  rowStyle: (d) => {
    const healthStatusBucket = d.orderExecutionHealth ? d.orderExecutionHealth : "";
    return {
      ...tableoptions.rowStyle,
      borderLeft: healthStatusBucket === "RED" ? '5px solid #FF4081' : healthStatusBucket === "YELLOW" ? '5px solid #F5B023' : ''
    }
  },
}

const Transport = ({ name, selections, showTabsBy, ...rest }) => {

  const { t } = useTranslation();
  const pageTitle = t('transportation_breadcrumb_text');

  const { type } = rest?.match?.params;

  // const items = getTabsData(selections[showTabsBy], viewByConstants[showTabsBy]);

  // const globalFilterSelection = generateHeader({ selections, type, showTabsBy, item: items.filter(d => d["custom"]["id"] === type)?.[0] });


  const classes = useStyles();
  const height = 160;
  const margins = { top: 30, right: 10, bottom: 40, left: 50, margin: 10 };
  const [expand, setExpand] = React.useState(false);
  const tableRef = React.createRef();

  const chartComponentDetails = [
    {
      name: t('planning_status'),
      componentName: PlanningDetailsChartBuilder,
      subtype: "TRANS_DETAIL_PLAN",
      showLegends: true,
    },
    {
      name: t('shipment_processing'),
      componentName: ShipmentProcessingChartBuilder,
      subtype: "TRANS_DETAIL_PLAN",
      showLegends: false,
      blockApi: true
    },
    {
      name: t('carrier_committed'),
      componentName: CarrierCommittedChartBuilder,
      subtype: "TRANS_DETAIL_PLAN",
      showLegends: false,
      blockApi: true
    },
    {
      name: t('transportation_execution_status'),
      componentName: DeliveryDetailsChartBuilder,
      subtype: "TRANS_DETAIL_EXEC",
      showLegends: false
    },
    {
      name: t('suspended_status'),
      componentName: SuspendedDetailsChartBuilder,
      subtype: "TRANS_DETAIL_SUSPENDED_STATUS",
      showOnExpand: true,
      showLegends: false
    },
    {
      name: t('appointment_status'),
      componentName: AppointmentDetailsChartBuilder,
      subtype: "TRANS_DETAIL_APPT_STATUS",
      showOnExpand: true,
      showLegends: false
    },
  ]

  const setDetails = (...args) => {
    rest.setDetails(...args, "transport")
 };

  return (
    <div className={classes.separator} data-testid="transportview" key={type}>
      {/* <Breadcrumb setDetails={setDetails} type={type} label={pageTitle} /> */}
      <BreadCrumb />
      <Card
        cardtitle={
          <Typography variant="h1" color="primary">
            {t('transportation_details')}
          </Typography>
        }
      >
        {/* Desktop view */}
        <Hidden smDown>
          <Grid container className={classes.grid} spacing={2}>
            {chartComponentDetails && chartComponentDetails.map((chartType) => (
              !chartType.showOnExpand && <Grid item md={6} lg={3}>
                <ChartComponent
                  blockApi={chartType.blockApi}
                  showLegends={chartType.showLegends}
                  innercard divider
                  BuilderComponent={chartType.componentName}
                  {...rest} name={chartType.name}
                  setDetails={setDetails}
                  type={type}
                  subtype={chartType.subtype}
                  height={height}
                  margins={margins}
                />
              </Grid>
            ))}
          </Grid>
          <Collapse in={expand}>
            <Grid className={classes.grid} container spacing={2}>
              {chartComponentDetails && chartComponentDetails.map((chartType) => (
                chartType.showOnExpand && <Grid className={classes.marginTop} item md={6} lg={4}>
                  <ChartComponent
                    showLegends={chartType.showLegends}
                    innercard divider
                    BuilderComponent={chartType.componentName}
                    {...rest} name={chartType.name}
                    setDetails={setDetails}
                    type={type}
                    subtype={chartType.subtype}
                    height={height}
                    margins={margins}
                  />
                </Grid>
              ))}
            </Grid>
          </Collapse>
          <Expander expand={expand} change={setExpand} />
        </Hidden>

        {/* Mobile view */}
        <Hidden mdUp>
          <ChartCarouselComponent chartsArray=
            {chartComponentDetails && chartComponentDetails.map((chartType) => (
              <Grid item xs={12} sm={12}>
                <ChartComponent
                  blockApi={chartType.blockApi}
                  showLegends={chartType.showLegends}
                  innercard divider
                  BuilderComponent={chartType.componentName}
                  {...rest} name={chartType.name}
                  setDetails={setDetails}
                  type={type} subtype={chartType.subtype}
                  height={height}
                  margins={margins}
                />
              </Grid>
            ))}
          />
        </Hidden>
      </Card>
      <Card >
        <TableComponent {...rest}
          pageTitle={pageTitle}
          tableName="transport"
          title={t('transport_title')}
          excludeArray={["searchStringList", "loadReadyDateTimeOriginTZ"]}
          searchTextPlaceholder={t('search_helper_text_transportation_page')}
          type={type}
          pageDetails={{}}
          tableRef={tableRef}
          ItemDetailSection={TransportDetails}
          itemDetailsColumns={transportDetailsColumns}
          defaultFilterArgs={defaultTransportFilterArgs}
          columns={defaultTransportColumns}
          conditionalFilterReplacement={conditionalFilterReplacement}
          filterBody={transportFilters}
          filterType="transportfilters"
          topDateFilter={{
            title: t('carrier_ready_date'),
            key: "loadReadyDateTimeOriginTZ"
          }}
          fetchEndPoint={endpoints.table.transport}
          tableStyleConfig={tableStyleConfig}
          setDetails={setDetails}
        />
      </Card>
    </div>
  );
}

const mapStateToProps = state => {
  const showTabsBy = defaultShowTabsBy(state);
  return {
    stopfavload: state.items.stopfavload,
    selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
    showTabsBy,
    blockOnTabChange: get(state, 'items.blockOnTabChange', false)
  }
}


export default connect(mapStateToProps, null)(Transport);