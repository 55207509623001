export default {
    SHOW_HIDE_FAVOURITES: "SHOW_HIDE_FAVOURITES",
    CHANGE_THEME: "CHANGE_THEME",
    SET_FILTERS: "SET_FILTERS",
    UNSET_FILTERS: "UNSET_FILTERS",
    RESET_FILTERS: "RESET_FILTERS",
    SAVE_COLUMN_STATE: "SAVE_COLUMN_STATE",
    RESET_COLUMN_STATE: "RESET_COLUMN_STATE",
    SET_VIEW_BY: "SET_VIEW_BY",
    SET_TABLE_BODY: "SET_TABLE_BODY"
}
