import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  generatePath,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams
} from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import useSetQuery from 'customHooks/useQuery';
import MillSvg from '../../assets/images/mill.svg';
import DCSvg from '../../assets/images/dc.svg';
import LAOTabsBar from 'components/EMEA/EMEATabsBar';


const LAOTabsWrapper = ({ region }) => {
  
  const [{ ...params }] = useSetQuery();
  const [subtabs, setSubTabs] = useState(null);
  const { tab } = useParams();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { locations } = useSelector(({ laoSites }) => laoSites);  

  const onTabChange = (e, value) => {
    history.replace({
      ...location,
      pathname: generatePath(match.path, { tab: value })
    });
  };

  useDeepCompareEffect(() => {
    const tabs = params.sites?.map(d => {
      let site = locations.find(item => item.siteNum === d)
      return {
        icon: site?.type === 'MILL' ? MillSvg : DCSvg,
        name: `${site?.shortName} (${d})`,
        siteNum: site?.siteNum,
        id: d
      }
    });
    setSubTabs(tabs);
    return;
  }, [params.sites, locations]);

  return (
    <div data-testid="laotabs-wrapper">
      <LAOTabsBar
        mainTabs={[]}
        subTabs={subtabs}
        tab={tab}
        onTabChange={onTabChange}
        region={region}
      />
    </div>
  );
};

export default LAOTabsWrapper;
