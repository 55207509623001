import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Grid, IconButton, makeStyles, Box, useMediaQuery, useTheme, InputBase } from '@material-ui/core';
import GeoCharts from "../../theme/layouts/GeoChart";
import Card from "./Card";
import PerformanceMapTable from "./PerformanceMapTable";
import { Refresh as RefreshIcon, Search as SearchIcon } from '@material-ui/icons';
import { sortBy, isArray } from "lodash";
import { getPerformanceMapService } from "reduxLib/services/getLocationsService";
import { useSelector, useDispatch } from "react-redux";
import { getStatusData, searchKey } from "helpers";
import location from "assets/images/location.svg"
import table from "assets/images/table.svg"
import PerformanceFilters, { performanceConstants } from "./PerformanceFilters";
import RedStatusIcon from '../../assets/images/status_red.svg';
import GreenStatusIcon from '../../assets/images/status_green.svg';
import YellowStatusIcon from '../../assets/images/status_yellow.svg';
import PinkStatusIcon from '../../assets/images/status_pink.svg';
import GreyStatusIcon from '../../assets/images/status_grey.svg';
import { useAppContext } from 'context/AppContext';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    icongrid: {
        "& > button": {
            marginLeft: theme.spacing(1)
        }
    },
    active: {
        borderRadius: theme.spacing(0.75),
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1)
    },
    inactive: {
        padding: theme.spacing(1)
    },
    spining: {
        pointerEvents: "none",
        animation: "App-logo-spin infinite 1s linear !important"
    },
    spin: {
        cursor: "pointer",
    },
    search: {
        marginTop: theme.spacing(1),
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.dark,
        '&:hover': {
            backgroundColor: "#303850",
        },
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputInput: {
        paddingLeft: `calc(1em + ${theme.spacing(2.5)}px)`,
        paddingRight: theme.spacing(0.5),
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0
        },
    },
    inputRoot: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            paddingLeft: theme.spacing(4)
        },
    },
    dropdown: {
        position: 'absolute',
        zIndex: 100,
        borderRadius: '0 !important',
        width: 200,
        right: 0,
        justifyContent: 'left'
    },
}));

const PerformanceMap = (props) => {
    const { setDetails, type, ...rest } = props;
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
    const { refresh, globalFilterSelection:{ siteNums } } = useAppContext();
    const dispatch = useDispatch();
    const { performance: perfomanceStats, performance_loading: loading } = useSelector(({ sites }) => sites);
    const { tempBusinessUnit: businessUnits } = useSelector(({ favorites }) => favorites);

    // const [refresh, setRefresh] = React.useState(refreshData);
    const [currentTab, switchTab] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();
    const [perfState, setPerfData] = React.useState([]);
    const [focus, setFocus] = React.useState(false);
    const [globalSearch, setGlobalSearch] = React.useState("");
    const performanceStatusFilter = [
        {
            name: t('healthy'),
            checked: true,
            value: t(performanceConstants.HEALTHY),
            legend: t('ahead_more_than_3_hours'),
            iconurl: GreenStatusIcon
        },
        {
            name: t('monitor'),
            checked: true,
            value: t(performanceConstants.MONITOR),
            legend: t('ahead_below_3_hours'),
            iconurl: YellowStatusIcon
        },
        {
            name: t('behind'),
            checked: true,
            value: t(performanceConstants.BEHIND),
            legend: t('behind_less_than_6_hours'),
            iconurl: PinkStatusIcon
        },
        {
            name: t('critical'),
            checked: true,
            value: t(performanceConstants.CRITICAL),
            legend: t('behind_more_than_6_hours'),
            iconurl: RedStatusIcon
        },
        {
            name: t('no_data'),
            checked: false,
            value: t(performanceConstants.NODATA),
            legend: t('no_data_0_hour'),
            iconurl: GreyStatusIcon
        }
    ]
    const [filters, updateFilters] = useState(performanceStatusFilter);
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const setData = (data) => {
        setPerfData(data);
    }


    const generatePrfData = () => {
        let perfData = isArray(perfomanceStats) ? perfomanceStats : [];
        perfData = sortBy(perfData.map(d => {
            const data = getStatusData({
                ...d,
                actualHours: d.aheadOrBehind == "BEHIND" ? -(d.hours) : d.hours,
                hours: parseFloat(d.hours).toFixed(1)
            }, "actualHours");
            return {
                ...d,
                ...data,
                status: t(data?.status)
            };
        }), ['actualHours']);
        perfData = [...perfData.filter(site => site.raw_status !== performanceConstants.NODATA), ...perfData.filter(site => site.raw_status === performanceConstants.NODATA)];

        if (siteNums?.length > 0 && type === "mylocation") {
            perfData = perfData.filter(d => siteNums.includes(d.siteNum));
        }
        return perfData;
    }

    React.useEffect(() => {
        setGlobalSearch("");
        const perfdata = generatePrfData();
        setPerfData(perfdata);
    }, [perfomanceStats])

    const getPerformanceData = () => {
        dispatch(getPerformanceMapService({ businessUnit: businessUnits }));
    }

    React.useEffect(() => {
        // setRefresh(refreshData);
        updateFilters(performanceStatusFilter)
    }, [refresh, localStorage.getItem('i18nextLng')])




    const Title = () => {
        const [search, setSearch] = React.useState(globalSearch);



        const updatePerformanceData = (e, value) => {
            e.preventDefault();
            setGlobalSearch(value);
            const perfdata = generatePrfData();
            const filtereddata = perfdata.filter(d => {
                return searchKey(value, d)
            });
            setFocus(true);
            setPerfData(filtereddata)
        }

        return (
            <Grid container alignItems='center' justify="space-between" data-testid='performanceview'>
                <Grid item xs={12} sm={6} md={8} lg={6}>
                    {
                        <Typography variant="body1" color="primary" data-testid='performanceTitle'>
                            {t('performance_map_title')}
                        </Typography>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} justify='flex-end' spacing={2} container className={classes.icongrid}>
                    <Grid item xs={12} md={6} lg={5}>
                        <Box textAlign='center' aria-label="Refrsh Table" color="primary" className={classes.search} data-testid="performanceSearch">
                            <form onSubmit={e => updatePerformanceData(e, search)}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={t('location_search')}
                                    inputProps={{ "data-testid": "searchbox" }}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    autoFocus={focus}
                                    value={search}
                                    onChange={e => {
                                        setSearch(e.target.value);
                                        if (e.target.value === "") {
                                            updatePerformanceData(e, "");
                                        }
                                    }}
                                />
                            </form>
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box textAlign='center' aria-label="Refresh Table" color="primary">
                            <IconButton className={classes.inactive} data-testid='refreshicon'>
                                <RefreshIcon className={loading ? classes.spining : classes.spin} onClick={() => getPerformanceData()} />
                            </IconButton>
                            {!isMobile && <Typography variant='body2'>{t('refresh')}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item >
                        <PerformanceFilters
                            data={perfState}
                            setData={setData}
                            filters={filters}
                            updateFilters={updateFilters}
                            open={open}
                            handleClick={handleClick}
                            handleClickAway={handleClickAway}
                            perfData={generatePrfData()}
                            search={globalSearch}
                            searchKey={searchKey}
                        />
                    </Grid>
                    <Grid item >
                        <Box textAlign='center' aria-label="Refrsh Table" color="primary">
                            <IconButton className={currentTab === 0 ? classes.active : classes.inactive}
                                data-testid='mapicon'
                                onClick={() => switchTab(0)}>
                                <img src={location}></img>
                            </IconButton>
                            {!isMobile && <Typography variant='body2'>{t('map_view')}</Typography>}
                        </Box>
                    </Grid>
                    <Grid item >
                        <Box textAlign='center' aria-label="Refrsh Table" color="primary">
                            <IconButton className={currentTab === 1 ? classes.active : classes.inactive}
                                data-testid='tableicon'
                                onClick={() => switchTab(1)}>
                                <img src={table}></img>
                            </IconButton>
                            {!isMobile && <Typography variant='body2'>{t('table_view')}</Typography>}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <Card cardtitle={<Title />}	>
            {currentTab === 0 ?
                <GeoCharts data={perfState} setDetails={setDetails} type={type} perfTableRedirection={rest.perfTableRedirection} /> :
                <PerformanceMapTable data={perfState} setDetails={setDetails} type={type} perfTableRedirection={rest.perfTableRedirection} />
            }
        </Card>
    );
}

export default PerformanceMap;
