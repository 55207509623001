import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import { get } from "lodash";
import { filterStyles } from "theme";
import { saveFilters } from "reduxLib/services";

const useStyles = makeStyles(filterStyles)


const FilterComponentContainer = (props) => {

    const { title, openAll, testId } = props;

    const [panel, openPanel] = React.useState(openAll);

    React.useEffect(() => {
        openPanel(openAll)
    }, [openAll]);


    const classes = useStyles();


    return (
        <div data-testid={testId} className={classes.border}>
            <Box alignItems="center" className={clsx(classes.container, classes.controls, !panel ? classes.openBox : classes.closedBox)}
                onClick={() => openPanel(!panel)}
            >
                <Grid container justify="space-between" spacing={1} data-testid={props.testid}>
                    <Grid lg={11} item style={{ marginTop: 10 }} className={classes.boxTitle}>
                        {title || "Title"}
                    </Grid>
                    <Grid lg={1} item style={{ marginTop: 10 }}>
                        <div
                            className={classes.expandButton}
                            onClick={() => {
                                openPanel(!panel);
                            }}>
                            {
                                !panel ? <ExpandMore className={classes.expandButton} /> : <ExpandLess className={classes.expandButton} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Collapse in={panel}>
                <Box className={classes.contentBox}>
                    {
                        props.children
                    }
                </Box>
            </Collapse>
        </div>

    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype } = ownProps;
    return {
        filters: get(state, `options.filters.${subtype}`, {})
    }
};

const mapDispatchToProps = {
    saveFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(FilterComponentContainer);
