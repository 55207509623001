import React from 'react';
import { useTranslation } from "react-i18next"
import {
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { downloadService } from 'reduxLib/services';
import ExcelIcon from 'assets/images/excel.png';
import Snack from '../Helpers/Snack';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: "flex",
        justifyContent: "end",
        visibility: (visible) => visible ? "visible": "hidden"
    }
}));

const DownloadSelfAlert = ({
    downloadConfig,
    config,
    visible
}) => {

    const { type, subtype, process, detailsProcess } = downloadConfig;
    const classes = useStyles(visible);
    const dispatch = useDispatch();
    const { downloading } = useSelector(({ downloads }) => get(downloads, [type, subtype], {}));

    const { t } = useTranslation();

    const [snack, setSnack] = React.useState({
        open: false,
        severity: null,
        message: null
    });

    const handleSnackClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const downloadExcel = () => {
        if (!downloading) {
            dispatch(downloadService({
                ...downloadConfig,
                downloadConfig,
                config,
                process,
                detailsProcess
            }));
            setSnack({
                open: true,
                severity: 'info',
                message: t("download_started_msg")
            });
        } else {
            setSnack({
                open: true,
                severity: 'warning',
                message: t("download_progress_msg")
            });
        }
    }

    return (
        <div item className={classes.root} data-testid="download-self-alert-table" justify="flex-start">
            <IconButton
                aria-label="ExportTable"
                color="primary"  
                data-testid="ExportTable"
                onClick={() => downloadExcel()}>
                <img src={ExcelIcon} alt="CSV" />
            </IconButton>
            <Snack {...snack} handleClose={handleSnackClose} />
        </div>
    );
};


export default DownloadSelfAlert;
