import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { withOktaAuth } from '@okta/okta-react';
import { addUser } from "../../reduxLib/services";
import FeedBack from "../../components/common/FeedBack";
import TranslationDropDown from "../../components/translation/TranslationDropDown";
import CurrencyDropDown from "components/EMEA/CurrencyDropDown";
import MoreIcon from '@material-ui/icons/MoreVert';
import MobileMenuProfile from './MobileMenuProfile';
import FeedbackIcon from '@material-ui/icons/Feedback';
import EmeaHamburger from './EMEA/EmeaHamburger';
import maestro_logo_light from '../../assets/images/maestro_logo_light.svg';
import maestro_logo_light_mobile from '../../assets/images/maestro_logo_light_mobile.svg';
import maestro_logo_dark from '../../assets/images/maestro_logo_dark.svg';
import { appbarStyles } from "theme";

const useStyles = makeStyles(appbarStyles);

const useStyles2 = makeStyles(theme => ({
  logo: {
    height: '62px',
    width: '326px',    
    backgroundSize: '95%',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(22.5),
      height: theme.spacing(4.5)
    }
  },
  logoLight: {
    background: `url(${maestro_logo_light}) no-repeat`,
    [theme.breakpoints.down('sm')]: {
      background: `url(${maestro_logo_light_mobile}) no-repeat`,
    }
  },
  logoDark: {
    background: `url(${maestro_logo_dark}) no-repeat`,
    backgroundSize: '95%',
  },
  tooltipStyles: {
    backgroundColor: theme?.palette?.tooltip?.paleBlue,
    color: theme?.palette?.secondary?.pressed
  }
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme?.palette?.tooltip?.paleBlue,
    color: theme?.palette?.secondary?.pressed,
    borderRadius: theme.spacing(1),
  },
  arrow: {
    color: theme?.palette?.tooltip?.paleBlue,
  },
  cursor: 'pointer'
}))(Tooltip);

const Appbar = props => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const dispatch = useDispatch();
  const { authService, region } = props;
  const user = useSelector(({ auth }) => auth.user);
  const { countries } = useSelector(({ emeaSites }) => emeaSites);
  const { t } = useTranslation();
  const username = user ? `${user.given_name} ${user.family_name}` : "   ";
  const anchorRef = useRef(null);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [countryList, setCountryList] = useState(countries || []);

  const CustomTooltip = () => {
    return (
      <LightTooltip
        arrow
        enterTouchDelay='10'
        data-testid="profilehtooltip"
        leaveDelay={250}
        disableHoverListener={region === 'LAO'}
        title={<Grid>
          <Typography>{`${t("authorized_countries")}: ${countryList.join(', ')}`}</Typography>
        </Grid>}
      >
        <Badge color="secondary">
          <Avatar className={classes.avatar}>
            <Typography variant="h1" className={classes.avatarText}>
              {`${username.split(" ")[0].charAt(0)}${username.split(" ")[1].charAt(0)}`}
            </Typography>
          </Avatar>
        </Badge>
      </LightTooltip>
    )
  }

  global.authService = authService;

  useEffect(() => {
    setCountryList(countries);
  }, [countries]);

  useEffect(() => {
    if (!user) {
      authService.getUser()
        .then(d => 
          dispatch(addUser(d))
        )
        .catch(err => 
          dispatch(addUser(null))
        );
    } else {
      window.FS.identify(user.sub, {
        displayName: username
      });
    }
  }, [user, authService]);

  return(
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.app}>
        <Toolbar className={classes.toolbar}>
          {region === 'EMEA' && <EmeaHamburger /> }
          <Typography className={classes.title} variant="h3" >
            <Box data-testid="KCLogo" className={[classes2.logo, region === 'LAO' ? classes2.logoDark : classes2.logoLight]} />
          </Typography>
          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {region === 'LAO' && <Box className={classes.center} >
              <TranslationDropDown region={region} />
            </Box>}
            {
              region === 'EMEA' && 
              <Box className={classes.center} >
                <CurrencyDropDown />
              </Box> 
            }
            <Box className={classes.center}>
              <CustomTooltip />
              <Typography variant="h6" className={classes.username} data-testid="username"> { username } </Typography>
            </Box>
            <Box className={classes.center}>
              <Button onClick={() => setOpenFeedback(!openFeedback)} >
                <FeedbackIcon fontSize="medium" color='primary' />
                <Typography variant="body1" className={classes.username} data-testid="userFeedback">{t('feedback')}</Typography>
              </Button>
            </Box>
          </div>

          <div className={classes.sectionMobile}>
            <Box >
              <CustomTooltip />
            </Box>
            <IconButton onClick={() => setOpenMobileMenu(!openMobileMenu)} aria-label="show more" aria-haspopup="true" color="primary" ref={anchorRef} >
              <MoreIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>

      <MobileMenuProfile
        open={openMobileMenu}
        setOpen={setOpenMobileMenu}
        openFeedback={openFeedback}
        setOpenFeedback={() => setOpenFeedback(!openFeedback)}
        username={username}
        anchorRef={anchorRef}
        region={region}
        countryList={countryList}
      />

      <FeedBack openFeedback={openFeedback} openAction={setOpenFeedback} />

    </div>
  )
}

export default (withOktaAuth(Appbar));