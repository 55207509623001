import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import KCChip from 'components/common/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { chipsMain, generateChipsData } from 'helpers/laoHelpers';
import useSetQuery, { useUpdateTabWitOptions } from 'customHooks/useQuery';
import { businessUnits } from 'configs/appConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0px ${theme.spacing(2)}px`
  },
  chips: {},
  label: {
    margin: theme.spacing(1)
  },
  chip: {
    borderRadius: theme.spacing(4)
  },
  clearAll: {
    color: theme.palette.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginTLeft: theme.spacing(2)
  },
  popper: {
    maxWidth: 300,
    maxHeight: 300,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    overflow: 'auto',
    border: `1px solid ${theme.palette.black}`,
    boxShadow: '0px 7px 7px -2px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.secondary.base
  }
}));

const ChipsMulti = ({ chips, clearAllChips, clearChip, chipKey }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <>
      <KCChip
        deleteIcon={<CloseIcon data-testid={'clear-chips'}/>}
        className={classes.chip}
        label={`${chips[0].name} + ${chips.length - 1} ${t('more')}`}
        color="primary"
        onDelete={clearAllChips}
        onClick={handleClick}
        variant="outlined"
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'bottm-right'}
        transition>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper className={classes.popper}>
            {chips.map((d) => {
              return (
                <KCChip
                  deleteIcon={<CloseIcon data-testid={`delete-${d.id}`}/>}
                  className={classes.chip}
                  label={d.name}
                  color="primary"
                  onDelete={d.onClose ? () => clearChip({ chip: d, key: chipKey }) : false}
                  variant="outlined"
                />
              );
            })}
            <Typography
              component={'div'}
              className={classes.clearAll}
              onClick={() => clearAllChips(chipKey)}
              data-testid={'clear-all-chips'}
            >
              {t('clear_all')}
            </Typography>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const LAOFilterChips = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tab } = useParams();
  const updateTabWithOptions = useUpdateTabWitOptions();
  const [{ BU, sites }, setParams] = useSetQuery();
  const { locations } = useSelector(({ laoSites }) => laoSites);
  const [chips, setChips] = useState(() => generateChipsData({ sites, viewby: "sites", tab, locations }));
  const [defaultChips, setDefaultChips] = useState(() => chipsMain({ BU }));

  useDeepCompareEffect(() => {
    setChips(generateChipsData({ sites, viewby: "sites", tab, locations }))
  }, [sites, tab, locations]);

  useDeepCompareEffect(() => {
    setDefaultChips(chipsMain({ BU }))
}, [BU]);

  const updateValue = ({ key, value }) => {
    setParams({ key, value });
  }

  const clearAllChips = (key) => {
    switch (key) {
      case "locations": {
        updateTabWithOptions({ key: "sites", value: [] }, {}, [])
        return;
      }
    }
  }

  const clearChip = ({ key, chip }) => {
    switch (key) {
      case "business": {
          updateValue({ key: "BU", value: JSON.stringify(businessUnits.filter(e => e != chip.id)) })
          return;
      }
      case "locations": {
        const filteredSites = sites?.filter(e => e != chip.id)
        updateTabWithOptions({ key: "sites", value: filteredSites }, {}, filteredSites)
        return;
      }
    }
  }

  return (
    <>
      <Grid container className={classes.root} data-testid="lao-filterchips">
        {Object.keys(defaultChips).map((d) => {
          if (!defaultChips[d]?.length) return null;
          return (
            <>
              <Box m={2} alignItems="center" className={classes.chips}>
                <Typography
                  variant="p"
                  component={'div'}
                  className={classes.label}>
                  {t(d)}
                </Typography>
                {defaultChips[d].map((e) => {
                  return (
                    <KCChip
                      deleteIcon={<CloseIcon data-testid={`delete-${e.name}`} />}
                      className={classes.chip}
                      label={e.name}
                      color="primary"
                      onDelete={e.onClose ? () => clearChip({ chip: e, key: d }) : false}
                      variant="outlined"
                    />
                  );
                })}
              </Box>
            </>
          );
        })}
        {Object.keys(chips).map((d) => {
          return (
            <>
              <Box m={2} alignItems="center" className={classes.chips}>
                <Typography
                  variant="p"
                  component={'div'}
                  className={classes.label}>
                  {t(d)}
                </Typography>
                {
                  chips[d].length === 1 && <KCChip
                    deleteIcon={<CloseIcon data-testid={`delete-${chips[d][0].id}`}/>}
                    className={classes.chip}
                    label={t(chips[d][0].name)}
                    color="primary"
                    onDelete={chips[d][0].onClose ? () => clearChip({ chip: chips[d][0], key: d }) : false}
                    variant="outlined"
                  />
                }
                {
                  chips[d].length > 1 && <ChipsMulti
                    chips={chips[d]}
                    tab={tab}
                    viewby={"sites"}
                    clearAllChips={() => clearAllChips(d)}
                    clearChip={clearChip}
                    chipKey={d}
                  />
                }
              </Box>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default LAOFilterChips;
