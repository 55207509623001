export default {
    LOADING: 'SELF_ALERTING_LOADING',
    LOAD_COMPARISION_METADATA: 'LOAD_COMPARISION_METADATA',
    GET_SAVED_ALERTS: "GET_SAVED_ALERTS",
    SAVE_SLEF_ALERTING_FILTERS: "SAVE_SLEF_ALERTING_FILTERS",
    SF_ADD_CUSO_SUCCESS: "SF_ADD_CUSO_SUCCESS",
    SF_REMOVE_CUSO_SUCCESS: "SF_REMOVE_CUSO_SUCCESS",
    SF_CLEAR_CUSO: "SF_CLEAR_CUSO",
    SF_REMOVE_SG_SUCCESS: "SF_REMOVE_SG_SUCCESS",
    SF_ADD_SG_SUCCESS: "SF_ADD_SG_SUCCESS",
    SAVE_ALERT_SUCCESS: "SAVE_ALERT_SUCCESS",
    ALERTS_LOADING: "ALERTS_LOADING",
    SAVE_ALERT_FAILED: "SAVE_ALERT_FAILED",
    DELETE_SELF_ALERT: "DELETE_SELF_ALERT",
    SF_ADD_CUSO_MULTIPLE_SUCCESS: "SF_ADD_CUSO_MULTIPLE_SUCCESS",
    RESET_SELF_ALERT_STATE: "RESET_SELF_ALERT_STATE",
    SAVE_ALERT_LOADER: "SAVE_ALERT_LOADER"
}