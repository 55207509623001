import React from 'react';
import { Tooltip, Typography, Grid, makeStyles } from '@material-ui/core';
import { ErrorOutlineOutlined, WarningOutlined } from '@material-ui/icons';
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import { isEmpty } from "lodash"
import { tableHealthCodeObject } from "../../reduxLib/constdata/filters"
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    container: ({healthCode}) => ({
        margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
        padding: `0px ${theme.spacing(1)}px`,
        borderLeft: `6px solid ${healthCode === "RED" ? theme.palette?.chartColors?.unhealthy : healthCode=== "YELLOW" ? theme?.palette?.chartColors?.risk : theme.palette.white }`,
    }),
    tooltip: {
        backgroundColor: theme.palette.white,
        color: 'black',
        maxWidth: 350,
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid ${theme.palette.iron}`,
        minWidth: 190,
    },
    arrow:{
        color: theme.palette.white,
    },
    bluesquare: {
        color: theme.palette?.chartColors?.healthy,
    },
    redsquare: {
        color: theme.palette?.chartColors?.unhealthy,
    },
    yellowsquare: {
        color: theme.palette?.chartColors?.risk,
    },
}))

export const OrderHealthTooltip = ({ data, tableName }) => {

    const tableHealthKey = tableHealthCodeObject[tableName]
    const rowHealthCodes = data[tableHealthKey.healthCodeArrayKey]
    const classes = useStyles({healthCode:data[tableHealthKey.healthKey]});
    const { t } = useTranslation();

    return (
        <>
            { (data[tableHealthKey.healthKey] !== "GREEN" && rowHealthCodes?.length > 0) && <Tooltip enterTouchDelay='10' data-testid="orderhealthtooltip" arrow classes={
                {
                    container:classes.container,
                    tooltip:classes.tooltip,
                    arrow:classes.arrow
                }
            } interactive leaveDelay={250} title={
                <Grid >
                    <Grid className={classes.container}>
                        {rowHealthCodes && !isEmpty(rowHealthCodes) && rowHealthCodes.map((code) => (
                            <Typography variant="body1" color="inherit" gutterBottom>
                                {t(orderHealthReasonCodeMap?.[code] || code)}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            } >
                {
                    
                    data[tableHealthKey.healthKey] === "RED" ? <ErrorOutlineOutlined className={classes.redsquare} /> :
                    data[tableHealthKey.healthKey] === "YELLOW" ? <WarningOutlined className={classes.yellowsquare} /> :
                    ""
                }
            </Tooltip>
            }
        </>
    );
}

