import React from 'react'
import Axis from './Axis'

const Axes = ({
  scales, margins, svgDimensions, wrapit, horizontal,
  diverging, margin, divergingScales, yLabel, xLabel, showEllipsis,
  suffix, ...rest
}) => {
  const { height, width } = svgDimensions

  const xProps = {
    ...rest,
    orient: 'Bottom',
    scale: scales.xScale,
    translate:  diverging? `translate(0,${margin.top})` : `translate(0, ${height - margins.bottom})`,
    tickSize: height - margins.top - margins.bottom,
    svgDimensions,
    wrapit,
    horizontal,
    diverging,
    scales,
    divergingScales,
    xLabel,
    margins,
    showEllipsis
  }

  const yProps = {
    ...rest,
    orient: 'Left',
    scale: scales.yScale,
    translate: diverging? `translate(${scales.xScale(0)},0)` : `translate(${margins.left}, 0)`,
    tickSize: width - margins.left - margins.right,
    svgDimensions,
    horizontal,
    diverging,
    scales,
    divergingScales,
    yLabel,
    margins,
    suffix
  }

  return (
    <g>
      <Axis {...xProps} />
      <Axis {...yProps} />
    </g>
  )
}

export default Axes;
