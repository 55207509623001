import React from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import {Favorite } from '@material-ui/icons';
import KCChip from "../common/Chip";
import {
    setBusinessService,
    setUnfavService,
    setFavCUSOService
} from "reduxLib/services";
import { isEmpty, uniq, concat } from "lodash";
import FavSvg from "assets/images/FavSvg.svg"

// Exporting Style
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    item: {
        margin: 'auto'
    },
    img: {
        textAlign: 'center',
        padding: 30,
        color: theme.palette?.white
    },
    soChip: {
        margin: `${theme.spacing(1.25)}px ${theme.spacing(0.5)}px ${theme.spacing(2)}px`
    }
}));

export const FavBlock = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <figure className={classes.img} data-testid="favblock">
        <img src={FavSvg} style={{ width: '80%' }} />
        <p>
          {t('favourites_message')}
        </p>
      </figure>
    )
}

export default () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { businessUnits, favorites: favitems, favCuso } = useSelector(({ favorites }) => favorites);
    const classes = useStyles();
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

    const sales_org = uniq(favCuso.FAV_SALES_OFFICE.map(({ salesOrg }) => salesOrg));
    const favCust = favCuso.FAV_CUST?.map(d => ({...d, salesOrg: [d.salesOrg]}));

    return (
        <Grid className={classes.root} data-testid="favmodule">
            <Box mt={2}>
            { isEmpty(businessUnits) && isEmpty(favitems) && isEmpty(favCuso.CUST) && isEmpty(favCuso.FAV_SALES_OFFICE) ? isMobile ? <FavBlock/> : <Typography variant="subtitle1" align="center">{t("you_do_not_have_any_favourites")}</Typography> : <Typography variant="h4">{t("list_of_favourited_selections")}</Typography>}
            {
                businessUnits.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="buFavs">
                    <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2}>
                        <Typography variant="h4">{t('business_unit')}</Typography>
                    </Grid>
                    <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                        {
                            businessUnits.map(d => {
                                return (<KCChip label={d} deleteIcon={<Favorite color='primary' id={d} data-testid="deleteFavBU"/>} onDelete={() => {
                                    dispatch(setBusinessService({ value: { value: d }, type: "unfav", bunits: businessUnits }));
                                }} />)
                            })
                        }
                    </Grid>
                </Grid>
            }
            {
                favitems.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="locationFavs">
                    <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2} >
                        <Typography variant="h4">{t('location')}</Typography>
                    </Grid>
                    <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                        {
                            favitems.map(d => {
                                return (<KCChip label={d.shortName} id="locationFavChip" name={d.shortName}
                                    deleteIcon={<Favorite id={d.shortName} data-testid="deleteFavLoc" color='primary' />} onDelete={() => {
                                        dispatch(setUnfavService({ variables: d }));
                                    }}
                                />)
                            })
                        }
                    </Grid>
                </Grid>
            }
            {
                favCuso.FAV_CUST.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="customerFavs">
                    <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2} >
                        <Typography variant="h4">{t('customer')}</Typography>
                    </Grid>
                    <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                        {
                            favCust.map(d => {
                                return (<KCChip name={d.customerName + "(" + d.salesOrg + ")"} id="customerFavChip" label={d.customerName + "(" + d.salesOrg + ")"}
                                    deleteIcon={<Favorite id={d.customerName + "(" + d.salesOrg + ")"} data-testid="deleteFavCust" color='primary' />} onDelete={() => {
                                        dispatch(setFavCUSOService({ variables: { shortName: d.customerName, reducerObject: d, state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE) }}));
                                    }}
                                />)
                            })
                        }
                    </Grid>
                </Grid>
            }
            {
                favCuso.FAV_SALES_OFFICE.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="salesFavs">
                    <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2} >
                        <Typography variant="h4">{t('sales_office_group')}</Typography>
                    </Grid>
                    {
                        sales_org.length > 0 &&
                        <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                            {sales_org.map(org => {
                            return( 
                            <div data-testid={"Sales Org " + org} id={org}>
                            <Typography variant="body1">{`${t('sales_org')} ${org}`}</Typography>
                            {
                                (favCuso.FAV_SALES_OFFICE.filter(SO => SO.salesOrg === org)).map(d => 
                                    {
                                    return (
                                        d.salesGroup ? 
                                        d.salesGroup.map(SG => { return (
                                            <KCChip id="salesGrpFavChip" name={SG + " (" + d.salesOffice + ")"} label={SG + " (" + d.salesOffice + ")"} className={classes.soChip}
                                                deleteIcon={<Favorite id={SG + " (" + d.salesOffice + ")"} data-testid="deleteFavSO" color='primary' />} onDelete={() => {
                                                    dispatch(setFavCUSOService({ variables: { type: "SALES_GROUP", shortName: SG, reducerObject: d, state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE) }}));
                                            }}/>
                                            )}) :
                                            <KCChip id="salesOfcFavChip" name={d.salesOffice} label={d.salesOffice} className={classes.soChip}
                                                deleteIcon={<Favorite id={d.salesOffice} data-testid="deleteFavSO" color='primary' />} onDelete={() => {
                                                    dispatch(setFavCUSOService({ variables: { shortName: d.salesOffice, reducerObject: d, state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE) }}));
                                            }}/>
                                    )
                                    })
                            }
                            </div>
                            )}
                            )}
                        </Grid>
                    }
                </Grid>
            }
            </Box>
        </Grid>
    )
}

