import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveWrapper from "../charts/ResponsiveWrapper";
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  skeleton: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2)
  },
  loader: {
  }
}));

const SkeletonLoader = ({ dimensions, parentWidth }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    
    <div data-testid="skeletonLoader" className={classes.loader} style={{ height: dimensions.height + 6}}>
        <Typography color="primary">
        {t("please_wait")}
          </Typography> 

      </div>
  );
}

export default ResponsiveWrapper(SkeletonLoader);
