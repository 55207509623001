import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import PublicIcon from '@material-ui/icons/Public';
import FeedbackIcon from '@material-ui/icons/Feedback';
import TranslationDropDown from "../../components/translation/TranslationDropDown";
import CurrencyDropDown from "components/EMEA/CurrencyDropDown";

const useStyles = makeStyles((theme) => ({
    popper: {
        display: 'flex',
        backgroundColor: theme.palette.extra?.mobileProfile,
        borderRight: 'none',
        top: theme.spacing(8),
        zIndex: 100
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    center: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    avatar: {
        background: 'transparent',
        border: `2px solid ${theme.palette.white}`,
        color: theme.palette.white,
        marginRight: theme.spacing(.5),
    },
    avatarText: {
        color: theme.palette.white,
    },
    common: {
        marginRight: theme.spacing(1.5),
        color: theme.palette.white
    }
}));

export default function MenuListComposition(props) {
    const {
        anchorRef, open, setOpen,
        setOpenFeedback,
        username,
        region
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();


    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
            <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <Box className={classes.center}>
                    <Badge>
                        <Avatar className={classes.avatar}>
                            <Typography variant="h1" className={classes.avatarText} color="primary">
                                { `${username.split(" ")[0].charAt(0)}${username.split(" ")[1].charAt(0)}` }
                            </Typography>
                        </Avatar>
                    </Badge>
                    <Typography variant="h6" className={classes.username} color="primary">{username}</Typography>
                </Box>
                <Divider />
                <MenuItem >
                    <PublicIcon fontSize="medium" className={classes.avatarText} />
                    { region === 'LAO' && <TranslationDropDown region={region} /> }
                    { region === 'EMEA' && <CurrencyDropDown /> }
                </MenuItem>
                <Divider />
                <MenuItem onClick={setOpenFeedback}>
                    <FeedbackIcon fontSize="medium" className={classes.common} />
                    <Typography variant="body1" className={classes.username} color="primary">{t("feedback")}</Typography>
                </MenuItem>
            </MenuList>
        </Popper>
    );
}
