import reprintConstants from "../constants/reprintConstants"

const reprintReducer = (state = {
    loading: false,
    status: {},
    precheckLoading: false,
    precheck: {},
    uclLoading: false,
    addrLoading: false,
    labelPrinted: false
}, action) => {

    switch (action.type) {

        case reprintConstants.REPRINT_LSR_LOADING:
            return { ...state, loading: action.payload };

        case reprintConstants.REPRINT_PRECHECK_LOADING:
            return { ...state, precheckLoading: action.payload };

        case reprintConstants.RESET_REPRINT_STATUS:
            return { ...state, status: {} };

        case reprintConstants.RESET_PRECHECK_STATUS:
            return { ...state, precheck: {} };

        case reprintConstants.REPRINT_LSR_SUCCESS:
        case reprintConstants.REPRINT_LSR_FAILED:
            return { 
                ...state, 
                loading: false,
                status: action.payload
            }

        case reprintConstants.REPRINT_PRECHECK_SUCCESS:
        case reprintConstants.REPRINT_PRECHECK_FAILED:
            return { 
                ...state, 
                precheckLoading: false,
                labelPrinted: action.payload?.labelPrinted,
                precheck: action.payload 
            }

        case reprintConstants.PRINT_UCL_LOADING:
            return { ...state, uclLoading: action.payload };
        
        case reprintConstants.PRINT_ADDR_LOADING:
            return { ...state, addrLoading: action.payload };

        case reprintConstants.PRINT_ADDR_SUCCESS:
        case reprintConstants.PRINT_ADDR_FAILED:
            return { 
                ...state, 
                addrLoading: false,
                status: action.payload
            }

        case reprintConstants.PRINT_UCL_SUCCESS:
        case reprintConstants.PRINT_UCL_FAILED:
            return { 
                ...state, 
                uclLoading: false,
                status: action.payload
            }

        default:
            return state
    }
}

export default reprintReducer;