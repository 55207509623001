import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import GeoMap from "../../theme/layouts/GeoMap";
import pickup from "../../assets/images/markerP.svg";
import delivery from "../../assets/images/markerD.svg";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.secondary.dark,
    maxHeight: 448,
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,
    },
    '&::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.secondary.dark}`,
      borderRadius: 0,
      background: theme.palette.divider
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.divider,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.white}`
    },
    [theme.breakpoints.down("sm")]:{
      padding:theme.spacing(2),
    }
  },
  nodata: {
    padding: theme.spacing(3)
  },
  locations: {
    padding: theme.spacing(0.5)
  },
  container: {
    minHeight: theme.spacing(60)
  },
  mapcontainer: {
    marginTop: theme.spacing(2),
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,
    },
    '&::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.secondary.dark}`,
      borderRadius: 0,
      background: theme.palette.divider
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.divider,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.white}`
    },
    maxHeight: 448,
    [theme.breakpoints.down("sm")]:{
      minHeight:448,
      order:-1
    }
  },
}));

const ShipmentTrackingDetails = ({ shipmentTrackingData, type }) => {

  const colorObj = {
    PICKUP: {
      "start": pickup,

    },
    DELIVERY: {
      "stop": delivery,

    },
  }

  const formattedLocationHistory = shipmentTrackingData?.stops?.map((stop) => ({
      status: stop.stopNum === 1 ? 'start' : 'stop',
      type: stop.stopType,
      shortName:stop.location.name,
      latitude: stop.location.geoLocation.latitude,
      longitude:stop.location.geoLocation.longitude,
  }))

  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container data-testid="trackingdetails">
      <Typography variant="h3"> {t("tracking_details")} </Typography>
      <Grid container item className={classes.container} spacing={4}>
        <Grid container item xs={12} md={6}>
          <Grid container alignContent='flex-start' item className={classes.root}>
            {isEmpty(shipmentTrackingData) || isEmpty(shipmentTrackingData.locationHistory) ? (
              <Grid item xs={12} className={classes.nodata}>
                <Typography color="primary" align="center" data-testid="no_data_tracking_details">{t("no_data_found")}</Typography>
              </Grid>
            ) : (
                shipmentTrackingData.locationHistory.map((item, index) => (
                  <Grid container item xs={12} key={index} spacing={2} className={classes.locations}>
                    <Grid item xs={6}>
                      <Typography variant="h6" data-testid="timestamp_tracking">
                        {moment(item.timestamp)?.tz(shipmentTrackingData.origin?.timezone)?.format(`MMM DD YYYY HH:mm z`)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" data-testid="location_tracking">
                        {item.city || "-"}, {item.state || "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              )}
          </Grid>
        </Grid>
        <Grid className={classes.mapcontainer} item xs={12} md={6}>
          <GeoMap parentHeight={416} colorObj={colorObj} disableMarkerLegend markers={formattedLocationHistory} disableMarkerInfo performanceData={shipmentTrackingData?.locationHistory} showPath type={type} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShipmentTrackingDetails;
