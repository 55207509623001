import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, IconButton, Button, Backdrop, Modal, Box, TextField, Grid, Divider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CloseOutlined } from '@material-ui/icons';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import SubmitLDIcon from "../../assets/images/submit_ld_icon.svg";
import clsx from 'clsx';
import { osmTableActionsStyles } from "theme";
import OsmDropdown from './OsmDropdown';
import { osmLoadingInstructions, osmDiagramType } from 'configs/appConstants';
import { getTrailerDimensionService, loadDiagramService, resetSubmitLD, submitLDPreCheckService, resetTrailerDimensions,
        resetSubmitLDPreCheck, getDeliveryCommentService, resetDeliveryComment } from "../../reduxLib/services/loadDiagramService";
import Snack from "../common/Helpers/Snack";
import { isEmpty, map, find, invert, reject } from 'lodash';
import InfoIcon from "../../assets/images/info_icon.svg";
import SubmitLDDisabledIcon from "../../assets/images/submit_simulation.svg";

const useStyles = makeStyles(osmTableActionsStyles);

const useStyles2 = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalbody: {
    background: theme.palette.chip.backgroundDefault,
    boxShadow: theme.shadows[5],
    width: "90%",
    position: "relative",
    // minHeight: '50vh',
    // maxHeight: '90vh',
    // overflow: "auto",
    // padding: theme.spacing(2),
    '&:focus-visible': {
      outline: 'none'
    }
  },
  progress: {
    backgroundColor: theme.palette?.secondary?.accent
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // marginBottom: theme.spacing(1.25),
    color: theme.palette?.primary.contrastText,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  form: {
    minHeight: '50vh',
    maxHeight: '90vh',
    overflow: "auto",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  inputBox: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette?.primary.main,
    color: theme.palette?.primary.contrastText,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.75)
  },
  label: {
    marginRight: theme.spacing(6),
  },
  textinput: {
    fontSize: 16,
  },
  itemGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  mainBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette?.primary.main,
    color: theme.palette?.primary.contrastText,
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(0.75)
  },
  containerGrid: {
    paddingTop: theme.spacing(1),
  },
  divider: {
    backgroundColor: theme.palette?.background.primaryBase,
    // margin: `${theme.spacing(0.5)}px ${theme.spacing(4)}px`
  },
  box: {
    width: 200,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(0.5)}px`,
    border: `1px solid ${theme.palette?.primary.contrastText}`,
    borderRadius: theme.spacing(0.5)
  },
  textfield: {
    '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline:hover': {
      width: '85%',
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px`,
      borderRadius: theme.spacing(0.5),  
      borderColor: `${theme.palette?.primary.contrastText} !important`,
    },
    '& .MuiInputBase-input': {
      boxSizing: 'border-box'
    }
  },
  bottomPadding: {
    paddingBottom: theme.spacing(1)
  },
  addIcon: {
    color: theme.palette?.extra.info,
    // marginRight: theme.spacing(1),
    // cursor: 'pointer'
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    }
  },
  commentGrid: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  commentsIcon: {
    color: theme.palette?.extra.info,
    marginRight: theme.spacing(1)
  },
  comments: {
    display: 'flex',
    padding: theme.spacing(.5),
    whiteSpace: 'pre-line'
  },
  submit: {
    '&.MuiButton-outlined': {
      border: `1px solid ${theme.palette?.primary.contrastText}`
    },
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette?.background.selected
    }
  },
  horizontalDivider: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    width: '80%',
    backgroundColor: theme.palette?.background.primaryBase
  },
  removeIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette?.extra.info,
    cursor: 'pointer'
  },
  unitGrid: {
    display: 'inline-grid',
    justifyContent: 'start'
  },
  disabled: {
    color: theme.palette?.legendColors.greyLight
  },
  optionalText: {
    paddingLeft: theme.spacing(1),
    textTransform: 'none',
    fontStyle: 'italic'
  },
  errorText: {
    color: theme.palette?.text?.error,
    display: 'inline-flex',
    alignItems: 'flex-end'
  },
  loader: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadText: {
    fontSize: 18
  },
  heightInfo: {
    marginLeft: theme.spacing(1),
    color: theme.palette?.tooltip?.base
  },
  compWidth: {
    width: 100
  }
}));

const SubmitLoadDiagram = ({ openLDModal, selectedRow, tableLoading }) => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const envArray = ["development", "test", "local"];
  const { trailer_dimension_loading, trailer_dimension, trailer_dimension_err, load_diagram, load_diagram_loading, 
          ld_precheck_loading, ld_precheck, dlv_comment_loading, dlv_comment, dlv_comment_err } = useSelector(({ loadDiagram }) => loadDiagram);
  const loadInstructionsArray = Object.values(osmLoadingInstructions);
  const diagramTypeArray = Object.values(osmDiagramType);
  const measurementUnitArray = ['Inches'];
  const vehiclesArray = map(trailer_dimension, 'vehicleID');
  // const deliveryNums = dlv_comment || [];
  // const trailerObj = [{name: 'compartmentID', id_1: 'trailer-compartmentID-1', id_2: 'trailer-compartmentID-2'}, 
  //                     {name: 'length', id_1: 'trailer-length-1', id_2: 'trailer-length-2'}, 
  //                     {name: 'frontHeight', id_1: 'trailer-frontHeight-1', id_2: 'trailer-frontHeight-2'}, 
  //                     {name: 'width', id_1: 'trailer-width-1', id_2: 'trailer-width-2'}]
  const trailerAdditionalArr = ['compartmentID', 'length', 'frontHeight', 'width'];
  const decimalRegex = /^\d{1,3}(\.\d{0,2})?$/

  const [open, setOpen] = useState(openLDModal);
  const [loadInstruction, setLoadInstruction] = useState(loadInstructionsArray[2]);
  const [diagramType, setDiagramType] = useState(diagramTypeArray[0]);
  const [vehicle, setVehicle] = useState("");
  const [deliveryNums, setDeliveryNums] = useState([]);
  let dimensions = find(trailer_dimension, {vehicleID: vehicle});
  const [vehicleDimension, setVehicleDimension] = useState(dimensions?.trailerDimension);
  const [measurementUnit, setmeasurementUnit] = useState([measurementUnitArray[0]]);
  const [rowCount, setRowCount] = useState([]);
  const [rowIndex, setRowIndex] = useState(3);
  const [lsr, setLsr] = useState(1);
  const [lsrError, setLsrError] = useState('');
  const [reportDest, setReportDest] = useState();
  const [dimension_1, setDimension_1] = useState({});
  const [dimension_2, setDimension_2] = useState({});
  const [dimension_3, setDimension_3] = useState({});
  const [dimension_4, setDimension_4] = useState({});
  const [dimensionError, setDimensionError] = useState([]);
  const [heightErrorMsg, setHeightErrorMsg] = useState([]);
  // const [diagramTypeError, setDiagramTypeError] = useState(null);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  })

  const getTrailerDimension = () => {
    dispatch(getTrailerDimensionService({
      body: {
        scac: selectedRow?.carrierCodeLD
      }
    }));
  }

  const handlePreCheck = () => {
    const preCheckBody = { 
      shipmentNum: selectedRow?.shipmentNum,
      region: "NA"
     };
    dispatch(submitLDPreCheckService(preCheckBody));
  };

  const handleModalOpen = () => {
    setOpen(true);
  }

  const handleModalClose = () => {
    setOpen(false);
    setDimension_1(isEmpty(vehicleDimension?.[0]) ? {"compartmentID": "1", "length": 0, "frontHeight": 0, "backHeight": 0, "width": 0} : vehicleDimension?.[0]);
    setDimension_2(isEmpty(vehicleDimension?.[1]) ? {"compartmentID": "2", "length": 0, "frontHeight": 0, "backHeight": 0, "width": 0} : vehicleDimension?.[1]);
    setDimension_3({});
    setDimension_4({});
    setReportDest();
    setLsr(1);
    setLsrError('');
    setRowIndex(3);
    setRowCount([]);
    setVehicle("");
    setDeliveryNums([]);
    setDimensionError([]);
    setHeightErrorMsg([]);
    // setDiagramTypeError(null);
    setDiagramType(diagramTypeArray[0]);
    setLoadInstruction(loadInstructionsArray[2]);
    dispatch(resetDeliveryComment());
    dispatch(resetTrailerDimensions());
  };

  const handleSnackClose = (event, reason) => {
    setSnack({
      ...snack,
      open: false
    });
  }

  const handleLoadInstructionChange = (event) => {
    setLoadInstruction(event.target.value);
  };

  const handleDiagramTypeChange = (event) => {
    setDiagramType(event.target.value);
    // if (event.target.value === "MFS Modified Fail-Safe Routine") {
    //   setDiagramTypeError(true)
    // } else {
    //   setDiagramTypeError(false)
    // }
  };

  const handleVehicleChange = (event) => {
    setVehicle(event.target.value);
  };

  const handleMeasurementUnitChange = (event) => {
    setmeasurementUnit(event.target.value);
  };

  const handleLsrChange = (event) => {
    if(event.target.value == '0' || event.target.value == '1' || event.target.value == '2') {
      setLsr(event.target.value);
      setLsrError("");
    } else {
      setLsr(event.target.value);
      setLsrError("Please enter a valid number between 0-2");
    }
  }

  const addRowFunc = () => {
    if (rowIndex == 3) setDimension_3({"compartmentID": "3", "length": 0, "frontHeight": 0, "width": 0});
    else if (rowIndex == 4) setDimension_4({"compartmentID": "4", "length": 0, "frontHeight": 0, "backHeight": 0, "width": 0});
    setRowIndex(rowIndex + 1);
    rowCount.push(rowIndex);
    setRowCount(rowCount);
  }

  // const removeRowFunc = (index) => {
  //   setRowIndex(rowIndex - 1);
  //   rowCount.pop();
  //   setRowCount(rowCount);
  // }
 
  const submitLD = (event) => {
    event.preventDefault();
    let LDbody = {
      region: "NA",
      loadDiagramSimulation: false,
      freightOrder: selectedRow?.shipmentNum,
      loadingInstruction: invert(osmLoadingInstructions)[loadInstruction],
      diagramType: invert(osmDiagramType)[diagramType],
      carrierCode: selectedRow?.carrierCodeLD,
      vehicleID: vehicle,
      reportDestination: reportDest,
      LSRCopies: lsr,
      trailerDimension: [
        {...dimension_1, length: Number(dimension_1.length), frontHeight: Number(dimension_1.frontHeight), backHeight: Number(dimension_1.frontHeight), width: Number(dimension_1.width)}, 
        {...dimension_2, length: Number(dimension_2.length), frontHeight: Number(dimension_2.frontHeight), backHeight: Number(dimension_2.frontHeight), width: Number(dimension_2.width)},
      ]
    }
    if (!isEmpty(dimension_3)) {
      LDbody = {...LDbody, trailerDimension: [
        ...LDbody.trailerDimension,
        {...dimension_3, length: Number(dimension_3.length), frontHeight: Number(dimension_3.frontHeight), backHeight: Number(dimension_3.frontHeight), width: Number(dimension_3.width)}
      ]}
    }
    if (!isEmpty(dimension_4)) {
      LDbody = {...LDbody, trailerDimension: [
        ...LDbody.trailerDimension,
        {...dimension_4, length: Number(dimension_4.length), frontHeight: Number(dimension_4.frontHeight), backHeight: Number(dimension_4.frontHeight), width: Number(dimension_4.width)}
      ]}
    }
    dispatch(loadDiagramService(LDbody));
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 350,
        minWidth: 250,
      },
      'data-testid': 'dropdownMenu'
    },
    getContentAnchorEl: null,
    autoFocus: false,
    anchorOrigin: {
      vertical: 60,
      horizontal: 0
    },
    transformOrigin: {
      vertical: 0,
      horizontal: 0
    }
  };

  useEffect(() => {
    if (!isEmpty(dlv_comment)) {
      setDeliveryNums(dlv_comment);
    }
  }, [dlv_comment]);

  useEffect(() => {
    if (!isEmpty(ld_precheck?.defaultPrinter)) {
      setReportDest(ld_precheck?.defaultPrinter);
    }
  }, [ld_precheck?.defaultPrinter]);

  // useEffect(() => {
  //   if (!isEmpty(selectedRow?.shipmentNum)) {
  //     dispatch(getDeliveryCommentService( { freightOrder: selectedRow?.shipmentNum } ));
  //   }
  // }, [selectedRow?.shipmentNum]);

  useEffect(() => {
    setVehicleDimension(dimensions?.trailerDimension);
    setDimension_1(isEmpty(vehicleDimension?.[0]) ? {"compartmentID": "1", "length": 0, "frontHeight": 0, "backHeight": 0, "width": 0} : 
                            {...vehicleDimension?.[0], "frontHeight": selectedRow?.custHeightRestriction ? selectedRow.custHeightRestriction : vehicleDimension?.[0]?.frontHeight });
    setDimension_2(isEmpty(vehicleDimension?.[1]) ? {"compartmentID": "2", "length": 0, "frontHeight": 0, "backHeight": 0, "width": 0} : 
                            {...vehicleDimension?.[1], "frontHeight": selectedRow?.custHeightRestriction ? selectedRow.custHeightRestriction : vehicleDimension?.[1]?.frontHeight });
  }, [vehicle, dimensions, vehicleDimension, trailer_dimension]);

  useEffect(() => {
    if (!ld_precheck_loading && ld_precheck) {
      if (ld_precheck?.status === 'success' && (selectedRow?.equipmentType === 'PKG' || selectedRow?.shippingCondFromShipment === 'PG')) {
        const autoLDbody = {
          region: "NA",
          loadDiagramSimulation: false,
          freightOrder: selectedRow?.shipmentNum,
          loadingInstruction: "P_PACKED_LOADING",
          diagramType: "FSR_FAILE_SAFE",
          carrierCode: selectedRow?.carrierCodeLD,
          vehicleID: "PKG",
          reportDestination: '',
          LSRCopies: 0,
          trailerDimension: [{
            compartmentID: "1",
            length: 630, 
            frontHeight: 108, 
            backHeight: 108, 
            width: 98
          }]
        }
        dispatch(loadDiagramService(autoLDbody));
      } else if (ld_precheck?.status === 'success') {
        getTrailerDimension();
        dispatch(getDeliveryCommentService( { freightOrder: selectedRow?.shipmentNum } ));
        handleModalOpen();
      } else {
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          message: (ld_precheck?.status === "invalid_shipment") ? t("invalid_shipment_error_text") : (ld_precheck?.status === "seal_empty") ? t("seal_empty_error_text") : t("ld_precheck_error_text")
        });
      }
      dispatch(resetSubmitLDPreCheck());
    }
  }, [ld_precheck_loading, ld_precheck])

  useEffect(() => {
    if (!load_diagram_loading && load_diagram) {
      setSnack({
        ...snack,
        open: true,
        severity: load_diagram === "success" ? "success" : "error",
        message: load_diagram === "success" ? t("load_diagram_submitted_successfully") : t("load_diagram_submission_failed")
      });

      dispatch(resetSubmitLD());
      if (load_diagram === 'success') {
        handleModalClose();
      }
    }
  }, [load_diagram_loading, load_diagram])

  return (
    <>
      { envArray.includes(process.env.REACT_APP_ENV) ?
        <Button className={classes.actions} data-testid="submit_ld_icon" onClick={handlePreCheck} disabled={tableLoading}>
          <img src={SubmitLDIcon} alt="Submit LD" />
          <Typography variant='body1'> { t('submit_ld') } </Typography>
        </Button> :
        <Button className={classes.actions} data-testid="submit_ld_icon_diabled" disabled={true}>
          <img src={SubmitLDDisabledIcon} alt="Submit LD" />
          <Typography variant='body1'> { t('submit_ld') } </Typography>
        </Button>
      }
      <Modal
        aria-labelledby="submitLD-modal-title"
        aria-describedby="submitLD-modal-description"
        open={open}
        className={classes2.modal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes2.modalbody} data-testid="submit_ld_modal" >
          {load_diagram_loading && <LinearProgress className={classes2.progress} /> }
          <Box className={classes2.title}>
            <Typography variant='h3'>{ t('load_diagram_submission') }</Typography>
            <IconButton
              data-testid="close-LD-modal"
              onClick={handleModalClose}
              className={classes2.closeicon}
              variant="outlined" size="small">
              <CloseOutlined fontSize="small" />
            </IconButton>
          </Box>
          { (trailer_dimension_loading || dlv_comment_loading) ? 
            <Grid className={classes2.loader}>
              <Typography className={classes2.loadText} data-testid="submitLdLoadingText"> {t('submit_ld_loading_text')} </Typography>
            </Grid>
            :
            <form data-testid="ldSubmitForm" className={classes2.form} onSubmit={(e) => submitLD(e)}>
              <Box className={classes2.inputBox} data-testid="freight_order">
                <Typography variant='h6' className={classes2.label}> { t('shipment_num_freight_order') } </Typography>
                <TextField className={classes2.textinput} id="freight-order" variant="outlined" value={selectedRow?.shipmentNum} disabled/>
              </Box>

              <Grid container className={classes2.inputBox}>
                <Grid item sm={12} md={6} className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                  <Typography variant='h6' className={classes2.label}> { t('loading_instructions') } </Typography>
                  <OsmDropdown testId="loadInstructionsSelect" width={250} data={loadInstructionsArray} MenuProps={MenuProps} initialValue={loadInstruction} onChange={handleLoadInstructionChange} />
                </Grid>
                <Grid item sm={12} md={6} className={classes2.itemGrid}>
                  <Typography variant='h6' className={classes2.label}> { t('diagram_type') } </Typography>
                  <OsmDropdown testId="diagramTypeSelect" data={diagramTypeArray} MenuProps={MenuProps} initialValue={diagramType} onChange={handleDiagramTypeChange} />
                </Grid>
                <Grid item xs={6}></Grid>
                {/* <Grid item xs={6}>
                  { diagramTypeError ? <Typography variant='body1' className={classes2.errorText}> { t('diagram_type_error') } </Typography> : null }
                </Grid> */}
              </Grid>

              <Box className={classes2.mainBox}>
                <Typography variant='h4'> { t('trailer_dimensions') } </Typography>
                <Grid container className={classes2.containerGrid}>
                  <Grid item md={6} lg={4} className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                    <Typography variant='h6' className={classes2.label}> { t('carrier_scac') } </Typography>
                    <TextField className={classes2.textinput} id="trailer-dimensions" variant="outlined" value={selectedRow?.carrierCodeLD} disabled/>
                  </Grid>
                  <Grid item md={6} lg={4} className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                    <Typography variant='h6' className={classes2.label}> { `${t('vehicle')}*` } </Typography>
                    { trailer_dimension_err ? 
                      <Typography variant='body1' className={classes2.errorText}> { t('trailer_dimension_error') } </Typography> :
                      isEmpty(vehiclesArray) ? 
                        <TextField className={classes2.textinput} data-testid="noVehicleText" variant="outlined" value={t('no_vehicles_found')} disabled/> :
                        <OsmDropdown testId="vehicleSelect" width={200} data={vehiclesArray} MenuProps={MenuProps} initialValue={vehicle} onChange={handleVehicleChange} placeholder={true} placeholderText={t('select_vehicle')}/>
                    }
                  </Grid>
                  <Grid item md={12} lg={4} className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                    <Typography variant='h6' className={classes2.label}> { t('measurement_unit') } </Typography>
                    <OsmDropdown testId="measurementUnitSelect" width={200} data={measurementUnitArray} MenuProps={MenuProps} initialValue={measurementUnit} onChange={handleMeasurementUnitChange} />
                  </Grid>
                </Grid>
                {selectedRow?.custHeightRestriction && <Grid container>
                  <img src={InfoIcon} alt='heightInfo' />
                  <Typography variant='h6' className={classes2.heightInfo}> { t('default_height_info', { height: selectedRow?.custHeightRestriction }) } </Typography>
                </Grid>}

                  {/* <Grid item md={12} lg={3}>
                    <Typography variant='h6' className={classes2.bottomPadding}> { t('measurement_unit') } </Typography>
                    <OsmDropdown testId="measurementUnitSelect" width={200} data={measurementUnitArray} MenuProps={MenuProps} initialValue={measurementUnit} onChange={handleMeasurementUnitChange} />
                  </Grid> */}

                <Grid container className={classes2.containerGrid}>
                  { trailerAdditionalArr.map((item) => 
                    <Grid item sm={3} lg={2} className={clsx(classes2.containerGrid, classes2.unitGrid)}>
                      <Typography variant='h6' className={classes2.bottomPadding}> { t(item) } </Typography>
                      <Box display='inline-grid'>
                        {item == 'compartmentID'  ?
                          <div className={clsx(classes2.modal, classes2.compWidth)} data-testid={item + '-1'} > {dimension_1?.[item]} </div>
                        :
                        <TextField className={classes2.textfield} inputProps={{'data-testid': item + '-1'}} variant="outlined" value={dimension_1?.[item]} placeholder={t('enter_number')}
                          onChange={(e) => {
                            if (decimalRegex.test(e.target.value)) {
                              setDimension_1({...dimension_1, [item]: (e.target.value)})
                              const err = isEmpty(dimensionError) ? [] : reject(dimensionError, {name: item, compt: "Compartment 1"})
                              setDimensionError(err)
                              if (item === 'frontHeight') {
                                const x = Number(e.target.value);
                                const warn = reject(heightErrorMsg, {name: item, compt: "Compartment 1"});
                                if (x > selectedRow?.custHeightRestriction) { warn.push({name: item, compt: "Compartment 1"}) }
                                setHeightErrorMsg(warn)
                              } 
                            } else {
                              setDimension_1({...dimension_1, [item]: (e.target.value)})
                              const err = reject(dimensionError, {name: item, compt: "Compartment 1"})
                              err.push({name: item, compt: "Compartment 1"})
                              setDimensionError(err)
                            }
                          }} /> 
                        }
                      </Box>
                    </Grid>
                  )}
                  </Grid>

                  <Grid container className={classes2.containerGrid}>
                    { trailerAdditionalArr.map((item) => 
                      <Grid item sm={3} lg={2} className={clsx(classes2.containerGrid, classes2.unitGrid)}>
                        <Box display='inline-grid'>
                          {item == 'compartmentID'  ?
                            <div className={clsx(classes2.modal, classes2.compWidth)} data-testid={item + '-2'} > {dimension_2?.[item]} </div>
                            :
                            <TextField className={classes2.textfield} inputProps={{'data-testid': item + '-2'}} variant="outlined" value={dimension_2?.[item]} placeholder={t('enter_number')}
                              onChange={(e) => {
                                if (decimalRegex.test(e.target.value)) {
                                  setDimension_2({...dimension_2, [item]: (e.target.value)})
                                  const err = isEmpty(dimensionError) ? [] : reject(dimensionError, {name: item, compt: "Compartment 2"})
                                  setDimensionError(err)
                                  if (item === 'frontHeight') {
                                    const x = Number(e.target.value);
                                    const warn = reject(heightErrorMsg, {name: item, compt: "Compartment 2"})
                                    if (x > selectedRow?.custHeightRestriction) { warn.push({name: item, compt: "Compartment 2"}) }
                                    setHeightErrorMsg(warn)
                                  }
                                } else {
                                  setDimension_2({...dimension_2, [item]: (e.target.value)})
                                  const err = reject(dimensionError, {name: item, compt: "Compartment 2"})
                                  err.push({name: item, compt: "Compartment 2"})
                                  setDimensionError(err)
                                }
                              }} />                      
                          }
                        </Box>
                      </Grid>
                    )}                  
                  </Grid>

                { (rowCount.length > 0) && rowCount.map((item) => 
                  <Grid container className={classes2.containerGrid}>
                    {/* <Grid item xs={3} ></Grid> */}
                    { trailerAdditionalArr.map(d => 
                      <Grid item sm={3} lg={2} className={clsx(classes2.containerGrid, classes2.unitGrid)}>
                        <Box display='inline-grid'>
                          { (item == 3) ? 
                          (d == 'compartmentID' ? <div className={clsx(classes2.modal, classes2.compWidth)} data-testid={d + '-' + item} > 3 </div> :
                          <TextField className={classes2.textfield} inputProps={{'data-testid': d + '-' + item}} variant="outlined" placeholder={t('enter_number')} value={dimension_3?.[d]}
                            onChange={(e) => {
                              if (decimalRegex.test(e.target.value)) {
                                setDimension_3({...dimension_3, [d]: e.target.value})
                                const err = isEmpty(dimensionError) ? [] : reject(dimensionError, {name: d, compt: "Compartment 3"})
                                setDimensionError(err)
                                if (d === 'frontHeight') {
                                  const x = Number(e.target.value);
                                  const warn = reject(heightErrorMsg, {name: d, compt: "Compartment 3"})
                                  if (x > selectedRow?.custHeightRestriction) { warn.push({name: d, compt: "Compartment 3"}) }
                                  setHeightErrorMsg(warn)
                                }
                              } else {
                                setDimension_3({...dimension_3, [d]: e.target.value})
                                const err = reject(dimensionError, {name: d, compt: "Compartment 3"})
                                err.push({name: d, compt: "Compartment 3"})
                                setDimensionError(err)
                              }
                            }}
                          />) :
                          (d == 'compartmentID' ? <div className={clsx(classes2.modal, classes2.compWidth)} data-testid={d + '-' + item} > 4 </div> :
                          <TextField className={classes2.textfield} inputProps={{'data-testid': d + '-' + item}} variant="outlined" placeholder={t('enter_number')} value={dimension_4?.[d]}
                            onChange={(e) => {
                              if (decimalRegex.test(e.target.value)) {
                                setDimension_4({...dimension_4, [d]: e.target.value})
                                const err = isEmpty(dimensionError) ? [] : reject(dimensionError, {name: d, compt: "Compartment 4"})
                                setDimensionError(err)
                                if (d === 'frontHeight') {
                                  const x = Number(e.target.value);
                                  const warn = reject(heightErrorMsg, {name: d, compt: "Compartment 4"})
                                  if (x > selectedRow?.custHeightRestriction) { warn.push({name: d, compt: "Compartment 4"}) }
                                  setHeightErrorMsg(warn)
                                }
                              } else {
                                setDimension_4({...dimension_4, [d]: e.target.value})
                                const err = reject(dimensionError, {name: d, compt: "Compartment 4"})
                                err.push({name: d, compt: "Compartment 4"})
                                setDimensionError(err)
                              }
                            }}
                          />)
                          }
                        </Box>
                      </Grid>
                    ) }                
                    {/* <Grid item className={classes2.removeIcon}>
                      <CloseOutlined onClick={() => removeRowFunc(item)} data-testid={`remove-row-icon-${item}`} />
                    </Grid> */}
                  </Grid>
                )}

                <Grid container className={classes2.containerGrid}>
                  { !isEmpty(heightErrorMsg) ? heightErrorMsg.map(warn => <Grid xs={12} item className={classes2.itemGrid}>
                      <Typography variant='body1' className={classes2.errorText}> 
                        <ReportProblemOutlinedIcon /> &nbsp;
                        {warn?.compt} : {t('height_restriction_error', { height: selectedRow?.custHeightRestriction })} 
                      </Typography>
                    </Grid>) : null 
                  }
                  { !isEmpty(dimensionError) ? dimensionError.map(warn => <Grid xs={12} item className={classes2.itemGrid}>
                      <Typography variant='body1' className={classes2.errorText}> 
                        <ReportProblemOutlinedIcon /> &nbsp;
                        {t('trailer_input_error')} { warn?.compt } - { t(warn?.name) } 
                      </Typography>
                    </Grid>) : null 
                  }
                  <Grid xs={12} item className={classes2.itemGrid}>
                  <IconButton className={classes2.addIcon} disabled={!(rowIndex <= 4)} data-testid="add-row-icon" onClick={addRowFunc} disableFocusRipple={true} disableRipple={true}>
                      <AddCircleOutlineIcon />
                    </IconButton> 
                    <Typography variant='h5' className={!(rowIndex <= 4) && classes2.disabled}> {t('add_another_row')} </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes2.horizontalDivider} />
              </Box>

              <Box className={classes2.mainBox}>
                <Typography variant='h4'> { t('internal_material_handling_comments') } </Typography>
                <Grid container className={classes2.containerGrid}>
                  <Grid item xs={3}>
                    <Typography variant='h6'> { t('delivery') } </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant='h6'> { t('comments') } </Typography>
                  </Grid>
                </Grid> 
                { deliveryNums.length ? deliveryNums.map((delivery) => {
                  return (
                    <Grid container className={classes2.commentGrid}>
                      <Grid item xs={3}>
                        <TextField className={classes2.textinput} id={delivery.deliveryNum} variant="outlined" value={delivery.deliveryNum} disabled/>
                      </Grid>
                      <Grid item xs={9}>
                        <div className={classes2.comments}>
                          {(delivery?.comment?.length) ? 
                            <Typography>{delivery.comment?.join('\n')}</Typography>  :
                            <>
                              <ErrorOutlineIcon className={classes2.commentsIcon} />
                              <Typography>{t('no_material_handling_comments_found')}</Typography>
                            </>
                          }
                        </div>
                      </Grid>
                    </Grid>
                  )
                }) : 
                  dlv_comment_err ? 
                    <Typography variant='body1' className={classes2.errorText}> { t('delivery_comment_error') } </Typography> :
                    <Typography variant='caption' >{t('no_data_found')}</Typography>
                }
              </Box>

              <Box className={classes2.mainBox}>
                <Typography variant='h4' className={classes2.itemGrid} > { t('printing_options') } 
                  {/* <span className={classes2.optionalText} > { `(${t('use_if_a_hard_copy_of_lsr_is_required')})` } </span>  */}
                </Typography>
                <Grid container className={classes2.containerGrid}>
                  <Grid item sm={12} md={5} className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                    <Typography variant='h6' className={classes2.label}> { t('report_destination') } </Typography>
                    <Box >
                      <TextField className={classes2.textfield} inputProps={{'data-testid': "report-destination"}} variant="outlined" value={reportDest} placeholder={t('enter_printer_name')}
                        onChange={(e) => setReportDest(e.target.value)} />
                    </Box>
                  </Grid>
                  <Grid item className={clsx(classes2.itemGrid, classes2.bottomPadding)}>
                    <Typography variant='h6' className={classes2.label}> { t('lsr_copies') } </Typography>
                    <Box >
                      <TextField className={classes2.textfield} inputProps={{'data-testid': "lsr-copies"}} variant="outlined" value={lsr} error={!!lsrError} helperText={lsrError} 
                        onChange={(e) => handleLsrChange(e)} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Grid container justify='center'>
                <Button variant='outlined' className={classes2.submit} type='submit' data-testid="submit_ld_button" 
                  disabled={isEmpty(vehiclesArray) || isEmpty(vehicle) || !isEmpty(lsrError) || !isEmpty(dimensionError) || !isEmpty(heightErrorMsg) ||  load_diagram_loading}> 
                  { t('load_diagram_submission') } 
                </Button>
              </Grid>

            </form>
          }
        </div>
      </Modal>
      <Snack {...snack} handleClose={handleSnackClose}/>
    </>
  )
}

export default SubmitLoadDiagram;