import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { businessUnits } from "configs/appConstants";
import Snack from "components/common/Helpers/Snack";
import { isJsonParsable } from 'helpers';
import qs from 'query-string';
import FavoriteIcon from 'assets/images/favorite.svg';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import { useSelector, useDispatch } from 'react-redux';
import { laoSetBUFavourite } from 'reduxLib/services/LaoServices/laoFavService';

const useStyles = makeStyles((theme) => ({
    businessFilterGridLao: {
        display: 'flex',
        flexDirection: 'column',
        margin: `${theme.spacing(1.5)}px 0px`
    },
    businessLao: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`
    },
    checkboxLao: {
        padding: theme.spacing(0.25),
        color: theme.palette.white,
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette?.white
        }
    },
    favIcon: {
        padding: '2px',
        filter: 'invert(100%) sepia(7%) saturate(2%) hue-rotate(309deg) brightness(110%) contrast(101%)'
    }
}));

const LaoBusinessUnits = ({ region }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = qs.parse(location.search);

    const { loading, favouriteBusinessUnits } = useSelector(({ laoFavorites: { loading, favouriteBusinessUnits } }) => ({
        loading,
        favouriteBusinessUnits
    }));

    const [laoBUSelected, setLaoBUSelected] = useState(() => isJsonParsable(queryParams?.BU) || businessUnits);
    const [snack, setSnack] = React.useState({
        open: false,
        severity: null,
        message: null
    });

    const handleLaoBUSelect = (event, bu) => {
        if (event.target.checked === false && laoBUSelected.length === 1) {
            setSnack({
                open: true,
                severity: "warning",
                message: t("atleast_one_business_unit_needs_to_selected")
            });
            return;
        }

        if (event.target.checked) {
            setLaoBUSelected(Array.from(new Set([...laoBUSelected, bu])));
        } else {
            const x = laoBUSelected.filter(d => d !== bu);
            setLaoBUSelected(x);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };

    const handleLaoBUFav = (isFav, bu) => {
        if (isFav) {
            const y = favouriteBusinessUnits.filter(d => d !== bu);
            dispatch(laoSetBUFavourite(y));
            if(laoBUSelected.length > 1) {
                const x = laoBUSelected.filter(d => d !== bu);
                setLaoBUSelected(x);
            }            
        } else {
            const z = Array.from(new Set([...favouriteBusinessUnits, bu]));
            dispatch(laoSetBUFavourite(z));
            setLaoBUSelected(Array.from(new Set([...laoBUSelected, bu])));
        }
    }

    useEffect(() => {
        if (laoBUSelected.length === 0) setLaoBUSelected(businessUnits);
        history.push({
            ...location,
            search: qs.stringify({ ...queryParams, BU: JSON.stringify(laoBUSelected) })
        });
    }, [laoBUSelected])


    return (
        <Grid container data-testid='businessfilter' className={classes.businessFilterGridLao}>
            {businessUnits.map(bu => {
                const isFav = favouriteBusinessUnits.includes(bu);
                return (
                    <Grid container item sm={8} md={6} lg={4} className={classes.businessLao}>
                        <Typography variant='body1'> {bu} </Typography>
                        <Grid item>
                            <Checkbox
                                name={bu}
                                checked={laoBUSelected.indexOf(bu) > -1}
                                onChange={(e) => handleLaoBUSelect(e, bu)}
                                className={classes.checkboxLao}
                                inputProps={{ 'data-testid': `select-${bu}` }}
                            />
                            <IconButton
                                aria-label="fav-button"
                                onClick={() => handleLaoBUFav(isFav, bu)}
                                disabled={loading}
                                data-testid={`fav-${bu}`}
                                className={classes.favIcon}
                            >
                                <img alt="fav-icon"
                                    src={isFav ? FavoriteIcon : NotFavoriteIcon}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            })
            }
            <Snack {...snack} handleClose={handleClose} />
        </Grid>
    )

}

export default LaoBusinessUnits;