import React from "react";
import EMEAOpenOrdersInvoiceKPIs from "components/EMEA/EMEAOpenOrdersInvoiceKPIs";
import EMEAOpenOrdersStatus from "components/EMEA/EMEAOpenOrdersStatus";


const EMEAOpenOrdersInvoice = () => {

  return (
    <>
      <EMEAOpenOrdersStatus pageName="invoiced" />
      <EMEAOpenOrdersInvoiceKPIs />
    </>
  )
}

export default EMEAOpenOrdersInvoice;