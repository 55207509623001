export const TRANSLATIONS_FR = {
    "stock_constraint_report_title": "Rapport sur les contraintes de stock",
    "plant": "Planter",
    "load_date": "DATE DE CHARGEMENT",
    "search": "Rechercher",
    "shipment": "Expédition",
    "material": "Matériel",
    "suggested_inbound_shipments": "Envois entrants suggérés",
    "recommended_inbound_details": "Détails entrants recommandés",
    "outbound_title": "Sortants",
    "inbound_title": "Entrants",
    "load_start_date_time": "Date et heure de début du chargement",
    "incoming_stock": "Stock entrant",
    "unrestricted_quantity": "Quantité illimitée",
    "demand_quantity": "Quantité de demande",
    "no_record_found": "Aucun enregistrement trouvé",
    "transportation_to_be_planned": "Transport à planifier",
    "transportation_planning": "Planification des transports",
    "transportation_processing": "Traitement du transport",
    "transportation_carrier_committed": "Transporteur engagé",
    "checked_in_by_DC": "Enregistré par DC",
    "loading_started": "Chargement démarré",
    "ready_for_pickup": "Prêt pour le ramassage",
    "in_transit": "En transit",
    "delivery_confirmed": "Livraison confirmée",
    "in_yard": "Dans la cour",
    "unloading": "Déchargement",
    "unloading_completed": "Déchargement terminé",
    "material_#": "Matériel #",
    "total_demand_quantity": "Quantité de la demande totale",
    "total_unrestricted_quantity": "Quantité totale illimitée",
    "delivery_quantity": "Quantité livrée",
    "stock_balance": "Solde des stocks",
    "shortage_quantity": "Quantité de pénurie",
    "actively_tracked_by_fourkites_eta": "Suivi actif par Fourkites eta",
    "trailer": "Remorque",
    "carrier": "Transporteur",
    "shipment_#": "Expédition #",
    "destination_eta": "Destination ETA",
    "origin_eta": "Origine ETA",
    "shipment_end_date_&_time": "Date et heure de fin d'expédition",
    "estimated_unloading_time": "Temps de déchargement estimé (en heures)",
    "yes": "Oui",
    "no": "Non",
    "tracking_#": "Pistage #",
    "trailer_#": "Remorque #",
    "yard_arrival_date_&_time": "Date et heure d'arrivée dans la cour",
    "unloading_start_date_&_time": "Date et heure de début du déchargement",
    "distribution_breadcrumb_text": "Distribution",
    "network_breadcrumb_text": "Réseau",
    "order_management_breadcrumb_text": "Gestion des commandes",
    "transportation_breadcrumb_text": "Transport",
    "go_to_stock_constraint_report": "Aller au rapport sur les contraintes de stock",
    "live_status_text": "STATUT EN DIRECT",
    "shipment_created": "Envoi créé",
    "shipment_status": "Statut de l'expédition",
    "order_management_title": "GESTION DES COMMANDES",
    "distribution_title": "DISTRIBUTION",
    "transportation_planning_title": "PLANIFICATION DES TRANSPORTS",
    "transportation_execution_title": "EXÉCUTION DU TRANSPORT",
    "transport_title": "Transport",
    "outbound_order_details": "DÉTAILS DE LA COMMANDE SORTANTE",
    "search_helper_text_network_page": "Entrez Expédition# Commande# Livraison #",
    "blocked_tms_planned": "TMS BLOQUÉ PRÉVU",
    "blocked_non_tms_planned": "NON TMS BLOQUÉ PRÉVU",
    "orders_block_free": "COMMANDES SANS BLOC",
    "back_orders": "COMMANDES EN SOUFFRANCE",
    "material_availability_date": "Date de disponibilité du matériel",
    "ship_outside_2_days": "Expédier en dehors de 2 jours",
    "healthy": "Sain",
    "ship_within_2_days": "Expédition dans les 2 jours",
    "critical_risk": "Risque critique",
    "potential_risk": "Risque potentiel",
    "planning_status": "ÉTAT DE LA PLANIFICATION",
    "shipment_processing": "TRAITEMENT DES ENVOIS",
    "carrier_committed": "Engagement du transporteur",
    "transportation_execution_status": "ÉTAT D'EXÉCUTION DU TRANSPORT",
    "suspended_status": "STATUT SUSPENDU",
    "appointment_status": "STATUT DU RENDEZ-VOUS",
    "transportation_details": "Détails sur le transport",
    "search_helper_text_transportation_page": "Entrez Expédition# Commande# Id de remorque Livraison #",
    "carrier_ready_date": "Date de prêt pour le transporteur",
    "business": "Affaire",
    "atleast_one_business_unit_needs_to_selected": "Au moins une unité d'affaires doit être sélectionnée",
    "business_unit": "Unité d'affaires",
    "customer_sales_office": "Bureau Client/Vente",
    "results": "Résultats",
    "filter_by": "Filtrer par",
    "view_by": "Voir par",
    "customer": "Client",
    "sales_org": "Organisation des ventes",
    "search_customer": "RECHERCHER UN CLIENT",
    "results_found": "résultats trouvés",
    "save_selections": "Enregistrer les sélections",
    "clear_selections": "Effacer les sélections",
    "favourites_message": "Les options favorites apparaîtront comme sélections par défaut chaque fois que vous visiterez Logistics MAESTRO. Pour mettre le client en favori, cliquez simplement sur l’icône de favori",
    "location": "Emplacement",
    "sales_office_group": "BUREAU DE VENTE/GROUPE",
    "you_can_favourite_locations!!": "Vous pouvez les endroits préférés !!",
    "favourited_message": "Les emplacements favoris apparaîtront comme emplacement sélectionné par défaut chaque fois que vous visiterez Logistics MAESTRO",
    "favourite_location_message_": "Pour mettre en favori les emplacements cliquez simplement sur l'icône fav",
    "next_to_the_location_name": "en regard du nom de l'emplacement.",
    "no_text": "Non",
    "found_matching_the_search_term": "trouvé correspondant au terme de recherche",
    "sales_office": "Bureau des ventes",
    "sales_groups": "Groupes de vente",
    "list_of_sales_group_for": "Liste des groupes de vente pour",
    "search_text": "RECHERCHER",
    "list_of_sales_offices_for_sales_org": "Liste des bureaux de vente pour Sales Org",
    "sales_office_customer": "Bureau des ventes / Client",
    "menu": "Menu",
    "favourites": "Favoris",
    "start_date": "Date de début",
    "end_date": "Date de fin",
    "100_%_confirmed_cube": "Cube 100 % confirmé",
    "less_than_100%_confirmed_cube": "Cube moins de 100% confirmé",
    "visible_in_tms": "Visible dans tms",
    "not_visible_in_tms": "Non visible dans tms",
    "pickup_not_scheduled": "Ramassage non programmé",
    "pickup_package_multi_block": "Pickup/colis multi bloc",
    "immediate_action": "Action immédiate",
    "delivery_#": "Livraison #",
    "inbound_shipment_status": "Statut de l'expédition entrante",
    "order_#": "Commande #",
    "order_status": "État de la commande",
    "order_type": "Type de commande",
    "origin_id": "ID d'origine",
    "origin": "Origine",
    "dest_city": "Ville de Dest",
    "dest_state": "État de Dest",
    "original_rdd": "RDD d'origine",
    "rdd": "RDD",
    "pickup_appt_date_&_time": "Date et heure du rendez-vous de ramassage",
    "ship_condition": "État du navire",
    "reason_for_alert": "Raison de l'alerte",
    "distribution_channel": "Canal de distribution",
    "sales_group": "Groupe des ventes",
    "mad": "MAD",
    "confirmed_delivery_appt_reschedule_count": "#Reschedules",
    "delivery_appt_date_&_time": "Date et heure du rendez-vous de livraison actuel",
    "confirmed_original_delivery_appt_date_time": "Date et heure de rendez-vous d’origine",
    "delivered_date_&_time": "Date et heure de livraison",
    "fourkites_status": "Statut fourkites",
    "late_reason_code": "Code de raison tardive",
    "actual_arrival_at_origin" : "Arrivée réelle à l'origine",
    "actual_departure_at_origin" : "Départ réel à l'origine",
    "actual_arrival_at_destination" : "Arrivée réelle à destination",
    "actual_departure_at_destination" : "Départ réel à destination",
    "records": "Archives",

    "sl_no": "Sl.No.",
    "material_id": "ID du matériau",
    "customer_material_id": "ID du matériau client",
    "material_description": "Description du matériau",
    "ordered_qty": "Qté commandé",
    "confirmed_qty": "Qté confirmé",
    "load_qty": "Charger Qty",
    "difference_qty": "Différence Qté",
    "delivery_block": "Bloc de livraison",
    "product_details": "Détails du produit",
    "location_performance": "PERFORMANCES DE LOCALISATION",
    "mill_dc_name": "NOM DE L'USINE/DC",
    "status": "Statut",
    "net_loading_hours": "Heures de chargement nettes (derrière/avant)",
    "no_data_found": "Aucune donnée trouvée",
    "monitor": "Moniteur",
    "behind": "Derrière",
    "critical": "Critique",
    "no_data": "Aucune donnée",
    "performance_map_title": "PLAN DES PERFORMANCES DU SITE – CALENDRIER DE CHARGEMENT SORTANT (HEURES EN AVANCE/EN RETARD)",
    "location_search": "Recherche d'emplacement",
    "refresh": "Rafraîchir",
    "map_view": "Vue de la carte",
    "table_view": "Vue tableau",
    "filters": "Filtres",
    "map_err_msg": "Désolé quelque chose s'est mal passé. Veuillez réessayer ou essayer avec un autre navigateur",
    "map_loading_msg": "La carte est en cours de chargement...",
    "ahead_more_than_3_hours": "(Avant >3 heures)",
    "ahead_below_3_hours": "(En avance 0.1-3 heures)",
    "behind_less_than_6_hours": "(Derrière >0 à 6 heures)",
    "behind_more_than_6_hours": "(Derrière >6 heures)",
    "no_data_0_hour": "(Pas de données 0 heure)",
    "filter_search": "FILTRES RECHERCHE",
    "clear_all": "Effacer tout",
    "select_all": "sélectionner tout",
    "enter": "Entrer",
    "expand": "Développer",
    "collapse": "Effondrement",
    "all": "Tout",
    "enter_a_view_name": "Entrez un nom de vue",
    "saved_successfully": "enregistré avec succès",
    "untitled_view": "Vue sans titre",
    "created": "créé",
    "deleted": "Supprimé",
    "views": "Affichage",
    "new_view_name_msg": "Crée une vue avec les paramètres par défaut",
    "add_view": "Ajouter une vue",
    "reset": "Réinitialisation",
    "new_view": "Nouvelle vue",
    "save": "Sauvegarder",
    "delete": "Supprimer",
    "all_dc_and_mill": "Tous les DC et moulins",
    "dc": "COURANT CONTINU",
    "mill": "MOULIN",
    "destination_state": "État de destination",
    "destination_city": "Ville de destination",
    "carrier_service_code": "Code de service du transporteur",
    "requested_delivery_date": "Date de livraison demandée",
    "delivered_date_time": "Date/heure de livraison",
    "customer_confirmed_delivery_date": "Date de livraison confirmée par le client",
    "unhealthy": "Malsain",
    "needs_attention": "Besoin d'attention",
    "order_block_free": "Commander Block Free",
    "order_blocked": "Commande bloquée",
    "manage_columns": "Gérer les colonnes",
    "download_successful": "Télécharger réussi!",
    "download_failed_msg": "Pardon! Échec du téléchargement du fichier. Veuillez réessayer.",
    "network_table": "Network_Table",
    "order_management": "Order_Management",
    "transportation": "Transport",
    "distribution_inbound": "Distribution_Inbound",
    "distribution_outbound": "Distribution_Outbound",
    "customer_po_#": "Bon de commande client #",
    "leq": "LEQ",
    "confirmed_leq": "LEQ confirmé",
    "allocated_leq": "LEQ alloué",
    "order_sub_status": "Sous-statut de la commande",
    "delivery_credit_block": "Bloc de livraison/crédit",
    "enter_delivery_block_code": "Entrez le code du bloc de livraison",
    "show_only_credit_hold_orders": "Afficher uniquement les ordres de retenue de crédit",
    "confirmed_cube": "Cube confirmé",
    "100%_confirmed_cube": "Cube 100% confirmé",
    "back_orders_block_free": "Commandes en souffrance Sans bloc",
    "back_orders_blocked": "Commandes en souffrance bloquées",
    "all_customer_orders": "Toutes les commandes des clients",
    "document_type": "Type de document",
    "standard_orders": "Commandes standard (ZSTD)",
    "multi_plant_orders": "Commandes multi-usines (ZMPF)",
    "vmi_orders": "Commandes VMI (ZVMI)",
    "merged_orders": "Commandes fusionnées (ZMER)",
    "foc_orders": "FOC Ordres (ZFOC)",
    "shipping_condition": "Condition d'expédition",
    "tm_operational_status": "État opérationnel de la MT",
    "unassigned": "Non attribué",
    "open": "Ouvrir",
    "planned": "Planifié",
    "tendered": "Présentée",
    "tender_rejected": "Offre rejetée",
    "tender_accepted": "Offre acceptée",
    "confirming": "Confirmant",
    "completed": "Terminé",
    "appointment_type": "Type de rendez-vous",
    "manual": "Manuelle",
    "automated": "Automatisé",
    "appointment_required": "Rendez-vous requis",
    "notified": "Notifié",
    "suggested": "Suggéré",
    "confirmed": "Confirmé",
    "not_yet_contacted": "Pas encore contacté",
    "appt_not_made_by_transport": "Appt non fabriqué par Transport",
    "live_load": "Chargement en direct",
    "equipment_type": "Type d'équipement",
    "full_truck_load": "Chargement complet du camion",
    "less_than_truck_load": "Moins que le chargement du camion",
    "truck_load_tl": "Chargement de camion (TL)",
    "intermodal_tf": "Intermodal (TF)",
    "open_op": "Ouvert (OP)",
    "package_pg": "Paquet (PG)",
    "customer_pickup_pu": "Ramassage des clients (PU)",
    "order_on_hold": "Commande en attente",
    "delivery_date": "Date de livraison",
    "loading_completed_on_time": "Chargement terminé à temps",
    "tm_status": "Statut TM",
    "appt_type": "Type d'appt",
    "appt_req_d": "Appt Req'd",
    "appt_status": "Statut de l'application",
    "short_lead": "Avance courte",
    "loading_completion_date_time": "Date/heure d'achèvement du chargement",
    "hours_in_ready_for_pickup": "Heures prêtes pour le ramassage",
    "load_#": "Charger #",
    "dest": "Dénuement",
    "date_and_time_arrived_in_yard": "Date et heure arrivées dans la cour",
    "duration_in_yard": "Durée dans la cour",
    "less_than_24_hours": "< 24 heures",
    "between_24_and_48_hours": "24 - 48 heures",
    "greater_than_72_hours": "> 72 heures",
    "date_arrived_in_yard": "Date d'arrivée dans la cour",
    "load_start_date": "Date de début du chargement",
    "estd_load_hrs_rem": "Estd Load Hrs Rem",
    "total_load_hrs": "Nombre total d'heures de charge",
    "customer_appt_time": "HEURE DU RENDEZ-VOUS CLIENT",
    "stock_transfer_orders": "Ordres de transfert de stock",
    "total": "Total",
    "blocked": "Bloqué",
    "blocked_free": "Bloqué gratuitement",
    "view_details": "Voir les détails",
    "order_blocked_and_shipping_soon": "Commande bloquée et expédition prochaine",
    "order_blocked_and_ship_date_past_due": "Commande bloquée et date d'expédition en retard",
    "pickup_not_scheduled_and_shipping_soon": "Ramassage non prévu et expédition bientôt",
    "pickup_not_scheduled_and_ship_date_past_due": "Ramassage non prévu et date d'expédition en retard",
    "order_blocked_and_immediate_action_needed": "Ordre bloqué et action immédiate nécessaire",
    "greater_than_1000_confirmed_cube_&_shipping_soon": "< 1000 cube confirmé et expédition prochaine",
    "greater_than_1000_confirmed_cube_&_ship_date_past_due": "< 1000 cube confirmé et date d'expédition en souffrance",
    "zero_confirmed_cube_&_shipping_soon": "Zéro cube confirmé et expédition bientôt",
    "zero_confirmed_cube_&_ship_date_past_due": "Zéro cube confirmé et date d'expédition en souffrance",
    "not_100%_confirmed": "Non confirmé à 100%",
    "short_lead_time": "Délai de livraison court",
    "can_put_more_product_on_trailer": "Peut mettre plus de produit sur la remorque",
    "load_too_heavy": "Charge trop lourde",
    "order_too_big_cannot_fit_on_trailer": "Commande trop grande - ne peut pas tenir sur la remorque",
    "past_due_for_planning": "Arriérés de planification",
    "short_lead_or_no_customer_appointment": "Court délai ou pas de rendez-vous client",
    "not_on_track_to_meet_standard_leadtime": "Pas sur la bonne voie pour respecter les délais d'exécution standard",
    "shipping_today_or_in_the_past_and_carrier_not_confirmed": "Expédition aujourd'hui ou dans le passé et transporteur non confirmé",
    "shipping_soon_and_carrier_not_confirmed": "Expédition bientôt et transporteur non confirmé",
    "delivery_document_not_created_and_shipping_today_or_in_the_past": "Document de livraison non créé et expédié aujourd'hui ou dans le passé",
    "delivery_document_not_created_and_shipping_soon": "Document de livraison non créé et expédition prochaine",
    "past_due_load_not_picked_up": "Arriéré - Charge non récupérée",
    "past_due_delivery_not_confirmed": "Arriéré - Livraison non confirmée",
    "shipment_not_started_and_LRTC_is_within_12_hours": "L'expédition n'a pas commencé et LRTC est dans les 12 heures",
    "not_on_track_to_achieve_LRTC": "Pas sur la bonne voie pour atteindre LRTC",
    "past_due_late_for_arrival_time": "Retard - retard pour l'heure d'arrivée",
    "in_yard_more_than_72_hrs": "Dans la cour plus de 72 heures",
    "unloading_taking_more_than_24_hrs": "Déchargement prenant plus de 24 heures",
    "export": "Exportation",
    "export_view": "VUE EXPORTER",
    "with_product_details": "AVEC LES DÉTAILS DU PRODUIT",
    "without_product_details": "SANS DÉTAILS SUR LE PRODUIT",
    "region": "Région",
    "customers_sales_office": "Clients / Bureau des ventes",
    "show_all": "Afficher tout",
    "hide_all": "Masquer tout",
    "consumer": "Consommateur",
    "professional": "Professionnel",
    "above_guard_rail": "Au-dessus du garde-corps",
    "below_guard_rail": "Sous le garde-corps",
    "shipment_load_number": "Numéro d'expédition/chargement",
    "latest_location": "Dernier emplacement",
    "destination": "Destination",
    "mode": "Mode",
    "stops": "S' arrête",
    "tracking_details": "Détails du suivi",
    "planned_arrival": "Arrivée prévue",
    "appointment_time": "Heure du rendez-vous",
    "detected_near_facility": "Détecté près de l'installation",
    "departed_facility": "Installation disparue",
    "network_view": "Vue réseau",
    "my_selections": "Mes sélections",
    "view_more": "Voir plus",
    "view_less": "Voir moins",
    "you_do_not_have_any_favourites": "Vous n'avez pas de favoris.",
    "list_of_favourited_selections": "Liste des sélections favorites",
    "home": "Domicile",
    "glossary": "Glossaire",
    "close_menu": "Fermer le menu",
    "supply_chain_analytics": "Analyse de la chaîne d'approvisionnement",
    "last_updated": "Dernière mise à jour",
    "feedback": "Rétroaction",
    "feedback_sucessfully_posted": "Commentaires publiés avec succès!",
    "something_went_wrong_please_try_again": "Quelque chose a mal tourné! Veuillez réessayer",
    "send_us_feedback": "Envoyez-nous vos commentaires",
    "thank_you_for_taking_the_time_to_give_us_feedback": "Merci d'avoir pris le temps de nous faire part de vos commentaires.",
    "are_you_satisfied_with_your_experience": "Êtes-vous satisfait de votre expérience ?",
    "required": "Obligatoire",
    "submit_feedback": "Soumettre des commentaires",
    "hide": "Cacher",
    "show": "Montrer",
    "site_type": "Site Type",
    "domestic": "Domestique",
    "international": "International",
    "access_sales_group_by_clicking_on_the_sales_office": "Accédez au groupe de vente en cliquant sur le bureau des ventes",
    "found": "Fonder",
    "to_be_planned": "À planifier",
    "planning": "Planification",
    "something_went_wrong": "Quelque chose a mal tourné",
    "please_wait": "Veuillez patienter...",
    "location_volume_per_day": "VOLUME DE LOCALISATION PAR JOUR",
    "in_hours": "EN HEURES",
    "in_truck_loads": "DANS LES CHARGEMENTS DE CAMIONS",
    "edit_update_guardrails": "Modifier/Mettre à jour les garde-corps",
    "unable_to_load": "Impossible de charger",
    "time_zone": "FUSEAU HORAIRE",
    "select_language": "SÉLECTIONNER UNE LANGUE",
    "search_helper_text_order_page": "Entrez Expédition# Commande# Client PO# Livraison #",
    "origin_sites": "Sites d'origine",
    "view": "Vue",
    "more": "Plus",
    "less": "Moins",
    "loads": "Charges",
    "orders": "Ordres",
    "unit": "Unité",
    "hours": "Heures",
    "hour": "Heure",
    "update_changes": "Mettre à jour les modifications",
    "distribution_guardrails": "Garde-corps de distribution",
    "download_started_msg": "Le téléchargement du fichier a commencé. Cela peut prendre quelques secondes.",
    "download_progress_msg": "Le téléchargement du fichier est déjà en cours",
    "rows_per_page": "Lignes par page",
    "no_records_to_display": "Aucun enregistrement à afficher",
    "track_trace": "Suivi et traçabilité",
    "with_managed_columns_and_filters_applied": "Avec des colonnes et des filtres gérés appliqués",
    "pickup": "Pick-up",
    "delivery": "Livraison",
    "just_now": "tout à l'heure",
    "promotional_material": "Matériel promotionnel",
    "indicates_promotional_orders": "Indique les commandes promotionnelles",
    "promotionalOrder": "Commande promotionnelle",
    "collectiveNumber": "Numéro collectif",
    "tell_us_about_your_experience": "Parlez-nous de votre expérience",
    "and": "et",
    "show_tabs_by": "Afficher les onglets par",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "of": "de",
    "health": "Santé",
    "show_only_promotional_orders": "Afficher uniquement les commandes promotionnelles",
    "hide_promotional_orders": "Masquer les commandes promotionnelles",

    "above_current_guard_rail": "Au-dessus du garde-corps actuel",
    "below_current_guard_rail": "Sous le garde-corps actuel",
    "tutorials_&_resources": "Tutoriels et ressources",
    "fourkites_back_button": "Retour aux résultats de la recherche",

    //Guadrail Page
    "accept": "Accepter",
    "decline": "Décliner",
    "enter_reason_here": "Entrez la raison ici",
    "close": "Fermer",
    "name": "Nom",
    "reason": "Raison",
    "please_mention_reason_for_decline": "Veuillez mentionner la raison du refus d'accès aux garde-fous d'édition/mise à jour",
    "your_request_submitted_wait_for_approval": "Votre demande a été soumise. Veuillez attendre l'appproval. Vous serez averti par e-mail",
    "request_access": "Demander l'accès",
    "please_mention_reason_for_request": "Veuillez mentionner pourquoi vous avez besoin d'accéder aux garde-corps d'édition/mise à jour*",
    "edit_update_access_req_for_guardrail": "Modifier/mettre à jour la demande d'accès pour le graphique Guardrail",
    "pending_requests": "Demandes en attente",
    "no_pending_requests": "Aucune demande en attente",

    // Transport - Item details table
    "actual_departure_from_destnation": "Départ effectif de Destnation",
    "sap": "SAP",
    "fourkites": "Fourkites",
    "tms": "TMS",
    "actual_departure_from_origin": "Départ effectif de l’origine,",
    "yms": "YMS",

    //Transport Details
    "trailer_status": "État de la remorque",
    "percent_Of_trailer_loaded": "% de remorque chargée",
    "customer_notified": "Avis du client",
    "delivery_appt_confirmed_by_customer": "Application de livraison confirmée par le client",
    "order_created_in_tms": "Commande créée dans TMS",

    "min": "Min",
    "max": "Max",
    "reschedules": "Reprogrammations",
    "min_max_error_text": "La valeur maximale doit être supérieure ou égale à la valeur minimale",
    "enter_value": "Entrer la valeur",
    "daily": "quotidien",
    "weekly": "hebdomadaire",
    "exportVolume:": "Volume d'exportation",
    "importVolume": "volume d'importation"
}