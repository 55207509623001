import React from 'react';
import { Typography, Grid, Divider, makeStyles, useTheme } from '@material-ui/core';
import { chartElementStyles } from "../../theme"
import D3Tooltip from "../D3Charts/D3Tooltip";
import { tooltipLinkArgumentsMap } from '../../reduxLib/constdata/filters';
import clsx from 'clsx';


const useStyles = makeStyles(chartElementStyles);

export const BackOrderDetailsChartBuilder = ({ chartsData, setDetails, subtype, type, colors, ...rest }) => {
    let mappedData = chartsData
   
    mappedData = mappedData.reduce((acc, obj, index) => {
        return {
            ...acc,
            [obj.state]: obj
        }
    }, {})

    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.container} wrap='nowrap' data-testid="backderorderchart" >
            <Grid item xs={6} data-testid="SO_BACK_ORDER_BLOCK_FREE">
                <D3Tooltip placement={"top"} color={useTheme().palette.chartColors['healthy']} title={

                    <div className={clsx(classes.hyperlink)} onClick={() => {
                        if (typeof (setDetails) === "function") {
                            setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap["SO_BACK_ORDER_BLOCK_FREE"], health: "", state: subtype }, true)
                        }
                    }}>
                        {mappedData?.SO_BACK_ORDER_BLOCK_FREE?.stateDesc || "-"} : {mappedData?.SO_BACK_ORDER_BLOCK_FREE?.greenCount || 0}
                    </div>
                }>
                    <Typography className={classes.count}>{mappedData?.SO_BACK_ORDER_BLOCK_FREE?.greenCount || 0}</Typography>
                </D3Tooltip>
                <Typography variant="h4">{mappedData?.SO_BACK_ORDER_BLOCK_FREE?.stateDesc || "-"}</Typography>
            </Grid>
            <Grid item className={classes.verticalDivider_solid} ><Divider orientation='vertical' /></Grid>
            <Grid item xs={6} data-testid="SO_BACK_ORDER_BLOCKED">
                <D3Tooltip key={1} placement={"top"} color={useTheme().palette.chartColors['healthy']} title={<div className={classes.hyperlink} onClick={() => {
                    if (typeof (setDetails) === "function") {
                        setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap["SO_BACK_ORDER_BLOCKED"], health: "", state: subtype }, true)
                    }
                }}>
                    {mappedData?.SO_BACK_ORDER_BLOCKED?.stateDesc || "-"} : {mappedData?.SO_BACK_ORDER_BLOCKED?.greenCount || 0}
                </div>}>
                    <Typography className={classes.count}>{mappedData?.SO_BACK_ORDER_BLOCKED?.greenCount || 0}</Typography>
                </D3Tooltip>
                <Typography variant="h4" >{mappedData?.SO_BACK_ORDER_BLOCKED?.stateDesc || "-"}</Typography>
            </Grid>
        </Grid>
    );
}
