import React from 'react'
import { stack } from 'd3'
import D3Tooltip from "./D3Tooltip";
import { formatLocaleNumber } from 'helpers/emeaHelpers';

const Dots = (props) => {
  const { 
    scales, data,
    xAccessor, 
    keys,
    xKey, 
    id,
    themeType = "dark",
    formatRequired = false,
  } = props
  const { xScale, yScale } = scales
  const stacked = stack().keys(keys);
  const stackedData = stacked(data);

  const renderDot = (item, key, index, i) => {

    let x = xScale(xAccessor(item.data));
    let y = yScale(item[1]);;
    let width = xScale.bandwidth();
    let height = yScale(item[0]) - yScale(item[1]);;

    const tooltipValue = item[1] - item[0];

    return (
      <g key={i} data-testid={`g-wrapper-${i}`}>
        {
          height && width ?
            <D3Tooltip
              placement={"top"}
              interactive
              themeType={themeType}
              title={
                <div data-testid="tooltip-test-id">
                  {item.data[xKey]} : {formatLocaleNumber(Math.floor(tooltipValue), formatRequired)}
                </div>
              }
            >
              <circle
                cx={x + width / 2}
                data-testid={`circle-side-${i}-${key}`}
                cy={y}
                key={i}
                r={3}
                height={height}
                width={width}
                stroke={"black"}
                fill={"white"}
                item={item}
                item-key={key}
                item-index={index}
              />
            </D3Tooltip>
            : null
        }

      </g>
    );
  }

  const renderDotSeries = (type2) => {
    return stackedData.map(item => {
      const { key, index } = item
      const data1 = Array.from(item)
      return data1.map((d, i) => {
        return renderDot(d, key, index, i);
      })
    });
  }

  return (
    <g data-testid='dots' id={id}>
      {renderDotSeries()}
    </g>
  )
}

export default Dots;