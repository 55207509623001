import React from 'react';
import { makeStyles, Select, MenuItem, FormControl, ListItemText, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: ({width}) => width || 300,
  },
  box: {
    width: 'fit-content',
    padding: 2,
    border: `1px solid ${theme.palette?.primary.contrastText}`,
    borderRadius: theme.spacing(0.5)
  },
  menuItem: {
    width: 300,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2)}px`,
    '&.MuiListItem-root.Mui-selected' : {
      backgroundColor: theme.palette?.background?.selected
    },
    '&.MuiListItem-root:hover' : {
      backgroundColor: theme.palette?.background?.selected
    }
  },
  select: {
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
    },
    '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline:hover': {
      borderColor: `transparent !important`,
    },
    '& .MuiSelect-icon': {
      color: theme.palette?.primary.contrastText
    },
    '& .Mui-focused': {
      border: `transparent`
    },
    '& .MuiInputBase-input': {
      boxSizing: 'border-box'
    },
    '& .MuiSelect-select:hover, .MuiSelect-select:focus': {
      backgroundColor: theme.palette?.background.selected
    }
  }
}));

const OsmDropdown = ({ data, MenuProps, testId, initialValue, onChange, placeholder, placeholderText, width }) => {
  const classes = useStyles({width});

  return(
    <Box className={classes.box}>
      <FormControl className={classes.formControl} data-testid="osmDropdown">
        <Select
          // data-testid={testId}
          value={initialValue}
          onChange={onChange}
          MenuProps={MenuProps} 
          variant='outlined'
          className={classes.select}
          SelectDisplayProps={ {"data-testid" : testId} }
          displayEmpty={placeholder}
        >
          {placeholder && <MenuItem value="" className={classes.menuItem} disabled> {placeholderText} </MenuItem>}
          {data.map((item) => (
            <MenuItem key={item} value={item} className={classes.menuItem} data-testid={`menu-${item}`}>
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default OsmDropdown;