import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import TrendLineChart from '../EMEA_Charts/TrendLineChart';
import TreeMap from '../EMEA_Charts/TreeMap';
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { chartsHeight as height } from 'helpers/styleHelpers';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';

const EMEAInnerChartCard = ({
    subtype, title, chart, updateDesc = true,
    trendline, showTotal, unitOfTotal,
    yLabel, legend, additionalFilters = {},
    singleDocumentDate, refresh, setDetails, ...rest
}) => {
    const [controller] = useState(() => new AbortController());
    const [updatedData, setUpdatedData] = useState([]);
    const dispatch = useDispatch();
    const { loading, data } = useSelector(({ emeaSummaries }) => emeaSummaries?.[subtype] || {});

    const {
        locations,
        customers = [],
        sofcs = [],
        BU,
        documentDate
    } = useEMEAGlobalContext();

    const currency = localStorage.getItem("currency") || "USD";

    const refreshInnerChart = () => {

        const payload = {
            "region": "EMEA",
            subtype,
            businessUnits: BU,
            sites: locations,
            documentDate,
            customerOrSalesOffice: [...customers, ...sofcs],
            currency,
            singleDocumentDate,
            ...additionalFilters
        }
        dispatch(emeaSummaryService({ payload, controller }));
    }


    useDeepCompareEffect(() => {
        refreshInnerChart();
    }, [subtype, locations, sofcs, documentDate, customers, BU, currency, refresh, singleDocumentDate]);

    useDeepCompareEffect(() => {
        if (data?.length) {
            const processedData = (updateDesc === true) ? data?.map(d => {
                return {
                    ...d,
                    stateDesc: `${d.stateDesc} (${d?.state})`
                }
            }) : data;
            setUpdatedData(processedData);
        } else {
            setUpdatedData([]);
        }
    }, [data])

    useEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const chartProps = {
        ...rest,
        data: updatedData,
        loading,
        height: height - 100,
        keys: ["totalCount"],
        yKey: "totalCount",
        themeType: "light",
        trendline,
        yLabel,
        subtype,
        legend,
        showEllipsis: true,
        dx: "-11%",
        setDetails,
        currency,
        setDetailsData: true
    }

    return (
        <div data-testid="inner-charts-card">
            {chart === "trendline" && <TrendLineChart {...chartProps} />}
            {chart === "treemap" && <TreeMap {...chartProps} />}
        </div>
    )
}

export default EMEAInnerChartCard;