import { osmLsrStackDetailsConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";

export const viewLSRService = variables => dispatch => {
  dispatch({
      type: osmLsrStackDetailsConstants.OSM_VIEW_LSR_LOADING,
      payload: true
  });
  let response = {};

  return fetch(endpoints.osm.viewLsr, {
      method: "POST",
      body: variables,
  })
      .then(async (d) => {
          if (d.status === 200) {
              return d.json();
          } else if (d.status === 401) {
              throw "osm_restricted"
          } else {
              response = await d.json();
              throw response?.exceptionCode === "ct_exec_35" ? "ld_not_submitted_error" : response?.exceptionCode === "ct_exec_42" ? "see_ld_submission_log" : "lsr_failed"
          }
      })
      .then(d => {
          dispatch({
              type: osmLsrStackDetailsConstants.OSM_VIEW_LSR_SUCCESS,
              payload: d,
              error: null
          });
          
          dispatch({
              type: osmLsrStackDetailsConstants.OSM_VIEW_LSR_LOADING,
              payload: false
          })
      })
      .catch(err => {
          dispatch({
              type: osmLsrStackDetailsConstants.OSM_VIEW_LSR_FAILED,
              payload: {},
              error: err  
          });
          dispatch({
              type: osmLsrStackDetailsConstants.OSM_VIEW_LSR_LOADING,
              payload: false
          });
      })
}

export const viewStackDetailsService = variables => dispatch => {
    dispatch({
        type: osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_LOADING,
        payload: true
    });
    let response = {};
  
    return fetch(endpoints.osm.viewStackInfo, {
        method: "POST",
        body: variables,
    })
        .then(async (d) => {
            if (d.status === 200) {
                return d.json();
            } else if (d.status === 401) {
                throw "osm_restricted"
            } else {
                response = await d.json();
                throw "stack_failed";
            }
        })
        .then(d => {
            dispatch({
                type: osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_SUCCESS,
                payload: d,
                error: null
            });
            
            dispatch({
                type: osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_FAILED,
                payload: {},
                error: err  
            });
            dispatch({
                type: osmLsrStackDetailsConstants.OSM_VIEW_STACK_DETAILS_LOADING,
                payload: false
            });
        })
}

export const resetLdLogsDownloadService = variables => dispatch => {
    dispatch({
        type: osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_RESET,
        payload: null
    });
}

export const loadLdLogsDownloadService = variables => dispatch => {
    dispatch({
        type: osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_LOADING,
        payload: variables?.loading
    });
}

export const ldLogsDownloadService = variables => dispatch => {
    dispatch({
        type: variables?.type === "success" ? osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_SUCCESS : osmLsrStackDetailsConstants.OSM_LD_DOWNLOAD_FAILED,
        payload: variables?.type
    });
}