import React, { useState } from 'react'
import MaterialTable from 'material-table'
import { Paper, useTheme, Grid, makeStyles, TablePagination, Box, Typography } from "@material-ui/core";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { cloneDeep, get, isEmpty, omit } from "lodash";
import { connect, useDispatch } from "react-redux";
import isEqual from "lodash/isEqual";
import {
    generateTablebody, generateDefaultData, getUpdatedColumns,
    createHyperLinkFilterObject, shouldRefreshRequest,
    isJsonParsable, getFilterArgs, hideDetailColumns
} from "helpers";
import LinearProgress from '@material-ui/core/LinearProgress';
import { tableStyles } from 'theme';
import { stickyHeaderCell, stickyBodyCell } from '../../helpers/tableStyleOverride';
import {
    saveColumnState,
    getTableData,
    setTableBodyAction,
    resetColumnState
} from "reduxLib/services";
import FilterView from './FilterView'
import TableActionsWrapper from './TableActionsWrapper';
import { OrderHealthTooltip } from "../common/OrderHealthTooltip"
import TablePaginationActions from "./Table/TablePaginationActions";
import { useDeepCompareEffect, useUpdateEffect } from 'react-use';
import PriceTag from "assets/images/pricetag.svg";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useAppContext } from 'context/AppContext';

const useStyles = makeStyles(tableStyles);
const useStylesPagination = makeStyles((theme) => ({
    spacer: {
        flex: "1 1 auto"
    },
    indicatesPadding: {
        [theme.breakpoints.down("sm")]: {
       paddingTop:theme.spacing(0.5),
        }
    },
}));

const fixFirstColumn = (columns, defaultColumns) => {
    let firstUnhiddenIndex = null
    const fixedColumnObject = columns.map((columnObject, index) => {

        firstUnhiddenIndex = !columnObject.hidden && (firstUnhiddenIndex === null || index <= firstUnhiddenIndex) ? index : firstUnhiddenIndex

        if ((firstUnhiddenIndex === index)) {
            return { 
                ...(defaultColumns[columnObject.field]),
                ...columnObject,
                cellStyle: { ...columnObject.cellStyle, ...stickyBodyCell }, headerStyle: { ...columnObject.headerStyle, ...stickyHeaderCell } 
            }
        } else {
            const cellStyle = omit(columnObject.cellStyle, Object.keys(stickyBodyCell))
            const headerStyle = omit(columnObject.headerStyle, Object.keys(stickyHeaderCell))
            return { 
                ...(defaultColumns[columnObject.field]),
                ...columnObject, 
                headerStyle, 
                cellStyle 
            }
        }
    });

    return fixedColumnObject;
}

const TableComponent = (props) => {
    let { title, tableName, excludeArray,
        prevPageDetails, activeSubTableTab,
        searchTextPlaceholder, type,
        ItemDetailSection, defaultFilterArgs, columns, conditionalFilterReplacement,
        filterBody = {}, pageDetails, filterType, topDateFilter,
        fetchEndPoint, filters, tableStyleConfig, tableRef,
        ...rest } = props

    const classes = useStyles();
    const dispatch = useDispatch();
    const paginationClasses = useStylesPagination();
    const theme = useTheme();
    const { t } = useTranslation();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [updatedDefCols,] = React.useState(() => {
        const updateDefaultCols = {};
        const cols = columns?.columnOrder?.map(d => {
            updateDefaultCols[d.field] = d
        });
        return updateDefaultCols;
    });

    const { itemDetailsColumns } = rest;

    const { setRef, globalFilterSelection, refresh } = useAppContext();

    const [columnOrder, setColumnOrder] = useState([]);

    const [fetchAllData, setFetchAllData] = useState({});

    const [businessUnits, setBusinessUnits] = useState(rest.businessUnits)

    const { siteNums } = globalFilterSelection || {};

    const [firsteffect, setFirstEffect] = React.useState(false);
    const [tablebody, setTableBody] = useState(() => {
        return isEmpty(props.tablebody) ? {
            ...defaultFilterArgs,
            businessUnits,
            [tableName === "distinbound" ? "destSites" : "sites"]: siteNums,
            customerOrSalesOffice: globalFilterSelection?.customerOrSalesOffice,
            //...conditionalFilterReplacement(generateTablebody(filters, siteNums))
        } : props.tablebody
    });

    // const [hyperLinkfilters, setHyperLinkFilters] = useState(createHyperLinkFilterObject({ pageDetails, tableName, filterBody }).fullObject)

    // React.useEffect(() => {
    //     setHyperLinkFilters(createHyperLinkFilterObject({ pageDetails, tableName, filterBody }).fullObject);
    // }, [pageDetails.filterParams])

    React.useEffect(() => {
        setBusinessUnits(rest.businessUnits);
    }, [rest.businessUnits]);

    const location = useLocation();
    const queryParams = qs.parse(location.search);

    useDeepCompareEffect(() => {
        setFirstEffect(true);
        if(!firsteffect) return;

        const queryfilters = isJsonParsable(queryParams.filters);

        let newfilters = generateTablebody(filters, siteNums);

        const filterargs = getFilterArgs(filters);

        if (!isEqual(filterargs, queryfilters)) {
            return;
        }

        conditionalFilterReplacement(newfilters)

        let newbody = {
            ...tablebody,
            businessUnits,
            ...newfilters,
            customerOrSalesOffice: globalFilterSelection?.customerOrSalesOffice || null
        };

        if (tableName === "distinbound") {
            newbody = {
                ...newbody,
                destSites: siteNums
            }
        }

        // let hyperfilters = generateTablebody(hyperLinkfilters);
        // conditionalFilterReplacement(hyperfilters)

        // hyperfilters = {
        //     ...newbody,
        //     ...hyperfilters
        // }

        if (!isEqual(tablebody, newbody)) {
            // if (!isEqual(tablebody, newbody) && !(!isEmpty(pageDetails.filterParams) &&
            // !isEqual(newbody, hyperfilters) && !isEmpty(hyperLinkfilters)) && !blockOnTabChange) {
            setTableBody(newbody);
            dispatch(setTableBodyAction({ body: newbody, tableName }));
            // setHyperLinkFilters({})
            setPage(0);
            refreshTable({
                tablebody: newbody,
                pageSize: rowsPerPage,
                page: 0,
            })
        }

    }, [filters, queryParams.filters, businessUnits, globalFilterSelection, siteNums, firsteffect]);


    const refreshTable = ({ pageSize, page: pageParam, forceRefresh, ...restOfParams }) => {
        hideDetailColumns(tableRef);
        const reqBody = {
            body: {
                fetchEndPoint,
                tablebody,
                pageSize,
                page: pageParam,
                type,
                tableName,
                ...restOfParams
            },
            language: localStorage.getItem('i18nextLng'),
            refresh,
            reqtime: new Date()
        }

        const { tableReqBody } = props;

        /*
        IsEqual will compare saved tablebody of the particualr table or force refresh condition and will do the api call
        */

        if (shouldRefreshRequest(tableReqBody, reqBody) || forceRefresh) {
            dispatch(getTableData(reqBody));
        }
    }

    useUpdateEffect(() => {
        if (!rest.loading) {
            refreshTable({
                pageSize: rowsPerPage,
                page
            });
        }
    }, [refresh, localStorage.getItem('i18nextLng')])

    const saveFetchAllData = (totalRecords) => {
        const detailColumns = itemDetailsColumns?.columnOrder?.map(item => {
            return {
                ...item,
                title: t(item.title),
            }
        });

        setFetchAllData({
            config: {
                url: fetchEndPoint,
                body: tablebody,
                totalRecords: totalRecords || 0,
                columns: columnOrder,
            },
            type,
            subtype: tableName,
            process: columns.columnConfiguration,
            detailsColumns: detailColumns,
            detailsProcess: itemDetailsColumns.columnConfiguration
        });
    }

    const setColumnsCustom = (cols, order) => {
        setColumnOrder(cols);
        dispatch(saveColumnState({ columns: cols, subtype: tableName }));
    }

    useDeepCompareEffect(() => {
        hideDetailColumns(tableRef);
        const translated = props.columnOrder?.map(item => {
            return {
                ...item,
                title: t(item.title),
            }
        });
        setColumnOrder(fixFirstColumn(translated, updatedDefCols));
    }, [props.columnOrder, tableRef?.current?.props?.data, localStorage.getItem('i18nextLng')])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        refreshTable({ pageSize: rowsPerPage, page: newPage });
    };


    const handleChangeRowsPerPage = (event) => {
        const pageSize = parseInt(event.target.value, 10)
        setRowsPerPage(pageSize);
        setPage(0);
        refreshTable({ pageSize: pageSize, page: 0 });
    };

    const [tabledata, setTableData] = React.useState(rest.tabledata)

    useDeepCompareEffect(() => {
        const tempData = rest.tabledata?.orders?.map(data => {
            let d = {};
            Object.keys(data).map(item => {
                if (data[item] !== 0 && !data[item]) {
                    d[item] = "-";
                } else {
                    d[item] = data[item];
                }
            })
            return {
                ...generateDefaultData(columnOrder),
                ...d,
                ...columns.columnConfiguration(d)
            }
        }) || [];

        setTableData({ ...(rest.tabledata || {}), orders: tempData });
        saveFetchAllData(rest?.tabledata?.totalRecords || 0);
    }, [rest.tabledata, localStorage.getItem('i18nextLng')])

    const { orders, totalRecords: totalRecordsData } = tabledata;

    React.useEffect(() => {
        // Every time we unmount the component we have to reset tablebody from the redux state
        //Also on unmount reset columns to default state
        return () => {
            dispatch(resetColumnState({ tableName }));
            dispatch(setTableBodyAction({ body: {}, tableName }));
        }
    }, []);

    return (
        <Grid className={classes.root}>
            <div id="scrollIntoView" ref={setRef} />
            <TableActionsWrapper {...{
                title,
                topDateFilter,
                type,
                tableName,
                preSetStatusFilter: createHyperLinkFilterObject({ pageDetails, tableName, filterBody }).preSetStatusFilter,
                fetchAllData,
                columnOrder,
                columns,
                setColumnsCustom,
                filterType,
                excludeArray,
                searchTextPlaceholder,
                filterBody,
                refreshTable: () => refreshTable({ pageSize: rowsPerPage, page, forceRefresh: true })
            }} />
            <FilterView
                // hyperLinkfilters={hyperLinkfilters}
                prevPageDetails={prevPageDetails}
                activeSubTableTab={activeSubTableTab}
                pageDetails={pageDetails}
                type={type}
                tableName={tableName}
                defaultColumns={columns?.columnOrder}
                filterBody={filterBody} />
            <Box display="flex" className={paginationClasses.indicatesPadding}>
                <Box>
                    <img src={PriceTag} className={classes.smallimage} />
                </Box>
                <Box flexGrow={1}>
                    <Typography>{`${t('indicates_promotional_orders')} (Costco, Grocery, Walmart, Target)`}</Typography>
                </Box>
            </Box>
            <Grid className={classes.table}>
                <MaterialTable
                    isLoading={rest.loading}
                    key={orders?.length}
                    localization={{
                        body: {
                            emptyDataSourceMessage: t("no_records_to_display")
                        }
                    }}
                    tableRef={tableRef}
                    components={{
                        Container: props_ => <Paper {...props_} elevation={0} />,
                        OverlayLoading: props_ => <LinearProgress />,
                        Action: props_ => (<OrderHealthTooltip tableName={tableName} {...props_} />),
                        Pagination: props_ => (<TablePagination
                            showFirstButton
                            showLastButton
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            component="div"
                            count={totalRecordsData || 0}
                            page={page}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            classes={paginationClasses}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage={t("rows_per_page")}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
                            data-testid="pagination"
                        />)
                    }}
                    options={{
                        ...tableStyleConfig,
                        pageSize: rowsPerPage
                    }}
                    style={{
                        backgroundColor: theme.palette.primary.base,
                        color: 'white',
                        borderBottom: '1px solid white',
                    }}
                    columns={cloneDeep(columnOrder)}
                    data={orders}
                    detailPanel={
                        [
                            rowData => ({
                                icon: () => <OrderHealthTooltip tableName={tableName} data={rowData} />,
                                openIcon: () => <OrderHealthTooltip tableName={tableName} data={rowData} />,
                                render: rowData_ => (<ItemDetailSection data={rowData_} />)
                            }),
                            {
                                render: rowData_ => (<ItemDetailSection data={rowData_} />)
                            },
                        ]
                    }
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { tableName, columns, type } = ownProps;
    return {
        columnOrder: get(state,
            `options.columns.${tableName}`,
            getUpdatedColumns(get(state, `user.currentView.${tableName}.columns`, columns?.columnOrder), columns?.columnOrder)
        ),
        filters: get(state, `options.filters.${tableName}`, {}),
        businessUnits: get(state, "favorites.tempBusinessUnit", []),
        tabledata: get(state, ['tabledata', type, tableName, "data"], {}),
        tableReqBody: get(state, ['tabledata', type, tableName, "payload"], {}),
        loading: get(state, ['tabledata', type, tableName, "loading"], true),
        tablebody: get(state, `options.tablebody.${tableName}`, {}),
    };
};


export default connect(mapStateToProps, null)(TableComponent);
