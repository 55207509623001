import React from "react";
import { makeStyles, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { searchFilterStyles } from "theme";

const useStyles = makeStyles(searchFilterStyles);

export default (props) => {
    const classes = useStyles();
    const { handleSubmit, handleChange, searchPhrase, placeholder } = props;
    return (
        <form className={classes.root} noValidate autoComplete="off"  data-testid="searchfield" onSubmit={(e) => {
            handleSubmit(e)
        }}>
            <div className={classes.root}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        data-testid="searchinput"
                        placeholder={placeholder}
                        value={searchPhrase || ""}
                        onChange={e => handleChange(e.target.value)}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                        }}
                        inputProps={{ "aria-label": "search" }}
                    />
                </div>
            </div>
        </form>
    );
}

