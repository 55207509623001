import { emeaFavoriteConstants } from "reduxLib/constants/EMEA/emeaFavoriteConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const emeaBusinessFavService = variables => dispatch => {
  dispatch({
    type: emeaFavoriteConstants.EMEA_BU_FAVORITES_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.buFav, {
    method: "PUT",
    body: variables,
  })
    .then(async (d) => {
      if (d.status === 200) {
        return d;
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_fav_bu_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaFavoriteConstants.EMEA_BU_FAVORITES_SUCCESS,
        payload: variables.favouriteBusinessUnits
      });

      // emeaUpdateFavs({
      //   favouriteBusinessUnits: ["CONSUMER"]
      // })(dispatch);

      dispatch({
        type: emeaFavoriteConstants.EMEA_BU_FAVORITES_LOADING,
        payload: false
      })
    })
    .catch(err => {
      dispatch({
        type: emeaFavoriteConstants.EMEA_BU_FAVORITES_FAILED,
        error: err
      });
      
      dispatch({
        type: emeaFavoriteConstants.EMEA_BU_FAVORITES_LOADING,
        payload: false
      });
    })
}

export const emeaGetAllFavService = variables => dispatch => {
  dispatch({
    type: emeaFavoriteConstants.EMEA_FAVORITES_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.allFavs, {
    method: "GET"
  })
    .then(async (d) => {
      if (d.status === 200) {
        return d.json();
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_get_allfav_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaFavoriteConstants.EMEA_FAVORITES_SUCCESS,
        payload: d,
        error: null
      });

      dispatch({
        type: emeaFavoriteConstants.EMEA_FAVORITES_LOADING,
        payload: false
      })
    })
    .catch(err => {
      dispatch({
        type: emeaFavoriteConstants.EMEA_FAVORITES_FAILED,
        payload: {},
        error: err
      });
      
      dispatch({
        type: emeaFavoriteConstants.EMEA_FAVORITES_LOADING,
        payload: false
      });
    })
}

export const emeaUpdateFavs = variables => dispatch => {
  dispatch({
    type: emeaFavoriteConstants.EMEA_UPDATE_FAVORITES,
    payload: variables,
    error: null
  });
}