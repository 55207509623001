import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useTranslation } from 'react-i18next';
import questionSVG from "assets/images/questionCircle.svg";

const useStyles = makeStyles((theme) => ({
    box: {
        minHeight: theme.spacing(10),
        padding: theme.spacing(5)
    },
    actions: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(2)
    },
    descriptionBox: {
        display: "flex",
        justifyContent: "center",
        fontSize: theme.spacing(2),
        padding: theme.spacing(4),
        alignItems: "center"
    },
    image: {
        marginRight: theme.spacing(1)
    },
    submit: {
        border: `2px solid ${theme.palette.secondary.base}`,
        marginRight: theme.spacing(1)
    },
    cancel: {
        border: `1px solid ${theme.palette.secondary.base}`,
    }
}));

export default function CustomDialog(props) {
    const { description, event, open, setOpen } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            draggable={false}
            className={classes.box}
        >
            <DialogContent>
                <DialogContentText className={classes.descriptionBox}>
                    <img src={questionSVG} className={classes.image}/>
                    <Typography className={classes.description}>{description}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button className={classes.submit} variant="contained" color="primary" onClick={event}>
                    {t("yes")}
                </Button>
                <Button className={classes.cancel} variant="contained" onClick={handleClose} color="secondary">
                    {t("no")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
