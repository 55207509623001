import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from "clsx";


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: ({themeType}) => {
            if(themeType ==="light") {
                return theme.palette.tooltip.lightPurpleblue
            }
            return theme.palette.white;
        },
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.12)",
    },
    tooltip: {
        backgroundColor: ({themeType}) => {
            if(themeType ==="light") {
                return theme.palette.tooltip.lightPurpleblue
            }
            return theme.palette.white;
        },
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.12)",
        color: 'black',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid ${theme.palette.iron}`,
        minWidth : 190,
    },
}));

const template = makeStyles(theme => ({
    bar: {

        paddingLeft: 5,
        height: "100%",
        marginLeft: 0,
        display: 'flex',
        alignItems: 'center',
    },
    square: {
        width: 10,
        height: 10,
        backgroundColor: theme.palette.primary.main,
        marginRight: 5
    },
    color:theme.palette.white
    
}))

export default function BootstrapTooltip(props) {
    const classes = useStylesBootstrap(props);
    const classes2 = template();
    let colorProps = {};
    if (props.color) {
        colorProps = {
                className: classes2.square,
                style: { backgroundColor: props.color }
        }
    }

    const Title = () => {
        return (
            <div data-testid='bootstrapTooltip' className={clsx(classes2.bar)}>
                <div {...colorProps} />
                {props.title}
            </div>
        )
    }
    
    return <Tooltip elevation={10} arrow interactive placement={props.placement} enterTouchDelay='10' classes={classes} {...props} title={<Title />} />;
}