import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid, useTheme } from '@material-ui/core';
import clsx from "clsx";
import D3Tooltip from "../D3Charts/D3Tooltip";
import { tooltipLinkArgumentsMap } from 'reduxLib/constdata/filters';
const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        height: 320
    },
    border: {
        border: `2px solid ${theme.palette.white}`
    },
    noborderBottom: {
        borderBottom: `2px solid ${theme.palette.white}`
    },
    borderRight: {
        borderRight: `2px solid ${theme.palette.white}`
    },
    borderTop: {
        borderTop: `2px solid ${theme.palette.white}`
    },
    subsection:{
        paddingTop: theme.spacing(1)
    },
    full: {
        height: 'auto'
    },
    confirmedcube: {
        backgroundColor: 'rgba(33,150,243,0.2)',
        color: 'white',
        width: '35%',
        textAlign: 'center',
        paddingTop: theme.spacing(1),
        "& > p, & > h1":{
            width: "100%"
        } 
    },
    nonconfirmedcube: {
        color: 'white',
        width: '65%',
        textAlign: 'center',
        margin: theme.spacing(2)
    },
    largetext: {
        fontSize: theme.spacing(5.25),
        fontWeight: 600,
        // margin: theme.spacing(1),
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(3)
        }
    },
    largetext2: {
        fontSize: 20,
        fontWeight: 200,
        textAlign: 'center',
        border: "2px solid",
        display: "inline-block",
        padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`,
        borderRadius: theme.spacing(2),
        margin: `${theme.spacing(.25)}px ${theme.spacing(0)}px`,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(1.45)
        }
    },
    subtext: {
        fontSize: 18,
        color: theme.palette.white,
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(1.35)
        }
    },
    yellow: {
        color: theme.palette.white,
        borderColor: theme.palette.chartColors.risk,
        backgroundColor: theme.palette.chartColors.risk
    },
    red: {
        color: theme.palette.white,
        borderColor: theme.palette.chartColors.unhealthy,
        backgroundColor: theme.palette.chartColors.unhealthy
    },
    minibox:{
        "& > span":{
            paddingLeft:theme.spacing(1),
            paddingRight:theme.spacing(1)
        }
    },
    hyperlink:{
        cursor:'pointer',
        textDecoration:'underline',
        letterSpacing: '1px',
        padding : theme.spacing(.4),
        '&:hover' :{
            color: theme.palette.link.hover
        }
    }
}));

export const BlockedFreeOrderTreeMapBuilder = ({chartsData, type, subtype, setDetails, ...rest }) => {
    const { t } = useTranslation();
    let mappedData = {};
    let total = 0;
    chartsData.map(d => {
        if (d.state === "SO_COMPLETELY_CONFIRMED_CUBE") {
            mappedData[d.state] = {
                ...d,
                name: d.stateDesc,
                value: d.totalCount,
            }
            total += d.totalCount || 0;
        }
        if (d.state === "SO_NOT_COMPLETELY_CONFIRMED_CUBE") {
            mappedData[d.state] = {
                ...d,
                name: d.stateDesc,
                blueCount: d.greenCount,
            }
            total += d.totalCount;
        }
    });

    const classes = useStyles();

    return (
        <div className={classes.root}  data-testid="ordersboxdesign">
            <Box display="flex" justifyContent="center" height="auto" className={clsx(classes.border, classes.full)} m={0} p={0}>
                <Grid container alignContent="center" data-testid="confirmedCube" className={clsx(classes.borderRight, classes.confirmedcube)}>
                    <Typography variant="h1" className={classes.largetext}>
                        {
                            mappedData?.SO_COMPLETELY_CONFIRMED_CUBE?.value || 0
                        }
                    </Typography>
                    <Typography variant="h1" className={classes.subtext}>
                        {
                            mappedData?.SO_COMPLETELY_CONFIRMED_CUBE?.name || null
                        }
                    </Typography>
                </Grid>
                <Box className={clsx(classes.nonconfirmedcube)}>
                    <Grid container justify="center" alignItems="center" data-testid="nonConfirmedCube" className={classes.minibox} >
                        <Typography variant="h1" component="span"  className={classes.largetext}>
                            {
                                mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.totalCount || 0
                            }
                        </Typography>
                        <Typography variant="h1" component="span"  className={classes.subtext}>
                            {
                                mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.name || null
                            }
                        </Typography>
                    </Grid>
                    <Grid container className={clsx(classes.borderTop, classes.subsection)} >
                        <Grid item xs={4} className={clsx(classes.borderRight)} >
                            <Typography variant="h1" className={classes.subtext}>{t('ship_outside_2_days')}</Typography>
                            <D3Tooltip placement={"top"} color={useTheme().palette.chartColors['healthy']} title={
                                <div className={clsx(classes.hyperlink)} onClick={() => {
                                        setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap["SO_NOT_COMPLETELY_CONFIRMED_CUBE"], health: "GREEN", state: subtype }, true)
                                }}> 
                                    {`${t('healthy')} : ${mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.blueCount || 0}`}
                            </div>}>
                            <Typography variant="h1" data-testid="blockfreehealthy" className={clsx(classes.largetext2)} >
                                {
                                     mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.blueCount || 0
                                }
                            </Typography>
                            </D3Tooltip>
                        </Grid>
                        <Grid item container xs={8}>
                            <Grid item xs={12}><Typography variant="h1" className={classes.subtext}>{t('ship_within_2_days')}</Typography></Grid>
                            <Grid item xs={6} className={clsx(classes.center, classes.padding)} >
                                <D3Tooltip placement={"top"} color={useTheme().palette.chartColors['unhealthy']} title={
                                    <div className={clsx(classes.hyperlink)} onClick={() => {
                                            setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap["SO_NOT_COMPLETELY_CONFIRMED_CUBE"], health: "RED", state: subtype }, true)
                                    }}> 
                                        {`${t('critical_risk')} : ${mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.redCount || 0}`}
                                    </div>}>
                                <Typography variant="h1" data-testid="blockfreeunhealthy" className={clsx(classes.largetext2, classes.red)}>
                                    {
                                        mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.redCount || 0
                                    }
                                </Typography>
                                </D3Tooltip>
                                <Typography variant="h1" className={classes.subtext}>{t('critical_risk')}</Typography>
                            </Grid>
                            <Grid item xs={6} className={clsx(classes.center, classes.padding)} >
                                <D3Tooltip placement={"top"} color={useTheme().palette.chartColors['risk']} title={
                                    <div className={clsx(classes.hyperlink)} onClick={() => {
                                            setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap["SO_NOT_COMPLETELY_CONFIRMED_CUBE"], health: "YELLOW", state: subtype }, true)
                                    }}> 
                                        {`${t('potential_risk')} : ${mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.yellowCount || 0}`}
                                    </div>}>
                                    <Typography variant="h1"  data-testid="blockfreerisk" className={clsx(classes.largetext2, classes.yellow)} >
                                        {
                                            mappedData?.SO_NOT_COMPLETELY_CONFIRMED_CUBE?.yellowCount || 0
                                        }
                                    </Typography>
                                </D3Tooltip>
                                <Typography variant="h1" className={classes.subtext}>{t('potential_risk')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}
