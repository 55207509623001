import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Grid } from '@material-ui/core';
import { useSelector } from "react-redux";
import Card from "./Card";
import PerformanceMapTable from "./PerformanceMapTable";
import { isArray, filter } from "lodash";
import { getStatusData } from "helpers";

// const useStyles = makeStyles((theme) => ({
// }))

const LocationPerformance = (props) => {
  // const classes = useStyles();

  const { setDetails, type, ...rest } = props;
  const { performance: perfomanceStats } = useSelector(({ sites }) => sites);

  let perfData = isArray(perfomanceStats) ? perfomanceStats : [];
  perfData = filter(perfData, {siteNum: type})
  perfData = perfData.map(d => {
    const data = getStatusData({
        ...d,
        actualHours: d.aheadOrBehind == "BEHIND" ? -(d.hours) : d.hours,
        hours: parseFloat(d.hours).toFixed(1)
    }, "actualHours");
    return {
        ...d,
        ...data
    };
  })

  const Title = () => {
    const { t } = useTranslation();
    return (
      <Grid container alignItems='center' justify="space-between" data-testid="locationPerformance">
        {
          <Typography variant="body1" color="primary">
            {t('location_performance')}
          </Typography>
        }
      </Grid>
    )
  }

  return (
    <Card cardtitle={<Title />}	>
      <PerformanceMapTable data={perfData} setDetails={setDetails} type={type} perfTableRedirection={rest.perfTableRedirection} singleLocation={true} />
    </Card>
);
}

export default LocationPerformance;