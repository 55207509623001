import moment from "moment-timezone";

const stockConstraintReportFilters = {
    loadDateTimeOriginTZ: {
        type: "date",
        name: "load_date",
        shortName: 'Load Date',
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        data: {
            startTime: moment().format("YYYY-MM-DD"),
            endTime: moment().add(2, "days").format("YYYY-MM-DD")
        }
    },
}

export default stockConstraintReportFilters;
