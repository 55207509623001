import reprintLSRConstants from "../constants/reprintConstants"
import { endpoints } from "helpers";
import fetch from "./serviceHelpers";

const errorCodes = {
    ct_exec_31: "printer_name_is_not_valid",
    ct_exec_34: "load_diagram_not_submitted",
    ct_exec_37: "ucc_label_not_required",
    ct_exec_38: "addr_label_not_required"
}

export const resetReprintStatus = variables => dispatch => {
    dispatch({
        type: reprintLSRConstants.RESET_REPRINT_STATUS,
        payload: {}
    })
}

export const resetPrecheckStatus = variables => dispatch => {
    dispatch({
        type: reprintLSRConstants.RESET_PRECHECK_STATUS,
        payload: {}
    })
}

export const reprintLSRService = (variables) => dispatch => {
    dispatch({
        type: reprintLSRConstants.REPRINT_LSR_LOADING,
        payload: true
    });

    return fetch(`${endpoints.osm.reprintLSR}`, {
        method: "POST",
        body: variables
    })
        .then(async (d) => {
            if (d.status === 200) {
                dispatch({
                    type: reprintLSRConstants.REPRINT_LSR_SUCCESS,
                    payload: {
                        ...variables,
                        status: "success",
                        message: "reprint_lsr_successful"
                    }
                });
            } else {
                const error = await d?.json();
                throw error;
            }
        })
        .catch((e) => {
            dispatch({
                type: reprintLSRConstants.REPRINT_LSR_FAILED,
                payload: {
                    ...variables,
                    status: "failed",
                    message: errorCodes[e.exceptionCode] || "print_request_failed"
                }
            });
        })
}

export const precheckService = (variables) => dispatch => {
    dispatch({
        type: reprintLSRConstants.REPRINT_PRECHECK_LOADING,
        payload: true
    });

    return fetch(`${endpoints.osm.precheck}?originSiteNum=${variables?.originSiteNum}`, {
        method: "POST",
        body: variables
    })
        .then(async (d) => {
            if (d.status === 200) {
                const response = await d.json();
                dispatch({
                    type: reprintLSRConstants.REPRINT_PRECHECK_SUCCESS,
                    payload: {
                        ...variables,
                        ...response,
                        status: "success",
                        message: null
                    }
                });
            } else {
                const error = await d?.json();
                throw error;
            }
        })
        .catch((e) => {
            dispatch({
                type: reprintLSRConstants.REPRINT_PRECHECK_FAILED,
                payload: {
                    ...variables,
                    ...e,
                    status: "failed",
                    message: errorCodes[e.exceptionCode] || "request_failed"
                }
            });
        })
}

export const printUCLService = (variables) => dispatch => {
    dispatch({
        type: reprintLSRConstants.PRINT_UCL_LOADING,
        payload: true
    });

    return fetch(`${endpoints.osm.printUCL}?originSiteNum=${variables?.originSiteNum}`, {
        method: "POST",
        body: variables
    })
        .then(async (d) => {
            if (d.status === 200) {
                dispatch({
                    type: reprintLSRConstants.PRINT_UCL_SUCCESS,
                    payload: {
                        ...variables,
                        status: "success",
                        message: "print_ucl_label_successful"
                    }
                });
            } else {
                const error = await d?.json();
                throw error;
            }
        })
        .catch((e) => {
            dispatch({
                type: reprintLSRConstants.PRINT_UCL_FAILED,
                payload: {
                    ...variables,
                    status: "failed",
                    message: errorCodes[e.exceptionCode] || "print_request_failed"
                }
            });
        })
}

export const printADDRService = (variables) => dispatch => {
    dispatch({
        type: reprintLSRConstants.PRINT_ADDR_LOADING,
        payload: true
    });

    return fetch(`${endpoints.osm.printADDR}?originSiteNum=${variables?.originSiteNum}`, {
        method: "POST",
        body: variables
    })
        .then(async (d) => {
            if (d.status === 200) {
                dispatch({
                    type: reprintLSRConstants.PRINT_ADDR_SUCCESS,
                    payload: {
                        ...variables,
                        status: "success",
                        message: "print_addr_label_successful"
                    }
                });
            } else {
                const error = await d?.json();
                throw error;
            }
        })
        .catch((e) => {
            dispatch({
                type: reprintLSRConstants.PRINT_ADDR_FAILED,
                payload: {
                    ...variables,
                    status: "failed",
                    message: errorCodes[e.exceptionCode] || "print_request_failed"
                }
            });
        })
}