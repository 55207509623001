import { tabledataConstants } from '../constants';
import { get } from "lodash";

const tabledataReducer = (state = {}, action) => {
    const { variables } = action;
    const { type, tableName } = get(variables, "body", {});
    switch (action.type) {
        // We are storing the table data in corrosponding order Type/TableName data and loaders to retrieve later

        case tabledataConstants.TABLE_DATA_FETCH_SUCCESS:
        case tabledataConstants.TABLE_DATA_FETCH_FAILURE:
            return {
                ...state,
                [type]: {
                    ...(get(state, [type], {})),
                    [tableName]: {
                        ...(get(state, [type, tableName], {})),
                        data: action.payload,
                        payload: variables || {}
                    }

                }
            };

        case tabledataConstants.LOADING:
            return {
                ...state,
                [type]: {
                    ...(get(state, [type], {})),
                    [tableName]: {
                        ...(get(state, [type, tableName], {})),
                        loading: action.payload,
                    }
                }
            };
        default:
            return state
    }
}

export default tabledataReducer;
