import React, { useState } from "react";
import { makeStyles, Grid, Box, Select, MenuItem, IconButton, Collapse } from '@material-ui/core';
import { isEmpty } from 'lodash';
import moment from "moment-timezone";
import GuardrailChart from "components/D3Charts/GuardrailChart";
import { processGuardRailData } from "helpers";
import { DateRange as DateIcon } from '@material-ui/icons';

const useStyles2 = makeStyles((theme) => ({
  select: {
    borderTop: `1px solid ${theme.palette.white}`,
    borderLeft: `1px solid ${theme.palette.white}`,
    borderRight: `1px solid ${theme.palette.white}`,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
  },
  menu: {
    backgroundColor: theme.palette?.form?.baseLight,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  griditem: {
    width: '33%'
  },
  iconbutton: {
    backgroundColor: "transparent"
  }
}));

export const DistributionShipmentsChartBuilder = (props) => {
  const classes2 = useStyles2();
  const { 
    margins, height, type, subtype, guardrailData, 
    viewDetails, chartConfig, hideRefLine, isNotALink
  } = props;
  const data = processGuardRailData(guardrailData);
  const { keys = [], refLine = "", xKey = "", subRefLines = [] } = chartConfig || {};
  const [date, updateDate] = useState(null);
  const [subdata, setSubdata] = React.useState([]);

  const dateOptions = [];
  !isEmpty(guardrailData) && guardrailData.forEach((d, i) => {
    dateOptions.push({ name: moment(d.dateTime).format("DD-MMM-YYYY"), value: d?.dateTime, today: d?.today })
  });

  React.useEffect(() => {
    updateDate(dateOptions?.filter(d => d.today === "Y")?.[0]?.value || null)
  }, [guardrailData]);

  React.useEffect(() => {
    const filtered = data?.filter(d => d.dateTime === date)[0]
    if (filtered) {
      setSubdata([filtered]);
    }
  }, [date, guardrailData]);


  return (
    <>
      <Grid container data-testid="distributionTL">
        <Grid item xs={12}>
          <GuardrailChart
            keys={keys}
            data={data}
            xKey={xKey}
            margins={margins}
            height={height}
            wrapit
            allkeys={keys}
            refLine={refLine}
            type={type}
            subtype={subtype}
            currentDay={true}
            hideRefLine={hideRefLine}
            isNotALink={isNotALink}
          />
        </Grid>
      </Grid>
      <Collapse in={viewDetails}>
        <Box mr={2} alignItems="center" >
          <div>
            <IconButton className={classes2.iconbutton}>
              <DateIcon />
            </IconButton>
            <Select color="primary"
              className={classes2.select}
              value={date}
              label='datetime'
              id="dateselect"
              onChange={({ target: { value } }) => {
                updateDate(value);
              }} >
              {!isEmpty(dateOptions) && dateOptions.map((d, i) => {
                return <MenuItem className={classes2.menu} selected={i === 0} value={d.value}>{d.name}</MenuItem>
              })}
            </Select>
          </div>
        </Box>
        <Grid container xs={12} md={12} lg={6}>
          <Grid item className={classes2.griditem}>
            <GuardrailChart
              keys={[keys[0]]}
              data={[{ ...subdata[0], businessUnit: "Consumer" }]}
              xKey={"businessUnit"}
              margins={margins}
              height={height}
              allkeys={keys}
              refLine={subRefLines[0]}
              showRef={true}
              type={type}
              subtype={subtype}
              wrapit
            />
          </Grid>
          <Grid item className={classes2.griditem}>
            <GuardrailChart
              keys={[keys[1]]}
              data={[{ ...subdata[0], businessUnit: "Professional" }]}
              xKey={"businessUnit"}
              margins={margins}
              height={height}
              allkeys={keys}
              refLine={subRefLines[1]}
              showRef={true}
              type={type}
              subtype={subtype}
              wrapit
            />
          </Grid>
          <Grid item className={classes2.griditem}>
            <GuardrailChart
              keys={[keys[2]]}
              data={[{ ...subdata[0], businessUnit: "STO" }]}
              allkeys={keys}
              xKey={"businessUnit"}
              margins={margins}
              height={height}
              showRef={true}
              refLine={subRefLines[2]}
              type={type}
              subtype={subtype}
              wrapit
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );

}
