import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EMEAChartCard from './EMEA_Common/EMEAChartCard';
import { chartsHeight as height } from 'helpers/styleHelpers';
import clsx from "clsx";
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import { useStyles } from './EMEA_Common/EMEAKPIsWrapper';
import EMEASelectMonthFilter from './EMEA_Common/EMEASelectMonthFilter';
import { getInitialDateobj } from 'helpers/emeaHelpers';

const useStyles2 = makeStyles((theme) => ({
    wrapperRoot: {
        marginTop: theme.spacing(2)
    },
    text: {
        marginBottom: theme.spacing(1)
    }
}));

export default function EMEAOpenOrdersInvoiceKPIs() {
    const classes = useStyles({ height });
    const theme = useTheme();
    const { t } = useTranslation();
    const classes2 = useStyles2();
    
    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    const [chartWidth, setChartWidth] = useState(() => {
        return window.innerWidth - window.innerWidth / 10 * 1
    });

    let resizeWindow = () => {
        setChartWidth(window.innerWidth - window.innerWidth / 10 * 1);
    };

    const [refresh, setRefresh] = useState(false);

    const {
        documentDate
    } = useEMEAGlobalContext();

    const [selectedMonth, setSelectedMonth] = useState(() => getInitialDateobj(documentDate));


    const additionalFilters = {
        orderType: "ORDER_ENTRY"
    }

    const chart = {
        subtype: "SUBTYPE_EMEA_OPEN_ORDERS_INVOCIE_OPEN_GAP",
        chart: "trendline",
        colorPalette: "set10",
        legend: false,
        colorBy: "yKey",
        customTooltipKey: "state",
        xKey: "stateDescCustom",
        keys: ["invoicePer", "openPer", "gapPer"],
        keyLabelMaps: {
            invoicePer: t('invoiced'),
            openPer: t('open'),
            gapPer: t('gap'),
        },
        displayKey: "stateDesc",
        tooltipKey: "state",
        maxWidth: chartWidth,
        minWidth: chartWidth,
        height: 500,
        legendGroup: true,
        monthFilter: true, 
        showMonthFilter: false,
        axisSuffix: "%",
        barSuffix: "%",
        legendGroupItems: [
            { type: 'square', color: theme.palette.set10[0], label: t('invoiced'), key: "invoicePer" },
            { type: 'square', color: theme.palette.set10[1], label: t('open'), key: "openPer" },
            { type: 'square', color: theme.palette.set10[2], label: t('gap'), key: "gapPer" },
        ],
        decimals: 2,
        refresh,
        setRefresh,
        showEllipsis: true,
        showXAxisLabel: !isMobile,
        showBarLabel: !isMobile, 
        testId: "invoice-open-gap-chart"
    }

    const updateDocDate = (newDates) => {
        setSelectedMonth(newDates);
        setRefresh(true);
    }

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (
        <div className={classes2.wrapperRoot}>
            <Typography component={"h1"} className={classes2.text}>{t("invoiced_openand_gap_country_code")}</Typography>
            <EMEASelectMonthFilter dates={documentDate} onChange={updateDocDate} />
            <Card className={clsx(classes.mt2, classes.root)} data-testid="openOrdersInvoicekpiswrapper">
                <CardContent p={0}>
                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item p={1}>
                            <EMEAChartCard
                                {...chart}
                                excludedFilters={additionalFilters}
                                boxShadow="none"
                                customDocDate={selectedMonth}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
}
