import {emeaCustomerConstants} from "reduxLib/constants/EMEA/emeaCustomerConstants";
import isEmpty from "lodash/isEmpty";

const initialState = {
  customer_search_loading: false,
  customers: [],
  customer_search_err: null,
  cust_no_data: false,
  cust_fav_loading: false,
  cust_fav: [],
  cust_fav_err: null
}

const emeaCustomerReducer = (state = initialState, action) => {
  switch (action.type) {

    case emeaCustomerConstants.EMEA_CUST_SEARCH_LOADING:
      return { ...state, customer_search_loading: action.payload };

    case emeaCustomerConstants.EMEA_CUST_SEARCH_SUCCESS:
      return { ...state, customers: action.payload, customer_search_err: action.error, cust_no_data: isEmpty(action.payload) ? true : false };

    case emeaCustomerConstants.EMEA_CUST_SEARCH_FAILED:
      return { ...state, customers: action.payload, customer_search_err: action.error, cust_no_data: false };

    case emeaCustomerConstants.EMEA_CUST_SEARCH_RESET:
      return { ...state, customers: [], cust_no_data: false, customer_search_err: null };  

    case emeaCustomerConstants.EMEA_CUST_FAV_LOADING:
      return { ...state, cust_fav_loading: action.payload };

    case emeaCustomerConstants.EMEA_CUST_FAV_SUCCESS:
      return { ...state, cust_fav: action.payload, cust_fav_err: 'success' };

    case emeaCustomerConstants.EMEA_CUST_FAV_FAILED:
      return { ...state, cust_fav_err: action.error };
  
    default:
      return state;
  }
}

export default emeaCustomerReducer;  