import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reduxLib/reducers';

const initialState = {
  user: {
    filterLayouts: {
      NETWORK: {
        tableName: "network",
        views: []
      }
    },
    currentView: {},
    initialLayouts: {}
  }
};

let middleware = [thunk];
let enhancers = [applyMiddleware(...middleware)];

const configureStore = () => {
  return createStore(
    rootReducer,
    initialState,
    compose(...enhancers)
  );
}

export default configureStore;