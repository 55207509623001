import { combineReducers } from 'redux';
import getLocationsReducer from './getLocationsReducer';
import getFavUnfavReducer from './getFavUnfavReducer';
import selectionsReducer from "./selectionsReducer"
import authReducer from './authReducer';
import optionsReducer from './optionsReducer';
import chartsReducer from './getChartsReducer';
import downloadReducer from './downloadReducer';
import getFilterLayoutsReducer from './getFilterLayoutsReducer';
import getShipmentDetailsReducer from './getShipmentDetailsReducer';
import feedbackReducer from './feedbackReducer';
import tabledataReducer from './tabledataReducer';
import analyticsReducer from './analyticsReducer';
import guardrailsReducer from './guardrailsReducer';
import stockConstraintReportReducer from './stockConstraintReportReducer';
import selfAlertingReducer from './selfAlertingReducer';
import loadDiagramReducer from './loadDiagramReducer';
import osmReducer from './osmReducer';
import osmAuthReducer from './osmAuthReducer';
import reprintReducer from "./reprintReducer";
import osmLsrStackDetailsReducer from "./osmLsrStackDetailsReducer";

// EMEA Reducers
import emeaLocationsReducer from './EmeaReducers/emeaLocationsReducer';
import emeaCustomerReducer from './EmeaReducers/emeaCustomerReducer';
import emeaFilterByReducer from './EmeaReducers/emeaFilterByReducer';
import emeaSalesOfficeGroupReducer from './EmeaReducers/emeaSalesOfficeGroupReducer';
import emeaSummaryReducer from './EmeaReducers/emeaSummaryReducer';
import emeaFavoriteReducer from './EmeaReducers/emeaFavoriteReducer';
import emeaTableReducer from './EmeaReducers/emeaTableReducer';
import emeaEditCommentReducer from './EmeaReducers/emeaEditCommentReducer';
import emeaViewsReducer from './EmeaReducers/emeaViewsReducer';

// LAO Reducers
import laoLocationsReducer  from './LaoReducers/laoLocationsReducer';
import laoViewsReducer from './LaoReducers/laoViewsReducer';
import laoFavReducer from './LaoReducers/laoFavReducer';
import laoChartsReducer from './LaoReducers/laoChartsReducer';
import laoTableReducer from './LaoReducers/laoTableReducer';

export default combineReducers({
    sites: getLocationsReducer,
    favorites: getFavUnfavReducer,
    items: selectionsReducer,
    auth: authReducer,
    options: optionsReducer,
    charts: chartsReducer,
    downloads: downloadReducer,
    user: getFilterLayoutsReducer,
    shipment:getShipmentDetailsReducer,
    feedbackform: feedbackReducer,
    tabledata: tabledataReducer,
    analytics: analyticsReducer,
    guardrails:guardrailsReducer,
    stockConstraintReport: stockConstraintReportReducer,
    selfalerting: selfAlertingReducer,
    loadDiagram: loadDiagramReducer,
    osm: osmReducer,
    osmAuth: osmAuthReducer,
    osmReprint: reprintReducer,
    osmLsrStackDetail: osmLsrStackDetailsReducer,

    // EMEA reducers
    emeaSites: emeaLocationsReducer,
    emeaChannelOrgs: emeaFilterByReducer,
    emeaCustomers: emeaCustomerReducer,
    emeaSalesOfc: emeaSalesOfficeGroupReducer,
    emeaSummaries: emeaSummaryReducer,
    emeaGlobalFavs: emeaFavoriteReducer,
    emeaTable: emeaTableReducer,
    emeaTableComment: emeaEditCommentReducer,
    emeaViews: emeaViewsReducer,

    //LAO
    laoSites: laoLocationsReducer,
    laoViews: laoViewsReducer,
    laoFavorites: laoFavReducer,
    laoCharts: laoChartsReducer,
    laoTable: laoTableReducer
});