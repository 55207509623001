import React  from"react";
import { useDispatch } from "react-redux";
import { Tabs, Tab,makeStyles, Box, Grid, useTheme, useMediaQuery } from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import KCChip from "components/common/Chip";
import { isEmpty } from "lodash";
import {
    removeItemCusoSF
} from 'reduxLib/services/selfAlertingService';

const chipStyles = makeStyles((theme)=>({
    tabRoot:{
        minWidth: "auto",
        padding:`${theme.spacing(.75)}px 0px`,
        maxWidth:"100%"
    }
}))

export default ({chipsObjectArray, action, selfalert}) => {

    const dispatch = useDispatch()
    const classes = chipStyles()

    const handleDelete = (chipToDelete) => () => {
        let  dispatchObject = {}
        if(chipToDelete.selectionType === "CUST"){
            const salesOrg = [chipToDelete.salesOrg]
            dispatchObject.reducerObject = {
                ...chipToDelete,
                salesOrg
            }
        }else if (chipToDelete.selectionType === "SALES_OFFICE"){
            const salesOrg = chipToDelete.salesOrg
            dispatchObject.reducerObject = {
                ...chipToDelete,
                salesOrg
            }
        }else{
            dispatchObject = {
            shortName:chipToDelete.value,                                           
            reducerObject:chipToDelete.reducerObject
            }
        }

        if(selfalert) {
            dispatch(removeItemCusoSF(dispatchObject));
        } else {
            dispatch(action(dispatchObject));
        }
    };

    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))
    return (
    <Grid item>
        {!isEmpty(chipsObjectArray) && <Box my={!isMobile ? 2 : 0}>
        <Tabs data-testid='customerchips'
            textColor="primary"
            className={classes.tabsRoot}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
          {
                chipsObjectArray.map((obj) => {     
                    const label = obj.selectionType === "CUST" ? `${obj.customerName}(${obj.salesOrg})` : obj.selectionType === "SALES_OFFICE" ? 
                        !obj.salesGroup || isEmpty(obj.salesGroup) ? `${obj.salesOffice}(${obj.salesOrg})` : `${obj.salesOffice}(${obj.salesOrg}) : ${obj.salesGroup.toString()}` : obj.value 
                    
                        return (
                        <Tab className={classes.tabRoot} label={
                            <KCChip
                                name={label}
                                disableRipple id="custchip"                      
                                color="primary"
                                deleteIcon={<HighlightOffRoundedIcon />}
                                label={label|| obj}
                                onDelete={handleDelete(obj)}
                                variant="outlined"
                            />}
                        />)
                })
            }
        </Tabs>
        </Box>}
    </Grid>
    )
}