import React from "react";
import { Link } from 'react-router-dom';

const FourKitesLink = ({ data }) => {
    const { expectedDeliveryDateTime, shipmentNum } = data;

    if (expectedDeliveryDateTime === '-' || !expectedDeliveryDateTime) {
        return "-"
    }

    return (
        <Link
            style={{ color: '#0091EA' }}
            to={`${window.location.pathname}/shipment/${shipmentNum}${window.location.search}`}>
            {expectedDeliveryDateTime}
        </Link>
    )
}

export default FourKitesLink;