import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { scaleBand, scaleLinear, scaleOrdinal, max, sum } from 'd3'
import ResponsiveWrapper from 'components/charts/ResponsiveWrapper';
import Axes from 'components/D3Charts/Axes';
import Bars from 'components/D3Charts/Bars';
import TrendLine from 'components/D3Charts/TrendLine';
import { emeaChartColorPanel } from 'helpers/styleHelpers';
import { Typography, makeStyles, useTheme } from '@material-ui/core';
import { Legends } from 'components/D3Charts/Pies';
import { isEmpty } from 'lodash';
import LegendGroup from 'components/D3Charts/LegendGroup';

const useStyles = makeStyles((theme) => ({
    viewDetails: {
        paddingLeft: theme.spacing(3),
        cursor: 'pointer',
        color: "inherit",
        background: "none",
        textDecoration: "underline",
        '&:after': {
            content: "' 🡺'",
            display: "inline-block",
            textDecoration: "none",
            paddingLeft: theme.spacing(.5),
        },
    },
    title: {
        color: "inherit",
        background: "none",
        display: "flex",
        textTransform: "uppercase",
        fontSize: theme.spacing(2),
        justifyContent: "center"
    }
}));

const TrendLineChart = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        xKey, yKey, height, loading, testId,
        themeType, trendline, yLabel, maxWidth,
        colorBy, colorPalette, legend,
        showXAxisLabel, viewDetails, dx,
        setDetails, displayKey, tooltipKey,
        xLabel, showEllipsis, showBarLabel,
        trendLineKey, xAxisTitle, legendGroup,
        legendGroupItems, tredlineStyle, ...rest
    } = props;
    const [data, setData] = useState([]);
    const [totalSum, setTotalSum] = useState(0);
    const keys = isEmpty(props.keys) ? [yKey] : props.keys;

    const colorPanel = emeaChartColorPanel;

    useEffect(() => {
        let totalSum = 0;
        setData(rest.data.map(d => {
            const sumOf = sum([...keys, trendLineKey], k => +d[k]);
            totalSum += sumOf;
            return { ...d, total: sumOf };
        }))
        setTotalSum(totalSum)
    }, [rest.data]);

    const margins = { top: 10, left: 80, right: 30, bottom: 30 };

    const svgDimensions = {
        width: maxWidth ? maxWidth : rest.parentWidth,
        height: height - (legend ? 50 : 0) - (viewDetails ? 10 : 0)
    }

    let xScale = scaleBand()
        .padding(0.4)
        .paddingInner(0.3)
        .domain(data.map(d => d[xKey]))
        .range([margins.left, svgDimensions.width - margins.right])

    let yScale = scaleLinear()
        .domain([0, max(data, d => sum([...keys, trendLineKey], k => +d[k]))])
        .nice()
        .range([svgDimensions.height - margins.bottom, margins.top]);

    const xAccessor = d => d[xKey];
    const centerPos = [svgDimensions.width / 2, svgDimensions.height / 2];

    let colors = scaleOrdinal().domain(keys).range(colorPanel);
    const newColorPanel = useTheme().palette[colorPalette];

    if (colorBy === "yKey") {
        colors = scaleOrdinal().domain(keys).range(newColorPanel);
    }

    if (colorBy === "xKey") {
        colors = scaleOrdinal().domain(data.map(d => d[xKey])).range(newColorPanel);
    }

    const InfoText = () => {
        const text = loading ? "loading" : "no_data_found"
        return (
            <g transform={`translate(${centerPos})`} data-testid={"no-data-found"}>
                <text textAnchor="middle" data-testid="noData">{t(text)}</text>
            </g>
        )
    }

    return (
        <div data-testid='barChart'
            style={{
                minHeight: height + margins.bottom,
                maxWidth: svgDimensions.width + margins.left
            }}>
            <svg width={svgDimensions.width} height={(svgDimensions.height + (xLabel ? 20 : 0))}>
                {

                    (!totalSum || loading) ? <InfoText /> :
                        <g>
                            <Axes
                                wrapit={true}
                                scales={{ xScale, yScale }}
                                showEllipsis={showEllipsis}
                                margins={margins}
                                svgDimensions={svgDimensions}
                                yLabel={yLabel}
                                xLabel={xLabel}
                                showXAxisLabel={showXAxisLabel}
                                suffix={rest.axisSuffix}
                                dx={legend ? "-40%" : dx || "-50%"}
                            />
                            <Bars
                                scales={{ xScale, yScale }}
                                margins={margins}
                                xKey={xKey}
                                data={data}
                                xAccessor={xAccessor}
                                colors={colors}
                                colorBy={colorBy}
                                keys={keys}
                                themeType={themeType}
                                formatRequired
                                showBarLabel={showBarLabel}
                                hideTopText={true}
                                highlightBarlabel={true}
                                suffix={rest.barSuffix}
                                tooltipKey={rest.customTooltipKey}
                                legendGroupItems={legendGroupItems}
                                decimals={rest.decimals}
                                tooltipOptions={rest.tooltipOptions}
                                setDetails={rest.isHyperlinkTooltip ? setDetails : null}
                                currency={rest.currency || ''}
                                setDetailsData={rest.setDetailsData}
                                isHyperlinkTooltip={rest.isHyperlinkTooltip}
                                tooltipExclusion={rest.tooltipExclusion}

                            />
                            {
                                trendline &&
                                <TrendLine
                                    height={svgDimensions.height}
                                    scales={{ xScale, yScale }}
                                    margins={margins}
                                    xKey={xKey}
                                    data={data}
                                    dashed={tredlineStyle === "dashed"}
                                    yKey={trendLineKey || yKey}
                                />
                            }
                        </g>}

            </svg>
            {(legendGroup && !loading)  && <LegendGroup items={legendGroupItems} />}
            {(xAxisTitle && !loading) && <div className={classes.title}>{xAxisTitle}</div>}
            {
                legend ?
                    (!totalSum || loading) ? null :
                        <Legends
                            {...rest}
                            data={data}
                            xKey={xKey}
                            colorScale={colors}
                            setDetails={setDetails}
                            displayKey={displayKey}
                            tooltipKey={tooltipKey}
                            viewDetails={typeof viewDetails === "function"}
                        /> : null
            }
            {
                typeof viewDetails === "function" &&
                <Typography className={classes.viewDetails} variant="subtitle1" color="primary" onClick={viewDetails} to="/" data-testid={`${testId}-viewLink`}>
                    {t('view_details')}
                </Typography>
            }
        </div>
    )

}

export default ResponsiveWrapper(TrendLineChart)