export default {
  REPRINT_LSR_LOADING: 'REPRINT_LSR_LOADING',
  RESET_REPRINT_STATUS: "RESET_REPRINT_STATUS",
  REPRINT_LSR_SUCCESS: "REPRINT_LSR_SUCCESS",
  REPRINT_LSR_FAILED: "REPRINT_LSR_FAILED",
  RESET_PRECHECK_STATUS: "RESET_PRECHECK_STATUS",
  REPRINT_PRECHECK_LOADING: "REPRINT_PRECHECK_LOADING",
  REPRINT_PRECHECK_SUCCESS: "REPRINT_PRECHECK_SUCCESS",
  REPRINT_PRECHECK_FAILED: "REPRINT_PRECHECK_FAILED",
  PRINT_UCL_LOADING: "PRINT_UCL_LOADING",
  PRINT_UCL_FAILED: "PRINT_UCL_FAILED",
  PRINT_UCL_SUCCESS: "PRINT_UCL_SUCCESS",
  PRINT_ADDR_LOADING: "PRINT_ADDR_LOADING",
  PRINT_ADDR_FAILED: "PRINT_ADDR_FAILED",
  PRINT_ADDR_SUCCESS: "PRINT_ADDR_SUCCESS",
}