const distoutboundfilters = {
    searchStringList: {
        type: "text",
        name: "search",
        stringToArray: true,
        data: null
    },
    collectiveNumList: {
        type: "text",
        name: "collectiveNumber",
        stringToArray: true,
        data: null
    },
    promotionalOrder: {
        type: "checkboxradio",
        name: "promotionalOrder",
        defaultValue: null,
        data: [
            { name: "show_only_promotional_orders", value: "Yes", checked: false },
            { name: "hide_promotional_orders", value: "No", checked: false },
        ]
    },
    orderExecutionBucket: {
        type: "checkbox",
        name: "shipment_status",
        data: [
            { name: "shipment_created", value: "SHIPMENT_CREATED", checked: false },
            { name: "checked_in_by_DC", value: "CHECK_IN", checked: false },
            { name: "loading_started", value: "LOADING_STARTED", checked: false },
            { name: "ready_for_pickup", value: "TRANS_EXEC_READY_PICK_UP", checked: false },
        ]
    },
    orderTypes: {
        type: "checkbox",
        name: "order_type",
        data: [
            { name: "Cust. Order", value: "CUST", checked: false },
            { name: "STO", value: "STO", checked: false }
        ]
    },
    sites: {
        type: "sites",
        name: "origin",
        data: null
    },
    destName: {
        type: "text",
        name: "customer",
        data: null
    },
    destState: {
        type: "text",
        name: "destination_state",
        data: null
    },
    destCity: {
        type: "text",
        name: "destination_city",
        data: null
    },
    loadReadyDateTimeOriginTZ: {
        type: "date",
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        name: "carrier_ready_date",
        shortName: 'Carr. Ready Date',
        data: null
    },
    loadStartDateTimeOriginTZ: {
        type: "date",
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        name: "load_start_date",
        shortName: 'Load Start Date',
        data: null
    },
    tariffServiceCodeList : {
        type: "text",
        stringToArray: true,
        name: "carrier_service_code",
        data: null
    },
    orderExecutionHealth: {
        type: "checkbox",
        name: "health",
        data: [
            { name: "unhealthy", value: "RED", checked: false },
            { name: "needs_attention", value: "YELLOW", checked: false },
            { name: "healthy", value: "GREEN", checked: false }

        ]
    },
    liveLoad: {
        type: "radio",
        name: "live_load",
        data: null
    },
    materialNumberList: {
        type: "text",
        name: "material_id",
        stringToArray: true,
        data: null
    }
}

export default distoutboundfilters;