import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Grid } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Slider from "react-slick";
import EMEAChartCard from './EMEAChartCard';
import { boxShadow, chartsHeight as height } from 'helpers/styleHelpers';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { pwaSettings } from 'configs/pwaConstants';

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
        minHeight: theme.spacing(25),
        borderRadius: theme.spacing(1),
        background: theme.palette.card.default,
    },
    title: {
        marginRight: "auto",
        paddingBottom: theme.spacing(2),
        fontSize: theme.spacing(2),
        textTransform: "none",
        [theme.breakpoints.down('sm')]:{
            paddingBottom: 0,
        }
    },
    expand: {
        textTransform: "capitalize",
        textDecoration: 'none',
        border: 'none'
    },
    cardcontent: {
        paddingTop: 0,
        paddingBottom: 0
    },
    chartCard: {
        height: ({ height }) => height,
        borderRadius: theme.spacing(3),
        boxShadow: boxShadow
    },
    chartCard1: {
        width: `94vw`, 
        borderRadius: theme.spacing(2),
        boxShadow: '0px 2px 4px 2px rgba(0,0,0,0.12)',
        marginBottom: theme.spacing(2)
    },
    chartCard2: {
        borderRadius: theme.spacing(2), 
        marginBottom: theme.spacing(2)
    },
    item: {
        flexBasis: "auto !important",
    },
    cardactions: {
        justifyContent: "center"
    },
    grid: {
        display: "flex",
        justifyContent: "center"
    },
    slider: {
        margin: `0px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        "& .slick-current": {
            display: "flex",
            justifyContent: "center"
        }
    },
    mt2: {
        marginTop: theme.spacing(2)
    }
}));

export default function EMEAKPIsWrapper() {
    const classes = useStyles({ height });
    const { t } = useTranslation();
    const setMultiParams = useMultiSetQuery();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const [expanded, setExpanded] = React.useState(false);
    const [params] = useSetQuery();

    const [innerWidth] = useState(() => window.innerWidth - window.innerWidth/10 * 1.5)

    const [unit, setUnit] = useState(() => {
        return localStorage.getItem("currency") || "USD";
    });

    useEffect(() => {
        setUnit(localStorage.getItem("currency") || "USD");
    }, [params.currency]);

    const setDetails = ({ key, data, type }) => {
        let payload = data.state;
        const processVals = ["<2", "2-10", ">10"];
        if (key === "returnProcessingTimeBuckets" && processVals.includes(payload)) {
            payload = `${processVals.indexOf(payload) + 1}`;
        }

        setMultiParams({
            emeaFilters: JSON.stringify({
                [key]: type === "array" ? [payload] : payload
            }),
            isHyperlink: true
        });
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const tooltipEventTopReason = ({ data }) => {
        return `${data.totalCount} : ${data.totalCount2} ${unit}`
    }

    const chartsTop = [
        {
            title: "reasons_for_returns_by_order",
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_TOP_REASON",
            chart: "pie",
            tooltipKey: "stateDesc",
            displayKey: "stateDesc",
            tooltipExclusion: ["other"],
            setDetails: (data) => {
                setDetails({ key: "orderReasonCodeList", data, type: "array" })
            },
            tooltipEvent: tooltipEventTopReason,
            testId: "reason-returns-order-chart"
        },
        {
            title: "reasons_for_returns_by_quantity",
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_MONTH_ORDER_QTY",
            chart: "trendline",
            trendline: true,
            yLabel: t('number_of_cases'),
            showTotal: true,
            testId: "reason-returns-qty-chart"
        },
        {
            title: "return_orders_by_value",
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_MONTH_ORDER_VALUE",
            chart: "trendline",
            trendline: true,
            showTotal: true,
            unitOfTotal: unit,
            yLabel: t('order_value_currency', { currency: unit }),
            testId: "return-orders-value-chart"
        }
    ];

    const chartsBottom = [
        {
            title: t("processing_time_days"),
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_PROCESSING_TIME",
            chart: "trendline",
            yLabel: t('order_count'),
            colorPalette: "set4",
            legend: true,
            displayKey: "stateDesc",
            tooltipKey: "stateDesc",
            colorBy: "xKey",
            showXAxisLabel: false,
            setDetails: (data) => {
                setDetails({ key: "returnProcessingTimeBuckets", data, type: "array" })
            },
            testId: "processing-time-chart"
        },
        {
            title: t("top_five_customers"),
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_CUSTOMER_ORDER_VALUE",
            chart: "trendline",
            yLabel: t('order_value_currency', { currency: unit }),
            colorPalette: "set5",
            legend: true,
            colorBy: "xKey",
            showXAxisLabel: false,
            setDetails: (data) => {
                setDetails({ key: "corporateCustomerName", data, type: "text" })
            },
            testId: "top-five-customers-chart"
        },
        {
            title: t("top_five_materials"),
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_MATERIAL_ORDER_VALUE",
            chart: "trendline",
            yLabel: t('order_value_currency', { currency: unit }),
            colorPalette: "set6",
            legend: true,
            colorBy: "xKey",
            displayKey: "stateDesc",
            tooltipKey: "state",
            showXAxisLabel: false,
            setDetails: (data) => {
                setDetails({ key: "materialNumberList", data, type: "array" })
            },
            testId: "top-five-materials-chart"
        }
    ];

    return (
        <Card className={classes.root} data-testid="kpiswrapper">
            <CardHeader
                title={<Typography className={classes.title} variant="h3" component={"h3"}>{t("live_status_text")}</Typography>}
            />
            {
                isMobile && <>
                    <Slider {...pwaSettings} className={classes.slider}>
                        {
                            [...chartsTop, ...chartsBottom].map(d => {
                                return (
                                        <div>
                                            <EMEAChartCard {...d} maxWidth={innerWidth} minWidth={innerWidth} boxShadow="none" />
                                        </div>
                                )
                            })
                        }
                    </Slider>
                </>
            }
            {
                !isMobile && <>
                    <CardContent p={0}>
                        <Grid
                            container
                            spacing={3}
                            alignItems="center"
                            justifyContent="center"
                        >
                            {
                                chartsTop.map(d => {
                                    return (
                                        <Grid item p={1}>
                                            <EMEAChartCard {...d} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </CardContent>
                    <Collapse in={expanded} timeout="auto">
                        <CardContent className={classes.cardcontent}>
                            <Grid
                                container
                                spacing={3}
                                alignItems="center"
                                justify="center"
                            >
                                {
                                    chartsBottom.map(d => {
                                        return (
                                            <Grid item p={1}>
                                                <EMEAChartCard {...d} />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </CardContent>
                    </Collapse>
                    <CardActions className={classes.cardactions}>
                        <Button
                            variant="outlined"
                            color="textSecondary"
                            className={classes.expand}
                            onClick={handleExpandClick}
                            disableRipple
                            data-testid="expand"
                            disableFocusRipple
                            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        >
                            {expanded ? t('view_less') : t('view_more')}
                        </Button>
                    </CardActions>
                </>
            }
        </Card>
    );
}
