import React from 'react';
import { makeStyles, Typography, Grid, Divider, IconButton, LinearProgress } from '@material-ui/core';
import Topbar from "./Topbar";
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import GuardrailRequestCard from "components/common/Guardrail/GuardrailRequestCard";
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests } from "reduxLib/services/getAllGuardRailsService";
import RefreshIcon from '@material-ui/icons/Refresh';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    main: {
        padding: `${theme.spacing(10)}px ${theme.spacing(3)}px`
    },
    items: {
        padding: theme.spacing(1.5)
    },
    button: {
        border: `1px solid ${theme.palette.common.white}`,
        margin: theme.spacing(1.5),
        backgroundColor: theme.palette.secondary.base
    },
    loader: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
}));

const Requests = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { pending_requests: pendingRequests, requestLoading } = useSelector(({ guardrails }) => guardrails);

    const getRequests = () => {
        dispatch(getAllRequests())
    }
    React.useEffect(() => {
        getRequests()
    }, [])

    return (
        <>
            <Topbar showHamburgerMenu={false} />
            <Grid container className={classes.main} data-testid="updateRanking">
                <Typography variant="h2" >{t('edit_update_access_req_for_guardrail')}</Typography>
                {
                    requestLoading ? <LinearProgress className={classes.loader} /> :
                        <Divider width="100%" className={classes.loader} />
                }
                <Typography variant="h2">
                    {t('pending_requests')}
                    <IconButton onClick={getRequests}>
                        <RefreshIcon size="small" />
                    </IconButton>
                </Typography>
                <Grid item xs={12}>
                    {
                        pendingRequests?.length === 0 ? <Typography variant="h4">
                            {t('no_pending_requests')}
                        </Typography> :
                            pendingRequests?.map(d => {
                                return <GuardrailRequestCard data={d} key={d.requestID} />
                            })
                    }
                </Grid>
            </Grid>

        </>
    );
};

Requests.propTypes = {
    children: PropTypes.node
};


export default (withOktaAuth(Requests));