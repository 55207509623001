import React from 'react'
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { StyledTextField, StyledButton } from "components/common/FilterViewsMenu";
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import FavoriteIcon from 'assets/images/favorite.svg';
import DeleteIcon from 'assets/images/delete_dark.svg';
import { laoViews } from 'theme';
import { useDispatch, useSelector } from 'react-redux';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { generateFilterLayoutTemplate } from 'helpers';
import { laoOrderDetailsColumns } from 'reduxLib/constdata/LAO/laoOrderDetailsColumns';
import { updateLAOView, deleteLAOView, favUnfavLAOView } from 'reduxLib/services/LaoServices/laoViewsServices';
import { laoViewTableNames } from 'configs/appConstants';
import { laoImportOrderDetailsColumns } from 'reduxLib/constdata/LAO/laoImportOrderDetailsColumns';

const useStyles = makeStyles(laoViews);

const StyledListItemLAO = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.base,
    },
    color: theme.palette.border,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.background.selectedView
    }
    },
  }
))(MenuItem);

const LaoViews = ({ open, tableName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ viewid, tableType }] = useSetQuery();
  const multiSetQuery = useMultiSetQuery();

  const [viewName, setTableViewName] = React.useState("");
  const { views: viewsLAO } = useSelector(({ laoViews: { views } }) => ({ views }));

  const addTableView = (e) => {
    e.preventDefault();
    
    const template = generateFilterLayoutTemplate({
      viewName,
      filters: {},
      columns: tableType === 'exportOrderDetails' ? laoOrderDetailsColumns.columnOrder : laoImportOrderDetailsColumns.columnOrder
    });
    dispatch(updateLAOView({
      view: template,
      tableName: laoViewTableNames[tableName],
      updateType: "new"
    }));
    setTableViewName("");
    multiSetQuery({
      viewid: template.id,
      updateFilters: true
    })
  }

  const changeView = (view) => {
    multiSetQuery({
      viewid: view.id,
      updateFilters: true
    })
  }

  const favView = (view) => {
    dispatch(favUnfavLAOView({
      viewid: view.id,
      tableName: laoViewTableNames[tableName],
      isFav: !view.isFav,
      method: view.isFav ? "unfav" : "fav"
    }));
  }

  const deleteView = (view) => {
    dispatch(deleteLAOView({
      viewid: view.id,
      tableName: laoViewTableNames[tableName]
    }));
    multiSetQuery({
      viewid: undefined,
      laoFilters: "{}"
    })
  }

  return (
    <div data-testid="viewsContainer" >
      {(open === true) &&
        <Card data-testid="viewsList" className={classes.laoViewsGrid} elevation={10} >
          <form data-testid="viewsForm" onSubmit={addTableView} >
            <FormGroup row>
              <StyledTextField
                name="viewname"
                className={classes.laoAddViewText}
                variant="outlined"
                size="small"
                inputProps={{ "data-testid": "save_new_view" }}
                value={viewName}
                onChange={(e) => setTableViewName(e.target.value)}
                placeholder={t('enter_a_view_name')}
                required
              />
              <StyledButton
                className={classes.laoAddViewButton}
                size="small"
                variant="contained"
                data-testid="laoaddview_btn"
                type="submit"
                disabled={!viewName}
              >
                {t('add_view')}
              </StyledButton>
              <Typography variant='body2' className={classes.label}> {t('new_view_name_msg')} </Typography>
            </FormGroup>
          </form>
          {viewsLAO?.map(v => {
            return (
              <StyledListItemLAO
                key={v.id}
                data-testid={`listid-${v.id}`}
                button
                onClick={() => changeView(v)}
                selected={viewid === v.id}
              >
                <ListItemText primary={v.name} />
                <ListItemSecondaryAction>
                  <IconButton data-testid="favview-btn-Lao" edge="end" onClick={() => favView(v)} >
                    <img src={v.isFav ? FavoriteIcon : NotFavoriteIcon} alt="fav" />
                  </IconButton>
                  <IconButton data-testid="delview-btn-Lao" onClick={() => deleteView(v)} >
                    <img src={DeleteIcon} alt="delete-Lao" />
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItemLAO>
            )
          })
          }
        </Card>
      }
    </div>
  )
}

export default LaoViews;