import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get } from "lodash";
import { saveFilters, saveSelfAlertFilters } from "../../reduxLib/services";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { connect } from "react-redux";
import { filterStyles } from "theme";
// import FilterComponentContainer from "./Filters/FilterComponentContainer";
import KCRadio from './Elements/KCRadio';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(filterStyles)

const RadioElement = (props) => {

    const { filters, filterKey, type, subtype, customSaveFilters } = props;

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    const { t } = useTranslation();
    const classes = useStyles();
    const filter = get(filters, `${filterKey}.data`, null)

    const [value, setValue] = React.useState(filter);
    React.useEffect(() => {
        setValue(filter);
    }, [filter])

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    const resetValue = (val) => {
        if (val === value) {
            setValue(null);
        }
    }

    React.useEffect(() => {
        if (filters[filterKey]) {
            const savePayload = {
                filter: {
                    [filterKey]: {
                        ...filters[filterKey],
                        data: value
                    }
                }, type, subtype
            };
            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayload);
            } else {
                saveFilters(savePayload);
            }
        }
    }, [value]);

    return (
        // <FilterComponentContainer testId='radioElement' {...props}>
            <FormControl component="fieldset" data-testid="radioElement">
                <RadioGroup row aria-label="position" defaultValue="end" name="Radio1" value={value} onChange={handleChange}>
                    <FormControlLabel className={classes.contentText} color="primary" value="Y" onClick={() => resetValue("Y")} control={<KCRadio />}
                        label={<Typography color="primary" className={clsx(classes.button, classes.contentText)}>{t("yes")}</Typography>}
                    />
                    <FormControlLabel className={classes.contentText} color="primary" value="N" onClick={() => resetValue("N")} control={<KCRadio />}
                        label={<Typography color="primary" className={clsx(classes.button, classes.contentText)}>{t("no")}</Typography>}

                    />
                </RadioGroup>
            </FormControl>
        // </FilterComponentContainer>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;
    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        filters
    }
};

const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(RadioElement);

