
import { osmConstants } from "../constants"
import { endpoints, sortOrder } from "helpers";
import fetch from "./serviceHelpers";

const getOSMViews = () => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });

    return fetch(`${endpoints.osm.getviews}?appName=OSM`, {
        method: "GET",
    })
        .then(d => d.json())
        .then(d => {
            const layout = d?.layouts?.[0];
            let views = [
                ...layout?.privateViews,
                ...layout?.publicViews?.map(view => ({ ...view, public: true }))
            ];
            views = sortOrder({
                data: views,
                order: layout?.viewOrder?.map(d => d.id),
                key: "id"
            }).map((d, i) => {
                return {
                    ...(layout?.viewOrder?.[i]),
                    ...d,
                    isFav: layout.viewDetail.id === d.id
                }
            });

            dispatch({
                type: osmConstants.FETCH_OSM_VIEWS_SUCCESS,
                payload: {
                    views,
                    viewOrder: layout.viewOrder,
                    active: layout?.viewDetail,
                    loading: false
                }
            });
        })
        .catch(() => {
            dispatch({
                type: osmConstants.FETCH_OSM_VIEWS_FAILED,
                payload: {
                    loading: false
                }
            });
        })
}

const getOSMView = (viewid) => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });
    return fetch(`${endpoints.osm.getview}?tableName=OSM&viewID=${viewid}`, {
        method: "GET",
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: osmConstants.GET_OSM_VIEW_SUCCESS,
                payload: {
                    loading: false,
                    active: d
                }
            });
        })
        .catch(() => {
            dispatch({
                type: osmConstants.GET_OSM_VIEW_FAILED,
                payload: {
                    loading: false,
                    active: null
                }
            });
        })
}

const hideOSMView = (viewid, hide) => dispatch => {
    dispatch({
        type: osmConstants.HIDE_OSM_VIEW,
        payload: viewid,
        hide
    });
}

const showHideAllOSMViews = (showall = true) => dispatch => {
    dispatch({
        type: osmConstants.SHOW_ALL_HIDE_ALL_OSM_VIEWS,
        payload: showall,
    });
}

const activateOSMView = (view) => dispatch => {
    dispatch({
        type: osmConstants.ACTIVATE_OSM_TAB,
        payload: view
    });
}

const updatedOSMView = (view) => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });
    return fetch(`${endpoints.osm.updateviews}?tableName=OSM`, {
        method: "PUT",
        body: {
            ...view,
            tableName: "OSM"
        },
    })
        .then(d => {
            if (d?.status === 200) {
                dispatch({
                    type: osmConstants.UPDATE_OSM_VIEW_SUCCESS,
                    payload: {
                        view,
                        status: {
                            status: "success",
                            message: "View Created/Updated Successfully"
                        }
                    }
                });
            } else {
                dispatch({
                    type: osmConstants.UPDATE_OSM_VIEW_FAILED,
                    payload: {
                        view: null,
                        status: {
                            status: "error",
                            message: "View Creation/Updatation Failed"
                        }
                    }
                });
            }
        })
        .catch(() => {
            dispatch({
                type: osmConstants.UPDATE_OSM_VIEW_FAILED,
                payload: {
                    view: null,
                    status: {
                        status: "error",
                        message: "View Update Failed"
                    }
                }
            });
        })
}

const favoriteOSMView = (viewid) => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });
    return fetch(`${endpoints.osm.favView}?tableName=OSM`, {
        method: "PUT",
        body: {
            id: viewid,
            tableName: "OSM"
        },
    })
        .then(d => {
            if (d?.status === 200) {
                dispatch({
                    type: osmConstants.FAV_OSM_VIEW_SUCCESS,
                    payload: viewid
                });
            } else {
                dispatch({
                    type: osmConstants.FAV_OSM_VIEW_FAILED,
                    payload: null
                });
            }
        })
        .catch(() => {
            dispatch({
                type: osmConstants.FAV_OSM_VIEW_FAILED,
                payload: null
            });
        })
}

const deleteOSMView = (viewid) => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });
    return fetch(`${endpoints.osm.deleteviews}?tableName=OSM&viewID=${viewid}`, {
        method: "DELETE",
    })
        .then(d => {
            if (d?.status === 200) {
                dispatch({
                    type: osmConstants.DELETE_OSM_VIEW_SUCCESS,
                    payload: {
                        id: viewid,
                        status: {
                            status: "success",
                            message: "View Deleted Successfully"
                        }
                    }
                });
            } else {
                dispatch({
                    type: osmConstants.DELETE_OSM_VIEW_FAILED,
                    payload: {
                        id: null,
                        status: {
                            status: "error",
                            message: "View Deletion Failed"
                        }
                    }
                });
            }
        })
        .catch(() => {
            dispatch({
                type: osmConstants.DELETE_OSM_VIEW_FAILED,
                payload: {
                    id: null,
                    status: {
                        status: "error",
                        message: "View Deletion Failed"
                    }
                }
            });
        })
}

const updateOSMViewOrder = (views) => dispatch => {
    dispatch({
        type: osmConstants.OSM_LOADING,
        payload: true
    });
    return fetch(`${endpoints.osm.viewOrder}?tableName=OSM`, {
        method: "PUT",
        body: views
    })
        .then(d => {
            if (d?.status === 200) {
                dispatch({
                    type: osmConstants.UPDATE_VIEW_ORDER_SUCCESS,
                    payload: {
                        views,
                        status: {
                            status: "success",
                            message: "View Created/Updated Successfully"
                        }
                    }
                });
            } else {
                dispatch({
                    type: osmConstants.UPDATE_VIEW_ORDER_FAILED,
                    payload: {
                        views,
                        status: {
                            status: "error",
                            message: "View Create/Update Failed"
                        }
                    }
                });
            }
        })
        .catch(() => {
            dispatch({
                type: osmConstants.UPDATE_VIEW_ORDER_FAILED,
                payload: {
                    views,
                    status: {
                        status: "error",
                        message: "View Update Failed"
                    }
                }
            });
        })
}

const resetViewStatus = () => dispatch => {
    dispatch({
        type: osmConstants.RESET_OSM_STATUS,
        payload: null
    });
}

export {
    getOSMViews,
    getOSMView,
    updatedOSMView,
    deleteOSMView,
    activateOSMView,
    hideOSMView,
    showHideAllOSMViews,
    favoriteOSMView,
    updateOSMViewOrder,
    resetViewStatus
}
