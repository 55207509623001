import React from 'react'
import truncate from 'lodash/truncate'
import { useTranslation } from "react-i18next"
import { scaleOrdinal, pie, arc, sum } from 'd3';
import { useTheme } from '@material-ui/core/styles';
import ResponsiveWrapper from '../charts/ResponsiveWrapper'
import { Typography } from '@material-ui/core';
import D3Tooltip from "./D3Tooltip";
import { makeStyles } from '@material-ui/core/styles';
import {tooltipLinkArgumentsMap} from '../../reduxLib/constdata/filters';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    text: {
        pointerEvents: 'none',
        ...theme.typography.body2
    },
    link:{
        cursor:'pointer',
        textDecoration:'underline',
        letterSpacing: '1px',
        padding : theme.spacing(.4),
        '&:hover' :{
            color: theme.palette.link.hover
        }
      },
}));




const PieChart = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { legend, xKey, center, header, footer, labels, type, subtype,setDetails } = props;
    const data = props.data || [];
    const margins = props.margins ? props.margins : { top: 50, right: 20, bottom: 50, left: 60, margin: 40 };
    const themeColors = Object.values(useTheme().palette['chartColorsMulti']);
    let chartColors = [useTheme().palette.chartColors['healthy'], useTheme().palette.chartColors['unhealthy'] ];
    const keyColorLabelMap = {
        redCount:{status:'unhealthy', name: 'RED', cssClass : classes.red,},
        yellowCount:{status:'needs_attention', name: 'YELLOW' , cssClass : classes.yellow},
        blueCount:{status:'healthy', name: 'GREEN', cssClass : classes.blue},
    }
    if (data.length > 2) {
        chartColors = themeColors;
    }

    const svgDimensions = {
        width: props.parentWidth || 250,
        height: props.height || 250
    }
    const { height, width } = svgDimensions;
    const radius = Math.min(width, height) / 2 - margins.margin;


    const color = scaleOrdinal()
        .domain(d => d[xKey])
        .range(chartColors)

    const pie2 = pie()
        .value(d => d.value)
    const data_ready = pie2(data)

    const arcPath = arc()
        .innerRadius(radius - 25)
        .outerRadius(radius)
    
    const Pies = () => {
        return data_ready.map((item, i) => {
            return (
                <D3Tooltip 
                    key={i} 
                    placement={"top"} 
                    color={color(item.data[xKey])} 
                    title={<div className={clsx(typeof (setDetails) === "function" ? classes.link: null)} 
                    onClick={() => {
                      if (typeof (setDetails) === "function") {
                        setDetails(type, subtype, true,{args:tooltipLinkArgumentsMap[item.data.state],health:item.data[xKey].toUpperCase() === "RED" ? t(keyColorLabelMap.redCount.name) : item.data[xKey].toUpperCase() === "BLUE" ? t(keyColorLabelMap.blueCount.name) : "" ,state:subtype}, true)
                     }
                    }}>
                        {item.data.name} : {item.value}
                  </div>}>
                    <path
                        key={i}
                        d={arcPath(item)}
                        fill={color(item.data[xKey])}
                        title={`piearc-${item.value}`}
                        data-testid={`${item.data.state}_${color(item.data[xKey])}`}
                        value={item.value}
                    ></path>
                </D3Tooltip>
            )
        })
    }


    const Texts = () => {
        return data_ready.map((item, i) => {
            const center_ = arcPath.centroid(item);
            return (
                <text
                    key={i}
                    transform={'translate(' + center_ + ')'}
                    className={classes.text}
                    style={{ textAnchor: 'middle' }}
                    fill="black"
                    dy={5}
                >{item.value || null}</text>
            )
        })
    }

    const Labels = ({ title, labelWidth, count }) => {
        const labelHeight = 40;
        return (
            <g>
                <foreignObject height={labelHeight} width={labelWidth} transform={`translate(${-labelWidth / 2}, ${-labelHeight / 2})`}>
                    <Typography style={{ wordWrap: 'break-word', width: 50 }} variant="body2" color="primary">{title}</Typography>
                    { count && <Typography style={{ wordWrap: 'break-word', width: 50 }} variant="body2" color="primary" data-testid="donutCount" id={footer}>{count}</Typography>}
                </foreignObject>
            </g>
        )
    }

    const centerPos = [svgDimensions.width / 2, svgDimensions.height / 2]

    const NoDataFound = ({ text }) => {
        return (
            <g transform={`translate(${centerPos})`} data-testid={`${subtype}_chart`} id={footer}>
                <text textAnchor="middle" fill="white" data-testid="noData">{t('no_data_found')}</text>
            </g>
        )
    }

    const legendRectSize = 10;
    const legendSpacing = 10;

    const getTransform = (d, i) => {
        var height_ = legendRectSize + legendSpacing;
        var offset = height_ * color.domain().length / 2;
        var horz = radius + margins.left;
        var vert = i * height_ - offset;
        return 'translate(' + horz + ',' + vert + ')';
    }

    const Legends = () => {

        return (data.map((d, i) => {
            return (
                <g className="legend" transform={getTransform(d[xKey], i)} key={i} data-testid={d[xKey]}>
                    <text transform={`translate(${legendRectSize + legendSpacing + 5}, ${legendRectSize - legendRectSize / 4})`} fill="white">{d[xKey]} ({d.totalCount})</text>
                    <rect height={legendRectSize} width={legendRectSize} fill={color(d[xKey])}></rect>
                </g>
            )
        }))
    }
    const moveLeft = legend ? 4 : 2;
    const totalSum = sum(data, d => d.value);

    return (
        <span data-testid="pieChart" style={{ textAlign: 'center' }} >
            <Typography className={classes.title} variant="body2">{header}</Typography>
            <svg width={svgDimensions.width} height={svgDimensions.height} >
                {
                    !totalSum ? <NoDataFound /> : null
                }
                <g transform={"translate(" + width / moveLeft + "," + height / 2 + ")"}>
                    {
                        totalSum ?
                            <g>
                                <Pies />
                                {
                                    center ? <Labels title={center} count={totalSum} labelWidth={50} /> : null
                                }
                                {
                                    legend ? <Legends /> : null
                                }
                                {
                                    labels ? <Texts /> : null
                                }
                            </g> : null
                    }
                </g>
            </svg>
            <Typography align='center' component='div' variant="caption" title={footer}>{truncate(footer, {'length': 15, 'omission': '...'})}</Typography>
        </span>
    )
}

export default ResponsiveWrapper(PieChart)
