import { analyticsConstants } from "../constants"
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";

export const updateCSTRank = variables => dispatch => {
  dispatch({
    type: analyticsConstants.UPDATE_LOADING,
    payload: true
  });

  return fetch(endpoints.analytics.updateCstRanking, {
    method: "PUT",
    body: variables,
  })
  .then(d => {
    if (d.status === 200) {
      dispatch({
        type: analyticsConstants.UPDATE_CST_RANK,
        payload: variables
      })
    } else {
      dispatch({
        type: analyticsConstants.UPDATE_FAILED,
        payload: false
      });
    }

    dispatch({
      type: analyticsConstants.UPDATE_LOADING,
      payload: false
    });
  })
  .catch(err => {
    dispatch({
      type: analyticsConstants.UPDATE_FAILED,
      payload: false
    });
    dispatch({
      type: analyticsConstants.UPDATE_LOADING,
      payload: false
    });
  })
}