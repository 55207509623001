import { emeaTableConstants } from "reduxLib/constants/EMEA/emeaTableConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const emeaEditCommentService = variables => dispatch => {
  dispatch({
    type: emeaTableConstants.EMEA_TABLE_COMMENT_LOADING,
    payload: true
  });

  return fetch(`${endpoints.emea.editComments}${variables.originSiteNum}`, {
    method: "PUT",
    body: variables
  })
    .then(d => {
      if (d.status === 200) {
        return d;
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_edit_comments_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaTableConstants.EMEA_TABLE_COMMENT_SUCCESS,
        payload: "emea_edit_comments_success",
      });

      dispatch({
        type: emeaTableConstants.EMEA_TABLE_COMMENT_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaTableConstants.EMEA_TABLE_COMMENT_FAILED,
        payload: err,
      });

      dispatch({
        type: emeaTableConstants.EMEA_TABLE_COMMENT_LOADING,
        payload: false,
      });
    })
}

export const resetComments = variables => dispatch => {
	dispatch({
		type: emeaTableConstants.EMEA_TABLE_COMMENT_RESET,
		payload: null,
    error: null
	})
}
