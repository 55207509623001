import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";
import { shortTitleCellStyle } from 'helpers/tableStyleOverride';

export const OSMStackDetailsColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNum",  
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },{
      title: 'stack_header_id', field: "stackId",  
      render: rowData => {
        return <CustomCellRender id="stackId" value={rowData.stackId} />
      }
    },{
      title: 'delivery', field: "deliveryNum",  
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },{
      title: 'delivery_item', field: "itemPosition",  
      render: rowData => {
        return <CustomCellRender id="itemPosition" value={rowData.itemPosition} />
      }
    },{
      title: 'material_number', field: "materialNum",  
      render: rowData => {
        return <CustomCellRender id="materialNum" value={rowData.materialNum} />  
      }
    },{
      title: 'wms_rp_sequence_num', field: "wmsSequenceNum",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="wmsSequenceNum" value={rowData.wmsSequenceNum} />
      }
    },{
      title: 'batch_management_req_ind', field: "batchManagementReqInd",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="batchManagementReqInd" value={rowData.batchManagementReqInd} />
      }
    },{
      title: 'unit_label_serial_num', field: "unitLabelSerialNumb",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="unitLabelSerialNumb" value={rowData.unitLabelSerialNumb} />
      }
    },{
      title: 'ucl_label_print_ind', field: "uccLabelPrintInd",  
      render: rowData => {
        return <CustomCellRender id="uccLabelPrintInd" value={rowData.uccLabelPrintInd} /> 
      }
    },{
      title: 'cube_code', field: "cubeCode",  
      render: rowData => {
        return <CustomCellRender id="cubeCode" value={rowData.cubeCode} />
      }
    },{
      title: 'stack_type', field: "productStackType",  
      render: rowData => {
        return <CustomCellRender id="productStackType" value={rowData.productStackType} />
      }
    },{
      title: 'stack_type_count', field: "productStackTypeCount",  
      render: rowData => {
        return <CustomCellRender id="productStackTypeCount" value={rowData.productStackTypeCount} />    
      }
    },{
      title: 'customer_purchase_order_num', field: "purchaseOrderNum",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="purchaseOrderNum" value={rowData.purchaseOrderNum} />
      }
    },{
      title: 'expected_qty', field: "expectedQuantity",  
      render: rowData => {
        return <CustomCellRender id="expectedQuantity" value={rowData.expectedQuantity} />
      }
    },{
      title: 'load_adjustment_qty', field: "loadAdjustmentQuantity",  
      render: rowData => {
        return <CustomCellRender id="loadAdjustmentQuantity" value={rowData.loadAdjustmentQuantity} />
      }
    },{
      title: 'max_delivery_qty_for_stack', field: "maxDeliveryQuantityforStack",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="maxDeliveryQuantityforStack" value={rowData.maxDeliveryQuantityforStack} />
      }
    },{
      title: 'target_qty_uom', field: "targetQuantityUom",  
      render: rowData => {
        return <CustomCellRender id="targetQuantityUom" value={rowData.targetQuantityUom} />
      }
    },{
      title: 'adjust_reason_code_originally', field: "adjustReasonCode",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="adjustReasonCode" value={rowData.adjustReasonCode} />
      }
    },{
      title: 'actual_pick_qty', field: "actualPickQuantity",  
      render: rowData => {
        return <CustomCellRender id="actualPickQuantity" value={rowData.actualPickQuantity} />
      }
    },{
      title: 'yn_ind_set_when_entire_po_is_cut', field: "poCutInd",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="poCutInd" value={rowData.poCutInd} />
      }
    },{
      title: 'rfid_ind', field: "rfidInd",  
      render: rowData => {
        return <CustomCellRender id="rfidInd" value={rowData.rfidInd} />
      }
    },{
      title: 'retail_case_goods_item_ind', field: "retailCaseGoodsItemInd",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="retailCaseGoodsItemInd" value={rowData.retailCaseGoodsItemInd} />
      }
    },{
      title: 'load_equivalent_factor', field: "loadEquivalentFactor",  
      render: rowData => {
        return <CustomCellRender id="loadEquivalentFactor" value={rowData.loadEquivalentFactor} />
      }
    },{
      title: 'volume', field: "volume",  
      render: rowData => {
        return <CustomCellRender id="volume" value={rowData.volume} />
      }
    },{
      title: 'volume_unit', field: "volumeUnit",  
      render: rowData => {
        return <CustomCellRender id="volumeUnit" value={rowData.volumeUnit} />
      }
    },{
      title: 'created_on', field: "createdOn",  
      render: rowData => {
        return <CustomCellRender id="createdOn" value={rowData.createdOn} />
      }
    // },{
    //   title: 'time_created', field: "timeCreated",  
    //   render: rowData => {
    //     return <CustomCellRender id="timeCreated" value={rowData.timeCreated} />
    //   }
    },{
      title: 'changed_on', field: "changedOn",  
      render: rowData => {
        return <CustomCellRender id="changedOn" value={rowData.changedOn} />
      }
    // },{
    //   title: 'last_changed_at', field: "lastChangedAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="lastChangedAt" value={rowData.lastChangedAt} />
    //   }
    },{
      title: 'platform_slipsheet_material_id', field: "platformSlipSheetMaterialID",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="platformSlipSheetMaterialID" value={rowData.platformSlipSheetMaterialID} />
      }
    },{
      title: 'display_unit_on_pallet_ind', field: "displayUnitOnPalletInd",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="displayUnitOnPalletInd" value={rowData.displayUnitOnPalletInd} />
      }
    },{
      title: 'overbox_num', field: "overboxNumb",  
      render: rowData => {
        return <CustomCellRender id="overboxNumb" value={rowData.overboxNumb} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}