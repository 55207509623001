import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Card from '@material-ui/core/Card';
import clsx from "clsx";
import LaunchIcon from '@material-ui/icons/Launch';
import Filters from "components/common/Filters/index";
import { ColumnManager } from 'components/common/Table/ColumnManager';
import Snack from 'components/common/Helpers/Snack';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import FilterIcon from "assets/images/filter.svg";
import ExcelIcon from 'assets/images/excel.png';
import ViewsIcon from "assets/images/table-view.svg";
import FormHelperText from '@material-ui/core/FormHelperText';
import SearchFilter from "../common/Filters/SearchFilter";
import LaoViews from './LaoViews';
import { getAllLAOViews, resetLAOViews } from 'reduxLib/services/LaoServices/laoViewsServices';
import { laoViewTableNames } from 'configs/appConstants';
import { getLAOFilterArgs } from 'helpers/laoHelpers';
import useSetQuery from 'customHooks/useQuery';
import { laoExpandDetailsColumns, laoOrderDetailsColumns } from 'reduxLib/constdata/LAO/laoOrderDetailsColumns';
import { downloadService } from 'reduxLib/services/downloadService';
import { laoImportExpandDetailsColumns, laoImportOrderDetailsColumns } from 'reduxLib/constdata/LAO/laoImportOrderDetailsColumns';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(0)
  },
  text: {
    color: theme.palette?.text?.primary
  },
  searchSpace: {
    paddingTop: theme.spacing(4)
  },
  searchMobile: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      display: 'flex'
    }
  },
  subtitle: {
    color: theme.palette.white,
    textTransform: 'none',
  },
  exportTitle: {
    backgroundColor: theme.palette.secondary.dark,
    textAlign: 'left',
    borderTop: `2px solid ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
  exportTitleHeading: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.base,
  },
  dropdown: {
    position: 'absolute',
    zIndex: 100,
    borderRadius: '0 !important',
    width: 350,
    right: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(3),
      width: theme.spacing(39)
    },
    justifyContent: 'left'
  },
  icon: {
    height: 20,
    width: 20
  },
  actionBar: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  }
}));

const LaoTableActionsWrapper = ({
  tableRef, tableName, refreshTable: refreshTableLao, filterType, 
  type, subtype, filterExclusionArray,
  searchText, columns: columnsLao, columnsAction: columnsActionLao, clearAllFilters,
  columns, totalRecords, fetchEndPoint
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileLao = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [controller] = useState(new AbortController());
  const [params, setParams] = useSetQuery();

  const laoTableData = useSelector(({ laoTable }) => laoTable?.[type]?.[tableName] || {});
  const downloading = useSelector(({ downloads }) => downloads?.[type]?.[subtype]?.downloading);

  const { payload, loading: loadingLao } = laoTableData;

  const [open, setOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [viewsOpen, setViewsOpen] = useState(false);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });

  useEffect(() => {
    dispatch(getAllLAOViews({
      tableName: laoViewTableNames[tableName]
    }, controller));
    return () => {
      dispatch(resetLAOViews({
        tableName: laoViewTableNames[tableName]
      }));
      controller.abort()
    }
  }, []);

  const exportOptions = () => {
    handleCloseTableFilters();
    setExportOpen((prev) => !prev);
  };

  const customSaveFilters = (savePayload) => {
    const args = getLAOFilterArgs(savePayload.filter);
    setParams({
      key: "laoFilters",
      value: {
        ...params.laoFilters,
        ...args
      }
    })
  }

  const handleOpenTableFilters = useCallback(() => {
    setOpen((prev) => !prev);
    handleCloseTableViewsLao();
  }, [open]);

  const handleCloseTableFilters = () => {
    setOpen(false);
  }

  const handleOpenTableViews = () => {
    setViewsOpen(e => !e);
    handleCloseTableFilters();
  };

  const handleCloseExportLao = () => {
    setExportOpen(false);
  }

  const handleCloseTableViewsLao = () => {
    setViewsOpen(false);
  };

  const columnsOpenActionLao = () => {
    handleCloseTableViewsLao();
    handleCloseTableFilters();
    handleCloseExportLao();
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({
      ...snack,
      open: false
    });
  };

  const translatedDetailsColumns = (columns) => {
    return columns.columnOrder?.map(item => {
      return {
        ...item,
        title: t(item.title),
      }
    });
  }

  const exportTable = (itemInfoRequired = false) => {
    if (!downloading) {
      dispatch(downloadService({
        config: {
          totalRecords,
          body: payload?.body?.tablebody,
          url: fetchEndPoint,
          columns
        },
        type,
        subtype,
        itemInfoRequired,
        lao: true,
        process: tableName === "importOrderDetails" ? laoImportOrderDetailsColumns.columnConfiguration : laoOrderDetailsColumns.columnConfiguration,
        detailsColumns: translatedDetailsColumns(tableName === "importOrderDetails" ? laoImportExpandDetailsColumns : laoExpandDetailsColumns),
        detailsProcess: tableName === "importOrderDetails" ? laoImportExpandDetailsColumns.columnConfiguration : laoExpandDetailsColumns.columnConfiguration
      }));
      setSnack({
        open: true,
        severity: 'info',
        message: t("download_started_msg")
      });
    } else {
      setSnack({
        open: true,
        severity: 'warning',
        message: t("download_progress_msg")
      });
    }
  }

  return (
    <Grid container
      data-testid='lao-table-actions-wrapper'
      direction="row"
      justify="space-between"
      spacing={3}
      alignItems="center"
      className={classes.root}
    >
      <Grid container item sm={12} md={6} lg={6} justify="flex-start" spacing={2} className={classes.searchMobile}>
        <div className={classes.searchSpace}>
            <SearchFilter
                type={type}
                subtype={subtype}
                filterKey={"searchStringList"}
                customSaveFilters={customSaveFilters}
                />
            <FormHelperText className={classes.subtitle}> {searchText} </FormHelperText>
        </div>
      </Grid>

      <Grid container item xs={12} sm={12} md={6} lg={6} spacing={2} className={classes.actionBar}>
        <Grid item >
          <Box textAlign='center' aria-label="Manage Columns">
            <ColumnManager
              columns={columnsLao}
              columnsAction={columnsActionLao}
              columnsOpenAction={columnsOpenActionLao}
            />
          </Box>
        </Grid>
        <Grid item >
          <Box textAlign='center'>
            <IconButton 
            className={classes.text} 
            data-testid="refreshTable"
            onClick={() => refreshTableLao(tableRef)} 
            disabled={loadingLao}
            >
              <RefreshOutlined />
            </IconButton>
            {!isMobileLao && <Typography variant='body2'> {t('refresh')} </Typography>}            
          </Box>
        </Grid>
        <Grid item >
          <ClickAwayListener onClickAway={handleCloseExportLao}>
            <Box textAlign='center'>
              <IconButton data-testid="exportTable" onClick={exportOptions}>
                <img src={ExcelIcon} alt="Export" />
              </IconButton>
              {!isMobileLao && <Typography variant='body2'> {t('export')} </Typography>}
              {exportOpen ? (
                <Card className={classes.dropdown}>
                  <div className={clsx(classes.exportTitle, classes.exportTitleHeading)}>
                    <Typography variant="h2" component="p" color="primary">{t('export_view')}
                      <Typography variant="caption" component="p" className={classes.subtitle}>{t("with_managed_columns_and_filters_applied")}</Typography>
                    </Typography>
                  </div>
                  <div className={classes.exportTitle}>
                    <Typography variant="h2" component="p" color="primary">{t('with_product_details')}</Typography>
                    <IconButton><LaunchIcon className={classes.icon} aria-label="exportWith" onClick={() => exportTable(true)} /></IconButton>
                  </div>
                  <div className={classes.exportTitle}>
                    <Typography variant="h2" component="p" color="primary">{t('without_product_details')}</Typography>
                    <IconButton><LaunchIcon className={classes.icon} aria-label="exportWithOut" onClick={() => exportTable(false)} /></IconButton>
                  </div>
                </Card>
              ) : null}
            </Box>
          </ClickAwayListener>          
        </Grid>
        <Grid item >
          <ClickAwayListener onClickAway={handleCloseTableViewsLao}>
            <div>
              <Box textAlign='center'>
                <IconButton data-testid="tableViews" onClick={handleOpenTableViews}>
                  <img src={ViewsIcon} alt="Views" />
                </IconButton>
                {!isMobileLao && <Typography variant='body2'> {t('views')} </Typography>}
              </Box>
              <LaoViews open={viewsOpen} type={type} tableName={tableName} columns={columnsLao} />
            </div>
          </ClickAwayListener>
        </Grid>
        <Grid item >
          <Box textAlign='center'>
            <IconButton data-testid="laoFilters"
              onClick={handleOpenTableFilters}>
              <img src={FilterIcon} alt="Filter" />
            </IconButton>
            {!isMobileLao && <Typography variant='body2'> {t('filters')} </Typography>}
          </Box>
          <Filters
            filtertype={filterType}
            open={open}
            handleOpen={handleOpenTableFilters}
            type={type}
            subtype={subtype}
            customSaveFilters={customSaveFilters}
            clearAllFilters={clearAllFilters}
            exclude={filterExclusionArray}
            searchText={searchText}
          />
        </Grid>
      </Grid>

      <Snack {...snack} handleClose={handleCloseSnack} />

    </Grid>
  )
}
export default LaoTableActionsWrapper;