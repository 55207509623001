import React from 'react';
import { useTranslation } from "react-i18next"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bluesquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette?.legendColors?.healthy,
    display: 'inline-block'
  },
  redsquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette?.legendColors?.unhealthy,
    display: 'inline-block'
  },
  yellowsquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette?.legendColors?.risk,
    display: 'inline-block'
  },
  legend: {
    padding: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
    // width: '150%',
    position: 'absolute'
  },
  textContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: 'max-content'
    // display: 'inline-block'
  },
  purplesquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette.guardrailColors?.consumer,
    display: 'inline-block'
  },
  pinksquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette.guardrailColors?.professional,
    display: 'inline-block'
  },
  greensquare: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette.guardrailColors?.STO,
    display: 'inline-block'
  },
  reddots: {
    fontSize: theme.spacing(2),
    color: theme.palette.legendColors?.pink,
    display: 'inline-block',
    '&::after': {
      fontSize: '1.5em',
      fontWeight: 'bold',
      content: `"\\2014"`
    },
  },
  bluedots: {
    fontSize: theme.spacing(2),
    color: theme.palette.chartColors?.healthy,
    display: 'inline-block',
    '&::after': {
      fontSize: '1.5em',
      fontWeight: 'bold',
      content: `"\\2014"`
    },
  },
  reddashes: {
    fontSize: theme.spacing(2),
    color: theme.palette.legendColors?.pink,
    display: 'inline-block',
    '&::after': {
      fontSize: '1.5em',
      fontWeight: 'bold',
      content: `"\\2026"`
    },
  },
  bluedashes: {
    fontSize: theme.spacing(2),
    color: theme.palette.chartColors?.healthy,
    display: 'inline-block',
    '&::after': {
      fontSize: '1.5em',
      fontWeight: 'bold',
      content: `"\\2026"`
    },
  }
}));

const Legend = ({ isGuardrail, isInbound }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if(isInbound) {
    return (
    <span data-testid="inboundlegend" color="primary">
      <div className={classes.legend}>
        <div className={classes.greensquare} />
        <div className={classes.textContent}>STO</div>
      </div>
    </span>
    )
  }

  return (
    <>
      {isGuardrail? (
        <span data-testid="gurdrailLegend" color="primary">
          <div className={classes.legend} data-testid="gurdrail-legend">
            <div className={classes.purplesquare} />
            <div className={classes.textContent}>CONSUMER</div>
            <div className={classes.pinksquare} />
            <div className={classes.textContent}>PROFESSIONAL</div>
            <div className={classes.greensquare} />
            <div className={classes.textContent}>STO</div>
            <div className={classes.reddots} />
            <div className={classes.textContent}>{t('above_guard_rail')}</div>
            <div className={classes.bluedots} />
            <div className={classes.textContent}>{t('below_guard_rail')}</div>

            <div className={classes.reddashes} />
            <div className={classes.textContent}>{t('above_current_guard_rail')}</div>
            <div className={classes.bluedashes} />
            <div className={classes.textContent}>{t('below_current_guard_rail')}</div>
          </div>
        </span>
      ) : (
        <span color="primary">
          <div className={classes.legend} data-testid="legend">
            <div className={classes.bluesquare} />
            <div className={classes.textContent}>{t('healthy')}</div>
            <div className={classes.yellowsquare} />
            <div className={classes.textContent}>{t('needs_attention')}</div>
            <div className={classes.redsquare} />
            <div className={classes.textContent}>{t("unhealthy")}</div>
          </div>
        </span>
      )}
    </>
  );
};

export default Legend;
