import {emeaCustomerConstants} from "reduxLib/constants/EMEA/emeaCustomerConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const emeaCustomerSearchService = variables => dispatch => {
  dispatch({
    type: emeaCustomerConstants.EMEA_CUST_SEARCH_LOADING,
    payload: true
  });
  // let response = {};

  return fetch(endpoints.emea.customerSearch, {
    method: "POST",
    body: variables,
  })
    .then(async (d) => {
      if (d.status === 200) {
        return d.json();
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        // response = await d.json();
        throw "emea_customer_search_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_SEARCH_SUCCESS,
        payload: d,
        error: null
      });

      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_SEARCH_LOADING,
        payload: false
      })
    })
    .catch(err => {
      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_SEARCH_FAILED,
        payload: [],
        error: err
      });
      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_SEARCH_LOADING,
        payload: false
      });
    })
}

export const resetCustomers = variables => dispatch => {
	dispatch({
		type: emeaCustomerConstants.EMEA_CUST_SEARCH_RESET,
		payload: variables
	})
}

export const emeaCustFavService = variables => dispatch => {
  dispatch({
    type: emeaCustomerConstants.EMEA_CUST_FAV_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.custFavs, {
    method: "PUT",
    body: variables
  })
    .then(d => {
      if (d.status === 200) {
        return d;
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_cust_fav_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_FAV_SUCCESS,
        payload: variables.favouriteCustomers
      });

      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_FAV_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_FAV_FAILED,
        error: err
      });

      dispatch({
        type: emeaCustomerConstants.EMEA_CUST_FAV_LOADING,
        payload: false
      });
    })
}