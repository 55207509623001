import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import MaterialTable from 'material-table';
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { appLightTheme, osmProductDetailsStyles } from 'theme';
import { OSMProductDetailsColumns } from "../../reduxLib/constdata/osmProductDetailsColumns";
import fetch from "reduxLib/services/serviceHelpers";
import { endpoints } from "../../configs/endpoints"

const useStyles = makeStyles(osmProductDetailsStyles)

export const detailOptions = {
  headerStyle: {
    backgroundColor: appLightTheme?.palette?.background?.secondaryBase,
    color: appLightTheme?.palette?.white,
    textTransform: "uppercase",
    padding: appLightTheme?.spacing(1),
    textAlign: 'left',
    ...appLightTheme?.typography.body2
  },
  rowStyle: {
    ...appLightTheme?.typography.body1,
    backgroundColor: appLightTheme?.palette?.primary?.secondaryBase,
    whiteSpace: 'nowrap',
    flexGrow: 1,
  },
  cellStyle: {
    padding: appLightTheme?.spacing(1)
  },
  loadingType: "linear",
  draggable: false,
  sorting: false,
  search: false,
  paging: false,
  showTitle: false,
  pagination: false,
  toolbar: false,
  maxBodyHeight: "50vh",
  padding: 'dense'
}

export const OsmProductDetailsTable = ({ orderStatusTableRowId, sites }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableBody = {
    region: "NA", 
    sites,
    orderStatusTableRowId
  }

  const [columns, setcolumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const translatedHeaders = OSMProductDetailsColumns.columnOrder?.map(data => {
      return {
        ...data,
        title: t(data.title),
      }
    });
    setcolumns(translatedHeaders);
  }, []);

  return (
    <Box data-testid="osmProductDetails" justifyContent="center" className={classes.tableBody}>
      <Typography variant="h3" className={classes.tableTitle}>{t('product_details')}</Typography>
      <Grid className={classes.detailsTable}>
        <MaterialTable
          localization={{
            body: { emptyDataSourceMessage: loading ? t("please_wait_while_the_data_is_loading") : t("no_records_to_display") }
          }}
          columns={columns}
          options={detailOptions}
          style={{ width: '92vw' }}
          data={() =>
            new Promise((resolve, reject) => {
              let endpoint = endpoints.table.osmProductDetails;
              fetch(endpoint, {
                method: "post",
                body: tableBody
              })
              .then(response => {
                  return response?.json() || {};
              })                            
              .then(result => {
                const tempData = result?.orderItems?.map((data, index) => {
                  return {
                    ...data,
                    slno: (index + 1)
                  }
                }) || [];
                setLoading(false);
                resolve({
                  data: tempData
                })
              })
              .catch(e => {
                setLoading(false);
                resolve({
                  data: []
                })
              })
            })
          }
        />
      </Grid>
    </Box>
  )
}

export default OsmProductDetailsTable;