import React, { useEffect, useRef } from 'react';
import { axisLeft, axisBottom } from 'd3-axis';
import { select } from 'd3-selection';

const gridlinesXY = ({ scale, size, orientation }) =>
  (orientation === "left" ? axisLeft : axisBottom)(scale).tickSize(size).tickFormat('');

const Grids = ({ margins, svgDimensions, xScale, yScale }) => {
  const xRef = useRef();
  const yRef = useRef();

  const width = svgDimensions.width - margins.left - margins.right;
  const height = svgDimensions.height - margins.bottom - margins.top;

  const renderGrids = () => {
    const nodeX = xRef.current;
    const nodeY = yRef.current;
    select(nodeX).call(gridlinesXY({ scale: xScale, size: height, orientation: "bottom" }))
      .style("stroke-dasharray", "5 5")
      .style("opacity", ".2");

    select(nodeY).call(gridlinesXY({ scale: yScale, size: -width, orientation: "left" }))
      .style("stroke-dasharray", "5 5")
      .style("opacity", ".2");
  }

  useEffect(() => {
    renderGrids();
  }, []);

  return (
    <g data-testid="grids">
      <g ref={xRef} className="grid-groupX" transform={`translate(0,${margins.top})`} />
      <g ref={yRef} className="grid-groupY" transform={`translate(${margins.left},0)`} />
    </g>
  );

}

export default Grids;
