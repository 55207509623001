import React from 'react';
import { useTranslation } from "react-i18next"
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { get } from "lodash";
import { saveFilters } from "reduxLib/services";
import { filterStyles } from "theme";
// import FilterComponentContainer from "./FilterComponentContainer";
import KCCheckBox from "../Elements/KCCheckbox";

const useStyles = makeStyles(filterStyles);

export const OrdersCheckBoxElement = (props) => {

    const { filters, filterKey, saveFilters, type, subtype, resetOthers } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const filter = get(filters, `${filterKey}.data`, []);
    const [groupControls, updateGroupcontrols] = React.useState([
        {
            name: t("order_block_free"),
            checked: false,
        },
        {
            name: t("order_blocked"),
            checked: false
        }
    ]);


    const setFilter = ({ item, target }) => {
        const checked = target.target.checked;
        const temp = groupControls.map(d => {
            if (d.name === item.parent) {
                return {
                    ...d,
                    checked: false
                }
            } else return d;
        })
        updateGroupcontrols(temp);
        const updatedobject = filter.map(d => {
            if (d.value === item.value) {
                return { ...d, checked }
            } else {
                if (resetOthers) {
                    return { ...d, checked: false }
                } else {
                    return d;
                }
            }
        });
        saveFilters({
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: updatedobject
                }
            }, type, subtype
        });
    }

    const checkAll = (name, e) => {
        const modified = filter.map(d => {
            if (d.parent === name) {
                return { ...d, checked: e.target.checked }
            } else return d;
        });
        const temp = groupControls.map(d => {
            if (d.name === name) {
                return {
                    ...d,
                    checked: e.target.checked
                }
            } else return d;
        })
        updateGroupcontrols(temp);
        // setFilterLocal(modified);
        saveFilters({
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: modified
                }
            }, type, subtype
        });
    }

    return (
        // <FilterComponentContainer {...props} testId='orderscheckbox'>
            <FormControl component="fieldset" data-testid="orderscheckbox">
                <FormGroup>
                    {
                        groupControls.map((ele, i) => {
                            const { name, checked } = ele;
                            return (
                                <div key={i}>
                                    <FormControlLabel
                                        control={<KCCheckBox name={name} data-testid='checkallcheckboxes' onChange={(e) => {
                                            checkAll(name, e)
                                        }} value={name} checked={checked} />}
                                        label={<Typography color="primary" className={clsx(classes.button, classes.contentText)}>{name}</Typography>}
                                    />
                                    {
                                        filter.map((d, index) => {
                                            if (d.parent === name) {
                                                return (<FormControlLabel
                                                    className={classes.shifttab}
                                                    key={index}
                                                    control={<KCCheckBox data-testid='childcheckbox' name={d.parent} color="primary" onChange={(e) => setFilter({ item: d, target: e })} checked={d.checked} />}
                                                    label={<Typography color="primary" className={clsx(classes.button, classes.contentText)}>{t(d.name) || "-"}</Typography>}
                                                />)
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </FormGroup>
            </FormControl>
        // </FilterComponentContainer>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype } = ownProps;
    return {
        filters: get(state, `options.filters.${subtype}`, {})
    }
};

const mapDispatchToProps = {
    saveFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(OrdersCheckBoxElement);
