import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"

const useTranslateHook = (value) => {

    const { t } = useTranslation();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(value && value.length) {
            let translatedArr = value.map(item => {
                return {
                    ...item,
                    title: t(item.title),
                }
            })
            setData(translatedArr);
        }
    }, [value])
    return data;
}

export default useTranslateHook;