import React, { useState } from "react";
import { makeStyles, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { uniq } from "lodash";
import BootstrapInput from "./BootstrapInput";

const useStyles = makeStyles((theme) => ({
    formelement: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formControl: {},
    chip: {
        margin: theme.spacing(.5)
    }
}));


export default function TimeMulti({ updatedTime, timedata }) {

    const classes = useStyles();
    const [val, setVal] = useState(timedata || []);
    const [inputvalue, setInput] = useState("");

    React.useEffect(() => {
        setVal(timedata);
        setInput(timedata?.[timedata?.length - 1] || "");
    }, [timedata])

    return (
        <FormControl variant="outlined" data-testid="timemulti" className={classes.formControl}>
            <TextField
                variant="outlined"
                required
                type="time"
                value={inputvalue}
                input={<BootstrapInput />}
                inputProps={{ "data-testid": "timemulti-text" }}
                onChange={({ target: { value } }) => {
                    const minutes = parseInt(value.split(":")[1], 10);
                    if (minutes % 30 === 0) {
                        const timedata = uniq([...val, value]);
                        setVal(timedata);
                        setInput(value)
                        updatedTime(timedata)
                    }
                }}
                size="small"
                placeholder="Time"
            />
        </FormControl>
    );
}
