import React from 'react'
import { useTranslation } from "react-i18next"
import { scaleBand, scaleLinear, scaleOrdinal, max, sum } from 'd3'
import { generatePath, useHistory,  useRouteMatch, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useTheme } from '@material-ui/core/styles';
import Axes from './Axes';
import Bars from './Bars';
import ReferenceBar from './ReferenceBar'
import ResponsiveWrapper from '../charts/ResponsiveWrapper'

const GuardRailBarChart = ({ wrapit, horizontal, header, keys, xKey, data, total, type, subtype, refLine, allkeys, ...props }) => {

    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const queryParams = qs.parse(location.search);

    const theme = useTheme();
    const { t } = useTranslation();
    let { margins, showRef, hideRefLine, isNotALink } = props;
    margins = { ...margins, bottom: 50 }

    const svgDimensions = {
        width: props.parentWidth,
        height: props.height + 20 || 250
    }

    const colors = scaleOrdinal()
        .domain(allkeys)
        .range([theme.palette.guardrailColors.consumer, theme.palette.guardrailColors.professional, theme.palette.guardrailColors.STO]);

    let totalSum = 0;
    data.forEach((d) => {
        d.total = sum(keys, k => +d[k]);
        totalSum += d.total;
        return d
    });

    let yDomain = [0, 0];
    if (data?.length > 0) {
        yDomain = [0, Math.max(max(data, d => sum(keys, k => +d[k])), max(data, d => sum([refLine], k => +d[k]))) + 10]
    }

    const setDetails = (data) => {
        const path = generatePath(match.path, {
            ...match.params,
            tabletype: undefined,
            page: "home"
        });
        history.push({
            pathname: path,
            search: qs.stringify({
              ...queryParams,
              filters: JSON.stringify({ guardrailDate: data?.dateTime }),
              viewid: undefined
            }, { encode: false })
          });
    }

    let xScale = scaleBand()
        .padding(0.5)
        .paddingInner(0.5)
        .domain(data.map(d => d[xKey]))
        .range([margins.left, svgDimensions.width - margins.right])

    let yScale = scaleLinear()
        .domain(yDomain)
        .nice()
        .range([svgDimensions.height - margins.bottom, margins.top]);


    const xAccessor = d => d[xKey];
    const centerPos = [svgDimensions.width / 2, svgDimensions.height / 2]

    const NoDataFound = ({ text }) => {
        return (
            <g transform={`translate(${centerPos})`}>
                <text textAnchor="middle" fill="white">{t('no_data_found')}</text>
            </g>
        )
    }

    return (
        <span data-testid='gbarChart' style={{ textAlign: 'center' }}>
            <svg width={svgDimensions.width} height={svgDimensions.height}>
                {
                    !totalSum ? <NoDataFound /> :
                        <g>

                            <Bars
                                id="guardrailChart"
                                horizontal={horizontal}
                                scales={{ xScale, yScale }}
                                margins={margins}
                                xKey={xKey}
                                data={data}
                                xAccessor={xAccessor}
                                colors={colors}
                                keys={keys}
                                type={type}
                                subtype={subtype}
                                isLink={true}
                                isGuardRail={true}
                                setDetails={isNotALink ? null : setDetails}
                            />
                            {
                                !hideRefLine && <ReferenceBar
                                    horizontal={horizontal}
                                    scales={{ xScale, yScale }}
                                    margins={margins}
                                    xKey={xKey}
                                    data={data}
                                    xAccessor={xAccessor}
                                    colors={colors}
                                    keys={keys}
                                    type={type}
                                    subtype={subtype}
                                    svgDimensions={svgDimensions}
                                    refLine={refLine}
                                    showRef={showRef}
                                    currentDay={props.currentDay}
                                />
                            }
                            <Axes
                                horizontal={horizontal}
                                wrapit={wrapit}
                                scales={{ xScale, yScale }}
                                margins={margins}
                                svgDimensions={svgDimensions}
                            />
                        </g>}

            </svg>
        </span>
    )
}

export default ResponsiveWrapper(GuardRailBarChart)
