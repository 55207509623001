import { tabledataConstants } from "../constants";
import fetch from "./serviceHelpers";
import { get } from "lodash";


export const getTableData = variables => dispatch => {

    const {  fetchEndPoint, tablebody, page, pageSize, type } = get(variables, "body", {});

    dispatch({
        type: tabledataConstants.LOADING,
        payload: true,
        variables
    });

    const url = `${fetchEndPoint}?pageSize=${pageSize}&pageNum=${page+1}`;

    return fetch(url, {
        method: "POST",
        body: tablebody,
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: tabledataConstants.TABLE_DATA_FETCH_SUCCESS,
                payload: d,
                variables
            });
            dispatch({
                type: tabledataConstants.LOADING,
                payload: false,
                variables
            })
        })
        .catch(err => {
            dispatch({
                type: tabledataConstants.LOADING,
                payload: false,
                variables
            });
            dispatch({
                type: tabledataConstants.TABLE_DATA_FETCH_FAILURE,
                variables,
                payload: []
            });
        })
}