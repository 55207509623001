import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { generatePath, useParams, withRouter } from "react-router-dom";
import { useRouteContext } from 'context/RouteContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { breadCrumbStyles } from 'theme';

const useStyles = makeStyles(breadCrumbStyles);

const OSMBreadCrumb = () => {
    const classes = useStyles();
    const { mainPath } = useRouteContext();
    const { t } = useTranslation();
    const params = useParams();

    const gotopage = React.useCallback(({ page }) => {
        return () => {
            return `${generatePath(
                mainPath,
                {
                    ...params,
                    page,
                })}?showDefault=true`
        }
    }, []);

    return (
        <div className={classes.main} data-testid="breadcrumb-comp">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link data-testid='breadcrumb_home' to={gotopage({ page: "home" })} className={classes.doccLink}>
                    {t('home')}
                </Link>
                <Typography className={classes.activeLinkLight}>{t('osm_docc_abbr')}</Typography>
            </Breadcrumbs>
            
        </div>
    );
}
export default withRouter(OSMBreadCrumb)