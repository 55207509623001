import laoChartConstants from "reduxLib/constants/LAO/laoChartConstants";

const initialState = {
    loading: true,
}

const laoChartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case laoChartConstants.LAO_CHARTS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case laoChartConstants.LAO_GET_EXPORT_CHART_SUCCESS:
        case laoChartConstants.LAO_GET_EXPORT_CHART_FAILED:
            return {
                ...state,
                loading: false,
                [action.subtype]: {
                    status: action.status,
                    data: action.payload
                }
            };

        default:
            return state;
    }
}

export default laoChartsReducer;  