import React, { useState, useEffect } from 'react';
import { businessUnits, distributionChannel, salesOrgsbyBU } from 'configs/appConstants';
import { Grid, Box, makeStyles, useMediaQuery, useTheme, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails  } from '@material-ui/core';
import CustomerSearchFilters from './CustomerSearchFilters';
import CustomerSearchListing from './CustomerSearchListing';
import SalesOfficeSearchListing from './SalesOfficeSearchListing';
import { useSelector } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';
import { ArrowBack } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filterStyles } from 'theme';
import { useTranslation } from 'react-i18next';


const filterViewTypeEnum = {
    "customer": "CUST",
    "salesOfc" : "SALES_OFFICE"
}

const useStyles = makeStyles(filterStyles)

const tooltipStyles = makeStyles((theme)=>({
    root:{
        borderLeft: `5px solid ${theme.palette.secondary.base}`
    }
}))

export const TooltipTitle = ({dc}) => {
    return (
        <Box data-testid='customertooltip' className={tooltipStyles().root}>
            <Box p={1} >Previously Selected Distribution Channel - {dc==='80'?90:80} </Box>
            <Box p={1} >You can replace the selection with Distribution Channel {dc} </Box>
        </Box>
    )
}

export default ({handleTabHideShow, selfalert}) => {

    const { t } = useTranslation();
    const [filterViewType, toggleFilterViewType] = useState(filterViewTypeEnum.customer)
    const [selectedDC, setDC] = useState(distributionChannel[0].value)
    const classes = useStyles()
    const selectedBusinessUnits = useSelector(({favorites})=> favorites.tempBusinessUnit)
    const [saleOrgsCheckboxArray, updateSaleOrgsCheckboxArray] = useState(salesOrgsbyBU.filter(({bu}) => !isEmpty(selectedBusinessUnits) ? selectedBusinessUnits.includes(bu) : businessUnits.includes(bu)).reduce((acc,{salesOrgs, bu,})=> [...acc,...salesOrgs.map((SO)=> ({value:SO.value, checked:true, name: `${SO.region} ${(bu).toLowerCase()}`}))],[]))
    
    const [expanded, setExpanded] = React.useState('rightpanel');
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }

    const customerFiltersHandlers = {
        searchType:{
            handleChange: (e) => {
                toggleFilterViewType(e.target.value)
            } 
        },
        selectedSalesOrg:{
            handleChange:(value) => updateSaleOrgsCheckboxArray(
                saleOrgsCheckboxArray.map((salesOrgs) => ({ ...salesOrgs, checked : salesOrgs.value === value ? !salesOrgs.checked : salesOrgs.checked})))
        },
        distributionChannel:{
            handleChange: (e) => {
                setDC(e.target.value)
            } 
        }
    }

    useEffect(()=>{
        updateSaleOrgsCheckboxArray(
            salesOrgsbyBU.filter(({bu}) => 
            !isEmpty(selectedBusinessUnits) ? selectedBusinessUnits.includes(bu) : businessUnits.includes(bu)).reduce((acc,{salesOrgs, bu, region}) => 
                    [
                        ...acc,
                        ...salesOrgs.map((SO)=> (
                            {   value:SO.value, 
                                checked:true, 
                                name: `${SO.region} ${bu.toLowerCase()}`
                            }))
                    ],[])
        )
    },[selectedBusinessUnits])

    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))
    return (
        <Grid container data-testid='customers' spacing={4}>
            {isMobile && <IconButton data-testid="mobilebackbutton" onClick={()=>handleTabHideShow(true)}>
                    <ArrowBack/><Typography>{t('customer_sales_office')}</Typography>
                </IconButton>
            }
            <CustomerSearchFilters selectedDC={selectedDC} saleOrgsCheckboxArray={sortBy(saleOrgsCheckboxArray,'value')}  customerFiltersHandlers={customerFiltersHandlers} filterViewTypeEnum={filterViewTypeEnum} filterViewType={filterViewType} expanded={expanded} handleChange={handleChange}  />
            <Grid item xs={12} sm={12} md={8} lg={9} >
            <Accordion expanded={ isMobile ? expanded === 'rightpanel' : true} onChange={handleChange('rightpanel')} className={classes.accordian} disabled={!isMobile} >
                <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                expandIcon={isMobile && <ExpandMoreIcon />}
                className={classes.accordianSummary}
                >
                    {isMobile && <Typography variant='body1' >{t('results')}</Typography>}
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails}>
                    {
                        filterViewType === filterViewTypeEnum.customer ? 
                        <CustomerSearchListing listingFilters={{selectedDC, filterViewType, saleOrgsCheckboxArray:sortBy(saleOrgsCheckboxArray, 'value')}} selfalert={selfalert} />:
                        <SalesOfficeSearchListing listingFilters={{selectedDC, filterViewType, saleOrgsCheckboxArray:sortBy(saleOrgsCheckboxArray, 'value')}} selfalert={selfalert} />
                    }
                </AccordionDetails>
            </Accordion>

            </Grid>
        </Grid>
    )

}
