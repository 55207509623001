import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Grid, makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';
import Card from './Card';
import { isEmpty, get } from "lodash";
import DivergingBarChart from "../D3Charts/DivergingBartChart"
import { changeTab, setViewBy } from "../../reduxLib/services"
import { useSelector, useDispatch } from 'react-redux';
import { performanceTableStyle } from 'theme'

const useStyles = makeStyles(performanceTableStyle);

const PerformanceMapTable = ({ data, perfTableRedirection, type, singleLocation }) => {

  const classes = useStyles({ singleLocation });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationCoreData = useSelector(({ sites }) => sites.locations || [])
  const showTabsBy = useSelector(({ options }) => get(options, "showTabsBy", null))
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));

  return (
    <Grid container className={classes.main} data-testid="performancetable">
      <Card innercard className={classes.innerCard} >
        <Box pl={!isMobile ? 2 : 0} pr={!isMobile ? 2 : 0} pb={2}>
          <Grid container item>
            <Grid container item xs={12} sm={12} md={9} lg={9} justify="flex-end" >
              <Grid container className={classes.tableHeader} justify="flex-end" item>
                <Grid item xs={6} sm={5} md={4} lg={4}>
                  <Typography variant="h3">{t('mill_dc_name')}</Typography>
                </Grid>
                {!isMobile && <Grid item xs={2}>
                  <Typography align='center' variant="h3">{t('status')}</Typography>
                </Grid>}
                <Grid item xs={6} sm={5} md={5} lg={5} className={classes.hourtitle}>
                  <Typography align='center' variant="h3">{t('net_loading_hours')}</Typography>
                </Grid>
              </Grid>
              {isEmpty(data) ? (
                <Grid
                  container
                  justify="center"
                  alignItems="baseline"
                  className={classes.nodata} data-testid="noSiteData">
                  <Typography color="primary">{t('no_data_found')}</Typography>
                </Grid>
              ) : <>
                <Grid item xs={6} sm={7} md={6} lg={6}>
                  {data.map((item) => (
                    <Grid container item className={classes.tableBody} data-testid="sitesData">
                      <Grid xs={8}>
                        <Typography variant="h6" color="primary">
                          <span
                            data-testid="distributionlink"
                            id={item?.siteNum}
                            className={classes.link}
                            onClick={async () => {
                              const selectedSiteData = locationCoreData.filter((site) => site.siteNum === item.siteNum)[0]
                              if (showTabsBy === "customer" || showTabsBy === null) {
                                dispatch(setViewBy("locations"))
                              }
                              await dispatch(changeTab(selectedSiteData))
                              
                              //Batch Call for two state changes
                              perfTableRedirection(selectedSiteData.siteNum, type)

                            }}>
                            {`${item?.shortName} ${item?.siteNum ? `(${item?.siteNum})` : ""}`}
                          </span>
                        </Typography>
                      </Grid>
                      {!isMobile && <Grid xs={4}>
                        <Typography align='center' variant="h6" color="primary">
                          {t(item.status)}
                        </Typography>
                      </Grid>}
                    </Grid>
                  ))}
                </Grid>
                <Grid className={classes.chart} xs={6} sm={5} md={5} lg={5}>
                  <DivergingBarChart data={data} ></DivergingBarChart>
                </Grid></>
              }
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

export default PerformanceMapTable;
