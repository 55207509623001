import { guardrailsConstants } from '../constants';

const guardrailsReducer = (state = {
    getGaurdrailsLoading: false,
    updateGaurdrailsLoading: false,
    allSiteGuardrails: { data: [], allowedToEdit: false, requestedForAccess: false },
    notification: {},
    disableButton: true,
    pending_requests: [],
    requestStatus: {},
    requestLoading: false,
    isAdmin: false
}, action) => {
    switch (action.type) {

        case guardrailsConstants.GUARDRAILS_FETCH_SUCCESS:
            return {
                ...state,
                allSiteGuardrails: {
                    data: action.payload?.data?.guardrailList || [],
                    allowedToEdit: action.payload?.data?.authorizedUser === "Y",
                    requestedForAccess: action.payload?.data?.requestedForAccess === "Y"
                },
                notification: {},
                disableButton: false
            }

        case guardrailsConstants.GUARDRAILS_FETCH_FAILED:
            return { ...state, notification: {}, allSiteGuardrails: { data: [], allowedToEdit: false, requestedForAccess: false } }

        case guardrailsConstants.GUARDRAILS_UPDATE_SUCCESS:
            const newGuardrailsObject = action.payload
            return {
                ...state, notification: { message: "Changes updated successfully", severity: "info" },
                allSiteGuardrails: {
                    ...state.allSiteGuardrails,
                    data: [...state.allSiteGuardrails.data.filter(({ siteNum }) => siteNum !== newGuardrailsObject.siteNum), newGuardrailsObject]
                }
            }

        case guardrailsConstants.GUARDRAILS_UPDATE_FAILED:
            return { ...state, notification: { message: " You are not authorized to make changes", severity: "error" } }

        case guardrailsConstants.GUARDRAILS_FETCH_LOADING:
            return { ...state, getGaurdrailsLoading: action.payload, notification: {} }

        case guardrailsConstants.GUARDRAILS_UPDATE_LOADING:
            return { ...state, updateGaurdrailsLoading: action.payload, notification: {} }

        case guardrailsConstants.GUARDRAILS_REQUEST_ACCESS_SUCCESS:
        case guardrailsConstants.GUARDRAILS_REQUEST_ACCESS_FAILED:
            return {
                ...state,
                allSiteGuardrails: {
                    ...state?.allSiteGuardrails,
                    requestedForAccess: true
                },
                requestStatus: action.payload,
            }

        case guardrailsConstants.GUARDRAILS_RESET_REQUEST_ACCESSS:
            return {
                ...state,
                requestStatus: {},
            }
            
        case guardrailsConstants.GUARDRAILS_REQUEST_LOADING:
            return {
                ...state,
                requestLoading: action.payload
            }

        case guardrailsConstants.GUARDRAILS_GET_REQUESTS_SUCCESS:
        case guardrailsConstants.GUARDRAILS_GET_REQUESTS_FAILED:
            return {
                ...state,
                pending_requests: action.payload.requests,
                isAdmin: action.payload.adminUser
            }

        case guardrailsConstants.GUARDRAILS_APPROVE_DECLINE_REQUEST_FAILED:
        case guardrailsConstants.GUARDRAILS_APPROVE_DECLINE_REQUEST_SUCCESS:
            const { payload } = action;
            const updatedRequests = state?.pending_requests?.filter(d => d.requestID !== payload.requestID)
            return {
                ...state,
                requestLoading: false,
                pending_requests: updatedRequests,
            }

        default:
            return state
    }
}

export default guardrailsReducer;