import React from 'react';
import { get } from "lodash";
import { appTheme } from 'theme';
import { ItemDetailsTable } from "components/common/ItemDetailsTable"
import useTranslateHook from "i18n/useTranslateHook";
import { promotionalColumns } from "./ItemDetailsTableOutBound";

const rowStyle = (d) => {
    return {
        backgroundColor: appTheme.palette.secondary.dark,
        flexGrow: 1,
        whiteSpace: 'nowrap',
        borderBottom: 'red',
        borderLeft: d.orderExecutionHealth === "RED" ? '5px solid #FF4081' : ''
    }
}

export const columns = {
    columnOrder: [
        { title: 'sl_no', field: 'slno', emptyValue: "-" },
        ...promotionalColumns,
        {
            title: 'material_description', field: 'itemDesc',
            render: rowData => {
                    return <div data-testid="itemDesc" >{rowData.itemDesc}</div>
            },
            emptyValue: "-"
        },
        {
            title: 'load_qty', render: (d) => {
                if (d.loadedQty >= 0) {
                    return <div data-testid="loadedQty">{`${d.loadedQty} ${d.qtyUom}`}</div>
                } else {
                    return <div data-testid="loadedQty" >{"-"}</div>
                }
            }, field: 'loadedQty'
        },
    ],
    columnConfiguration: (d) => {
        return {
            materialNum: get(d, "materialNum", "-"),
            itemDesc: get(d, "itemDesc", "-"),
            loadedQty: d.loadedQty >= 0 ? `${d.loadedQty} ${d.qtyUom}` : '-'
        }
    }
}

const ItemDetailsTableInBound = ({ data: { orderStatusTableRowId } }) => {
    return (
        <ItemDetailsTable testId="itemdetailsinbound" orderStatusTableRowId={orderStatusTableRowId} columns={useTranslateHook(columns.columnOrder)} rowStyle={rowStyle} />
    )
}

export default ItemDetailsTableInBound;
