import React from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import clsx from 'clsx';
import KCButton from '../../components/common/Button';
import IOSSwitch from '../../components/common/IOSSwitch';
import {
  Typography,
  Box,
  FormControlLabel,
  Grid,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import LocationsFilterLayout from '../../components/header/LocationsFilterLayout';
import { get, intersection, isEmpty } from 'lodash';
import KCSelect from 'components/common/Select';
import { viewByConstants } from 'configs/appConstants';
import {
  addItem,
  removeItem,
  clearAll,
  getLocationsService as getLocationsServiceAction,
  showhideFavourites as showhideFavouritesAction,
  addItemsLocations as addItemsLocationsAction,
  removeFavs,
  getFilterLayouts as getFilterLayoutsAction,
  getAllRequests,
  setBusinessLocalAction as setBusinessLocal,
  setViewBy as setViewByAction,
  getSalesOfficeService as getSalesOfficeServiceAction,
  addItemsCustomer as addItemsCustomerAction,
  joinFavstoCuso,
  removeFavsCuso,
  getPerformanceMapService,
  updateLocations,
} from '../../reduxLib/services';
import qs from 'query-string';
import Snack from 'components/common/Helpers/Snack';
import { filterbarStyles } from 'theme';
import { defaultShowTabsBy, getTabsData, isJsonParsable } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams, useRouteMatch, useLocation, generatePath } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { useMemo } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { businessUnits as defaultBusinessUnits } from 'configs/appConstants';

const useStyles = makeStyles(filterbarStyles);

const FilterBar = React.memo(
  ({
    showhidefavs,
    showhideFavourites,
    addItemsLocations,
    getLocationsService,
    getSalesOfficeService,
    getFilterLayouts,
    locations,
    setBusinessLocalAction,
    selections,
    addItemsCustomer,
    isAdmin,
    businessUnits,
    ...rest
  }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const [snack, setSnack] = React.useState({
      open: false,
      severity: null,
      message: null
    });

    const match = useRouteMatch();
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const queryParams = qs.parse(location.search);
    const { refresh } = useAppContext();

    const [viewby, setViewBy] = React.useState(params.showtabsby);
    const [isFav, setFav] = React.useState(showhidefavs);
    const [allFavs, setAllFavs] = React.useState(() => {
      return [
        ...getTabsData(rest.favorites.customer, viewByConstants.customer),
        ...getTabsData(rest.favorites.locations, viewByConstants.locations)
      ]
    });
    const { t } = useTranslation();


    React.useEffect(() => {
      setViewBy(params.showtabsby);
      rest.setViewBy(params.showtabsby);
    }, [params.showtabsby]);

    React.useEffect(() => {
      if (params.showtabsby !== viewby) {
        const newpath = generatePath(match.path, {
          ...params,
          type: "mylocation",
          showtabsby: viewby
        });
        history.push({
          ...location,
          pathname: newpath
        });
      }
    }, [viewby]);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnack({
        ...snack,
        open: false
      });
    };

    const setShowFavs = (status) => {
      setFav(status);
      showhideFavourites(status);

      if (!status) {
        rest.removeFavs(rest.favorites.locations);
        rest.removeFavsCuso(rest.favorites.customer);
      } else {
        rest.joinFavstoCuso(rest.favorites.customer);
        addItemsLocations(rest.favorites.locations);
      }
    };

    useMemo(() => {
      let locations = selections.locations;
      if(rest.favorites.locations?.length > 0 && locations.length > 0) {
        const favs = rest.favorites.locations?.map(d => d.siteNum);
        locations = locations?.map(d => {
          return favs.includes(d.siteNum) ? {
            ...d,
            isFav: true
          } : d
        })
        dispatch(updateLocations(locations))
      }
    }, [rest.favorites.locations.length, selections.locations.length])


    useMemo(() => {
      const allSelections = [
        ...getTabsData(selections.customer, viewByConstants.customer),
        ...getTabsData(selections.locations, viewByConstants.locations)
      ].map((d) => d.custom.id);
      const allFavorites = [
        ...getTabsData(rest.favorites.customer, viewByConstants.customer),
        ...getTabsData(rest.favorites.locations, viewByConstants.locations)
      ].map((d) => d.custom.id);

      const favLocations = getTabsData(rest.favorites.locations, viewByConstants.locations).map(d => d.custom.id);
      let allLocations = getTabsData(selections.locations, viewByConstants.locations);

      if (favLocations.length > 0) {
        allLocations = allLocations.map(d => {
          if (favLocations.includes(d.custom.id)) {
            return {
              ...d,
              isFav: true
            }
          }
          return d;
        });
        addItemsLocations(allLocations);
      }
      setAllFavs(allFavorites);
      const favsintersection = intersection(allSelections, allFavorites).length !== 0;
      setFav(favsintersection);
      showhideFavourites(favsintersection);
    }, [
      selections.locations.length,
      selections.customer.length,
      rest.favorites.locations.length,
      rest.favorites.customer.length,
    ]);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    React.useEffect(() => {
      if (!showhidefavs) {
        setFav(showhidefavs);
      }
    }, [showhidefavs]);

    useDeepCompareEffect(() => {
      dispatch(getPerformanceMapService({ businessUnit: businessUnits }));
    }, [refresh, businessUnits]);

    React.useEffect(() => {
      // if (locations.length === 0) {
      //   getLocationsService(); //We are doing this actions already inredirect manager everytime
      // }
      getSalesOfficeService();
      if (!rest.fetchStatus) {
        const global = isJsonParsable(queryParams.global);
        getFilterLayouts({
          loadBU: businessUnits.length === 0,
          showFavs: global ? global?.cuso?.length === 0 && global?.sites?.length === 0 : true,
          global
        });
      }
      dispatch(getAllRequests());
    }, []);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
      <>
        <Grid container className={classes.root}>
          <Box mr={2} alignItems="center" className={clsx(classes.filter)}>
            <KCButton
              disableRipple
              endIcon={!open ? <ExpandMore /> : <ExpandLess />}
              className={classes.location}
              onClick={handleClick}
              color="primary">
              {t('filter_by')}
            </KCButton>
          </Box>
          {selections.locations.length > 0 && selections.customer.length > 0 ? (
            <Box mr={2} alignItems="center" className={clsx(classes.filter)}>
              {!isMobile && (
                <Typography className={classes.textHead}>
                  {t('show_tabs_by')}
                </Typography>
              )}
              <KCSelect
                customClasses={{ button: classes.location }}
                color="primary"
                value={viewby}
                onChange={(value) => {
                  setViewBy(value);
                  rest.setViewBy(value);
                }}
                label={t('show_tabs_by')}
                options={[
                  { name: t('location'), value: 'locations' },
                  { name: t('customer_sales_office'), value: 'customer' }
                ]}
              />
            </Box>
          ) : null}

          {allFavs.length > 0 ? (
            <Box mr={2}>
              <FormControlLabel
                className={classes.showfavs}
                color="primary.main"
                value="showfavs"
                control={
                  <IOSSwitch checked={isFav} switchEvent={setShowFavs} />
                }
                label={
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    data-testid="hide-show">
                    {isFav ? t('hide') : t('show')} {t('favourites')}
                  </Typography>
                }
                labelPlacement="start"
              />
            </Box>
          ) : null}
        </Grid>
        <LocationsFilterLayout open={open} handleClick={handleClick} />
        <Snack {...snack} handleClose={handleClose} />
      </>
    );
  }
);

const mapStatetoProps = (state) => {
  return {
    loading: state.sites.loading,
    showhidefavs: state.options.showfavs,
    user: state.auth.user || null,
    favsLoading: state.favorites.loading,
    cusoFavs: state.favorites.favCuso,
    favorites: {
      locations: state.favorites.favorites,
      customer: [
        ...get(state, 'favorites.favCuso.FAV_CUST', []),
        ...get(state, 'favorites.favCuso.FAV_SALES_OFFICE', [])
      ]
    },
    locations: state.sites.locations || [],
    filterLayout: state.user.filterLayout,
    fetchStatus: get(state, ["user", "fetched"], false),
    businessUnits: get(state, 'favorites.tempBusinessUnit', defaultBusinessUnits),
    selections: {
      locations: state.items.items,
      customer: [
        ...get(state, 'items.cuso.CUST', []),
        ...get(state, 'items.cuso.SALES_OFFICE', [])
      ]
    },
    showTabsBy: defaultShowTabsBy(state),
    isAdmin: get(state, ['guardrails', 'isAdmin'], false),
    optionsShowTabsBy: get(state, 'options.showTabsBy', null)
  };
};

const mapDispatchToProps = {
  showhideFavourites: showhideFavouritesAction,
  addItemsLocations: addItemsLocationsAction,
  removeFavs,
  addItem,
  removeItem,
  clearAll,
  getLocationsService: getLocationsServiceAction,
  getSalesOfficeService: getSalesOfficeServiceAction,
  getFilterLayouts: getFilterLayoutsAction,
  setBusinessLocalAction: setBusinessLocal,
  setViewBy: setViewByAction,
  addItemsCustomer: addItemsCustomerAction,
  joinFavstoCuso,
  removeFavsCuso
};

export default connect(mapStatetoProps, mapDispatchToProps)(FilterBar);
