import LAOVolumeChart from 'components/LAO/LaoVolumeChart';
import LaoOrderDetails from './LaoOrderDetails';
import React from 'react';

const LaoHome = () => {
  return (
    <div>
        <LAOVolumeChart />
        <LaoOrderDetails />
    </div>
  )
}

export default LaoHome