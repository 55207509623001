import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFeatureFlag } from 'configs/useFeatureFlag';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
    powerBiReport: {
        height: `${theme.spacing(12.25)}vh`,
        width: '100%',
        marginTop: theme.spacing(4),
        padding: `0px ${theme.spacing(1)}px`
    }
}));

const AnalyticsReport = () => {
    const classes = useStyles();
    const analyticsReports = useFeatureFlag('analyticsReports');
    const { reportUrl: reportUrl } = useSelector(({ analytics }) => analytics);
    const params = useParams();
    const location = useLocation();
    const [reportURL, setReportURL] = React.useState("");

    React.useEffect(() => {
        const reportURL = analyticsReports?.description?.filter(desc => desc?.name === params?.folder)[0]?.reports?.filter(item => item?.reportName === params?.report)[0]?.reportUrl;
        setReportURL(reportURL)
    }, [params, location, analyticsReports])

    return (
        <main key={params.report}>
            <Helmet>
                <title>{`CT - ${params.report}`}</title>
            </Helmet>
            <iframe
                data-testid="powerBiReport"
                className={classes.powerBiReport}
                src={reportURL || reportUrl }
                frameborder="0"
                allowFullScreen="true"></iframe>
        </main>
    );
};

export default AnalyticsReport;
