import React, { useEffect } from 'react'
import { Grid, Box, Typography, makeStyles, AppBar, Tabs, Tab } from "@material-ui/core"
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment-timezone";
import getShipmentDetailsService from '../../reduxLib/services/getShipmentDetailsService'
import ShipmentTrackingDetails from 'components/common/ShipmentTrackingDetails';
import { detailsConstants } from '../../reduxLib/constants';
import {shipmentStyles} from "theme"
import { useTranslation } from 'react-i18next';
import BreadCrumb from 'components/common/Elements/BreadCrumb';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(shipmentStyles);


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div 
            className={other.className}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const ShipmentDetails = ({ setDetails, type, pageDetails, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    
    const getShippingDetails = (activeSearchType) => {
        dispatch(getShipmentDetailsService({ 
            shipmentNum: params.shipmentNum, 
            searchType: activeSearchType 
        }));
    }
    const [value, setValue] = React.useState(0);
    const shipmentDetails = useSelector(({ shipment: { details } }) => details);
    const shipmentTrackingData = useSelector(({ shipment: { details: {origin}, locationHistory, stops } }) => ({ origin, locationHistory, stops }) );

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue) {
            getShippingDetails(detailsConstants.TRACKING_DETAILS);
        }
    };

    useEffect(() => {
        getShippingDetails(detailsConstants.STOPS_DETAILS);
    }, [])

    return (
        <Grid className={classes.component}>
            <BreadCrumb />
            <Grid className={classes.container} data-testid="shipmentdetails" justify='space-between' container >
                <Grid  item container xs={12} sm={12} md={2} lg={2} className={classes.leftBox} >
                    <Grid item xs={6} sm={6} md={12} lg={12} >
                        <Typography variant="h3" data-testid="shipment-number-title">{t('shipment_load_number')}</Typography>
                        <Typography className={classes.shipmentNumber} data-testid={shipmentDetails.shipmentNum}>{shipmentDetails.shipmentNum}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={12} lg={12} >
                        <Typography variant="h3" data-testid="fourkites-eta-title">{t('destination_eta')}</Typography>
                        <Typography variant="h6" data-testid="next-stop-ETA">{shipmentDetails?.nextStopETA ? moment(shipmentDetails.nextStopETA)?.tz(shipmentDetails.nextStopTimezone)?.format(`MMM DD YYYY HH:mm z`) : "-" }</Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={9} lg={9} spacing={4} >
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <Typography variant="h3" data-testid="origin-title">{t("origin")}</Typography>
                        <Typography variant="h6" data-testid="origin-name">{shipmentDetails.origin?.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <Typography variant="h3" data-testid="carrier-title">{t("carrier")}</Typography>
                        <Typography variant="h6" data-testid="service-code">{shipmentDetails.tariffServiceCode || "-" }</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <Typography variant="h3" data-testid="latest-location-title">{t('latest_location')}</Typography>
                        {shipmentDetails?.currentLocation && <Typography variant="body1" data-testid={`${shipmentDetails.currentLocation?.city}, ${shipmentDetails.currentLocation?.state}`}>{shipmentDetails.currentLocation?.city}, {shipmentDetails.currentLocation?.state}</Typography>}
                        <Typography variant="h6" data-testid="current-location-time">{shipmentDetails?.currentLocation ? moment(shipmentDetails.currentLocation?.timestamp)?.tz(shipmentDetails.origin?.timezone)?.format(`MMM DD YYYY HH:mm z`) : "-" }</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <Typography variant="h3" data-testid="destination-title">{t('destination')}</Typography>
                        <Typography variant="h6" data-testid="finaldestination">{ shipmentDetails?.finalDestination ?  shipmentDetails.finalDestination?.name : "-" }</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4}>
                        <Typography variant="h3" data-testid="mode-title">{t('mode')}</Typography>
                        <Typography variant="h6" data-testid="mode">{shipmentDetails.equipmentTypeMode || "-" }</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <div className={classes.root} data-testid="shipmentdetailstabs">
                    <AppBar position="static" className={classes.appbar} elevation={0}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="primary"
                        >
                            <Tab className={classes.tab} selectionFollowsFocus
                                label={<Typography className={classes.tabName} variant="subtitle1" >{t('stops')}</Typography>}
                                id="scrollable-auto-tab-0"
                                aria-controls="scrollable-auto-tabpanel-0"
                            />
                            <Tab className={classes.tab} selectionFollowsFocus
                                label={<Typography className={classes.tabName} variant="subtitle1" >{t('tracking_details')}</Typography>}
                                id="scrollable-auto-tab-1"
                                aria-controls="scrollable-auto-tabpanel-1"
                            />
                        </Tabs>
                    </AppBar>
                    <TabPanel className={classes.tabPanel} value={value} index={0} >
                        <Typography data-testid="stops" variant="h3">{t("stops")}</Typography>
                        {shipmentDetails?.stops && shipmentDetails.stops.map((stop, index) => (
                            <Grid container className={classes.stops} key={index} spacing={2}>
                                <Grid item variant="body1" xs={12} sm={12} md={3} lg={3}>
                                    <Typography>{stop.stopType}</Typography>
                                </Grid>
                                <Grid item center variant="h6" xs={6} sm={6} md={2} lg={2}>
                                    <Typography data-testid="location">{stop.location?.name}, {stop.location?.city}, {stop.location?.state}</Typography>
                                </Grid>
                                <Grid item center xs={6} sm={6} md={2} lg={2}>
                                    { stop.plannedArrivalDateTime && <><Typography variant="h6">{stop.stopNum===1 ? t("planned_arrival") : t("appointment_time")}</Typography>
                                    <Typography data-testid="planned-arrival">{moment(stop.plannedArrivalDateTime)?.tz(stop.location.timezone)?.format(`MMM DD YYYY HH:mm z`)}</Typography></>}
                                </Grid>
                                <Grid item center xs={6} sm={6} md={2} lg={2}>
                                    { stop.actualArrivalDateTime && <><Typography variant="h6" >{t('detected_near_facility')}</Typography>
                                    <Typography data-testid="actual-arrival">{moment(stop.actualArrivalDateTime)?.tz(stop.location.timezone)?.format(`MMM DD YYYY HH:mm z`)}</Typography></>}
                                </Grid>
                                {stop.actualDepartureDateTime && <Grid item xs={6} sm={6} md={2} lg={2} >
                                    <Typography center variant="body1" >{t("departed_facility")}</Typography>
                                    <Typography data-testid="actual-departure">{moment(stop.actualDepartureDateTime)?.tz(stop.location.timezone)?.format(`MMM DD YYYY HH:mm z`)}</Typography>
                                </Grid>}
                            </Grid>
                        ))}
                    </TabPanel>
                    <TabPanel className={classes.tabPanel} value={value} index={1} >
                        <ShipmentTrackingDetails type={type} shipmentTrackingData={shipmentTrackingData}/>
                    </TabPanel>
                </div>
            </Grid>
        </Grid>
    )
}

export default ShipmentDetails;
