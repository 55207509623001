import React, { createRef } from "react";
import EMEAKPIsWrapper from "components/EMEA/EMEA_Common/EMEAKPIsWrapper";
import EMEABreadcrumb from "components/EMEA/EMEA_Common/EMEABreadcrumb";
import EmeaTable from 'components/EMEA/EMEA_Common/EmeaTable';
import { emeaCustReturnsColumns, emeaCustReturnsFixedColumn } from 'reduxLib/constdata/EMEA/emeaCustReturnsColumns';
import emeaCustReturnsFilters from 'reduxLib/constdata/EMEA/emeaCustReturnsFilters';
import { endpoints } from 'helpers';

const EMEAReturns = () => {
  const tableRef = createRef();
  const exclusionArray = ["searchStringList", "requestedDeliveryDateCustom", "documentDate", "excludeSoldToNumList", "excludeMaterialNumberList", "excludeDocumentTypesList", "excludeOrderReasonCodeList"];
  const orderBy = [
    {
      "key": "orderHealth", "ordering": "desc"
    },
    {
      "key": "orderNum", "ordering": "asc"
    }
  ];
  
  return (
    <>
      <EMEABreadcrumb pageName={"returns"} />
      <EMEAKPIsWrapper />
      <EmeaTable 
        fetchEndPoint={endpoints.emea.emeaTable} 
        columns={emeaCustReturnsColumns}
        defaultColumns={emeaCustReturnsColumns}
        tableName={"custReturns"}
        subType={"EMEA_CUST_RETURNS"}
        orderType={"CUST_RETURN"}
        orderBy={orderBy}
        tableRef={tableRef}
        filterBody={emeaCustReturnsFilters}
        filterType={"emeaCustReturnsFilters"}
        filterExclusionArray={exclusionArray}
        searchText={'search_helper_text_returns_page'}
        fixedColumn={emeaCustReturnsFixedColumn}
      />
    </>
  )
}

export default EMEAReturns;