import React from "react";
import PieChart from "components/D3Charts/PieChart";
import { useTranslation } from "react-i18next";

export const AppointmentDetailsChartBuilder = (props) => {

    const { margins, height, type, subtype, chartsData, setDetails } = props
    const xKey = "stateDesc";
    const { t } = useTranslation();

    const barData = chartsData ? chartsData.map(d => {
        return {
            ...d,
            name: d[xKey],
            value: d.totalCount,
            blueCount: d.greenCount
        }
    }) : [];

    return (
        <div data-testid="transportAppointmentStatus" >
            <PieChart center={t("stops")}  footer="&nbsp;" data={barData} xKey="name" margins={margins} height={height} legend type={type} subtype={subtype} setDetails={setDetails} />
        </div>
    );
}
