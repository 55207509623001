// import moment from "moment-timezone";

const emeaCustReturnsFilters = {
  searchStringList: {
    type: "text",
    name: "search",
    stringToArray: true,
    data: null
  },
  soldToNumList: {
    type: "text",
    name: "sold_to_party",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeSoldToNumList',
    data: null
  },
  materialNumberList: {
    type: "text",
    name: "material",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeMaterialNumberList',
    data: null
  },
  documentTypeList: {
    type: "checkbox",
    name: "sales_document_type",
    data: [
      { name: "zre_kc_returns", value: "ZRE", checked: false },
      { name: "zrfc_kc_foc_returns", value: "ZRFC", checked: false },
      { name: "zrbt_kc_bag_n_tag_return", value: "ZRBT", checked: false },
      { name: "zcrf_kc_credit_dl_failure", value: "ZCRF", checked: false },
      { name: "zdrf_kc_debit_dl_failure", value: "ZDRF", checked: false }
    ]
  },
  requestedDeliveryDateCustom: {
    type: "customdaterange",
    name: "delivery_date",
    data: null
  },
  requestedDeliveryDate: {
    type: "customdaterange",
    name: "delivery_date",
    shortName: 'Delivery Date',
    data: null
  },
  originSites: {
    type: "text",
    name: "plant",
    stringToArray: true,
    data: null
  },
  deliveryBlocked: {
    type: "checkboxradio",
    name: "delivery_block",
    defaultValue: null,
    data: [
      { name: "blocked", value: "Y", checked: false },
      { name: "not_blocked", value: "N", checked: false }
    ]
  },
  orderReasonCodeList: {
    type: "text",
    name: "order_reason_code",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeOrderReasonCodeList',
    data: null
  },
  divisionList: {
    type: "text",
    name: "division",
    stringToArray: true,
    data: null
  },
  overallCompletionStatusList: {
    type: "checkbox",
    name: "status",
    data: [
      { name: "not_relevant", value: "", checked: false },
      { name: "not_yet_processed", value: "A", checked: false },
      { name: "partially_processed", value: "B", checked: false },
      { name: "completely_processed", value: "C", checked: false }
    ]
  },
  // documentDate: {
  //   type: "daterange",
  //   dummytime: true,
  //   timeformat: "T",
  //   startTime: "[00:00:00.000]",
  //   endTime: "[23:59:59.999]",
  //   name: "document_date",
  //   shortName: "Document Date",
  //   data: {
  //     startTime: moment().subtract(90, "days").format("YYYY-MM-DD [00:00:00.000]"),
  //     endTime: moment().format("YYYY-MM-DD [23:59:59.999]")
  //   }
  // },
  excludeSoldToNumList: {
    type: "text",
    name: "excluded_sold_to_party",
    stringToArray: true,
    data: null
  },
  excludeMaterialNumberList: {
    type: "text",
    name: "excluded_material",
    stringToArray: true,
    data: null
  },
  excludeDocumentTypesList: {
    type: "text",
    name: "excluded_sales_document_type",
    stringToArray: true,
    data: null
  },
  excludeOrderReasonCodeList: {
    type: "text",
    name: "excluded_order_reason_code",
    stringToArray: true,
    data: null
  },
  corporateCustomerName: {
    type: "text",
    name: "customer",
    data: null
  },
  returnProcessingTimeBuckets: {
    type: "checkbox",
    name: "processing_time",
    data: [
      { name: "less_than_two_days", value: "1", checked: false },
      { name: "two_to_ten_days", value: "2", checked: false },
      { name: "greater_than_2_days", value: "3", checked: false }
    ]
  },
}

export default emeaCustReturnsFilters;