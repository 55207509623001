import React from 'react';
import CustomCellRender from "components/common/CustomCellRender";

export const emeaOrderEntryByCustomerColumns = {
  columnOrder: [
    {
      title: 'customer', field: "corporateCustomerName",
      render: rowData => {
        return <CustomCellRender id="corporateCustomerName" value={rowData.corporateCustomerName} />
      }
    },
    {
      title: 'order_value', field: "netOrderValue",
      render: rowData => {
        return <CustomCellRender id="netOrderValue" value={(rowData.netOrderValue).toLocaleString('en-US', {maximumFractionDigits:2})} />
      }
    },
    {
      title: 'quantity', field: "netOrderVolume",
      render: rowData => {
        return <CustomCellRender id="netOrderVolume" value={`${rowData.netOrderVolume.toLocaleString('en-US')} ${rowData.volumeUom}`} />
      }
    },
    {
      title: 'total_num_of_orders', field: "totalOrders",
      render: rowData => {
        return <CustomCellRender id="totalOrders" value={rowData.totalOrders} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}