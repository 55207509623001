import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';


const KCCheckBox = withStyles(theme => ({
    root: {
        color: theme?.palette.primary.contrastText,
        '&$checked': {
            color: theme?.palette.primary.contrastText,
        },
    },
    checked: {},
}))((props) => <Checkbox color="default" {...props} />);

export default KCCheckBox;