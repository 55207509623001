import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  text: {
    pointerEvents: 'none'
  },
  legend: {
    height: theme.spacing(1),
    width: theme.spacing(1)
  },
}));
const DivergingBars = (props) => {

  const classes = useStyles();
  const { scales, data} = props
  const { xScale, yScale } = scales

  const renderItem = (item, i) => {
    let x = null;
    let y = null;
    let width = null;
    let height = null;

    x = xScale(Math.min(item.value, 0))
    y = yScale(i);
    height = yScale.bandwidth();
    width = Math.abs(xScale(item.value) - xScale(0));

    return (
      <g key={i}>
        {
          height && width ?
            <rect
              x={x}
              y={y}
              key={i}
              height={height}
              width={width}
              fill={item.color}
              item={item}
              item-key={i}
              item-index={i}
            />
            : null
        }
        <text
          x={xScale(item.value) + Math.sign(item.value - 0) * 4}
          y={y + yScale.bandwidth() / 2}
          dy={'0.35em'}
          fontSize={11}
          textAnchor={item.value < 0 ? "end" : "start"}
          alignmentBaseline="middle"
          fill="white"
          className={classes.text}
        >
          {parseFloat(item.value).toFixed(1)}
        </text>
      </g>
    );
  }

  const renderSeries = () => {
    return data.map((d, i) => {
      return renderItem(d, i)
    })
  }


  return (
    <g data-testid='bars'>
      {renderSeries()}
    </g>
  )
}

export default DivergingBars;