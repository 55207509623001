import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, TextField, Drawer } from '@material-ui/core';
import { HighlightOff as CloseIcon, SentimentVeryDissatisfied as Unhappy, SentimentVerySatisfied as Happy } from '@material-ui/icons';
import KCButton from 'components/common/Button';
import clsx from "clsx";
import { saveFeedback, resetFeedback } from "reduxLib/services";
import { useSelector, useDispatch } from "react-redux";
import Snack from "./Helpers/Snack";
import { useTranslation } from "react-i18next";

const drawerWidth = 440;
const topSpacing = 10;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    background: theme.palette.secondary.base,
    borderLeft: `1px solid ${theme.palette.border} !important`
  },
  drawerContainer: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(topSpacing),
    overflow: 'auto',
    height: '100%'
  },
  closeicon: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(topSpacing),
    cursor: "pointer",
    fontSize: theme.spacing(3),
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.secondary
    }
  },
  icons: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  helpertext: {
    marginTop: theme.spacing(3),
    color: theme.palette.text.primary
  },
  textarea: {
    width: '100%',
    '& .MuiInputBase-input' : {
      color: theme.palette.text.primary
    }
  },
  submit: {
    bottom: 0,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    border: `1px solid ${theme.palette.text.primary}`,
    '&>.MuiButton-label': {
      pointerEvents: 'none'
    },
    '&:hover': {
      border: `1px solid ${theme.palette.text.secondary}`
    }
  },
  icon: {
    fontSize: theme.spacing(6),
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.text.primary,
    '&:hover': {
      background: theme.palette.secondary.secondary,
      padding: theme.spacing(0.5),
      borderRadius: '50%',
    }
  },
  iconactive: {
    background: theme.palette.secondary.secondary,
    padding: theme.spacing(0.5),
    borderRadius: '50%'
  },
  smalltext: {
    fontSize: theme.spacing(1.3),
    color: theme.palette.text.primary
  },
  submiterror: {
    fontSize: theme.spacing(1.3),
    color: theme.palette.legendColors.criticalRed
  },
  heading: {
    fontSize: theme.spacing(2.5),
    color: theme.palette.text.primary
  }
}));


const FeedBack = ({ openFeedback, openAction }) => {

  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { feedback_status, feedback_submit_loading: loading } = useSelector(({ feedbackform }) => feedbackform);

  const [values, setValues] = React.useState({
    happy: null,
    feedback: null,
    submitted: false
  });

  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({
      ...snack,
      open: false
    });
  };

  React.useEffect(() => {
    if (!loading && feedback_status) {
      setSnack({
        ...snack,
        open: true,
        severity: feedback_status === "success" ? "info" : "error",
        message: feedback_status === "success" ? t("feedback_sucessfully_posted") : t("something_went_wrong_please_try_again")
      });

      dispatch(resetFeedback());
      if (feedback_status === 'success') {
        setValues({
          happy: null,
          feedback: '',
          submitted: false
        });
        openAction(!state);
      }
    }
  }, [feedback_status, loading]);

  React.useEffect(() => {
    setState(openFeedback);
  }, [openFeedback]);

  const submitFeedback = (e) => {
    e.preventDefault();
    setValues({ ...values, submitted: true });
    if (values.happy === null) return null;
    if (values.happy !== null && values.feedback) {
      dispatch(saveFeedback({ isHappy: values.happy, feedbackMessage: values.feedback }));
    } else {
      dispatch(saveFeedback({ isHappy: values.happy }));
    }
  }

  return (
    <div className={classes.root} data-testid='feedback'>
      <Drawer
        anchor={"right"}
        open={state}
        className={classes.drawer}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <form onSubmit={(e) => submitFeedback(e)}>
          <Box className={classes.drawerContainer}>
            <Typography variant="body1" color="primary" className={classes.heading}>{t("send_us_feedback")}</Typography>
            <span data-testid='closeicon' id='closeicon' className={classes.closeicon} onClick={() => openAction(!state)}><CloseIcon /></span>
            <Typography variant="subtitle1" className={classes.helpertext}>{t("thank_you_for_taking_the_time_to_give_us_feedback")}</Typography>
            <Typography variant="subtitle1" className={classes.helpertext}>{t("are_you_satisfied_with_your_experience")}</Typography>
            <div className={classes.icons}>
              <Happy data-testid='happyicon' id="happy" fontSize="large" onClick={() => setValues({ ...values, happy: true })} className={clsx(classes.icon, values.happy ? classes.iconactive : {})} />
              <Unhappy data-testid='unhappyicon' id="unhappy" fontSize="large" onClick={() => setValues({ ...values, happy: false })} className={clsx(classes.icon, values.happy === false ? classes.iconactive : {})} />
              <Typography variant="body2" className={clsx(values.happy === null && values.submitted ? classes.submiterror : classes.smalltext)} >* {t("required")} {values.submitted} {values.happy}</Typography>
            </div>
            <TextField
              className={classes.textarea}
              id="feedbackinput"
              label={`${t('tell_us_about_your_experience')}...`}
              multiline
              rows={6}
              variant="outlined"
              value={values.feedback}
              onChange={(e) => setValues({ ...values, feedback: e.target.value })}
            />
            <KCButton data-testid="submitfeedback" disabled={loading} id="button" variant="outlined" color="primary" type="submit" className={classes.submit}>{t("submit_feedback")}</KCButton>
          </Box>
        </form>
      </Drawer>
      <Snack {...snack} handleClose={handleClose} />
    </div>
  );
}


export default FeedBack