import React from "react";
import {
    Grid, Typography, makeStyles, IconButton
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, generatePath, useRouteMatch } from "react-router-dom";
import { deleteSelfAlert } from "reduxLib/services";
import { useDispatch } from "react-redux";
import { ExpandMore, ExpandLess, TableChart } from "@material-ui/icons";
import { useTranslation } from 'react-i18next';
import { useRouteContext } from "context/RouteContext";
import { freqMappings, viewByConstants } from "configs/appConstants";
import { getFilterMappings, createHyperLinkFilterObject2, reprocessSlefAlertFilters, getTabsData } from 'helpers';
import selfalertingFilters from 'reduxLib/constdata/selfalertingFilters';
import { isArray } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.secondary.dark,
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        position: "relative"
    },
    capitalize: {
        textTransform: "capitalize"
    },
    break: {
        marginTop: theme.spacing(2)
    },
    small: {
        fontSize: theme.spacing(1.2)
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1)
    },
    anchor: {
        cursor: "pointer",
        position: "absolute",
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        display: "flex"
    },
    viewmore: {
        maxHeight: "auto",
        overflow: "none"
    },
    viewmore: {
        maxHeight: 150,
        overflow: "hidden"
    }
}));


const SelfAlertCard = ({
    alert,
    mappedTitles
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { mainPath } = useRouteContext();
    const [viewmore, setViewMore] = React.useState(true);

    const {
        alertName,
        severity,
        actions,
        isEditableByCurrentUser,
        createdBy,
        targetEndpoint: {
            restJsonObjectBody,
            restJsonObjectBody: {
                dateComparisons,
                customerOrSalesOffice
            }
        }
    } = alert;


    const customersLabels = React.useMemo(() => {
        const cust = getTabsData(customerOrSalesOffice, viewByConstants["customer"])
        return cust.map(d => d?.custom?.name);
    }, [customerOrSalesOffice]);

    const filtermappingsCard = React.useMemo(() => {
        const modifiedFilters = createHyperLinkFilterObject2({
            pageDetails: {
                filterParams: {
                    args: reprocessSlefAlertFilters(restJsonObjectBody),
                }
            },
            filterBody: selfalertingFilters,
            filterKeys: Object.keys(selfalertingFilters)
        });
        return getFilterMappings(modifiedFilters.fullObject);
    }, [restJsonObjectBody]);

    const match = useRouteMatch();

    const FilterRender = ({ values }) => {
        if (isArray(values)) {
            return <>
                {
                    values.map(d => t(d)).join(", ")
                }
            </>
        }
        return t(values);
    }

    const alertpath = React.useMemo(() => {
        return `${generatePath(mainPath, {
            ...match.params
        })}/alerts/${alert.alertId}`;
    }, [match.url]);

    return (
        <div container className={classes.root} data-testid="selfalertcard">
            <div className={viewmore ? classes.viewmore : classes.viewless}>
                {
                    <div className={classes.closeicon}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <IconButton size="small" component={Link} to={`${alertpath}?evaluate=true`}
                                    color="primary"
                                    data-testid="editcard"
                                >
                                    <TableChart fontSize="small" />
                                </IconButton>
                                <Typography className={classes.small} variant="small" component="p">Evaluate</Typography>
                            </Grid>
                            {
                                isEditableByCurrentUser && <>
                                    <Grid item>
                                        <IconButton size="small" component={Link} to={alertpath}
                                            color="primary"
                                            data-testid="editcard"
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                        <Typography className={classes.small} variant="small" component="p">Edit</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton size="small" onClick={() => {
                                            dispatch(deleteSelfAlert(alert))
                                        }}
                                            data-testid="deletecard"
                                            color="primary">
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        <Typography className={classes.small} component="p">Delete</Typography>
                                    </Grid></>
                            }

                        </Grid>
                    </div>
                }
                <Typography variant="h2">{alertName}</Typography>
                <div>
                    <Grid container>
                        <Grid item xs={2}>Frequency of alert:</Grid>
                        <Grid item xs={10}>
                            {freqMappings[alert?.freqOfAlerts?.freqType]}
                            {
                                alert?.freqOfAlerts?.freqType === "CUSTOM" ?
                                    ` ${alert?.freqOfAlerts?.customTimestamps} ${alert?.freqOfAlerts?.customTimeZone}`
                                    : null
                            }
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>Severity of alert:</Grid>
                        <Grid item xs={10}>{severity}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>Send alerts to:</Grid>
                        <Grid item xs={10}>{actions?.[0]?.mailingList.join(", ")}</Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={2}>Alert created by:</Grid>
                        <Grid item xs={10}>{createdBy}</Grid>
                    </Grid>
                    <br />
                    <Typography component='h2'>Send Alerts when</Typography>
                    <Grid container className={classes.textcontainer}>
                        {
                            customersLabels?.length > 0 && <Grid container>
                                <Grid item xs={2}>
                                    <Typography component="h4">Customers:</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography component="h4">
                                    <FilterRender values={customersLabels} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {
                            Object.keys(filtermappingsCard).map(filter => {
                                return <Grid container>
                                    <Grid item xs={2}>
                                        <Typography component="h4">{t(filter)}:</Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography component="h4">
                                            <FilterRender values={filtermappingsCard[filter]} />
                                        </Typography>
                                    </Grid>
                                </Grid>;
                            })
                        }
                    </Grid>
                </div>
                <div className={classes.break}>
                    {
                        dateComparisons?.map((d, index) => {
                            return (
                                <div style={{ width: '100%' }} className={classes.capitalize} key={index}>
                                    <Typography variant="h4">Criteria {(index + 1)}</Typography>
                                    {mappedTitles[d.field1]} | {mappedTitles[d.operation]} | {mappedTitles[d.field2]} | {`${d.timeUnitsToAdd > 0 ? "+": ""}${d.timeUnitsToAdd} ${d.timeUnitType}s`}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Typography data-testid="viewmore-less" component={"a"} className={classes.anchor} onClick={() => setViewMore(!viewmore)}>
                {viewmore ? <ExpandMore fontSize="small" /> : <ExpandLess fontSize="small" />}
                {viewmore ? "View More" : "View Less"}
            </Typography>
        </div>
    )
}

export default SelfAlertCard;