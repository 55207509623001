import React from 'react';
import moment from "moment-timezone";
import CustomCellRender from "../../components/common/CustomCellRender";
import OsmShipmentHyperlink from "../../components/osm/OsmShipmentHyperlink";

const shipmentStatusBucket = {
  OSM_LOAD_DIAGRAM_SUBMISSION_PENDING: "LD Pending",
  OSM_LOAD_DIAGRAM_SUBMITTED: "LD Submitted",
  OSM_UCC_LABEL_PRINTED: "Print UCL",
  OSM_ADDRESS_LABEL_PRINTED: "Print ADDR",
  OSM_DOWNLOADED_TO_WMS: "DL to WMS",
  OSM_LOADING_STARTED: "Load Start",
  OSM_LOADING_ENDED: "Load End",
  OSM_SHIPMENT_COMPLETION: "Ship Comp",
  OSM_SHIPMENT_START: "Ship Start",
  OSM_SHIPMENT_END: "Ship End"
}

const statusBucket = {
  SUCCESS: "Success",
  NOT_SUBMITTED: "Not Submitted",
  SUBMITTED: "Submitted",
  ERROR: "Error",
  NA: "NA"
}

export const defaultOSMColumns = {
  columnOrder: [
    {
      title: 'shipment_freight_order', field: "shipmentNum", 
      render: rowData => {
        return <OsmShipmentHyperlink id="shipmentNum" shipmentNum={rowData.shipmentNum} />
      }
    },
    {
      title: 'sap_shipment_status', field: "sapShipmetStatus", 
      render: rowData => {
        return <CustomCellRender id="sapShipmetStatus" value={rowData.sapShipmetStatus} />
      }
    },
    {
      title: 'ld_strt_date_time', field: "loadingStartDateTime", 
      render: rowData => {
        return <CustomCellRender id="loadingStartDateTime" value={rowData.loadingStartDateTime} />
      }
    },
    {
      title: 'tariff', field: "tariffServiceCode", 
      render: rowData => {
        return <CustomCellRender id="tariffServiceCode" value={rowData.tariffServiceCode} />
      }
    },
    {
      title: 'scac', field: "scac", 
      render: rowData => {
        return <CustomCellRender id="scac" value={rowData.scac} />
      }
    },
    {
      title: 'equip_type', field: "equipmentType", 
      render: rowData => {
        return <CustomCellRender id="equipmentType" value={rowData.equipmentType} />
      }
    },
    {
      title: 'seal_no', field: "sealNumber", 
      render: rowData => {
        return <CustomCellRender id="sealNumber" value={rowData.sealNumber} />
      }
    },
    {
      title: 'carrier_name', field: "carrierName", 
      render: rowData => {
        return <CustomCellRender id="carrierName" value={rowData.carrierName} />
      }
    },
    {
      title: 'trailer_number', field: "trailerNum", 
      render: rowData => {
        return <CustomCellRender id="trailerNum" value={rowData.trailerNum} />
      }
    },
    {
      title: 'bol', field: "bolNum", 
      render: rowData => {
        return <CustomCellRender id="bolNum" value={rowData.bolNum} />
      }
    },
    {
      title: 'shipment_created_date_time', field: "shipmemtCreateDateTime", 
      render: rowData => {
        return <CustomCellRender id="shipmemtCreateDateTime" value={rowData.shipmemtCreateDateTime} />
      }
    },
    {
      title: 'actual_shipmemt_start_date_time', field: "actualShipmemtStartDateTime", 
      render: rowData => {
        return <CustomCellRender id="actualShipmemtStartDateTime" value={rowData.actualShipmemtStartDateTime} />
      }
    },
    {
      title: 'actual_load_end_date_time', field: "actualLoadEndDateTime", 
      render: rowData => {
        return <CustomCellRender id="actualLoadEndDateTime" value={rowData.actualLoadEndDateTime} />
      }
    },
    {
      title: 'status', field: "osmShipmentExecutionBucket", 
      render: rowData => {
        return <CustomCellRender id="osmShipmentExecutionBucket" value={rowData.osmShipmentExecutionBucket} />
      }
    },
    {
      title: 'ld_status', field: "loadDiagramStatus", 
      render: rowData => {
        return <CustomCellRender id="loadDiagramStatus" value={rowData.loadDiagramStatus} />
      }
    },
    {
      title: 'wms_status', field: "wmsStatus", 
      render: rowData => {
        return <CustomCellRender id="wmsStatus" value={rowData.wmsStatus} />
      }
    },
    {
      title: 'shipping_condition', field: "shippingCondFromShipment", 
      render: rowData => {
        return <CustomCellRender id="shippingCondFromShipment" value={rowData.shippingCondFromShipment} />
      }
    },
    {
      title: 'haz_cd', field: "hazardOrderInd", 
      render: rowData => {
        return <CustomCellRender id="hazardOrderInd" value={rowData.hazardOrderInd} />
      }
    },
    {
      title: 'live', field: "liveLoadInd", 
      render: rowData => {
        return <CustomCellRender id="liveLoadInd" value={rowData.liveLoadInd} />
      }
    },
    {
      title: 'confirmed_estm_load_hrs', field: "eccEstimatedLoadTime", 
      render: rowData => {
        return <CustomCellRender id="eccEstimatedLoadTime" value={rowData.eccEstimatedLoadTime} />
      }
    },
    {
      title: 'edi_status', field: "ediStatus", 
      render: rowData => {
        return <CustomCellRender id="ediStatus" value={rowData.ediStatus} />
      }
    },
    {
      title: 'tender_status', field: "tenderStatus",
      render: rowData => {
        return <CustomCellRender id="tenderStatus" value={rowData.tenderStatus} />
      }
    },
    {
      title: 'manifest_status', field: "manifestStatus", 
      render: rowData => {
        return <CustomCellRender id="manifestStatus" value={rowData.manifestStatus} />
      }
    },
    {
      title: 'ucl_required', field: "uccLabelRequired", 
      render: rowData => {
        return <CustomCellRender id="uccLabelRequired" value={rowData.uccLabelRequired} />
      }
    },
    {
      title: 'ucl_printed', field: "uccLabelPrinted", 
      render: rowData => {
        return <CustomCellRender id="uccLabelPrinted" value={rowData.uccLabelPrinted} />
      }
    },
    {
      title: 'addr_required', field: "addrLabelRequired", 
      render: rowData => {
        return <CustomCellRender id="addrLabelRequired" value={rowData.addrLabelRequired} />
      }
    },
    {
      title: 'addr_printed', field: "addrLabelPrinted", 
      render: rowData => {
        return <CustomCellRender id="addrLabelPrinted" value={rowData.addrLabelPrinted} />
      }
    },
    {
      title: 'shipmemt_created_by', field: "shipmemtCreatedBy", 
      render: rowData => {
        return <CustomCellRender id="shipmemtCreatedBy" value={rowData.shipmemtCreatedBy} />
      }
    },
    {
      title: 'shipmemt_last_changed_by', field: "shipmemtLastChangedBy", 
      render: rowData => {
        return <CustomCellRender id="shipmemtLastChangedBy" value={rowData.shipmemtLastChangedBy} />
      }
    },
    {
      title: 'wh_ready_date_time', field: "warehouseReadyDateTime", 
      render: rowData => {
        return <CustomCellRender id="warehouseReadyDateTime" value={rowData.warehouseReadyDateTime} />
      }
    },
    {
      title: 'case_label_print_status', field: "caseLabelPrintStatus",
      render: rowData => {
        return <CustomCellRender id="caseLabelPrintStatus" value={rowData.caseLabelPrintStatus} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
      uccLabelRequired: (d?.uccLabelRequired !== undefined) ? (d.uccLabelRequired == "Y" ? "Yes" : "No") : "-",
      uccLabelPrinted: (d?.uccLabelPrinted !== undefined) ? (d.uccLabelPrinted === "SUCCESS" ? "Y" : d.uccLabelPrinted === "NOT_SUBMITTED" ? "N" : d.uccLabelPrinted === "ERROR" ? "E" : "NA") : "-",
      addrLabelRequired: (d?.addrLabelRequired !== undefined) ? (d.addrLabelRequired == "Y" ? "Yes" : "No") : "-",
      addrLabelPrinted: (d?.addrLabelPrinted !== undefined) ? (d.addrLabelPrinted === "SUCCESS" ? "Y" : d.addrLabelPrinted === "NOT_SUBMITTED" ? "N" : d.addrLabelPrinted === "ERROR" ? "E" : "NA") : "-",
      caseLabelPrintStatus: (d?.caseLabelPrintStatus !== undefined) ? (d.caseLabelPrintStatus == "Y" ? "Yes" : "No") : "-",
      loadingStartDateTime : (d?.loadingStartDateTime && d?.originTimeZone) ? moment(d.loadingStartDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      shipmemtCreateDateTime : (d?.shipmemtCreateDateTime && d?.originTimeZone) ? moment(d.shipmemtCreateDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      actualShipmemtStartDateTime : (d?.actualShipmemtStartDateTime && d?.originTimeZone) ? moment(d.actualShipmemtStartDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      actualLoadEndDateTime : (d?.actualLoadEndDateTime && d?.originTimeZone) ? moment(d.actualLoadEndDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      warehouseReadyDateTime : (d?.warehouseReadyDateTime && d?.originTimeZone) ? moment(d.warehouseReadyDateTime)?.tz(d.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      osmShipmentExecutionBucket: shipmentStatusBucket[d?.osmShipmentExecutionBucket] || '-',
      hazardOrderInd: (d?.hazardOrderInd !== undefined) ? (d.hazardOrderInd == "Y" ? "Yes" : "No") : "-",
      loadDiagramStatus: statusBucket[d?.loadDiagramStatus] || '-',
      wmsStatus: statusBucket[d?.wmsStatus] || '-'
    }
  }
}