import React from 'react';
import {Divider, makeStyles, Card as MuiCard, CardContent as MuiCardContent, CardActions as MuiCardActions, CardHeader as MuiCardHeader } from '@material-ui/core';
import clsx from "clsx";
import {cardStyles} from '../../theme';

const useStyles = makeStyles(cardStyles)

export default function Card(props) {
  const classes = useStyles();

  const { cardtitle, divider, children, cardactions, padding, innercard, elevation=2 } = props

  return (
    <MuiCard data-testid='cardbasic' className={clsx(classes.root, !props.actions ? classes.addPadding : {}, innercard && classes.innercard )} elevation={elevation} {...props}>
      <MuiCardHeader className={classes.title} title={cardtitle} />
      {
        divider && 
        <Divider data-testid='divider' className={classes.divider} />
      }
      <MuiCardContent style={{padding: padding? 0 : null}} className={classes.content}>
        {
          children
        }
      </MuiCardContent>
      {cardactions && <MuiCardActions className={classes.actions}>
        {
          cardactions
        }
      </MuiCardActions>}
    </MuiCard >
  );
}
