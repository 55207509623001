import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useLocation, useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { excludedUrlParam } from 'configs/appConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.card.default,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(3),
        borderRadius: theme.spacing(1),
        paddingLeft: theme.spacing(2)
    },
    link: {
        cursor: "pointer"
    },
    label: {
        textTransform: "capitalize"
    }
}));

const EMEABreadcrumb = ( props ) => {
    const {
        pageName,
        detailsPageName,
        routerDetailsPageName,
        hasDetailsPage
    } = props;
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const gotoHome = (e) => {
        e.preventDefault();
        const queryParams = qs.parse(location.search)
        const regx = new RegExp("[^\/]+$", "g");

        let pathName = location.pathname;
        let excludedUrlValue = location?.pathname.split('/')[5];
        pathName = excludedUrlParam.includes(excludedUrlValue) ? location?.pathname.replace(`/${excludedUrlValue}`, "") : pathName;

        history.replace({
            ...location,
            pathname: pathName.replace(regx, "home"),
            search: qs.stringify({
                ...queryParams,
                viewid: undefined,
                documentDate: undefined,
                emeaFilters: undefined,
                loadView: true
            })
        })
    }

    const handleLinkClick = () => {
        let updatedPath = location.pathname;
        updatedPath = updatedPath.replace(`/${routerDetailsPageName}`, "");
        history.push({
            ...location,
            pathname: updatedPath
        })
    }

    return (
        <Breadcrumbs
            className={classes.root}
            data-testid="emea-breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
        >
            <Link data-testid="emea-home" color="inherit" className={classes.link} onClick={gotoHome}>
                {t('home')}
            </Link>
            {
                hasDetailsPage
                    ? <Link color="inherit" className={classes.link} onClick={handleLinkClick} data-testid="emea-pageName">{t(pageName)}</Link>
                    : <Typography className={classes.label} color="textPrimary"> {t(pageName)} </Typography>
            }

            {
                detailsPageName && <Typography className={classes.label}> {t(detailsPageName)} </Typography>
            }
        
        </Breadcrumbs>
    )
}

export default EMEABreadcrumb;