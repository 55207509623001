export const emeaCustomerConstants = {
  EMEA_CUST_SEARCH_LOADING: 'EMEA_CUST_SEARCH_LOADING',
  EMEA_CUST_SEARCH_SUCCESS: 'EMEA_CUST_SEARCH_SUCCESS',
  EMEA_CUST_SEARCH_FAILED: 'EMEA_CUST_SEARCH_FAILED',
  EMEA_CUST_SEARCH_RESET: 'EMEA_CUST_SEARCH_RESET',

  
  EMEA_CUST_FAV_LOADING: 'EMEA_CUST_FAV_LOADING',
  EMEA_CUST_FAV_SUCCESS: 'EMEA_CUST_FAV_SUCCESS',
  EMEA_CUST_FAV_FAILED: 'EMEA_CUST_FAV_FAILED',

}