import React from 'react';
import {
  makeStyles,
  Menu,
  MenuItem,
  withStyles,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  Button,
  useMediaQuery,
  IconButton
} from '@material-ui/core';
import { connect } from 'react-redux';
import { get } from 'lodash';
import NetworkSvg from '../../assets/images/network.svg';
import DCSvg from '../../assets/images/dc.svg';
import MillSvg from '../../assets/images/mill.svg';
import GlobeSvg from '../../assets/images/globe.svg';
import { tabsBarStyles } from 'theme';
import { KeyboardArrowDown } from '@material-ui/icons';
import { viewByConstants } from 'configs/appConstants';
import { defaultShowTabsBy, getTabsData } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Title from 'components/common/Elements/Title';
import { useDeepCompareEffect } from 'react-use';

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
};

const a12yProps = (index, siteNum) => {
  return {
    id: `scrollable-auto-tab2-${index}`,
    'aria-controls': `scrollable-auto-tabpanel2-${index}`,
    'data-testid': 'tab',
    'data-value': index,
    'data-id': siteNum
  };
};

const useStyles = makeStyles(tabsBarStyles);

export const IconName = ({ type, name, showLabel = true, data, icon }) => {
  const classes = useStyles();
  let Icon = NetworkSvg;

  if (type === 'DC') {
    Icon = DCSvg;
  }

  if (type === 'MILL') {
    Icon = MillSvg;
  }

  if (type === 'network') {
    Icon = GlobeSvg;
  }
  return (
    <span className={classes.tabName}>
      <img src={icon ? icon : Icon} alt="ICON" className={classes.icon} />
      {showLabel && (
        <Typography
          variant="p"
          className={classes.name}
          data-testid="tabname"
          id={name}>
          {name}
        </Typography>
      )}
    </span>
  );
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: theme.spacing(30),
    borderBottom: `2px solid ${theme.palette.white}`,
    minHeight: 'auto',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '&:focus': {
      backgroundColor: theme.palette.secondary.base,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const ButtonInTabs = ({ className, onClick, children }) => {
  return <Button className={className} onClick={onClick} children={children} />;
};

const TabsBar = ({
  selections,
  activeTab,
  showTabsBy,
  toggleMainTab,
  ...rest
}) => {
  const classes = useStyles();

  const { mainTab } = rest;
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [showTabs, setShowTabs] = React.useState(true);
  const { type } = viewByConstants[showTabsBy];

  const [items, setItems] = React.useState([]);
  const [tabtitles, setTabtitles] = React.useState({});

  useDeepCompareEffect(() => {
    const items = getTabsData(
      selections[showTabsBy],
      viewByConstants[showTabsBy]
    );
    setItems(items);
    const idmaps = {};
    items.map(d => idmaps[d.custom.id] = d.custom.name);
    setTabtitles(idmaps);
  }, [selections, showTabsBy])

  // const allowedPlaces = ["network", "mylocation"];
  const isMyLocation = items?.length > 1;
  const singleLocation = items?.length === 1;

  const showHideTabs = () => {
    setShowTabs(!showTabs);
    if (match?.params?.type !== "network") {
      toggleMainTab({ tab1: 1, tab2: false });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = withStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette.secondary.base,
      borderRadius: 0,
      '& ul': {
        padding: 0
      },
      '& li': {
        display: 'block'
      }
    }
  }))((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ));

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <Title page={tabtitles[mainTab.tab2]} tab1={mainTab.tab1} tab2={mainTab.tab2} mainpage={match.params.page} />
      <AppBar
        elevation={3}
        className={classes.appbar}
        position="static"
        color="inherit">
        <Grid container>
          <Grid item>
            <Tabs
              value={mainTab.tab1}
              onChange={(event, newValue) =>
                toggleMainTab({ tab1: newValue, tab2: false })
              }
              indicatorColor="secondary"
              textColor="primary">
              <Tab
                color="primary"
                selectionFollowsFocus
                name="network"
                width="150px"
                icon={
                  <IconName
                    name={t('network_view')}
                    showLabel={!isMobile}
                    type="network"
                  />
                }
                {...a11yProps('network')}
              />
              {isMyLocation ? (
                <Tab
                  selectionFollowsFocus
                  name="mylocation"
                  width="150px"
                  wrapped
                  label={
                    <IconName
                      name={`${t('my_selections')} (${items?.length})`}
                      showLabel={!isMobile}
                      type="default"
                    />
                  }
                  {...a11yProps('mylocation')}
                />
              ) : null}
              {isMyLocation ? (
                !isMobile ? (
                  <Box display={{ xs: 'block', sm: 'inline-block' }}>
                    <ButtonInTabs
                      className={classes.addButton}
                      onClick={showHideTabs}>
                      {!showTabs ? (
                        <Typography
                          className={classes.viewmore}
                          variant="caption"
                          color="primary"
                          data-testid="viewMoreTabs">
                          {t('view_more')}
                        </Typography>
                      ) : (
                        <Typography
                          className={classes.viewmore}
                          variant="caption"
                          color="primary"
                          data-testid="viewLessTabs">
                          {t('view_less')}
                        </Typography>
                      )}
                    </ButtonInTabs>
                  </Box>
                ) : (
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <KeyboardArrowDown />
                  </IconButton>
                )
              ) : null}
            </Tabs>
          </Grid>
          <Grid item xs={6} md={6} lg={8}>
            {!isMobile || items?.length < 2 ? (
              <Tabs
                value={mainTab.tab2}
                className={{
                  [classes.showTabs]: showTabs && isMyLocation,
                  [classes.showSingleLocation]: singleLocation,
                  [classes.hideTabs]: !(
                    (showTabs && isMyLocation) ||
                    singleLocation
                  )
                }}
                onChange={(event, newValue) =>
                  toggleMainTab({ tab1: false, tab2: newValue })
                }
                indicatorColor="secondary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {items?.map((d, i) => {
                  return (
                    <Tab
                      value={d?.custom?.id}
                      selectionFollowsFocus
                      className={classes.customtab}
                      key={d?.custom?.id || i}
                      icon={
                        <IconName
                          name={d?.custom?.name}
                          type={d[type]}
                          data={d?.custom?.id}
                        />
                      }
                      {...a12yProps(d?.custom?.name, d?.custom?.id)}
                    />
                  );
                })}
              </Tabs>
            ) : (
              <StyledMenu
                anchorEl={anchorEl}
                id="long-menu"
                keepMounted
                open={open}
                onClose={handleClose}>
                {items?.map((d, i) => {
                  return (
                    <StyledMenuItem
                      key={d?.custom?.id}
                      onClick={() => {
                        toggleMainTab({ tab1: false, tab2: d?.custom?.id });
                        handleClose();
                      }}>
                      {d?.custom?.name}
                    </StyledMenuItem>
                  );
                })}
              </StyledMenu>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selections: {
      locations: state.items.items,
      customer: [
        ...get(state, 'items.cuso.CUST', []),
        ...get(state, 'items.cuso.SALES_OFFICE', [])
      ]
    },
    showTabsBy: defaultShowTabsBy(state)
  };
};

export default connect(mapStateToProps, null)(TabsBar);
