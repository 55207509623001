import React, { createRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { laoOrderDetailsColumns } from "reduxLib/constdata/LAO/laoOrderDetailsColumns";
import { laoImportOrderDetailsColumns } from "reduxLib/constdata/LAO/laoImportOrderDetailsColumns";
import { useDispatch } from "react-redux";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LaoTable from "components/LAO/LaoTable";
import { endpoints } from 'helpers';
import { Grid } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { laoSearchType } from "configs/appConstants";
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { getAllLAOViews, resetLAOViews } from 'reduxLib/services/LaoServices/laoViewsServices';
import { laoViewTableNames } from 'configs/appConstants';

const useStyles = makeStyles(theme => ({
    tableContainer: {
      background: theme.palette.primary.base,
      borderRadius: theme.spacing(1.5),
      padding: `${theme.spacing(3)}px ${theme.spacing(8)}px ${theme.spacing(3)}px ${theme.spacing(8)}px`,
      marginTop: theme.spacing(7),
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
        marginTop: theme.spacing(6),
      }
    },
    active: {
      opacity: "100%",
      cursor: 'pointer'      
    },
    faded: {
      opacity: "30%",
      cursor: 'pointer'
    },
    volume: {
      display: 'flex',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-evenly'
      }
    },
  }));

const LaoOrderDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tableRef = createRef();
  const dispatch = useDispatch();
  const [controller] = useState(() => new AbortController());
  const [{tableType}, setParams] = useSetQuery();
  const setMultiQuery = useMultiSetQuery();
  const [activeOrder, setActiveOrder] = useState("export");
  const isMobileLao = useMediaQuery(useTheme().breakpoints.down('xs'));

  const setTableType = (values) => {
    setActiveOrder(values);
    setMultiQuery({
      tableType: values === 'export' ? "exportOrderDetails" : "importOrderDetails",
      viewid: undefined,
      loadView: true
    });
    dispatch(resetLAOViews({
      tableName: laoViewTableNames[values === 'export' ? "exportOrderDetails" : "importOrderDetails"]
    }, controller));
    dispatch(getAllLAOViews({
      tableName: laoViewTableNames[values === 'export' ? "exportOrderDetails" : "importOrderDetails"]
    }, controller));
  }

  return (
    <Grid container className={classes.tableContainer} data-testid="lao-tablecontainer">
        <Grid container className={classes.volume}>
            <Grid item md={4} lg={2}>
                <Typography data-testid="exportOrder" variant="h6" className={!tableType || tableType === "exportOrderDetails" ? classes.active : classes.faded} onClick={() => setTableType('export')}>{isMobileLao ? t('exportOrderMobile') : t('exportOrder')}</Typography>
            </Grid>
            <Grid item md={4} lg={2}>
                <Typography data-testid="importOrder" variant="h6" className={tableType && tableType === "importOrderDetails" ? classes.active : classes.faded} onClick={() => setTableType('import')}>{isMobileLao ? t('importOrderMobile'): t('importOrder')}</Typography>
            </Grid>
        </Grid>
      <LaoTable
        fetchEndPoint={tableType === "importOrderDetails" ? endpoints.lao.laoTableImport : endpoints.lao.laoTableExport}
        columns={tableType === "importOrderDetails" ? laoImportOrderDetailsColumns : laoOrderDetailsColumns}
        tableName={tableType === "importOrderDetails" ? "importOrderDetails" : "exportOrderDetails"}
        subType={tableType === "importOrderDetails" ? laoSearchType.importOrderDetails : laoSearchType.exportOrderDetails}
        filterType={tableType === "importOrderDetails" ? "laoImportOrderDetailsFilters" : "laoOrderDetailsFilters" }
        tableRef={tableRef}
        filterExclusionArray={['searchStringList']}
        searchText={tableType === "importOrderDetails" ? t('lao_order_details_search_helper_text_import') : t('lao_order_details_search_helper_text')}
      />
    </Grid>
  )
}

export default LaoOrderDetails;