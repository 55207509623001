import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { get } from "lodash";
import { saveFilters, saveSelfAlertFilters } from "../../reduxLib/services";
import { filterStyles } from "theme";
// import FilterComponentContainer from "./Filters/FilterComponentContainer";
import KCCheckBox from "components/common/Elements/KCCheckbox";
import { useTranslation } from "react-i18next"
const useStyles = makeStyles(filterStyles);

export const CheckBoxElement = (props) => {

    const { filters, filterKey, type, subtype, resetOthers, testid, customSaveFilters } = props;

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    const { t } = useTranslation();
    const classes = useStyles();
    const filter = get(filters, `${filterKey}.data`, [])
    
    const setFilter = ({ item, target }) => {
        const checked = target.target.checked;
        const updatedobject = filter.map(d => {
            if (d.value === item.value) {
                return { ...d, checked }
            } else {
                if (resetOthers) {
                    return { ...d, checked: false }
                } else {
                    return d;
                }
            }
        });
        const savePayload = {
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: updatedobject
                }
            }, type, subtype
        };

        if (typeof customSaveFilters === "function") {
            customSaveFilters(savePayload);
            return;
        }
        saveFilters(savePayload);
    }
    return (
        // <FilterComponentContainer testId='checkBoxElement' {...props}>
            <FormControl component="fieldset" data-testid="checkBoxElement" >
                <FormGroup data-testid={testid} className={classes.checkbox}>
                    {
                        filter?.map((d, i) => {
                            return (<FormControlLabel
                                data-testid={d.name}
                                key={i}
                                control={<KCCheckBox name={d.value} color="primary" onChange={(e) => setFilter({ item: d, target: e })} checked={d.checked} />}
                                label={<Typography className={clsx(classes.button, classes.contentText)} data-testid={d.name}>{t(d.name) || "-"}</Typography>}
                            />)
                        })
                    }
                </FormGroup>
            </FormControl>
        // </FilterComponentContainer>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;
    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        filters
    }
};

const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(CheckBoxElement);
