import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useMediaQuery, useTheme, makeStyles, Typography } from '@material-ui/core';
import { withOktaAuth } from '@okta/okta-react';
import FilterBar from './FilterBar';
import TabsBar from './TabsBar';
import { connect } from 'react-redux';
import {
  addUser,
  removeUser,
  setBlockOnTabChange,
  setViewBy
} from '../../reduxLib/services';
import DownloadManager from 'components/common/Table/DownloadManager';
import { useDeepCompareEffect } from 'react-use';
import { get, isEmpty } from 'lodash';
import Topbar from './Topbar';
import TabPanel from 'components/header/TabPanel';
import ChipFilters from './ChipFilters';
import { viewByConstants } from 'configs/appConstants';
import { getTabsData, defaultShowTabsBy, generateHeader, getTabletype, getHealth } from 'helpers';
import useScrollInfo from './ScrollHelper';
import AppContext from '../../context/AppContext';
import RouteContext from '../../context/RouteContext';
import { mainLayoutStyles } from 'theme';
import useUniqTab from 'components/common/Helpers/UniqTab';
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import qs from 'query-string';
import { allowedPlaces, chartDataIdentifierForAPI } from 'helpers/index';
import Timer from 'components/common/Helpers/Timer';
import { useMemo } from 'react';
const useStyles = makeStyles(mainLayoutStyles);

// export const chartDataIdentifierForAPI = {
//   network: 'NETWORK',
//   order: 'ORDER',
//   transPlan: 'TRANS_PLAN',
//   transExec: 'TRANS_EXEC',
//   dist: 'DIST',
//   shipmentdetails: 'SHIPMENTDETAILS',
//   analytics: 'ANALYTICS',
//   stockConstraintReport: 'STOCKCONSTRAINTREPORT'
// };
// export const allowedPlaces = ['network', 'mylocation'];

export const MainLayout = ({ component: Component, location, hide, ...props }) => {
  const classes = useStyles();
  const locationURL = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  const { authState, businessUnits } = props;

  React.useEffect(() => {
    if (!authState?.isAuthenticated) {
      history.push(`/redirect?path=${encodeURIComponent(locationURL?.pathname)}&search=${locationURL.search}`)
    }
  }, [authState])

  const isDesktop = useMediaQuery(useTheme().breakpoints.up('lg'), {
    defaultMatches: true
  });

  useUniqTab();

  const {
    selections,
    stopfavload,
    showTabsBy
  } = props;

  const [mainTab, setMainTab] = useState({ tab1: false, tab2: false });
  const [pageDetails, setDetailsMain] = useState({
    [chartDataIdentifierForAPI.network]: true,
    type: allowedPlaces[0]
  });
  const [items, setItems] = React.useState([]);
  // const [singleLocation, setSingle] = React.useState(false);
  // const [isMyLocation, setIsMyLocation] = React.useState(false);

  // const [activeTopNavTabObject, setActiveTopNavTab] = useState({
  //   activeTopNavTab: allowedPlaces[0],
  //   secondaryActiveTab: null
  // });

  // const { id } = viewByConstants[showTabsBy];
  // const visibility =
  //   !pageDetails[chartDataIdentifierForAPI.shipmentdetails] &&
  //   !pageDetails[chartDataIdentifierForAPI.analytics] &&
  //   !pageDetails[chartDataIdentifierForAPI.stockConstraintReport];


  const toggleMainTab = ({ tab1, tab2 }) => {
    const tabtype = ([0, 1].includes(tab1) ? tab1 === 0 ? "network" : "mylocation" : tab2) || "network";

    const path = generatePath(match.path, {
      ...match.params,
      type: tabtype,
      showtabsby: showTabsBy
    });

    history.push({
      ...locationURL,
      pathname: path,
    })
  }

  const manageTabs = (tabtype, isMyLocation, singleLocation) => {

    const selectedTabsList = getTabsData(
      selections[showTabsBy],
      viewByConstants[showTabsBy]
    );
    const ids = selectedTabsList.map(d => d?.custom?.id);
    const tab1 = allowedPlaces.includes(tabtype) ? tabtype === "network" ? 0 : 1 : false;
    const tab2 = !allowedPlaces.includes(tabtype) ? tabtype : false;

    if (tab2 && ids.includes(tab2)) {
      setMainTab({ tab1, tab2 });
      toggleMainTab({ tab1, tab2 });
    } else {
      let tabOne = isMyLocation ? 1 : singleLocation ? false : 1;
      if (tab1 === 0) {
        toggleMainTab({ tab1, tab2: false });
        setMainTab({ tab1, tab2: false });
      } else {
        if (tabOne === 1 && !isMyLocation) {
          tabOne = 0;
        }
        toggleMainTab({ tab1: tabOne, tab2: !tab1 ? singleLocation ? ids[0] : false : false });
        setMainTab({ tab1: tabOne, tab2: !tab1 ? singleLocation ? ids[0] : false : false });
      }
    }
  };

  const setDetails = (tabtype, subtype, value, filterParams = {}, scrollTo = false, topage) => {
    if (topage) {
      const queryParams = qs.parse(location.search);
      const health = getHealth(filterParams, topage)
      const newQueries = {
        ...queryParams,
        isHyperlink: true,
        filters: JSON.stringify({
          ...filterParams?.args,
          ...health
        }),
      };

      const path = generatePath(match.path, {
        ...match.params,
        page: topage,
        tabletype: getTabletype(topage, filterParams)
      });

      history.push({
        pathname: path,
        search: qs.stringify(newQueries)
      })
    }
  }

  const [, setRef, ref] = useScrollInfo();

  const executeScroll = () => {
    return ref?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };
  React.useEffect(() => {
    const parsed = qs.parse(locationURL.search);
    const isHyper = parsed?.isHyperlink === "true";

    if (isHyper || !isEmpty(parsed?.hyperlinks)) {
      executeScroll();
    }
  }, [locationURL.search])

  // useDeepCompareEffect(() => {
  //   if (!stopfavload) {
  //     if (items.length !== tablist.length) {
  //       setMainTab({ tab1: isMyLocation ? 1 : singleLocation ? false : false, tab2: singleLocation ? items[0]?.["custom"]?.["id"] : false });
  //       toggleMainTab({ tab1: isMyLocation ? 1 : singleLocation ? false : false, tab2: singleLocation ? items[0]?.["custom"]?.["id"] : false });
  //     } else {
  //       manageTabs(match?.params?.type);
  //     }
  //   }
  //   setTabList(items);
  // }, [stopfavload, match?.params?.type, match?.params?.showtabsby, items]);

  const { type } = match.params;
  const [globalFilterSelection, setGlobal] = React.useState({});
  const [processed, setProcessed] = React.useState(false);
  const [refresh, setRefresh] = React.useState({ refresh: false, time: new Date() });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRefresh({
        refresh: !refresh.refresh,
        time: new Date(),
      });
    }, 300000);
    return () => clearInterval(interval);
  }, [refresh.refresh]);

  useDeepCompareEffect(() => {

    const tabsdata = getTabsData(
      selections[showTabsBy],
      viewByConstants[showTabsBy]
    );

    const isMyLocation1 = tabsdata?.length > 1;
    const singleLocation1 = tabsdata?.length === 1;

    if (!stopfavload) {
      setProcessed(true);
      if (tabsdata.length !== items?.length && processed) {
        const tabs = {
          tab1: isMyLocation1 ? 1 : singleLocation1 ? false : false,
          tab2: singleLocation1 ? tabsdata[0]?.["custom"]?.["id"] : false
        }
        if(tabs.tab1 === false && tabs.tab2 === false) {
          tabs.tab1 = 0;
        }
        setMainTab(tabs);
        toggleMainTab(tabs);
      } else {
        manageTabs(match?.params?.type, isMyLocation1, singleLocation1);
      }
    }

    setItems(tabsdata);

    // setSingle(singleLocation1);
    // setIsMyLocation(isMyLocation1);
    const tabids = tabsdata.map(d => d?.custom?.id)
    const ids = ["network", "mylocation", ...(tabids)];

    if (ids.includes(type)) {
      const globalheaders = generateHeader({
        selections,
        type,
        showTabsBy,
        item: tabsdata.filter(d => d?.["custom"]?.["id"] === type)?.[0]
      });
      setGlobal({
        ...globalheaders,
        businessUnits
      });
    }

  }, [stopfavload, type, selections, showTabsBy, match?.params?.showtabsby, businessUnits])

  useMemo(() => {
    setRefresh({
      ...refresh,
      time: new Date()
    })
  }, [match?.params])

  const memoizedValue = React.useMemo(() => ({
    executeScroll,
    setRef,
    ref,
    globalFilterSelection,
    refresh: refresh.refresh,
    setRefresh
  }), [refresh.refresh, executeScroll, setRef, ref, globalFilterSelection]);

  if (!authState?.isAuthenticated) return null;

  return (
    <AppContext.Provider value={memoizedValue}>
      <RouteContext.Provider value={{
        mainPath: "/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport|osm)/:tabletype(inbound|outbound)?",
        analyticsPath: "/dashboard/:type/:showtabsby/analytics/",
        shipmentDetailsPath: "/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/shipment/",
        selfalertid: "/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/alerts/:alertid",
        selfalertpath: "/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/alerts",
        osmPath: "/dashboard/:type/:showtabsby/osm/:osmviewid"
      }}>
        <Topbar
          {...props}
          pageDetails={pageDetails}
          showHamburgerMenu={true}
          stoploaders={hide}
        />
        <div
          data-testid="mainlayout"
          className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: isDesktop
          })}>

          {!hide && <FilterBar className={classes.filter} />}
          {(!stopfavload || hide) ? (
            <>
              <DownloadManager />
              {!hide && (
                <TabsBar
                  location={location}
                  mainTab={mainTab}
                  toggleMainTab={toggleMainTab}
                />
              )}
              <TabPanel classes={classes}>
                {!hide &&
                  <>
                    <ChipFilters />
                    <div className={classes.timerBox}>
                      <Typography data-testid='timer' className={classes.timer}>
                        <Timer date={refresh.time} />
                      </Typography>
                    </div>
                  </>
                }
                <Component
                  {...location}
                  setDetails={setDetails}
                />
              </TabPanel>
            </>
          ) : null}
        </div>
      </RouteContext.Provider>
    </AppContext.Provider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => {
  return {
    stopfavload: state.items.stopfavload,
    selections: {
      locations: state.items.items,
      customer: [
        ...get(state, 'items.cuso.CUST', []),
        ...get(state, 'items.cuso.SALES_OFFICE', [])
      ]
    },
    showTabsBy: defaultShowTabsBy(state),
    businessUnits: get(state, "favorites.tempBusinessUnit", []),
    blockOnTabChange: get(state, 'items.blockOnTabChange', false)
  };
};

const mapDispatchToProps = {
  addUser,
  removeUser,
  setBlockOnTabChange,
  setViewBy
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOktaAuth(MainLayout));
