export default {
    OSM_LOADING: 'OSM_LOADING',
    FETCH_OSM_VIEWS_SUCCESS: 'FETCH_OSM_VIEWS_SUCCESS',
    FETCH_OSM_VIEWS_FAILED: 'FETCH_OSM_VIEWS_FAILED',
    GET_OSM_VIEW_SUCCESS: 'GET_OSM_VIEW_SUCCESS',
    GET_OSM_VIEW_FAILED: 'GET_OSM_VIEW_FAILED',
    DELETE_OSM_VIEW_SUCCESS: 'DELETE_OSM_VIEW_SUCCESS',
    DELETE_OSM_VIEW_FAILED: 'DELETE_OSM_VIEW_FAILED',
    UPDATE_OSM_VIEW_SUCCESS: 'UPDATE_OSM_VIEW_SUCCESS',
    UPDATE_OSM_VIEW_FAILED: 'UPDATE_OSM_VIEW_FAILED',
    ACTIVATE_OSM_TAB: 'ACTIVATE_OSM_TAB',
    HIDE_OSM_VIEW: 'HIDE_OSM_VIEW',
    SHOW_ALL_HIDE_ALL_OSM_VIEWS: 'SHOW_ALL_HIDE_ALL_OSM_VIEWS',
    FAV_OSM_VIEW_SUCCESS: 'FAV_OSM_VIEW_SUCCESS',
    FAV_OSM_VIEW_FAILED: 'FAV_OSM_VIEW_FAILED',
    UPDATE_VIEW_ORDER_SUCCESS: 'UPDATE_VIEW_ORDER_SUCCESS',
    UPDATE_VIEW_ORDER_FAILED: 'UPDATE_VIEW_ORDER_FAILED',
    RESET_OSM_STATUS: "RESET_OSM_STATUS"
  }