import React from "react";
import { Paper, useTheme, makeStyles, TablePagination, LinearProgress } from "@material-ui/core";
import { getTableData } from "reduxLib/services";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { endpoints } from 'helpers';
import MaterialTable from 'material-table'
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import { defaultNetworkColumns } from "reduxLib/constdata/networkColumns";
import { conditionalFilterReplacement } from "components/pages/Home";
import { defaultNetworkFilterArgs } from "reduxLib/constdata/filters"
import { tableoptions } from 'helpers/tableStyleOverride';
import DownloadSelfAlert from "components/common/Table/DownloadSelfAlert";

const useStylesPagination = makeStyles((theme) => ({
    spacer: {
        flex: "1 1 auto"
    }
}));

const SelfAlertTable = ({ body }) => {
    const { t } = useTranslation();

    const { tabledata } = useSelector(({
        tabledata
    }) =>
        ({  tabledata })
    );
    const [tablebody, setTableBody] = React.useState({});

    const [columns] = React.useState(() => {
        return defaultNetworkColumns.columnOrder?.map(item => {
            return {
                ...item,
                title: t(item.title),
            }
        });
    })

    const [pages, setPages] = React.useState({
        page: 0,
        pageSize: 10
    })

    React.useEffect(() => {
        getSelfAlertTableData({
            ...pages,
            body: body?.targetEndpoint?.restJsonObjectBody
        });
    }, [body?.targetEndpoint?.restJsonObjectBody]);

    const [data, setData] = React.useState({
        loading: true,
        orders: [],
        ...pages,
        totalRecords: 0
    });

    const paginationClasses = useStylesPagination();

    const theme = useTheme();

    const dispatch = useDispatch();

    const getSelfAlertTableData = ({
        page,
        pageSize,
        body,
        ...restOfParams
    }) => {

        const reqBody = {
            body: {
                fetchEndPoint: endpoints.table.network,
                tablebody: {
                    ...defaultNetworkFilterArgs,
                    orderTypes: undefined,
                    ...body
                },
                pageSize,
                page,
                type: "network",
                tableName: "network",
                ...restOfParams
            },
            language: localStorage.getItem('i18nextLng'),
            refresh: true,
            reqtime: new Date()
        }
        dispatch(getTableData(reqBody));
        setTableBody({
            ...defaultNetworkFilterArgs,
            orderTypes: undefined,
            ...body
        });
    }

    React.useEffect(() => {
        const tempData = tabledata?.network?.network?.data?.orders?.map(order => {
            let d = {};
            Object.keys(order).map(item => {
                if (order[item] !== 0 && !order[item]) {
                    d[item] = "-";
                } else {
                    d[item] = order[item];
                }
            })
            return {
                ...d,
                ...defaultNetworkColumns.columnConfiguration(d)
            }
        }) || [];

        setData({
            ...data,
            ...(tabledata?.network?.network?.data || {}),
            orders: tempData,
            loading: tabledata?.network?.network?.loading
        });
    }, [tabledata?.network?.network?.loading])

    const handleChangePage = (event, newPage) => {
        setPages({
            ...pages,
            page: newPage,
        });
        let reqBody = body?.targetEndpoint?.restJsonObjectBody;
        conditionalFilterReplacement(reqBody)
        getSelfAlertTableData({
            ...pages,
            page: newPage,
            body: reqBody
        });
    };

    const handleChangeRowsPerPage = (event) => {
        const pageSize = parseInt(event.target.value, 10)
        setPages({
            page: 0,
            pageSize
        });
        getSelfAlertTableData({
            page: 0,
            pageSize,
            body: body?.targetEndpoint?.restJsonObjectBody
        });
    }

    const { orders } = data;
    const totalRecords = tabledata?.network?.network?.data?.totalRecords || 0;

    return (
        <div data-testid="selfalerttable">
            <DownloadSelfAlert 
                visible={totalRecords>0}
                downloadConfig={{
                    type: "network",
                    subtype: "network",
                    process: defaultNetworkColumns.columnConfiguration,
                    detailsProcess: () => {},
                }}
                config={{
                    body: tablebody,
                    url: endpoints.table.network,
                    columns,
                    totalRecords: totalRecords
                }}
            />
            <MaterialTable
                isLoading={data?.loading}
                key={orders?.length}
                localization={{
                    body: {
                        emptyDataSourceMessage: t("no_records_to_display")
                    }
                }}
                components={{
                    Container: props_ => <Paper {...props_} elevation={0} />,
                    OverlayLoading: props_ => <LinearProgress />,
                    Pagination: props_ => (<TablePagination
                        showFirstButton
                        showLastButton
                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                        component="div"
                        count={totalRecords}
                        page={pages.page}
                        onChangePage={handleChangePage}
                        rowsPerPage={pages.pageSize}
                        classes={paginationClasses}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelRowsPerPage={t("rows_per_page")}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`}
                        data-testid="pagination"
                    />)
                }}
                options={{
                    ...tableoptions,
                    pageSize: pages.pageSize
                }}
                style={{
                    backgroundColor: theme.palette.primary.base,
                    color: 'white',
                    borderBottom: '1px solid white',
                }}
                columns={columns}
                data={orders}
            />
        </div>
    )
}

export default SelfAlertTable;