export const TRANSLATIONS_ES = {
    "select_language": "SELECCIONAR IDIOMA",
    "location": "Ubicación",
    "business": "Negocio",
    "region": "Región",
    "filter_by": "Filtrar por",
    "menu": "Menú",
    "favourites": "Favoritos",
    "you_do_not_have_any_favourites": "No tienes favoritos.",
    "favourites_message": "Las opciones favoritas aparecerán como selecciones predeterminadas cada vez que visite Logistics MAESTRO. Para marcar al cliente como favorito, simplemente haga clic en el icono favorito",
    "rows_per_page": "Filas por página",
    "no_records_to_display": "No hay registros que mostrar",
    "records": "Archivo",
    "loading": "Carga",
    "location_search": "Búsqueda de ubicación",
    "search": "Buscar",
    "locations": "Ubicaciones",
    "daily": "Diario",
    "weekly": "Semanal",
    "exportVolume": "Volumen de exportación",
    "importVolume": "Volumen de importación",
    "exportOrder": "Detalles del pedido de exportación",
    "importOrder": "Detalles del pedido de importación",
    "lao_restricted": "La página a la que intentas acceder está restringida.\n Si desea solicitar un acceso, póngase en contacto con nuestro servicio de asistencia.",
    "lao_sites_failed": "Por el momento, no hemos podido procesar los datos relacionados con LAO.\n Intente actualizar la página después de un tiempo. Si aún así no ayuda, póngase en contacto con nuestro servicio de asistencia.",
    "views_loading_failed": "Error en la carga de vistas",
    "feedback": "Retroalimentación",
    "feedback_sucessfully_posted": "¡Comentarios publicados con éxito!",
    "something_went_wrong_please_try_again": "¡Algo salió mal!, por favor, inténtelo de nuevo",
    "send_us_feedback": "Envíenos sus comentarios",
    "thank_you_for_taking_the_time_to_give_us_feedback": "Gracias por tomarse el tiempo de darnos su opinión.",
    "are_you_satisfied_with_your_experience": "¿Está satisfecho con su experiencia?",
    "required": "Obligatorio",
    "submit_feedback": "Enviar comentarios",
    "tell_us_about_your_experience": "Cuéntanos tu experiencia",
    "network": "Red",
    "my_selections": "Mi Selección",
    "clear_selections": "Borrar selecciones",
    "save_selections": "Guardar selecciones",
    "view_more": "Ver más",
    "view_less": "Ver menos",
    "filters": "Filtros",
    "views": "Vistas",
    "add_view": "Agregar vista",
    "reset": "Restablecimiento",
    "new_view": "Nueva vista",
    "save": "Salvar",
    "delete": "Borrar",
    "new_view_name_msg": "Crea una nueva vista con la configuración predeterminada",
    "enter_a_view_name": "Introduzca un nombre de vista",
    "saved_successfully": "Guardado con éxito",
    "untitled_view": "Vista sin título",
    "created": "Creado",
    "deleted": "borrado",
    "filter_search": "BÚSQUEDA DE FILTROS",
    "clear_all": "Borrar todo",
    "select_all": "Seleccionar todo",
    "enter": "Entrar",
    "expand": "Expandir",
    "collapse": "Colapso",
    "all": "Todo",
    "manage_columns": "Administrar columnas",
    "show_all": "Mostrar todo",
    "hide_all": "Ocultar todo",
    "refresh": "Actualizar",
    "export": "Exportar",
    "apply_selections": "Aplicar selecciones",
    "more": "más",
    "loading_text": "Siéntese y relájese, mientras cargamos los datos para usted...",
    "we_are_sorry": "Lo sentimos...",

    //LAO Table
    "purchase_order_origin": "PO",
    "purchase_order_trade": "PO FTZ",
    "invoice_num": "Número de factura",
    "invoicing_date": "Fecha de facturación",
    "vendor_number": "Número de proveedor",
    "vendor_plant": "Planta de proveedores",
    "origin_plant": "Planta (Planta de origen)",
    "free_trade_destination_plant": "Código de la zona franca de la planta",
    "free_trade_destination_description": "Nombre de la zona franca de la planta",
    "destination_plant": "Código de cliente/Planta",
    "destination_description": "Nombre del cliente",
    "origin_port": "Puerto de origen",
    "international_shipper": "Remitente internacional",
    "booking_number": "Número de reserva",
    "shipment_number": "Número de envío de exportación",
    "load_number": "Número de carga",
    "delivery_number": "Número de entrega",
    "export_shipment_status": "Estado de exportación",
    "origin_country": "País de origen",
    "destination_country": "País de destino",
    "bill_lading": "Conocimiento de embarque",
    "bid_lane_number": "Número de carril BID",
    "lao_order_details_search_helper_text": "Ingrese PO#, factura#, carga#, entrega#.",
    "vendor_name_plant": "Nombre del proveedor/Planta (Planta de origen)",
    "customer_code_plant": "Código de cliente/Planta",
    "customer_name": "Nombre del cliente",
    "container_id": "Id. de contenedor",
    "sku_id": "SKU (Material)",
    "material_description": "Descripción del material",
    "material_type": "Tipo de material",
    "import_shipment_number": "Número de envío de importación",
    "vendor_name": "Nombre del proveedor",
    "import_pre_shipment_status": "Estado previo al envío",
    "import_post_shipment_status": "Estado posterior al envío",
    "import_destination_port": "Nombre del puerto de destino",
    "import_license_number": "Número de licencia de importación",
    "import_license_date": "Fecha de la licencia de importación",
    "delivery_date": "Fecha de entrega",
    "shipment_mode": "Modo de envío",
    "import_container_type": "Tipo de contenedor",
    "incoterms": "Incoterms",
    "export_shipment_etd_date": "KC ETD",
    "export_shipment_eta_date": "KC ETA",
    "export_kc_documental_cutoff_date": "Corte Documental",
    "import_etd_date": "KC ETD",
    "import_eta_date": "KC ETA",
    "import_id_date": "Fecha de identificación",
    "import_declaration": "Declaración de Importación",
    "import_customs_channel": "Canal de Aduanas",
    "import_free_period_end_date": "Fin del período gratuito",
    "import_kc_dock_time": "Hora del muelle de KC",
    "import_req_date_cont_at_plant": "Fecha de requerimiento: cont en planta",
    "import_time_of_arrival_in_plant": "Hora de llegada a la planta",
    "import_date_cont_at_plant": "Fecha de continencia en planta",
    "import_hour_cont_at_plant": "Hora de cont en planta",
    "import_transhipment_etd": "Transbordo ETD",
    "import_tentative_eta": "ETA tentativa",
    "import_tentative_etd": "ETD tentativo",
    "import_customs_leave": "Permiso de Aduana",
    "import_container_return_date": "Fecha de devolución del contenedor",
    "delivery_completion_ind": "Finalización de la entrega",

    "po_item": "Posición de orden de compra",
    "sku_material": "SKU/Material",
    "po_quantity": "Cantidad de orden de compra",
    "quantity_packed": "Cantidad embalada",
    "volume_packed": "Volumen empaquetado",
    "order_unit": "Unidad de pedido",

    "no_data_found": "No se han encontrado datos",
    "download_started_msg": "Se ha iniciado la descarga de archivos. Esto puede tardar un par de segundos.",
    "download_progress_msg": "La descarga de archivos ya está en curso",
    "export_view": "VISTA DE EXPORTACIÓN",
    "with_product_details": "CON DETALLES DEL PRODUCTO",
    "without_product_details": "SIN DETALLES DEL PRODUCTO",
    "with_managed_columns_and_filters_applied": "Con columnas y filtros administrados aplicados",
    "well_this_is_unexpected": "Bueno, esto es inesperado...",
    "please_wait": "Espera...",
    "download_successful": "¡Descarga exitosa!",
    "download_failed_msg": "¡Arrepentido! Error en la descarga del archivo. Por favor, inténtelo de nuevo.",
    "view_deleted_success": "Ver eliminado correctamente",
    "view_deleted_failed": "Error en la eliminación de la vista",
    "view_fav_unfav_success": "Ver éxito favorito/no favorito",
    "view_fav_unfav_failed": "Ver Favorito/No Favorito Fallido",
    "start_date": "Fecha de inicio",
    "end_date": "Fecha final",
    "please_wait_while_the_data_is_loading": "Espere mientras se cargan los datos...",
    "view_created_updated_success": "Vista creada/actualizada correctamente",
    "view_creation_updation_failed": "Error en la creación/actualización de la vista",
    "list_of_favourited_selections": "Lista de selecciones favoritas",
    "business_unit": "Unidad de Negocio",
    "delivery_eta": "Entrega ETA",
    "atleast_one_business_unit_needs_to_selected": "Es necesario seleccionar al menos una unidad de negocio",
    "exportOrderMobile": "Exportar",
    "importOrderMobile": "Importación",
    "lao_order_details_search_helper_text_import": "Orden de compra#, # de factura, BL#, número de envío de importación.",
    "yes": "Sí",
    "no": "No",
    "helperTextWithSeparator": "Para la búsqueda múltiple, utilice solo el punto y coma como separador. Ej: 111; 222 La búsqueda de comodines (*) solo está habilitada para la búsqueda única. Ej: 111* o 222*",
    "helperText": "Para la búsqueda múltiple, utilice separadores como coma, espacio o punto y coma. Ej: 111.222 o 111 222 o 111; 222 La búsqueda de comodines (*) solo está habilitada para la búsqueda única. Ej: 111* o 222*"
}