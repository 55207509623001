import { emeaFavoriteConstants } from "reduxLib/constants/EMEA/emeaFavoriteConstants";

const initialState = {
  bu_fav_loading: false,
  bu_fav: [],
  bu_fav_err: null,
  all_favs_loading: false,
  all_favs: {},
  all_favs_err: null,
}

const emeaFavoriteReducer = (state = initialState, action) => {
  switch (action.type) {

    case emeaFavoriteConstants.EMEA_BU_FAVORITES_LOADING:
      return { ...state, bu_fav_loading: action.payload };

    case emeaFavoriteConstants.EMEA_BU_FAVORITES_SUCCESS:
      return { ...state, bu_fav: action.payload, bu_fav_err: 'success' };

    case emeaFavoriteConstants.EMEA_BU_FAVORITES_FAILED:
      return { ...state, bu_fav_err: action.error };

    case emeaFavoriteConstants.EMEA_FAVORITES_LOADING:
      return { ...state, all_favs_loading: action.payload };

    case emeaFavoriteConstants.EMEA_FAVORITES_SUCCESS:
    case emeaFavoriteConstants.EMEA_FAVORITES_FAILED:
    case emeaFavoriteConstants.EMEA_UPDATE_FAVORITES:
      return { ...state, all_favs: action.payload, all_favs_err: action.error };


    default:
      return state;
  }
}

export default emeaFavoriteReducer;  