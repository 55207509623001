import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { getMonthYearRange } from 'helpers/emeaHelpers';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
    select: {
        maxHeight: theme.spacing(4),
        "& :focus": {
            backgroundColor: "transparent"
        },
        '& .MuiSelect-icon': {
            color: 'inherit',
        },
    }
}));

const EMEASelectMonthFilter = ({ dates = {}, onChange }) => {
    const classes = useStyles();

    const monthRange = useMemo(() => getMonthYearRange(dates), [dates.startTime, dates.endTime]);
    const [selected, setSelected] = useState(monthRange[0]?.month || "");

    useEffect(() => {
        if (monthRange.length > 0) {
            setSelected(monthRange[0].month);
        }
    }, [monthRange]);

    const updateDates = useCallback((value) => {
        const date = moment(value, "MMM YYYY");
        setSelected(value);
        onChange({
            startTime: date.startOf('month').format('YYYY-MM-DD'),
            endTime: date.endOf('month').format('YYYY-MM-DD')
        });
    });

    return (
        <FormControl variant="outlined" className={classes.formControl} data-testid="month-filter-emea">
            <Select
                value={selected}
                onChange={(e) => updateDates(e.target.value)}
                className={classes.select}
            >
                {monthRange.map((d, index) => (
                    <MenuItem key={index} value={d.month}>{d.month}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default EMEASelectMonthFilter;
