import React from 'react'
import {
    Typography, makeStyles, TextField, Box, Modal,
    Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { requestForAccess, resetRequeststatus } from 'reduxLib/services/getAllGuardRailsService';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { viewStyles } from "theme";

const useStylesModal = makeStyles(viewStyles);
const useStyles = makeStyles(theme => ({
    root: {
        width: "50%",
        background: theme.palette.primary.base,
        padding: theme.spacing(2.5),
        margin: `${theme.spacing(16)}px auto`,
        position: 'relative',
        "&>div:nth-child(3)": {
            marginTop: theme.spacing(2),
            borderRadius: theme.spacing(.5),
            width: "100%",
        }
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1.5),
        cursor: "pointer",
        zIndex: 10
    },
    textarea: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(2),
        textAlign: "center"
    }
}))

export default function RequestAccessModal({ open, setOpen, snack, setSnack }) {
    const modalClasses = useStylesModal();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user: { name } } = useSelector(({ auth }) => auth);
    const { requestStatus } = useSelector(({ guardrails }) => guardrails);
    const [disable, setDisable] = React.useState(false);
    const [reason, setReason] = React.useState(null);

    const { t } = useTranslation();

    const requestAccess = (e) => {
        e.preventDefault();
        setDisable(true);
        dispatch(requestForAccess({
            requestedUser: name,
            reason
        }));
    }

    React.useEffect(() => {
        setDisable(!open);
    }, [open])

    React.useEffect(() => {
        const { status, reason: reason1 } = requestStatus
        if (status) {
            const successMessage = t("your_request_submitted_wait_for_approval")
            setDisable(requestStatus.disable)
            setSnack({
                ...snack,
                open: true,
                severity: status === "success" ? "success" : "error",
                message: status === "success" ? successMessage : reason1
            });
            setOpen(false);
            dispatch(resetRequeststatus());
        }
    }, [requestStatus])

    return (
        <Modal
            className={modalClasses.modalBody}
            open={open}
            onClose={() => setOpen(false)}
            data-testid="guardrail-modal-request"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes.root} >
                <span title='closeicon_modal' data-testid='closeicon_modal' className={classes.closeicon} onClick={() => setOpen(false)}><CloseIcon /></span>
                <form onSubmit={requestAccess}>
                    <Typography component='div' variant='h4' data-testid="request_access_title">{t("request_access")}</Typography>
                    <Typography component='div' variant='p' data-testid="please_mention_reason_for_request">{t('please_mention_reason_for_request')}</Typography>
                    <TextField
                        data-testid="outlined-multiline-static"
                        label={t("enter_reason_here")}
                        multiline
                        rows={4}
                        variant="outlined"
                        value={reason}
                        className={classes.textarea}
                        onChange={(e) => setReason(e.target.value)}
                        required
                        disabled={disable}
                    />
                    <Box textAlign='center'>
                        <Button disable={disable}
                            className={classes.button}
                            type="submit"
                            variant="outlined"
                            data-testid="submit-button"
                            disabled={disable}
                            onSubmit={requestAccess} >{t("request_access")}</Button>
                    </Box>
                </form>
            </div>
        </Modal>
    )
}