import React, { useState, useEffect } from 'react';
import { useThemeContext } from 'context/ThemeContext';
import {
  makeStyles,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  Collapse, useMediaQuery, useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '../../assets/images/home.svg';
import OrdersIcon from '../../assets/images/cart.svg';
import DistributionIcon from '../../assets/images/warehouse.svg';
import TransportIcon from '../../assets/images/truck.svg';
import OsmIcon from '../../assets/images/docc.svg';
import TutorialIcon from '../../assets/images/tutorial-icon.svg';
// import StockConstraintReportIcon from '../../assets/images/report.svg';
import AnalyticsIcon from '../../assets/images/analytics.svg';
import { CTLink } from './CTLink';
import { chartDataIdentifierForAPI as chartDataIdentifierForAPI } from 'helpers/index';
import MenuIcon from '@material-ui/icons/Menu';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { intersection, isEmpty } from 'lodash';
import { useFeatureFlag } from 'configs/useFeatureFlag';
import { useTranslation } from 'react-i18next';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { useRouteContext } from 'context/RouteContext';
import { useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import HomeDarkIcon from '../../assets/images/home_dark.svg';
import OrdersDarkIcon from '../../assets/images/cart_dark.svg';
import DistributionDarkIcon from '../../assets/images/warehouse_dark.svg';
import TransportDarkIcon from '../../assets/images/truck_dark.svg';
import OsmDarkIcon from '../../assets/images/docc_dark.svg';
import TutorialDarkIcon from '../../assets/images/tutorial_icon_dark.svg';
import AnalyticsDarkIcon from '../../assets/images/analytics_dark.svg';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.drawer,
    borderRight: 'none',
    top: theme.spacing(8)
  },
  drawerHeader: {
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  listHeader: {
    height: theme.spacing(4),
    float: 'right'
  },
  close: {
    color: theme.palette.text.primary,
    display: 'inline-flex',
    cursor: 'pointer'
  },
  closeIcon: {
    fontSize: 'medium',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  },
  listItems: {
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    '&.MuiListItem-root.Mui-selected' : {
      backgroundColor: theme.palette?.link?.default
    }
  },
  menuButton: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1)
  },
  menuButtonFocus: {
    background: theme.palette.primary.pressed,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1)
  },
  analyticsList: {
    background: theme.palette.chip?.backgroundDefault,
  },
  text: {
    color: theme.palette.text.primary
  }
}));

const AppDrawer = ({ setDetails, pageDetails }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { theme } = useThemeContext();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mainPath, analyticsPath } = useRouteContext();
  const [openDrawer, setDrawerOpen] = useState(false);
  const activePageMapping = {
    home: "NETWORK",
    orders: "ORDER",
    transport: "TRANS_PLAN",
    distribution: "DIST",
    osm: "OSM",
  }
  const activeMenu = intersection(
    Object.values(chartDataIdentifierForAPI),
    [activePageMapping[params?.page]]
  )[0];
  const [selectedIndex, setSelectedIndex] = useState(activeMenu);
  const [extend, setExtend] = React.useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const analyticsReports = useFeatureFlag('analyticsReports');
  const showAnalyticsLink = useFeatureFlag('showAnalyticsLink')?.enabled;

  const privateURLs = [
    {
      name: t('osm_breadcrumb_text'),
      iconUrl: theme?.type === 'dark' ? OsmIcon : OsmDarkIcon,
      viewUrl: chartDataIdentifierForAPI.osm
    }
  ];

  const menuList = [
    {
      name: t('home'),
      iconUrl: theme?.type === 'dark' ? HomeIcon : HomeDarkIcon,
      viewUrl: chartDataIdentifierForAPI.network
    },
    {
      name: t('order_management_breadcrumb_text'),
      iconUrl: theme?.type === 'dark' ? OrdersIcon : OrdersDarkIcon,
      viewUrl: chartDataIdentifierForAPI.order
    },
    {
      name: t('distribution_breadcrumb_text'),
      iconUrl: theme?.type === 'dark' ? DistributionIcon : DistributionDarkIcon,
      viewUrl: chartDataIdentifierForAPI.dist
    },
    {
      name: t('transportation_breadcrumb_text'),
      iconUrl: theme?.type === 'dark' ? TransportIcon : TransportDarkIcon,
      viewUrl: chartDataIdentifierForAPI.transPlan
    },
    ...privateURLs,
    {
      name: t('tutorials_&_resources'),
      iconUrl: theme?.type === 'dark' ? TutorialIcon : TutorialDarkIcon,
      viewUrl: '',
      link: process.env.REACT_APP_GLOSSARY_URL
    },
    // Commenting SCR Link
    // {
    //   name: t('stock_constraint_report_title'),
    //   iconUrl: StockConstraintReportIcon,
    //   viewUrl: chartDataIdentifierForAPI.stockConstraintReport
    // }
  ];

  const [analyticsList, updateAnalyticsList] = useState(analyticsReports.description || []);

  const collapseAll = () => {
    setExtend(false);
    const collapseLists = analyticsList?.map((d) =>
      d.open ? { ...d, open: false } : d
    );
    updateAnalyticsList(collapseLists);
  };

  const handleListItemClick = (category, reportUrl, analyticFolder) => {
    setSelectedIndex(category);
    if (Object.values(chartDataIdentifierForAPI).includes(category)) {
      collapseAll();
    }
    const pages = {
      NETWORK: "home",
      ORDER: "orders",
      DIST: "distribution",
      TRANS_PLAN: "transport",
      OSM: "osm"
    }
    if (reportUrl && !pages[category]) {
      dispatch({ type: 'UPDATE_REPORT_URL', payload: reportUrl })
    }
    if(category) {
      const path = pages[category]
      ? mainPath
      : `${analyticsPath}${analyticFolder.name}/${category}`
    const newpath = generatePath(
      path,
      {
        ...params,
        page: pages[category],
        // tabletype: category === 'DIST' && 'outbound' 
        tabletype: pages[category] === "distribution" ? "outbound": undefined
      }
    );
    const updatedParamPath = `${newpath}?showDefault=true${ pages[category] === "osm" ? '&activity=reset&osmfilters={}&osmglobal={}': ''}`;
    history.push(updatedParamPath)
    // history.push(`${newpath}?showDefault=true`)
    setDrawerOpen(!openDrawer);
    }
  };

  const onAnalyticsClick = (analyticFolderIndex) => {
    const activeLists = analyticsList?.map((d, index) =>
      analyticFolderIndex == index ? { ...d, open: !d.open } : d
    );
    updateAnalyticsList(activeLists);
  };

  useEffect(() => {
    updateAnalyticsList(
      analyticsReports.description
    );
  }, [analyticsReports]);

  useEffect(() => {
    if (activeMenu && activeMenu !== chartDataIdentifierForAPI.analytics)
      setSelectedIndex(activeMenu);
  }, [activeMenu]);

  useDeepCompareEffect(() => {
    const { report, folder } = params;
    if (report) {
      setSelectedIndex(params.report);
      setExtend(true);
      const activeLists = analyticsList?.map((d, index) => {
        return d.name === folder ? { ...d, open: true } : d
      });
      if (!isEmpty(activeLists)) {
        updateAnalyticsList(activeLists);
      }
    }
  }, [params.report, params.folder, analyticsList])

  return (
    <>
      <IconButton
        color="primary"
        data-testid="menu"
        aria-label="open drawer"
        onClick={() => setDrawerOpen(!openDrawer)}
        edge="start"
        className={openDrawer ? classes.menuButtonFocus : classes.menuButton}>
        <MenuIcon />
      </IconButton>
      <Drawer
        data-testid="app-drawer"
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{ paper: classes.drawerPaper }}>
        <List
          subheader={
            <ListSubheader
              component="div"
              data-testid="nested-list-subheader"
              className={classes.listHeader}
              onClick={() => setDrawerOpen(!openDrawer)}>
              <CTLink className={classes.close} data-testid="closeicon">
                <CloseIcon className={classes.closeIcon} />
                <Typography data-testid="closeMenu" variant="subtitle1" className={classes.text}> { t("close_menu") } </Typography>
              </CTLink>
            </ListSubheader>
          }></List>

        {menuList &&
          menuList.map((menuItem, index) => (
            <ListItem
              button
              onClick={() => handleListItemClick(menuItem.viewUrl, false)}
              className={classes.listItems} key={index}
              selected={selectedIndex === menuItem.viewUrl}>
              {menuItem.viewUrl && (
                <CTLink
                  className={classes.close}>
                  <ListItemIcon>
                    {' '}
                    <img src={menuItem.iconUrl} />{' '}
                  </ListItemIcon>
                  <ListItemText data-testid={menuItem.viewUrl} primary={menuItem.name} />
                </CTLink>
              )}
              {menuItem.link && (
                <CTLink
                  className={classes.close}
                  target="_blank"
                  href={menuItem.link}
                  onClick={() => setDrawerOpen(!openDrawer)}>
                  <ListItemIcon>
                    {' '}
                    <img src={menuItem.iconUrl} />{' '}
                  </ListItemIcon>
                  <ListItemText primary={menuItem.name} />
                </CTLink>
              )}
            </ListItem>
          ))}

        {!isMobile && showAnalyticsLink && !isEmpty(analyticsList) && (
          <>
            <ListItem
              button
              className={classes.listItems}
              data-testid="analytics"
              onClick={() => setExtend(!extend)}>
              <ListItemIcon>
                {' '}
                <img src={theme?.type === 'dark' ? AnalyticsIcon : AnalyticsDarkIcon} />{' '}
              </ListItemIcon>
              <ListItemText primary={t("supply_chain_analytics")} />
              {extend ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={extend}
              className={classes.analyticsList}
              timeout="auto"
              unmountOnExit>
              {analyticsList?.map((analyticFolder, analyticFolderIndex) => (
                <List component="div" disablePadding>
                  <ListItem
                    button
                    key={analyticFolder.name}
                    className={classes.listItems}
                    data-testid="analyticsFolder"
                    onClick={() => onAnalyticsClick(analyticFolderIndex)}>
                    <ListItemText primary={analyticFolder.name} />
                    {analyticFolder.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse
                    in={analyticFolder.open}
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {analyticFolder?.reports?.map((report) => (
                        <ListItem
                          button
                          key={report.reportName}
                          data-testid="analyticsReport"
                          selected={selectedIndex === report.reportName}
                          onClick={() =>
                            handleListItemClick(
                              report.reportName,
                              report.reportUrl,
                              analyticFolder
                            )
                          }>
                          <ListItemText className={classes.text} primary={report.reportName} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              ))}
            </Collapse>
          </>
        )}
      </Drawer>
    </>
  );
};

export default AppDrawer;
