import React from 'react';
import { connect } from "react-redux";
import { get } from "lodash";
import { saveFilters, saveSelfAlertFilters } from "reduxLib/services";
// import FilterComponentContainer from "../Filters/FilterComponentContainer";
import { TextField, Typography, Checkbox, Grid, makeStyles, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    listbox:{
        '&::-webkit-scrollbar': {
            height: `${theme.spacing(1.5)}px !important`,
            width: `${theme.spacing(1.5)}px !important`,
            
        },
        '&::-webkit-scrollbar-track': {
            border: `5px solid ${theme.palette.white}`, 
            borderRadius: 0,
            background:theme.palette.divider
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.divider, 
            borderRadius: '10px',
        },
    },
    option: {
        color : theme.palette.black,
        '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
            backgroundColor: theme.palette.secondary.dark,
            borderColor: 'transparent',
            color : theme.palette.white,
            '& p, & h6':{
                color: theme.palette.white
            },
            '& .MuiIconButton-label' : {
                color: theme.palette.white
            }
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.secondary.base,
            borderColor: 'transparent',
            color : theme.palette.white,
            '& p, & h6':{
                color: theme.palette.white
            },
            '& .MuiIconButton-label' : {
                color: theme.palette.white
            }
        },
    },
    tag: {
        border: `1px solid ${theme.palette.border}`,
        backgroundColor: theme.palette.secondary.dark,
        margin: theme.spacing(0.5),
    }
}));

const useStyles2 = makeStyles((theme) => ({
    subtext:{
        color: theme.palette.black
    },
    checkbox: {
        color: theme.palette.black,
        '&$checked,&:hover ': {
            color: theme.palette.white,
        },
    },
}))

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        }
    },
}))(TextField);


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const OriginFilterElement = ({ locations, defaultValues, addOrigin }) => {
    const classes = useStyles();
    const classes2 = useStyles2();
    const { t } = useTranslation()
    const mappedElements = locations.filter(d => defaultValues?.includes(d.siteNum)) || [];

    return (
        <Autocomplete data-testid='originFilterElement'
            multiple
            id="origin-filter"
            options={locations}
            disableCloseOnSelect
            limitTags={1}
            value={mappedElements}
            getOptionLabel={(option) => {
                return `${option.shortName} (${option?.alias || ""}${!!option?.alias && ', '}${option.siteNum})`
            }}
            onChange={addOrigin}
            classes={classes}
            renderOption={(option, { selected }) => (
                <>
                    <Grid item>
                        <Checkbox
                            className={classes2.checkbox}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                    </Grid>
                    <Grid container >
                        <Typography className={classes2.subtext} variant="h6">{option.shortName}
                            <Typography variant="body2" className={classes2.subtext}>
                                {option?.alias || '' }{!!option?.alias && "-"}{option.siteNum}

                            </Typography>
                        </Typography>
                    </Grid>
                </>
            )}
            style={{ width: "100%", color: 'red' }}
            renderInput={(params) => (
                <CssTextField {...params} variant="outlined" label={t("origin_sites")} />
            )}
        />
    );
}


const OriginFilter = (props) => {

    const { filters, filterKey, type, subtype, locations, items, customSaveFilters } = props;

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    // const allowedPlaces = ["network", "mylocation"];


    const filter = get(filters, `${filterKey}.data`, [])
    const [input, setInput] = React.useState(filter);

    const changeOrigins = (sites) => {
        const savePayload = {
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: sites
                }
            }, type, subtype
        };
        if (typeof customSaveFilters === "function") {
            customSaveFilters(savePayload);
            return;
        }
        saveFilters(savePayload);
    }

    React.useEffect(() => {
        setInput(filter);
    }, [filter])

    const addOrigin = (data, sites) => {
        let originSites = sites?.map(d => {
            return d.siteNum
        });
        if (originSites.length === 0) {
            originSites = null;
        }
        changeOrigins(originSites);
    }


    // if (!allowedPlaces.includes(type)) {
    //     return null;
    // }

    const filterSites = type === "network" ? locations : items;

    return (
        // <FilterComponentContainer testId='originFilterElementContainer' {...props}>
        <div data-testid="originFilterElementContainer">
            <OriginFilterElement {...props} locations={filterSites} addOrigin={addOrigin} defaultValues={input || []} />
        </div>
        // </FilterComponentContainer>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;

    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        loading: state.sites.loading,
        items: state.items.items,
        locations: state.sites.locations || [],
        favorites: state.favorites.favorites || [],
        filters
    }
};

const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(OriginFilter);
