import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { Grid, Typography, makeStyles, Box, useMediaQuery, useTheme } from "@material-ui/core"
import { searchFilterStyles, locationfilterlayoutStyles } from "theme"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty, intersection, concat } from "lodash"
import NoResult from "./NoResult"
import FavTickElement from "components/common/FavUnfavElement";
import { clearAllCuso } from "reduxLib/services"
import KCButton from "components/common/Button/Button"
import SearchBar from "components/common/SearchBar"
import {
    getCustomersService,
    addItemCuso,
    removeItemCuso,
    setFavCUSOService,
    addItemCusoSF,
    removeItemCusoSF
} from "../../reduxLib/services"
import CustomersChipComponent from "./CustomersChipComponent"
import { TooltipTitle } from "./Customers";


const useStyles = () => {
    return {
        ...makeStyles(searchFilterStyles)(),
        ...makeStyles(locationfilterlayoutStyles)()
    }
}   

export default (props) => {
    const {listingFilters, selfalert} = props;


    let addCuso = addItemCuso;
    let removeCuso = removeItemCuso;

    if(selfalert) {
        addCuso = addItemCusoSF;
        removeCuso = removeItemCusoSF;
    }

    const [search, setSearch] = React.useState("");
    const classes = useStyles()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    let filteredCustomerList = useSelector(({sites})=> sites.customer)
    const { cuso } = useSelector(({ selfalerting, items}) =>  {
        if (selfalert) return selfalerting;
        else return items;
    })
    const { favCuso } = useSelector(({favorites}) =>  favorites)
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))

    const searchPayload = {
        salesOrg: listingFilters.saleOrgsCheckboxArray.filter(({checked}) => checked).map(({value})=> value),
        distributionChannel:[listingFilters.selectedDC],
        searchString:search
    }

    const submitSearch = (e) => {
        e.preventDefault() 
        dispatch(getCustomersService(searchPayload))
    }

    const getTickValue = ({distributionChannel = "",customerName, salesOrgList }) => {
        const currentSelection = intersection(searchPayload.salesOrg, salesOrgList.map(({salesOrg})=> salesOrg));
        const salesOrgArrayFromState = cuso.CUST.filter((item) => ( distributionChannel && distributionChannel === item.distributionChannel) && customerName === item.customerName).map(({salesOrg}) => salesOrg)
        const bas = intersection(currentSelection,salesOrgArrayFromState)
        return   currentSelection?.length === 0 ? false : currentSelection.length === bas.length
    }

    const getFavValue = ({distributionChannel,customerName, salesOrgList }) => {
        const currentSelection = intersection(searchPayload.salesOrg, salesOrgList.map(({salesOrg})=> salesOrg));
        const salesOrgArrayFromState = favCuso.FAV_CUST.filter((item) => distributionChannel === item.distributionChannel && customerName === item.customerName).map(({salesOrg}) => salesOrg)
        const bas = intersection(currentSelection,salesOrgArrayFromState)
        return currentSelection.length === 0 ? false : currentSelection.length === bas.length
    }

    const [favElementKeys, setFavElementKeys] = useState([]);

    const getValue = (data = []) => {
        const values = [];
        data.map(d => values.push(d[0]));
        return values;
    }

    useEffect(() => {
        const values = getValue(favCuso.FAV_CUST);
        setFavElementKeys(values);
    }, [favCuso]);

    // useEffect(()=>{
    //     if(search!=="" ) dispatch(getCustomersService(searchPayload))
    // },[listingFilters])

    return(
        <>
        <CustomersChipComponent action={removeItemCuso} chipsObjectArray={[...cuso.CUST,...cuso.SALES_OFFICE]} selfalert={selfalert} />
        <Grid container data-testid='CUlisting' justify={isEmpty(filteredCustomerList.data) ? "center" : "flex-start"} >
            <Grid item xs={12} sm={12} md={4} lg={4} >
                <Box ml={2} className={{[classes.searchBar]: isEmpty(filteredCustomerList.data) && !filteredCustomerList.noData }} >
                    {!isMobile && <Typography>{t('search_customer')}</Typography>}
                    <SearchBar handleSubmit={submitSearch} searchPhrase={search} handleChange={(value)=> setSearch(value)} placeholder={"ex: Wal*"} />
                    { !isEmpty(filteredCustomerList.data) && <Typography variant="body1" className={classes.searchCountText} >{`${filteredCustomerList?.data?.length || 0} ${t('results_found')}`}</Typography>}
                </Box>
            </Grid>
            {
                (filteredCustomerList.noData) && <><Grid className={classes.noresultContainer} xs={12} ><NoResult value={search} searchType={"Customers"} /></Grid></>
            }
            <Grid item xs={12} container alignContent="flex-start" className={[`${classes.overflowLocations}`, {[classes.center]:isEmpty(filteredCustomerList), [classes.height] : !isEmpty([...cuso.CUST,...cuso.SALES_OFFICE])}]}>
                {!isEmpty(filteredCustomerList.data) && filteredCustomerList?.data?.length > 0 && filteredCustomerList.data.map(customer => {
                        return <FavTickElement 
                                    key={customer.customerName}
                                    selfalert={selfalert}
                                    favElements={favElementKeys} data={
                                        {
                                            shortName:`${customer.customerName}`,
                                            reducerObject:{
                                                customerName:`${customer.customerName}`,
                                                selectionType:"CUST",
                                                distributionChannel:searchPayload.distributionChannel[0],
                                                salesOrg:intersection(searchPayload.salesOrg, customer.salesOrgList.map(({salesOrg})=> salesOrg))
                                            },
                                            state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE)
                                        }
                                    }
                                    tooltipTitle={ getTickValue(({
                                                    customerName:`${customer.customerName}`,
                                                    selectionType:"CUST",
                                                    distributionChannel:searchPayload.distributionChannel[0],
                                                    salesOrgList:customer.salesOrgList
                                                    })) ? <TooltipTitle dc={searchPayload.distributionChannel[0]} /> : undefined }
                                    unCheckClick={removeCuso}
                                    checkClick={addCuso} 
                                    favClick={setFavCUSOService} 
                                    unfavClick={setFavCUSOService}
                                    tick={getTickValue(({
                                            customerName:`${customer.customerName}`,
                                            selectionType:"CUST",
                                            distributionChannel:searchPayload.distributionChannel[0],
                                            salesOrgList:customer.salesOrgList
                                    }))}
                                    fav={getFavValue(({
                                        customerName:`${customer.customerName}`,
                                        selectionType:"CUST",
                                        distributionChannel:searchPayload.distributionChannel[0],
                                        salesOrgList:customer.salesOrgList }))
                                    } 
                                />
                            })  
                }
            </Grid>
            {!isEmpty(filteredCustomerList.data) && <KCButton
                data-testid='CUButton'
                className={[classes.actionButton, classes.actionButtonCUSO]} variant="outlined"
                onClick={() => dispatch(clearAllCuso())}
                disabled={isEmpty([...cuso.CUST,...cuso.SALES_OFFICE])} 
                >
                {t('clear_selections')}
            </KCButton> }         
        </Grid>
        </>
    )
}