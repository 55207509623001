import React from "react";
import {
    TextField,
    MenuItem
} from "@material-ui/core"

const SFSelect = ({ props, menu }) => {
    return (
        <TextField
            {...props}
        >
            {
                menu.map(({ name, value }) => {
                    return <MenuItem value={value}>{name}</MenuItem>
                })
            }
        </TextField>
    )
}

export default SFSelect;