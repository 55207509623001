import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import MaterialTable from 'material-table';
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import fetch from "reduxLib/services/serviceHelpers";
import { appLightTheme, osmProductDetailsStyles } from 'theme';
import { detailOptions } from 'components/osm/OsmProductDetailsTable';

const useStyles = makeStyles(osmProductDetailsStyles);

const detailTableOptions = {
  ...detailOptions,
  headerStyle: {
    ...detailOptions.headerStyle,
    backgroundColor: appLightTheme?.palette?.table?.secondaryHeader,
    padding: `${appLightTheme?.spacing(1)}px ${appLightTheme?.spacing(2)}px`,
  },
  rowStyle: {
    ...detailOptions.rowStyle,
    backgroundColor: appLightTheme?.palette?.table?.secondaryBase,
  },
  cellStyle: {
    padding: `${appLightTheme?.spacing(1)}px ${appLightTheme?.spacing(2)}px`
  }
}

export const EmeaDetailsTable = ({ columns, fetchEndpoint, tablePayload, rowIDValue, rowIDKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [translatedColumns, setTranslatedcolumns] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const translatedHeaders = columns.columnOrder?.map(data => {
      return {
        ...data,
        title: t(data.title),
      }
    });
    setTranslatedcolumns(translatedHeaders);
  }, []);

  return (
    <Box data-testid="emeaDetailsTable" justifyContent="center" className={classes.lightTableBody}>
      {/* <Typography variant="h3" className={classes.tableTitle}>{t('product_details')}</Typography> */}
      <Grid className={classes.detailsTable}>
        <MaterialTable
          localization={{
            body: { emptyDataSourceMessage: loader ? t("please_wait_while_the_data_is_loading") : t("no_records_to_display") }
          }}
          columns={translatedColumns}
          options={detailTableOptions}
          data={() =>
            new Promise((resolve, reject) => {
              fetch(fetchEndpoint, {
                method: "post",
                body: { ...tablePayload, [rowIDKey]: rowIDValue }
              })
              .then(response => {
                  return response?.json() || {};
              })                            
              .then(result => {
                setLoader(false);
                resolve({
                  data: result?.items
                })
              })
              .catch(e => {
                setLoader(false);
                resolve({
                  data: []
                })
              })
            })
          }
        />
      </Grid>
    </Box>
  )

}

export default EmeaDetailsTable;