import React from 'react';
import { makeStyles, Box, IconButton } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Customers from 'components/header/Customers';
import { filterStyles } from "theme";
import clsx from "clsx";
import { CloseOutlined } from '@material-ui/icons';

const useStyles = makeStyles(filterStyles);

export default function CustomerModal({ osmFilter }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div data-testid="customermodal">
            <div className={!osmFilter && classes.border}>
                <Box alignItems="center"
                    data-testid="opencustomers"
                    className={osmFilter ? classes.customerBox
                        : clsx(classes.container, classes.controls, classes.openBox, classes.modalbutton)}
                    onClick={handleOpen}
                >
                    {osmFilter ? "Select Customer/Sales Office" : "CUSTOMERS"}
                </Box>
            </div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.modalbody}>
                    <IconButton
                        data-testid="closeicon-cuso"
                        onClick={handleClose}
                        className={classes.closeicon}
                        variant="outlined" size="small">
                        <CloseOutlined fontSize="small" />
                    </IconButton>
                    <Customers
                        handleTabHideShow={() => { }}
                        selfalert={true}
                    />
                </div>
            </Modal>
        </div>
    );
}
