import React from "react";
import { Box } from "@material-ui/core";
import PriceTag from "assets/images/pricetag.svg";
import { Typography } from "@material-ui/core";

const PromotionalOrder = ({ data, textkey = "orderNum", tagkey="promotionalOrder" }) => {
    return (
        <Box display="flex" p={1} data-testid="promotionalorder-with-tag" >
            <Box p={1} flexGrow={1} >
                <Typography data-testid="promotionalorder-text">{data[textkey] || "-"}</Typography>
            </Box>
            <Box p={1} >
                {
                    data?.[tagkey] === "Yes" ?
                        <img src={PriceTag} style={{ height: 16, width: 16 }} data-testid="image-tag" /> :
                        null
                }
            </Box>
        </Box>
    )


}

export default PromotionalOrder;