import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import { makeStyles, Box, Typography, Grid, TextField, FormControl, Input, InputAdornment, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { Search } from "@material-ui/icons";
import OsmSelect from 'components/osm/OsmSelect';
import { businessUnits as BU } from "configs/appConstants";
import { osmSelectStyles } from "theme"
import map from 'lodash/map';
import without from 'lodash/without';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { isJsonParsable } from 'helpers';
import { useDeepCompareEffect } from 'react-use';
import { flatten } from 'lodash';

const selectStyles = makeStyles(osmSelectStyles);

const useStyles = makeStyles((theme) => ({
  titles: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1.5)
  },
  customSpace: {
    // paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2)
    }
  },
  item: {
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    borderLeft: `1px solid ${theme.palette?.text?.primary}`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      marginRight: 0,
      borderLeft: 'none',
      marginTop: theme.spacing(1),
    }
  },
  searchLocation: {
    margin: `${theme.spacing(1)}px 0px`,
    padding: theme.spacing(1)
  }
}));


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 350,
      minWidth: 250,
    },
    'data-testid': 'dropdownMenu'
  },
  getContentAnchorEl: null,
  autoFocus: false,
  anchorOrigin: {
    vertical: 38,
    horizontal: -8
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

const OsmGlobalFilters = () => {
  const classes = useStyles();
  const classesSelect = selectStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { osm_sites_loading, regions, countries, sites, currentSelections, osm_sites } = useSelector(({ osmAuth }) => osmAuth);

  const [region, setRegion] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [business, setBusiness] = useState(BU);
  const [searchText, setSearchText] = useState("");
  const [queryParams, setQueryParams] = React.useState({});
  const [globallocal, setGlobalLocal] = useState(null);
  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

  const filteredLocations = useMemo(() => {
    const forcedList = globallocal?.siteslist || [];
    return sites?.filter((option) => {
      if(forcedList.length && !forcedList?.includes(option.st_site_num)) return false;
      return containsText(option?.name, searchText) && country.length && country.includes(option?.st_country);
    });
  },
    [searchText, sites, country, globallocal?.siteslist]
  );

  const allSitesSelected = useMemo(()=>{
    return selectedSites.length > 0 && filteredLocations.filter(v=>selectedSites.includes(v.st_site_num)).length === filteredLocations.length
  
  },[filteredLocations, selectedSites])

  const updateURLParam = (params) => {
    const paramObj = {
      osmglobal: JSON.stringify({
        ...(JSON.parse(queryParams.osmglobal)),
        ...params
      })
    }
    const newQueries = {
      ...queryParams,
      ...paramObj,
    };
    history.replace({
      ...location,
      search: qs.stringify(newQueries, { encode: false }),
    })
  }

  const handleSelectedSites = (siteArray) => {
    // if (siteArray?.length === 0) return;
    if(siteArray[siteArray.length-1] === 'all'){
      const allItemsChecked = filteredLocations.filter(v=>siteArray.includes(v.st_site_num)).length === filteredLocations.length
      siteArray = allItemsChecked ? [] : filteredLocations.map(v=>v.st_site_num)
    }
    // let selected = siteArray.length ? siteArray : sites.length ? [sites[0]?.st_site_num] : [];
    const sitesArr = (searchText == "") ? siteArray.filter(item => item) : without(siteArray, searchText)
    setSelectedSites(sitesArr);
    updateURLParam({
      sites: sitesArr
    })
  }

  useEffect(() => {
    if (globallocal) {
      updateURLParam(globallocal);
      setRegion(globallocal.regions);
      setCountry(globallocal.countries);
      setSelectedSites(globallocal.sites);
      setBusiness(globallocal.business);
    }
  }, [globallocal]);

  useEffect(() => {
    setQueryParams(qs.parse(location.search));
  }, [location.search])

  useDeepCompareEffect(() => {
    if (!osm_sites_loading && queryParams?.osmglobal) {
      const global = isJsonParsable(queryParams?.osmglobal);
      const values = flatten(Object.values(global));
      const siteids = map(sites, 'st_site_num');

      if (values.length === 0) {
        setGlobalLocal({
          regions,
          countries,
          sites: sites.map(d => d.st_site_num),
          business
        });
      } else {
        const siteIds = siteids.filter(d => global.sites.includes(d));
        setGlobalLocal({
          regions: global.regions,
          countries: global.countries,
          sites: siteIds,
          business: global.business,
          siteslist: global.siteslist
        });
      }
    }
  }, [osm_sites_loading, queryParams?.osmglobal, currentSelections]);

  return (
    <Grid container direction="row" data-testid="osmFilterBy" >
      <Grid item sm={6} md={3} lg={2} className={classes.customSpace}>
        {
          <Box alignItems="center" my={2}>
            <Typography variant="body1" className={classes.titles}> {t('region')} </Typography>
            <OsmSelect labelId="region-checkbox-label" testId="region-checkbox" value={region} MenuProps={MenuProps}
              title="Region" data={regions} onChange={setRegion} searchBar={false} updateURLParam={updateURLParam} 
              osm_sites={osm_sites}
              />
          </Box>
        }
      </Grid>
      <Grid item sm={6} md={3} lg={2} className={classes.item}>
        {
          <Box alignItems="center" my={2}>
            <Typography variant="body1" className={classes.titles}> {t('country')} </Typography>
            <OsmSelect labelId="country-checkbox-label" testId="country-checkbox" value={country} MenuProps={MenuProps}
              title="Country" data={countries} onChange={setCountry} searchBar={false} updateURLParam={updateURLParam} 
              osm_sites={osm_sites}
            />
          </Box>
        }
      </Grid>
      <Grid item sm={6} md={3} lg={3} className={classes.item}>
        {
          <Box alignItems="center" my={2}>
            <Typography variant="body1" className={classes.titles}> {t('location')} </Typography>

            <FormControl variant="outlined" className={classesSelect.formControl}>
              <Select
                labelId="location-checkbox-label"
                data-testid="location-checkbox"
                multiple
                value={selectedSites}
                onChange={(event) => handleSelectedSites(event.target.value)}
                input={<Input />}
                displayEmpty={true}
                renderValue={(selected) => {
                  const label = filteredLocations.filter(d => selectedSites.includes(d.st_site_num)).map(d => d.name)
                  return selected.length ? label.join(', ') : "Select Location"
                }}
                MenuProps={MenuProps}
                disableUnderline
                className={classesSelect.mutliSelect}
                onClose={() => setSearchText("")}
              >
                <Typography variant="body1" className={classesSelect.menuTitles}> {t('location')} </Typography>

                <TextField
                  size="small"
                  variant='outlined'
                  autoFocus
                  placeholder="Search Location"
                  fullWidth
                  className={classes.searchLocation}
                  InputProps={{
                    'data-testid': 'locationSearch',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />

                {filteredLocations.length > 0 && (<MenuItem value='all' key='selectall_loations'>
                  <Checkbox
                    checked={allSitesSelected}
                    indeterminate={selectedSites.length > 0 && selectedSites.length < filteredLocations.length}
                    className={classesSelect.checkbox} 
                    inputProps={{ 'data-testid': 'locationcheckbox_selectall' }}
                  />
                  <ListItemText primary={t('select_all')} />
                </MenuItem>)}

                {filteredLocations?.map((item) => (
                  <MenuItem key={item.st_site_num} value={item.st_site_num} className={classesSelect.menuItem} data-testid={`menu-${item.st_site_num}`}>
                    <Checkbox checked={selectedSites.indexOf(item.st_site_num) > -1} className={classesSelect.checkbox}
                      inputProps={{ 'data-testid': `locationcheckbox_${item.st_site_num}` }}
                    />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
      </Grid>
      <Grid item sm={6} md={3} lg={2} className={classes.item}>
        {
          <Box alignItems="center" my={2}>
            <Typography variant="body1" className={classes.titles}> {t('business')} </Typography>
            <OsmSelect labelId="business-checkbox-label" testId="business-checkbox" value={business} MenuProps={MenuProps}
              title="Business" data={BU} onChange={setBusiness} searchBar={false} updateURLParam={updateURLParam} 
              osm_sites={osm_sites}
            />
          </Box>
        }
      </Grid>
      {/* <Grid item className={classes.item}>
          {
            <Box alignItems="center" my={2}>
              <Typography variant="body1" className={classes.titles}> { t('customer_sales_office') } </Typography>
              <CustomerModal osmFilter={true} />
            </Box>
          }
        </Grid> */}
    </Grid>
  )
};

export default OsmGlobalFilters;