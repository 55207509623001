import React, { useState } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment-timezone";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ExpandMore } from '@material-ui/icons';
import { formatDate } from "helpers";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { saveFilters } from "reduxLib/services";

export const useStyles = makeStyles((theme) => ({
  zeroMargin: {margin: "0px !important"},
  datepicker: {
    height: theme.spacing(5),
    width: ({ filterType }) => {
      if(filterType === "daterange") return "100%";
      return 380;
    },
    fontSize: 14,
    backgroundColor: theme.palette?.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: `${theme.spacing(2.5)}px ${theme.spacing(2)}px 0px`,
    border: `1px solid ${theme.palette?.border}`,
    borderRadius: theme.spacing(.75),
    textTransform: 'capitalize',
    '&:hover, &:active' : {
      border: `1px solid ${theme.palette?.border}`,
      backgroundColor: theme.palette?.link?.hover
    },
    [theme.breakpoints.down('sm')]:{
      paddingTop: theme.spacing(.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),  
      width: ({ filterType }) => {
        if(filterType === "daterange") return "100%";
        return 275;
      },
      marginTop: theme.spacing(1),
      "& > span" :{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }
    }
  },
  title: {
    border: `1px solid ${theme.palette?.border}`,
    fontSize: 12,
    position: "absolute",
    top: "-22px",
    left: "5px",
    borderRadius: theme.spacing(.75),
    backgroundColor: theme.palette?.white,
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`
  },
  title2: {
      top: "-15px",
      left: "5px",
      padding: `${theme.spacing(.25)}px ${theme.spacing(.5)}px`
  },
  select: {
    "& .MuiSelect-icon": {
      color: theme.palette?.text.secondary
    }
  },
  smalltext: {
    width: theme.spacing(8)
  },
  applybutton:{
    width: "70%",
    border: `1px solid ${theme.palette?.text.secondary}`,
    textTransform: "capitalize"
  },
  customTitle: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center'
  }
}))

const OsmDateRangePicker = ({ title, type, subtype, filterKey, filterType, customSaveFilters }) => {
  const classes = useStyles({filterType});
  moment.locale(localStorage.getItem('i18nextLng'));
  const dispatch = useDispatch();

  const filters = useSelector(({ options }) => options?.filters?.[subtype]);
  const date = get(filters, `${filterKey}.data`);
  const [dateRange, setDateRange] = useState(date);

  const showTitle = filterType!== "daterange";

  const applyDates = (range) => {
    setDateRange(range);
    const savePayload = {
      filter: {
        [filterKey]: {
          ...filters[filterKey],
          data: range || null
        }
      }, type, subtype
    };
    if (typeof customSaveFilters === "function") {
      customSaveFilters(savePayload);
      return;
    }
    dispatch(saveFilters(savePayload));
  }

  React.useEffect(() => {
    setDateRange(date);
  }, [date])

  return ( 
    <DateRangePicker 
      initialSettings={dateRange}
      onApply={(e, item) => {
        applyDates({
          startTime: item?.startDate.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].startTime : ''}`),
          endTime: item?.endDate.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].endTime : ''}`)
        });
      }}
    >
      <Button 
        data-testid='osmDateRangeFilter'
        disableRipple
        endIcon={<ExpandMore />}
        startIcon={<DateRangeIcon />}
        className={classes.datepicker} 
      >
        {showTitle && <Typography className={classes.title}> {title} </Typography>}
        {formatDate(dateRange?.startTime) || "Start Date"} to {formatDate(dateRange?.endTime) || "End Date"}
      </Button>
    </DateRangePicker>
  );

}

export default OsmDateRangePicker;