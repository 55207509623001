import React from 'react';
import { useTranslation } from "react-i18next"
import { makeStyles, Card, Typography } from '@material-ui/core';
import DNDList from "./DNDList";
import KCButton from "components/common/Button"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    marginTop: 3,
    zIndex: 100,
    width: 350,
    textAlign: 'left',
    borderRadius: 0,
    backgroundColor: theme.palette?.form?.baseLight
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: theme.spacing(5),
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: `1px solid ${theme.palette?.primary.contrastText}`,
    textTransform: 'capitalize',
    textAlign: 'left',
    color: theme.palette?.text?.primary,
    '&:hover, &:active': {
      backgroundColor: theme.palette?.secondary?.focus,
      border: `1px solid ${theme.palette?.chip?.borderActive}`
    }
  },
  alignLeft: {
    textAlign: 'left'
  },
  text: {
    pointerEvents: 'none'
  },
  title: {
    fontSize: 16,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette?.primary.contrastText
  },
  buttongroup: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    display: 'inline-flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette?.secondary?.border}`
  },
  padding: {
    padding: theme.spacing(1),
    display: 'block',
    borderBottom: `1px solid ${theme.palette?.secondary?.border}`,
  },
  height: {
    height: 300,
    overflow: 'auto'
  },
  icon: {
    cursor: 'pointer'
  },
  iconcircle: {
    display: 'block',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    borderRadius: '50%',
    padding: 5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.base,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.base,
    },
  }
}));

export const ColumnsView = ({ openManager, columns, columnsAction }) => {

  const classes = useStyles();
  const { t } = useTranslation();

  const [columnOrder, setColumnOrder] = React.useState(columns || []);

  const isHidden = (hiddenColumnOrder_) => {
    return hiddenColumnOrder_?.filter(d => d.hidden).length === hiddenColumnOrder_.length;
  }

  const [selectall, setSelectAll] = React.useState(isHidden(columnOrder) || false);
  const collength = columns.length || 0;

  React.useEffect(() => {
    setColumnOrder(columns);
    setSelectAll(isHidden(columns))
  }, [columns])

  const setHiddenCols = (e) => {
    const { checked, value } = e.target;
    const updatedColumns = columnOrder.map(d => {
      if (d.field === value) {
        d.hidden = checked;
      }
      return d;
    });
    const hiddenLength = updatedColumns.filter(d => d.hidden).length;

    if (collength === hiddenLength) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setColumnOrder(updatedColumns);
    columnsAction(updatedColumns);
  }

  const unhideAllCols = () => {
    const temp = [];
    const updatedColumns = columnOrder.map(d => {
      if (d.hidden) {
        temp.push(d.field)
      }
      d.hidden = false;
      return d;
    });
    setColumnOrder(updatedColumns);
    columnsAction(updatedColumns);
    return temp;
  }

  const changeOrder = (items, result) => {
    const hidden = unhideAllCols();
    const updatedCols = items.map(d => {
      return {
        ...d,
        hidden: hidden.includes(d.field) || false
      }
    });
    setColumnOrder(updatedCols);
    columnsAction(updatedCols, result);
  }


  const selectAll = (checked) => {
    const updatedColumns = columnOrder.map(d => {
      return {
        ...d,
        hidden: checked
      }
    });
    setSelectAll(checked)
    setColumnOrder(updatedColumns);
    columnsAction(updatedColumns);
  }

  if (!openManager) {
    return null;
  }

  const disabled = (status) => {
    const hiddenLength = columns.filter(d => d.hidden).length;
    if (hiddenLength < collength && hiddenLength > 0 ) {
      return false;
    }
    return status;
  }

  return (
    <Card
      data-testid="columnsview"
      className={classes.root}
      elevation={10}
    >
      <div className={classes.title}>
        <Typography data-testid="manageColumnsTitle"
          className={classes.title}
          component="div"
          variant="h2"
        >{t('manage_columns')}</Typography>
      </div>
      <div className={classes.buttongroup}>
        <KCButton data-testid="showallbutton" className={classes.button} startIcon={<VisibilityIcon />} onClick={() => selectAll(false)} disabled={disabled(!selectall)} >{t('show_all')}</KCButton>
        <KCButton data-testid="hideallbutton" className={classes.button} startIcon={<VisibilityOffIcon />} onClick={() => selectAll(true)} disabled={disabled(selectall)} >{t('hide_all')}</KCButton>
      </div>
      <DNDList items={columnOrder} setHiddenCols={setHiddenCols} changeOrder={changeOrder} />
    </Card >
  )
};

