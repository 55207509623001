import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, Typography, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import moment from "moment-timezone";
import useSetQuery, { useUpdateTabWitOptions } from 'customHooks/useQuery';
import KCChip from 'components/common/Chip';
import CloseIcon from '@material-ui/icons/Close';
import { chipsMain, generateChipsData } from 'helpers/emeaHelpers';
import { useDeepCompareEffect } from 'react-use';
import { useParams, useLocation } from 'react-router-dom';
import { businessUnits } from 'configs/appConstants';

const useStyles = makeStyles((theme) => ({
    root: {},
    chips: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(.5),
        }
    },
    lastupdatedtime: {
        position: "absolute",
        marginTop: theme.spacing(2),
        right: theme.spacing(4),
        [theme.breakpoints.down('sm')]:{
            position: "relative",
            marginTop: 0,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            right: theme.spacing(3),
        }
    },
    label: {
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]:{
            margin: `0px ${theme.spacing(1)}px`,
        }
    },
    chip: {
        borderRadius: theme.spacing(4),
    },
    clearAll: {
        color: theme.palette.blue,
        textDecoration: "underline",
        cursor: "pointer",
        marginTop: theme.spacing(1),
        marginTLeft: theme.spacing(2),
    },
    popper: {
        maxWidth: 300,
        maxHeight: 300,
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        overflow: "auto",
        border: `1px solid ${theme.palette.black}`,
        boxShadow: "0px 7px 7px -2px rgba(0,0,0,0.2)"
    }
}));



const LastUpdatedTime = ({ date }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();

    moment.fn.fromJustNow = function (a) {
        moment.locale(localStorage.getItem('i18nextLng'))
        if (Math.abs(moment().diff(this)) < 15000) {
            return 'just now';
        }
        return this.fromNow(a);
    }

    const [{ BU, Cust, sites, sofcNSG }] = useSetQuery();
    const { tab } = useParams();
    const [timer, setTimer] = React.useState(moment(date).fromJustNow());

    useDeepCompareEffect(() => {
        moment.locale(localStorage.getItem('i18nextLng'))
        setTimer(moment(new Date()).fromJustNow())
    }, [localStorage.getItem('i18nextLng'), BU, Cust, sites, sofcNSG, tab, location.pathname])

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimer(moment(date).fromJustNow())
        }, 30000);
        return () => clearInterval(interval);
    }, [timer]);

    return (
        <Typography data-testid="timer" className={classes.lastupdatedtime}>
            {t("last_updated")} {timer === 'just now' ? t('just_now') : timer}
        </Typography>
    )
}

const ChipsMulti = ({ chips, clearAllChips, clearChip, chipKey }) => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
    }

    return (
        <>
            <KCChip
                deleteIcon={<CloseIcon />}
                className={classes.chip}
                label={`${chips[0].name} + ${chips.length - 1} ${t('more')}`}
                color="primary"
                onDelete={clearAllChips}
                onClick={handleClick}
                variant="outlined"
            />
            <Popper open={open} anchorEl={anchorEl} placement={"bottm-right"} transition>
                {() => (
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <Paper className={classes.popper}>
                            {
                                chips.map(d => {
                                    return <KCChip
                                        deleteIcon={<CloseIcon />}
                                        className={classes.chip}
                                        label={d.name}
                                        color="primary"
                                        onDelete={d.onClose ? () => clearChip({ chip: d, key: chipKey }) : false}
                                        variant="outlined"
                                    />
                                })
                            }
                            <Typography
                                component={"div"}
                                className={classes.clearAll}
                                onClick={() => clearAllChips(chipKey)}
                            >
                                {t("clear_all")}
                            </Typography>
                        </Paper>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    )
}

const EMEAGlobalChips = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { tab } = useParams();
    const [{ BU, sites, Cust, Sofc, sofcNSG, SG, viewby, documentDate }, setParams] = useSetQuery();
    const [chips, setChips] = useState(() => generateChipsData({ sites, Cust, sofcNSG, tab, viewby }));
    const [defaultChips, setDefaultChips] = useState(() => chipsMain({ BU }));
    const updateTabWithOptions = useUpdateTabWitOptions();
    const location = useLocation();
    const pageFromUrl = location?.pathname.split('/')[4];

    useDeepCompareEffect(() => {
        setChips(generateChipsData({ sites, Cust, sofcNSG, viewby, tab, documentDate }))
    }, [sites, Cust, sofcNSG, viewby, tab, documentDate]);

    useDeepCompareEffect(() => {
        setDefaultChips(chipsMain({ BU }))
    }, [BU]);

    // const setMultiQueries = useMultiSetQuery();


    const updateValue = ({ key, value }) => {
        setParams({ key, value });
    }

    const clearAllChips = (key) => {
        switch (key) {
            case "locations": {
                updateTabWithOptions({ key: "sites", value: [] }, {}, [], Cust, sofcNSG)
                return;
            }
            case "customer": {
                updateTabWithOptions({ key: "Cust", value: [] }, {}, sites, [], sofcNSG)
                return;
            }
            case "sales_office": {
                updateTabWithOptions({ key: "Sofc", value: [] }, {
                    SG: JSON.stringify({}),
                    sofcNSG: JSON.stringify([]),
                    selectAllOrgs: undefined,
                }, sites, Cust, []);
                return;
            }
        }
    }

    const clearChip = ({ key, chip }) => {
        switch (key) {
            case "business": {
                updateValue({ key: "BU", value: JSON.stringify(businessUnits.filter(e => e != chip.id)) })
                return;
            }
            case "locations": {
                const filteredSites = sites?.filter(e => e != chip.id)
                updateTabWithOptions({ key: "sites", value: filteredSites }, {}, filteredSites, Cust, sofcNSG)
                return;
            }
            case "customer": {
                const filteredCust = Cust?.filter(e => e != chip.id)
                updateTabWithOptions({ key: "Cust", value: filteredCust }, {}, sites, filteredCust, sofcNSG)
                return;
            }
            case "sales_office": {
                updateSalesOffcies(chip.id)
                return;
            }
            case "document_date": {
                setParams({ key: "documentDate", value: "{}" })
                return;
            }
            case "invoice_date": {
                setParams({ key: "documentDate", value: "{}" })
                return;
            }        
        }
    }

    const updateSalesOffcies = (key) => {
        const sofcsgKeys = Object.keys(SG);
        const isJustSofc = Sofc?.includes(key);
        const sofcKey = key.slice(0, key.lastIndexOf("_"));
        if (isJustSofc) {
            const filteredSofc = Sofc.filter(d => d !== key);
            const filteredSoSg = sofcNSG.filter(d => d !== key);
            // setMultiQueries({
            //     Sofc: JSON.stringify(Sofc.filter(d => d !== key)),
            //     sofcNSG: JSON.stringify(sofcNSG.filter(d => d !== key)),
            // })
            updateTabWithOptions({ key: "Sofc", value: filteredSofc }, {
                sofcNSG: JSON.stringify(filteredSoSg),
                selectAllOrgs: undefined,
            }, sites, Cust, filteredSoSg);
        } else if (sofcsgKeys.includes(sofcKey)) {
            const updatedSofcs = [...Sofc];
            const updatedSofcNSG = [...sofcNSG];

            const sgKey = key.split("_")?.[4];

            let allSG = SG;
            allSG[sofcKey] = allSG[sofcKey].filter(d => d !== sgKey);
            if (allSG[sofcKey].length === 0) {
                updatedSofcs.push(sofcKey);
                updatedSofcNSG.push(sofcKey)
            }
            updateTabWithOptions({ key: "Sofc", value: updatedSofcs }, {
                sofcNSG: JSON.stringify(updatedSofcNSG.filter(d => d !== key)),
                SG: JSON.stringify(allSG),
                selectAllOrgs: undefined,
            }, sites, Cust, updatedSofcs);
            // setMultiQueries({
            //     Sofc: JSON.stringify(updatedSofcs),
            //     sofcNSG: JSON.stringify(updatedSofcNSG.filter(d => d !== key)),
            //     SG: JSON.stringify(allSG)
            // })
        }

    }

    return (
        <>
            <Grid container className={classes.root} data-testid="emea-globalchips">
                <LastUpdatedTime date={new Date()} />
                {
                    Object.keys(defaultChips).map(d => {
                        if (!defaultChips[d]?.length) return null;
                        return (<>
                            <Box alignItems="center" className={classes.chips}>
                                <Typography variant='body2' component={"div"} className={classes.label}>{t(d)}</Typography>
                                {defaultChips[d].map(e => {
                                    return (<KCChip
                                        deleteIcon={<CloseIcon />}
                                        className={classes.chip}
                                        label={e.name}
                                        color="primary"
                                        onDelete={e.onClose ? () => clearChip({ chip: e, key: d }) : false}
                                        variant="outlined"
                                    />
                                    )
                                })}
                            </Box>
                        </>)
                    })
                }
                {
                    Object.keys(chips).map(d => {
                        if (chips[d]?.[0]?.hideOn?.includes(pageFromUrl) || !chips[d]?.length) {
                            return null;
                        }
                        return (<>
                            <Box m={2} alignItems="center" className={classes.chips}>
                                <Typography variant='p' component={"div"} className={classes.label}>{t(d)}</Typography>
                                {        
                                    chips[d].length === 1 && <KCChip
                                        deleteIcon={<CloseIcon />}
                                        className={classes.chip}
                                        label={chips[d][0].name}
                                        color="primary"
                                        onDelete={chips[d][0].onClose ? () => clearChip({ chip: chips[d][0], key: d }) : false}
                                        variant="outlined"
                                    />
                                }
                                {
                                    chips[d].length > 1 && <>
                                        <ChipsMulti
                                            chips={chips[d]}
                                            tab={tab}
                                            viewby={viewby}
                                            clearAllChips={() => clearAllChips(d)}
                                            clearChip={clearChip}
                                            chipKey={d}
                                        />
                                    </>
                                }
                            </Box>
                        </>)
                    })
                }
            </Grid>
        </>
    )
}

export default EMEAGlobalChips;