import React from 'react'
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { StyledTextField, StyledButton } from "components/common/FilterViewsMenu";
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import FavoriteIcon from 'assets/images/favorite.svg';
import DeleteIcon from 'assets/images/delete_dark.svg';
import { useDispatch, useSelector } from 'react-redux';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { generateFilterLayoutTemplate } from 'helpers';
import { deleteEMEAView, favUnfavEMEAView, updateEMEAView } from 'reduxLib/services/EmeaServices/emeaViewsServices';
import { viewTableNames } from 'configs/appConstants';

const useStyles = makeStyles(theme => ({
  viewsGrid: {
    position: 'absolute',
    right: theme.spacing(3.5),
    flexGrow: 1,
    zIndex: 100,
    maxHeight: theme.spacing(70),
    width: theme.spacing(50),
    textAlign: 'left',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette?.card?.border}`,
  },
  addViewText: {
    backgroundColor: theme.palette?.background?.drawer,
    width: '70%',
    marginRight: theme.spacing(1),
  },
  addViewButton: {
    width: '25%',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.border}`,
    backgroundColor: theme.palette?.white
  },
  label: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(.5),
  }
}));

const StyledListItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.base,
    }
  }
}))(MenuItem);

const EmeaViews = ({ open, defaultColumns, tableName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ viewid }] = useSetQuery();
  const multiSetQuery = useMultiSetQuery();

  const [viewName, setViewName] = React.useState("");
  const { views } = useSelector(({ emeaViews: { views } }) => ({ views }));

  const addView = (e) => {
    e.preventDefault();

    const template = generateFilterLayoutTemplate({
      viewName,
      filters: {},
      columns: defaultColumns.columnOrder
    });
    dispatch(updateEMEAView({
      view: template,
      tableName: viewTableNames[tableName],
      updateType: "new"
    }));
    setViewName("");
    multiSetQuery({
      viewid: template.id,
      updateFilters: true
    })
  }

  const changeView = (view) => {
    multiSetQuery({
      viewid: view.id,
      updateFilters: true
    })
  }

  const favView = (view) => {
    dispatch(favUnfavEMEAView({
      viewid: view.id,
      tableName: viewTableNames[tableName],
      isFav: !view.isFav,
      method: view.isFav ? "unfav": "fav"
    }));
  }

  const deleteView = (view) => {
    dispatch(deleteEMEAView({
      viewid: view.id,
      tableName: viewTableNames[tableName]
    }));
  }

  return (
    <div data-testid="viewsContainer" >
      {(open === true) &&
        <Card data-testid="viewsList" className={classes.viewsGrid} elevation={10} >
          <form data-testid="viewsForm" onSubmit={addView} >
            <FormGroup row >
              <StyledTextField
                name="viewname"
                className={classes.addViewText}
                variant="outlined"
                size="small"
                inputProps={{ "data-testid": "save_new_view" }}
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
                placeholder={t('enter_a_view_name')}
                required
              />
              <StyledButton
                className={classes.addViewButton}
                size="small"
                variant="contained"
                data-testid="addview_btn"
                type="submit"
                disabled={!viewName}
              >
                {t('add_view')}
              </StyledButton>
              <Typography variant='body2' className={classes.label}> {t('new_view_name_msg')} </Typography>
            </FormGroup>
          </form>
          {/* { isEmpty(views) ? 
            null :  */}
          {views?.map(view => {
            return (
              <StyledListItem
                key={view.id}
                data-testid={`listid-${view.id}`}
                button
                onClick={() => changeView(view)}
                selected={viewid === view.id}
              >
                <ListItemText primary={view.name} />
                <ListItemSecondaryAction>
                  <IconButton data-testid="favview-btn" edge="end" onClick={() => favView(view)} >
                    <img src={view.isFav ? FavoriteIcon : NotFavoriteIcon} alt="fav" />
                  </IconButton>
                  <IconButton  data-testid="delview-btn"  onClick={() => deleteView(view)} >
                    <img src={DeleteIcon} alt="delete" />
                  </IconButton>
                </ListItemSecondaryAction>
              </StyledListItem>
            )
          })
          }
        </Card>
      }
    </div>
  )
}

export default EmeaViews;