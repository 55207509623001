import { appTheme, appLightTheme } from 'theme';

const tableoptions = {
  detailPanelColumnStyle: {
    width: '3%'
  },
  headerStyle: {
    backgroundColor: appTheme?.palette.card.base,
    color: appTheme?.palette.white,
    borderBottom: `1px solid ${appTheme?.palette.white}`,
    textTransform: "uppercase",
    padding: appTheme?.spacing(2),
    paddingRight: appTheme?.spacing(2),
    paddingLeft: appTheme?.spacing(2),
    textAlign:'left',
    ...appTheme?.typography.body2
  },
  rowStyle: {
      ...appTheme?.typography.body1,
      flexGrow: 1,
      color : appTheme?.palette.white,
  },
  draggable: false,
  sorting: false,
  search: false,
  pageSize: 10,
  showFirstLastPageButtons: true,
  showTitle: false,
  pageSizeOptions: [5, 10, 20, 50, 100],
  toolbar: false,
  maxBodyHeight: "63vh",
}

const shortTitleCellStyle = {
  minWidth: appTheme?.spacing(20),  
}

const mediumTitleCellStyle = {
  minWidth: appTheme?.spacing(30),  
}

const longTitleCellStyle = {
  minWidth: appTheme?.spacing(40),  
}

export const shortOsmCellStyle = {
  minWidth: appTheme?.spacing(20),  
  padding: appTheme?.spacing(1)
}

export const smallOsmCellStyle = {
  minWidth: appTheme?.spacing(10),  
  padding: appTheme?.spacing(1)
}

const stickyColumn = {
  position:"sticky",
  left:0,
  zIndex:14,
  boxShadow:`1px 0px 4px 0px ${appTheme?.palette.divider}`
}

const stickyHeaderCell = {
  ...stickyColumn,
  zIndex:16,
  top:0
}

const stickyBodyCell = {
  ...stickyColumn,
  backgroundColor:appTheme?.palette.primary.base
}

const lightTableOptions = {
  detailPanelColumnStyle: {
    width: '3%'
  },
  headerStyle: {
    backgroundColor: appLightTheme?.palette.background.primaryBase,
    color: appLightTheme?.palette.white,
    borderBottom: `1px solid ${appLightTheme?.palette.white}`,
    textTransform: "uppercase",
    padding: appLightTheme?.spacing(1),
    textAlign:'left',
    ...appLightTheme?.typography.body2
  },
  rowStyle: {
    ...appLightTheme?.typography.body1,
    flexGrow: 1,
    color : appLightTheme?.palette.text.primary,
    backgroundColor: appLightTheme?.palette.table.primaryBase,
    whiteSpace: 'nowrap'
  },
  cellStyle: {
    padding: appLightTheme?.spacing(1),
    minWidth: appLightTheme?.spacing(7.5)
  },
  draggable: false,
  padding: 'dense', 
  sorting: false,
  search: false,
  pageSize: 50,
  showFirstLastPageButtons: true,
  showTitle: false,
  pageSizeOptions: [5, 10, 20, 50],
  toolbar: false,
  maxBodyHeight: "80vh",
  selection: true,
  showSelectAllCheckbox: false
}

const laoTableOptions = {
  detailPanelColumnStyle: {
    width: '3%'
  },
  headerStyle: {
    backgroundColor: appTheme?.palette.secondary.base,
    color: appLightTheme?.palette.white,
    borderBottom: `1px solid ${appTheme?.palette.secondary.base}`,
    textTransform: "uppercase",
    padding: appLightTheme?.spacing(1),
    textAlign:'left',
    ...appLightTheme?.typography.body2
  },
  rowStyle: {
    ...appLightTheme?.typography.body1,
    flexGrow: 1,
    color : appLightTheme?.palette.white,
    backgroundColor: appTheme?.palette.primary.base,
    whiteSpace: 'nowrap'
  },
  cellStyle: {
    padding: appLightTheme?.spacing(3),
    minWidth: appLightTheme?.spacing(7.5),
    borderBottom: `1px solid ${appTheme?.palette.white}`,
  },
  draggable: false,
  padding: 'dense', 
  sorting: false,
  search: false,
  pageSize: 50,
  showFirstLastPageButtons: true,
  showTitle: false,
  pageSizeOptions: [10, 30, 50],
  toolbar: false,
  maxBodyHeight: "60vh",
  selection: true,
  showSelectAllCheckbox: false
}

const detailOptions = {
  headerStyle: {
    backgroundColor: appLightTheme?.palette?.background?.primaryBase,
    color: appLightTheme?.palette?.white,
    textTransform: "uppercase",
    padding: `${appLightTheme?.spacing(.5)}px ${appLightTheme?.spacing(1)}px`,
    textAlign: 'left',
    ...appLightTheme?.typography.body2
  },
  rowStyle: {
    ...appLightTheme?.typography.body1,
    backgroundColor: appLightTheme?.palette?.primary?.primaryBase,
    whiteSpace: 'nowrap',
    flexGrow: 1,
  },
  cellStyle: {
    padding: appLightTheme?.spacing(1)
  },
  loadingType: "linear",
  draggable: false,
  sorting: false,
  search: false,
  paging: false,
  showTitle: false,
  pagination: false,
  toolbar: false,
  padding: 'dense'
}


export {
  tableoptions,
  shortTitleCellStyle,
  mediumTitleCellStyle,
  longTitleCellStyle,
  stickyColumn,
  stickyBodyCell,
  stickyHeaderCell,
  lightTableOptions,
  detailOptions,
  laoTableOptions
}