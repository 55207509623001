export default {
    LOCATION_FETCH_SUCCESS: 'LOCATION_FETCH_SUCCESS',
    LOCATION_FETCH_FAILED: 'LOCATION_FETCH_FAILED',
    PERFORMANCE_FETCH_SUCCESS: 'PERFORMANCE_FETCH_SUCCESS',
    PERFORMANCE_FETCH_FAILED: 'PERFORMANCE_FETCH_FAILED',
    PEFRORMANCE_LOADING: 'PEFRORMANCE_LOADING',
    LOADING: 'LOCATIONS_LOADING',
    CUSTOMER_FETCH_SUCCESS:"CUSTOMER_FETCH_SUCCESS",
    CUSTOMER_FETCH_FAILED: "CUSTOMER_FETCH_FAILED",
    SALESOFC_FETCH_SUCCESS:"SALESOFC_FETCH_SUCCESS",
    SALESOFC_FETCH_FAILED: "SALESOFC_FETCH_FAILED"
}