import { emeaLocationConstants } from "reduxLib/constants/EMEA/emeaLocationConstants";
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import flatMap from 'lodash/flatMap';
import sortBy from "lodash/sortBy";

const initialState = {
  locations_loading: false,
  emea_all_sites: {},
  countries: [],
  locations: [],
  locations_err: null,
  locations_fav_loading: false,
  locations_fav: [],
  locations_fav_err: null
}

const mapCountry = (authSites) => {
  const countries = Object.keys(authSites['EMEA']);
  if (countries?.length)
    return sortBy(countries);
  else return [];
}

const mapLocation = (authSites, countryList, buList) => {
  const emeaObj = authSites['EMEA'];
  let sites = flatMap(pick(emeaObj, countryList));
  sites = sortBy(sites, "st_short_name");

  if (buList?.length === 2 || buList?.length === 0) {
    return sites;
  } else {
    const sitesArr = [];
    sites.map(d => {
      if (d?.businessUnitsList.includes(buList[0]))
        sitesArr.push(d);
    })
    return sitesArr;
  }
}

const emeaLocationsReducer = (state = initialState, action) => {
  switch (action.type) {

    case emeaLocationConstants.EMEA_LOCATIONS_LOADING:
      return { ...state, locations_loading: action.payload };

    case emeaLocationConstants.EMEA_LOCATIONS_SUCCESS:
      return {
        ...state,
        emea_all_sites: action.payload,
        locations_err: action.error
      };

    case emeaLocationConstants.EMEA_MAP_COUNTRY:
      return { ...state, countries: isEmpty(state.emea_all_sites) ? [] : mapCountry(state.emea_all_sites) };

    case emeaLocationConstants.EMEA_MAP_LOCATION:
      return { ...state, locations: isEmpty(state.emea_all_sites) ? [] : mapLocation(state.emea_all_sites, action.payload.countryList, action.payload.BU) };

    case emeaLocationConstants.EMEA_LOCATIONS_FAILED:
      return { ...state, emea_all_sites: {}, countries: [], locations: [], locations_err: action.error };

    case emeaLocationConstants.EMEA_LOCATION_FAV_LOADING:
      return { ...state, locations_fav_loading: action.payload };

    case emeaLocationConstants.EMEA_LOCATION_FAV_SUCCESS:
      return { ...state, locations_fav: action.payload, locations_fav_err: 'success' };

    case emeaLocationConstants.EMEA_LOCATION_FAV_FAILED:
      return { ...state, locations_fav_err: action.error };

    default:
      return state;
  }
}

export default emeaLocationsReducer;  