export const orderHealthReasonCodeMap = {
    VISIBLE_TMS_DELIVERY_BLOCK_SOON_MAD: "order_blocked_and_shipping_soon",
    VISIBLE_TMS_DELIVERY_BLOCK_PAST_MAD: "order_blocked_and_ship_date_past_due",
    NOT_VISIBLE_TMS_SOON_MAD: "order_blocked_and_shipping_soon",
    NOT_VISIBLE_TMS_PAST_MAD: "order_blocked_and_ship_date_past_due",
    PICKUP_NOT_SCHEDULED_SOON_MAD: "pickup_not_scheduled_and_shipping_soon",
    PICKUP_NOT_SCHEDULED_PAST_MAD: "pickup_not_scheduled_and_ship_date_past_due",
    IMMEDIATE_ACTION: "order_blocked_and_immediate_action_needed",
    PICKUP_MULTI_BLOCK_SOON_MAD: "order_blocked_and_shipping_soon",
    PICKUP_MULTI_BLOCK_PAST_MAD: "order_blocked_and_ship_date_past_due",
    NOT_CONFIRM_LESS_X_CUBE_SOON_MAD: "greater_than_1000_confirmed_cube_&_shipping_soon",
    NOT_CONFIRM_LESS_X_CUBE_PAST_MAD: "greater_than_1000_confirmed_cube_&_ship_date_past_due",
    NOT_CONFIRM_0_CUBE_SOON_MAD: "zero_confirmed_cube_&_shipping_soon",
    NOT_CONFIRM_0_CUBE_PAST_MAD: "zero_confirmed_cube_&_ship_date_past_due",
    NOT_CONFIRM_CUBE_SOON_MAD: "not_100%_confirmed",
    STO_SHORT_LEAD: "short_lead_time",
    STO_LESS_PRODUCT: "can_put_more_product_on_trailer",
    STO_HEAVY: "load_too_heavy",
    STO_LARGE: "order_too_big_cannot_fit_on_trailer",
    UNASSIGNED_SHORT_LEAD: "short_lead",
    UNASSIGNED_PAST_DUE_PLANNING: "past_due_for_planning",
    TRANS_OPEN_SHORT_LEAD: "short_lead_or_no_customer_appointment",
    TRANS_OPEN_NOT_ON_TRACK: "not_on_track_to_meet_standard_leadtime",
    TRANS_PROCESS_SHIP_PAST: "shipping_today_or_in_the_past_and_carrier_not_confirmed",
    TRANS_PROCESS_SHIP_SOON: "shipping_soon_and_carrier_not_confirmed",
    TRANS_CARRIER_CONFIRMED_SHIP_PAST: "delivery_document_not_created_and_shipping_today_or_in_the_past",
    TRANS_CARRIER_CONFIRMED_SHIP_SOON: "delivery_document_not_created_and_shipping_soon",
    READY_NOT_PICKED_UP: "past_due_load_not_picked_up",
    INTRANSIT_NOT_DELIVERED: "past_due_delivery_not_confirmed",
    SHIP_NOT_STARTED_WITHIN_X_LRTC: "shipment_not_started_and_LRTC_is_within_12_hours",
    LOAD_NOT_STARTED_LESS_LOAD_HOURS_REM: "not_on_track_to_achieve_LRTC",
    LOAD_NOT_COMP_LESS_LAB_HOURS_REM: "not_on_track_to_achieve_LRTC",
    PAST_DUE_LATE_FOR_ARRIVAL_TIME: "past_due_late_for_arrival_time",
    IN_YARD_MORE_THAN_X_HRS: "in_yard_more_than_72_hrs",
    UNLOADING_TAKING_MORE_THAN_X_HRS: "unloading_taking_more_than_24_hrs"
}