export default {
  TRAILER_DIMENSION_LOADING: "TRAILER_DIMENSION_LOADING",
  TRAILER_DIMENSION_SUCCESS: "TRAILER_DIMENSION_SUCCESS",
  TRAILER_DIMENSION_FAILED: "TRAILER_DIMENSION_FAILED",
  RESET_TRAILER_DIMENSION: "RESET_TRAILER_DIMENSION",

  LOAD_DIAGRAM_LOADING: "LOAD_DIAGRAM_LOADING",
  LOAD_DIAGRAM_SUCCESS: "LOAD_DIAGRAM_SUCCESS",
  LOAD_DIAGRAM_FAILED: "LOAD_DIAGRAM_FAILED",
  RESET_SUBMIT_LOAD_DIAGRAM: "RESET_SUBMIT_LOAD_DIAGRAM",

  PRECHECK_LD_LOADING: "PRECHECK_LD_LOADING",
  PRECHECK_LD_SUCCESS: "PRECHECK_LD_SUCCESS",
  PRECHECK_LD_FAILED: "PRECHECK_LD_FAILED",
  RESET_PRECHECK_LD: "RESET_PRECHECK_LD",

  SEND_TO_WMS_LOADING: "SEND_TO_WMS_LOADING",
  SEND_TO_WMS_SUCCESS: "SEND_TO_WMS_SUCCESS",
  SEND_TO_WMS_FAILED: "SEND_TO_WMS_FAILED",
  RESET_SEND_TO_WMS: "RESET_SEND_TO_WMS",

  DELIVERY_COMMENT_LOADING: "DELIVERY_COMMENT_LOADING",
  DELIVERY_COMMENT_SUCCESS: "DELIVERY_COMMENT_SUCCESS",
  DELIVERY_COMMENT_FAILED: "DELIVERY_COMMENT_FAILED",
  RESET_DELIVERY_COMMENT: "RESET_DELIVERY_COMMENT"
}