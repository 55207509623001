import { optionsConstants } from "../constants"

export const showhideFavourites = variables => dispatch => {
    dispatch({
        type: optionsConstants.SHOW_HIDE_FAVOURITES,
        payload: variables
    })
}

export const saveFilters = variables => dispatch => {
    dispatch({
        type: optionsConstants.SET_FILTERS,
        payload: variables
    })
}

export const resetFilters = variables => dispatch => {
    dispatch({
        type: optionsConstants.RESET_FILTERS,
        payload: variables
    })
}

export const saveColumnState = variables => dispatch => {
    dispatch({
        type: optionsConstants.SAVE_COLUMN_STATE,
        payload: variables
    });
}

export const resetColumnState = variables => dispatch => {
    dispatch({
        type: optionsConstants.RESET_COLUMN_STATE,
        payload: { ...variables, subtype: variables?.tableName }
    });
}

export const setViewBy = variables => dispatch => {
    dispatch({
        type: optionsConstants.SET_VIEW_BY,
        payload: variables
    })
}

export const setTableBodyAction = variables => dispatch => {
    dispatch({
        type: optionsConstants.SET_TABLE_BODY,
        payload: variables
    })
}
