import { emeaTableConstants } from "reduxLib/constants/EMEA/emeaTableConstants";
import fetch from "../serviceHelpers";
import get from "lodash/get";

export const getEmeaTableData = variables => dispatch => {

  const { fetchEndPoint, tablebody, page, pageSize } = get(variables, "body", {});

  dispatch({
    type: emeaTableConstants.EMEA_TABLE_LOADING,
    payload: true,
    variables
  });
  const url = `${fetchEndPoint}?pageSize=${pageSize}&pageNum=${page + 1}`;

  return fetch(url, {
    method: "POST",
    body: tablebody,
    signal: variables?.controller?.signal
  })
    .then((d) => {
      if (d.status === 200) {
        return d.json();
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_failed_to_fetch_table_records";
      }
    })
    .then(d => {
      dispatch({
        type: emeaTableConstants.EMEA_TABLE_SUCCESS,
        payload: d,
        status: {},
        variables
      });
    })
    .catch(err => {
      dispatch({
        type: emeaTableConstants.EMEA_TABLE_FAILED,
        variables,
        payload: {},
        status: {
          status: "error",
          message: err || "emea_failed_to_fetch_table_records"
        },
      });
    })
}
