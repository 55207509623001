import { makeStyles, Link } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    color: 'inherit',
    cursor:"pointer",
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
}));
export const CTLink = ({ children, className, href, target = '_self', title, variant, onClick }) => {
  const classes = useStyles();
  return (
    <Link
      data-testid='ctLink'
      className={clsx(className, classes.root)}
      //innerRef={ref}
      variant={variant}
      target={target}
      title={title}
      href={href}
      onClick={onClick}
      rel={target === '_blank' ? 'noopener' : ''}
    >
      {children}
    </Link>
  );
};
