import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import { StyledTableRow, StyledTableCell } from "../EmeaTopMaterialSKUTable";
import useSetQuery from "customHooks/useQuery";

const useStyles = makeStyles((theme) => ({
  noData: {
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center',
    height: theme.spacing(12.5),
    position: 'relative',
    left: '40vw'
  }
}));

const EmeaCustomTable = ({ subType, title, columnHeaders, footer = true }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loadView }] = useSetQuery();
  const dispatch = useDispatch();
  const [controller] = useState(() => new AbortController());
  const loading = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.loading);
  const data = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.data || []);
  const error = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.error);

  const [kpiData, setKpiData] = useState(data);
  const [kpiTotals, setKpiTotals] = useState([]);

  const {
    locations,
    customers = [],
    sofcs = [],
    BU,
    documentDate
  } = useEMEAGlobalContext();

  const refreshTable = () => {
    if (loadView) return null;

    const payload = {
      region: "EMEA",
      orderType: "ORDER_ENTRY",
      businessUnits: BU,
      subtype: subType,
      sites: locations,
      customerOrSalesOffice: [...customers, ...sofcs],
      documentDate,
      currency: localStorage.getItem("currency") || "USD",
    }
    dispatch(emeaSummaryService({ payload, controller }));
  }

  useDeepCompareEffect(() => {
    refreshTable();
  }, [locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency")]);

  useDeepCompareEffect(() => {
    if (!loading) {
      const totals = [];
      const records = [];

      data.forEach((obj) => {
        (obj?.state === "TOTAL") ? totals.push({...obj, stateDescCustom : t('total')}) : records.push(obj);
      });
    
      setKpiTotals(totals);
      setKpiData(records);
    }
  }, [data, loading])

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <Box mt={5}>
      <Typography variant="body1"> {title} </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="emea custom table" data-testid="emea-custom-table">
          <TableHead>
            <TableRow>
              {columnHeaders.map((element) => 
                <StyledTableCell> { t(element.header) } </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ?
              <div className={classes.noData}> {t('please_wait_while_the_data_is_loading')} </div>
              : error ?
                <div className={classes.noData}> {t(error)} </div>
                : (kpiData?.length === 0) ?
                  <div className={classes.noData}> {t('no_data_found')} </div>
                  : kpiData.map((rowObj, index) => (
                    <StyledTableRow key={index}>
                      {columnHeaders.map((item) =>
                        <StyledTableCell> {(rowObj?.[item.apiKey]) === undefined ? "-" : rowObj[item.apiKey]} {[item.suffix]} </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
          </TableBody>
          {footer && <TableFooter>
            <TableRow>
              {columnHeaders.map((item) =>
                <StyledTableCell> {(kpiTotals[0]?.[item.apiKey]) === undefined ? "-" : kpiTotals[0][item.apiKey]} {[item.suffix]} </StyledTableCell>
              )}
            </TableRow>
          </TableFooter>}
        </Table>
      </TableContainer>
    </Box>
  )

}

export default EmeaCustomTable;