import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import {
    Favorite,
    FavoriteBorderOutlined,
    CheckCircleOutline,
    CheckCircle
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import {
    removeItem,
} from "../../reduxLib/services"
import CustomizedTooltip from "./CustomizedTooltip";
import { CTLink } from './CTLink';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1.25),
        marginBottom: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
        align: 'left',
        size: theme.spacing(2),
        lineHeight: '22px'
    },
    icons: {
        cursor: 'pointer'
    },
    iconSet: {
        color: theme.palette.black
    },
    iconUnset: {
        color: theme.palette.text.secondary,
    },
    icon:{
        verticalAlign: "middle",
        marginLeft: theme.spacing(2)
    },
    primary: {
        color: theme.palette.primary.contrastText
    }
}));



export default ({ data, favElements = [], favClick, unfavClick, handleClick, checkClick, unCheckClick, tick, icon, tooltipTitle, fav, ...rest }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { selfalert } = rest;
            
    // const fav = favElements.includes(data.siteNum) || favElements.includes(data.shortName);

    let service = () => { };

    if (!fav) {
        service = favClick;
    } else {
        service = unfavClick
    }    

    if (!tick) {
         var action = checkClick
    } else {
         var action = unCheckClick
    }

    const clickHandle = () => {
        dispatch(action({ ...data, isFav: fav?.isFav || false }));
    }

    return (
        <Grid className={classes.root} item xs={12} sm={6} md={4} lg={4} data-testid='favtickelement' >
            <Grid container>
                <Grid item xs={8} sm={8} md={9} lg={9}>
                    <Typography variant="h6" className={classes.primary} component="div">
                        <CustomizedTooltip title={tooltipTitle}>
                            <span>
                            {handleClick ? <CTLink onClick={()=> handleClick({open:true,data})}>
                                {data.shortName}
                            </CTLink>:
                            <span data-testid={data.shortName}>
                                {data.shortName }
                            </span>}
                            {icon && <img className={classes.icon} src={icon} /> }
                            </span>
                        </CustomizedTooltip>
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} data-testid="tickIcon" onClick={clickHandle} className={clsx(tick ? classes.iconSet : classes.iconUnset, classes.icons)}>
                    {
                        !tick ? <CheckCircleOutline data-testid="unticked" className={classes.primary} /> : <CheckCircle data-testid="ticked" className={classes.primary} />
                    }
                </Grid>
                {
                    !selfalert && <Grid item xs={2} sm={2} md={1} lg={1} data-testid="favIcon" onClick={() => {
                        dispatch(service({ variables: data }))
                        dispatch(removeItem({ ...data, isFav: fav }))
                    }} className={clsx(fav ? classes.iconSet : classes.iconUnset, classes.icons)}>
                        {
                            !fav ? <FavoriteBorderOutlined data-testid="notfav" className={classes.primary} /> : <Favorite data-testid="fav" className={classes.primary} />
                        }
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

