export default {
  OSM_VIEW_LSR_SUCCESS: 'OSM_VIEW_LSR_SUCCESS',
  OSM_VIEW_LSR_FAILED: 'OSM_VIEW_LSR_FAILED',
  OSM_VIEW_LSR_LOADING: 'OSM_VIEW_LSR_LOADING',

  OSM_VIEW_STACK_DETAILS_SUCCESS: 'OSM_VIEW_STACK_DETAILS_SUCCESS',
  OSM_VIEW_STACK_DETAILS_FAILED: 'OSM_VIEW_STACK_DETAILS_FAILED',
  OSM_VIEW_STACK_DETAILS_LOADING: 'OSM_VIEW_STACK_DETAILS_LOADING',

  OSM_LD_DOWNLOAD_LOADING: 'OSM_LD_DOWNLOAD_LOADING',
  OSM_LD_DOWNLOAD_SUCCESS: 'OSM_LD_DOWNLOAD_SUCCESS',
  OSM_LD_DOWNLOAD_FAILED: 'OSM_LD_DOWNLOAD_FAILED',
  OSM_LD_DOWNLOAD_RESET: "OSM_LD_DOWNLOAD_RESET"
}