import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { Typography, IconButton, makeStyles, Box, useMediaQuery, useTheme, InputBase, ClickAwayListener, Grid, CardHeader, CardContent, Paper } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import { FilterList as FilterListIcon } from '@material-ui/icons';
import KCCheckBox from './Elements/KCCheckbox';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        zIndex: 100,
        borderRadius: '0 !important',
        width: 300,
        right: 0,
        [theme.breakpoints.down('sm')]: {
            right: -150,
        },
        justifyContent: 'left',
        textAlign: 'left',
        backgroundColor: theme.palette.secondary.dark,
        height: 'fit-content',
        textTransform: "none"
    },
    header: {
        backgroundColor: theme.palette.secondary.base,
        paddingBottom: theme.spacing(2),
        fontFamily: 'Gilroy',

    },
    active: {
        backgroundColor: theme.palette.secondary.dark,
        padding: theme.spacing(1)
    },
    inactive: {
        padding: theme.spacing(1)
    },
    legends: {
        display: 'inline-flex',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        flexFlow: `row nowrap`,
        paddingRight: theme.spacing(2),
        border: `1px solid ${theme.palette.border}`,
        fontFamily: 'Gilroy',
    },
    icons: {
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(3)
        }
    }
}));

export const performanceConstants = {
    HEALTHY: 'healthy',
    MONITOR: 'monitor',
    BEHIND: 'behind',
    CRITICAL: 'critical',
    NODATA: 'no_data'
}

const PerformanceFitlers = ({ data, setData, filters, updateFilters, ...rest }) => {

    const { open, handleClick, handleClickAway, perfData, search, searchKey } = rest;
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
    const classes = useStyles();
    const { t } = useTranslation();

    const filterData = (activeFilters) => {
        let appliedFilterValues = activeFilters.reduce((valueArray, item) =>
            (item.checked === true) ? [...valueArray, item.value] : [...valueArray], [])

        const filterdLocations = !isEmpty(appliedFilterValues) ? perfData.filter((site) => appliedFilterValues.includes(site.status)) : [];
        if (!isEqual(filterdLocations, data)) {
            if (search) {
                const filtereddata = filterdLocations.filter(d => {
                    return searchKey(search, d)
                });
                if(!isEqual(filtereddata, data)) {
                    setData(filtereddata)
                }
            } else {
                setData(filterdLocations)
            }
        }
    }

    const setfilter = (target) => {
        const activeFilters = filters.map((d) => target.value == d.value ? { ...d, checked: target.checked } : d);
        updateFilters(activeFilters);
        filterData(activeFilters)
    };

    useEffect(() => {
        filterData(filters);
    }, [data]);

    return (
        <Box textAlign='center' aria-label="Filter map" color="primary">
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.root}>
                    <IconButton onClick={handleClick} className={open ? classes.active : classes.inactive} data-testid='filtericon'>
                        <FilterListIcon />
                    </IconButton>
                    {!isMobile && <Typography variant="body2">{t('filters')}</Typography>}
                    {open ? (
                        <Paper classes={{
                            root: classes.dropdown,
                            content: classes.content
                        }} elevation={10}>
                            <CardHeader
                                className={classes.header}
                                title={<Typography variant="h3">
                                    {t('filter_by')}
                                </Typography>}
                            />
                            {filters.map((obj, index) => (
                                <Grid item container className={classes.legends} >
                                    <KCCheckBox
                                        color="primary"
                                        name={obj.name}
                                        inputProps={{ "data-testid": `filtercheckbox_${index}` }}
                                        // data-testid="filtercheckbox"
                                        onChange={(e) => {
                                            setfilter(e.target);
                                        }}
                                        value={obj.value}
                                        checked={obj.checked}
                                    />
                                    <Grid item>
                                        <Typography variant="subtitle1" className={classes.title}>
                                            {obj.name}
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.title}>
                                            {obj.legend}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.icons}>
                                        <img src={obj.iconurl} fontSize="medium" />
                                    </Grid>
                                </Grid>
                            ))}
                        </Paper>
                    ) : null}
                </div>
            </ClickAwayListener>
        </Box>
    );
}

export default PerformanceFitlers;


