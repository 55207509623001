import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationsFilterLayout from 'components/header/LocationsFilterLayout';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    display: "flex",
    justifyContent: "space-between",
    width: theme.spacing(27),
    height: theme.spacing(5),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(.5),
    color: theme.palette?.text?.primary,
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]:{
      width: theme.spacing(13.25),
    }
  },
  box: {
    margin: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
    [theme.breakpoints.down('sm')]:{
      margin: `0px ${theme.spacing(.5)}px`,
    }
  }
}))

const EmeaFilterBy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const regionFromUrl = location?.pathname.split('/')[2];
  
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <>
      <Grid container className={classes.root} data-testid="emea-filterby">
        <Box alignItems="center" className={classes.box}>
          <Button disableRipple className={classes.filterButton} onClick={handleClick} endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />} data-testid="filterby-button" >
            {t('filter_by')}
          </Button>
        </Box>
      </Grid>
      <LocationsFilterLayout open={open} handleClick={handleClick} region={regionFromUrl} />
    </>
  )
}

export default EmeaFilterBy;