import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { CTLink } from "components/common/CTLink"
import FavoriteIcon from 'assets/images/favorite.svg';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.tooltip?.base,
    textDecoration: 'underline',
    marginRight: theme.spacing(.5),
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  groupGrid: {
    maxHeight: 315,
    overflowX: 'auto',
    '& >div:not(:nth-child(3n))': {
      borderRight: `1px solid ${theme.palette.border}`
    },
    [theme.breakpoints.down("md")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      },
      '& >div:nth-child(odd)': {
        borderRight: `1px solid ${theme.palette.border}`
      }
    },
    [theme.breakpoints.down("sm")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      }
    }
  },
  groups: {
    margin: `${theme.spacing(.5)}px 0px`,
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0px ${theme.spacing(3)}px`,
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.secondary?.pressed
    }
  },
  allLocations: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: `${theme.spacing(.5)}px 0px`,
    border: 'none !important'
  },
}))

const EMEASalesGroups = ({ setSalesGroupOpen, salesOrg, salesOffice, updateSelections, selected, favList, updateFavList, loading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedSalesGrp, setSelectedSalesGrp] = useState(selected);
  const [selectAllSalesGrp, setSelectAllSalesGrp] = useState(false);

  useEffect(() => {
    setSelectedSalesGrp(selected);
    if (selectedSalesGrp === salesOffice.salesGroupList) {
      setSelectAllSalesGrp(true);
    }
    if (!selected?.length) {
      setSelectAllSalesGrp(false);
    }
  }, [selected]);

  const handleSalesGrpSelect = (event, group) => {
    let filtered = [];
    if (selectedSalesGrp.includes(group)) {
      filtered = selectedSalesGrp.filter((sg) => sg !== group);
      setSelectAllSalesGrp(false);      
    } else {
      filtered = [...selectedSalesGrp, group];
      if (isEqual(salesOffice?.salesGroupList.sort(), ([...selectedSalesGrp, group].sort()))) {
        setSelectAllSalesGrp(true);
      }
    }
    setSelectedSalesGrp(filtered);
    updateSelections({
      [salesOffice.salesOfficeKey]: filtered
    });
  }

  const handleAllSalesGroups = (e) => {
    if (e.target.checked) {
      setSelectedSalesGrp(salesOffice.salesGroupList);
      updateSelections({
        [salesOffice.salesOfficeKey]: salesOffice.salesGroupList
      });
      setSelectAllSalesGrp(true)
    } else {
      setSelectedSalesGrp([])
      updateSelections({
        [salesOffice.salesOfficeKey]: []
      });
      setSelectAllSalesGrp(false)
    }
  }

  const handleSGFav = (event, sg) => {
    const key = salesOffice.salesOfficeKey + '_' + sg;
    if(favList.includes(key)) {
      const z = favList.filter(x => x !== key);
      updateFavList(z);
      if (selectedSalesGrp.includes(sg)) {
        const k = selectedSalesGrp.filter((d) => d !== sg);
        setSelectedSalesGrp(k);
        updateSelections({
          [salesOffice.salesOfficeKey]: k
        });
      }
    } else {
      updateFavList([...favList, key]);
      if (selectedSalesGrp.includes(sg) === false) {
        const z = [...selectedSalesGrp, sg]
        updateSelections({
          [salesOffice.salesOfficeKey]: z
        });
      }
    }
  }

  return (
    <>
      <Box data-testid='SGListing' mb={1} >
        <CTLink className={classes.link} onClick={() => setSalesGroupOpen(false)}>
          {`${t('sales_office')} ${salesOffice?.salesOffice} (${salesOrg}) `}
        </CTLink>
        <Typography component='span' >{` > ${t('sales_groups')}`}</Typography>
      </Box>
      <Grid container sm={12} md={12} lg={12} className={classes.allLocations} data-testid="select-all-sales-grps">
          <Grid item>
            <Checkbox name='selectAllSalesGrp' checked={selectAllSalesGrp} onChange={handleAllSalesGroups} className={classes.checkbox}
              inputProps={{'data-testid': 'select-all-sales-grp'}} />                              
          </Grid>          
          <Typography variant='body1'>{t('select_all')}</Typography> 
        </Grid>
      <Grid container className={classes.groupGrid}>        
        {salesOffice?.salesGroupList?.length ? salesOffice?.salesGroupList.map(grp => {
          return (
            <Grid container item sm={12} md={6} lg={4} className={classes.groups}>
              <Typography variant='body1'> {grp} </Typography>
              <Grid item>
                <Checkbox
                  name={grp}
                  checked={selectedSalesGrp.indexOf(grp) > -1}
                  onChange={(e) => handleSalesGrpSelect(e, grp)}
                  className={classes.checkbox}
                  inputProps={{ 'data-testid': `select_${grp}` }}
                />
                <IconButton aria-label="fav-button" onClick={(e) => handleSGFav(e, grp)} disabled={loading} data-testid={`fav-${salesOffice.salesOfficeKey}_${grp}`} >
                  <img alt="fav-icon" src={favList.includes(`${salesOffice.salesOfficeKey}_${grp}`) ? FavoriteIcon : NotFavoriteIcon} />
                </IconButton>
              </Grid>
            </Grid>
          )
        }) : null
        }
      </Grid>
    </>
  )
}

export default EMEASalesGroups;