import { emeaSummaryConstants } from "reduxLib/constants/EMEA/emeaSummaryConstants";

const initialState = {}

const emeaSummaryReducer = (state = initialState, action) => {

  const { payload, subtype, error } = action;
  switch (action.type) {
    case emeaSummaryConstants.EMEA_SUMMARY_LOADING:
      return {
        ...state,
        [subtype]: {
          ...state[subtype],
          loading: payload,
          error: error
        }
      };

    case emeaSummaryConstants.EMEA_SUMMARY_SUCCESS:
    case emeaSummaryConstants.EMEA_SUMMARY_FAILED:
      return {
        ...state,
        [subtype]: {
          ...state[subtype],
          data: payload || [],
          loading: false,
          error: error
        }
      };

    default:
      return state;
  }
}

export default emeaSummaryReducer;  