import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationsFilterLayout from 'components/header/LocationsFilterLayout';

const useStyles = makeStyles((theme) => ({
  laoFilterButton: {
    display: "flex",
    justifyContent: "space-between",
    width: theme.spacing(27),
    height: theme.spacing(5),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: theme.spacing(.5),
    color: theme.palette?.text?.primary,
    textTransform: 'capitalize'
  }
}))

const LaoFilterBy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const urlLocation = useLocation();
  const getRegion = urlLocation?.pathname.split('/')[2];
  
  const [open, setFilterBoxOpen] = useState(false);

  const handleBtnClick = () => {
    setFilterBoxOpen(!open);
  }

  return (
    <>
      <Grid container className={classes.root} data-testid="lao-filterby">
        <Box m={2} alignItems="center">
          <Button disableRipple className={classes.laoFilterButton} onClick={handleBtnClick} endIcon={!open ? <ExpandMoreIcon /> : <ExpandLessIcon />} data-testid="filterby-button" >
            {t('filter_by')}
          </Button>
        </Box>
      </Grid>
      <LocationsFilterLayout open={open} handleClick={handleBtnClick} region={getRegion} />
    </>
  )
}

export default LaoFilterBy;