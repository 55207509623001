import React, { useState } from "react";
import { makeStyles, FormControl } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { uniq } from "lodash";
import BootstrapInput from "./BootstrapInput";

const useStyles = makeStyles((theme) => ({
    formelement: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(.5)
    }
}));


export default function EmailMulti({ updatedMails, emaildata }) {

    const classes = useStyles();
    const [val, setVal] = useState(emaildata || []);
    const [inputvalue, setInput] = useState("");

    React.useEffect(() => {
        setVal(emaildata)
    }, [emaildata])

    const inputprops = {
        variant: "outlined",
        required: true,
        type: "email",
        value: inputvalue,
        input: <BootstrapInput />,
        size: "small",
        placeholder: "Enter valid email ids",
        onChange: ({ target: { value } }) => {
            setInput(value)
        }
    }

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            const mails = uniq([...val, inputvalue]);
            setVal(mails);
            updatedMails(mails)
            setInput("")
        }} data-testid="emailmulti">
            <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                    inputProps={{ "data-testid": "emailmulti-text" }}
                    required
                    {...inputprops}
                />
            </FormControl>
        </form>
    );
}
