export const emeaFavoriteConstants = {
  EMEA_FAVORITES_LOADING: 'EMEA_FAVORITES_LOADING',
  EMEA_FAVORITES_SUCCESS: 'EMEA_FAVORITES_SUCCESS',
  EMEA_FAVORITES_FAILED: 'EMEA_FAVORITES_FAILED',

  EMEA_UPDATE_FAVORITES: 'EMEA_UPDATE_FAVORITES',

  EMEA_BU_FAVORITES_LOADING: 'EMEA_BU_FAVORITES_LOADING',
  EMEA_BU_FAVORITES_SUCCESS: 'EMEA_BU_FAVORITES_SUCCESS',
  EMEA_BU_FAVORITES_FAILED: 'EMEA_BU_FAVORITES_FAILED',
}