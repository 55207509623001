import React from "react";
import NextArrowIcon from "../assets/images/next_arrow_dark.svg";
import PreviousArrowIcon from "../assets/images/prev_arrow_dark.svg";


export const pwaSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <img src={PreviousArrowIcon} />,
    nextArrow: <img src={NextArrowIcon} />,
  };