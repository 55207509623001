import { laoLocationConstants } from "reduxLib/constants/LAO/laoLocationConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const laoGetLocationsService = variables => dispatch => {
    dispatch({
        type: laoLocationConstants.LAO_LOCATIONS_LOADING,
        payload: true
    });

    return fetch(endpoints.lao.locations, {
        method: "GET",
        signal: variables.controller.signal
    })
        .then(d => {
            if (d.status === 200) {
                return d.json();
            } else if (d.status === 401) {
                throw "lao_restricted";
            } else {
                throw "lao_sites_failed";
            }
        })
        .then(d => {
            dispatch({
                type: laoLocationConstants.LAO_LOCATIONS_SUCCESS,
                payload: d,
                error: null
            });
            dispatch({
                type: laoLocationConstants.LAO_LOCATIONS_LOADING,
                payload: false,
            })
        })
        .catch(err => {
            if (err?.name === "AbortError") {
                return;
            }
            dispatch({
                type: laoLocationConstants.LAO_LOCATIONS_FAILED,
                payload: {},
                error: err,
            });
            dispatch({
                type: laoLocationConstants.LAO_LOCATIONS_LOADING,
                payload: false,
            });
        })
}