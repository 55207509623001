import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';


const customTooltipStyles = makeStyles((theme) => ({
  arrow: {
      color: theme.palette.white,
  },
  tooltip: {
      backgroundColor: theme.palette.white,
      color: 'black',
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${theme.palette.iron}`,
  },
}));


const CustomizedTooltip = (props) => {
  const classes = customTooltipStyles()
  const { title, children } = props;
  return (
    <div data-testid="tooltipBody">
      {children && <Tooltip title={title} disableHoverListener={title ? false : true} classes={classes} interactive arrow placement="top" enterTouchDelay='10'>
        {children}
      </Tooltip>
      }
    </div>
  );
}

export default CustomizedTooltip;