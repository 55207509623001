export const formatColumnData = (chartsData, keys, type) => {
    let data = [];
    const item = chartsData?.find(d => d.state === type) || {};

    keys.map((key) => {
        data.push({
            name: key.name,
            redCount: item[key.red] || 0,
            blueCount: (item[key.green]) || 0,
            totalCount: item[key.total] || 0,
            state: `${type}_${key.name}`
        });
    })

    return data;
}

export const columnKeys = [
    { red: "redCountSO", green: "greenCountSO", total: "totalCountSO", name: "CUST" },
    { red: "redCountSTO", green: "greenCountSTO", total: "totalCountSTO", name: "STO" },
];

export const rfpColumns = [
    {
        red: "redCountCustPickUp", green: "greenCountCustPickUp", total: "totalCountCustPickUp", name: "Cust Pickup",
    },
    ...columnKeys
]