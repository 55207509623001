import React from "react";
import BarChart from "components/D3Charts/BarChart";

export const DeliveryDetailsChartBuilder = (props) => {

    const { margins, height, type, subtype, chartsData, keys, setDetails } = props
    
    const boxMargins = { ...margins, left: 100 }
    const xKey = "stateDesc";

    const barData = chartsData ? chartsData.map(d => {
        return {
            ...d,
            name: d[xKey],
            value: d.totalCount,
            blueCount: d.greenCount
        }
    }) : [];

    return (
        <div data-testid="transportDeliveryStatus" >
            <BarChart id={subtype} data={barData} keys={keys} xKey={xKey} margins={boxMargins} height={height} horizontal type={type} subtype={subtype} setDetails={setDetails} />
        </div>
    );
}
