import { analyticsConstants } from "../constants"

const analyticsReducer = (state = { loading: false, cstUpdated: null, reportUrl: null }, action) => {

  switch (action.type) {
    case analyticsConstants.UPDATE_CST_RANK:
      return { ...state, cstUpdated: true };

    case analyticsConstants.UPDATE_FAILED:
      return { ...state, cstUpdated: action.payload }

    case analyticsConstants.UPDATE_LOADING:
      return { ...state, loading: action.payload }

    case analyticsConstants.UPDATE_REPORT_URL:
      return { ...state, reportUrl: action.payload }

    default:
      return state
  }
}

export default analyticsReducer;