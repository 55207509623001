export default {
    GET_FAVS_SUCCESS: "GET_FAVS_SUCCESS",
    GET_FAVS_FAILED: "GET_FAVS_FAILED",
    LOADING: "LOADING",
    MARK_FAV_SUCCESS: "MARK_FAV_SUCCESS",
    MARK_UNFAV_SUCCESS: "MARK_UNFAV_SUCCESS",
    MARK_FAV_FAILED: "MARK_FAV_FAILED",
    MARK_UNFAV_FAILED: "MARK_UNFAV_FAILED",
    RESET_FAV_UNFAV_MARKING: "RESET_FAV_UNFAV_MARKING",
    MARK_LOADING: "MARK_LOADING",
    ADD_BUSINESS_UNIT_SUCCESS: "ADD_BUSINESS_UNIT_SUCCESS",
    ADD_BUSINESS_UNIT_FAILED: "ADD_BUSINESS_UNIT_FAILED",
    ADD_BUSINESS_UNIT_LOADING: "ADD_BUSINESS_UNIT_LOADING",
    SET_BUSINESS_UNIT_LOCAL: "SET_BUSINESS_UNIT_LOCAL",
    GET_FAV_CUSO: "GET_FAV_CUSO",
    FAV_CUSO_LOADING: "FAV_CUSO_LOADING",
    FAV_CUSO_SUCCESS: "FAV_CUSO_SUCCESS",
    FAV_CUSO_FAILED: "FAV_CUSO_FAILED"
}