import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";

export const OSMProductDetailsColumns = {
  columnOrder: [
    { title: 'sl_no', field: 'slno', emptyValue: "-" },
    {
      title: 'material_id', field: "materialNum",  
      render: rowData => {
        return <CustomCellRender id="materialNum" value={rowData.materialNum} />
      }
    },{
      title: 'customer_material_id', field: "custMaterialNum",  
      render: rowData => {
        return <CustomCellRender id="custMaterialNum" value={rowData.custMaterialNum} />
      }
    },{
      title: 'material_group_num', field: "materialGroupNum",  
      render: rowData => {
        return <CustomCellRender id="materialGroupNum" value={rowData.materialGroupNum} />
      }
    },{
      title: 'material_description', field: "itemDesc",  
      render: rowData => {
        return <CustomCellRender id="itemDesc" value={rowData.itemDesc} />
      }
    // },{
    //   title: 'order_num', field: "netOrderValue",  
    //   render: rowData => {
    //     return <CustomCellRender id="netOrderValue" value={rowData.netOrderValue} />    // to be checked
    //   }
    },{
      title: 'ordered_qty', field: "orderedQty",  
      render: rowData => {
        return <CustomCellRender id="orderedQty" value={rowData.orderedQty} />
      }
    },{
      title: 'confirmed_qty', field: "confirmedQty",  
      render: rowData => {
        return <CustomCellRender id="confirmedQty" value={rowData.confirmedQty} />
      }
    },{
      title: 'load_qty', field: "loadedQty",  
      render: rowData => {
        return <CustomCellRender id="loadedQty" value={rowData.loadedQty} />
      }
    },{
      title: 'difference_qty', field: "differenceQty",  
      render: rowData => {
        return <CustomCellRender id="differenceQty" value={rowData.differenceQty} />
      }
    },{
      title: 'delivery_block', field: "deliveryBlockCode",  
      render: rowData => {
        return <CustomCellRender id="deliveryBlockCode" value={rowData.deliveryBlockCode} />
      }
    },{
      title: 'mad', field: "matAvailDate",  
      render: rowData => {
        return <CustomCellRender id="matAvailDate" value={rowData.matAvailDate} />
      }
    // },{
    //   title: 'order_num', field: "itemPosNum",  
    //   render: rowData => {
    //     return <CustomCellRender id="itemPosNum" value={rowData.itemPosNum} />        // to be checked
    //   }
    // },{
    //   title: 'order_num', field: "qtyDiscrepancy",  
    //   render: rowData => {
    //     return <CustomCellRender id="qtyDiscrepancy" value={rowData.qtyDiscrepancy} />    // to be checked
    //   }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}