import React from 'react';
import moment from "moment-timezone";
import { shortTitleCellStyle, longTitleCellStyle } from 'helpers/tableStyleOverride';
import { formatToSentenceCase, msToHMS } from "helpers";
import { get, isEmpty, isArray } from "lodash";
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import CustomRender from "../../components/common/CustomRender";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";
import CustomCellRender from "../../components/common/CustomCellRender";

export const defaultOutboundColumns = {
  columnOrder: [
    {
      title: 'shipment_#', field: "shipmentNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },
    {
      title: 'delivery_#', field: "deliveryNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    {
      title: 'shipment_status', field: "orderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderExecutionBucketDesc" value={rowData.orderExecutionBucketDesc} />
      }
    },
    {
      title: 'order_#', field: "orderNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <PromotionalOrder data={rowData} />
      }
    },
    {
      title: 'promotionalOrder', field: "promotionalOrder", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="promotionalOrder" value={rowData.promotionalOrder} />
      }
    },
    {
      title: 'collectiveNumber', field: "collectiveNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
      }
    },
    {
      title: 'pickup_appt_date_&_time', field: "loadReadyDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadReadyDateTime" value={rowData.loadReadyDateTime} />
      }
    },
    {
      title: 'load_start_date_time', field: "loadingStartDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadingStartDateTime" value={rowData.loadingStartDateTime} />
      }
    },
    {
      title: 'estd_load_hrs_rem', field: "remainingLoadTimeMS", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="remainingLoadTimeMS" value={rowData.remainingLoadTimeMS} />
      }
    },
    {
      title: 'total_load_hrs', field: "estimatedLoadTimeMS", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="estimatedLoadTimeMS" value={rowData.estimatedLoadTimeMS} />
      }
    },
    {
      title: 'customer', field: "customer", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="customer" value={rowData.customer} />
      }
    },
    {
      title: 'origin_id', field: "originId",
      render: rowData => {
        return <CustomCellRender id="originId" value={rowData.originId} />
      }
    },
    {
      title: 'origin', field: "origin", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="origin" value={rowData.origin} />
      }
    },
    {
      title: 'order_status', field: "orderStatus", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderStatus" value={rowData.orderStatus} />
      }
    },
    {
      title: 'order_type', field: 'orderType',
      render: rowData => {
        return <CustomCellRender id="orderType" value={rowData.orderType} />
      }
    },
    {
      title: 'shipping_condition', field: "shippingCondition", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
      }
    },
    {
      title: 'live_load', field: "liveLoadInd",
      render: rowData => {
        return <div data-testid="liveLoadInd"><CustomRender value={rowData.liveLoadInd} /></div>
      }
    },
    {
      title: 'carrier', field: "tariffServiceCode",
      render: rowData => {
        return <CustomCellRender id="tariffServiceCode" value={rowData.tariffServiceCode} />
      }
    },
    {
      title: 'dest_city', field: "destinationCity",
      render: rowData => {
        return <CustomCellRender id="destinationCity" value={rowData.destinationCity} />
      }
    },
    {
      title: 'dest_state', field: "destinationState",
      render: rowData => {
        return <CustomCellRender id="destinationState" value={rowData.destinationState} />
      }
    },
    {
      title: 'business', field: "orderBusinessUnit",
      render: rowData => {
        const codeString = isArray(rowData?.orderBusinessUnit) ? rowData?.orderBusinessUnit?.map((bu) => <div>{bu}</div>) : rowData?.orderBusinessUnit;
        return <div data-testid="orderBusinessUnit">{!isEmpty(codeString) ? codeString : "-"}</div> ;
      }
    },
    {
      title: 'reason_for_alert', field: "reasonCodeString", cellStyle: longTitleCellStyle,
      render: rowData => {
        const codeString = rowData?.orderExecutionReasonCodes?.map((code) => <div><CustomRender value={orderHealthReasonCodeMap[code]}/></div>);
        return <div data-testid="reasonCodeString">{!isEmpty(codeString) ? codeString : "-"}</div> ;
      }
    }

      ],
    columnConfiguration: (d) => {
      return {
        origin: d?.orderOrigin?.name ? get(d, "orderOrigin.name", "-") : "-",
        originId: get(d, "orderOrigin.id", "-"),
        liveLoadInd: d.liveLoadInd ? d.liveLoadInd === "Y" ? "yes" : "no" : "-",
        // appointmentRequired: d.appointmentRequired ? d.appointmentRequired === "Y" ? "yes" : "no" : "-",
        // orderOnHoldInd: d.orderOnHoldInd ? d.orderOnHoldInd === "Y" ? "yes" : "no" : "-",
        // deliverApptDateTime: d?.deliverApptDateTime ? moment(d.deliverApptDateTime)?.tz(d?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
        loadingStartDateTime: d?.loadingStartDateTime ? moment(d?.loadingStartDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
        loadReadyDateTime: d?.loadReadyDateTime ? moment(d?.loadReadyDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
        customer: formatToSentenceCase(get(d, "orderDestination.name", "-")),
        destinationCity: formatToSentenceCase(get(d, "orderDestination.city", "-")),
        destinationState: get(d, "orderDestination.state", "-"),
        remainingLoadTimeMS: msToHMS(parseInt(get(d, "remainingLoadTimeMS", 0)), d.remainingLoadTimeMS),
        estimatedLoadTimeMS: msToHMS(parseInt(get(d, "estimatedLoadTimeMS", 0)), d.estimatedLoadTimeMS),
        shippingCondition: get(d, "shippingCondition", "-"),
        reasonCodeString: d?.orderExecutionReasonCodes?.reduce((codeString, code, index) => index === (d.orderExecutionReasonCodes.length - 1) ? `${codeString} ${orderHealthReasonCodeMap[code]}` : `${codeString} ${orderHealthReasonCodeMap[code]} |`, "") || "-"
    }
  }
}

