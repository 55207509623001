import React from 'react';
import {
  Typography,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  Button,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { selectStyles } from 'theme';

const useStyles = makeStyles(selectStyles);

const useCustomStyles = makeStyles(theme => ({
  button: {
    width: theme.spacing(33),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    textTransform: 'capitalize',
    textAlign: 'left',
    height: theme.spacing(5),
  },
  menuList: {
    backgroundColor: theme.palette.secondary.base,
    color: theme.palette.white,
  }
}))

export const Select = (props) => {
  const { onChange, label, options, customClasses, disableLabel=false, disabled, data, testId } = props;
  const classes = useStyles();
  const ButtonNewclasses = useCustomStyles();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value);
  const [name, setName] = React.useState(`${label}`);
  const anchorRef = React.useRef(null);

  React.useEffect(() => {
    setValue(props.value);
    options.map(d => {
      if (props.value === d.value) {
        setName(d.name)
      }
    })
  }, [props.value, localStorage.getItem('i18nextLng')])

  const handleToggle = () => {
    setOpen((prevOpen1) => !prevOpen1);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return (
    <div className={classes.root} ref={anchorRef} data-testid="selectElement">
      {(!isMobile && !disableLabel) && (
        <div name="toplabel-element" className={classes.toplabel}>
          {label || 'Select'}
        </div>
      )}
      <Button data-testid={label}
        className={customClasses?.button ? customClasses.button : classes.button}
        aria-controls={open ? 'menu-list-grow' : undefined}
        endIcon={!open ? <ExpandMore /> : <ExpandLess />}
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleToggle}>
        <Typography data-testid={testId} data-value={data} className={classes.text}>{name}</Typography>
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'bottom', zIndex: 999 }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                keepMounted
                autoFocusItem={open}
                id="menu-list-grow"
                className={customClasses?.button ? ButtonNewclasses.menuList : classes.menuList}>
                {options.map((d) => {
                  return (
                    <MenuItem
                      data-testid={d.name}
                      name="menuitem"
                      key={d.value}
                      className={classes.menuItem}
                      selected={value === d.value}
                      onClick={() => {
                        onChange(d.value);
                        setName(d.name);
                        handleClose();
                      }}>
                      {d.name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Select;
