import React, { useState } from 'react';
import {
    makeStyles,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconName } from 'theme/layouts/TabsBar';
import useSetQuery from 'customHooks/useQuery';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
    root: {

    },
    viewless: {
        color: theme.palette.white,
        borderLeft: `1px solid ${theme.palette.white}`,
        borderRight: `1px solid ${theme.palette.white}`,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: "flex"
    },
    button: {
        border: `1px solid ${theme.palette.white}`,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    tabs: {},
    subTabs: {
        marginLeft: theme.spacing(1),
        '& .MuiTabs-scrollButtons': {
            color: theme.palette.white
        }
    },
    tab: {
        maxWidth: "100%",
        overflow: "hidden",
        height: theme.spacing(8),
        padding: theme.spacing(1),
        color: theme.palette.white,
        marginLeft: 0,
        marginRight: 0,
    },
    appbar: {
        height: theme.spacing(8),
        justifyContent: "center",        
        boxShadow: "0px 7px 7px -2px rgba(0,0,0,0.2)"
    },
    appbarEmea: {
        background: theme.palette.topbar,
    },
    appbarLao: {
        background: theme.palette.secondary.base,
    }
}));

const ButtonInTabs = ({ className, onClick, children }) => {
    return <Button className={className} onClick={onClick} children={children} size='small' />;
};

const EMEATabsBar = ({ subTabs = [], tab, onTabChange, region }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [params] = useSetQuery();

    const [viewMore, setViewMore] = useState(true);
    const isMySelections = (isEmpty(params.sites) && isEmpty(params.Cust) && isEmpty(params.sofcNSG)) ? false : true;

    const updateViewMore = () => {
        setViewMore(!viewMore);
        if (viewMore && tab !== "network") {
            onTabChange(null, "myselections")
        }
    }


    return (
        <div className={classes.root} data-testid="emeatabsbar">
            <AppBar
                elevation={3}
                className={[classes.appbar, region === 'LAO' ? classes.appbarLao : classes.appbarEmea]}
                position="static"
                color="inherit">
                <Box display="flex">
                    <Box>
                        <Tabs
                            onChange={onTabChange}
                            value={tab}
                        >
                            <Tab
                                color="primary"
                                className={classes.tab}
                                name="network"
                                active={tab === "network"}
                                value={"network"}
                                icon={<IconName type="network" name={t('network')} />}
                            />
                            {
                                isMySelections &&
                                <Tab
                                    color="primary"
                                    className={classes.tab}
                                    name="myselections"
                                    value={"myselections"}
                                    active={tab === "myselections"}
                                    icon={<IconName type="default" name={t('my_selections')} />}
                                />
                            }
                            {
                                region === 'LAO' && isMySelections &&
                                <div className={classes.viewless}>
                                    <ButtonInTabs
                                        className={classes.button}
                                        onClick={updateViewMore}
                                    >
                                        <Typography
                                            variant="caption"
                                            color="primary"
                                            data-testid="viewMoreTabs">
                                            {t(viewMore ? 'view_less' : 'view_more')}
                                        </Typography>
                                    </ButtonInTabs>
                                </div>
                            }
                        </Tabs>
                    </Box>
                    {
                        region === 'LAO' && viewMore &&
                        <Box flexGrow={1} width="">
                            <Tabs
                                value={tab}
                                className={classes.subTabs}
                                variant="scrollable"
                                onChange={onTabChange}
                                wrapped
                                scrollButtons="auto"
                            >
                                {
                                    subTabs?.map(d => {
                                        return <Tab
                                            color="primary"
                                            className={classes.tab}
                                            name={d.name}
                                            value={`${d.id}`}
                                            active={tab === d.id}
                                            icon={<IconName icon={d.icon} type="default" name={d.name} />}
                                        />
                                    })
                                }
                            </Tabs>

                        </Box>
                    }
                </Box>
            </AppBar>
        </div>
    );
};

export default EMEATabsBar;
