import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { generatePath, useParams, withRouter, useLocation, useHistory } from "react-router-dom";
import { useRouteContext } from 'context/RouteContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { breadCrumbStyles } from 'theme';

const useStyles = makeStyles(breadCrumbStyles);

const BreadCrumb = () => {
    const classes = useStyles();
    const { mainPath, selfalertid } = useRouteContext();
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const params = useParams();

    const gotopage = React.useCallback(({ page }) => {
        return () => {
            return `${generatePath(
                mainPath,
                {
                    ...params,
                    page,
                    tabletype: page === "distribution" ? "outbound" : undefined
                })}?showDefault=true`
        }
    }, []);

    const goBack = (e, topage) => {
        const urlAbsPath = params.alertid ? selfalertid: mainPath;
        e.preventDefault();
        history.push({
            ...location,
            pathname: generatePath(
                urlAbsPath,
                {
                    ...params,
                    page: topage,
                })
        });
    }

    const breadcrumbName = (value) => {
        const pageMapping = {
            home: t('home'),
            orders: t("order_management_breadcrumb_text"),
            distribution: t("distribution_breadcrumb_text"),
            transport: t("transportation_breadcrumb_text")
        }
        return pageMapping[value];
    }

    const { page, shipmentNum } = params;

    return (
        <div className={classes.root} data-testid="breadcrumb-comp">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link data-testid='breadcrumb_home' to={gotopage({ page: "home" })} className={classes.link}>
                    {t('home')}
                </Link>
                {
                    (page !== "home" && shipmentNum) ?
                        <Link
                            data-testid="breadcrumb_go"
                            className={classes.link}
                            to={gotopage({ page })}
                        >
                            {breadcrumbName(page)}
                        </Link> : null
                }
                {
                    (page !== "home" && !shipmentNum) &&
                    <Typography className={classes.activeLink}>{breadcrumbName(page)}</Typography>
                }
                {shipmentNum && <Typography className={classes.activeLink}>{t('track_trace')}</Typography>}
            </Breadcrumbs>
            {
                shipmentNum &&
                <Button
                    size="small"
                    onClick={(e) => goBack(e, page)}
                    className={classes.backbutton}
                    startIcon={<ArrowBackIcon />}
                    data-testid="back_button"
                >
                    {t('fourkites_back_button')}
                </Button>
            }
        </div>
    );
}
export default withRouter(BreadCrumb)