import React, { isValidElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { getData } from "components/common/FIlterChips";
import KCChip from "components/common/Chip";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { saveFilters } from "reduxLib/services";
import { createFilterObjFromPayload } from "helpers/emeaHelpers";
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import FilterViewIcon from "assets/images/new_view.svg";
import DeleteIcon from 'assets/images/delete_dark.svg';
import {
  deleteEMEAView, getEMEAView,
  resetEMEAStatus,
  resetEMEAViews,
  updateEMEAView
} from "reduxLib/services/EmeaServices/emeaViewsServices";
import { isEmpty } from "lodash";
import { Button, FormGroup, LinearProgress, TextField } from "@material-ui/core";
import { defaultDocumentDate, viewTableNames } from "configs/appConstants";
import { useEffect } from "react";
import { generateFilterLayoutTemplate, getFilterArgs } from "helpers";
import Snack from "components/common/Helpers/Snack";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    borderRadius: theme.spacing(3),
    background: theme.palette.primary.main,
    minHeight: 130,
    position: "relative",
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    }
  },
  progress: {
    backgroundColor: theme.palette?.legendColors?.greyLight,
    width: "98%",
    position: "absolute",
    height: '2px',
    borderRadius: "50%",
    top: -3
  },
  textlink: {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  chip: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    height: 35,
    backgroundColor: theme.palette.chip?.tableChips,
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1)
    }
  },
  viewActions: {
    textAlign: 'center',
    margin: `${theme.spacing(1)}px 0px`,
    [theme.breakpoints.up('md')]: {
      borderLeft: `2px solid ${theme.palette.primary?.pressed}`,
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: `2px solid ${theme.palette.primary?.pressed}`,
    }
  },
  icon: {
    color: theme.palette?.text?.primary
  },
  saveButton: {
    backgroundColor: theme.palette?.primary.contrastText,
    color: theme.palette.white,
    '&.MuiButton-contained:hover': {
      backgroundColor: theme.palette?.background.primaryBase
    },
    borderRadius: 0,
  },
  cancelButton: {
    color: "inherit",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  addText: {
    border: `1px solid ${theme.palette?.background.secondaryBase}`,
    borderRadius: theme.spacing(0.5),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "none",
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}));

export const EMEAFilterChips = ({ type, deleteChip, remappedFilters }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [chipsdata, setChipsData] = useState([]);

  useDeepCompareEffect(() => {
    let tempChipData = [];
    Object.keys(remappedFilters).map((key) => {
      const d = remappedFilters[key];
      if (d?.type === "checkbox" || d?.type === "checkboxradio" || d?.type === "ordercheckbox") {
        d.data.map(item => {
          tempChipData.push({ ...d, ...item, type: "checkbox", parent: type, key, data: item.checked });
          return item;
        })
      } else tempChipData.push({ ...d, parent: type, key });
      return key;
    });
    setChipsData(tempChipData)
  }, [remappedFilters]);


  return <div data-testid="filterChips" >
    {
      chipsdata.filter(d => d.data)
        .map((data, index) => {
          const chipdata = getData({
            ...data,
            name: t(data?.name)
          }, []);
          return ((data.data && chipdata.label) ? <KCChip
            key={index}
            deleteIcon={<HighlightOffRoundedIcon data-testid={`deletechip-${data.key}`} />}
            label={
              isValidElement(chipdata.label) ?
                chipdata.label : t(chipdata.label)
            }
            className={classes.chip}
            onDelete={() => deleteChip(data)}
          /> : null)
        })
    }
  </div>
}

const EmeaFilterBox = ({
  tableName, type, filterBody,
  columns, clearAllFilters
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [newView, setNewView] = useState({ open: false });
  const [params, setParams] = useSetQuery();
  const setMultiQuery = useMultiSetQuery();
  const [remappedFilters, setRemappedFilters] = useState({});
  const { activeView, viewLoading, loading, views, status } = useSelector(
    ({ emeaViews: { activeView, viewLoading, loading, views, status } }) =>
      ({ activeView, viewLoading, loading, views, status })
  );
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });

  const viewApplied = !isEmpty(activeView);

  const generateURLFilters = (activeViewFilters) => {
    const args = getFilterArgs(activeViewFilters);
    if(args.deliveryBlocked?.length === 1) {
      args.deliveryBlocked = args.deliveryBlocked[0]
    }
    return args;
  }

  useEffect(() => {
    if (status.message && status.show) {
      setSnack({
        open: true,
        severity: status.status,
        message: t(status.message)
      });
      dispatch(resetEMEAStatus())
    }
  }, [status]);

  useDeepCompareEffect(() => {
    const remappedObject = createFilterObjFromPayload({
      filterPaylod: params?.emeaFilters,
      filterBody,
    });
    setRemappedFilters(remappedObject);
    dispatch(saveFilters({
      filter: {
        ...filterBody,
        ...remappedObject
      }, type, subtype: tableName
    }));
  }, [params?.emeaFilters]);

  useDeepCompareEffect(() => {
    if (!viewLoading && views?.length >= 0 && params.loadView) {
      const found = views.filter(d => d.isFav)[0] || {};
      if (found.id) {
        setMultiQuery({
          documentDate: JSON.stringify(defaultDocumentDate),
          viewid: found.id,
        }); 
      } else {
        setMultiQuery({
          documentDate: JSON.stringify(defaultDocumentDate),
          emeaFilters: "{}",
          updateFilters: undefined,
          loadView: undefined,
          viewid: undefined
        });
      }
    }
  }, [params.loadView, views, viewLoading]);

  useEffect(() => {
    if (params.viewid && activeView.id !== params.viewid) {
      dispatch(getEMEAView({
        viewid: params.viewid,
        tableName: viewTableNames[tableName]
      }));
    }

    if (params.viewid && activeView.id === params.viewid && (params.updateFilters || params.loadView)) {
      setMultiQuery({
        updateFilters: undefined,
        loadView: undefined,
        emeaFilters: JSON.stringify(generateURLFilters(activeView.filters))
      });
    }
  }, [params.viewid, activeView.id, params.updateFilters, params.loadView]);

  const checkFiltersLength = () => { };

  const deleteChip = (chip) => {
    const updatedobject = () => {
      if (chip.key === "deliveryBlocked") {
        return undefined
      }
      return params.emeaFilters?.[chip.key]?.filter(d => d !== chip.value);
    }
    setParams({
      key: "emeaFilters",
      value: {
        ...params.emeaFilters,
        [chip.key]: chip.type === "checkbox" ? updatedobject() : undefined
      }
    });
  }

  const addView = (e) => {
    e.preventDefault();

    const template = generateFilterLayoutTemplate({
      viewName: newView.name,
      filters: remappedFilters,
      columns: columns
    });
    dispatch(updateEMEAView({
      view: template,
      tableName: viewTableNames[tableName],
      updateType: "new"
    }));
    setMultiQuery({
      viewid: template.id,
      updateFilters: true
    });
    setNewView({ open: false })
  }

  const resetTableFilters = () => {
    setMultiQuery({
      emeaFilters: JSON.stringify(generateURLFilters(activeView.filters))
    });
  }

  const saveView = () => {
    dispatch(updateEMEAView({
      view: {
        ...activeView,
        filters: remappedFilters,
        columns: columns,
      }, tableName: viewTableNames[tableName]
    }))
  }

  const deleteView = () => {
    dispatch(deleteEMEAView({
      viewid: activeView.id,
      tableName: viewTableNames[tableName]
    }));
    setMultiQuery({
      viewid: undefined,
      emeaFilters: "{}"
    });
  }

  useEffect(() => {
    return () => {
      dispatch(resetEMEAViews({
        tableName: viewTableNames[tableName]
      }));
    };
  }, []);

  return (
    <Grid container data-testid="emeaFilterBar" className={classes.root}>
      {
        (viewLoading || loading) &&
        <LinearProgress className={classes.progress} />
      }
      <Snack {...snack} handleClose={() => setSnack({ open: false })} />
      <Grid item container alignItems="center" xs={12} sm={12} md={9} lg={8}>
        <EMEAFilterChips
          type={type}
          subtype={tableName}
          filterBody={filterBody}
          deleteChip={deleteChip}
          remappedFilters={remappedFilters}
          checkFilterChipsLength={checkFiltersLength}
        />
      </Grid>
      <Grid item container alignItems="center" justify="space-around" xs={3} sm={3} md={1} lg={1} className={classes.divider}>
        <Grid item>
          <Link onClick={clearAllFilters} data-testid="clearEmeafilters" className={classes.textlink} > {t('clear_all')} </Link>
        </Grid>
        {viewApplied && <Grid item>
          <Link data-testid="resetEmeafilters" onClick={resetTableFilters} className={classes.textlink} > {t('reset')} </Link>
        </Grid>}
      </Grid>
      <Grid item container justify="space-around" alignItems="center" xs={12} sm={12} md={2} lg={3} className={classes.viewActions}>
        {
          !newView.open && <>
            {
              viewApplied &&
              <Grid item xs={12}>
                <Typography align='center' variant='h4'> {activeView.name} </Typography>
              </Grid>
            }
            <Grid item>
              <IconButton data-testid="newViewIcon" onClick={() => setNewView({ open: true })} >
                <img src={FilterViewIcon} fontSize='medium' />
              </IconButton>
              <Typography variant='subtitle1'> {t('new_view')} </Typography>
            </Grid>
            {
              viewApplied &&
              <>
                <Grid item>
                  <IconButton data-testid="saveview-btn" onClick={saveView}>
                    <SaveOutlinedIcon fontSize="medium" className={classes.icon} />
                  </IconButton>
                  <Typography variant='subtitle1'> {t('save')} </Typography>
                </Grid>
                <Grid item>
                  <IconButton data-testid="delview-btn" onClick={deleteView}>
                    <img src={DeleteIcon} fontSize='medium' />
                  </IconButton>
                  <Typography variant='subtitle1'> {t('delete')} </Typography>
                </Grid>
              </>
            }
          </>
        }
        {newView.open &&
          <Grid item>
            <form onSubmit={addView} >
              <FormGroup row
                className={classes.addview}>
                <TextField
                  value={newView.name}
                  onChange={(e) => setNewView({ open: true, name: e.target.value })}
                  variant="outlined"
                  required
                  className={classes.addText}
                  autoComplete={false}
                  inputProps={{
                    "data-testid": "save_new_from_view",
                    "autocomplete": "off"
                  }}
                  name="viewname"
                  placeholder={t('enter_a_view_name')}
                  size="small"
                />
                <Button
                  className={classes.saveButton}
                  variant="contained"
                  size="small"
                  type="submit"
                  disableElevation
                  disabled={isEmpty(newView.name)}
                  data-testid="addview_submit">
                  {t('save')}
                </Button>
                <Button
                  className={classes.cancelButton}
                  disableElevation
                  variant="contained"
                  onClick={(e) => setNewView({ open: false })}
                  size="small"
                  type="reset"
                  data-testid="addview_cancel">
                  {t('cancel')}
                </Button>
              </FormGroup>
            </form>
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default EmeaFilterBox;