export const endpoints = {
    table : {
        network: "/v2/orders/search",
        orders: "/v2/orders/search",
        transport: "/v2/orders/search",
        distributionInbound: "/v2/orders/search/inbound",
        distributionOutbound: "/v2/orders/search",
        osm: "/osm/shipment/search",
        osmOrderDetails: "/osm/shipment/search/orders",
        osmProductDetails: "/osm/shipment/order/search/items"
    },
    charts:"/v2/summary",
    itemDetails:"/v2/orders/search/items",
    shipmentDetails:"/shipment/search",
    selfalerting:{
        metadata: "/v2/orders/search/date-comparison-metadata",
        alerts: "/alerts"
    },
    globalFilters:{
        locations:"/sites/region/NA",
        sitePerformance: "/sites/performance/region/NA",
        customerSearch:"/customer/search",
        saleOfficeSearch:"/customer/salesoffice/search"
    },
    favourites: {
        userFavourites: "/users/favourites",
        favSites: "/users/favSites?action=fav",
        unfavSites: "/users/favSites?action=unfav",
        buFavourites: "/users/favBusinessUnits",
        cusoFavourites: "/users/favCustomerSalesOffice"
    },
    analytics: {
        updateCstRanking: "/carrieroptimization/secondarysequence"
    },
    guardrails: {
       siteData:"/site/guardrails",
       siteInboundData:"/site/inbound/guardrails",
       allData:"/users/getAllWarehouseGuardrails",
       update:"/users/updateGuardrail",
       updateUserRequests: "/users/updateUserRequests",
       userRequests: "/users/userRequests"
    },
    stockConstraintReport: {
        shipmentList:"/stockconstraintreport/shipments",
        // shipmentMaterials:"/stockconstraintreport/shipments/items",
        shipmentSearch:"/stockconstraintreport/shipments/search",
        shipmentMaterialRecomendation:"/stockconstraintreport/shipments/inbound/recommendation",
        materialsSearch: "/stockconstraintreport/items",
        materialOutboundSearch: "/stockconstraintreport/items/search/outbound",
        materialInboundSearch: "/stockconstraintreport/items/search/inbound"
    },
    osm: {
        authSites: "/users/osm/authorizedSites",
        trailerDimensions: "/loadDiagram/vehicleDimension",
        ldSubmission: "/loadDiagram/submission",
        submitLdPreCheck: "/loadDiagram/submission/preCheck",
        sendToWms: "/osm/publish/ld/wms/",
        getviews: "/users/layoutsInfo",
        getview: "/users/viewDetails",
        updateviews: "/users/updateView",
        deleteviews: "/users/deleteView",
        favView: "/users/favView",
        viewOrder: "/users/viewOrder",
        deliveryComments: "/loadDiagram/delComments",
        reprintLSR: "/loadDiagram/reprint/lsr",
        precheck: "/osm/preCheck",
        viewLsr: "/loadDiagram/view/lsr",
        viewStackInfo: "/loadDiagram/view/stackInfo",
        printUCL: "/osm/printlabel/ucc",
        printADDR: "/osm/printlabel/addr",
        ldLogsDownload: "/loadDiagram/download/ldPayload"
    },
    emea : {
        locations: "/users/emea/authorizedSites",
        dcAndSalesOrgs: "/emea/salesOrg",
        customerSearch: "/emea/customer/search",
        salesOfficeGroups: "/emea/salesOffice",
        summary: "/emea/v2/summary",
        buFav: "/users/favBusinessUnits",
        sitesFav: "/users/favSiteNums",
        custFavs: "/users/favCustomer",
        sosgFavs: "/users/favSalesOffice",
        allFavs: "/users/favourites?region=EMEA",
        emeaTable: "/emea/orders/search",
        editComments: "/users/emea/item/comment?originSiteNum=",
        getviews: "/users/layoutsInfo",
        getview: "/users/viewDetails",
        updateviews: "/users/updateView",
        deleteviews: "/users/deleteView",
        favView: "/users/favView",
        emeaDetailsTable: "/emea/orders/search"
    },
    lao: {
        locations: "/users/lao/authorizedSites",
        getviews: "/users/layoutsInfo",
        getview: "/users/viewDetails",
        updateviews: "/users/updateView",
        deleteView: "/users/deleteView",
        favView: "/users/favView",
        favourites: "/users/favourites?region=LAO",
        favBusinessUnits: "/users/favBusinessUnits",
        favSiteNums: "/users/favSiteNums",
        laoTableExport: "/lao/orders/search",
        laoTableImport: "/lao/orders/search/inbound",
        summary: "/lao/v2/summary",
    }
}