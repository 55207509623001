import { createMuiTheme, fade } from '@material-ui/core';
import { dark, light } from './palette';
import { typography, gilroy } from './typography';

const colorPanel = dark;
const lightPanel = light;

export const appTheme = createMuiTheme({
  palette: colorPanel,
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gilroy]
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: colorPanel.secondary.dark
        }
      }
    },
    MuiMenu: {
      list: {
        backgroundColor: colorPanel.form.baseLight
      }
    },
    MuiTextField: {
      root: {
        '& ::-webkit-calendar-picker-indicator': {
          filter: "invert(1)"
        }
      }
    }
  },
  type: colorPanel.type,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export const appLightTheme = createMuiTheme({
  palette: lightPanel,
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gilroy],
        '::-webkit-scrollbar-thumb' : {
          background: lightPanel.white,
          borderRadius: '10px',
          border: `2px solid ${lightPanel.table.scrollBorder}`
        }
      }
    },
    MuiSelect: {
      icon: {
        color: lightPanel.white
      }
    },
    MuiTextField: {
      root: {
        '& ::-webkit-calendar-picker-indicator': {
          filter: "invert(0)"
        }
      }
    }
  },
  type: lightPanel.type,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export const testTheme = createMuiTheme({
  palette: colorPanel,
  typography,
  type: colorPanel.type,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export const chartElementStyles = (theme => ({
  root: {
    height: theme.spacing(42),
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4)
    },
  },
  link: {
    color: colorPanel.secondary.accent,
    cursor: 'pointer',
    textDecoration: 'none'
  },
  spining: {
    pointerEvents: "none",
    height: '0.9em',
    animation: "App-logo-spin infinite 1s linear !important"
  },
  spin: {
    height: '0.9em',
    cursor: "pointer",
  },
  refreshIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: '51%',
    transform: 'translateY(-50%)'

  },
  label: {
    color: theme.palette.menu.base
  },
  verticalDivider: {
    flexBasis: '2px',
    "& hr": {
      padding: 0,
      backgroundColor: 'transparent',
      borderLeft: `2px dashed ${theme.palette.divider}`,
    }
  },
  count: {
    fontSize: theme.spacing(6)
  },
  container: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-35%)",
    textAlign: "center",
    width: "100%",
    verticalDivider_solid: {
      flexBasis: '2px',
      "& hr": {
        padding: 0,
        backgroundColor: 'transparent',
        borderLeft: `2px solid ${theme.palette.divider}`,
      }
    },
  },
  hyperlink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    letterSpacing: '1px',
    padding: theme.spacing(.4),
    '&:hover': {
      color: theme.palette.link.hover
    }
  },
  card: {
    backgroundColor: theme.palette.primary.base
  },
  pbZero: {
    paddingBottom: 0
  },
  ptZero: {
    paddingTop: 0
  },
  minHeight: {
    height: 'fit-content',
    minHeight: 336
  },
  notransform: {
    textTransform: "none"
  }
}));


export const cardStyles = (theme) => ({
  root: {
    display: 'block',
    flexWrap: 'wrap',
    width: '100%',
    height: "100%",
    borderRadius: '6px',
    backgroundColor: theme.palette.card.default,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  addPadding: {
    paddingBottom: theme.spacing(2)
  },
  content: {
    paddingBottom: 0,
    padding: '12px 0px 0px',
  },
  title: {
    padding: theme.spacing(1.5),
    position: 'relative',
    textTransform: 'uppercase'

  },
  actions: {
    display: 'block',
    marginLeft: theme.spacing(1),
    bottom: theme.spacing(2),
    position: 'absolute',
    width: `${theme.spacing(12)}%`
  },
  divider: {
    padding: 0,
    height: '2.03px',
    marginLeft: -(theme.spacing(1)),
    marginRight: -(theme.spacing(1)),
    backgroundColor: theme.palette.secondary.base
  },
  innercard: {
    backgroundColor: theme.palette.card.base,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: "relative"
    // marginTop: theme.spacing(0),
    // marginBottom: theme.spacing(0),
  }
});


export const filterStyles = (theme) => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    marginTop: 10,
    marginRight: theme.spacing(3),
    right: 0,
    zIndex: 100,
    width: 350,
    borderRadius: 0,
    backgroundColor: theme.palette.chip.backgroundDefault,
    height: '80vh',
    overflow: 'auto'
  },
  shifttab: {
    marginLeft: theme.spacing(1)
  },
  show: {
    display: 'block'
  },
  hide: {
    display: 'none'
  },
  container: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  title: {
    position: "sticky",
    top: 0,
    zIndex: 999,
    backgroundColor: theme.palette.extra.filterTitle,
    fontFamily: 'Gilroy',
    padding: theme.spacing(2),
    color: theme.palette.white
  },
  serchSpace: {
    backgroundColor: theme.palette.chip?.tableChips,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  actions: {
    backgroundColor: theme.palette.extra.filterActions
  },
  subtitle: {
    fontFamily: 'Gilroy',
    fontSize: 12,
    fontWeight: 200,
    letterSpacing: 0.5,
    color: theme.palette.primary.contrastText
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    backgroundColor: theme.palette.extra.filterTitle,
    '&:hover': {
      // backgroundColor: fade(theme.palette.common.white, 0.25),
      backgroundColor: theme.palette.extra.filterTitle,
    },
    marginLeft: 0,
    height: 40,
    width: '100%',
    border: `1px solid #303862`,
    marginTop: 4,
    color: theme.palette.primary.contrastText

  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  controls: {
    // height: 45,
    display: 'flex',
    alignContent: 'left',
    justifyContent: 'middle',
    cursor: 'pointer'
  },
  controlText: {
    color: theme.palette.extra.iron,
    fontSize: 14,
    letterSpacing: 0,
    textDecoration: 'underline',
    userSelect: "none",
    cursor: "pointer",
  },
  button: {
    cursor: 'pointer',
    userSelect: "none",
  },
  expandButton: {
    cursor: 'pointer',
    userSelect: "none",
    pointerEvents: 'none'
  },
  boxTitle: {
    fontFamily: "Gilroy",
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0.58,
    textTransform: "uppercase"
  },
  openBox: {
    backgroundColor: theme.palette.background.filters,
  },
  closedBox: {
    backgroundColor: theme.palette.extra.expandFilter,
  },
  border: {
    borderBottom: `2px solid ${theme.palette.extra.filterBorder}`
  },
  contentBox: {
    height: "100%",
    padding: theme.spacing(2)
  },
  breakWord:{
    wordWrap: "break-word"
  },
  contentText: {
    fontFamily: "Gilroy",
    fontSize: 16,
    color: theme.palette.primary.contrastText
  },
  customerFilterRoot: {
    borderRight: `1px solid ${theme.palette.primary.contrastText}`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px !important`,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px !important`,
    }
  },
  underline: {
    textDecoration: 'underline'
  },
  label: {
    textTransform: "capitalize"
  },
  accordian: {
    background: "transparent !important",
    boxShadow: "none",
  },
  accordianSummary: {
    minHeight: "10px !important",
    padding: 0,
    opacity: "1 !important",
    "& > .Mui-expanded": {
      margin: `0 !important`,
    }
  },
  accordianDetails: {
    display: "block",
    padding: 0,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalbody: {
    background: theme.palette.chip.backgroundDefault,
    boxShadow: theme.shadows[5],
    width: "90%",
    position: "relative",
    height: 500,
    maxHeight: '700px',
    overflow: "auto",
    padding: theme.spacing(2),
    '&:focus-visible': {
      outline: 'none'
    }
  },
  modalbutton: {
    padding: theme.spacing(2, 2, 1)
  },
  closeicon: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1000
  },
  inputGroup: {
    color: theme.palette.primary.contrastText
  },
  customerBox: {
    padding: `8.5px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.background.primaryBase,
    color: theme.palette.white,
    cursor: 'pointer',
    '&:hover' : {
      backgroundColor: theme.palette?.background?.secondaryBase
    }
  },
  checkbox: {
    display: 'grid',
    width: 310,
    maxHeight: 300,
    overflowX: "auto",
    // flexDirection: "row"
  }
})

export const tableStyles = (theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: 0
    },
  },
  expand: {
    backgroundColor: theme.palette.card.base,
    padding: theme.spacing(4),
    width: "70%",
  },
  outline: {
    backgroundColor: theme.palette.card.base,
  },
  grid: {
    borderBotton: '1px solid red',
    padding: theme.spacing(1)
  },
  text: {
    textTransform: "uppercase",
    fontFamily: 'Gilroy',
    letterSpacing: '0.1em'

  },
  subtext: {
    marginTop: -3,
    fontSize: "13px",
    lineHeight: "25px",
    fontFamily: 'Gilroy',
    // paddingLeft: theme.spacing(3),
    letterSpacing: '0.03em'
  },
  location: {
    // width: "250px",
    height: '45px',
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `1px solid gray`,
    paddingTop: 15,
    textTransform: 'capitalize'
  },
  align: {
    display: 'flex',
    alignContent: 'left',
    justifyContent: 'middle',
    marginRight: theme.spacing(2),
  },
  filtericon: {
    border: '1px solid white',
    borderRadius: '50%',
    height: 30,
    width: 30,
    padding: 5
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& div::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,

    },
    '& div::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.primary.base}`,
      borderRadius: 0,
      background: theme.palette.divider
    },
    '& div::-webkit-scrollbar-thumb': {
      background: theme.palette.table.scrollColor,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.table.scrollBorder}`
    },
  },
  etabutton: {
    cursor: "pointer",
    color: theme.palette.secondary.accent,
    "&:hover": {
      color: theme.palette.link.hover
    }
  },
  smallimage: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  item: {
    paddingTop: `0px !important`
  },
  item1: {
    marginTop: theme.spacing(3),
  }
})

export const viewStyles = ((theme) => ({
  marginTop: {
    marginTop: theme.spacing(1)
  },
  separator: {
    "& >div": {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  hide: {
    visibility: 'hidden',
    display: 'none'
  },
  expand: {
    marginTop: theme.spacing(1),
    display: 'block',
    textTransform: "capitalize",
  },
  addPadding: {
    [theme.breakpoints.up('md')]: {
      padding: `0px ${theme.spacing(1)}px`
    },
    [theme.breakpoints.between('xs', 'md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    }
  },
  extendTitle: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(0)}px`,
    "& >div": {
      borderBottom: `2px solid ${theme.palette.secondary.accent}`
    },
    "& >div:first-child": {
      flexBasis: "79.5%",
      maxWidth: "80%"
    },
    "& >div:nth-child(2)": {
      flexBasis: "19.5%",
      maxWidth: "20%"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  modalBody: {
    overflow: "auto"
  },
  scrLinkbutton: {
    cursor: "pointer",
    color: theme.palette.secondary.lightCyanBlue,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    fontSize: theme.spacing(2),
    // "&:hover": {
    //   color: theme.palette.link.hover
    // }
  },
  arrowImg: {
    paddingLeft: theme.spacing(2)
  }
}))


export const itemDetailsStyles = ((theme) => ({
  root: {
  },
  tablebody: {
    padding: theme.spacing(5),
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(2),
    fontSize: "14px"
  }
}));

export const selectStyles = theme => ({
  root: {
    "& > div > ul": {
      maxHeight: theme.spacing(50),
      overflow: "auto",
      '&::-webkit-scrollbar': {
        height: `${theme.spacing(1.5)}px !important`,
        width: `${theme.spacing(1.5)}px !important`,
      },
      '&::-webkit-scrollbar-track': {
        border: `5px solid ${theme.palette.text.underline}`,
        borderRadius: 0,
        background: theme.palette.divider
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.divider,
        borderRadius: '10px',
        border: `2px solid ${theme.palette.white}`
      },
    }
  },
  button: {
    display: 'flex',
    border: `1px solid ${theme.palette.white}`,
    minWidth: 200,
    borderRadius: '4px',
    padding: theme.spacing(2),
    '& .MuiButton-label': {
      justifyContent: 'space-between'
    },
    cursor: 'pointer',
    textTransform: 'initial',
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto"
    }
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  text: {
    pointerEvents: 'none',
  },
  textTransform: {
    pointerEvents: 'none',
    "&:first-letter": {
      textTransform: 'initial'
    },
  },
  business: {
    textAlign: 'left',
    minWidth: theme.spacing(20),
    height: theme.spacing(5),
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  icon: {
    color: theme.palette.black,
  },
  menuList: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black
  },
  menuItem: {
    '&:hover': {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    '&:focus': {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      '&:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  popper: {
    width: 198,
    borderRadius: '50%',
    zIndex: 9
  },
  toplabel: {
    top: theme.spacing(1.5), //15
    width: "fit-content",
    zIndex: 100,
    color: theme.palette.white,
    border: `1px solid ${theme.palette.white}`,
    paddingLeft: theme.spacing(1),
    borderRadius: theme.spacing(0.6),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.2),
    backgroundColor: theme.palette.secondary.base,
    position: 'absolute',
    marginLeft: theme.spacing(1),
    fontSize: 11,
  },
})


export const mapStyles = theme => ({
  root: {
  },
  map: {
    height: (props) => {
      return `${props.parentHeight}px !important`;
    },
    width: (props) => {
      return `${props.parentWidth}px !important`;
    }
  },
  tooltip: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.black,
    fontWeight: 500,
  },
  tooltipname: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: "bold",
    width: 'fit-content',
    color: theme.palette.black,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  legend: {
    minWidth: 400,
    textAlign: 'left',
    bottom: `${theme.spacing(1)}px !important`
  },
  circle: {
    borderRadius: '50%',
    width: 20,
    height: 20
  },
  redFill: {
    backgroundColor: theme.palette.legendColors.unhealthy
  },
  blueFill: {
    backgroundColor: theme.palette.legendColors.healthy
  },
  image: {
    width: 20
  },
  healthy: {
    borderLeft: `6px solid ${theme.palette.legendColors.healthyGreen}`,
  },
  critical: {
    borderLeft: `6px solid ${theme.palette.legendColors.criticalRed}`,
  },
  behind: {
    borderLeft: `6px solid ${theme.palette.legendColors.pink}`,
  },
  monitor: {
    borderLeft: `6px solid ${theme.palette.legendColors.risk}`,
  }
});

export const shipmentStyles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: "transparent",
  },
  appbar: {
    background: "transparent",
    borderBottom: `1px solid ${theme.palette.white}`,
  },
  tab: {
    [theme.breakpoints.down("sm")]: {
      flexBasis: "50%"
    }
  },
  tabName: {
    textAlign: "center",
    cursor: 'pointer',
  },
  shipmentNumber: {
    fontSize: theme.spacing(4),
    fontWeight: "500"
  },
  leftBox: {
    borderRight: `2px solid ${theme.palette.white}`,
    "& > div": {
      marginBottom: theme.spacing(4)
    },
    [theme.breakpoints.down("sm")]: {
      borderRight: 'none',
      "& > div": {
        marginBottom: theme.spacing(2)
      },
    }
  },
  container: {
    margin: `${theme.spacing(4)}px 0px`,
    [theme.breakpoints.down("sm")]: {
      padding: `0px ${theme.spacing(3)}px`,
    }
  },
  tabPanel: {
    background: theme.palette.text.underline
  },
  stops: {
    background: theme.palette.secondary.dark,
    borderRadius: theme.spacing(.5),
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(2.5)}px 0px`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      width: "100%"
    }
  },
  component: {
    marginTop: theme.spacing(8)
  }
})

export const tabsBarStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  appbar: {
    background: theme.palette.secondary.dark,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  tabpanel: {
  },
  tabName: {
    float: 'left',
    textAlign: "left",
    display: 'flex'
  },
  name: {
    textAlign: "left",
    color: theme.palette.whte,
    pointerEvents: 'none',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    marginTop: theme.spacing(.25),
    textTransform: 'capitalize'
  },
  icon: {
    display: 'inline-block',
    paddingRight: 10,
  },
  addButton: {
    color: "white",
    cursor: 'pointer'
  },
  showTabs: {
    display: 'flex',
    borderLeft: `3px solid ${theme.palette.white}`,
    marginLeft: theme.spacing(2)
  },
  showSingleLocation: {
    display: 'flex',
  },
  customtab: {
    maxWidth: '100%'
  },
  hideTabs: {
    display: 'none'
  },
  viewmore: {
    borderRadius: '3px',
    border: `1px solid ${theme.palette.secondary.accent}`,
    padding: theme.spacing(.5),
    textTransform: 'capitalize'
  },
})

export const performanceTableStyle = (theme) => ({
  main: {
    maxHeight: '550px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,
    },
    '&::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.text.underline}`,
      borderRadius: 0,
      background: theme.palette.divider
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.divider,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.white}`
    },
  },
  innerCard: {
    minHeight: props => theme.spacing(props.singleLocation ? 0 : 45),
    width: "100%",
    backgroundColor: theme.palette.card.base
  },
  title: {
    paddingTop: theme.spacing(2)
  },
  legends: {
    display: 'inline-flex',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1.25),
    flexFlow: `row nowrap`
  },
  sitecolumn: {
    paddingLeft: theme.spacing(1.5)
  },
  aheadcolumn: {
    paddingLeft: theme.spacing(0.5)
  },
  hourcolumn: {
    paddingRight: theme.spacing(1),
    textAlign: 'end'
  },
  icons: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(3)

    }
  },
  nodata: {
    width: '75%'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    "&:hover": {
      color: theme.palette.secondary.accent
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.25),
      whiteSpace: "nowrap"
    }
  },
  filters: {
    width: theme.spacing(37.5),
    height: theme.spacing(50),
    backgroundColor: theme.palette.secondary.base,
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      height: "auto",
      marginBottom: theme.spacing(2)
    }
  },
  tableBody: {
    marginBottom: theme.spacing(1.25),
    height: theme.spacing(2.75)
  },
  tableHeader: {
    marginBottom: theme.spacing(6)
  },
  aheadicon: {
    paddingLeft: theme.spacing(2.5)
  },
  behindicon: {
    paddingLeft: theme.spacing(2.25)
  },
  chart: {
    position: "relative",
    top: `-${theme.spacing(6)}px`,
  }
})


export const filterbarStyles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(9.5),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    borderRadius: 0,
    backgroundColor: theme.palette.filterbar,
    borderBottom: `1px solid ${theme.palette.border}`,
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  dropdown: {
    position: 'absolute',
    top: 124,
    right: 1,
    left: 1,
    margin: 0,
    zIndex: 1,
    width: 'auto',
    height: '436px',
    padding: theme.spacing(1),
  },
  filter: {
    display: 'flex',
    alignContent: 'left',
    justifyContent: 'middle',
    marginRight: theme.spacing(2),
  },
  location: {
    width: theme.spacing(29),
    height: theme.spacing(5),
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: `1px solid ${theme.palette.border}`,
    textTransform: 'capitalize',
    [theme.breakpoints.down("xs")]: {
      minWidth: theme.spacing(16),
      maxWidth: theme.spacing(23),

    }
  },
  text: {
    marginRight: theme.spacing(2),
  },
  textHead: {
    marginRight: theme.spacing(2)
  },
  othertext: {
    marginRight: theme.spacing(1),

  },
  showfavs: {
    width: theme.spacing(20),
    marginLeft: 0,
    marginRight: 0,
  },
  // chipsBox: {
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2),
  // },
  chip: {
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.secondary.dark,
    margin: theme.spacing(0.5),
  },
})

export const locationfilterlayoutStyles = (theme) => ({
  root: {
    margin: 0,
    width: 'auto',
    borderRadius: 0,
    zIndex: "999",
    '& div::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,

    },
    '& div::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.secondary.base}`,
      borderRadius: 0,
      background: theme.palette.divider
    },
    '& div::-webkit-scrollbar-thumb': {
      background: theme.palette.divider,
      borderRadius: '10px',
      border: `2px solid ${theme.palette.white}`
    },
    [theme.breakpoints.down("sm")]: {
      top: `${theme.spacing(15.25)}px`,
      height: "auto"
    }
  },
  overflow: {
    height: theme.spacing(50),
    overflowX: 'auto',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "auto"
    }
  },
  overflowLocations: {
    maxHeight: theme.spacing(35),
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&>div': {
      padding: `0px ${theme.spacing(4)}px`,
    },
    '& >div:not(:nth-child(3n))': {
      borderRight: '1px solid'
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: theme.spacing(40),
      '&>div': {
        padding: `0px ${theme.spacing(2)}px`,
      },
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      },
      '& >div:nth-child(odd)': {
        borderRight: '1px solid'
      },
    }
  },
  height: {
    height: theme.spacing(25),
    [theme.breakpoints.down("sm")]: {
      height: "40vh"
    }
  },
  favorites: {
    backgroundColor: theme.palette.secondary.base,
    height: theme.spacing(65),
    [theme.breakpoints.down("sm")]: {
      height: "auto"
    }
  },
  locations: {
    padding: theme.spacing(3),
    height: theme.spacing(65),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(11)
    }
  },
  actionButton: {
    textTransform: 'capitalize',
    position: 'absolute',
    bottom: theme.spacing(2.5),
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`
  },
  actionButtonCUSO: {
    bottom: "-56px",
    '&:hover': {
      backgroundColor: theme.palette.primary.hover
    },
    [theme.breakpoints.down("sm")]: {
      bottom: `-${theme.spacing(12)}px`
    }
  },
  closeicon: {
    position: "absolute",
    right: 25,
    top: 25,
    cursor: "pointer"
  },
  formControl: {
    border: `white`,
    borderRadius: '5px'
  },
  input: {
  },
  textField: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cssLabel: {
    color: theme.palette.primary.contrastText
  },

  cssOutlinedInput: {
    color: theme.palette.primary.contrastText,
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.contrastText} !important`,
    }
  },
  insidefilter: {
    marginBottom: theme.spacing(1)
  },
  menuitem: {
    color: 'black'
  },
  notchedOutline: {
    color: theme.palette.primary.contrastText,

    borderWidth: '1px',
    borderColor: `${theme.palette.primary.contrastText} !important`
  },
  noresultContainer: {
    height: theme.spacing(10)
  },
  link: {
    color: theme.palette.secondary.accent
  },
  searchCountText: {
    color: theme.palette.legendColors.greyLight
  },
  searchBar: {
    position: "absolute",
    top: "45%",
    transform: "translateY(-45%)",
    [theme.breakpoints.down("sm")]: {
      position: "static",
      marginTop: theme.spacing(3),
      minWidth: theme.spacing(18)
    }
  }
})

export const searchFilterStyles = (theme) => ({
  root: {
    flexGrow: 1
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.chip.backgroundDefault,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover
    },
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
  customInput1: {
    border: `1px solid`,
    borderRadius: theme.spacing(.5)
  }
})

export const KCReactTabs = (theme) => ({
  tabs: {
    width: "100%",
    "& .react-tabs__tab--selected[aria-selected='true']": {
      background: "transparent",
      border: "none",
      color: theme.palette.primary.contrastText,
      borderBottom: `5px solid ${theme.palette.secondary.accent}`,
    }
  }
})

export const mainLayoutStyles = theme => ({
  mainLayoutContainer: {
    paddingTop: theme.spacing(2),
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    }
  },
  shiftContent: {},
  content: {
    height: '100%'
  },
  filter: {
    marginTop: '65px',
    height: 200
  },
  timerBox: {
    position: 'relative',
  },
  timer: {
    position: 'absolute',
    right: 20,
    fontSize: 13,
    color: 'gray',
    top: 0,
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(2),
      textAlign: 'right',
      maxWidth: theme.spacing(12)
    }
  },
  padding: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    }
  }
})

export const selfAlertingStyles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  buttongroup: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  chip: {
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.secondary.dark,
    margin: theme.spacing(0.5),
  },
  allcards: {
    marginTop: theme.spacing(8)
  },
  margintop: {
    marginTop: theme.spacing(2)
  },
  component: {
    paddingTop: theme.spacing(8)
  },
  title: {
    fontSize: 18,
    textTransform: "uppercase"
  },
  uppercase: {
    textTransform: "uppercase",
    fontSize: 14
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  text: {
    maxWidth: 500
  },
  marginleft: {
    marginLeft: theme.spacing(1)
  },
  grid: {
    padding: theme.spacing(2)
  },
  formelement: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  linearprogress: {
    height: 1
  },
  absolute: {
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'absolute',
    zIndex: 100,
    width: "100%",
    borderRadius: 0,
    maxHeight: 500,
    overflow: "auto",
    backgroundColor: theme.palette.secondary.base,
  }
})

export const breadCrumbStyles = (theme) => ({
  root: {
      '& > * + *': {
          marginTop: theme.spacing(0),
      },
  },
  main: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2)
    }
  },
  backbutton: {
      textTransform: 'none !important',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      background: theme.palette.background.default,
      color: theme.palette.primary.main,
      '&:hover': {
          color: theme.palette.primary.main,
          background: theme.palette.background.default,
      }
  },
  link: {
      color: 'inherit',
      textDecoration: 'inherit'
  },
  activeLink: {
      color: theme.palette.white
  },
  activeLinkDark: {
    color: theme.palette.black
  },
  activeLinkLight: {
    color: theme.palette.text?.subtitle
  },
  doccLink: {
    color: theme.palette.text?.link
  }
})

export const osmSelectStyles = (theme) => ({
  formControl: {
    padding: `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    minWidth: 180,
    maxWidth: 300,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette?.background?.primaryBase,
    '&:hover' : {
      backgroundColor: theme.palette?.background?.secondaryBase
    }
  },
  mutliSelect: {
    color: theme.palette?.white
  },
  menuItem: {
    '&.MuiListItem-root.Mui-selected' : {
      backgroundColor: theme.palette?.background?.selected
    },
    '&.MuiListItem-root:hover' : {
      backgroundColor: theme.palette?.background?.selected
    }
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.text?.caption
    }
  },
  menuTitles: {
    color: theme.palette?.text?.caption,
    textTransform: 'uppercase',
    padding: `0px ${theme.spacing(2)}px`
  }
})

export const osmOrderDetailsStyles = ((theme) => ({
  tablebody: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette?.secondary?.main
  },
  title: {
    paddingBottom: theme.spacing(1)
  },
  table: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75)
  }
}));

export const osmProductDetailsStyles = ((theme) => ({
  tableBody: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette?.primary?.primaryBase
  },
  tableTitle: {
    paddingBottom: theme.spacing(1)
  },
  detailsTable: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(1.75)
  },
  lightTableBody: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette?.white
  }
}));

export const osmTableActionsStyles = ((theme) => ({
  actions: {
    textAlign: 'center',
    display: 'block',
    border: `1px solid transparent`,
    width: 100,
    textTransform: 'capitalize',
    '&:hover, &:active' : {
      border: `1px solid ${theme.palette?.border}`,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette?.link?.hover
    }
  }
}));

export const osmLsrStyles = ((theme) => ({
  prevNavigator: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette?.text.subtitle,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
    }
  },
  title: {
    marginTop: theme.spacing(2.5),
    color: theme.palette?.primary?.contrastText,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  container: {
    backgroundColor: theme.palette?.primary?.main,
    borderRadius: theme.spacing(.75),
    color: theme.palette?.primary?.contrastText,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    }
  },
  spacer: {
    padding: `${theme.spacing(.5)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  inlineCenter: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: `0px ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(.5),
    }
  },
  textfield: {
    backgroundColor: theme.palette?.form?.baseLight,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(.5),
    border: `1px solid ${theme.palette?.secondary?.focus}`,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      maxHeight: theme.spacing(7.5),
      overflowX: 'hidden',
      overflowY: 'auto'
    }
  },
  warning: {
    color: theme.palette?.text?.error,
    padding: `0px ${theme.spacing(2)}px`,
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-end'
  },
  subtitle: {
    fontSize: 13,
    fontStyle: 'italic',
    paddingLeft: theme.spacing(.25),
    [theme.breakpoints.down('xs')]: {
      fontSize: 9,
    }
  },
  dimensionTable: {
    width: '30%',
    paddingLeft: theme.spacing(1)
  },
  dimensionRow: {
    textAlign: 'left',
    width: '30%'
  },
  dimensionHeader: {
    textTransform: 'uppercase',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  dimensionCell: {
    width: 'fit-content',
    marginBottom: theme.spacing(.5),
    border: `1px solid ${theme.palette?.secondary?.focus}`,
    borderRadius: theme.spacing(.5),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette?.form?.baseLight,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  noTableData: {
    width: '300%',
    textAlign: 'end',
    padding: `${theme.spacing(3)}px 0px`
  },
  tableDiv: {
    maxHeight: '66vh',
    overflow: 'auto'
  },
  topDiv: {
    display: 'inline-grid',
    justifyItems: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
    cursor: "pointer"
  },
  top: {
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette?.text?.primary,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette?.text?.primary
    },
    '&:active': {
      textDecoration: 'none',
      color: theme.palette?.text?.primary
    }
  },
  noInfo: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    padding: `${theme.spacing(2.5)}px 0px`
  },
  stackTable: {
    '& div::-webkit-scrollbar': {
      height: `${theme.spacing(1)}px !important`,
      width: `${theme.spacing(1)}px !important`,
    },
    '& div::-webkit-scrollbar-track': {
      border: `3px solid ${theme.palette.primary.base}`,
      borderRadius: 0,
      background: theme.palette?.divider
    },
    '& div::-webkit-scrollbar-thumb': {
      background: theme.palette.table?.scrollColor,
      borderRadius: '10px',
      border: `1px solid ${theme.palette.table?.scrollBorder}`
    },
  },
  stacksBar: {
    borderBottom: `1px solid ${theme.palette?.text?.primary}`
  },
  tabStackDetails: {
    "& .MuiTab-root": {
      borderRadius: `${theme.spacing(1.25)}px ${theme.spacing(1.25)}px 0px 0px`
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: theme.palette?.secondary?.secondary
    },
    "& .MuiTabs-indicator": {
      display: 'none'
    },
  },
  rotateIcon: {
    paddingLeft: theme.spacing(1)
  },
  shipInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2)}px 0px`
  },
  toggleButton: {
    "& .MuiToggleButton-root": {
      color: theme.palette.text?.primary,
      backgroundColor: theme.palette.card?.base
    },
    "& .MuiToggleButton-root.Mui-selected": {
      color: theme.palette.text?.main,
      backgroundColor: theme.palette.primary?.contrastText
    }
  }
}));

export const osmTrailerVisualStyles = ((theme) => ({
  main: {
    backgroundColor: theme.palette?.primary?.main
  },
  trailerGrid: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up('2500')]: {
      paddingLeft: '20%',
      paddingRight: '20%'
    },
    [theme.breakpoints.between('1650', '2500')]: {
      paddingLeft: '15%',
      paddingRight: '15%'
    },
    [theme.breakpoints.between('1400', '1650')]: {
      paddingLeft: '12%',
      paddingRight: '12%'
    },
    [theme.breakpoints.between('1280', '1400')]: {
      paddingLeft: '9%',
      paddingRight: '9%'
    },
    [theme.breakpoints.between('1140', '1280')]: {
      paddingLeft: '23%',
      paddingRight: '23%'
    },
    [theme.breakpoints.between('940', '1140')]: {
      paddingLeft: '15%',
      paddingRight: '15%'
    },
    [theme.breakpoints.between('800', '940')]: {
      paddingLeft: '10%',
      paddingRight: '10%'
    },
    [theme.breakpoints.down('800')]: {
      paddingLeft: '10%',
      paddingRight: '2%'
    }
  },
  infoGrid: {
    justifyContent: 'center',
    height: 'fit-content'
  },
  rowSpanGrid: {
    position: 'absolute',
    left: '10%',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    paddingTop: theme.spacing(3.5),
    [theme.breakpoints.between('1280', '1430')]: {
      left: '7%'
    },
    [theme.breakpoints.down('940')]: {
      left: '3%'
    },
    [theme.breakpoints.down('470')]: {
      left: 0
    }
  },
  rowSpan: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(28.5),
    margin: `${theme.spacing(1)}px 0px`,
    color: theme.palette?.text?.secondarySubtitle,
    [theme.breakpoints.between('470', '720')]: {
      height: 145
    },
    [theme.breakpoints.down('600')]: {
      fontSize: 10
    },
    [theme.breakpoints.down('470')]: {
      height: 135
    }
  },
  trailerBody: {
    minHeight: 680,
    border: `2px solid ${theme.palette?.black}`,
    borderRadius: theme.spacing(1.25),
    backgroundColor: theme.palette?.white,
    flexWrap: 'nowrap'
  },
  trailerLayoutLeft: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: 'fit-content'
  },
  trailerLayoutRight: {
    height: 'fit-content'
  },
  layoutTitle: {
    color: theme.palette?.tooltip?.main,
    textAlign: 'center',
    width: '100%'
  },
  package: {
    height: theme.spacing(28.5),
    width: theme.spacing(38.5),
    margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px 0px`,
    padding: theme.spacing(2),
    alignContent: 'flex-start',
    transition: '.4s',
    backgroundColor: theme.palette?.background?.quaternaryBase,
    boxShadow: `0px 1px 2px 0px ${theme.palette?.extra?.mainShadow}`,
    backgroundSize: 'cover',
    overflow: 'auto',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5)
    },
    '&::-webkit-scrollbar-thumb': {
      border: 'none',
      background: theme.palette?.extra?.scrollBar
    },
    '&:hover': {
      transition: '.4s',
      boxShadow: `0px 4px 12px 0px ${theme.palette?.extra?.primaryShadow}`,
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: theme.spacing(34.5),
      margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`
    },
    [theme.breakpoints.between('470', '720')]: {
      height: 145,
      width: 200,
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('470')]: {
      height: 135,
      width: 155,
      padding: theme.spacing(1)
    },
  },
  activePackage: {
    height: theme.spacing(28.5),
    width: theme.spacing(38.5),
    margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px 0px`,
    padding: theme.spacing(2),
    alignContent: 'flex-start',
    transition: '.4s',
    backgroundColor: theme.palette?.background?.quaternaryBase,
    border: `1px solid ${theme.palette?.legendColors?.greyLight}`,
    boxShadow: `0px 4px 12px 0px ${theme.palette?.extra?.primaryShadow}`,
    backgroundSize: 'cover',
    overflow: 'auto',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.5)
    },
    '&::-webkit-scrollbar-thumb': {
      border: 'none',
      background: theme.palette?.extra?.scrollBar
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: theme.spacing(34.5),
      margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
    [theme.breakpoints.between('470', '720')]: {
      height: 145,
      width: 200,
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('470')]: {
      height: 135,
      width: 155,
      padding: theme.spacing(1)
    },
  },
  packageInfo: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  packageInfo2: {
    padding: `${theme.spacing(.5)}px 0px`,
    textAlign: 'center',
    backgroundSize: 'cover'
  },
  cardRoot: {
    width: 500,
    border: `0.5px solid ${theme.palette?.legendColors?.greyLight}`,
    margin: `${theme.spacing(1.5)}px 0px`
  },
  cardHeader: {
    backgroundColor: theme.palette?.card?.header,
    borderBottom: `0.5px solid ${theme.palette?.legendColors?.greyLight}`
  },
  divider: {
    background: theme.palette?.legendColors?.greyLight,
    margin: `${theme.spacing(1)}px 0px`
  },
  legendGrid: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: `0px ${theme.spacing(5)}px`,
    justifyContent: 'space-between',
    margin: `${theme.spacing(1)}px 0px`
  },
  legendGrid2: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(7)
  },
  primaryText: {
    color: theme.palette?.primary?.contrastText,
    marginRight: theme.spacing(1),
    display: 'inline-flex'
  },
  secondaryText: {
    color: theme.palette?.text?.secondary
  },
  compartmentGrid: {
    textAlign: 'center'
  },
  dimension: {
    color: theme.palette?.text?.secondary,
    marginTop: theme.spacing(1.5)
  },
  lightText: {
    color: theme.palette?.white
  },
  hazIcon: {
    paddingRight: theme.spacing(1)
  }
}));

export const appbarStyles = ((theme) => ({
  root: {
    boxShadow: 'none',
    flexGrow: 1,
  },
  app: {
    backgroundColor: theme.palette.topbar,
    borderBottom: `1px solid ${theme.palette.border}`,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  grow: {
    flexGrow: 1
  },
  toolbar: {
    padding: 0
  },
  avatar: {
    border: `2px solid ${theme.palette.white}`,
    color: theme.palette.white,
    backgroundColor: theme.palette.topbar,
  },
  avatarText: {
    color: theme.palette.white,
  },
  username: {
    color: theme.palette.white,
    paddingLeft: theme.spacing(1)
  },
  switch: {
    paddingRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    alignItems: "center",
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  button: {
    height: theme.spacing(4.5),
    color: "#fff",
    transition: '.2s ease-in',
    background: 'transparent',
    '&:hover': {
      background: `${theme.palette.primary.pressed} !important`,
      borderColor: 'none',
    }
  },
  center: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(1.5)
  },
  center2: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  }
}))

export const laoViews = (theme => ({
  laoViewsGrid: {
    position: 'absolute',
    right: theme.spacing(4.5),
    flexGrow: 1,
    zIndex: 100,
    maxHeight: theme.spacing(70),
    width: theme.spacing(50),
    textAlign: 'left',
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `1px solid ${theme.palette?.card?.border}`,
    [theme.breakpoints.down("xs")]: {
      right: theme.spacing(0.5),
      width: theme.spacing(46.25),
    }
  },
  laoAddViewText: {
    backgroundColor: theme.palette?.background?.drawer,
    width: '70%',
    marginRight: theme.spacing(1),
  },
  laoAddViewButton: {
    width: '25%',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.border}`,
    backgroundColor: theme.palette?.white,
    '&.MuiButton-contained.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  label: {
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(.5),
    color: theme.palette?.black
  }
}));

export const laoFilterBox = (theme => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    borderRadius: theme.spacing(3),
    background: theme.palette.secondary.base,
    minHeight: 130,
    position: "relative",
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    }
  },
  progress: {
    backgroundColor: theme.palette?.legendColors?.greyLight,
    width: "98%",
    position: "absolute",
    height: '2px',
    borderRadius: "50%",
    top: -3
  },
  textlink: {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  laoChip: {
    border: `1px solid ${theme.palette.primary.contrastText}`,
    height: 35,
    backgroundColor: theme.palette.chip?.tableChips,
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1)
    }
  },
  laoViewActions: {
    textAlign: 'center',
    margin: `${theme.spacing(1)}px 0px`,
    [theme.breakpoints.up('md')]: {
      borderLeft: `2px solid ${theme.palette.primary?.pressed}`,
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: `2px solid ${theme.palette.primary?.pressed}`,
    }
  },
  saveIcon: {
    color: theme.palette?.text?.primary
  },
  laoSaveButton: {
    backgroundColor: theme.palette?.primary.active,
    color: theme.palette.white,
    '&.MuiButton-contained:hover': {
      backgroundColor: theme.palette?.background.primaryBase
    },
    borderRadius: 0,
  },
  laoCancelButton: {
    color: theme.palette?.text.greyMedium,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  laoAddText: {
    border: `1px solid ${theme.palette?.background.secondaryBase}`,
    borderRadius: theme.spacing(0.5),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: "none",
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}));