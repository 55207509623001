import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "./locales/en/translation";
import { TRANSLATIONS_FR } from "./locales/fr/translation";
import { TRANSLATIONS_DE } from "./locales/de/translation";
import { TRANSLATIONS_ES } from "./locales/es/translation";
import { TRANSLATIONS_PT } from "./locales/pt/translation";

const RESOURCES = {
  en: {
    translation: TRANSLATIONS_EN
  },
  fr: {
    translation: TRANSLATIONS_FR
  },
  de: {
    translation: TRANSLATIONS_DE
  },
  es: {
    translation: TRANSLATIONS_ES
  },
  pt: {
    translation: TRANSLATIONS_PT
  }
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  // caches: ['localStorage']
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: DETECTION_OPTIONS,
    resources: RESOURCES
  });
i18n.changeLanguage(localStorage.getItem('i18nextLng'));

export default i18n;