import { unionWith, isEqual, isEmpty, uniq } from 'lodash';
import {
    selfAlertingConstants
} from '../constants';
import selfalertingFilters from "reduxLib/constdata/selfalertingFilters";
import { getRemovedSG } from './selectionsReducer';

const initialState = {
    loading: true,
    alertsloading: true,
    metadata: {},
    alerts: [],
    filters: selfalertingFilters,
    cuso: { CUST: [], SALES_OFFICE: [] },
    save_loading: false
}

const updateAlerts = (alerts, newalert) => {
    if (isEmpty(newalert)) return alerts;

    const newalertid = newalert?.alertId;
    const alertids = alerts.map(d => d.alertId);

    let updatedalerts = alerts.map(d => {
        if (d.alertId === newalertid) {
            return newalert;
        }
        return d;
    })
    if (alertids.includes(newalertid)) {
        return updatedalerts
    }
    return [...updatedalerts, newalert];
}


const selfAlertingReducer = (state = initialState, action) => {

    const {
        data,
        loading,
    } = action.payload || {};

    switch (action.type) {

        case selfAlertingConstants.LOADING:
            return {
                ...state,
                loading
            }
        case selfAlertingConstants.SAVE_ALERT_LOADER:
            return {
                ...state,
                save_loading: action.payload
            }

        case selfAlertingConstants.RESET_SELF_ALERT_STATE:
            return {
                ...initialState,
                alerts: state.alerts,
                alertsloading: false
            };

        case selfAlertingConstants.ALERTS_LOADING:
            return {
                ...state,
                alertsloading: loading
            }

        case selfAlertingConstants.SAVE_ALERT_SUCCESS:
            return {
                ...state,
                alerts: updateAlerts(state.alerts, action.payload.alert),
                alertstatus: action.payload.status,
                alertdata: action.payload,
                savedAlertId: action.payload?.alert?.alertId || undefined
            }

        case selfAlertingConstants.SAVE_ALERT_FAILED:
            return {
                ...state,
                alertstatus: action.payload.status,
                alertdata: action.payload,
            }

        case selfAlertingConstants.LOAD_COMPARISION_METADATA:
            return {
                ...state,
                metadata: data,
                loading
            }

        case selfAlertingConstants.GET_SAVED_ALERTS:
            return {
                ...state,
                alerts: data,
                alertsloading: loading
            }

        case selfAlertingConstants.SAVE_SLEF_ALERTING_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload.filter
                }
            }
        case selfAlertingConstants.SF_ADD_CUSO_MULTIPLE_SUCCESS:
            return { ...state, cuso: action.payload }

        case selfAlertingConstants.DELETE_SELF_ALERT:
            return {
                ...state,
                alerts: state.alerts.filter(d => d.alertId !== action.payload.alertId)
            }

        case selfAlertingConstants.SF_ADD_CUSO_SUCCESS:

            if (action.payload.selectionType === "CUST") {
                const spreadPayload = action.payload.salesOrg.map((value) => ({ ...action.payload, salesOrg: value }))
                return {
                    ...state, cuso: {
                        ...state.cuso,
                        CUST: unionWith(spreadPayload, state.cuso.CUST, isEqual),
                    }
                }

            } else if (action.payload.selectionType === "SALES_OFFICE") {

                const findSO = state.cuso.SALES_OFFICE.filter(({ salesOffice, salesOrg }) =>
                    action.payload.salesOffice === salesOffice && action.payload.salesOrg === salesOrg).length > 0
                return {
                    ...state, cuso: {
                        ...state.cuso,
                        SALES_OFFICE: findSO ? state.cuso.SALES_OFFICE.map((SO) =>
                            action.payload.salesOffice === SO.salesOffice
                                && action.payload.salesOrg === SO.salesOrg ? { ...SO, distributionChannel: action.payload.distributionChannel } : SO
                        ) : [...state.cuso.SALES_OFFICE, action.payload],
                    }
                }
            } else {
                return state
            }

        case selfAlertingConstants.SF_REMOVE_CUSO_SUCCESS:

            if (action.payload.selectionType === "CUST") {
                const { distributionChannel, salesOrg, customerName } = action.payload
                return {
                    ...state,
                    cuso: {
                        ...state.cuso,
                        CUST: state.cuso.CUST.filter((object) => !(object.distributionChannel === distributionChannel
                            && object.customerName === customerName && salesOrg.includes(object.salesOrg)
                        ))
                    }
                }
            } else if (action.payload.selectionType === "SALES_OFFICE") {
                var { distributionChannel, salesOrg, salesOffice } = action.payload
                return {
                    ...state,
                    cuso: {
                        ...state.cuso,
                        SALES_OFFICE: state.cuso.SALES_OFFICE.filter((object) => !(object.distributionChannel === distributionChannel && object.salesOffice === salesOffice && object.salesOrg === salesOrg)),
                    }
                }
            } else {
                return state
            }

        case selfAlertingConstants.SF_ADD_SG_SUCCESS:

            var { shortName, reducerObject } = action.payload

            const findSO = state.cuso.SALES_OFFICE.filter((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice)
            const updatedSO = state.cuso.SALES_OFFICE.map((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice ?
                { ...SO, distributionChannel: reducerObject.distributionChannel, salesGroup: SO.salesGroup ? uniq([...SO?.salesGroup, shortName]) : [shortName] } : SO)

            return { ...state, cuso: { ...state.cuso, SALES_OFFICE: isEmpty(findSO) ? [...state.cuso.SALES_OFFICE, { ...reducerObject, salesGroup: [shortName] }] : updatedSO } }

        case selfAlertingConstants.SF_REMOVE_SG_SUCCESS:

            return getRemovedSG({ state, action });

        default:
            return state
    }
}

export default selfAlertingReducer;