import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LaoFilterBy from 'components/LAO/LaoFilterBy';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LAOFilterChips from 'components/LAO/LAOFilterChips';
import LAOTabsWrapper from 'components/LAO/LAOTabsWrapper';
import LAOGlobalContext from 'context/LAOGlobalContext';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { useDeepCompareEffect } from 'react-use';
import { laoGetLocationsService } from 'reduxLib/services/LaoServices/laoLocationService';
import { laoGetFavourites } from 'reduxLib/services/LaoServices/laoFavService';
import LoadingPage from 'components/common/LoadingPage';
import ErrorHandlerPage from 'components/common/ErrorHandlerPage';
import { useRouteMatch, generatePath, useParams } from 'react-router-dom';
import { generatePayload } from 'helpers/emeaHelpers';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },
    // background: theme.palette.primary.base
  },
  root1: {
    marginTop: theme.spacing(8),
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },
    // background: theme.palette.primary.base
  },
  body: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
      marginTop: theme.spacing(1),
    }
  }
}));

const LaoMainLayout = ({ component, region }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [params] = useSetQuery();
  const { tab } = useParams();
  const setMultiParams = useMultiSetQuery();
  const [memoizedValue, setMemoizedValue] = useState(null);
  const { locations_err, locations_loading } = useSelector(({ laoSites }) => laoSites);
  const language = localStorage.getItem('i18nextLng');
  const match = useRouteMatch();

  const { loading_all_favs, favouriteBusinessUnits, favouriteSiteNums } =
    useSelector(({ laoFavorites }) => laoFavorites);

  const loadFav = params.loadFav === true;

  useEffect(() => {
    if (!loading_all_favs && loadFav) {
      const path = generatePath(match.path, {
        tab: favouriteSiteNums.length > 0 ? "myselections": "network"
      });
      setMultiParams({
        BU: JSON.stringify(favouriteBusinessUnits),
        sites: JSON.stringify(favouriteSiteNums),
        loadFav: undefined
      }, path);
    }
  }, [loading_all_favs, loadFav]);

  useDeepCompareEffect(() => {
    if (params.BU) {
      const { locations, BU } = generatePayload({
        ...params,
        tab,
        viewby: "sites"
      });
      setMemoizedValue({
        locations,
        BU,
        language
      });
    }
  }, [params.BU, params.sites, tab, language]);


  useEffect(() => {
    const controller = new AbortController();
    const favController = new AbortController();
    dispatch(laoGetFavourites({ controller: favController }))
    dispatch(laoGetLocationsService({ controller }));
    return () => {
      controller.abort();
      favController.abort();
    }
  }, []);


  return (
    <div data-testid="laomainlayout" className={(locations_loading && loading_all_favs) || loadFav ? classes.root1 : classes.root}>
      {
        ((locations_loading && loading_all_favs) || loadFav)
          ? <LoadingPage />
          : locations_err
            ? <ErrorHandlerPage errTagline={locations_err === 'lao_restricted' ? t("we_are_sorry") : null} errMessage={t(locations_err)} />
            : <>
              <Grid container alignItems='center'>
                <Grid item>
                  <LaoFilterBy />
                </Grid>
              </Grid>
              <LAOTabsWrapper region={region} />
              <LAOGlobalContext.Provider value={memoizedValue}>
                {memoizedValue && <>
                  <LAOFilterChips />
                  <div className={classes.body} >
                    {component}
                  </div>
                </>}
              </LAOGlobalContext.Provider>
            </>
      }
    </div>
  )
}

export default LaoMainLayout;