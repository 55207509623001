import React from 'react'
import { useTranslation } from "react-i18next"
import MaterialTable from 'material-table';
import { Grid, Box, Typography, makeStyles } from "@material-ui/core"
import { appTheme, itemDetailsStyles } from 'theme';
import fetch from "../../reduxLib/services/serviceHelpers";
import {endpoints} from "../../configs/endpoints"


const useStyles = makeStyles(itemDetailsStyles)

let options = {
    headerStyle: {
        backgroundColor: appTheme?.palette.card.base,
        color: appTheme?.palette.white,
        fontSize: "14px",
        fontWeight: 600,
        letterSpacing: "0.78px",
        lineHeight: "17px",
        whiteSpace: 'nowrap',
        borderBottom: '2px solid white',
        textTransform: "uppercase",
    },
    loadingType: "linear",
    draggable: false,
    sorting: false,
    search: false,
    paging: false,
    pageSize: 5,
    showTitle: false,
    showFirstLastPageButtons: true,
    pagination: false,
    toolbar: false,
    maxBodyHeight: "30vh"
}

export const ItemDetailsTable = ({ orderStatusTableRowId, columns, testId, rowStyle }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const tablebody = {
        region: "NA",
        orderStatusTableRowId
    }

    options = { ...options, rowStyle }

    return (
        <Box data-testid={testId} justifyContent="center" className={classes.tablebody}>
            <Typography variant="h3" className={classes.title}>{t('product_details')}</Typography>
            <Grid className={classes.table}>
                <MaterialTable
                    localization={{
                        body: {
                            emptyDataSourceMessage: t("no_records_to_display")
                        }
                    }}
                    columns={columns}
                    options={options}
                    style={{
                        backgroundColor: appTheme.palette.primary.base,
                        color: 'white',
                        borderBottom: '1px solid white',
                        width: 1500
                    }}
                    data={query =>
                        new Promise((resolve, reject) => {
                            let url = endpoints.itemDetails;
                            fetch(url, {
                                method: "post",
                                body: tablebody
                            })
                                .then(response => {
                                    return response?.json() || {};
                                })                            
                                .then(result => {
                                    const tempData = result?.orderItems?.map((data, i) => {
                                        let d = {};
                                        Object.keys(data).map(item => {
                                            if (data[item] !== 0 && !data[item]) {
                                                d[item] = "-";
                                            } else {
                                                d[item] = data[item];
                                            }
                                        });
                                        return {
                                            ...d,
                                            slno: (i + 1),
                                        }
                                    }) || [];
                                    resolve({
                                        data: tempData
                                    })
                                })
                                .catch(e => {
                                    resolve({
                                        data: []
                                    })
                                })
                        })
                    }
                />
            </Grid>
        </Box>
    )
}

export default ItemDetailsTable;
