import React, { useState, useEffect } from 'react';
import { useLocation, Redirect } from "react-router-dom";
import { makeStyles, Typography, Grid, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@material-ui/core';
import Topbar from "./Topbar";
import { withOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Card from 'components/common/Card';
import KCButton from 'components/common/Button';
import { updateCSTRank } from "../../reduxLib/services"

const useStyles = makeStyles((theme) => ({
  main: {
    padding: `${theme.spacing(10)}px ${theme.spacing(3)}px`
  },
  items: {
    padding: theme.spacing(1.5)
  },
  button: {
    border: `1px solid ${theme.palette.common.white}`,
    margin: theme.spacing(1.5),
    backgroundColor: theme.palette.secondary.base
  }
}));

const UpdateCSTRanking = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { cstUpdated, loading } = useSelector(({ analytics }) => analytics);

  let queryParams = new URLSearchParams(useLocation().search).toString()
  const parsed = queryString.parse(queryParams);
  // const CSTCarrierList = parsed.CST.split(',').join(', ');
  const CTCarrierList = parsed.CT.split(',').join(', ');

  const handleDialog = () => {
    setOpen(!open);
  };

  const handleTabCloseDialog = () => {
    setOpenPopup(!openPopup);
  };

  const submitData = () => {
    setOpen(false);
    const data = {
      lane: parsed.Lane,
      // CSTRankingList: parsed.CST.split(','),
      // CTRankingList: parsed.CT.split(',')
    }
    dispatch(updateCSTRank(data))
  }

  useEffect(() => {
    if (!loading) {
      setOpenPopup(true);
    }
  }, [cstUpdated, loading]);

  return (
    <>
    {(parsed && parsed.Lane && parsed.CT) ? (
      <>
    <Topbar showHamburgerMenu={false} />
      <Grid container className={classes.main} data-testid="updateRanking">
        <Card cardtitle={ 
          <Typography variant="h3">UPDATE CST RANKING</Typography> 
        }>
          <Grid item container className={classes.items}>
            <Grid item lg={2}><Typography variant="h4">Lane</Typography></Grid>
            <Grid item lg={1}><Typography variant="h4">:</Typography></Grid>
            <Grid item lg={9}><Typography>{parsed.Lane}</Typography></Grid>
          </Grid>
          {/* <Grid item container className={classes.items}>
            <Grid item lg={2}><Typography variant="h4">CST Carrier Ranking</Typography></Grid>
            <Grid item lg={1}><Typography variant="h4">:</Typography></Grid>
            <Grid item lg={9}><Typography>{CSTCarrierList}</Typography></Grid>
          </Grid> */}
          <Grid item container className={classes.items}>
            <Grid item lg={2}><Typography variant="h4">CT Carrier Ranking</Typography></Grid>
            <Grid item lg={1}><Typography variant="h4">:</Typography></Grid>
            <Grid item lg={9}><Typography>{CTCarrierList}</Typography></Grid>
          </Grid>
          {cstUpdated === true ? 
          <KCButton id="button" variant="outlined" color="primary" type="submit" 
            className={classes.button} disabled>
            Update
          </KCButton> :
          <KCButton id="button" variant="outlined" color="primary" type="submit" 
            className={classes.button} onClick={handleDialog}>
            Update
          </KCButton>
          }
          <Dialog open={open} onClose={handleDialog} aria-describedby="dialogContent" 
            PaperProps={{
              style: {
                  backgroundColor: theme.palette.card.base,
                  color: theme.palette.white
              }
            }}
          >
            <DialogContent>
              <DialogContentText id="dialogContent">
                <Typography variant="subtitle2" color="primary">Do you want to update the current CST Ranking?</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <KCButton className={classes.button} onClick={submitData} color="primary">
                Yes
              </KCButton>
              <KCButton className={classes.button} onClick={handleDialog} color="primary" autoFocus>
                No
              </KCButton>
            </DialogActions>
          </Dialog>

          {(cstUpdated === true || cstUpdated === false) && <Dialog open={openPopup} onClose={handleTabCloseDialog} aria-describedby="dialogTabClose" 
            PaperProps={{
              style: {
                  backgroundColor: '#272C4F',
                  color: 'white'
              }
            }}
          >
          <DialogContent>
            <DialogContentText id="dialogTabClose">
              {cstUpdated === true && <Typography variant="subtitle2" color="primary">CST Ranking updated successfully. Please close the current tab.</Typography> }
              {cstUpdated === false && <Typography variant="subtitle2" color="primary">Something went wrong! Please try again later.</Typography> }
            </DialogContentText>
          </DialogContent>
          </Dialog>}
        </Card>
      </Grid>
     </> ) :
      <Redirect to='/not-found'/>
     }
    </> 
  );
};

UpdateCSTRanking.propTypes = {
  children: PropTypes.node
};


export default (withOktaAuth(UpdateCSTRanking));