import React from "react";
import { makeStyles, Hidden, Grid } from '@material-ui/core';
import NextArrowIcon from "../../assets/images/next-arrow.svg";
import PreviousArrowIcon from "../../assets/images/prev-arrow.svg";
import ActiveCarousel from "../../assets/images/active_carousel.svg";
import '@brainhubeu/react-carousel/lib/style.css';
import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: 0,
    [theme.breakpoints.up("md")]: {
      flexFlow: 'row nowrap',
    },
    [theme.breakpoints.down("sm")]: {
      margin: `0px ${theme.spacing(2)}px`,
    }
  },
  cursor: {
    cursor: 'pointer'
  },
  dots: {
    "& li": {
      "& .BrainhubCarousel__dot::before": {
        fontSize: theme.spacing(1),
        backgroundColor: theme.palette.white,
        opacity: 1
      },
      "& .BrainhubCarousel__dot--selected::before": {
        backgroundColor: theme.palette.black,
        content: `url(${ActiveCarousel})`
      }
    }
  }
}));

const ChartCarouselComponent = ({ chartsArray }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const changeSlide = (e) => {
    if (value !== e && e >= 0 && e < chartsArray.length ) {
      setValue(e)
    }
  }

  return (
    <div data-testid="slickCarousel">
      {/* Desktop view */}
      <Hidden smDown>
        <Grid container wrap="nowrap">
          {chartsArray && chartsArray.map(chart =>
            chart
          )}
        </Grid>
      </Hidden>

      {/* Mobile view */}
      <Hidden mdUp>
        <Carousel
          plugins={[
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: <img src={PreviousArrowIcon} className={classes.cursor} />,
                arrowRight: <img src={NextArrowIcon} className={classes.cursor} />,
                addArrowClickHandler: true,
              }
            }
          ]}
          value={value}
          slides={chartsArray && chartsArray.map(chart =>
            chart
          )}
          className={classes.slidemain}
          onChange={changeSlide}
        />
        <Dots value={value} onChange={changeSlide} number={chartsArray?.length} className={classes.dots} />
      </Hidden>
    </div>
  );
}

export default ChartCarouselComponent;