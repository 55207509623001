
const distinboundfilters = {
    searchStringList: {
        type: "text",
        stringToArray: true,
        name: "search",
        data: null
    },
    inboundOrderExecutionBucket: {
        type: "checkbox",
        name: "shipment_status",
        data: [
            { name: "in_transit", value: "INBOUND_IN_TRANSIT", checked: false },
            { name: "in_yard", value: "INBOUND_IN_YARD", checked: false },
            { name: "unloading", value: "INBOUND_UNLOADING", checked: false },
            { name: "unloading_completed", value: "INBOUND_UNLOADING_COMPLETED", checked: false },
        ]
    },
    destState: {
        type: "text",
        name: "destination_state",
        data: null
    },
    destCity: {
        type: "text",
        name: "destination_city",
        data: null
    },
    collectiveNumList: {
        type: "text",
        name: "collectiveNumber",
        stringToArray: true,
        data: null
    },
    promotionalOrder: {
        type: "checkboxradio",
        name: "promotionalOrder",
        defaultValue: null,
        data: [
            { name: "show_only_promotional_orders", value: "Yes", checked: false },
            { name: "hide_promotional_orders", value: "No", checked: false },
        ]
    },
    yardArrivalDateTimeDestTZ: {
        type: "date",
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        name: "date_arrived_in_yard",
        shortName: 'Yard Arrival',
        data: null
    },
    durationInYard: {
        type: "checkboxradio",
        name: "duration_in_yard",
        data: [
            { name: "less_than_24_hours", value: { startTimeMins: null, endTimeMins: 1439 }, checked: false },
            { name: "between_24_and_48_hours", value: { startTimeMins: 1440, endTimeMins: 2880 }, checked: false },
            { name: "greater_than_72_hours", value: { startTimeMins :4321 , endTimeMins: null }, checked: false },
        ]
    },
    inboundOrderExecutionHealth: {
        type: "checkbox",
        name: "health",
        data: [
            { name: "unhealthy", value: "RED", checked: false },
            { name: "needs_attention", value: "YELLOW", checked: false },
            { name: "healthy", value: "GREEN", checked: false }

        ]
    },
    materialNumberList: {
        type: "text",
        name: "material_id",
        stringToArray: true,
        data: null
    }
}

export default distinboundfilters;
