import laoFavConstants from "reduxLib/constants/LAO/laoFavConstants";

const initialState = {
    loading_all_favs: true,
    loading: true,
    favouriteBusinessUnits: [],
    favouriteSiteNums: [],
    status: {}
}

const laoFavReducer = (state = initialState, action) => {
    switch (action.type) {
        case laoFavConstants.VIEWS_LOADING:
            return {
                ...state,
                loading: action.payload,
                status: {}
            };

        case laoFavConstants.LAO_GET_ALL_FAVS_SUCCESS:
        case laoFavConstants.LAO_GET_ALL_FAVS_FAILED:
            return {
                ...state,
                loading_all_favs: false,
                loading: false,
                ...action.payload
            };

        case laoFavConstants.LAO_FAV_BU_SUCCESS:
        case laoFavConstants.LAO_FAV_BU_FAILED:
            return {
                ...state,
                loading: false,
                favouriteBusinessUnits: action.payload,
                status: action.status
            };

        case laoFavConstants.LAO_FAV_SITE_SUCCESS:
        case laoFavConstants.LAO_FAV_SITE_FAILED:
            return {
                ...state,
                loading: false,
                favouriteSiteNums: action.payload,
                status: action.status
            };

        default:
            return state;
    }
}

export default laoFavReducer;  