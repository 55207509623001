import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


const SelectRadioDropdown = ({labelId, testId, value, MenuProps, title, data, onChange, styleClasses, searchBar, keyType, ...props}) => {

  return (
    <FormControl variant="outlined" className={styleClasses.formControl} data-testid="selectRadio">
      <Select
        labelId={labelId}
        SelectDisplayProps={ {"data-testid" : testId} }
        value={value}
        onChange={onChange}
        input={<Input />}
        displayEmpty={true}
        renderValue={(selected) => selected ? selected : `Select ${title}`}
        MenuProps={MenuProps}
        disableUnderline
        className={styleClasses.select}
        {...props}
      >
        {searchBar ? 
          <TextField size="small" variant='outlined' autoFocus fullWidth
            placeholder="Search" inputProps={{'data-testid': `${testId}-search`}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            defaultValue={props?.searchValue}
            value={props?.searchValue}
            onChange={(e) => props?.searchFunction(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        : null}
        {data?.map((item) => (
          keyType ?
            <MenuItem key={item[keyType]} value={item[keyType]} data-testid={`menu-${item[keyType]}`} className={styleClasses.menuList} >
              <Radio checked={value === item[keyType]} inputProps={{'data-testid': `radio_${item[keyType]}`}} className={styleClasses.radio}/>
              <ListItemText primary={item[keyType]} />
            </MenuItem>
          : <MenuItem key={item} value={item} data-testid={`menu-${item}`} className={styleClasses.menuList} >
              <Radio checked={value === item} inputProps={{'data-testid': `radio_${item}`}} className={styleClasses.radio}/>
              <ListItemText primary={item} />
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectRadioDropdown;