import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FavBlock } from 'components/header/FavouritesModule';
import KCChip from 'components/common/Chip';
import { emeaCustSeparator } from "configs/appConstants";
import { emeaBusinessFavService, emeaUpdateFavs } from 'reduxLib/services/EmeaServices/emeaFavoriteService';
import { emeaLocationFavService } from 'reduxLib/services/EmeaServices/emeaLocationServices';
import { emeaCustFavService } from 'reduxLib/services/EmeaServices/emeaCustomerServices';
import { emeaSOSGFavService } from 'reduxLib/services/EmeaServices/emeaSalesOfficeGroupServices'; 
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import FavoriteIcon from 'assets/images/favorite.svg';

const useStyles = makeStyles((theme) => ({
  box: {
    maxHeight: 408,
    overflowX: 'auto'
  },
  container: {
    alignItems: 'center',
    margin: `${theme.spacing(1)}px 0px`
  },
  actionGrid: {
    border: `none !important`,
    position: 'absolute',
    bottom: theme.spacing(2.5),
    width: 'auto'
  },
  actionsButton: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.primary?.button}`,
    color: theme.palette?.primary?.button,
    marginRight: theme.spacing(2)
  },
  favModule: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px'
    }
  },
  favModuleEmpty: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.menu.base,
    }
  }
}));

const EmeaFavorites = ({ region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const { all_favs, bu_fav_err, bu_fav_loading } = useSelector(({ emeaGlobalFavs }) => emeaGlobalFavs);
  const { locations_fav_loading, locations_fav_err } = useSelector(({ emeaSites }) => emeaSites);
  const { cust_fav_loading, cust_fav_err } = useSelector(({ emeaCustomers }) => emeaCustomers);
  const { sosg_fav_loading, sosg_fav_err } = useSelector(({ emeaSalesOfc }) => emeaSalesOfc);

  const [BUFavs, setBUFavs] = useState(all_favs?.favouriteBusinessUnits || []);
  const [siteFavs, setSiteFavs] = useState(all_favs?.favouriteSiteNums || []);
  const [custFavs, setCustFavs] = useState(all_favs?.favouriteCustomers || []);
  const [SOFavs, setSOFavs] = useState(all_favs?.favouriteSalesOffices || []);

  const handleBUFav = (bu) => {
    const y = BUFavs.filter(x => x !== bu);
    setBUFavs(y);
  }

  const handleSitesFav = (siteNum) => {
    const y = siteFavs.filter(x => x !== siteNum);
    setSiteFavs(y);
  }

  const handleCustFav = (cust) => {
    const y = custFavs.filter(x => x !== cust);
    setCustFavs(y);
  }

  const handleSOSGFav = (so) => {
    const y = SOFavs.filter(x => x !== so);
    setSOFavs(y);
  }

  const handleApplyFavs = () => {
    if(!isEqual(all_favs?.favouriteBusinessUnits, BUFavs)) {
      dispatch(emeaBusinessFavService({ region: region, favouriteBusinessUnits: BUFavs }))
    }
    if(!isEqual(all_favs?.favouriteSiteNums, siteFavs)) {
      dispatch(emeaLocationFavService({ region: region, favouriteSiteNums: siteFavs }))
    }
    if(!isEqual(all_favs?.favouriteCustomers, custFavs)) {
      dispatch(emeaCustFavService({ region: region, favouriteCustomers: custFavs }));
    }
    if(!isEqual(all_favs?.favouriteSalesOffices, SOFavs)) {
      dispatch(emeaSOSGFavService({ region: region, favouriteSalesOffices: SOFavs }));
    }
  }

  useEffect(() => {
    if(!bu_fav_loading && bu_fav_err === 'success') {
      const body = {...all_favs, favouriteBusinessUnits: BUFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [bu_fav_err, bu_fav_loading])

  useEffect(() => {
    if(!locations_fav_loading && locations_fav_err === 'success') {
      const body = {...all_favs, favouriteSiteNums: siteFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [locations_fav_loading, locations_fav_err])

  useEffect(() => {
    if(!cust_fav_loading && cust_fav_err === 'success') {
      const body = {...all_favs, favouriteCustomers: custFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [cust_fav_loading, cust_fav_err])

  useEffect(() => {
    if(!sosg_fav_loading && sosg_fav_err === 'success') {
      const body = {...all_favs, favouriteSalesOffices: SOFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [sosg_fav_loading, sosg_fav_err])

  const emeaHasNoFavs = () => {
    return isEmpty(BUFavs) &&  isEmpty(siteFavs) &&  isEmpty(custFavs) &&  isEmpty(SOFavs);
  }

  return (
    <Grid data-testid="favmodule" className={emeaHasNoFavs() ? classes.favModuleEmpty : classes.favModule}>
      <Grid container item>
        <Box mt={2} className={classes.box}>
          { emeaHasNoFavs() ? isMobile ? <FavBlock /> 
              : <Typography variant="subtitle1" align="center"> { t("you_do_not_have_any_favourites") } </Typography> 
            : <Typography variant="h4"> { t("list_of_favourited_selections") } </Typography>
          }
          {BUFavs.length > 0 && 
            <Grid container className={classes.container} data-testid="buFavs">
              <Grid item className={classes.item} sm={12} md={2}>
                <Typography variant="h4"> { t('business_unit') } </Typography>
              </Grid>
              <Grid item className={classes.item} sm={12} md={10}>
                {
                  BUFavs.map(bu => {
                    return (<KCChip label={bu} deleteIcon={<img src={FavoriteIcon} alt="fav-icon" data-testid={`deleteFavBU-${bu}`}/>} onDelete={() => handleBUFav(bu)} />)
                  })
                }
              </Grid>
            </Grid>
          }
          {siteFavs.length > 0 && 
            <Grid container className={classes.container} data-testid="siteFavs">
              <Grid item className={classes.item} sm={12} md={2}>
                <Typography variant="h4"> { t('location') } </Typography>
              </Grid>
              <Grid item className={classes.item} sm={12} md={10}>
                {
                  siteFavs.map(site => {
                    return (<KCChip label={site} deleteIcon={<img src={FavoriteIcon} alt="fav-icon" data-testid={`deleteFavSite-${site}`}/>} onDelete={() => handleSitesFav(site)} />)
                  })
                }
              </Grid>
            </Grid>
          }
          {custFavs.length > 0 && 
            <Grid container className={classes.container} data-testid="custFavs">
              <Grid item className={classes.item} sm={12} md={2}>
                <Typography variant="h4"> { t('customer') } </Typography>
              </Grid>
              <Grid item className={classes.item} sm={12} md={10}>
                {
                  custFavs.map(cust => {
                    const name = cust.split(emeaCustSeparator);
                    return (<KCChip label={`${name[3]} (${name[0]} - ${name[2]})`} deleteIcon={<img src={FavoriteIcon} alt="fav-icon" data-testid={`deleteFavCust-${cust}`}/>} onDelete={() => handleCustFav(cust)} />)
                  })
                }
              </Grid>
            </Grid>
          }
          {SOFavs.length > 0 && 
            <Grid container className={classes.container} data-testid="sosgFavs">
              <Grid item className={classes.item} sm={12} md={2}>
                <Typography variant="h4"> { t('sales_office_group') } </Typography>
              </Grid>
              <Grid item className={classes.item} sm={12} md={10}>
                {
                  SOFavs.map(so => {
                    const name = so.split('_');
                    return (
                      name?.length > 4 ? 
                        <KCChip label={`${name[3]} (${name[0]} - ${name[2]}) ${name[4]} `} deleteIcon={<img src={FavoriteIcon} alt="fav-icon" data-testid={`deleteFavSO-${so}`}/>} onDelete={() => handleSOSGFav(so)} />
                      :  <KCChip label={`${name[3]} (${name[0]} - ${name[2]})`} deleteIcon={<img src={FavoriteIcon} alt="fav-icon" data-testid={`deleteFavSO-${so}`}/>} onDelete={() => handleSOSGFav(so)} />
                    )
                  })
                }
              </Grid>
            </Grid>
          }
        </Box>
      </Grid>
      <Grid container item className={classes.actionGrid}>
        <Button variant="outlined" className={classes.actionsButton} data-testid="apply-changes" onClick={handleApplyFavs}> {t('apply_changes')} </Button>
      </Grid>
    </Grid>
  )
}

export default EmeaFavorites;