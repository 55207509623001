import React from "react";
import { Grid } from '@material-ui/core';
import BarChart from "components/D3Charts/BarChart";

export const NetworkChartBuilder = (props) => {
    const {charttype,horizontalMargins,margins,height, type, subtype, chartsData,keys,dimensions,setDetails} = props
    return (
        <Grid container>
            <Grid item xs={12}>
                <BarChart id={`${subtype}_networkchart`}
                    horizontal={charttype === "horizontal"}
                    margins={charttype === "horizontal" ? horizontalMargins : margins}
                    height={height}
                    type={type}
                    subtype={subtype}
                    wrapit
                    data={chartsData}
                    xKey={"stateDesc"}
                    keys={keys}
                    dimensions={dimensions}
                    setDetails={setDetails}
                />
            </Grid>
        </Grid>
    )
}




