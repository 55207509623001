import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { get, isEmpty } from "lodash";
import { Grid, makeStyles, IconButton, withStyles, Link, Typography } from "@material-ui/core"
import { connect } from "react-redux";
import { Save as SaveIcon, DeleteForeverOutlined as DeleteForeverOutlinedIcon } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import {
    createHyperLinkFilterObject2,
    generateFilterLayoutTemplate,
    getFilterArgs,
    isJsonParsable
} from "../../helpers";
import {
    saveFilters,
    setFilterLayouts,
    resetFilters,
    setCurrentView,
    saveColumnState,
    resetColumnState,
    setFirstLoad
} from "reduxLib/services";
import KCButton from '../common/Button';
import FilterViewIcon from "../../assets/images/table-view.svg";
import Snack from "./Helpers/Snack";
import FilterChips from "./FIlterChips";
import clsx from 'clsx'
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';
import { useDeepCompareEffect } from "react-use";
import defaultFilters from "reduxLib/constdata/defaultFilters";
import { useAppContext } from "context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        border: `2px solid ${theme.palette.secondary.base}`,
        padding: theme.spacing(1),
        borderRadius: theme.spacing(4),
        background: theme.palette.secondary.dark,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
        }
    },
    link: {
        cursor: 'pointer'
    },
    divider: {
        padding: `0px ${theme.spacing(2.25)}px !important`,
        margin: `${theme.spacing(1.5)}px 0px`,
        "& div>div, & div>div>button": {
            paddingBottom: `0 !important`
        },
        [theme.breakpoints.up('md')]: {
            borderLeft: `2px solid ${theme.palette.secondary.base}`,

        },
        [theme.breakpoints.down('sm')]: {
            borderTop: `2px solid ${theme.palette.secondary.base}`,

        }
    },
    button: {
        margin: `${theme.spacing(1.5)}px 0px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
        background: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: theme.spacing(1)
    },
    label: {
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        }
    },
    hideFilterViewComponent: {
        display: "none"
    }
}));

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& .MuiOutlinedInput-input": {
            padding: theme.spacing(1.5)
        },
        "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 14px) scale(1)"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(.75)"
        }
    },
}))(TextField);


export const omitDefaultFilters = ({ filterBody, tableName }) => {
    let filterBodyMinusDefaultFilters = filterBody

    if (tableName === 'orders') {
        const skipDefaultDateFilter = { matAvailDate: { ...filterBody.matAvailDate, data: null } }

        const skipDefaultOrderTypeFilter = {
            orderTypes: {
                ...filterBody.orderTypes,
                data: filterBody.orderTypes.data.map(type => {
                    return { ...type, checked: false }
                })
            }
        }

        filterBodyMinusDefaultFilters = { ...filterBodyMinusDefaultFilters, ...skipDefaultDateFilter, ...skipDefaultOrderTypeFilter }

    }

    if (tableName === 'transport') {

        const skipDefaultDateFilter = { loadReadyDateTimeOriginTZ: { ...filterBody.loadReadyDateTimeOriginTZ, data: null } }
        filterBodyMinusDefaultFilters = { ...filterBodyMinusDefaultFilters, ...skipDefaultDateFilter }

    }

    return filterBodyMinusDefaultFilters
}


export const saveView = ({ showFilterViewNotifications, createNewViewFromCurrent, createNewView, tableName, viewName, filterBody, filters, currentView, initialLayouts, setFilterLayouts, defaultColumns, columnOrder }) => {

    // Will remove the default filters from Order and transport page when new view is created in order to give empty list of filters.
    let filterBodyMinusDefaultFilters = omitDefaultFilters({ filterBody, tableName })
    let actionObject = {};

    // three conditions 1. When current view is empty means no view is applied
    // 2. When we can create a new from ADD NEW VIEW button from dropdown
    //3. When we want to create a new view by carrying the filters from an already appied view
    const viewObject = (isEmpty(currentView) || createNewView || createNewViewFromCurrent) ? generateFilterLayoutTemplate({
        viewName,
        filters: createNewView ? filterBodyMinusDefaultFilters : filters,
        columns: createNewView ? defaultColumns : columnOrder
    }) : { ...currentView, name: viewName, filters, columns: columnOrder }
    // Else condition work when we modify currently applied view

    // 1. Add new view to an empty array 
    // 2. Add new viewObject to an existing array of views 
    // 3. Find the existing view from the array and replace it with the modified view object
    actionObject = {
        ...initialLayouts, [(tableName).toUpperCase()]: {
            tableName: (tableName).toLowerCase(),
            views: (!initialLayouts[(tableName).toUpperCase()]?.views) ? [viewObject] : initialLayouts[(tableName).toUpperCase()]?.views.filter(({ id }) => viewObject.id === id).length === 0 ? [...initialLayouts[(tableName).toUpperCase()]?.views, viewObject] :
                initialLayouts[(tableName).toUpperCase()]?.views.map(view => viewObject.id === view.id ? viewObject : view)
        }
    };

    // Set filter layout action and then notification
    setFilterLayouts({ actionObject, viewObject, tableName }).then(() => {
        showFilterViewNotifications && showFilterViewNotifications({ notificationType: (isEmpty(currentView) || createNewView || createNewViewFromCurrent) ? "add" : "save", viewName })
    })
}

export const deleteView = ({ showFilterViewNotifications, viewId, tableName, filterBody, initialLayouts, setFilterLayouts, views, currentView, deleteCurrentView = false }) => {

    let actionObject = {}
    const viewList = views.filter(({ id }) => id !== viewId)

    // Two condition - 1. Delete current view. Which stop showing the filter view box
    // 2. remove a view other than current view
    const viewObject = deleteCurrentView ? {} : currentView
    actionObject = {
        ...initialLayouts, [(tableName).toUpperCase()]: {
            tableName: (tableName).toLowerCase(),
            views: viewList
        }
    };
    setFilterLayouts({ actionObject, viewObject, tableName }).then(() => {
        showFilterViewNotifications && showFilterViewNotifications({ notificationType: "delete", viewName: views.filter(({ id }) => id === viewId)[0].name })
    })
}

export const FilterView = (props) => {
    const {
        currentView, saveFilters: saveFiltersAction,
        filters, setFilterLayouts, views,
        initialLayouts, tableName, type,
        resetFilters, defaultColumns, setCurrentView,
        columnOrder, filterBody, defaultView, saveColumnState
    } = props;

    const { globalFilterSelection: { siteNums, customerOrSalesOffice, businessUnits } } = useAppContext();
    const [toggleForm, setToggleForm] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const [viewName, setViewName] = useState(currentView?.name || "");

    const [hideFilterViewComponent, setHideFilterViewComponent] = useState(true)

    const viewLimit = process.env.REACT_APP_VIEWLIMIT;

    const resetView = (e) => {
        e.preventDefault();
        saveFiltersAction({ type, subtype: tableName, filter: currentView.filters })
    }

    const clearView = (e) => {
        e.preventDefault();
        resetFilters({
            filter: {}, type, subtype: tableName,
        })
    }

    const [snack, setSnack] = useState({
        open: false,
        severity: null,
        message: null
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };

    const showFilterViewNotifications = ({ notificationType, viewName: viewName1 }) => {

        const notificationTypeDescriptionMap = {
            save: `${viewName1} ${t('saved_successfully')}`,
            add: `${viewName1 || t('untitled_view')} ${t('created')}`,
            delete: `${viewName1} ${t('deleted')}`,
        }
        setSnack({
            open: true,
            severity: "info",
            message: notificationTypeDescriptionMap[notificationType]
        });
    }

    const toggleFilterViewOptions = () => {
        setToggleForm(true);
    }

    const checkFilterChipsLength = (count) => {
        setHideFilterViewComponent(count > 0 ? false : true)
    }

    const updateURLParam = (params) => {

        const queryParams = qs.parse(location.search);
        const newQueries = {
            ...queryParams,
            ...params,
        };
        history.replace({
            ...location,
            search: qs.stringify(newQueries)
        })
    };
    const location = useLocation();
    const history = useHistory();
    const queryParams = qs.parse(location.search);
    const queryfilters = isJsonParsable(queryParams.filters);
    const showDefault = queryParams.showDefault === "true";


    useEffect(() => {
        const filterargs = createHyperLinkFilterObject2({
            pageDetails: { filterParams: { args: queryfilters } },
            tableName, filterBody
        }).fullObject;

        const shortenedFilters = getFilterArgs(filterargs)

        saveFiltersAction({
            filter: isEmpty(shortenedFilters) ? filterargs : {},
            type,
            subtype: tableName,
        })
    }, []);

    const generateView = ({ filters, queryfilters, view }) => {

        if (!isEmpty(view)) {
            if (isEmpty(queryfilters)) return view[0];
            else return {
                ...view[0],
                filters: filters
            }
        }
    }

    const setFilters = (filters, viewid = null) => {
        saveFiltersAction({
            type,
            subtype: tableName,
            filter: {
                ...filterBody,
                ...filters
            }
        });
    }

    const global = React.useMemo(() => {
        return JSON.stringify({
            sites: siteNums || [],
            cuso: customerOrSalesOffice || [],
            bu: businessUnits
        })
    }, [customerOrSalesOffice, siteNums, businessUnits]);

    const [firsteffect, setFirstEffect] = React.useState(true);

    useDeepCompareEffect(() => {
        setFirstEffect(false);
        const { viewid } = queryParams;
        const view = views.filter(d => d.id == queryParams.viewid);
        const filterargs = createHyperLinkFilterObject2({
            pageDetails: { filterParams: { args: queryfilters } },
            tableName, filterBody
        }).fullObject;

        const generatedView = generateView({ queryfilters, filters: filterargs, view });

        const shortenedFilters = getFilterArgs(filterargs)

        const isValidView = !isEmpty(view[0]);

        if (viewid && !isEmpty(queryfilters)) { //View Id is present and Filters present
            const viewFromURL = view[0];
            if (isEmpty(viewFromURL)) {
                setCurrentView({}, tableName);
            } else {
                setCurrentView(generatedView, tableName);
            }
            setFilters(filterargs, viewid)
        } else if (viewid && isEmpty(queryfilters)) { //View Id, With Out Filters
            if (isValidView) {
                setCurrentView(generatedView, tableName);
            }
            setFilters({}, viewid);
        } else if (!viewid && isEmpty(queryfilters)) { //No View Id, With Out Filters
            if (!isEmpty(defaultView) && !currentView?.id && firsteffect) {
                setCurrentView(defaultView, tableName);
                setFilters(defaultView.filters);
            } else {
                if (showDefault && !currentView?.id && isEmpty(shortenedFilters)) {
                    setFilters(defaultFilters[tableName])
                }
            }
        } else if (!viewid && !isEmpty(queryfilters)) { //No View Id, With Filters
            setCurrentView({}, tableName);
            setFilters(filterargs);
        }

        if (typeof queryParams.filters === "undefined" || typeof queryParams.global === "undefined") {
            updateURLParam({
                filters: typeof queryParams.filters === "undefined" ? "{}" : queryParams.filters,
                global: typeof queryParams.global === "undefined" ? global : queryParams.global,
            })
        }
    }, [queryParams.viewid, queryParams.filters, showDefault, global]);


    useEffect(() => {
        if (currentView?.name) {
            setViewName(currentView.name);
            setToggleForm(false);
            saveColumnState({
                columns: currentView.columns,
                subtype: tableName,
                defaultColumns
            });
            updateURLParam({
                ...queryParams,
                viewid: currentView?.id
            })
        }
    }, [currentView?.id])

    useDeepCompareEffect(() => {
        const filterargs = getFilterArgs(filters);
        if (queryParams?.isHyperlink === "true") { //When Hyperlink 
            updateURLParam({
                filters: JSON.stringify(queryfilters),
                viewid: currentView?.id || undefined,
                global,
                isHyperlink: undefined,
                showDefault: undefined
            })
        } else {
            updateURLParam({
                filters: JSON.stringify(filterargs),
                viewid: currentView?.id || undefined,
                isHyperlink: undefined,
                global,
                showDefault: undefined
            })
        }
    }, [filters, showDefault, global])

    React.useEffect(() => {
        return () => {
            setFirstEffect(false);
            setCurrentView({}, tableName);
            saveFiltersAction({
                type,
                subtype: tableName,
                filter: filterBody
            });
        }
    }, []);

    return (
        <>
            <Grid data-testid='filterview' container className={clsx(classes.root, hideFilterViewComponent && isEmpty(currentView) ? classes.hideFilterViewComponent : "")}>
                <Grid item container alignItems="center" xs={12} sm={12} md={9} lg={8}>
                    <FilterChips
                        type={type}
                        subtype={tableName}
                        filterBody={filterBody}
                        checkFilterChipsLength={checkFilterChipsLength}
                    />
                </Grid>
                <Grid item container xs={3} sm={3} md={1} lg={1} alignItems="center" justify={"space-around"}>
                    <Grid item><Link onClick={clearView} data-testid="clearfilters" className={classes.link} >{t('clear_all')}</Link></Grid>
                    {!isEmpty(currentView) && <Grid item><Link data-testid="resetfilters" onClick={resetView} className={classes.link} >{t('reset')}</Link></Grid>}
                </Grid>
                <Grid item className={classes.divider} xs={12} sm={12} md={2} lg={3}  >
                    {!toggleForm && <Grid container spacing={3} alignItems="flex-end" justify="space-evenly" >
                        {!isEmpty(currentView) && <Grid item xs={12}><Typography className={classes.label} align='center' variant='h4'>{currentView?.name}</Typography></Grid>}
                        {(views?.length <= viewLimit) && <Grid item >
                            <IconButton
                                data-testid="togglenewviewicon"
                                onClick={toggleFilterViewOptions}
                                color="primary">
                                <img src={FilterViewIcon} fontSize='medium' ></img>
                            </IconButton>
                            <Typography variant='subtitle1'>{t('new_view')}</Typography>
                        </Grid>}
                        {!isEmpty(currentView) &&
                            <>
                                <Grid item>
                                    <IconButton data-testid="savecurrentview" onClick={() => {
                                        saveView({ showFilterViewNotifications, createNewView: false, tableName, viewName, filterBody, filters, currentView, initialLayouts, setFilterLayouts, defaultColumns, columnOrder })
                                    }} color="primary">
                                        <SaveIcon fontSize="medium" />
                                    </IconButton>
                                    <Typography align='center' variant='subtitle1'>{t('save')}</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton data-testid="deletecurrentview" onClick={() => {
                                        deleteView({ showFilterViewNotifications, viewId: currentView.id, tableName, filterBody, initialLayouts, setFilterLayouts, views, currentView, deleteCurrentView: true })
                                    }} color="primary">
                                        <DeleteForeverOutlinedIcon />
                                    </IconButton>
                                    <Typography align='center' variant='subtitle1'>{t('delete')}</Typography>
                                </Grid>
                            </>
                        }
                    </Grid>}
                    {toggleForm &&
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item xs={8}>
                                <form onSubmit={((e) => {
                                    e.preventDefault()
                                    saveView({ showFilterViewNotifications, createNewViewFromCurrent: true, tableName, viewName, filterBody, filters, currentView, initialLayouts, setFilterLayouts, defaultColumns, columnOrder })
                                })}>
                                    <CssTextField
                                        inputProps={{ "data-testid": "savenewviewforminput" }}
                                        value={viewName}
                                        onChange={(e) => { setViewName(e.target.value) }}
                                        label="View Name"
                                        variant="outlined"
                                    />
                                </form>
                            </Grid>
                            <Grid item xs={4} >
                                <KCButton data-testid='savenewfromcurrent' type="button" className={classes.button} onClick={() => {
                                    saveView({ showFilterViewNotifications, createNewViewFromCurrent: true, tableName, viewName, filterBody, filters, currentView, initialLayouts, setFilterLayouts, defaultColumns, columnOrder })
                                }} >{t('save')}</KCButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Snack {...snack} handleClose={handleClose} />
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { tableName, defaultColumns } = ownProps;
    return {
        initialLayouts: get(state, `user.initialLayouts`, []),
        filters: get(state, `options.filters.${tableName}`, {}),
        views: get(state, `user.filterLayouts.${(tableName).toUpperCase()}.views`, []),
        columnOrder: get(state, `options.columns.${tableName}`, get(state, `user.currentView.columns`, defaultColumns || [])),
        currentView: get(state, `user.currentView.${tableName}`, {}),
        firstLoad: get(state, `user.firstLoad`, false),
        defaultViewsFilters: get(state, `user.defaultViewsFilters`, {}),
        defaultView: get(state, `user.defaultViews.${tableName}`, {}),
    }
};

const mapDispatchToProps = {
    saveFilters,
    setFilterLayouts,
    resetFilters,
    setCurrentView,
    saveColumnState,
    resetColumnState,
    setFirstLoad
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterView);    