import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import * as serviceWorkerRegistration from './theme/serviceWorkerRegistration';
import swConfig from "./workers/swConfig";

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register(swConfig);
