import {emeaFilterByConstants} from "reduxLib/constants/EMEA/emeaFilterByConstants";

const initialState = {
  dc_org_loading: false,
  dist_channel: [],
  sales_org: [],
  dc_org_err: null
}

const MapSalesOrgByBU = (orgList, buList) => {
  const orgs = [];
  let list = orgList.map(item => {
    return {
      ...item,
      siteNumCountryCode: `${item.salesOrgId} (${item.countryId})`
    }
  })
  if (list?.length) {
    if (buList?.length === 0 || buList?.length === 2)
      return list;
    else {
      list.map(d => {
        if (d?.businessUnits.includes(buList[0])) 
          orgs.push(d);
      })
      return orgs;
    }
  } else return orgs;
}

const emeaFilterByReducer = (state = initialState, action) => {
  switch (action.type) {

    case emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_LOADING:
      return { ...state, dc_org_loading: action.payload };

    case emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_SUCCESS:
      return { ...state, dist_channel: action.payload?.DC, sales_org: MapSalesOrgByBU(action.payload?.SO, action.payload?.BU), dc_org_err: action.error };

    case emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_FAILED:
      return { ...state, dist_channel: action.payload?.DC, sales_org: action.payload?.SO, dc_org_err: action.error };

    default:
      return state;
  }
}

export default emeaFilterByReducer;  