import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core';
import uniqid from 'uniqid'

export const TAB_ID = 'Tab-Id';
export const DEVICE_TYPE = 'Device-Type';

export default () => {

    const id = React.useMemo(uniqid, [])
    let deviceType = "desktop";

    const deviceNames = {
        sm: "mobile",
        xs: "mobile",
        md: "tablet",
        lg: "laptop",
        xl: "desktop",
    }

    Object.keys(deviceNames).map(d => {
        const screen = useMediaQuery(useTheme().breakpoints.only(d), {
            defaultMatches: true
        });
        if (screen) {
            deviceType = deviceNames[d];
        }
        return d;
    })

    React.useEffect(() => {
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(TAB_ID, id)
            sessionStorage.setItem(DEVICE_TYPE, deviceType)
        }
    }, [id, deviceType])

    return id
}