import moment from "moment-timezone";

const defaultFilters = {
    orders: {
        orderTypes: {
            type: "checkbox",
            name: "order_type",
            data: [
                { name: "all_customer_orders", value: "CUST", checked: true },
                { name: "STOs", value: "STO", checked: false }
            ]
        },
        matAvailDate: {
            type: "date",
            name: "material_availability_date",
            shortName: 'Mat.Avail Date',
            data: {
                startTime: moment().format("YYYY-MM-DD"),
                endTime: moment().add(2, "days").format("YYYY-MM-DD")
            }
        },
    },
    transport: {
        loadReadyDateTimeOriginTZ: {
            type: "date",
            name: "carrier_ready_date",
            shortName: 'Car.Ready Date',
            dummytime: true,
            timeformat: "T",
            startTime: "[00:00:00.000]",
            endTime: "[23:59:59.999]",
            data: {
                startTime: moment().format("YYYY-MM-DD [00:00:00.000]"),
                endTime: moment().add(2, "days").format("YYYY-MM-DD [23:59:59.000]")
            }
        }
    },
    network: {},
    distribution: {}
}

export default defaultFilters;