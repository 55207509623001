import React from 'react';
import { useTranslation } from "react-i18next"
import {
  IconButton,
  makeStyles,
  Typography,
  ClickAwayListener,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  Card
} from '@material-ui/core';
import clsx from "clsx";
import LaunchIcon from '@material-ui/icons/Launch';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { downloadService, resetDownload } from 'reduxLib/services';
import ExcelIcon from 'assets/images/excel.png';
import Snack from '../Helpers/Snack';
import { useDeepCompareEffect } from 'react-use';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  buttonPadding: {
    [theme.breakpoints.down("sm")]: {
   paddingLeft:theme.spacing(1.125),
   paddingRight:theme.spacing(1.125)
    }
},
  title: {
    backgroundColor: theme.palette.secondary.dark,
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
    borderTop: `2px solid ${theme.palette.border}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleHeading: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.base,
  },
  subtitle: {
    textTransform: 'none',
  },
  dropdown: {
    position: 'absolute',
    zIndex: 100,
    borderRadius: '0 !important',
    width: 350,
    right: -77,
    justifyContent: 'left'
  },
  icon: {
    height: 20,
    width: 20
  },
}));

const DownloadTable = (props) => {
  const { downloadService: service, downloads, downloadConfig, updatedColumns } = props;



  const { downloading } = downloads;
  const [open, setOpen] = React.useState(false);
  const [changedColumns, setChangedColumns] = React.useState(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const { t } = useTranslation()
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });

  React.useEffect(() => {
    setChangedColumns(updatedColumns);
  }, [updatedColumns])

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({
      ...snack,
      open: false
    });
  };

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [finalColumnObject, setFinalColObject] = React.useState([]);

  useDeepCompareEffect(() => {
    const columns = (changedColumns || downloadConfig?.config?.columns)?.map(item => {
      return {
        ...item,
        title: t(item.title),
      }
    });
    setFinalColObject(columns);
  }, [changedColumns, downloadConfig?.config?.columns])

  const downloadExcel = (itemInfoRequired = false) => {

    if (!downloading) {
      service({
        ...downloadConfig,
        config: {
          ...downloadConfig.config,
          columns: finalColumnObject
        },
        itemInfoRequired,
      });
      setSnack({
        open: true,
        severity: 'info',
        message: t("download_started_msg")
      });
    } else {
      setSnack({
        open: true,
        severity: 'warning',
        message: t("download_progress_msg")
      });
    }
  }

  return (
    <Grid item className={classes.root} data-testid="downloadtable" justify="flex-start">
      <Box textAlign="center" aria-label="Export Table" color="primary">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classes.root}>
            <IconButton className={classes.buttonPadding}
              aria-label="ExportTable"
              color="primary" onClick={handleClick}>
              <img src={ExcelIcon} alt="CSV" />
            </IconButton>
            {!isMobile && <Typography variant="body2" data-testid="ExportTable">{t('export')}</Typography>}
            {open ? (
              <Card className={classes.dropdown}>
                <div className={clsx(classes.title, classes.titleHeading)}>
                  <Typography variant="h2" component="p" color="primary">{t('export_view')}
                    <Typography variant="caption" component="p" className={classes.subtitle}>{t("with_managed_columns_and_filters_applied")}</Typography>
                  </Typography>
                </div>
                <div className={classes.title}>
                  <Typography variant="h2" component="p" color="primary">{t('with_product_details')}</Typography>
                  <IconButton><LaunchIcon className={classes.icon} aria-label="exportWith" onClick={() => downloadExcel(true)} /></IconButton>
                </div>
                <div className={classes.title}>
                  <Typography variant="h2" component="p" color="primary">{t('without_product_details')}</Typography>
                  <IconButton><LaunchIcon className={classes.icon} aria-label="exportWithOut" onClick={() => downloadExcel(false)} /></IconButton>
                </div>
              </Card>
            ) : null}
          </div>
        </ClickAwayListener>
      </Box>
      <Snack {...snack} handleClose={handleSnackClose} />
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    downloadConfig: { type, subtype }
  } = ownProps;
  return {
    downloads: get(state, ['downloads', type, subtype], {}),
    updatedColumns: get(state, ['options', 'columns', subtype], null),
  };
};

const mapDispatchToProps = {
  downloadService,
  resetDownload
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadTable);
