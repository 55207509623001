import { batch } from "react-redux";
import { osmAuthConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";

export const osmAuthorizedSitesService = variables => dispatch => {
	dispatch({
		type: osmAuthConstants.OSM_SITES_LOADING,
		payload: true,
	});

	return fetch(endpoints.osm.authSites, {
		method: "GET",
	})
		.then(d => {
			if (d.status === 200) {
				return d.json()
			} else if (d.status === 401) {
				throw "osm_restricted"
			} else {
				throw "osm_sites_failed"
			}
		})
		.then(d => {
			batch(() => {
				dispatch({
					type: osmAuthConstants.OSM_SITES_FETCH_SUCCESS,
					payload: d,
					error: null
				});
				dispatch({
					type: osmAuthConstants.OSM_MAP_REGIONS,
					payload: d,
				});
				dispatch({
					type: osmAuthConstants.OSM_MAP_COUNTRIES,
					payload: d,
				});
				dispatch({
					type: osmAuthConstants.OSM_MAP_SITES,
					payload: d,
				});
				dispatch({
					type: osmAuthConstants.OSM_SITES_LOADING,
					payload: false,
				})
			})
		})
		.catch(err => {
			dispatch({
				type: osmAuthConstants.OSM_SITES_LOADING,
				payload: false,
			});
			dispatch({
				type: osmAuthConstants.OSM_SITES_FETCH_FAILED,
				payload: {},
				error: err,
			});
		})
}

export const saveOsmCurrentGlobalRegionFilter = variables => dispatch => {
	dispatch({
		type: osmAuthConstants.OSM_CURRENT_REGIONS,
		payload: variables
	})
}

export const saveOsmCurrentGlobalCountryFilter = variables => dispatch => {
	dispatch({
		type: osmAuthConstants.OSM_CURRENT_COUNTRIES,
		payload: variables
	})
}

export const saveOsmCurrentGlobalSiteFilter = variables => dispatch => {
	dispatch({
		type: osmAuthConstants.OSM_CURRENT_SITES,
		payload: variables
	})
}

export const saveOsmCurrentGlobalBusinessFilter = variables => dispatch => {
	dispatch({
		type: osmAuthConstants.OSM_CURRENT_BUSINESS,
		payload: variables
	})
}