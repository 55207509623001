import React from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { get, isEmpty } from "lodash";
import { saveFilters, saveSelfAlertFilters } from "../../reduxLib/services";
import TextField from '@material-ui/core/TextField';
import moment from "moment-timezone";
import { useDeepCompareEffect } from 'react-use';

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        marginBottom: theme.spacing(2),
        backgroundColor: theme?.palette?.form?.baseLight,
        "& label": {
            color: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.primary.contrastText}`,
            borderRadius: theme.spacing(0.5),
            backgroundColor: theme?.palette?.form?.baseLight,
            padding: theme.spacing(0.5)
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        '& .MuiInputBase-input': {
            color: theme.palette.primary.contrastText
        }
    },
}))(TextField);


const DateFilter = (props) => {

    const { filters, filterKey, type, subtype, disableStartDate, customSaveFilters } = props;

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    const filter = get(filters, `${filterKey}.data`, { startTime: "", endTime: "" })

    const [datetime, setDatetime] = React.useState(filter || {});

    const { t } = useTranslation();

    const changeDatetime = (value, dateType) => {
       let date = moment(value, "YYYY-MM-DD").format(`YYYY-MM-DD`);
        if (filters[filterKey]?.dummytime) {
            date = disableStartDate ? moment(value, "YYYY-MM-DD").format(`YYYY-MM-DD`)
                : moment(value, "YYYY-MM-DD").format(`YYYY-MM-DD ${filters[filterKey][dateType]}`);
        }
        setDatetime({ ...datetime, [dateType]: date });
    }

    React.useEffect(() => {
        if (datetime?.startTime && datetime?.endTime) {
            const savePayload = {
                filter: {
                    [filterKey]: {
                        ...filters[filterKey],
                        data: datetime
                    }
                }, type, subtype
            }
            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayload);
                return;
            } else {
                saveFilters(savePayload);
            }
        }
    }, [datetime?.startTime, datetime?.endTime])

    useDeepCompareEffect(() => {
        setDatetime(filter || { startTime: "", endTime: "" });
    }, [filters?.[filterKey]?.data]);
    
    return (
        // <FilterComponentContainer testId='dateFilterElement' {...props}>
        <div data-testid="dateFilterElement" style={{display: disableStartDate ? "flex" : ""}}>
            <CssTextField
                label={t('start_date')}
                InputLabelProps={{
                    shrink: true,
                }}
                value={datetime?.startTime ? moment(datetime?.startTime).format('YYYY-MM-DD') : ""}
                type="date"
                variant="outlined"
                onChange={(e) => changeDatetime(e.target.value, "startTime")}
                inputProps={{ 
                    "aria-label": "startdate",
                    max: (datetime?.endTime) ? moment(datetime.endTime).format('YYYY-MM-DD') : ""
                }}
                disabled={disableStartDate ? true : false}
                style={disableStartDate ? {"marginRight": 10}: null }
            />
            <CssTextField
                label={t('end_date')}
                InputLabelProps={{
                    shrink: true,
                }}
                value={datetime?.endTime ? moment(datetime.endTime).format('YYYY-MM-DD') : ""}
                type="date"
                onChange={(e) => changeDatetime(e.target.value, "endTime")}
                variant="outlined"
                disabled={isEmpty(datetime?.startTime)}
                inputProps={{ 
                    "aria-label": "enddate",
                    min: (datetime?.startTime) ? moment(datetime?.startTime).format('YYYY-MM-DD') : ""
                }}
                

            />
        {/* </FilterComponentContainer> */}
        </div>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;
    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        filters
    }
};

const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(DateFilter);
