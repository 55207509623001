import React from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next"
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { saveFilters, saveSelfAlertFilters } from "reduxLib/services";
import { stringToArray, stringToArrayV2 } from 'helpers';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles(theme => ({
    checkBox: {
        '&.MuiCheckbox-colorSecondary.Mui-checked': {
          color: theme.palette?.secondary?.pressed
        }
    }
}))

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        }
    },
}))(TextField);

const TextFilterElement = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { title, filters, filterKey, type, subtype, placeholder, filter: filterBase, customSaveFilters, helperText } = props;

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    const filter = get(filters, `${filterKey}.data`, null);
    const excludeFilter = get(filters, `${filterBase?.excludeKey}.data`, null);
    const [input, setInput] = React.useState(filter || excludeFilter);
    const [checked, setChecked] = React.useState(false);

    useDeepCompareEffect(() => {
        const excludeData = filters?.[filterBase?.excludeKey]?.data;
        setChecked(excludeData ? true : false);
    }, [filters?.[filterBase?.excludeKey]?.data]);

    const submitChange = (e) => {
        e.preventDefault();
        setInput(filterBase?.stringToArray
            ? filterBase?.region === 'lao'
                ? stringToArrayV2(input, filterKey)
                : stringToArray(input)
            : input?.trim()              
        )
        if (checked) {
            const savePayload = {
                filter: {
                    [filterKey]: {
                        ...filters[filterKey],
                        data: null
                    },
                    [filterBase?.excludeKey]: {
                        ...filters[filterBase?.excludeKey],
                        data: filterBase?.stringToArray ? stringToArray(input) : input?.trim() || null
                    }
                }, type, subtype
            };
            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayload);
                return;
            }
            saveFilters(savePayload);
        } else {
            let savePayloadUnchecked = {
                filter: {
                    [filterKey]: {
                        ...filters[filterKey],
                        data: filterBase?.stringToArray
                            ? filterBase?.region === 'lao'
                                ? stringToArrayV2(input, filterKey)
                                : stringToArray(input)
                            : input?.trim() || null
                    }
                }, type, subtype
            };

            if(filterBase?.excludeKey) {
                savePayloadUnchecked.filter[filterBase.excludeKey] = {
                    ...filters[filterBase.excludeKey],
                    data: null
                }
            }

            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayloadUnchecked);
                return;
            }
            saveFilters(savePayloadUnchecked);
        }
    }

    const handleChange = () => { setChecked(!checked) }

    React.useEffect(() => {
        isEmpty(filter) ? setInput(excludeFilter) : setInput(filter);
    }, [filter, excludeFilter])

    return (
        <form onSubmit={submitChange} data-testid="textFilterElement">
            <CssTextField
                value={input || ""}
                title="textbox"
                inputProps={{ "data-testid": "textfilter" }}
                onChange={e => setInput(e.target.value)}
                label={placeholder || `Enter ${title.toLowerCase()}`}
                variant="outlined"
                helperText={t(helperText)}
            />
            {filterBase?.exclude === true && <FormControlLabel
                control={ <Checkbox checked={checked} onChange={handleChange} name={filterKey} className={classes.checkBox} inputProps={{ 'data-testid': `checkbox-${filterBase?.excludeKey}`}} /> }
                label={'Exclude'}
                data-testid={filterBase?.excludeKey}
            />}
        </form>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;
    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        filters
    }
};

const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(TextFilterElement);
