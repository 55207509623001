import {dark} from './palette';
import GilroyWoff2 from '../assets/fonts/gilroy.woff2';

export const gilroy = {
  fontFamily: 'Gilroy',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('Gilroy'),
    local('Gilroy-Regular'),
    url(${GilroyWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const fontFamilies = {
  gilroy: gilroy,
};

//500 is semibold

export const typography =(palette) => {
  return {
    fontFamily: "'Gilroy', sans_serif",
    h1: { //body large
      fontSize: '18px',
      '@media (min-width:600px)': {
        fontWeight: "500"
      }
    },
    h2: {
      fontWeight: 500,
      fontSize: '18px',
      textTransform: 'uppercase'
    },
    h3: {
      fontWeight: 500,
      fontSize: '16px',
      textTransform: 'uppercase',
      '@media (min-width:600px)': {
        fontWeight: "500"
      },
      '@media (max-width:600px)': {
        fontSize: '14px'
      }
    },
    h4: {
      fontWeight: 500,
      fontSize: '14px',
      textTransform: 'uppercase',
      '@media (max-width:600px)': {
        fontSize: '10px'
      }
    },
    h5: {
      fontWeight: 500,
      fontSize: '12px',
      textTransform: 'uppercase'
    },
    h6: { //body-base
      fontSize: '16px',
      '@media (min-width:600px)': {
        fontWeight: "500"
      },
      '@media (max-width:600px)': {
        fontSize: '12px'
      }
    },
    body1: { //body small
      fontSize: '14px',
      '@media (min-width:600px)': {
        fontWeight: "500"
      },
      '@media (max-width:600px)': {
        fontSize: '12px'
      }
    },
    body2: { //body xsmall
      fontSize: '12px',
      '@media (min-width:600px)': {
        fontWeight: "500"
      },
      '@media (max-width:600px)': {
        fontSize: '10px'
      }
    },    
    button: {
      fontWeight: 500,
      fontSize: '16px'
    },
    caption: {
      fontSize: '12px',
      textTransform: 'capitalize !important'
    },
    subtitle1: { //label
      color: palette.text.primary,
      fontSize: '14px',
    },
    subtitle2: { //Input
      fontSize: '16px',

    }
    // h6: { //Chart
    //   fontSize: '12px',
    //   textTransform: 'uppercase'
    // },
    // overline: {
    //   color: palette.text.secondary,
    //   fontSize: '11px',
    //   fontWeight: 500,
    //   textTransform: 'uppercase'
    // },
  }
}


export const getTypography = (type) => {
  // if (type === "light") {
  //   return typography(light)
  // } 
  return typography(dark)
}