import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette?.extra?.info,
    textDecoration: `underline`
  }
}));

const OsmShipmentHyperlink = ({ shipmentNum, id }) => {
  const classes = useStyles();
  const url = `${process.env.REACT_APP_ECC_URL}${shipmentNum}`;

  if (shipmentNum === '-' || !shipmentNum) {
    return "-";
  }

  return (
    <Link
      className={classes.link}
      to={{ pathname: url }}
      target="_blank" 
      data-testid={id} >
        { shipmentNum }
    </Link>
  )
}

export default OsmShipmentHyperlink;