import { emeaTableConstants } from "reduxLib/constants/EMEA/emeaTableConstants";

const initialState = {
  edit_comment_loading: false,
  edit_comment_status: null,
  edit_comment_error: null
}

const emeaEditCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case emeaTableConstants.EMEA_TABLE_COMMENT_LOADING:
      return { ...state, edit_comment_loading: action.payload }

    case emeaTableConstants.EMEA_TABLE_COMMENT_SUCCESS:
      return { ...state, edit_comment_status: 'success', edit_comment_error: action.payload }

    case emeaTableConstants.EMEA_TABLE_COMMENT_FAILED:
      return { ...state, edit_comment_status: 'failed', edit_comment_error: action.payload }

    case emeaTableConstants.EMEA_TABLE_COMMENT_RESET:
      return { ...state, edit_comment_status: action.payload, edit_comment_error: action.error }

    default:
      return state;
  
  }
}

export default emeaEditCommentReducer;