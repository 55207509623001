import React from "react";
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { Favorite } from '@material-ui/icons';
import KCChip from "../common/Chip";
import { laoSetSiteFavourite, laoSetBUFavourite } from 'reduxLib/services/LaoServices/laoFavService';
import { isEmpty } from "lodash";
import { FavBlock, useStyles } from "components/header/FavouritesModule";

export default () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { favouriteBusinessUnits: businessUnits, favouriteSiteNums: favSites } =
        useSelector(({ laoFavorites }) => laoFavorites);

    const classes = useStyles();
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

    const handleLaoBUFav = (bu) => {
        const favBUs = businessUnits.filter(d => d !== bu);
        dispatch(laoSetBUFavourite(favBUs));
    }

    const handleLaoSiteFav = (siteNum) => {
        const updatedSites = favSites.filter(d => d !== siteNum);
        dispatch(laoSetSiteFavourite(updatedSites));
    }

    return (
        <Grid className={classes.root} data-testid="favmodule">
            <Box mt={2}>
                {isEmpty(businessUnits) && isEmpty(favSites) ? isMobile ? <FavBlock /> : <Typography variant="title" align="center">{
                    t("you_do_not_have_any_favourites")}</Typography> : <Typography variant="h4">{t("list_of_favourited_selections")}</Typography>}
                {
                    businessUnits.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="buFavs">
                        <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2}>
                            <Typography variant="h4">{t('business_unit')}</Typography>
                        </Grid>
                        <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                            {
                                businessUnits.map(d => {
                                    return (<KCChip label={d} deleteIcon={<Favorite color='primary' id={d} data-testid="deleteFavBU" />} 
                                    onDelete={() => { handleLaoBUFav(d); }} />)
                                })
                            }
                        </Grid>
                    </Grid>
                }
                {
                    favSites.length > 0 && <Grid container spacing={2} className={classes.container} data-testid="locationFavs">
                        <Grid item className={classes.item} xs={12} sm={12} md={2} lg={2} >
                            <Typography variant="h4">{t('location')}</Typography>
                        </Grid>
                        <Grid item className={classes.item} xs={12} sm={12} md={10} lg={10} >
                            {
                                favSites.map(d => {
                                    return (<KCChip label={d} id="locationFavChip" name={d}
                                        deleteIcon={<Favorite id={d} data-testid="laoadeleteFavLoc" color='primary' />} 
                                        onDelete={() => { handleLaoSiteFav(d); }} 
                                    />)
                                })
                            }
                        </Grid>
                    </Grid>
                }
            </Box>
        </Grid>
    )
}

