import { feedbackConstants } from "../constants"
import fetch from "./serviceHelpers";

const saveFeedback = variables => dispatch => {
    dispatch({
        type: feedbackConstants.SUBMIT_FEEDBACK_LOADING,
        payload: true
    });

    return fetch('/users/feedback', {
        method: "post",
        body: { ...variables, receivedDatetime: new Date() },
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: feedbackConstants.SUBMIT_FEEDBACK_STATUS,
                    payload: "success"
                });
            } else {
                dispatch({
                    type: feedbackConstants.SUBMIT_FEEDBACK_STATUS,
                    payload: "failed"
                });
            }
            
            dispatch({
                type: feedbackConstants.SUBMIT_FEEDBACK_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: feedbackConstants.SUBMIT_FEEDBACK_STATUS,
                payload: "failed"
            });
            dispatch({
                type: feedbackConstants.SUBMIT_FEEDBACK_LOADING,
                payload: false
            });
        })
}

const resetFeedback = variables => dispatch => {
    dispatch({
        type: feedbackConstants.RESET_SUBMIT_FEEDBACK_STATUS,
        payload: null
    });
}

export {
    saveFeedback,
    resetFeedback
}
