import React, { useEffect } from 'react';
import { Typography, Grid, makeStyles, useMediaQuery, useTheme, Modal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next"
import Card from "components/common/Card";
import { DistributionOutboundSCChartBuilder } from 'components/common/DistributionOutboundSCChartBuilder';
import { DistributionInboundChartBuilder } from 'components/common/DistributionInboundChartBuilder';
import DistributionTabs from "components/common/DistributionTabs";
import { tabTitleEnum } from 'components/common/DistributionTabs'
import { viewStyles } from "theme"
import ChartComponent from "components/common/ChartComponent";
import GuardrailChartComponent from "components/common/GuardrailChartComponent";
import ChartCarouselComponent from "components/common/ChartCarouselComponent";
import { DistributionOutboundCDChartBuilder } from 'components/common/DistributionOutboundCDChartBuilder';
import { DistributionOutboundLSChartBuilder } from 'components/common/DistributionOutboundLSChartBuilder';
import { DistributionOutboundRFPChartBuilder } from 'components/common/DistributionOutboundRFPChartBuilder';
import { DistributionShipmentsChartBuilder } from 'components/common/DistributionShipmentsChartBuilder';
import { getAllGuardrails } from 'reduxLib/services/getAllGuardRailsService';
import Snack from "components/common/Helpers/Snack";
import UpdateGuardrails from 'components/UpdateGuardrails';
import { isEmpty } from "lodash";
import { viewByConstants } from "configs/appConstants";
import { getTabsData, defaultShowTabsBy } from "helpers";
import { connect } from "react-redux";
import { get } from "lodash";
import BreadCrumb from 'components/common/Elements/BreadCrumb';

const useStyles = makeStyles(viewStyles);

const Distribution = ({ name, setActiveTopNavTab, selections, showTabsBy, ...rest }) => {
    const classes = useStyles();

    const { type } = rest?.match?.params;

    // const globalFilterSelection = generateHeader({ selections, type, showTabsBy, item: items.filter(d => d["custom"]["id"] === type)?.[0] });


    const dispatch = useDispatch();
    const { t } = useTranslation();
    const pageTitle = t('distribution_breadcrumb_text')
    const height = 160;
    const margins = { top: 30, right: 10, bottom: 40, left: 50, margin: 10 };
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
    const singleLocation = /^\d+$/.test(type);
    const notification = useSelector(({ guardrails }) => guardrails.notification)

    const [open, setOpen] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false,
        severity: "info",
        message: null
    })



    const showGuardrailsUpdateDialog = async () => {
        setOpen(true)
    }

    const chartComponentDetails = [{
        name: `${isMobile ? `${t('outbound_title')} - ` : ""}${t('shipment_created')}`,
        componentName: DistributionOutboundSCChartBuilder,
        showLegends: true,
        subtype: tabTitleEnum.outbound
    },
    {
        name: `${isMobile ? `${t('outbound_title')} - ` : ""}${t('checked_in_by_DC')}`,
        componentName: DistributionOutboundCDChartBuilder,
        showLegends: false,
        subtype: tabTitleEnum.outbound,
        blockApi: true
    },
    {
        name: `${isMobile ? `${t('outbound_title')} - ` : ""}${t('loading_started')}`,
        componentName: DistributionOutboundLSChartBuilder,
        showLegends: false,
        subtype: tabTitleEnum.outbound,
        blockApi: true
    },
    {
        name: `${isMobile ? `${t('outbound_title')} - ` : ""}${t('ready_for_pickup')}`,
        componentName: DistributionOutboundRFPChartBuilder,
        showLegends: false,
        subtype: tabTitleEnum.outbound,
        blockApi: true
    },
    {
        name: `${isMobile ? `${t('inbound_title')} - ` : ""}${t('shipment_status')}`,
        componentName: DistributionInboundChartBuilder,
        showLegends: false,
        subtype: tabTitleEnum.inbound,
        sitesKey: "destSites"
    }]

    const shipmentStatusDetails = [{
        name: t("location_volume_per_day"),
        componentName: DistributionShipmentsChartBuilder,
        showLegends: true,
        guardrails: true,
        config: {
            showLegends: true,
            guardrails: true,
            keys: ["CONSUMER_shipmentCount", "PROFESSIONAL_shipmentCount", "ALL_shipmentCount"],
            xKey: "name",
            refLine: "totalGuardrailValue",
            subRefLines: ["CONSUMER_guardrailValue", "PROFESSIONAL_guardrailValue", "ALL_guardrailValue"]
        }
    }]

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };

    useEffect(() => {
        dispatch(getAllGuardrails())
    }, [])

    useEffect(() => {
        if (!isEmpty(notification)) setSnack({ open: true, ...notification })
    }, [notification]);

    const setDetails = (...args) => {
        rest.setDetails(...args, "distribution")
    };

    //SCR Code <--STARTS-->
    // const goToStockConstraint = () => {
    //   setDetails(type, chartDataIdentifierForAPI.stockConstraintReport, true, {
    //     navHistory: [{ id: intersection(Object.values(chartDataIdentifierForAPI), Object.keys(rest.pageDetails))[0], label: pageTitle, type }].filter(({ id }) => id !== chartDataIdentifierForAPI.network)
    //   })
    // }
    //SCR Code <--ENDS-->

    return (
        <div className={classes.separator} data-testid='distributionContainer' key={type}>
            <BreadCrumb />

            {/* Commenting SCR Link
      {singleLocation && <div data-testid="scReport" className={classes.scrLinkbutton} onClick={goToStockConstraint}>
        {t('go_to_stock_constraint_report')}
        <img src={ArrowRight} className={classes.arrowImg} />
      </div>} */}
            <Card
                cardtitle={
                    <>
                        <Typography variant="h1" color="primary">
                            {t('live_status_text')}
                        </Typography>
                        <Grid container className={classes.extendTitle} spacing={2} justify="space-between" >
                            <Grid item sm={8} >
                                <Typography variant="h1" color="primary">
                                    {`${t('outbound_title')}`}
                                </Typography>
                            </Grid>
                            <Grid item sm={4} >
                                <Typography variant="h1" color="primary">
                                    {`${t('inbound_title')}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                }
            >
                <ChartCarouselComponent chartsArray={
                    chartComponentDetails && chartComponentDetails.map((chart) => (
                        <Grid item xs={12} md={4} lg={3} className={classes.addPadding}>
                            <ChartComponent
                                blockApi={chart.blockApi}
                                sitesKey={chart.sitesKey}
                                showLegends={chart.showLegends}
                                innercard divider
                                BuilderComponent={chart.componentName}
                                {...rest}
                                name={chart.name}
                                setDetails={setDetails}
                                type={type} subtype={chart.subtype}
                                height={height} margins={margins}
                            />
                        </Grid>
                    ))
                }
                />
            </Card>
            <>
                {
                    singleLocation === true && shipmentStatusDetails && shipmentStatusDetails.map((chart) => (
                        <GuardrailChartComponent
                            chartConfig={chart.config}
                            blockApi={chart.blockApi}
                            showLegends={chart.showLegends}
                            innercard
                            BuilderComponent={chart.componentName}
                            {...rest}
                            name={chart.name} setDetails={setDetails}
                            type={type}
                            height={200}
                            margins={margins}
                            guardrails={chart.guardrails}
                            showGuardrailsUpdateDialog={showGuardrailsUpdateDialog}
                        />
                    ))
                }
                <Modal
                    className={classes.modalBody}
                    open={open}
                    onClose={() => setOpen(false)}
                    data-testid="guardrail-modal"
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <UpdateGuardrails onClose={() => setOpen(false)} />
                </Modal>
            </>

            <Card>
                <DistributionTabs
                    pageTitle={pageTitle}
                    setActiveTopNavTab={setActiveTopNavTab}
                    {...rest}
                    setDetails={setDetails}
                    pageDetails={{ filterParams: {} }}
                    type={type}
                />
            </Card>
            <Snack {...snack} handleClose={handleClose} />
        </div>
    );
}

const mapStateToProps = state => {
    const showTabsBy = defaultShowTabsBy(state);
    return {
        stopfavload: state.items.stopfavload,
        selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
        showTabsBy,
        blockOnTabChange: get(state, 'items.blockOnTabChange', false)
    }
}


export default connect(mapStateToProps, null)(Distribution);