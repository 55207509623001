import { getFavUnfavConstants } from '../constants';
import * as _ from 'lodash';

const mapData = (data) => {
    const favs = data && data.length>0 ? data.map(d => ({ ...d, isFav: true})) : [];
    let result = _.sortBy(favs, "shortName");
    return result;
}

const removeDups = (data) => {
    return _.sortBy(_.uniqBy(data, "siteNum"), "shortName");
}

const removeElement = (data, key) => {
    _.remove(data, { siteNum: key });
    return data;
}

const getFavUnfavReducer = (state = {
    favloading: false,
    mark_loading: false,
    favorites: [],
    favCuso: { FAV_CUST: [], FAV_SALES_OFFICE: [] },
    tempBusinessUnit: [],
    bu_updated: true,
    fav_cuso_loading: false,
    businessUnits: []
}, action) => {
    switch (action.type) {
        case getFavUnfavConstants.GET_FAVS_SUCCESS:
            return { ...state, favorites: mapData(action.payload) }

        case getFavUnfavConstants.GET_FAV_CUSO:
            const cust = action.payload.filter((d => d.selectionType === "CUST"));
            const SO = action.payload.filter((d => d.selectionType === "SALES_OFFICE"));
            return { ...state, favCuso: { FAV_CUST: cust, FAV_SALES_OFFICE: SO }}

        case getFavUnfavConstants.FAV_CUSO_SUCCESS:
            if (action.payload.selectionType === "CUST") {
                
                return { 
                    ...state, favCuso: {
                    ...state.favCuso, 
                    // FAV_CUST: _.unionWith(action.payload.favouriteCustomerSalesOffice[0], state.favCuso.FAV_CUST, _.isEqual)
                    FAV_CUST: action.payload.updatedState.filter(obj => obj.selectionType === "CUST")
                }}
            } else if(action.payload.selectionType === "SALES_OFFICE") {

                return { 
                    ...state, favCuso: {
                    ...state.favCuso, 
                    FAV_SALES_OFFICE: action.payload.updatedState.filter(obj => obj.selectionType === "SALES_OFFICE"),
                }}
            } else {
                return state;
            }
        
        case getFavUnfavConstants.GET_FAVS_FAILED:
            return { ...state, favorites: [] }

        case getFavUnfavConstants.FAV_CUSO_FAILED:
            return { ...state, favCuso: { FAV_CUST: [], FAV_SALES_OFFICE: [] } }

        case getFavUnfavConstants.LOADING:
            return { ...state, favloading: action.payload }

        case getFavUnfavConstants.MARK_FAV_SUCCESS:
            return {
                ...state, markfav: action.payload.status,
                favorites: [...removeDups([...state.favorites, { ...action.payload.data, isFav: true }])]
            };

        case getFavUnfavConstants.MARK_FAV_FAILED:
            return { ...state, markfav: action.payload.status };

        case getFavUnfavConstants.MARK_UNFAV_SUCCESS:
            return { ...state, markunfav: action.payload.status, favorites: [...removeElement(state.favorites, action.payload.data.siteNum)] };

        case getFavUnfavConstants.MARK_UNFAV_FAILED:
            return { ...state, markunfav: action.payload.status };

        case getFavUnfavConstants.MARK_LOADING:
            return { ...state, mark_loading: action.payload }

        case getFavUnfavConstants.ADD_BUSINESS_UNIT_LOADING:
            return { ...state, add_bu_loading: action.payload }

        case getFavUnfavConstants.ADD_BUSINESS_UNIT_SUCCESS:
        case getFavUnfavConstants.ADD_BUSINESS_UNIT_FAILED:
            let stateObject = { ...state };
            if (state.bu_updated) {
                stateObject = { ...stateObject, businessUnits: action.payload, tempBusinessUnit: action.payload, bu_updated: false }
            } else {
                stateObject = { ...stateObject, businessUnits: action.payload }
            }
            return { ...stateObject }

        case getFavUnfavConstants.SET_BUSINESS_UNIT_LOCAL:
            return {
                ...state, tempBusinessUnit: action.payload
            }
    
        case getFavUnfavConstants.FAV_CUSO_LOADING:
            return { ...state, fav_cuso_loading: action.payload }

        default:
            return state
    }
}

export default getFavUnfavReducer;
