import { businessUnits, emeaCustSeparator } from "configs/appConstants";
import { find, isArray, isEmpty, isObject } from "lodash";
import uniqBy from "lodash/uniqBy";
import moment from "moment-timezone";


export const formatLocaleNumber = (number, formatRequired = true, decimals = 0) => {
    if (formatRequired && number) {
        if (decimals) {
            return number.toFixed(decimals)
        }
        return number.toLocaleString("en-US");
    }
    return number;
};

export const formatCompactNumber = ( number ) => {
    const value = new Intl.NumberFormat('en-US', {
        notation: "compact",
        maximumFractionDigits: 2
    }).format(number)
    return value;
};

export const chipsMain = ({ BU }) => {
    let business = BU;
    if (!business?.length) {
        business = businessUnits
    }
    return {
        region: [{ name: "EMEA", onClose: false }],
        business: business.map(d => {
            return {
                name: d,
                id: d,
                onClose: business.length === 2
            }
        })
    }
}


export const generateChipsData = ({ sites, Cust, sofcNSG, viewby, tab, documentDate }) => {
    let locations = sites;
    let customers = Cust;
    let sofcs = sofcNSG;
    if (tab === "network") {
        locations = ["All"];
        customers = ["All"];
        sofcs = ["All"];
    } else {
        if (tab !== "myselections") {
            if (viewby === "sites") {
                locations = [tab]
            }
            if (viewby === "Cust") {
                customers = [tab]
            }
            if (viewby === "Sofc") {
                sofcs = [tab]
            }
        }
    }
    const docDate = (documentDate?.startTime && documentDate?.endTime) ?
        [documentDate] : [];

    return {
        locations: locations?.map(d => {
            return {
                name: tab === "network" ? "All" : d,
                id: d,
                onClose: tab !== "network"
            }
        }),
        customer: customers?.map(d => {
            return {
                name: tab === "network" ? "All" : `${d.split(emeaCustSeparator)?.[3]} (${d.split(emeaCustSeparator)?.[0]} - ${d.split(emeaCustSeparator)?.[2]})`,
                id: d,
                onClose: tab !== "network"
            }
        }),
        sales_office: sofcs?.map(d => {
            const splits = d.split("_");
            return {
                name: tab === "network" ? "All" : `${splits[3]} (${splits[0]} - ${splits[2]}) ${splits[4] || ""}`,
                id: d,
                onClose: tab !== "network"
            }
        }),
        document_date: docDate.map(d => {
            const startTime = moment(d.startTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
            const endTime = moment(d.endTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
            return {
                name: `${startTime}, ${endTime}`,
                onClose: true,
                hideOn: ["home",'invoices']
            }
        }),
        invoice_date: docDate.map(d => {
            const startTime = moment(d.startTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
            const endTime = moment(d.endTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
            return {
                name: `${startTime}, ${endTime}`,
                onClose: true,
                hideOn: ["home","returns","orderEntry","openOrders","orderCutsRejections"]
            }
        })
    }
}

export const generateSites = (data) => {
    let sites = data?.map(item => {
        let y = item.split(' ');
        return y[0];
    });
    return sites;
}

export const generateCustomers = (data = [], type = "CUST") => {
    const values = [];
    const temp = data?.map(cust => {
        const obj = {};
        const arr = cust.split(emeaCustSeparator);
        obj.selectionType = type;
        obj.salesOrg = arr[0];
        obj.distributionChannel = arr[1];
        obj.customerName = arr[3];
        obj.countryId = arr[2];
        values.push(obj);
    })
    return values;
}

export const generateSofcs = (data = [], type = "SALES_OFFICE") => {
    let values = [];
    const sgs = {};
    
    const temp = data?.map(sofc => {
        const obj = {};
        const arr = sofc.split('_');
        const sofcKey = [arr[0], arr[1], arr[3]].join("_");
        obj.selectionType = type;
        obj.salesOrg = arr[0];
        obj.distributionChannel = arr[1];
        obj.countryId = arr[2];
        obj.salesOffice = arr[3];
        obj.sofcKey = sofcKey;
        if (arr[4]) {
            sgs[sofcKey] = [...(sgs?.[sofcKey] || []), arr[4]]
        }
        values.push(obj);
    });
    values = uniqBy(values, "sofcKey").map(d => {
        const final = {
            ...d,
            salesGroup: sgs[d.sofcKey]
        }
        delete final.sofcKey;
        if (!final.salesGroup?.length) {
            delete final.salesGroup;
        }
        return final;
    });
    return values;
}

export const generatePayload = ({ sites, Cust, sofcNSG, viewby, tab, selectAllOrgs, BU }) => {
    let sitesTemp = sites;
    let customersTemp = Cust;
    let sofcsTemp = sofcNSG;

    const generateSOFCObj = (obj) => {
        const splits = obj.split("_");
        return {
            selectionType: "SALES_OFFICE",
            salesOrg: splits[0],
            distributionChannel: splits[1],
            countryId: splits[2],
            salesOffice: "",
        }
    }

    if (tab === "network") {
        sitesTemp = [];
        customersTemp = [];
        sofcsTemp = [];
    } else {
        if (tab !== "myselections") {
            if (viewby === "sites") {
                sitesTemp = [tab]
            }
            if (viewby === "Cust") {
                customersTemp = [tab]
            }
            if (viewby === "Sofc") {
                sofcsTemp = [tab]
            }
        }
    }
    sofcsTemp = generateSofcs(sofcsTemp);

    if (selectAllOrgs) {
        const emptyObj = generateSOFCObj(selectAllOrgs);
        sofcsTemp.push(emptyObj);
    }

    return {
        locations: generateSites(sitesTemp),
        customers: generateCustomers(customersTemp),
        sofcs: sofcsTemp,
        BU: isEmpty(BU) ? businessUnits : BU
    }
}

export const truncateText = (label) => {
    if (label?.length > 5) {
        return label.substring(0, 5) + '...'
    }
    return label;
}

export const generateFilterPayload = (filters) => {
    if (!isEmpty(filters)) {
        let data = {}
        for (const [key, value] of Object.entries(filters)) {
            if (value?.data) {
                if (Array.isArray(value.data) && !value.stringToArray) {
                    const mappedvals = value.data.filter(({ checked }) => checked).map(({ value }) => value);
                    if (!isEmpty(mappedvals)) {
                        data[key] = mappedvals
                    }
                } else {
                    data[key] = value.data;
                }
            } else {
                data[key] = value.data;
            }
        }
        return data;
    }
    return {}
}

export const createFilterObjFromPayload = ({ filterPaylod, filterBody, filterKeys = null }) => {

    const finalObj = filterPaylod ? Object.keys(filterPaylod).reduce((res, key) => {
        if (filterKeys && !filterKeys.includes(key)) return {
            ...res
        };
        return {
            ...res, [key]: {
                ...filterBody?.[key],
                data: isArray(filterBody?.[key]?.data) ? filterBody?.[key]?.data.map(filterOption => {
                    if (isObject(filterOption.value)) {
                        return find(filterPaylod, filterOption.value) ? { ...filterOption, checked: true } : filterOption
                    }
                    return filterPaylod?.[key]?.includes(filterOption.value) ? { ...filterOption, checked: true } : filterOption
                }) : filterPaylod[key]
            }
        }
    }, {}) : {};

    return finalObj;
}

export const getEMEAFilterArgs = (filters) => {
    if (!isEmpty(filters)) {
        let data = {}
        for (const [key, value] of Object.entries(filters)) {
            if (value?.data) {
                if (Array.isArray(value.data) && !value.stringToArray) {
                    const mappedValues = value.data.filter(({ checked }) => checked)
                        .map(({ value }) => value);
                    if (!isEmpty(mappedValues)) {
                        data[key] = mappedValues
                    } else {
                        data[key] = undefined;
                    }
                } else {
                    data[key] = value.data;
                }
            } else {
                data[key] = undefined;
            }
        }
        return data;
    }
    return {}
}

export const generateSiteObject = (org) => {
    let arr = org.split(' ');
    let code = arr[1]?.substring(1, 3);
    return {
        id: arr[0],
        code: code
    }
}


export const generateDates = (dates) => {
    const startDate = moment(dates?.startTime, 'YYYY-MM-DD');
    const endDate = moment(dates?.endTime, 'YYYY-MM-DD');

    const dateValueMap = [];

    let currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate)) {
        const formatted = currentDate.format("DD/MM/YYYY");
        dateValueMap.push(formatted);
        currentDate.add(1, 'day');
    }
    return dateValueMap;
}


export const getFormattedMonthYear = (dateRange) => {
    const startDate = moment(dateRange.startTime, 'YYYY-MM-DD');
    const endDate = moment(dateRange.endTime, 'YYYY-MM-DD');

    const startMonthYear = startDate.format('MMMM YYYY');
    const endMonthYear = endDate.format('MMMM YYYY');

    if (startDate.isSame(endDate, 'month') && startDate.isSame(endDate, 'year')) {
        return startMonthYear;
    } else {
        return `${startMonthYear} - ${endMonthYear}`;
    }
}

export const getMonthYearRange = ({ startTime, endTime }) => {
    if (!startTime && !endTime) return [];
    let start = moment(startTime);
    let end = moment(endTime);
    let monthYearRange = [];

    while (start.isSameOrBefore(end, 'month')) {
        let startOfMonth = start.clone().startOf('month');
        let endOfMonth = start.clone().endOf('month');
    
        monthYearRange.push({
            month: start.format('MMM YYYY'),
            range: {
                startTime: startOfMonth.format('YYYY-MM-DD'),
                endTime: endOfMonth.format('YYYY-MM-DD')
            }
        });
        start.add(1, 'month');
    }

    return monthYearRange;
}

export const formatNumber = (num) => {
    if (!num) return ;
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    } else {
        return num.toString();
    }
}

export const getInitialDateobj = (dates) => {
    const date = moment(dates.startTime, 'YYYY-MM-DD');
    return {
        startTime: date.startOf('month').format('YYYY-MM-DD'),
        endTime: date.endOf('month').format('YYYY-MM-DD')
    }
}