import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import distinboundFilters from '../../reduxLib/constdata/distinboundFilters';
import distoutboundFilters from '../../reduxLib/constdata/distoutboundFilters';
import { defaultInboundFilterArgs, defaultOutboundFilterArgs } from "../../reduxLib/constdata/filters"
import { defaultInboundColumns } from "../../reduxLib/constdata/distinboundColumns";
import { defaultOutboundColumns } from "../../reduxLib/constdata/distoutboundColumns";
import { tableoptions } from '../../helpers/tableStyleOverride';
import { endpoints } from "helpers";
import { get } from "lodash";
import TableComponent from "../common/TableComponent";
import ItemDetailsTableOutBound, { columns as outBoundDetailsColumns } from "../common/ItemDetailsTableOutBound";
import ItemDetailsTableInBound, { columns as inBoundDetailsColumns } from "../common/ItemDetailsTableInBound";
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';

export const tabTitleEnum = {
    outbound: 'DIST_OUTBOUND_SHIPMENTS',
    inbound: 'DIST_INBOUND_SHIPMENTS',
}

function TabPanel(props) {
    const { children, value, index, pageDetails, ...other } = props;
    const boxClasses = useStyles();
    return (
        <Grid
            className={useStyles().tabpanel}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {((value === index && !pageDetails?.filterParams?.state) || (value === index && pageDetails?.filterParams?.state === index) || (value === index && pageDetails?.filterParams?.state === 'DIST')) && (
                <Box className={boxClasses.filterPadding}>
                    {children}
                </Box>
            )}
        </Grid>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
        'data-testid': `dist-tab-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.primary.base,
    },
    appbar: {
        background: theme.palette.primary.base,
        borderBottom: '1px solid #279CFF',
    },
    filterPadding:{
        paddingTop:theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            paddingTop:theme.spacing(0),
        }
    },
    tab: {
        width: 10,
        [theme.breakpoints.down("sm")]: {
            flexBasis: "50%"
        }
    },
    tabpanel: {
        padding: `${theme.spacing(2)} 0px`
    },
    tabName: {
        fontFamily: 'Gilroy',
        fontSize: 16,
        fontWeight: 600,
        textAlign: "center",
        cursor: 'pointer',
        textTransform: 'capitalize'
    }
}));

export const inboundConditionalFilterReplacement = (newfilters) => {
    if (get(newfilters, "inboundOrderExecutionBucket", []).length === 0) {
        newfilters.inboundOrderExecutionBucket = defaultInboundFilterArgs.inboundOrderExecutionBucket;
    }
}

export const outboundConditionalFilterReplacement = (newfilters) => {
    if (get(newfilters, "orderExecutionBucket", []).length === 0) {
        newfilters.orderExecutionBucket = defaultOutboundFilterArgs.orderExecutionBucket;
    }
}

export const tableStyleConfigOutbound = {
    ...tableoptions,
    rowStyle: (d) => {
        const healthStatusBucket = d.orderExecutionHealth ? d.orderExecutionHealth : "";
        return {
            ...tableoptions.rowStyle,
            borderLeft: healthStatusBucket === "RED" ? '5px solid #FF4081' : healthStatusBucket === "YELLOW" ? '5px solid #F5B023' : ''
        }
    },
}

export const tableStyleConfigInbound = {
    ...tableoptions,
    rowStyle: (d) => {
        const healthStatusBucket = d.inboundOrderExecutionHealth ? d.inboundOrderExecutionHealth : "";
        return {
            ...tableoptions.rowStyle,
            borderLeft: healthStatusBucket === "RED" ? '5px solid #FF4081' : healthStatusBucket === "YELLOW" ? '5px solid #F5B023' : ''
        }
    },
}

const DistributionTabs = ({ pageTitle, setDetails, siteNums, pageDetails, setActiveTopNavTab, prevPageDetails, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { type, tabletype } = rest?.match?.params;
    const [value, setValue] = React.useState(tabTitleEnum[tabletype]);
    const tableRef = React.createRef();
    const match = useRouteMatch();
    const history = useHistory();

    const changeType = (type) => {
        const val = {
            DIST_OUTBOUND_SHIPMENTS: 'outbound',
            DIST_INBOUND_SHIPMENTS: 'inbound'
        }
        const path = generatePath(match.path, {
            ...match.params,
            tabletype: val[type]
        });
        history.push(path);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        changeType(newValue);
    };

    useEffect(() => {
        if (!match.params?.tabletype) {
            changeType(value)
        }
    }, [value])

    useEffect(() => {
        setValue(tabTitleEnum[tabletype] || "DIST_OUTBOUND_SHIPMENTS")
    }, [tabletype])

    return (
        <div className={classes.root} data-testid="distributiontabs">
            <AppBar position="static" className={classes.appbar} elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="primary"
                >
                    <Tab
                        className={classes.tab}
                        selectionFollowsFocus
                        value={tabTitleEnum.outbound}
                        label={<Typography className={classes.tabName} variant="h2" data-testid="distOutboundTab">{t("outbound_title")}</Typography>}
                        {...a11yProps(0)}
                    />
                    <Tab
                        className={classes.tab}
                        selectionFollowsFocus
                        value={tabTitleEnum.inbound}
                        label={<Typography className={classes.tabName} variant="h2" data-testid="distInboundTab">{t("inbound_title")}</Typography>}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={tabTitleEnum.outbound} pageDetails={pageDetails}>
                <TableComponent {...rest}
                    pageTitle={pageTitle}
                    setDetails={setDetails}
                    prevPageDetails={prevPageDetails}
                    activeSubTableTab={value}
                    pageDetails={pageDetails}
                    siteNums={siteNums}
                    tableName="distoutbound"
                    excludeArray={["searchStringList"]}
                    searchTextPlaceholder={t('search_helper_text_network_page')}
                    type={type}
                    tableRef={tableRef}
                    ItemDetailSection={ItemDetailsTableOutBound}
                    itemDetailsColumns={outBoundDetailsColumns}
                    defaultFilterArgs={defaultOutboundFilterArgs}
                    columns={defaultOutboundColumns}
                    conditionalFilterReplacement={outboundConditionalFilterReplacement}
                    filterBody={distoutboundFilters}
                    filterType="distoutboundfilters"
                    fetchEndPoint={endpoints.table.distributionOutbound}
                    tableStyleConfig={tableStyleConfigOutbound}
                />
            </TabPanel>
            <TabPanel value={value} index={tabTitleEnum.inbound} pageDetails={pageDetails}>
                <TableComponent {...rest}
                    pageTitle={pageTitle}
                    setDetails={setDetails}
                    prevPageDetails={prevPageDetails}
                    activeSubTableTab={value}
                    pageDetails={pageDetails}
                    siteNums={siteNums}
                    tableName="distinbound"
                    excludeArray={["searchStringList"]}
                    searchTextPlaceholder={t("search_helper_text_transportation_page")}
                    type={type}
                    tableRef={tableRef}
                    ItemDetailSection={ItemDetailsTableInBound}
                    itemDetailsColumns={inBoundDetailsColumns}
                    defaultFilterArgs={defaultInboundFilterArgs}
                    columns={defaultInboundColumns}
                    conditionalFilterReplacement={inboundConditionalFilterReplacement}
                    filterBody={distinboundFilters}
                    filterType="distinboundfilters"
                    fetchEndPoint={endpoints.table.distributionInbound}
                    tableStyleConfig={tableStyleConfigInbound}
                />
            </TabPanel>
        </div>
    );
}



export default DistributionTabs