import React from 'react';
import { Button, Typography } from '@material-ui/core';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment-timezone";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ExpandMore } from '@material-ui/icons';
import { formatDate } from "helpers";
import useSetQuery from 'customHooks/useQuery';
import { useStyles } from 'components/osm/OsmDateRangePicker';

const EMEADateRangePicker = ({ title, filterKey="documentDate", filterType, maxMonth }) => {
    const classes = useStyles({ filterType });
    moment.locale(localStorage.getItem('i18nextLng'));

    const [params, setParams] = useSetQuery();

    const dateRange = params[filterKey];

    const showTitle = filterType !== "daterange";

    const applyDates = (range) => {
        setParams({ key: filterKey, value: range });
    }

    return (
        <DateRangePicker
            initialSettings={{
                ...dateRange,
                maxSpan: { "months": maxMonth }
            }}
            onApply={(e, item) => {
                applyDates({
                    startTime: item?.startDate.format(`YYYY-MM-DD`),
                    endTime: item?.endDate.format(`YYYY-MM-DD`)
                });
            }}
        >
            <Button
                data-testid='emeaDateRangeFilter'
                disableRipple
                endIcon={<ExpandMore />}
                startIcon={<DateRangeIcon />}
                className={{
                    [classes.datepicker]: true,
                    [classes.zeroMargin]: true
                }}
            >
                {
                    showTitle &&
                    <Typography className={{
                        [classes.title]: true,
                        [classes.title2]: true
                    }} > {title} </Typography>
                }
                {formatDate(dateRange?.startTime) || "Start Date"} to {formatDate(dateRange?.endTime) || "End Date"}
            </Button>
        </DateRangePicker >
    );

}

export default EMEADateRangePicker;