import React from 'react';
import moment from "moment-timezone";
import CustomCellRender from "../../components/common/CustomCellRender";
import { get } from "lodash";
import { shortOsmCellStyle, smallOsmCellStyle } from 'helpers/tableStyleOverride';
import { msToHrs } from "helpers";
import GreenLightIcon from '../../assets/images/green_circle.svg';
import RedLightIcon from '../../assets/images/red_circle.svg';
import YellowLightIcon from '../../assets/images/yellow_circle.svg';

export const OSMSalesOrderDetailsColumns = {
  columnOrder: [
    {
      title: 'order_num', field: "orderNum", 
      render: rowData => {
        return <CustomCellRender id="orderNum" value={rowData.orderNum} />
      }
    },
    {
      title: 'delivery', field: "deliveryNum", 
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    {
      title: 'stage', field: "stage",
      render: rowData => {
        return <CustomCellRender id="stage" value={rowData.stage} />
      }
    },
    {
      title: 'sales_doc_type', field: "docType", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="docType" value={rowData.docType} />
      }
    },
    {
      title: 'sales_org', field: "salesOrg", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesOrg" value={rowData.salesOrg} />
      }
    },
    {
      title: 'distribution_channel', field: "distributionChannel", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="distributionChannel" value={rowData.distributionChannel} />
      }
    },
    {
      title: 'shipping_condition', field: "shippingCondition", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
      }
    },
    {
      title: 'plant', field: "originSiteNum", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="originSiteNum" value={rowData.originSiteNum} />
      }
    },
    {
      title: 'shipping_point', field: "shippingPoint", 
      render: rowData => {
        return <CustomCellRender id="shippingPoint" value={rowData.shippingPoint} />
      }
    },
    {
      title: 'receiving_point', field: "receivingPoint", 
      render: rowData => {
        return <CustomCellRender id="receivingPoint" value={rowData.receivingPoint} />
      }
    },
    {
      title: 'route', field: "route",
      render: rowData => {
        return <CustomCellRender id="route" value={rowData.route} />
      }
    },
    {
      title: 'ship_to', field: "shipTo", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipTo" value={rowData.shipTo} />
      }
    },
    {
      title: 'ship_to_name', field: "shipToName", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipToName" value={rowData.shipToName} />
      }
    },
    {
      title: 'ship_to_city', field: "shipToCity", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipToCity" value={rowData.shipToCity} />
      }
    },
    {
      title: 'ship_to_region', field: "shipToRegion", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipToRegion" value={rowData.shipToRegion} />
      }
    },
    {
      title: 'ship_to_postalcode', field: "shipToPostalCode", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipToPostalCode" value={rowData.shipToPostalCode} />
      }
    },
    {
      title: 'sold_to', field: "soldTo", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="soldTo" value={rowData.soldTo} />
      }
    },
    {
      title: 'sold_to_name', field: "soldToName", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="soldToName" value={rowData.soldToName} />
      }
    },
    {
      title: 'mad', field: "matAvailDate", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="matAvailDate" value={rowData.matAvailDate} />
      }
    },
    {
      title: 'requested_delivery_date', field: "requestedDeliveryDate", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="requestedDeliveryDate" value={rowData.requestedDeliveryDate} />
      }
    },
    {
      title: 'delivery_date', field: "deliveryDate", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryDate" value={rowData.deliveryDate} />
      }
    },
    {
      title: 'carrier_ready_date_time', field: "loadReadyDateTime", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadReadyDateTime" value={rowData.loadReadyDateTime} />
      }
    },
    {
      title: 'estm_loading_hours', field: "estimatedLoadTimeMS", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="estimatedLoadTimeMS" value={rowData.estimatedLoadTimeMS} />
      }
    },
    // {
    //   title: 'direct_load_indicator', field: "directLoadInd", 
    //   render: rowData => {
    //     return <CustomCellRender id="directLoadInd" value={rowData.directLoadInd} />
    //   }
    // },
    {
      title: 'rank_segmentation', field: "rankSegmentation", 
      render: rowData => {
        return <CustomCellRender id="rankSegmentation" value={rowData.rankSegmentation} />
      }
    },
    {
      title: 'fin_unfin_goods', field: "finishedGoodsInd", 
      render: rowData => {
        return <CustomCellRender id="finishedGoodsInd" value={rowData.finishedGoodsInd} />
      }
    },
    {
      title: 'haz_cd', field: "hazardOrderInd", 
      render: rowData => {
        return <CustomCellRender id="hazardOrderInd" value={rowData.hazardOrderInd} />
      }
    },
    {
      title: 'leq_cb', field: "loadEquivCube", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadEquivCube" value={rowData.loadEquivCube} />
      }
    },
    {
      title: 'customer_po_#', field: "customerPoNum", 
      render: rowData => {
        return <CustomCellRender id="customerPoNum" value={rowData.customerPoNum} />
      }
    },
    {
      title: 'po_type', field: "poType", 
      render: rowData => {
        return <CustomCellRender id="poType" value={rowData.poType} />
      }
    },
    {
      title: 'pro_#', field: "proHash", 
      render: rowData => {
        return <CustomCellRender id="proHash" value={rowData.proHash} />
      }
    },
    {
      title: 'order_header_db', field: "orderHeaderDb", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderHeaderDb" value={rowData.orderHeaderDb} />
      }
    },
    {
      title: 'header_billing_block', field: "billingBlockCode", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="billingBlockCode" value={rowData.billingBlockCode} />
      }
    },
    {
      title: 'payment_terms', field: "paymentTerms", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="paymentTerms" value={rowData.paymentTerms} />
      }
    },
    {
      title: 'incoterms_one', field: "incoTermsOne", 
      render: rowData => {
        return <CustomCellRender id="incoTermsOne" value={rowData.incoTermsOne} />
      }
    },
    {
      title: 'incoterms_two', field: "incoTermsTwo", 
      render: rowData => {
        return <CustomCellRender id="incoTermsTwo" value={rowData.incoTermsTwo} />
      }
    },
    {
      title: 'credit_status', field: "overallCreditStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="overallCreditStatus" value={rowData.overallCreditStatus} />
      }
    },
    {
      title: 'credit_status_light', field: "creditStatusLight", cellStyle: smallOsmCellStyle,
      render: rowData => {
        // return <CustomCellRender id="creditStatusLight" value={rowData.creditStatusLight} />
        return <div data-testid="creditStatusLight"> 
          { rowData?.creditStatusLight === 'GREEN' ? <img src={GreenLightIcon} alt="Green Light" /> : rowData?.creditStatusLight === 'RED' ? 
            <img src={RedLightIcon} alt="Red Light" /> : <img src={YellowLightIcon} alt="Yellow Light" />} 
        </div>
      }
    },
    {
      title: 'goods_issue_status', field: "goodsIssueStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="goodsIssueStatus" value={rowData.goodsIssueStatus} />
      }
    },
    {
      title: 'sto_receipt_status', field: "stoReceiptStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="stoReceiptStatus" value={rowData.stoReceiptStatus} />
      }
    },
    {
      title: 'credtord_create_time_ord', field: "orderCreateDateTime", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderCreateDateTime" value={rowData.orderCreateDateTime} />
      }
    },
    {
      title: 'credtdel_create_time_dlv', field: "deliveryCreateDateTime", cellStyle: shortOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryCreateDateTime" value={rowData.deliveryCreateDateTime} />
      }
    },
    {
      title: 'priority', field: "priority", 
      render: rowData => {
        return <CustomCellRender id="priority" value={rowData.priority} />
      }
    },
    {
      title: 'spec_proc', field: "specialProcessInd", 
      render: rowData => {
        return <CustomCellRender id="specialProcessInd" value={rowData.specialProcessInd} />
      }
    },
    {
      title: 'coll_key', field: "collectiveNum", 
      render: rowData => {
        return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
      }
    },
    {
      title: 'appt_confirm', field: "appointmentConfirm", 
      render: rowData => {
        return <CustomCellRender id="appointmentConfirm" value={rowData.appointmentConfirm} />
      }
    },
    {
      title: 'appt_contact', field: "appointmentContact", 
      render: rowData => {
        return <CustomCellRender id="appointmentContact" value={rowData.appointmentContact} />
      }
    },
    {
      title: 'appt_num', field: "appointmentNumber", 
      render: rowData => {
        return <CustomCellRender id="appointmentNumber" value={rowData.appointmentNumber} />
      }
    },
    {
      title: 'live', field: "liveLoadInd", 
      render: rowData => {
        return <CustomCellRender id="liveLoadInd" value={rowData.liveLoadInd} />
      }
    },
    {
      title: 'condition_group_ship_with', field: "conditionGroupShipWith", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="conditionGroupShipWith" value={rowData.conditionGroupShipWith} />
      }
    },
    {
      title: 'condition_group_sequence', field: "conditionGroupSequence", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="conditionGroupSequence" value={rowData.conditionGroupSequence} />
      }
    },
    {
      title: 'actual_gi_date', field: "actualGiDate", 
      render: rowData => {
        return <CustomCellRender id="actualGiDate" value={rowData.actualGiDate} />
      }
    },
    {
      title: 'total_gm_status', field: "totalGmStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="totalGmStatus" value={rowData.totalGmStatus} />
      }
    },
    {
      title: 'cust_serv_rep', field: "custServRep", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="custServRep" value={rowData.custServRep} />
      }
    },
    {
      title: 'csr_name', field: "csrName", 
      render: rowData => {
        return <CustomCellRender id="csrName" value={rowData.csrName} />
      }
    },
    {
      title: 'order_created_by', field: "orderCreatedBy", 
      render: rowData => {
        return <CustomCellRender id="orderCreatedBy" value={rowData.orderCreatedBy} />
      }
    },
    {
      title: 'order_created_by_name', field: "orderCreatedByName", 
      render: rowData => {
        return <CustomCellRender id="orderCreatedByName" value={rowData.orderCreatedByName} />
      }
    },
    {
      title: 'dlv_created_by', field: "dlvCreatedBy", 
      render: rowData => {
        return <CustomCellRender id="dlvCreatedBy" value={rowData.dlvCreatedBy} />
      }
    },
    {
      title: 'dlv_created_by_name', field: "dlvCreatedByName", 
      render: rowData => {
        return <CustomCellRender id="dlvCreatedByName" value={rowData.dlvCreatedByName} />
      }
    },
    {
      title: 'dlv_last_changed_by', field: "dlvLastChangedBy", 
      render: rowData => {
        return <CustomCellRender id="dlvLastChangedBy" value={rowData.dlvLastChangedBy} />
      }
    },
    {
      title: 'dlv_last_changed_by_name', field: "dlvChangedByName", 
      render: rowData => {
        return <CustomCellRender id="dlvChangedByName" value={rowData.dlvChangedByName} />
      }
    },
    {
      title: 'sales_office', field: "salesOffice", 
      render: rowData => {
        return <CustomCellRender id="salesOffice" value={rowData.salesOffice} />
      }
    },
    {
      title: 'sales_group', field: "salesGroup", 
      render: rowData => {
        return <CustomCellRender id="salesGroup" value={rowData.salesGroup} />
      }
    },
    {
      title: 'i2_flag', field: "i2TriggerFlag", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="i2TriggerFlag" value={rowData.i2TriggerFlag} />
      }
    },
    {
      title: 'fully_picked', field: "fullyPicked", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="fullyPicked" value={rowData.fullyPicked} />
      }
    },
    {
      title: 'z856_asn', field: "z856ASN", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="z856ASN" value={rowData.z856ASN} />
      }
    },
    {
      title: 'hdr_console_relevant', field: "hdrConsolRelevant", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="hdrConsolRelevant" value={rowData.hdrConsolRelevant} />
      }
    },
    {
      title: 'hdr_descre_relevant', field: "hdrDiscreRelevant", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="hdrDiscreRelevant" value={rowData.hdrDiscreRelevant} />
      }
    },
    {
      title: 'hdr_parcel_label_print_status', field: "hdrParcelLabelPrintStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="hdrParcelLabelPrintStatus" value={rowData.hdrParcelLabelPrintStatus} />
      }
    },
    {
      title: 'delivery_db', field: "deliveryDB", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryDB" value={rowData.deliveryDB} />
      }
    },
    {
      title: 'confirmed_estm_load_hrs', field: "confirmedEstLoadingHours", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="confirmedEstLoadingHours" value={rowData.confirmedEstLoadingHours} />
      }
    },
    {
      title: 'hdr_reject_status', field: "overallRejectionStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="overallRejectionStatus" value={rowData.overallRejectionStatus} />
      }
    },
    {
      title: 'hdr_ord_blng_status', field: "hdrOrdBlngStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="hdrOrdBlngStatus" value={rowData.hdrOrdBlngStatus} />
      }
    },
    {
      title: 'tms_load_appt_status', field: "tmsLoadApptStatus", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="tmsLoadApptStatus" value={rowData.tmsLoadApptStatus} />
      }
    },
    {
      title: 'tms_load_appt_status_text', field: "tmsLoadApptStatusText", cellStyle: smallOsmCellStyle,
      render: rowData => {
        return <CustomCellRender id="tmsLoadApptStatusText" value={rowData.tmsLoadApptStatusText} />
      }
    },
    {
      title: 'rfid', field: "rfidFlag", 
      render: rowData => {
        return <CustomCellRender id="rfidFlag" value={rowData.rfidFlag} />
      }
    }
  ],
  columnConfiguration: (d) => {
    return {
      shipToName: get(d, "orderDestination.name", "-"),
      shipToCity: get(d, "orderDestination.city", "-"),
      shipToRegion: get(d, "orderDestination.state", "-"),
			loadReadyDateTime: (d?.loadReadyDateTime) ? moment(d?.loadReadyDateTime)?.tz(d?.orderOrigin?.timezone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      orderCreateDateTime: (d?.orderCreateDateTime) ? moment(d?.orderCreateDateTime)?.tz(d?.orderOrigin?.timezone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      deliveryCreateDateTime: (d?.deliveryCreateDateTime) ? moment(d?.deliveryCreateDateTime)?.tz(d?.orderOrigin?.timezone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      estimatedLoadTimeMS: msToHrs(parseInt(get(d, "estimatedLoadTimeMS", 0)), d.estimatedLoadTimeMS),
      rfidFlag: (d?.rfidFlag !== undefined) ? (d.rfidFlag == "Y" ? "Yes" : "No") : "-",
      hazardOrderInd: (d?.hazardOrderInd !== undefined) ? (d.hazardOrderInd == "Y" ? "Yes" : "No") : "-",
      finishedGoodsInd: (d?.finishedGoodsInd !== undefined) ? (d.finishedGoodsInd == "Y" ? "Yes" : "No") : "-",
    }
  }
}