import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import ResponsiveWrapper from 'components/charts/ResponsiveWrapper';
import Pies, { PieLabel, Legends } from 'components/D3Charts/Pies';
import { makeStyles } from '@material-ui/core';
import { formatLocaleNumber } from 'helpers/emeaHelpers';

const useStyles = makeStyles(() => ({
    root: {
        height: ({ height }) => height
    }
}));

const DonutChart = (props) => {
    const { t } = useTranslation();
    const { xKey, yKey, height, loading, themeType, tooltipEvent, ...rest } = props;

    const classes = useStyles({ height });
    const [data, setData] = useState([]);
    const [totalSum, setTotalSum] = useState(() => data.reduce((a, b) => a + b[yKey], 0));

    useEffect(() => {
        setData(rest.data.map(d => ({ ...d, total: d[yKey], value: d[yKey] })))
        setTotalSum(rest.data.reduce((a, b) => a + b[yKey], 0))
    }, [rest.data]);

    const svgDimensions = {
        width: rest.parentWidth,
        height
    }

    const centerPos = [svgDimensions.width / 2, svgDimensions.height / 2];

    const InfoText = () => {
        const text = loading ? "loading" : "no_data_found"
        return (
            <g transform={`translate(${centerPos})`} data-testid={"no-data-found"}>
                <text textAnchor="middle" data-testid="noData">{t(text)}</text>
            </g>
        )
    }

    return (
        <div data-testid='donutChart' className={classes.root}>
            <svg width={svgDimensions.width}>
                {
                    (!totalSum || loading) ? <InfoText /> :
                        <g transform={"translate(" + svgDimensions.width / 2 + "," + svgDimensions.height / 3 + ")"}>
                            <Pies
                                data={data}
                                radius={70}
                                setDetails={() => { }}
                                xKey={xKey}
                                tooltipEvent={tooltipEvent}
                            />
                            <PieLabel title={"Total"} subtitle={formatLocaleNumber(totalSum)} />
                        </g>
                }
            </svg>
            {
                (!totalSum || loading) ? null : 
                <Legends {...rest} data={data} xKey={xKey} />
            }
        </div>
    )

}

export default ResponsiveWrapper(DonutChart)