import { emeaViewsConstants } from "reduxLib/constants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const resetEMEAViews = ({ tableName }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.RESET_VIEWS,
        tableName
    });
}

export const resetEMEAStatus = () => dispatch => {
    dispatch({
        type: emeaViewsConstants.RESET_STATUS,
    });
}


export const resetEMEAViewStatus = ({ tableName }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.RESET_EMEA_STATUS,
        tableName
    });
}

export const getAllEMEAViews = ({ tableName, controller }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.VIEWS_LOADING,
        payload: true,
        tableName
    });

    return fetch(`${endpoints.emea.getviews}?appName=${tableName}`, {
        method: "GET",
        signal: controller?.signal
    })
        .then(d => {
            if (d.status === 200) {
                return d.json()
            }
            throw "views_loading_failed"
        })
        .then(d => {
            const viewsData = d.layouts?.filter(e => e.tableName === tableName)[0] || {};
            const views = (viewsData.privateViews || [])
                .map(e => ({ ...e, isFav: e.id === viewsData.favViewID }));
            dispatch({
                type: emeaViewsConstants.GET_ALL_VIEWS_SUCCESS,
                status: {
                    status: "success",
                    show: false,
                    message: "views_loaded_successfully"
                },
                payload: { views },
                tableName,
            });
        })
        .catch((e) => {
            dispatch({
                type: emeaViewsConstants.GET_ALL_VIEWS_FAILED,
                status: {
                    status: "error",
                    message: e,
                    show: false,
                },
                tableName,
                payload: { views: [], activeView: {} }
            });
        });
}

export const getEMEAView = ({ viewid, tableName }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.VIEW_LOADING,
        payload: true,
        tableName
    });

    return fetch(`${endpoints.emea.getview}?tableName=${tableName}&viewID=${viewid}`, {
        method: "GET",
    })
        .then(d => {
            if (d.status === 200) {
                return d.json();
            }
            throw "Error";
        })
        .then(d => {
            dispatch({
                type: emeaViewsConstants.GET_VIEW_SUCCESS,
                status: {
                    show: false,
                    status: "success",
                    message: "get_view_success"
                },
                tableName,
                payload: d
            });

        })
        .catch(() => {
            dispatch({
                type: emeaViewsConstants.GET_VIEW_FAILED,
                status: {
                    show: false,
                    status: "error",
                    message: "get_view_failed"
                },
                tableName,
                payload: {}
            });
        })
}

export const updateEMEAView = ({ view, tableName, updateType = "update" }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.VIEW_LOADING,
        payload: true,
        tableName
    });

    return fetch(`${endpoints.emea.updateviews}?tableName=${tableName}`, {
        method: "PUT",
        body: {
            ...view,
            tableName
        },
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: emeaViewsConstants.INSERT_UPDATE_VIEW_SUCCESS,
                    status: {
                        show: true,
                        status: "success",
                        message: "view_created_updated_success"
                    },
                    tableName,
                    updateType,
                    payload: view
                });
            } else {
                throw "Error";
            }
        })
        .catch((e) => {
            dispatch({
                type: emeaViewsConstants.INSERT_UPDATE_VIEW_FAILED,
                status: {
                    show: true,
                    status: "error",
                    message: "view_creation_updation_failed"
                },
                tableName,
                updateType,
                payload: {}
            });
        })
}

export const deleteEMEAView = ({ viewid, tableName }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.VIEW_LOADING,
        payload: true,
        tableName
    });

    return fetch(`${endpoints.emea.deleteviews}?tableName=${tableName}&viewID=${viewid}`, {
        method: "DELETE",
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: emeaViewsConstants.DELETE_VIEW_SUCCESS,
                    status: {
                        status: "success",
                        show: true,
                        message: "view_deleted_success"
                    },
                    tableName,
                    payload: viewid
                });
            } else {
                throw "Error";
            }
        })
        .catch(() => {
            dispatch({
                type: emeaViewsConstants.DELETE_VIEW_FAILED,
                status: {
                    status: "error",
                    show: true,
                    message: "view_deleted_failed"
                },
                tableName,
                payload: null
            });
        })
}

export const favUnfavEMEAView = ({ viewid, tableName, isFav, method }) => dispatch => {
    dispatch({
        type: emeaViewsConstants.VIEW_LOADING,
        payload: true,
        tableName
    });

    return fetch(`${endpoints.emea.favView}?tableName=${tableName}`, {
        method: "PUT",
        body: {
            id: method === "fav" ? viewid: "",
            tableName: tableName
        }
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: emeaViewsConstants.FAV_UNFAV_VIEW_SUCCESS,
                    status: {
                        status: "success",
                        show: true,
                        message: "view_fav_unfav_success"
                    },
                    tableName,
                    isFav,
                    payload: viewid
                });
            } else {
                throw "Error"
            }
        })
        .catch(() => {
            dispatch({
                type: emeaViewsConstants.FAV_UNFAV_VIEW_FAILED,
                status: {
                    status: "error",
                    show: true,
                    message: "view_fav_unfav_failed"
                },
                tableName,
                payload: null
            });
        });
}


