import { stockConstraintReportConstants } from "../constants";

const stockConstraintReportReducer = (state = {
    loading: false,
    shipmentList: [],
    shipment_data: [],
    recomendation_data: [],
    materialsList: [],
    materialsOutbound: {},
    materialsInbound: {}
}, action) => {
    switch(action.type) {
        case stockConstraintReportConstants.FETCH_SHIPMENT_LIST_SUCCESS:
        case stockConstraintReportConstants.SHIPMENT_LIST_FAILED:
            return {
                ...state,
                shipmentList: action.payload,
                materialsList: [],
                shipment_data: [],
                materialsOutbound: {},
                materialsInbound: {}
            }
        case stockConstraintReportConstants.LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case stockConstraintReportConstants.SEARCH_SHIPMENT_SUCCESS:
        case stockConstraintReportConstants.SEARCH_SHIPMENT_FAILED:
            return {
                ...state,
                shipment_data: action.payload
            }
        case stockConstraintReportConstants.FETCH_RECOMENDATIONS_SUCCESS:
        case stockConstraintReportConstants.RECOMENDATIONS_FAILED:
            return {
                ...state,
                recomendation_data: action.payload
            }
       //Material #
        case stockConstraintReportConstants.FETCH_MATERIALS_SUCCESS:
        case stockConstraintReportConstants.MATERIALS_FAILED:
            return {
                ...state,
                materialsList: action.payload,
                shipmentList: [],
                shipment_data: [],
                materialsOutbound: {},
                materialsInbound: {}
            }
        case stockConstraintReportConstants.FETCH_MATERIALS_OUTBOUND_SUCCESS:
        case stockConstraintReportConstants.MATERIALS_OUTBOUND_FAILED:
            return {
                ...state,
                materialsOutbound: action.payload
            }
        case stockConstraintReportConstants.FETCH_MATERIALS_INBOUND_SUCCESS:
        case stockConstraintReportConstants.MATERIALS_INBOUND_FAILED:
            return {
                ...state,
                materialsInbound: action.payload
            }
        default:
            return state;
    }
}

export default stockConstraintReportReducer;