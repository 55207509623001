import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  report: {
    padding: `0px ${theme.spacing(1)}px`,
    height: `${theme.spacing(12.25)}vh`,
    marginTop: theme.spacing(4),
    width: '100%'
  }
}));

const EMEACustServiceLevel = () => {
  const classes = useStyles();
  const reportURL = "https://app.powerbi.com/reportEmbed?reportId=c79b42d0-cd11-47e5-9563-094289146017&autoAuth=true&ctid=fee2180b-69b6-4afe-9f14-ccd70bd4c737&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtZS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9";

  return (
    <>
      <main key={reportURL}>
        <iframe
          data-testid="report"
          className={classes.report}
          src={reportURL}
          frameborder="0"
          allowFullScreen="true">
        </iframe>
      </main>
    </>
  )
}

export default EMEACustServiceLevel;