const ordersfilters = {
    searchStringList: {
        type: "text",
        name: "search",
        stringToArray: true,
        data: null
    },
    destName: {
        type: "text",
        name: "customer",
        data: null
    },
    sites: {
        type: "sites",
        name: "origin",
        data: null
    },
    shippingConditionList: {
        type: "text",
        stringToArray: true,
        name: "shipping_condition",
        data: null
    },
    requestedDeliveryDate: {
        type: "date",
        name: "requested_delivery_date",
        shortName: 'Req. Delivery Date',
        data: null
    },
    collectiveNumList: {
        type: "text",
        name: "collectiveNumber",
        stringToArray: true,
        data: null
    },
    promotionalOrder: {
        type: "checkboxradio",
        name: "promotionalOrder",
        defaultValue: null,
        data: [
            { name: "show_only_promotional_orders", value: "Yes", checked: false },
            { name: "hide_promotional_orders", value: "No", checked: false },
        ]
    },
    deliveryBlockCodeList: {
        type: "textcheck",
        stringToArray: true,
        name: "delivery_credit_block",
        placeholder: 'enter_delivery_block_code',
        shortName: "Delivery Block Code",
        addon: "creditOnHold",
        data: null
    },
    creditOnHold: {
        hidden: true,
        type: "text",
        name: "show_only_credit_hold_orders",
        data: null
    },
    confirmedEquivCubes: {
        type: "checkbox",
        name: "confirmed_cube",
        shortName: 'Confirmed Cube',
        data: [
            { name: "< 1000", value: { lte: 999.9999999 }, checked: false },
            { name: "1000 - 2000", value: { gte: 1000, lte: 1999.9999999 }, checked: false },
            { name: "2000 - 3000", value: { gte: 2000, lte: 2999.9999999 }, checked: false },
            { name: "> 3000", value: { gte: 3000 }, checked: false },
        ]
    },
    destState: {
        type: "text",
        name: "destination_state",
        data: null
    },
    destCity: {
        type: "text",
        name: "destination_city",
        data: null
    },
    matAvailDate: {
        type: "date",
        name: "material_availability_date",
        shortName: 'Mat.Avail Date',
        data: null
    },
    actualDeliveredDateTime: {
        type: "date",
        name: "delivered_date_time",
        shortName: 'Delivered Date/Time ',
        data: null
    },
    orderStatusBucket: {
        type: "ordercheckbox",
        name: "order_status",
        data: [
            { name: "100%_confirmed_cube", value: "SO_COMPLETELY_CONFIRMED_CUBE", checked: false, parent: "Order Block Free", },
            { name: "less_than_100%_confirmed_cube", value: "SO_NOT_COMPLETELY_CONFIRMED_CUBE", checked: false, parent: "Order Block Free", },
            { name: "back_orders_block_free", value: "SO_BACK_ORDER_BLOCK_FREE", checked: false, parent: "Order Block Free", },
            { name: "visible_in_tms", value: "SO_BLOCKED_TMS_PLANNED_VISIBLE", checked: false, parent: "Orders Blocked" },
            { name: "not_visible_in_tms", value: "SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE", checked: false, parent: "Orders Blocked" },
            { name: "pickup_not_scheduled", value: "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED", checked: false, parent: "Orders Blocked" },
            { name: "pickup_package_multi_block", value: "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK", checked: false,parent: "Orders Blocked" },
            { name: "immediate_action", value: "SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION", checked: false, parent: "Orders Blocked" },
            { name: "back_orders_blocked", value: "SO_BACK_ORDER_BLOCKED", checked: false, parent: "Orders Blocked" },
        ]
    },
    orderExecutionBucket: {
        type: "checkbox",
        name: "shipment_status",
        data: [
            { name: "transportation_to_be_planned", value: "TRANS_PLAN_UNASSIGNED", checked: false },
            { name: "transportation_planning", value: "TRANS_PLAN_OPEN", checked: false },
            { name: "transportation_processing", value: "TRANS_PLAN_PROCESSING", checked: false },
            { name: "transportation_carrier_committed", value: "TRANS_PLAN_TENDER_ACCEPTED", checked: false },
            { name: "shipment_created", value: "SHIPMENT_CREATED", checked: false },
            { name: "checked_in_by_DC", value: "CHECK_IN", checked: false },
            { name: "loading_started", value: "LOADING_STARTED", checked: false },
            { name: "ready_for_pickup", value: "TRANS_EXEC_READY_PICK_UP", checked: false },
            { name: "in_transit", value: "TRANS_EXEC_IN_TRANSIT", checked: false },
            { name: "delivery_confirmed", value: "TRANS_EXEC_DELIVERY_CONFIRMED", checked: false }
        ]
    },
    fourkitesShipmentStatus: {
        type: "checkbox",
        name: "fourkites_status",
        data: [
            { name: "in_transit", value: "TRANS_EXEC_IN_TRANSIT", checked: false },
            { name: "delivery_confirmed", value: "TRANS_EXEC_DELIVERY_CONFIRMED", checked: false }
        ]
    },
    inboundOrderExecutionBucket: {
        type: "checkbox",
        name: "inbound_shipment_status",
        data: [
            { name: "in_transit", value: "INBOUND_IN_TRANSIT", checked: false },
            { name: "in_yard", value: "INBOUND_IN_YARD", checked: false },
            { name: "unloading", value: "INBOUND_UNLOADING", checked: false },
            { name: "unloading_completed", value: "INBOUND_UNLOADING_COMPLETED", checked: false },
        ]
    },
    orderTypes: {
        type: "checkbox",
        name: "order_type",
        data: [
            { name: "all_customer_orders", value: "CUST", checked: false },
            { name: "STOs", value: "STO", checked: false }
        ]
    },
    documentTypes: {
        type: "checkbox",
        name: "document_type",
        data: [
            { name: "standard_orders", value: "ZSTD", checked: false },
            { name: "multi_plant_orders", value: "ZMPF", checked: false },
            { name: "vmi_orders", value: "ZVMI", checked: false },
            { name: "merged_orders", value: "ZMER", checked: false },
            { name: "foc_orders", value: "ZFOC", checked: false },
            { name: "non_finished_gds", value: "ZNFG", checked: false },
            { name: "third_party", value: "Z3PO", checked: false },
            { name: "dot_com_order", value: "ZCOM", checked: false },
            { name: "foc_mult_plant", value: "ZFOM", checked: false },
            { name: "replacement_ord", value: "ZREP", checked: false },
            { name: "scrap_sales", value: "ZSEC", checked: false },
            { name: "standard_order", value: "ZOR", checked: false },
            { name: "foc_non_finished_zrfn", value: "ZRFN", checked: false },
            { name: "fg_salvage", value: "ZFGS", checked: false },
            { name: "foc_non_finished_zfnf", value: "ZFNF", checked: false },
            // { name: "otc_intraco_sto_apo", value: "ZUB", checked: false },
            // { name: "otc_interco_sto_apo", value: "ZNB", checked: false },
            // { name: "two_intraco_sto_ph", value: "ZPH", checked: false },
            // { name: "otc_intraco_sto_manl", value: "ZUM", checked: false },
            // { name: "otc_interco_sto_manl", value: "ZNM", checked: false },
            // { name: "otc_intraco_sto_imprt", value: "ZUI", checked: false },
            // { name: "otc_interco_sto_imprt", value: "ZNI", checked: false },
            // { name: "otc_intraco_sto_spcmk", value: "ZUSM", checked: false },
            // { name: "otc_intercosto_spcmk", value: "ZNSM", checked: false },
            // { name: "standard_po", value: "NB", checked: false },
            // { name: "stock_transport_order", value: "UB", checked: false }
        ]
    },
    orderHealth: {
        type: "checkbox",
        name: "health",
        data: [
            { name: "unhealthy", value: "RED", checked: false },
            { name: "needs_attention", value: "YELLOW", checked: false },
            { name: "healthy", value: "GREEN", checked: false }

        ]
    },
    materialNumberList: {
        type: "text",
        name: "material_id",
        stringToArray: true,
        data: null
    }
}

export default ordersfilters;