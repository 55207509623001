import { osmAuthConstants } from "../constants";

const initialState = {
  osm_sites_loading: false,
  osm_sites: [],
  regions: [],
  countries: [],
  sites: [],
  currentSelections: { regions: [], countries: [], sites: [], business: ["CONSUMER","PROFESSIONAL"] },
  osm_sites_error: null
}

const mapRegions = (osmSites) => {
  return Object.keys(osmSites);
}

const mapCountries = (osmSites, region) => {
  let values = [];
  const regionList = Object.keys(osmSites);
  let regionArr = region?.length ? region : regionList?.length ? [regionList[0]] : [];
  if(regionArr?.length) {
    for(const item of regionArr) {
      values.push(...Object.keys(osmSites[item]));
    }
    return values;
  } else return values;
}

export const mapSites = (osmSites, region, country, countryList=[]) => {
  let values = [];
  let countryArr = country?.length ? country : countryList?.length ? [countryList[0]] : [];
  if(countryArr?.length) {
    for(const item of region) {
      for(const loc of countryArr) {
        for(const element of osmSites[item][loc]) {
          values.push({ ...element, name: `${element.st_short_name} (${element.st_site_num})` });
        }
      }
    }
    return values;
  } else {
    return values;
  }
}

const updateCurrentRegions = (osmSites, stateRegions, regionsList) => {
  const length = regionsList?.length;
  const regions = length ? regionsList : stateRegions?.length ? [stateRegions[0]] : [];
  const countries = regions?.length ? mapCountries(osmSites, regions) : [];
  const sites = regions?.length ? mapSites(osmSites, regions, countries) : [];
  return { regions: regions, countries: countries, sites: sites, business: ["CONSUMER","PROFESSIONAL"]} ;
}

const updateCurrentCountries = (osmSites, regionsList, stateCountries, countriesList) => {
  const length = countriesList?.length;
  const countries = length ? countriesList : stateCountries?.length ? [stateCountries[0]] : [];
  const sites = countries?.length ? mapSites(osmSites, regionsList, countries) : [];
  return {regions: regionsList, countries: countries, sites: sites, business: ["CONSUMER","PROFESSIONAL"]};
}

const updateCurrentSites = (osmFilteredSites, siteList) => {
  let values = [];
  if (siteList?.length) {
    values = osmFilteredSites?.filter(item => siteList?.includes(item.name));
    return values;
  } else {
    values = osmFilteredSites?.length ? [osmFilteredSites[0]] : [];
    return values;
  }
}

export const mapSitesByBusiness = (osmSites, regions, countries, businessList) => {
  let values = [];
  if (!businessList.length) {
    values = mapSites(osmSites, regions, countries);
    return values;
  } else if (businessList.length == 2) {
    values = mapSites(osmSites, regions, countries);
    return values;
  } else {
    const BU = businessList[0];
    for(const item of regions) {
      for(const loc of countries) {
        for(const element of osmSites[item][loc]) {
          element?.businessUnitsList.includes(BU) && values.push({ ...element, name: `${element.st_short_name} (${element.st_site_num})` });
        }
      }
    }
    return values;
  }
}

const osmAuthReducer = (state = initialState, action) => {
  switch (action.type) {

    case osmAuthConstants.OSM_SITES_LOADING:
      return { ...state, osm_sites_loading: action.payload };

    case osmAuthConstants.OSM_SITES_FETCH_SUCCESS:
    case osmAuthConstants.OSM_SITES_FETCH_FAILED:
      return { ...state, osm_sites: action.payload, osm_sites_error: action.error };
    
    case osmAuthConstants.OSM_MAP_REGIONS:
      return { ...state, regions: mapRegions(action.payload) };
    
    case osmAuthConstants.OSM_MAP_COUNTRIES:
      return { ...state, countries: mapCountries(action.payload, state.regions) };
    
    case osmAuthConstants.OSM_MAP_SITES:
      return { ...state, sites: mapSites(action.payload, state.regions, state.countries) };
    
    case osmAuthConstants.OSM_CURRENT_REGIONS:
      return { ...state,
                countries: mapCountries(state.osm_sites, action.payload), 
                sites: mapSites(state.osm_sites, action.payload, state.countries),
                currentSelections: updateCurrentRegions(state.osm_sites, state.regions, action.payload)
              };
      
    case osmAuthConstants.OSM_CURRENT_COUNTRIES:
      return { ...state, 
                countries: mapCountries(state.osm_sites, state.currentSelections.regions), 
                sites: mapSites(state.osm_sites, state.currentSelections.regions, action.payload, state.countries),
                currentSelections: updateCurrentCountries(state.osm_sites, state.currentSelections.regions, state.countries, action.payload) 
              };

    case osmAuthConstants.OSM_CURRENT_SITES:
      return { ...state, currentSelections: { ...state.currentSelections, sites: updateCurrentSites(state.sites, action.payload) } };

    case osmAuthConstants.OSM_CURRENT_BUSINESS:
      return { ...state, 
              sites: mapSitesByBusiness(state.osm_sites, state.currentSelections.regions, state.currentSelections.countries, action.payload), 
              currentSelections: {...state.currentSelections, 
                                  business: action.payload?.length ? action.payload : ["CONSUMER","PROFESSIONAL"], 
                                  sites: mapSitesByBusiness(state.osm_sites, state.currentSelections.regions, state.currentSelections.countries, action.payload) 
                                } 
            }

    default:
      return state;
    }
  }
  
  export default osmAuthReducer;
  