import React from 'react';
import { makeStyles, FormControl, Input, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { osmSelectStyles } from "theme"
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { mapSitesByBusiness } from 'reduxLib/reducers/osmAuthReducer';
import { isJsonParsable } from 'helpers';
import map from 'lodash/map';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(osmSelectStyles);

export const SelectComponent = ({ labelId, testId, value, MenuProps, data, styleClass, onChange, renderValue, searchBar, ...props }) => {

  return (
    <Select
      labelId={labelId}
      data-testid={testId}
      multiple
      value={value}
      onChange={onChange}
      input={<Input />}
      displayEmpty={true}
      renderValue={renderValue}
      MenuProps={MenuProps}
      disableUnderline
      className={styleClass?.mutliSelect}
      SelectDisplayProps={ {"data-testid" : `${testId}-select`} }
      {...props}
    >
      {searchBar ? 
        <TextField size="small" variant='outlined' autoFocus fullWidth
          placeholder="Search"
          inputProps={{'data-testid': `${testId}-search`}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          value={props?.searchValue}
          onChange={(e) => props?.searchOnChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      : null}
      {data.map((item) => (
        <MenuItem key={item} value={item} className={styleClass.menuItem} data-testid={`menu-${item}`} >
          <Checkbox checked={value.indexOf(item) > -1} className={styleClass.checkbox} 
            inputProps={{'data-testid': `checkbox_${item}`}} />
          <ListItemText primary={item} />
        </MenuItem>
      ))}
    </Select>
  )
}

const OsmSelect = ({ labelId, testId, value, MenuProps, title, data, onChange, updateURLParam, osm_sites }) => {
  const classes = useStyles();
  const location = useLocation();
  const [queryParams, setQueryParams] = React.useState({});
  
  React.useEffect(() => {
    setQueryParams(qs.parse(location.search));
  }, [location.search])

  const handleChange = (targetValue) => {
    if (targetValue?.length === 0) {
      return null
    }
    onChange(targetValue); 
    if (title == 'Region') {
      const osmglobal = isJsonParsable(queryParams.osmglobal);
      const mappedSites = map(mapSitesByBusiness(osm_sites, targetValue, osmglobal?.countries, osmglobal?.business), "st_site_num");
      updateURLParam({
        regions: targetValue,
        sites: mappedSites,
        siteslist: mappedSites
      })
    }
    if (title == 'Country') {
      const osmglobal = isJsonParsable(queryParams.osmglobal);
      const mappedSites = map(mapSitesByBusiness(osm_sites, osmglobal?.regions, targetValue, osmglobal?.business), "st_site_num");
      updateURLParam({
        countries: targetValue,
        sites: mappedSites,
        siteslist: mappedSites
      })
    }
    if (title == 'Business') {
      const osmglobal = isJsonParsable(queryParams.osmglobal) || {};
      const mappedSites = map(mapSitesByBusiness(osm_sites, osmglobal?.regions, osmglobal?.countries, targetValue), "st_site_num");
      updateURLParam({
        business: targetValue,
        sites: mappedSites,
        siteslist: mappedSites
      })
    }
  }

  return (
    <FormControl variant="outlined" className={classes.formControl} data-testid="osmSelect">
      <SelectComponent labelId={labelId} data-testid={testId} value={value} MenuProps={MenuProps} styleClass={classes}
        onChange={(event) => handleChange(event.target.value)} data={data} 
        renderValue={(selected) => selected.join(', ')}
      />
    </FormControl>
  )
}

export default OsmSelect;