import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Typography, Button, Collapse, TextField } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import { updateRequestStatus } from "reduxLib/services/getAllGuardRailsService";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.base,
        '&:first-child': {
            marginTop: theme.spacing(1)
        },
        marginBottom: theme.spacing(2),
        position: 'relative',
    },
    button: {
        textTransform: 'none !important',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    declinebutton: {
        textTransform: 'none !important',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    acceptbutton: {
        textTransform: 'none !important',
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        background: theme.palette.menu.base,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        transition: 'ease-in 300ms',
        '&:hover':{
            color: theme.palette.primary.base
        }
    },
    text: {
        maxWidth: 500,
    },
    date: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(1),
    },
    decline: {
        display: 'block',
    },
    title: {
        fontSize: theme.spacing(2),
        textAlign: 'left',
    },
    status: {
        marginTop: theme.spacing(1)
    },
    textfield: {
        width: '100%',
        marginTop: 10
    }
}));

function dateToFromNowDaily(myDate) {
    moment.locale(localStorage.getItem('i18nextLng'));

    let date = moment(myDate);
    if (moment().diff(date, 'days') >= 2) {
        return date.fromNow();
    }
    return date.calendar().split(' ')[0];
}

export default function GuardrailRequestCard({ data }) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [status, setStatus] = React.useState(null);

    const { t } = useTranslation();

    const { pending_requests: pendingRequests } = useSelector(({ guardrails }) => guardrails);

    const currentRequest = pendingRequests.filter(d => d.requestID === data?.requestID)?.[0];

    const dispatch = useDispatch();
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const approveRequest = () => {
        const approved = {
            ...data,
            requestStatus: "Accepted",
            reason: ""
        }
        dispatch(updateRequestStatus(approved));
    }

    const declineRequest = (e) => {
        e.preventDefault();
        const decline = {
            ...data,
            requestStatus: "Rejected",
            reason
        }
        dispatch(updateRequestStatus(decline));
    }

    React.useEffect(() => {
        if (currentRequest?.requestStatus?.requestStatus) {
            setStatus(currentRequest?.requestStatus?.requestStatus);
            setExpanded(false);
        }
    }, [pendingRequests]);

    const { createdOn, requestReason, requestedUser } = data;

    return (
        <div data-testid="guardrailrequest-card">
            <Card className={classes.root}>
                <div className={classes.date} data-testid="datenow">{dateToFromNowDaily(createdOn)}</div>
                <CardContent>
                    <Grid container>
                        <Grid item xs={1}>
                            <Typography>{t('name')}:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>{requestedUser}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1}>
                            <Typography>{t('reason')}:</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.text}>
                                {requestReason}
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        !status &&
                        <div style={{ visibility: expanded ? 'hidden' : 'visible' }}>
                            <Button
                                elevation={2}
                                size="small"
                                variant="contained"
                                className={classes.acceptbutton}
                                onClick={approveRequest}
                                data-testid="approve-button"
                            >
                                {t('accept')}
                            </Button>
                            <Button
                                elevation={2}
                                size="small"
                                variant="outlined"
                                className={classes.declinebutton}
                                onClick={handleExpandClick}
                                data-testid="decline-expand-button"
                            >
                                {t('decline')}
                            </Button>
                        </div>
                    }
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <form onSubmit={declineRequest} className={classes.decline}>
                            <Typography className={classes.title}>
                                {t("please_mention_reason_for_decline")}
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                label={t("enter_reason_here")}
                                multiline
                                rows={4}
                                className={classes.textfield}
                                variant="outlined"
                                required
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                            <br />
                            <Button
                                elevation={2}
                                size="small"
                                variant="outlined"
                                className={classes.button}
                                onClick={handleExpandClick}
                                data-testid="close-button"
                            >
                                {t('close')}
                            </Button>
                            <Button
                                elevation={2}
                                size="small"
                                type='submit'
                                variant="outlined"
                                className={classes.declinebutton}
                                data-testid="decline-button"
                            >
                                {t('decline')}
                            </Button>
                        </form>
                    </Collapse>
                </CardContent>
            </Card>
        </div>
    );
}
