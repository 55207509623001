import React, { createRef } from 'react';
import EMEABreadcrumb from 'components/EMEA/EMEA_Common/EMEABreadcrumb';
import EMEAOpenFollowUp from 'components/EMEA/EmeaOpenFollowUp';
import EmeaTable from 'components/EMEA/EMEA_Common/EmeaTable';
import { emeaOrderCutsAndRejectionsColumns, emeaOrderCutsAndRejectionsFixedColumn } from 'reduxLib/constdata/EMEA/emeaOrderCutsAndRejectionsColumns';
import emeaOrderCutsAndRejectionsFilters from 'reduxLib/constdata/EMEA/emeaOrderCutsAndRejectionsFilters';
import { endpoints } from 'helpers';

const EMEAOrderCutsAndRejections = () => {
    const tableRef = createRef();

    const exclusionArray = ["searchStringList", "requestedDeliveryDateCustom",
        "documentDate", "excludeSoldToNumList", "excludeMaterialNumberList",
        "excludeDocumentTypesList", "excludeOrderReasonCodeList", "excludeItemReasonCodeList"
    ];
    const orderBy = [
        {
            "key":"documentDate",
            "ordering":"asc"
        }
    ];

    const filterBody = {
        ...emeaOrderCutsAndRejectionsFilters,
        itemStatusBucketList: {
            type: "checkbox",
            name: "status",
            data: [
              { name: "rejected", value: "REJECTED", checked: true } // Defaulted true
            ]
          },
    }

    return (
        <>
            <EMEABreadcrumb pageName={'order_cuts_and_rejections'} />
            <EMEAOpenFollowUp />
            <EmeaTable
                fetchEndPoint={endpoints.emea.emeaTable}
                columns={emeaOrderCutsAndRejectionsColumns}
                defaultColumns={emeaOrderCutsAndRejectionsColumns}
                tableName={"cutsAndRejections"}
                subType={"EMEA_OPEN_ORDERS"}
                orderType={"ORDER_ENTRY"}
                orderBy={orderBy}
                tableRef={tableRef}
                filterBody={filterBody}
                filterType={"emeaOrderCutsAndRejectionsFilters"}
                filterExclusionArray={exclusionArray}
                searchText={'search_helper_text_returns_page'}
                fixedColumn={emeaOrderCutsAndRejectionsFixedColumn}
            />
        </>
    )
}

export default EMEAOrderCutsAndRejections;