import React, { createRef, useEffect  } from "react";
import EMEAOpenOrdersKPIs from "components/EMEA/EMEAOpenOrdersKPIs";
import EmeaTable from 'components/EMEA/EMEA_Common/EmeaTable';
import { emeaOpenOrdersColumns, emeaOpenOrdersFixedColumn } from 'reduxLib/constdata/EMEA/emeaOpenOrdersColumns';
import emeaOpenOrdersFilters from 'reduxLib/constdata/EMEA/emeaOpenOrdersFilters';
import { endpoints } from 'helpers';
import EMEAOpenOrdersStatus from "components/EMEA/EMEAOpenOrdersStatus";
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { defaultOrderEntryDate } from "configs/appConstants";

const EMEAOpenOrders = () => {  
  const tableRef = createRef();

  const exclusionArray = ["searchStringList", "requestedDeliveryDateCustom",
    "documentDate", "excludeSoldToNumList", "excludeMaterialNumberList",
    "excludeDocumentTypesList", "excludeOrderReasonCodeList"
  ];
  
  const orderBy = [
    {
      "key": "itemStatusHealth",
      "ordering": "desc"
    },
    {
      "key": "orderNum",
      "ordering": "asc"
    }
  ];

  const setMultiQuery = useMultiSetQuery();
  const [params] = useSetQuery();

  useEffect(() => {
    if (params.loadView) {
      setMultiQuery({
        documentDate: JSON.stringify(
          params.loadView ? defaultOrderEntryDate : params.documentDate
        ),
        loadView: undefined
      });
    }
  }, [params.loadView, params.documentDate]);

  return (
    <>
      <EMEAOpenOrdersStatus pageName={"open_orders"} />
      <EMEAOpenOrdersKPIs />
      <EmeaTable
        fetchEndPoint={endpoints.emea.emeaTable}
        columns={emeaOpenOrdersColumns}
        defaultColumns={emeaOpenOrdersColumns}
        tableName={"openOrders"}
        subType={"EMEA_OPEN_ORDERS"}
        orderType={"ORDER_ENTRY"}
        orderBy={orderBy}
        tableRef={tableRef}
        filterBody={emeaOpenOrdersFilters}
        filterType={"emeaOpenOrdersFilters"}
        filterExclusionArray={exclusionArray}
        searchText={'search_helper_text_returns_page'}
        fixedColumn={emeaOpenOrdersFixedColumn}
      />
    </>
  )
}

export default EMEAOpenOrders;