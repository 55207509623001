import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";
import { shortTitleCellStyle } from 'helpers/tableStyleOverride';

export const OSMGenralControlStackColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNumber",  
      render: rowData => {
        return <CustomCellRender id="shipmentNumber" value={rowData.shipmentNumber} />
      }
    },{
      title: 'source_type', field: "sourceType",  
      render: rowData => {
        return <CustomCellRender id="sourceType" value={rowData.sourceType} />
      }
    },{
      title: 'routine_type', field: "routineType",  
      render: rowData => {
        return <CustomCellRender id="routineType" value={rowData.routineType} />
      }
    },{
      title: 'load_type', field: "loadType",  
      render: rowData => {
        return <CustomCellRender id="loadType" value={rowData.loadType} />
      }
    },{
      title: 'load_instructions', field: "loadingInstruction",  
      render: rowData => {
        return <CustomCellRender id="loadingInstruction" value={rowData.loadingInstruction} /> 
      }
    },{
      title: 'stock_code_summary_id', field: "summaryId",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="summaryId" value={rowData.summaryId} />
      }
    },{
      title: 'printer_id', field: "printerId",  
      render: rowData => {
        return <CustomCellRender id="printerId" value={rowData.printerId} />
      }
    },{
      title: 'num_of_copies_lsr', field: "noofCopy",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="noofCopy" value={rowData.noofCopy} />
      }
    },{
      title: 'package_lsr_report_ind', field: "lSRind",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="lSRind" value={rowData.lSRind} />
      }
    },{
      title: 'mainframe_jobname', field: "jobname",  
      render: rowData => {
        return <CustomCellRender id="jobname" value={rowData.jobname} />
      }
    },{
      title: 'error_code', field: "errorCode",  
      render: rowData => {
        return <CustomCellRender id="errorCode" value={rowData.errorCode} />
      }
    },{
      title: 'error_message', field: "errorMessage",  
      render: rowData => {
        return <CustomCellRender id="errorMessage" value={rowData.errorMessage} />
      }
    },{
      title: 'standard_indicator', field: "standardInd",  
      render: rowData => {
        return <CustomCellRender id="standardInd" value={rowData.standardInd} /> 
      }
    },{
      title: 'volume_unit', field: "volumeUnit",  
      render: rowData => {
        return <CustomCellRender id="volumeUnit" value={rowData.volumeUnit} /> 
      }
    },{
      title: 'beginning_cube', field: "beginingCube",  
      render: rowData => {
        return <CustomCellRender id="beginingCube" value={rowData.beginingCube} /> 
      }
    },{
      title: 'ending_cube', field: "endingCube",  
      render: rowData => {
        return <CustomCellRender id="endingCube" value={rowData.endingCube} /> 
      }
    },{
      title: 'max_cases_add', field: "maxAdd",  
      render: rowData => {
        return <CustomCellRender id="maxAdd" value={rowData.maxAdd} /> 
      }
    },{
      title: 'max_cases_cut', field: "maxCut",  
      render: rowData => {
        return <CustomCellRender id="maxCut" value={rowData.maxCut} /> 
      }
    },{
      title: 'ucl_labelling', field: "ucLLabelInd",  
      render: rowData => {
        return <CustomCellRender id="ucLLabelInd" value={rowData.ucLLabelInd} /> 
      }
    },{
      title: 'simulate_indicator', field: "simulateInd",  
      render: rowData => {
        return <CustomCellRender id="simulateInd" value={rowData.simulateInd} /> 
      }
    },{
      title: 'pack_instruction_ind', field: "packInstructionInd",  
      render: rowData => {
        return <CustomCellRender id="packInstructionInd" value={rowData.packInstructionInd} /> 
      }
    },{
      title: 'actual_pick_qty_ind', field: "quantitiesInd",  
      render: rowData => {
        return <CustomCellRender id="quantitiesInd" value={rowData.quantitiesInd} /> 
      }
    },{
      title: 'weight_unit', field: "weightUnit",  
      render: rowData => {
        return <CustomCellRender id="weightUnit" value={rowData.weightUnit} /> 
      }
    },{
      title: 'net_weight_of_trailer_one', field: "netWeightofTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="netWeightofTrailer1" value={rowData.netWeightofTrailer1} /> 
      }
    },{
      title: 'net_weight_of_trailer_two', field: "netWeightofTrailer2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="netWeightofTrailer2" value={rowData.netWeightofTrailer2} /> 
      }
    },{
      title: 'steer_axle_wt_of_trailer_one', field: "steerAxleWeightofTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="steerAxleWeightofTrailer1" value={rowData.steerAxleWeightofTrailer1} /> 
      }
    },{
      title: 'steer_axle_wt_of_trailer_two', field: "steerAxleWeightofTrailer2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="steerAxleWeightofTrailer2" value={rowData.steerAxleWeightofTrailer2} /> 
      }
    },{
      title: 'drive_axle_wt_of_trailer_one', field: "driveAxleWeightofTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="driveAxleWeightofTrailer1" value={rowData.driveAxleWeightofTrailer1} /> 
      }
    },{
      title: 'drive_axle_wt_of_trailer_two', field: "driveAxleWeightofTrailer2", cellStyle: shortTitleCellStyle, 
      render: rowData => {
        return <CustomCellRender id="driveAxleWeightofTrailer2" value={rowData.driveAxleWeightofTrailer2} /> 
      }
    },{
      title: 'unit_of_dimension_for_lwh', field: "unit",  
      render: rowData => {
        return <CustomCellRender id="unit" value={rowData.unit} /> 
      }
    },{
      title: 'rear_axle_min_distance_setting_trailer_one', field: "rearAxleMinDistanceSettingTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMinDistanceSettingTrailer1" value={rowData.rearAxleMinDistanceSettingTrailer1} /> 
      }
    },{
      title: 'rear_axle_max_distance_setting_trailer_one', field: "rearAxleMaxDistanceSettingTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMaxDistanceSettingTrailer1" value={rowData.rearAxleMaxDistanceSettingTrailer1} /> 
      }
    },{
      title: 'rear_axle_min_weight_setting_trailer_one', field: "rearAxleMinWeightSettingTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMinWeightSettingTrailer1" value={rowData.rearAxleMinWeightSettingTrailer1} /> 
      }
    },{
      title: 'rear_axle_max_weight_setting_trailer_one', field: "rearAxleMaxWeightSettingTrailer1",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMaxWeightSettingTrailer1" value={rowData.rearAxleMaxWeightSettingTrailer1} /> 
      }
    },{
      title: 'rear_axle_min_distance_setting_trailer_two', field: "rearAxleMinDistanceSettingTrailer2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMinDistanceSettingTrailer2" value={rowData.rearAxleMinDistanceSettingTrailer2} /> 
      }
    },{
      title: 'rear_axle_max_distance_setting_trailer_two', field: "rearAxleMaxDistanceSettingTrailer2", cellStyle: shortTitleCellStyle, 
      render: rowData => {
        return <CustomCellRender id="rearAxleMaxDistanceSettingTrailer2" value={rowData.rearAxleMaxDistanceSettingTrailer2} /> 
      }
    },{
      title: 'rear_axle_min_weight_setting_trailer_two', field: "rearAxleMinWeightSettingTrailer2", cellStyle: shortTitleCellStyle, 
      render: rowData => {
        return <CustomCellRender id="rearAxleMinWeightSettingTrailer2" value={rowData.rearAxleMinWeightSettingTrailer2} /> 
      }
    },{
      title: 'rear_axle_max_weight_setting_trailer_two', field: "rearAxleMaxWeightSettingTrailer2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="rearAxleMaxWeightSettingTrailer2" value={rowData.rearAxleMaxWeightSettingTrailer2} /> 
      }
    },{
      title: 'remaining_gap_left', field: "remainingGapLeft",  
      render: rowData => {
        return <CustomCellRender id="remainingGapLeft" value={rowData.remainingGapLeft} /> 
      }
    },{
      title: 'remaining_gap_right', field: "remainingGapRight",  
      render: rowData => {
        return <CustomCellRender id="remainingGapRight" value={rowData.remainingGapRight} /> 
      }
    },
    {
      title: 'scac', field: "scac",  
      render: rowData => {
        return <CustomCellRender id="scac" value={rowData.scac} /> 
      }
    },{
      title: 'container_id', field: "containerID",  
      render: rowData => {
        return <CustomCellRender id="containerID" value={rowData.containerID} /> 
      }
    },{
      title: 'created_on', field: "createdon",  
      render: rowData => {
        return <CustomCellRender id="createdon" value={rowData.createdon} /> 
      }
    // },{
    //   title: 'time_created', field: "timecreated",  
    //   render: rowData => {
    //     return <CustomCellRender id="timecreated" value={rowData.timecreated} /> 
    //   }
    },{
      title: 'changed_on', field: "changedOn",  
      render: rowData => {
        return <CustomCellRender id="changedOn" value={rowData.changedOn} /> 
      }
    // },{
    //   title: 'last_changed_at', field: "lastchangedAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="lastchangedAt" value={rowData.lastchangedAt} /> 
    //   }
    },{
      title: 'plant', field: "plant",  
      render: rowData => {
        return <CustomCellRender id="plant" value={rowData.plant} /> 
      }
    },{
      title: 'storage_location', field: "storageLocation",  
      render: rowData => {
        return <CustomCellRender id="storageLocation" value={rowData.storageLocation} /> 
      }
    },{
      title: 'total_order_qty', field: "orderQuantity",  
      render: rowData => {
        return <CustomCellRender id="orderQuantity" value={rowData.orderQuantity} /> 
      }
    },{
      title: 'total_load_qty', field: "totalLoadQuantity",  
      render: rowData => {
        return <CustomCellRender id="totalLoadQuantity" value={rowData.totalLoadQuantity} /> 
      }
    },{
      title: 'center_of_gravity', field: "centerofGravity",  
      render: rowData => {
        return <CustomCellRender id="centerofGravity" value={rowData.centerofGravity} /> 
      }
    },{
      title: 'new_wms', field: "newWMS",  
      render: rowData => {
        return <CustomCellRender id="newWMS" value={rowData.newWMS} /> 
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}