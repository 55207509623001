import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const ReferenceBar = (props) => {
    const { margins, scales: { xScale, yScale }, data, svgDimensions, xKey, refLine, showRef, currentDay } = props;

    const height = svgDimensions.height - margins.bottom;
    const theme = useTheme();

    const distance = 4;

    const color = {
        red: theme.palette.legendColors.pink,
        blue: theme.palette.chartColors.healthy,
    }
    return (
        <g data-testid='referencebars'>
            {
                data.map((d, i) => {
                    let currentGuardrail = {
                        visible: false, value: 0
                    }
                    if ('currentGuardrailValue' in d) {
                        currentGuardrail = {
                            visible: true,
                            value: d.currentGuardrailValue,
                            color: Math.floor(d.currentGuardrailValue) >= Math.floor(d.totalShipmentCount) ? color.blue : color.red
                        }
                    }
                    if (i === 0 && d.today !== "Y" && !showRef) return null;
                    const x = xScale(d[xKey]);
                    const y = yScale(d[refLine]);
                    const tHeight = height - yScale(d[refLine]);
                    const tWidth = xScale.bandwidth();

                    const currentDayHeight = yScale(currentGuardrail.value);

                    let fill = Math.floor(d[refLine]) > Math.floor(d.total) ? color.blue : color.red;

                    if (Math.floor(d[refLine]) === Math.floor(d.total)) {
                        fill = color.blue;
                    }

                    return (
                        <g>
                            <line
                                stroke={fill}
                                strokeWidth={3}
                                x1={x + tWidth + distance}
                                y1={y}
                                x2={x + tWidth + distance}
                                y2={y + tHeight}
                                d={1}
                            />
                            <line
                                stroke={fill}
                                strokeWidth={3}
                                x1={x}
                                y1={y}
                                x2={x + tWidth + distance + 1}
                                y2={y}
                                d={1}
                            />

                            {
                                (
                                    currentGuardrail.visible && currentDay && 
                                    currentGuardrail.value > 0  && currentGuardrail.value !== Math.floor(d[refLine])
                                ) && 
                                <>
                                    <line
                                        stroke={currentGuardrail.color}
                                        strokeWidth={2}
                                        x1={x}
                                        y1={currentDayHeight}
                                        x2={x + tWidth + distance + 1}
                                        y2={currentDayHeight}
                                        d={1}
                                        strokeDasharray={("3, 3")}
                                    />
                                    <text
                                        alignmentBaseline="middle"
                                        dx={'-0.1em'}
                                        fontSize={11}
                                        fill={currentGuardrail.color}
                                        x={x + tWidth + 10}
                                        y={currentDayHeight + 3}
                                        data-value={Math.floor(currentGuardrail.value)}
                                    >
                                        {Math.floor(currentGuardrail.value)}
                                    </text>
                                </>
                            }
                            <text
                        alignmentBaseline="middle"
                        dx={'-0.1em'}
                        fontSize={15}
                        fill={fill}
                        x={x + tWidth + 10}
                        y={y - 5}
                        data-value={Math.floor(d[refLine])}
                    >
                        {Math.floor(d[refLine])}
                    </text>
                        </g>
    )
})
            }
        </g >
    )
}

export default ReferenceBar;