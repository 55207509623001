import React from 'react'
import {
    Typography, makeStyles, TextField, Box, Modal,
    Button, Grid
} from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux'
import { 
    precheckService, reprintLSRService, resetPrecheckStatus, resetReprintStatus, 
    printUCLService, printADDRService
 } from 'reduxLib/services/reprintService';
import Card from 'components/common/Card';
import KCRadio from '../common/Elements/KCRadio';
import Snack from 'components/common/Helpers/Snack';
import { osmPrintTypes, osmPrintModes } from "configs/appConstants";
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
    root: {
        width: "70%",
        background: theme.palette.primary.base,
        margin: `${theme.spacing(16)}px auto`,
        position: 'relative',
        overflow: 'auto',
        "&>div:nth-child(3)": {
            marginTop: theme.spacing(2),
            borderRadius: theme.spacing(.5),
            width: "100%",
        }
    },
    progress: {
        backgroundColor: theme.palette?.secondary?.accent
    },
    form: {
        padding: theme.spacing(2.5),
    },
    modalBody: {
        width: '90%',
        overflow: 'auto'
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1.5),
        cursor: "pointer",
        zIndex: 10
    },
    text: {
        color: theme.palette?.primary.contrastText,
    },
    textarea: {
        width: "100%",
        marginTop: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(2),
        textAlign: "center",
        borderColor: theme.palette?.primary.contrastText
    },
    container: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    },
    textfield: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline:hover': {
          width: 200,
          padding: theme.spacing(1),
          borderRadius: theme.spacing(0.5),  
          borderColor: `${theme.palette?.primary.contrastText} !important`,
        },
        '& .MuiInputBase-input': {
          boxSizing: 'border-box'
        }
    },
    numfield: {
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-notchedOutline:hover': {
            borderColor: `${theme.palette?.primary.contrastText} !important`,
        }
    },
    optionContainer: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px 0px`
    }
}))

export default function ReprintModal({ selectedRow, openType, setOpenType, ...props }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const { status, loading, precheck, precheckLoading, uclLoading, labelPrinted, addrLoading } = useSelector(({ osmReprint }) => osmReprint)

    const [error, setError] = React.useState(null);
    const [labelOptionsError, setLabelOptionsError] = React.useState(null);
    const [open, setOpen] = React.useState(() => props.open);
    const [labelOptions, setLabelOptions] = React.useState(null);
    const [precheckError, setPrecheckError] = React.useState(null);
    const [values, setValues] = React.useState({
        shipmentNum: null,
        printerName: null,
        noOfCopies: 1,
        originRegion: "NA"
    });

    const [snack, setSnack] = React.useState({
        open: false,
        severity: "info",
        message: null
    });

    React.useEffect(() => {
        if (props.open && precheck.status === "success") {
            setValues({
                ...values,
                printerName: precheck.defaultPrinter || ""
            })
            setPrecheckError(precheck?.addressLabelRequired === "N" ? t("addr_label_not_required_for_this_shipment"): null)
            setOpen(true);
            dispatch(resetPrecheckStatus());
        } else if(props.open && precheck.status === "failed") {
            setSnack({
                ...snack,
                open: true,
                severity: "error",
                message: t(precheck.message)
            });
            dispatch(resetPrecheckStatus());
            props.setOpen(false);
            setOpenType(null);
        }
    }, [props.open, precheck.status])


    React.useEffect(() => {
        if (status.status) {
            setSnack({
                open: true,
                severity: status.status === "success" ? "success" : "error",
                message: t(status.message)
            });
            if (status.status === "success") {
                closeModal();
            }
            dispatch(resetReprintStatus());
        }
    }, [status])

    React.useEffect(() => {
        if (openType === "ADDR") {
            if(!values?.noOfCopies || values?.noOfCopies < 1 ||  values?.noOfCopies > 50) {
                setError(t("more_addr_error"));
             } else setError(null)
        } else {
            if(!values?.noOfCopies || values?.noOfCopies < 1 ||  values?.noOfCopies > 2) {
                setError(t("more_lsr_Error"));
            } else setError(null)
        }
    }, [values?.noOfCopies])


    React.useEffect(() => {
        setValues({
            ...values,
            shipmentNum: selectedRow?.shipmentNum
        })
        if (selectedRow?.shipmentNum && props.open) {
            dispatch(precheckService({
                shipmentNum: selectedRow?.shipmentNum,
                originRegion: "NA",
                osmSubType: openType === 'LSR' ? osmPrintTypes.LSR : openType === 'UCL' ? osmPrintTypes.UCL : osmPrintTypes.ADDR,
                originSiteNum: selectedRow?.originSiteNum
            }));
        }
    }, [selectedRow?.shipmentNum, props.open])

    const { t } = useTranslation();

    const print = (e) => {
        e.preventDefault();
        if (openType === 'LSR') {
            dispatch(reprintLSRService(values))
        } else if (openType === 'UCL') {
            if (isEmpty(labelOptions))
                setLabelOptionsError("label_options_error");
            else dispatch(printUCLService({...values, printMode: labelOptions, originSiteNum: selectedRow?.originSiteNum}));
        } else if (openType === "ADDR") {
            dispatch(printADDRService({...values, originSiteNum: selectedRow?.originSiteNum}));
        }
    }

    const handleSnackClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    const closeModal = () => {
        setOpen(false);
        setError(null);
        setLabelOptions(null);
        setLabelOptionsError(null);
        props.setOpen(false);
        setOpenType(null);
        setValues({
            shipmentNum: null,
            printerName: null,
            noOfCopies: 1,
            originRegion: "NA"
        });
    }

    const handleLabelOptionsChange = (event) => {
        setLabelOptions(event.target.value);
        setLabelOptionsError(null);
    }

    const textStrings = {
        LSR: {
            label: t("reprint_lsr"),
            placeholder: t("LSR_copy"),
            printedError:  null
        },
        UCL: {
            label: t("print_ucl_label"),
            placeholder: t('ucl_copy'),
            printedError:  t("ucl_label_already_printed")
        },
        ADDR: {
            label: t("print_addr_label"),
            placeholder: t('addr_copy'),
            printedError:  t("addr_label_already_printed")
        }
    }


    return (
        <>
            <Snack {...snack} handleClose={handleSnackClose} />
            <Modal
                open={open}
                onClose={closeModal}
                data-testid="reprint-modal"
            >
                <div className={classes.root} >
                {(loading || uclLoading || addrLoading) && <LinearProgress className={classes.progress} /> }
                    <span title='closeicon_modal' data-testid='closeicon_modal' className={classes.closeicon} onClick={closeModal}><CloseIcon /></span>
                    <form onSubmit={print} className={classes.form}>
                        <Typography component='div' variant='h3' data-testid="reprint-title" className={classes.text}>
                            { textStrings?.[openType]?.label }
                        </Typography>
                        <br />
                        <Card
                            elevation={0}
                            cardtitle={
                                <Typography component='div' variant='h4' className={classes.text}> {t("printing_options")} * </Typography>
                            }
                        >
                            {
                                precheckError && <>
                                    <Alert severity="info" color="warning">
                                        { precheckError }
                                    </Alert>
                                    <br />
                                </>
                            }
                            {
                                labelPrinted && textStrings[openType]?.printedError && <>
                                    <Alert severity="warning" color="error">
                                        { textStrings[openType]?.printedError }
                                    </Alert>
                                    <br />
                                </>
                            }
                            <Grid container spacing={2} alignItems="center" className={classes.container}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={5} className={classes.text}>
                                            <Typography variant='body1'> {t("shipment_num_freight_order")} </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                value={values.shipmentNum}
                                                disabled
                                                id="input-shipment-text"
                                                variant="outlined" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={5}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={5} className={classes.text}>
                                        <Typography variant='body1'> {t("report_destination")} </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                placeholder={t('enter_printer_name')}
                                                className={classes.textfield}
                                                value={values.printerName}
                                                required
                                                onChange={(e) => setValues({
                                                    ...values,
                                                    printerName: e.target.value
                                                })}
                                                variant="outlined"
                                                inputProps={{ "data-testid": `input-report-text` }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={5} className={classes.text}>
                                            <Typography variant='body1'>{textStrings[openType]?.placeholder}</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField
                                                value={values.noOfCopies}
                                                type="number"
                                                required
                                                onChange={(e) => setValues({
                                                    ...values,
                                                    noOfCopies: e.target.value ? parseInt(e.target.value): ""
                                                })}
                                                inputProps={{ 
                                                    "data-testid": `input-noOfCopies`,
                                                    min: 1,
                                                    max: 50
                                                }}
                                                variant="outlined"
                                                className={openType === 'LSR' ? classes.numfield : null}
                                                disabled={
                                                    openType === 'UCL'
                                                } />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                openType === 'UCL' &&  <Grid container className={classes.optionContainer}>
                                    <Grid item xs={12}>
                                        <Typography variant='h4' className={classes.text}> {t('print_label_options')} </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <RadioGroup row aria-label="label-options" name="label-options" value={labelOptions} onChange={handleLabelOptionsChange} data-testid="label-options">
                                                <FormControlLabel value={osmPrintModes.stack} control={<KCRadio />} label={t('per_stack_mods')} className={classes.text}/>
                                                <FormControlLabel value={osmPrintModes.case} control={<KCRadio />} label={t('one_label_per_case')} className={classes.text}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            }
                            {
                                error && <>
                                    <br />
                                    <Alert severity="warning" color="error">
                                        {t(error)}
                                    </Alert>
                                </>
                            }
                            {
                                labelOptionsError && <>
                                    <br />
                                    <Alert severity="warning" color="error" data-testid="label-options-error">
                                        {t(labelOptionsError)}
                                    </Alert>
                                </>
                            }
                        </Card>
                        <Box textAlign='center'>
                            <Button
                                className={classes.button}
                                disabled={precheckLoading || !!error || loading || uclLoading || addrLoading}
                                type="submit"
                                variant="outlined"
                                data-testid="submit-button"
                                onSubmit={print} >
                                { textStrings[openType]?.label }
                            </Button>
                        </Box>
                    </form>
                </div>
            </Modal>
        </>
    )
}