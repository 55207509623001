import { feedbackConstants } from '../constants';

const initialState = {
    feedback_submit_loading: false,
    feedback_status: null
}

const feedbackReducer = (state = initialState, action) => {

    
    switch (action.type) {

        case feedbackConstants.SUBMIT_FEEDBACK_LOADING:
            return { ...state, feedback_submit_loading: action.payload }

        case feedbackConstants.SUBMIT_FEEDBACK_STATUS:
            return { ...state, feedback_status: action.payload }

            case feedbackConstants.RESET_SUBMIT_FEEDBACK_STATUS:
                return { ...state, feedback_status: null }

        default:
            return state
    }
}

export default feedbackReducer;
