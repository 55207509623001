import emeaViewsConstants from "reduxLib/constants/EMEA/emeaViewsConstants";
import { updateViews } from "../osmReducer";

const initialState = {
    tableName: null,
    loading: true,
    viewLoading: true,
    views: null,
    status: {},
    activeView: {}
}

const emeaViewsReducer = (state = initialState, action) => {
    switch (action.type) {

        case emeaViewsConstants.VIEWS_LOADING:
            return {
                ...state,
                loading: action.payload,
                viewLoading: action.payload,
                tableName: action.tableName,
            };
        case emeaViewsConstants.VIEW_LOADING:
            return {
                ...state,
                viewLoading: action.payload,
                tableName: action.tableName,
            };
        case emeaViewsConstants.GET_ALL_VIEWS_SUCCESS:
        case emeaViewsConstants.GET_ALL_VIEWS_FAILED:
            return {
                ...state,
                loading: false,
                tableName: action.tableName,
                status: action.status,
                viewLoading: false,
                ...action.payload
            };

        case emeaViewsConstants.GET_VIEW_SUCCESS:
        case emeaViewsConstants.GET_VIEW_FAILED:
            return {
                ...state,
                loading: false,
                status: action.status,
                viewLoading: false,
                tableName: action.tableName,
                activeView: action.payload
            };
        case emeaViewsConstants.INSERT_UPDATE_VIEW_SUCCESS:
        case emeaViewsConstants.INSERT_UPDATE_VIEW_FAILED:
            return {
                ...state,
                viewLoading: false,
                status: action.status,
                views: action.status.status === "success" ?
                    updateViews(action.payload, state.views || []) : state.views,
                activeView: action.status.status === "success" ?
                    action.payload : state.activeView
            };

        case emeaViewsConstants.FAV_UNFAV_VIEW_SUCCESS:
        case emeaViewsConstants.FAV_UNFAV_VIEW_FAILED:
            return {
                ...state,
                viewLoading: false,
                status: action.status,
                views: state.views.map(d => {
                    return {
                        ...d,
                        isFav: d.id === action.payload ? action.isFav : false
                    }
                })
            };
        case emeaViewsConstants.DELETE_VIEW_SUCCESS:
        case emeaViewsConstants.DELETE_VIEW_FAILED:
            return {
                ...state,
                viewLoading: false,
                status: action.status,
                views: state.views.filter(d => d.id !== action.payload),
                activeView: state.activeView.id === action.payload ? {} : state.activeView
            };
        case emeaViewsConstants.RESET_VIEWS:
            return {
                ...state,
                tableName: null,
                viewLoading: false,
                views: null,
                activeView: {},
            };
        case emeaViewsConstants.RESET_EMEA_STATUS:
            return {
                ...state,
                viewLoading: true,
                views: null,
                loading: true,
                activeView: {},
                status: {}
            };

        case emeaViewsConstants.RESET_STATUS:
            return {
                ...state,
                status: {}
            };

        default:
            return state;
    }
}

export default emeaViewsReducer;  