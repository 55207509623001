import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from "react-redux";
import { ldLogsDownloadService, loadLdLogsDownloadService } from 'reduxLib/services/osmLsrStackDetailsService';
import fetch from "reduxLib/services/serviceHelpers";
import { endpoints } from "../../configs/endpoints"
import DownloadIcon from "../../assets/images/json_download.svg";
const fileDownload = require('js-file-download');

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: `underline`,
    display: 'inline-flex'
  }
}));

const OsmLDLogsDownload = ({ id, value, type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const downloadFile = () => {
    const body = {
      shipmentNum: value?.shipmentNum,
      submitDateTime: value?.submittedDateTime,
      submittedBy: value?.submittedBy,
      payloadType: type
    }
    const data = new Promise((resolve, reject) => {
      let endpoint = endpoints.osm.ldLogsDownload;
      dispatch(loadLdLogsDownloadService({ loading: true }));
      fetch(endpoint, {
        method: "post",
        body: body
      })
      .then( async (res) => {
        return await res.blob();
      })                            
      .then(blob => {
        const fileType = type === "response" ? "Outbound" : "Inbound";
        const date = Date.now();
        const fileName = `${value?.shipmentNum}_${fileType}_${date}.json`;
        fileDownload(blob, fileName);
        dispatch(ldLogsDownloadService({ type: "success" }));
        dispatch(loadLdLogsDownloadService({ loading: false }));
      })
      .catch(e => {
        dispatch(ldLogsDownloadService({ type: "failed" }));
        dispatch(loadLdLogsDownloadService({ loading: false }));
        resolve({
          data: []
        })
      })
    })
  }

  return (
    <div
      className={classes.link}
      data-testid={id} 
      onClick={downloadFile} >
        { ".JSON" } { <img src={DownloadIcon} alt="download" /> }
    </div>

  )
}

export default OsmLDLogsDownload;