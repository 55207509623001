import React from 'react';
import { generatePath, Link as RouterLink, useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Box, Avatar,
  Badge, Typography, makeStyles, Button,
  IconButton
} from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { withOktaAuth } from '@okta/okta-react';
import clsx from 'clsx';
import { get } from "lodash";
import { connect } from "react-redux";
import maestro_logo_dark from '../../assets/images/maestro_logo_dark.svg';
// import new_logo_dark from '../../assets/images/new_logo_dark.svg';
import maestro_logo_light from '../../assets/images/maestro_logo_light.svg';
import LinearLoader from "../../components/common/LinearLoader";
import { addUser } from "../../reduxLib/services";
import AppDrawer from "../../components/common/AppDrawer";
import FeedBack from "../../components/common/FeedBack";
import TranslationDropDown from "../../components/translation/TranslationDropDown";
import { useTranslation } from 'react-i18next';
import MoreIcon from '@material-ui/icons/MoreVert';
import MobileMenuProfile from './MobileMenuProfile';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import { useRouteContext } from 'context/RouteContext';
import { appbarStyles } from "theme";

const useStyles = makeStyles(appbarStyles);

const useStyles2 = makeStyles(theme => ({
  logo: {
    height: '62px',
    width: '326px',
    // margin: `${theme.spacing(1)}px 0px`,
    background: `url(${theme.type === "dark" ? maestro_logo_dark : maestro_logo_light}) no-repeat`,
    backgroundSize: '95%',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '220px',
    }
  },
}));

const Topbar = props => {
  const {
    loading, favsLoading,
    user, addUser, authService,
    stopfavload, buloading,
    setDetails, pageDetails,
    showHamburgerMenu,
    guardrailsRequests, isAdmin,
    stoploaders
  } = props;
  const classes = useStyles();
  const classes2 = useStyles2();
  const { t } = useTranslation();
  const username = user ? `${user.given_name} ${user.family_name}` : "   ";
  const anchorRef = React.useRef(null);
  const region = 'NA';

  global.authService = authService;

  React.useEffect(() => {
    if (!user) {
      authService.getUser().then(d => addUser(d)).catch(err => addUser(null));
    } else {
      window.FS.identify(user.sub, {
        displayName: username
      });
    }
  }, [addUser, user, authService]);

  const [openFeedback, setOpenFeedback] = React.useState(false);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);

  const match = useRouteMatch();
  const { mainPath } = useRouteContext();

  const alertspath = React.useMemo(() => {
    const { page, showtabsy, type, tabletype } = match.params;
    return `${generatePath(mainPath, {
      ...match.params,
      page: page || "home",
      type: type || "network",
      showtabsy: showtabsy|| "locations",
      })}/alerts`
  }, [match.params]);

  return (
    <div className={classes.root}>
      <AppBar elevation={0} className={classes.app}>
        <Toolbar className={classes.toolbar}>
          {
            showHamburgerMenu &&
            <AppDrawer setDetails={setDetails} pageDetails={pageDetails} />
          }
          <Typography
            className={classes.title}
            variant="h3"
          >
            <Box data-testid="KCLogo" className={clsx(classes2.logo)} />
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {isAdmin && <Box className={classes.center2}>
              <RouterLink to="/requests" >
                <IconButton
                  aria-label="user requests"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <Badge badgeContent={guardrailsRequests} color="secondary">
                    <PersonAddIcon fontSize="medium" color="primary" />
                  </Badge>
                </IconButton>
              </RouterLink>
            </Box>
            }
            <Box className={classes.center} >
              <TranslationDropDown region={region} />
            </Box>
            {
              <Box className={classes.center2}>
                <Button
                  component={RouterLink} className={classes.button}
                  to={alertspath}
                  startIcon={<ReportOutlinedIcon />}>
                  Alerts
                </Button>
              </Box>
            }
            <Box className={classes.center}>
              <Badge color="secondary" >
                <Avatar className={classes.avatar}>
                  <Typography variant="h1" className={classes.avatarText} color="primary">{
                    `${username.split(" ")[0].charAt(0)}${username.split(" ")[1].charAt(0)}`
                  }</Typography>
                </Avatar>
              </Badge>
              <Typography variant="h6" className={classes.username} color="primary" data-testid="username">{username}</Typography>
            </Box>
            <Box className={classes.center}>
              <Button onClick={() => setOpenFeedback(!openFeedback)} >
                <FeedbackIcon fontSize="medium" color='primary' />
                <Typography variant="body1" className={classes.username} color="primary" data-testid="userFeedback">{t("feedback")}</Typography>
              </Button>
            </Box>
          </div>
          <div className={classes.sectionMobile}>
            <Box >
              <Badge color="secondary" >
                <Avatar className={classes.avatar}>
                  <Typography variant="h1" className={classes.avatarText} color="primary">{
                    `${username.split(" ")[0].charAt(0)}${username.split(" ")[1].charAt(0)}`
                  }</Typography>
                </Avatar>
              </Badge>
            </Box>
            <IconButton
              onClick={() => setOpenMobileMenu(!openMobileMenu)}
              aria-label="show more"
              aria-haspopup="true"
              color="primary"
              ref={anchorRef}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <MobileMenuProfile
        open={openMobileMenu}
        setOpen={setOpenMobileMenu}
        openFeedback={openFeedback}
        setOpenFeedback={() => setOpenFeedback(!openFeedback)}
        username={username}
        anchorRef={anchorRef}
        region={region}
      />
      {!stoploaders && showHamburgerMenu && (loading || favsLoading || stopfavload || buloading) && <LinearLoader />}
      <FeedBack openFeedback={openFeedback} openAction={setOpenFeedback} />
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  authState: PropTypes.any,
  authService: PropTypes.any
};


const mapStateToProps = (state) => {
  return {
    loading: state.sites.loading,
    favsLoading: state.favorites.mark_loading,
    showfavs: state.options.showfavs,
    user: state.auth.user,
    stopfavload: state.items.stopfavload,
    buloading: state.favorites.add_bu_loading,
    guardrailsRequests: state?.guardrails?.pending_requests?.length || 0,
    isAdmin: get(state, ["guardrails", "isAdmin"], false),
  }
}

const mapDispatchToProps = {
  addUser
}

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Topbar));