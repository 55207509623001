import React from 'react';
import moment from "moment-timezone";
import { shortTitleCellStyle, longTitleCellStyle } from 'helpers/tableStyleOverride';
import { formatToSentenceCase, msToHMS } from "helpers";
import { get, isEmpty, isArray } from "lodash";
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";
import CustomCellRender from "../../components/common/CustomCellRender";
import CustomRender from "../../components/common/CustomRender";
import FourKitesLink from 'components/common/Elements/FourKitesLink';

export const defaultInboundColumns = {
	columnOrder: [
		{
			title: 'shipment_#', field: "shipmentNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
			}
		},
		{
			title: 'delivery_#', field: "deliveryNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
			}
		},
		{
			title: 'order_#', field: "orderNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <PromotionalOrder data={rowData} />
			}
		},
		{
			title: 'promotionalOrder', field: "promotionalOrder", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="promotionalOrder" value={rowData.promotionalOrder} />
			}
		},
		{
			title: 'collectiveNumber', field: "collectiveNum", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
			}
		},
		{
			title: 'origin_id', field: "originId",
			render: rowData => {
				return <CustomCellRender id="originId" value={rowData.originId} />
			}
		},
		{
			title: 'origin', field: "origin",
			render: rowData => {
				return <CustomCellRender id="origin" value={rowData.origin} />
			}
		},
		{
			title: 'Dest', field: "customer", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="customer" value={rowData.customer} />
			}
		},
		{
			title: 'date_and_time_arrived_in_yard', field: 'yardArrivalDateTime', cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="yardArrivalDateTime" value={rowData.yardArrivalDateTime} />
			}
		},
		{
			title: 'duration_in_yard', field: "durationInYardMS",
			render: rowData => {
				return <CustomCellRender id="durationInYardMS" value={rowData.durationInYardMS} />
			}
		},
		{
			title: 'trailer_#', field: "trailerNum",
			render: rowData => {
				return <CustomCellRender id="trailerNum" value={rowData.trailerNum} />
			}
		},
		{
			title: 'shipment_status', field: "inboundOrderExecutionBucketDesc",
			render: rowData => {
				return <CustomCellRender id="inboundOrderExecutionBucketDesc" value={rowData.inboundOrderExecutionBucketDesc} />
			}
		},
		{
			title: 'ship_condition', field: "shippingCondition",
			render: rowData => {
				return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
			}
		},
		{
			title: 'dest_city', field: "destinationCity",
			render: rowData => {
				return <CustomCellRender id="destinationCity" value={rowData.destinationCity} />
			}
		},
		{
			title: 'dest_state', field: "destinationState",
			render: rowData => {
				return <CustomCellRender id="destinationState" value={rowData.destinationState} />
			}
		},
		{
			title: 'business', field: "orderBusinessUnit", cellStyle: longTitleCellStyle,
			render: rowData => {
				const codeString = isArray(rowData?.orderBusinessUnit) ? rowData?.orderBusinessUnit?.map((bu) => <div>{bu}</div>) : rowData?.orderBusinessUnit;
				return <div data-testid="orderBusinessUnit">{!isEmpty(codeString) ? codeString : "-"}</div>;
			}
		},
		{
			title: 'reason_for_alert', field: "reasonCodeString",
			render: rowData => {
				const codeString = rowData?.inboundOrderExecutionReasonCodes?.map((code) => <div><CustomRender value={orderHealthReasonCodeMap[code]} /></div>);
				return <div data-testid="reasonCodeString">{!isEmpty(codeString) ? codeString : "-"}</div>;
			}
		},
		// { title: 'destination_eta', field: "expectedDeliveryDateTime", cellStyle: shortTitleCellStyle, disableClick: true }
		{
			title: 'destination_eta',
			field: "expectedDeliveryDateTime",
			cellStyle: shortTitleCellStyle,
			disableClick: true,
			render: rowData => <FourKitesLink data={rowData} />
		},
	],
	columnConfiguration: (d) => {
		return {
			origin: d?.orderOrigin?.name ? get(d, "orderOrigin.name", "-") : "-",
			originId: get(d, "orderOrigin.id", "-"),
			// liveLoadInd: d.liveLoadInd ? d.liveLoadInd === "Y" ? "yes" : "no" : "-",
			// appointmentRequired: d.appointmentRequired ? d.appointmentRequired === "Y" ? "yes" : "no" : "-",
			// orderOnHoldInd: d.orderOnHoldInd ? d.orderOnHoldInd === "Y" ? "yes" : "no" : "-",
			deliverApptDateTime: d?.deliverApptDateTime ? moment(d.deliverApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			expectedDeliveryDateTime: (d?.expectedDeliveryDateTime && d?.destinationTimeZone) ? moment(d.expectedDeliveryDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			loadReadyDateTime: d?.loadReadyDateTime ? moment(d?.loadReadyDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			yardArrivalDateTime: d?.yardArrivalDateTime ? moment(d?.yardArrivalDateTime)?.tz(d?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
			customer: formatToSentenceCase(get(d, "orderDestination.name", "-")),
			destinationCity: formatToSentenceCase(get(d, "orderDestination.city", "-")),
			destinationState: get(d, "orderDestination.state", "-"),
			durationInYardMS: msToHMS(parseInt(get(d, "durationInYardMS", 0))),
			shippingCondition: get(d, "shippingCondition", "-"),
			reasonCodeString: d?.inboundOrderExecutionReasonCodes?.reduce((codeString, code, index) => index === (d.inboundOrderExecutionReasonCodes.length - 1) ? `${codeString} ${orderHealthReasonCodeMap[code]}` : `${codeString} ${orderHealthReasonCodeMap[code]} |`, "") || "-"

		}
	}

}

