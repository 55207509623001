import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  legend: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    marginBottom: theme.spacing(1)
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  dottedLine: {
    width: 20,
    borderBottom: `2px dotted`,
    marginRight: theme.spacing(1),
  },
  square: {
    width: 20,
    height: 10,
    marginRight: theme.spacing(1),
  },
}));

const LegendGroup = ({ items=[] }) => {
  const classes = useStyles();

  return (
    <Box className={classes.legend} data-testid="legend-group">
      {items.map((item, index) => (
        <Box className={classes.legendItem} key={index}>
          <Box
            className={classes[item.type]}
            style={{
              backgroundColor: item.color,
              borderColor: item.color,
            }}
          ></Box>
          <Typography variant="body2">{item.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default LegendGroup;
