import React from 'react'
import { useTranslation } from "react-i18next"
import { stack, sum } from 'd3'
import { makeStyles, Grid } from '@material-ui/core';
import { tooltipLinkArgumentsMap } from '../../reduxLib/constdata/filters'
import D3Tooltip from "./D3Tooltip";
import clsx from "clsx";
import { formatLocaleNumber } from 'helpers/emeaHelpers';
import { getTextColorByBG } from 'helpers';

const useStyles = makeStyles((theme) => ({
  text: {
    pointerEvents: 'none'
  },
  legend: {
    height: theme.spacing(1),
    width: theme.spacing(1)
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    letterSpacing: '1px',
    padding: theme.spacing(.4),
    '&:hover': {
      color: theme.palette.link.hover
    }
  },
  container: {
    display: "block"
  },
  tooltipItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
  tooltipColor: {
    width: 12,
    height: 12,
    borderRadius: 2,
    marginRight: theme.spacing(1),
  },
  dottedLine: {
    width: 12,
    borderBottom: `2px dotted`,
    marginRight: theme.spacing(1),
  },
  tooltipLabel: {
    lineHeight: 1.2,
  },
}));

export const ParentTooltipContent = ({ segmentData, labelGroups = [], formattingOptions = [], parentProps = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    suffix, type, subtype, setDetails,
    colorBy, colors, xKey,
    tooltipOptions: { showTotal = true } = {},
    ...rest
  } = parentProps;

  const keyColorLabelMap = {
    redCount: { status: 'unhealthy', name: 'RED', cssClass: classes.red },
    yellowCount: { status: 'needs_attention', name: 'YELLOW', cssClass: classes.yellow },
    blueCount: { status: 'healthy', name: 'GREEN', cssClass: classes.blue },
  }

  const getColor = (key, data) => {
    if (colorBy === "xKey") {
      return colors(data[xKey])
    }
    return colors(key)
  }

  return (
    <Grid container className={classes.container} data-testid="tooltip-container">
      {showTotal && `${t('total')} - ${formatLocaleNumber(segmentData.total, ...formattingOptions)}${suffix || ""}`}
      {
        labelGroups.map((item) => (
          <div key={item.key} className={classes.tooltipItem}>
            <span
              className={clsx(classes[item.type], classes.tooltipColor)}
              style={{ backgroundColor: item.color }}
            />
            <span className={classes.tooltipLabel}>{item.label} - {formatLocaleNumber(segmentData[item.key], ...formattingOptions)}{suffix}</span>
          </div>
        ))
      }
      {segmentData['redCount'] > 0 &&
        <Grid
          className={clsx(classes.link)} container xs={12} s={12} item wrap='wrap' justify='space-between' alignItems='center'
          data-testid="red-click"
          onClick={() => {
            if (typeof (setDetails) === "function") {
              setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap[segmentData.state], health: keyColorLabelMap.redCount.name, state: subtype }, true)
            };
          }}
        >
          <Grid item className={classes.legend} style={{ backgroundColor: getColor('redCount', segmentData) }}></Grid>
          <Grid item xs={11} title="clicktextred">{segmentData['redCount']} - {t(keyColorLabelMap.redCount.status)}</Grid>
        </Grid>
      }
      {segmentData['yellowCount'] > 0 &&
        <Grid
          className={clsx(classes.link)} item wrap='wrap' justify='space-between' alignItems='center' container xs={12} s={12}
          data-testid="yellow-click"
          onClick={() => {
            if (typeof (setDetails) === "function") {
              setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap[segmentData.state], health: keyColorLabelMap.yellowCount.name, state: subtype }, true)
            };
          }}
        >
          <Grid item className={classes.legend} style={{ backgroundColor: getColor('yellowCount', segmentData) }}></Grid>
          <Grid item xs={11} title="clicktextyellow" >{segmentData['yellowCount']} - {t(keyColorLabelMap.yellowCount.status)}</Grid>
        </Grid>
      }
      {segmentData['blueCount'] > 0 &&
        <Grid
          className={clsx(classes.link)} item wrap='wrap' justify='space-between' alignItems='center' container xs={12} s={12}
          data-testid="blue-click"
          onClick={() => {
            if (typeof (setDetails) === "function") {
              setDetails(type, subtype, true, { args: tooltipLinkArgumentsMap[segmentData.state], health: keyColorLabelMap.blueCount.name, state: subtype }, true)
            };
          }}
        >
          <Grid item className={classes.legend} style={{ backgroundColor: getColor('blueCount', segmentData) }}></Grid>
          <Grid item xs={11} title="clicktextblue">{segmentData['blueCount']} - {t(keyColorLabelMap.blueCount.status)}</Grid>
        </Grid>
      }
      {
       segmentData['rejectedPer'] && <Grid
          item wrap='wrap' justify='space-between' alignItems='center' container xs={12} s={12}
          data-testid="reason-click"
        >
          <Grid item xs={11} >{`${t('reason')} - ${segmentData.stateDesc}`}</Grid>
          <Grid item xs={11} >{`${t('total')} - ${segmentData.totalCount} (${rest.currency})`}</Grid>
          <Grid item xs={11} >{`${t('percentage')} - ${segmentData.rejectedPer}%`}</Grid>
        </Grid>
      }
    </Grid>
  )
}



const Bars = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setDetails,
    scales, data,
    xAccessor, colors,
    keys, horizontal,
    xKey, type,
    subtype, id,
    isGuardRail,
    setDetailsData,
    showBarLabel = true,
    themeType = "dark",
    formatRequired = false,
    colorBy, suffix,
    decimals = null, 
    tooltipExclusion = [], ...rest
  } = props
  const { xScale, yScale } = scales
  const stacked = stack().keys(keys);
  const stackedData = stacked(data);

  const formattingOptions = [formatRequired, decimals];

  const tooltipKey = rest.tooltipKey || xKey;

  const keyColorLabelMap = {
    redCount: { status: 'unhealthy', name: 'RED', cssClass: classes.red },
    yellowCount: { status: 'needs_attention', name: 'YELLOW', cssClass: classes.yellow },
    blueCount: { status: 'healthy', name: 'GREEN', cssClass: classes.blue },
  }

  const getColor = (key, data, test) => {
    if (colorBy === "xKey") {
      return colors(data[xKey])
    }
    return colors(key)
  }

  const renderItem = (item, key, index, i, type_) => {

    let x = null;
    let y = null;
    let width = null;
    let height = null;

    if (horizontal) {
      x = xScale(item[0])
      y = yScale(xAccessor(item.data));
      height = yScale.bandwidth();
      width = xScale(item[1]) - xScale(item[0]);
    } else {
      x = xScale(xAccessor(item.data))
      y = yScale(item[1]);
      height = yScale(item[0]) - yScale(item[1]);
      width = xScale.bandwidth();
    }

    const value = item[1] - item[0];
    const isLink = typeof (setDetails) === "function" && !tooltipExclusion.includes(item.data.state);
    const barColor = getColor(key, item?.data);

    return (
      <g key={i} data-testid={`g-wrapper-${i}`}>
        {
          height && width ?
            <D3Tooltip
              placement={horizontal ? "top" : "right"}
              interactive color={getColor(key, item.data)}
              themeType={themeType}
              title={
                <div data-testid="tooltip-test-id" className={clsx(isLink ? classes.link : {})} onClick={() => {
                  if (isLink) {
                    if (isGuardRail || setDetailsData) { // GuardRails, EMEA, LAO 
                      setDetails(item.data);
                    } else { // NA region
                      setDetails(type_, subtype, true, { args: tooltipLinkArgumentsMap[item.data.state], health: keyColorLabelMap?.[key]?.name, state: subtype }, true)
                    }
                  };
                }}>
                  {item.data[tooltipKey]} : {formatLocaleNumber(value, ...formattingOptions)}{suffix}
                </div>
              }
            >
              <rect
                x={horizontal ? x + 1 : x}
                data-testid={`rect-side-${i}-${key}`}
                y={y}
                key={i}
                height={height}
                width={width}
                fill={barColor}
                item={item}
                item-key={key}
                item-index={index}
              />
            </D3Tooltip>
            : null
        }
        {
          showBarLabel &&
          <text
            x={!horizontal ? x + width / 2 + 3 : xScale(item[0]) + yScale.bandwidth()}
            y={!horizontal ? yScale(item[0]) - 5 : yScale(item.data[xKey]) + yScale.bandwidth() / 2}
            dx={'-0.3em'}
            fontSize={8.5}
            textAnchor="middle"
            alignmentBaseline="middle"
            fill={getTextColorByBG(barColor)}
            className={classes.text}
            data-testid={`text-side-${i}-${key}`} item={item}
          >
            {
              horizontal && width > 30 ? formatLocaleNumber(value, ...formattingOptions) || null : null
            }
            {
              !horizontal && height > 12 ? formatLocaleNumber(value, ...formattingOptions) || null : null
            }
            {suffix}
          </text>
        }
      </g>
    );
  }





  const renderSeries = (type2) => {
    return stackedData.map(item => {
      const { key, index } = item
      const data1 = Array.from(item)
      return data1.map((d, i) => {
        return renderItem(d, key, index, i, type2)
      })
    });
  }

  return (
    <g data-testid='bars' id={id}>
      {renderSeries()}
      {
        data.map((item, i) => {
          let barProps = {};
          item.totalSum = sum(keys, k => +item[k]);
          if (horizontal) {
            barProps = {
              y: yScale(item[xKey]) + yScale.bandwidth() / 2,
              x: xScale(item.totalSum) + 15
            }
          } else {
            barProps = {
              x: xScale(item[xKey]) + xScale.bandwidth() / 2,
              y: yScale(item.totalSum) - 5
            }
          }
          const tooltipRefComponent = { x: barProps.x - 20, y: barProps.y - 8 }
          return (
            <g>
              {
                item.totalSum > 0 && <D3Tooltip
                  placement={"top"}
                  themeType={themeType}
                  interactive
                  title={<ParentTooltipContent
                    segmentData={item}
                    labelGroups={rest.legendGroupItems}
                    formattingOptions={formattingOptions}
                    parentProps={props}
                  />}
                >
                  <rect
                    {...tooltipRefComponent}
                    key={i}
                    height={15}
                    width={40}
                    data-testid="emptybar"
                    fill={'transparent'}
                    item={item}
                  />
                </D3Tooltip>
              }
              <text
                key={i}
                data-testid={`barCount-${i}`}
                {...barProps}
                fontSize={12}
                textAnchor="middle"
                alignmentBaseline="middle"
                fill={themeType === "dark" ? "white" : "black"}
                className={classes.text}
                visibility={rest.hideTopText ? "hidden" : "visible"}
              >
                {formatLocaleNumber(item.total, ...formattingOptions)}
              </text>
            </g>
          )
        })
      }
    </g>
  )
}

export default Bars;