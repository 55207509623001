import { profileConstants, getFavUnfavConstants, checklistConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";
import { batch } from "react-redux";
import { isEmpty } from "lodash";

export const addNewFilterLayout = variables => dispatch => {
    dispatch({
        type: profileConstants.PROFILE_CREATE_FILTER_LAYOUT,
        payload: {
            data: variables.viewObject,
            tableName: variables.tableName
        }
    })
}

export const setCurrentView = (variables, tableName) => dispatch => {
    dispatch(
        {
            type: profileConstants.PROFILE_SET_CURRENT_VIEW,
            payload: {variables,tableName}
        }
    );
}

export const setFirstLoad = (variables) => dispatch => {
    dispatch(
        {
            type: profileConstants.SET_FIRST_LOAD,
            payload: variables
        }
    );
}

export const getFilterLayouts = (variables) => dispatch => {
    const { loadBU, showFavs } = variables;

    dispatch({
        type: profileConstants.LOADING,
        payload: true,
        fetched: true
    });

    return fetch(endpoints.favourites.userFavourites, {}).then(d => d.json()
    ).then(d => {
        let { favouriteSites } = d;
        if (favouriteSites && favouriteSites.length >0) {
            favouriteSites =  favouriteSites?.map(item => ({ ...item, isFav: true }))
        }

        // Batch actions from single reducer to save multiple component updates
        batch(()=>{
            dispatch({
            type: profileConstants.LOADING,
            payload: false
            });
            dispatch({
                type: profileConstants.FETCH_STATUS,
                payload: true
            });
            dispatch({
                type: profileConstants.PROFILE_FETCH_SUCCESS,
                payload: d
            });
        })


        batch(()=>{
            dispatch({
                type: getFavUnfavConstants.GET_FAV_CUSO,
                payload: d?.favouriteCustomerSalesOffice || []
            });
            dispatch({
                type: getFavUnfavConstants.GET_FAVS_SUCCESS,
                payload: favouriteSites || []
            });
            if (loadBU) {
                dispatch({
                    type: getFavUnfavConstants.ADD_BUSINESS_UNIT_SUCCESS,
                    payload: d?.favouriteBusinessUnits || []
                });
            }
        })

        batch(()=>{
            
            const CUSOpayload = !isEmpty(d?.favouriteCustomerSalesOffice) ? d?.favouriteCustomerSalesOffice.reduce((acc, item) => item.selectionType === "CUST" ? 
            {...acc, CUST:[...acc.CUST, item]} : {...acc, SALES_OFFICE:[...acc.SALES_OFFICE, item]} , {CUST:[],SALES_OFFICE:[]}) : {CUST:[],SALES_OFFICE:[]} 
            
            // dispatch({
            //     type: checklistConstants.ADD_CUSO_MULTIPLE_SUCCESS,
            //     payload
            // });
        
            // dispatch({
            //     type: checklistConstants.ADD_ITEM_MULTIPLE_SUCCESS,
            //     payload: favouriteSites || []
            // });

            if (showFavs) {
                dispatch({
                    type: checklistConstants.SET_ONLOAD_SELECTIONS,
                    payload: { locations: favouriteSites || [], cuso: CUSOpayload }
                })
            } else {
                dispatch({
                    type: checklistConstants.SET_LOAD_FALSE,
                    payload: false
                })
            }
        })
    })
        .catch(err => {
            dispatch({
                type: profileConstants.LOADING,
                payload: false
            });
            dispatch({
                type: profileConstants.PROFILE_FETCH_FAILED,
                payload: err
            });
            dispatch({
                type: getFavUnfavConstants.ADD_BUSINESS_UNIT_FAILED,
                payload: []
            });
            dispatch({
                type: getFavUnfavConstants.GET_FAVS_FAILED,
                payload: []
            });
            dispatch({
                type: getFavUnfavConstants.FAV_CUSO_FAILED,
                payload: []
            });
        })
}


export const setFilterLayouts = variables => dispatch => {
    dispatch({
        type: profileConstants.LOADING,
        payload: true
    });

    const formData = () => {
        return ({
            "filterLayouts": { ...variables.actionObject }
        })
    }

    return fetch('/users/layout', {
        method: "PUT",
        body: formData(),
    })
        .then(d => {
            dispatch({
                type: profileConstants.PROFILE_SAVE_SUCCESS,
                payload: {
                    data: formData(),
                    currentView: variables.viewObject,
                    tableName:variables.tableName,
                    status: "done"
                }
            });
            dispatch({
                type: profileConstants.LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: profileConstants.LOADING,
                payload: false
            });
            dispatch({
                type: profileConstants.PROFILE_SAVE_FAILED,
                payload: {
                    data: null,
                    status: "failed"
                }
            });
        })
}

export const setAsFavorite = variables => dispatch => {
    dispatch({
        type: profileConstants.LOADING,
        payload: true
    })

    const formData = () => {
        return ({
            "filterLayouts": { ...variables.actionObject }
        })
    }

    //Snapshot API
    return fetch('/users/layout', {
        method: "PUT",
        body: formData(),
    })
        .then(d => {
            dispatch({
                type: profileConstants.PROFILE_MARKFAV_SUCCESS,
                payload: {
                    data: formData(),
                    status: "done"
                }
            });
            dispatch({
                type: profileConstants.LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: profileConstants.LOADING,
                payload: false
            });
            dispatch({
                type: profileConstants.PROFILE_MARKFAV_FAILED,
                payload: {
                    data: null,
                    status: "failed"
                }
            });
        })
}


