export default {
    LOADING: "STOCK_CONSTRAINT_LOADING",

    //Shipment#
    FETCH_SHIPMENT_LIST_SUCCESS: "FETCH_SHIPMENT_LIST_SUCCESS",
    SHIPMENT_LIST_FAILED: "SHIPMENT_LIST_FAILED",

    SEARCH_SHIPMENT_SUCCESS: "SEARCH_SHIPMENT_SUCCESS",
    SEARCH_SHIPMENT_FAILED: "SEARCH_SHIPMENT_FAILED",

    FETCH_RECOMENDATIONS_SUCCESS: "FETCH_RECOMENDATIONS_SUCCESS",
    RECOMENDATIONS_FAILED: "RECOMENDATIONS_FAILED",

    //Material#
    FETCH_MATERIALS_SUCCESS: "FETCH_MATERIALS_SUCCESS",
    MATERIALS_FAILED: "MATERIALS_FAILED",

    FETCH_MATERIALS_OUTBOUND_SUCCESS: "FETCH_MATERIALS_OUTBOUND_SUCCESS",
    MATERIALS_OUTBOUND_FAILED: "MATERIALS_OUTBOUND_FAILED",

    FETCH_MATERIALS_INBOUND_SUCCESS: "FETCH_MATERIALS_INBOUND_SUCCESS",
    MATERIALS_INBOUND_FAILED: "MATERIALS_INBOUND_FAILED",
}