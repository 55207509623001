import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import MaterialTable from 'material-table';
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { osmOrderDetailsStyles } from 'theme';
import { OSMSalesOrderDetailsColumns } from "../../reduxLib/constdata/osmSalesOrderDetailsColumns";
// import { mockOsmSalesOrderTable } from "../dummydata/osmMockData";
import fetch from "reduxLib/services/serviceHelpers";
import { endpoints } from "../../configs/endpoints"
import { OsmProductDetailsTable } from "./OsmProductDetailsTable"
import { detailOptions } from 'helpers/tableStyleOverride';

const useStyles = makeStyles(osmOrderDetailsStyles)

const options = {
  ...detailOptions,
  maxBodyHeight: "50vh"
}

export const OsmSalesOrderDetailsTable = ({ shipment, sites, filterBody }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const newFilterBody = {...filterBody, searchStringList: null}
  const tableBody = {
    region: "NA", 
    shipmentNum : shipment,
    ...newFilterBody
  }

  const [columnsOrder, setcolumnsOrder] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const translated = OSMSalesOrderDetailsColumns.columnOrder?.map(item => {
      return {
        ...item,
        title: t(item.title),
      }
    });
    setcolumnsOrder(translated);
  }, []);

  return (
    <Box data-testid="osmSalesOrderDetails" justifyContent="center" className={classes.tablebody}>
      <Typography variant="h3" className={classes.title}>{t('order_delivery_details')}</Typography>
      <Grid className={classes.table}>
        <MaterialTable
          localization={{
            body: {
              emptyDataSourceMessage: loading ? t("please_wait_while_the_data_is_loading") : t("no_records_to_display")
            }
          }}
          columns={columnsOrder}
          options={options}
          style={{ width: '93vw' }}
          data={() =>
            new Promise((resolve, reject) => {
              let url = endpoints.table.osmOrderDetails;
              fetch(url, {
                method: "post",
                body: tableBody
              })
              .then(response => {
                  return response?.json() || {};
              })                            
              .then(result => {
                const tempData = result?.orders?.map(data => {
                  return {
                    ...data,
                    ...OSMSalesOrderDetailsColumns.columnConfiguration(data)
                  }
                }) || [];
                setLoading(false);
                resolve({
                  data: tempData
                })
              })
              .catch(e => {
                setLoading(false);
                resolve({
                  data: []
                })
              })
            })
          }
          detailPanel={
            [
              rowData => ({
                render: rowData_ => (<OsmProductDetailsTable orderStatusTableRowId={rowData_.orderStatusTableRowId} sites={filterBody?.sites} />)
              })
            ]
          }
        />
      </Grid>
    </Box>
  )
}

export default OsmSalesOrderDetailsTable;