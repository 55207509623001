export default {
    GET_ALL_VIEWS_SUCCESS: "GET_ALL_VIEWS_SUCCESS",
    GET_ALL_VIEWS_FAILED: "GET_ALL_VIEWS_FAILED",
    GET_VIEW_SUCCESS: "GET_VIEW_SUCCESS",
    GET_VIEW_FAILED: "GET_VIEW_FAILED",
    VIEWS_LOADING: "VIEWS_LOADING",
    VIEW_LOADING: "VIEW_LOADING",
    INSERT_UPDATE_VIEW_SUCCESS: "INSERT_UPDATE_VIEW_SUCCESS",
    INSERT_UPDATE_VIEW_FAILED: "INSERT_UPDATE_VIEW_FAILED",
    DELETE_VIEW_SUCCESS: "DELETE_VIEW_SUCCESS",
    DELETE_VIEW_FAILED: "DELETE_VIEW_FAILED",
    FAV_UNFAV_VIEW_SUCCESS: "FAV_UNFAV_VIEW_SUCCESS",
    FAV_UNFAV_VIEW_FAILED: "FAV_UNFAV_VIEW_FAILED",
    RESET_VIEWS: "RESET_LAO_VIEWS",
    RESET_LAO_STATUS: "RESET_LAO_STATUS",
    RESET_STATUS: "RESET_VIEW_API_STATUS"
}