import React from "react";
import { Snackbar } from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';


const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Snack = ({ open, handleClose, severity, message, autoHideDuration=3000 }) => {
    return (
        <Snackbar data-testid='snackbar' open={open} autoHideDuration={autoHideDuration} onClose={handleClose} TransitionComponent="SlideTransition" anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert severity={severity} data-testid="close-alert-snack">
                {message}
            </Alert>
        </Snackbar>
    )
}

export default Snack;