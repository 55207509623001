import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Link from "@material-ui/core/Link";
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { updateEmeaLocations, emeaLocationFavService } from 'reduxLib/services/EmeaServices/emeaLocationServices';
import { emeaUpdateFavs } from 'reduxLib/services/EmeaServices/emeaFavoriteService';
import { isJsonParsable } from 'helpers';
import { businessUnits, emeaCountryCodes } from "configs/appConstants";
import Snack from "components/common/Helpers/Snack";
import qs from "query-string";
import isEqual from 'lodash/isEqual';
import SearchIcon from '@material-ui/icons/Search';
import FavoriteIcon from 'assets/images/favorite.svg';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import useSetQuery,{ useUpdateTabWitOptions } from 'customHooks/useQuery';
import { generateSites } from 'helpers/emeaHelpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
    minWidth: 280,
    maxWidth: 320,
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette?.text?.primary}`
  },
  menuItem: {
    whiteSpace: 'pre-wrap',
    '&.MuiListItem-root.Mui-selected' : {
      backgroundColor: theme.palette?.background?.selected
    },
    '&.MuiListItem-root:hover' : {
      backgroundColor: theme.palette?.background?.selected
    }
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.secondary?.pressed
    }
  },
  icon: {
    fill: theme.palette?.text?.primary
  },
  locationGrid: {
    maxHeight: 300,
    overflowX: 'auto',
    '& >div:not(:nth-child(3n))': {
      borderRight: `1px solid ${theme.palette.border}`
    },
    [theme.breakpoints.down("md")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      },
      '& >div:nth-child(odd)': {
        borderRight: `1px solid ${theme.palette.border}`
      }
    },
    [theme.breakpoints.down("sm")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      }
    }
  },
  sites: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0px ${theme.spacing(3)}px`,
    margin: `${theme.spacing(.5)}px 0px`
  },
  allLocations: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: `${theme.spacing(.5)}px 0px`,
    border: 'none !important'
  },
  customLinks: {
    justifyContent: 'space-between',
  },
  actionGrid: {
    border: `none !important`,
    position: 'absolute',
    bottom: theme.spacing(2.5),
    width: 'auto'
  },
  actionsButton: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.primary?.button}`,
    color: theme.palette?.primary?.button,
    marginRight: theme.spacing(2),
  },
  textlink: {
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
    textDecoration: 'underline'
  },
}))

export const CssTextField = withStyles(theme => ({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.border,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.border,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.border,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.border,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(2.25)}px ${theme.spacing(2.5)}px`
    }
  }
}))(TextField);

const EmeaLocations = ({ region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParams = qs.parse(location.search);
  const [params] = useSetQuery();
  const updateTabWithOptions = useUpdateTabWitOptions();

  const { countries, locations, locations_fav_loading, locations_fav_err } = useSelector(({ emeaSites }) => emeaSites);
  const { all_favs } = useSelector(({ emeaGlobalFavs }) => emeaGlobalFavs);

  const bu = isJsonParsable(queryParams?.BU) || businessUnits;
  const [searchText, setSearchText] = useState("");
  const containsText = (text, searchtext) => text.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  const filteredLocations = useMemo(() => {
    return locations?.filter((option) => {
      return containsText(`${option?.st_short_name} (${option?.st_site_num})`, searchText)
    });
  }, [searchText, locations]);
 
  const [countryList, setCountryList] = useState(() => isJsonParsable(queryParams?.ctry) || []);
  const [sitesSelectedURL, setSitesSelectedURL] = useState(() => isJsonParsable(queryParams?.sites) || []);
  const [sitesSelected, setSitesSelected] = useState(() => generateSites(sitesSelectedURL) || []);  
  const isAllSites = (filteredLocations, sitesSelected) => {
    let sites = filteredLocations.map(item => {
      return item.st_site_num;
    })
    return (JSON.stringify(sites) === JSON.stringify(sitesSelected)) && sites.length && sitesSelected.length ? true : false  
  }
  const [siteFavs, setSiteFavs] = useState(all_favs?.favouriteSiteNums || []);
  const [isCheckSelect, setIsCheckSelect] = useState(false);
  const [enableSelectAll, setEnableSelectAll] = useState(() => isAllSites(filteredLocations, sitesSelected) || false);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });  
  
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        minWidth: 280,
        maxWidth: 300
      },
      'data-testid': 'dropdownFilter'
    },
    style: {zIndex: 1500},
    getContentAnchorEl: null,
    autoFocus: false,
    anchorOrigin: {
      vertical: 42,
      horizontal: -12
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  const handleLocationSearch = (event) => {
    setSearchText(event.target.value);
  }

  const handleLocationSelect = (event, siteObj) => {
    if (event.target.checked) {
      setSitesSelected([...sitesSelected, siteObj.st_site_num])
      setSitesSelectedURL([...sitesSelectedURL, `${siteObj.st_site_num} (${siteObj.st_country})`])
      const data = filteredLocations.map(item => {
        return item.st_site_num
      })
      if (isEqual(data.sort(), ([...sitesSelected, siteObj.st_site_num].sort()))) {
        setEnableSelectAll(true);
      }
    } else {
      let x = sitesSelected.filter(site => site !== siteObj.st_site_num);
      setSitesSelected(x);
      let y = sitesSelectedURL.filter(siteCountry => siteCountry !== `${siteObj.st_site_num} (${siteObj.st_country})`);
      setEnableSelectAll(false);
      setSitesSelectedURL(y);
    }
  }

  const handleCountrySelect = (event) => {
    if (event.target.value.includes('Select Country')) {
      event.preventDefault()
    } else {
      setCountryList(event.target.value);
    }
  };

  const handleClearSelections = () => {
    setEnableSelectAll(false);
    setSitesSelected([]);
    updateTabWithOptions({ key: "sites", value: []}, {}, [], params.Cust, params.sofcNSG)
    // history.push({
    //   ...location,
    //   search: qs.stringify({
    //     ...queryParams,
    //     sites: JSON.stringify([])
    //   })
    // });
  }

  const handleSaveSelections = () => {
    updateTabWithOptions({ key: "sites", value: sitesSelectedURL }, {}, sitesSelectedURL, params.Cust, params.sofcNSG);
    // history.push({
    //   ...location,
    //   search: qs.stringify({
    //     ...queryParams,
    //     sites: JSON.stringify(sitesSelected)
    //   })
    // });
    if(!isEqual(all_favs?.favouriteSiteNums, siteFavs)) {
      dispatch(emeaLocationFavService({ region: region, favouriteSiteNums: siteFavs }))
    }
  }

  const handleSiteFav = (event, siteNum, siteNumCountry) => {
    if(siteFavs.includes(siteNumCountry)) {
      const y = siteFavs.filter(x => x !== siteNumCountry);
      setSiteFavs(y);
      if (sitesSelected.includes(siteNum)) {
        const x = sitesSelected.filter(d => d !== siteNum);
        setSitesSelected(x);
        const xy = sitesSelectedURL.filter(d => d !== siteNumCountry);
        setSitesSelectedURL(xy)
      }
    } else {
      setSiteFavs([...siteFavs, siteNumCountry]);
      if (sitesSelected.includes(siteNum) === false) 
        setSitesSelected([...sitesSelected, siteNum]);
      if (sitesSelectedURL.includes(siteNumCountry) === false) {
        setSitesSelectedURL([...sitesSelectedURL, siteNumCountry])
      }       
    }
  }

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //     return;
    // }
    setSnack({
        ...snack,
        open: false
    });
  };

  const handleSelectAllLocations = (e) => {
    if (e.target.checked) {
      setEnableSelectAll(true);
      const data = filteredLocations.map(item => {
        return item.st_site_num
      })
      const urlData = filteredLocations.map(item => {
        return `${item.st_site_num} (${item.st_country})`
      })
      setSitesSelected(data);
      setSitesSelectedURL(urlData);   
    } else {
      setEnableSelectAll(false);
      setSitesSelected([])
      setSitesSelectedURL([])
    }
  }

  const handleClearAllCountries = () => {
    setCountryList([]);
  };

  const handleSelectAllCountries = () => {
    setCountryList(countries);
  };

  useEffect(() => {
    if(!locations_fav_loading && locations_fav_err && locations_fav_err !== 'success') {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: t(locations_fav_err)
      });
    }
    if(!locations_fav_loading && locations_fav_err === 'success') {
      const body = {...all_favs, favouriteSiteNums: siteFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [locations_fav_err, locations_fav_loading])

  useDeepCompareEffect(() => {
    const ctries = isJsonParsable(queryParams?.ctry) || [];
    const sites = isJsonParsable(queryParams?.sites) || [];
    setCountryList(ctries);
    setSitesSelectedURL(sites);
  }, [queryParams?.ctry, queryParams?.sites]);


  useDeepCompareEffect(() => {
    history.push({
      ...location,
      search: qs.stringify({
        ...queryParams,
        ctry: JSON.stringify(countryList),
      })
    });
    dispatch(updateEmeaLocations({
      countryList, 
      bu
    }));
  }, [countryList, locations, queryParams?.sites])

  return (
    <Grid container data-testid='filterLocations'>
      <Grid container item spacing={2}>
        <Grid item>
          <Typography variant="h6" > { t('country') } </Typography>
          <FormControl variant="outlined" className={classes.formControl} data-testid="countryDropdown">
            <Select 
              labelId={'country-checkbox-label'}
              SelectDisplayProps={ {"data-testid" : `countryFilter-select`} }
              multiple
              input={<Input />}
              displayEmpty={true}
              disableUnderline
              value={countryList}
              MenuProps={MenuProps}
              inputProps={{ classes: { icon: classes.icon } }}
              onChange={(e) => handleCountrySelect(e)} 
              renderValue={(selected) => selected.length ? selected.join(', '): `${t('select_country')}`}
            >
              <MenuItem value="Select Country" className={classes.menuItem} data-testid={'menu-select'} >                
                <Grid container sm={12} md={12} lg={12} className={classes.customLinks}>
                  <Grid item>
                    <Link data-testid="clearAllCountries" className={classes.textlink} onClick={handleClearAllCountries}> {t('clear_all')} </Link>
                  </Grid>
                  <Grid item>
                    <Link data-testid="selectAllCountries" className={classes.textlink} onClick={handleSelectAllCountries}> {t('select_all')} </Link>
                  </Grid>
                </Grid>
              </MenuItem>
            { countries.map((item) => (
              <MenuItem key={item} value={item} className={classes.menuItem} data-testid={`menu-${item}`} >
                <Checkbox checked={countryList.indexOf(item) > -1} className={classes.checkbox} 
                  inputProps={{'data-testid': `checkbox_${item}`}} />
                <ListItemText primary={`${item} - ${emeaCountryCodes[item] || ''}`} />
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="h6"> { t('search') } </Typography>
          <CssTextField id="id-location-search" placeholder={t("location_search")} value={searchText} onChange={(e) => handleLocationSearch(e)} variant="outlined" 
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            inputProps= {{'data-testid': 'location-search'}}
          />
        </Grid>
      </Grid>
      { locations?.length > 1 ?
      <Grid container sm={12} md={12} lg={12} className={classes.allLocations} data-testid="select-all-locations">
          <Grid item>
            <Checkbox name='selectAllLocation' checked={enableSelectAll} onChange={handleSelectAllLocations} className={classes.checkbox}
              inputProps={{'data-testid': 'select-all-checkbox'}} />                              
          </Grid>          
          <Typography variant='body1'>{t('select_all')}</Typography> 
      </Grid> : null }
      <Grid container item className={classes.locationGrid} data-testid="location-list">        
        { locations?.length ? filteredLocations?.map(site => {
            return (
              <Grid container item sm={12} md={6} lg={4} className={classes.sites}>
                <Typography variant='body1'> {site?.st_short_name} ({site?.st_site_num}) </Typography>
                <Grid item>
                  <Checkbox name={site?.st_short_name} checked={sitesSelected.indexOf(site?.st_site_num) > -1} onChange={(e) => handleLocationSelect(e, site)} className={classes.checkbox}
                    inputProps={{'data-testid': `select_${site?.st_site_num}`}} />
                  <IconButton aria-label="fav-button" onClick={(e) => handleSiteFav(e, site?.st_site_num, `${site?.st_site_num} (${site?.st_country})`)} disabled={locations_fav_loading} data-testid={`fav-${site?.st_site_num}`} >
                    <img alt="fav-icon" src={siteFavs.includes(`${site?.st_site_num} (${site?.st_country})`) ? FavoriteIcon : NotFavoriteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })
        : null }
        { locations?.length ?
            <Grid container item className={classes.actionGrid}>
              <Button variant="outlined" className={classes.actionsButton} data-testid="save-sites" onClick={handleSaveSelections}> {t('apply_location_changes')} </Button>
              <Button variant="outlined" className={classes.actionsButton} data-testid="clear-sites" onClick={handleClearSelections}> {t('clear_location_selections')} </Button>
            </Grid>
          : null
        }
      </Grid>

      <Snack {...snack} handleClose={handleClose} />
    </Grid>
  )
}

export default EmeaLocations;