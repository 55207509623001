import React, { useState } from 'react';
import { line as d3Line } from "d3"
import { useDeepCompareEffect } from 'react-use';

const TrendLine = (props) => {
    const { scales: { xScale: x, yScale: y }, data, xKey, yKey, dashed } = props;
    const line = d3Line()
        .x(d => x(d[xKey]) + x.bandwidth() / 2)
        .y(d => y(d[yKey]));
    const [lineData, setLineData] = useState(() => line(data));

    useDeepCompareEffect(() => {
        setLineData(line(data))
    }, [data])

    return (
        <path
            fill="none"
            pointerEvents={"none"}
            data-testid='d3-trendline'
            strokeMiterlimit={1}
            stroke='black'
            strokeWidth={2}
            strokeDasharray={dashed ? "5, 5" : ""}
            d={lineData}
        />
    )
}

export default TrendLine;