import { emeaLocationConstants } from "reduxLib/constants/EMEA/emeaLocationConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const emeaGetLocationsService = variables => dispatch => {
  dispatch({
    type: emeaLocationConstants.EMEA_LOCATIONS_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.locations, {
    method: "GET",
    signal: variables.controller.signal
  })
    .then(d => {
      if (d.status === 200) {
        return d.json();
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_sites_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATIONS_SUCCESS,
        payload: d,
        error: null
      });
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATIONS_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      if (err?.name === "AbortError") {
        return;
      }
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATIONS_FAILED,
        payload: {},
        error: err,
      });
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATIONS_LOADING,
        payload: false,
      });
    })
}

export const updateCountries = variables => dispatch => {
	dispatch({
		type: emeaLocationConstants.EMEA_MAP_COUNTRY,
		payload: variables
	})
}

export const updateEmeaLocations = variables => dispatch => {
	dispatch({
		type: emeaLocationConstants.EMEA_MAP_LOCATION,
		payload: { countryList: variables.countryList, BU: variables.bu }
	})
}

export const emeaLocationFavService = variables => dispatch => {
  dispatch({
    type: emeaLocationConstants.EMEA_LOCATION_FAV_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.sitesFav, {
    method: "PUT",
    body: variables
  })
    .then(d => {
      if (d.status === 200) {
        return d;
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_sites_fav_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATION_FAV_SUCCESS,
        payload: variables.favouriteSiteNums
      });

      dispatch({
        type: emeaLocationConstants.EMEA_LOCATION_FAV_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaLocationConstants.EMEA_LOCATION_FAV_FAILED,
        error: err
      });

      dispatch({
        type: emeaLocationConstants.EMEA_LOCATION_FAV_LOADING,
        payload: false
      });
    })
}