import { detailsConstants } from '../constants';



const getShipmentDetailsReducer = (state = {loading: false, details: {}, stops: [], locationHistory:[]}, action) => {
    const {payload} = action;
    const shipmentDetails = payload;
    
    switch (action.type) {
        case detailsConstants.SHIPMENT_STOPS_DATA_FETCH_SUCCESS:
            const stops = payload.stops;
            return { ...state, details: shipmentDetails, stops, locationHistory: []};

        case detailsConstants.SHIPMENT_TRACKING_DATA_FETCH_SUCCESS:
            const locationHistory = payload.locationHistory.map(carrierPing => ({ ...carrierPing, latitude: carrierPing.geoLocation.latitude, longitude: carrierPing.geoLocation.longitude }))
            return { ...state, locationHistory};

        case detailsConstants.SHIPMENT_STOPS_DATA_FETCH_FAILED:
            return { ...state, details: {}, stops:[], locationHistory:[], error: payload};

        case detailsConstants.SHIPMENT_TRACKING_DATA_FETCH_FAILED:
            return { ...state, locationHistory:[], error: payload};

        case detailsConstants.SHIPMENT_LOADING:
            return { ...state, loading: payload }
            

        default:
            return state
    }
}

export default getShipmentDetailsReducer;