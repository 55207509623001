import { getFavUnfavConstants, checklistConstants } from "../constants";
import { businessUnits as businessUnitsDefault } from "configs/appConstants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";
import { find, flattenDeep, includes, uniq, isEmpty } from "lodash"

const formData = (data) => {
    return ({
        "favouriteSites": [data]
    })
}

const setFavService = datavariables => dispatch => {
    dispatch({
        type: getFavUnfavConstants.MARK_LOADING,
        payload: true
    })
    const { variables } = datavariables;


    return fetch(endpoints.favourites.favSites, {
        method: "PUT",
        body: formData(variables),
    })
        .then(d => {
            dispatch({
                type: getFavUnfavConstants.MARK_FAV_SUCCESS,
                payload: {
                    data: datavariables.variables,
                    status: "done"
                }
            });
            dispatch({
                type: checklistConstants.ADD_ITEM_SUCCESS,
                payload: { ...datavariables.variables, isFav: true }
            })

            dispatch({
                type: getFavUnfavConstants.MARK_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getFavUnfavConstants.MARK_LOADING,
                payload: false
            });
            dispatch({
                type: getFavUnfavConstants.MARK_FAV_FAILED,
                payload: {
                    data: null,
                    status: "failed"
                }
            });
        })
}

const setUnfavService = datavariables => dispatch => {
    dispatch({
        type: getFavUnfavConstants.MARK_LOADING,
        payload: true
    });

    const { variables } = datavariables;

    return fetch(endpoints.favourites.unfavSites, {
        method: "PUT",
        body: formData(variables),
    })
        .then(d => {
            dispatch({
                type: getFavUnfavConstants.MARK_UNFAV_SUCCESS,
                payload: {
                    data: datavariables.variables,
                    status: "done"
                }
            });
            dispatch({
                type: checklistConstants.REMOVE_ITEM_SUCCESS,
                payload: datavariables.variables
            });
            dispatch({
                type: getFavUnfavConstants.MARK_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getFavUnfavConstants.MARK_LOADING,
                payload: false
            });
            dispatch({
                type: getFavUnfavConstants.MARK_UNFAV_FAILED,
                payload: { status: "failed", data: variables.data }
            });
        })
}

const resetMarkings = variables => dispatch => {
    dispatch({
        type: getFavUnfavConstants.RESET_FAV_UNFAV_MARKING,
        payload: null
    })
}


const setBusinessService = variables => dispatch => {
    dispatch({
        type: getFavUnfavConstants.ADD_BUSINESS_UNIT_LOADING,
        payload: true
    });

    const { value, type, bunits, lbunits } = variables;

    let localBusinessUnits = lbunits || [];

    let businessUnits = [];


    if (type === "fav") {
        if (bunits.length <= 1 && bunits[0] !== value.value) {
            businessUnits = [...bunits, value.value];
            localBusinessUnits = localBusinessUnits.length === 2 ? localBusinessUnits : [...localBusinessUnits, value.value];
        }
    }

    if (type === "unfav") {
        businessUnits = bunits.filter(d => d !== value.value);
        localBusinessUnits = localBusinessUnits.filter(d => d !== value.value);

        if (localBusinessUnits.length === 0) {
            localBusinessUnits = businessUnitsDefault;
        }
    }

    businessUnits = uniq(businessUnits);
    localBusinessUnits = uniq(localBusinessUnits);

    return fetch(endpoints.favourites.buFavourites, {
        method: "PUT",
        body: {
            favouriteBusinessUnits: businessUnits
        },
    })
        .then(d => {
            dispatch({
                type: getFavUnfavConstants.ADD_BUSINESS_UNIT_SUCCESS,
                payload: businessUnits
            });
            dispatch({
                type: getFavUnfavConstants.SET_BUSINESS_UNIT_LOCAL,
                payload: localBusinessUnits
            });
            dispatch({
                type: getFavUnfavConstants.ADD_BUSINESS_UNIT_LOADING,
                payload: false
            });

        })
        .catch(err => {
            dispatch({
                type: getFavUnfavConstants.ADD_BUSINESS_UNIT_LOADING,
                payload: false
            });
            dispatch({
                type: getFavUnfavConstants.ADD_BUSINESS_UNIT_FAILED,
                payload: []
            });
        })

}

const setBusinessLocalAction = variables => dispatch => {
    dispatch({
        type: getFavUnfavConstants.SET_BUSINESS_UNIT_LOCAL,
        payload: variables
    });
}

const setFavCUSOService = ({ variables }) => dispatch => {
    dispatch({
        type: getFavUnfavConstants.FAV_CUSO_LOADING,
        payload: true
    });
    let { reducerObject, state, shortName } = variables;
    let actionType = "";

    let favouriteCustomerSalesOffice = (reducerObject.selectionType === "CUST") ?
        reducerObject.salesOrg.map((value) => ({ ...reducerObject, salesOrg: value })) :
        [reducerObject]
    let updatedState = [];

    if (variables.type === "SALES_GROUP") {
        if (find(state, favouriteCustomerSalesOffice[0]) === undefined) {
            actionType = "fav";
            updatedState = ([...state, { ...reducerObject, salesGroup: [shortName] }])
        } else {
            if (find(state, (n) => includes(n.salesGroup, shortName) && n.salesOrg === reducerObject.salesOrg && n.salesOffice === reducerObject.salesOffice && n.distributionChannel === reducerObject.distributionChannel)) {
                actionType = "unfav"
                const filterSGArray = (SO) => {
                    return SO.salesGroup?.filter((value) => value !== shortName)
                }
                updatedState = state.map((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice ? 
                    !isEmpty(filterSGArray(SO)) ? { ...SO, salesGroup: SO.salesGroup.filter((value) => value !== shortName) } : (() => { delete SO.salesGroup; return SO })()
                : SO)

            } else {
                actionType = "fav"
                updatedState = state.map((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice ? { ...SO, salesGroup: SO.salesGroup ? [...SO?.salesGroup, shortName] : [shortName] } : SO)
            }
        }
    } else if (reducerObject.selectionType === "SALES_OFFICE") {
        if (find(state, favouriteCustomerSalesOffice[0]) === undefined) {
            actionType = "add"
            find(state, (n) => n.salesOrg === reducerObject.salesOrg && n.salesOffice === reducerObject.salesOffice) ?
                updatedState = state.map(SO => (SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice) ? { ...SO, distributionChannel: reducerObject.distributionChannel } : SO)
                : updatedState = [...state, favouriteCustomerSalesOffice[0]]
        } else {
            actionType = "remove"
            updatedState = [state.filter((object) => !(object.salesOffice === favouriteCustomerSalesOffice[0].salesOffice && object.salesOrg === favouriteCustomerSalesOffice[0].salesOrg))];
        }
    }
    else {
        if (find(state, favouriteCustomerSalesOffice[0]) === undefined) {
            actionType = "add";
            updatedState = [...state, flattenDeep(favouriteCustomerSalesOffice)];
        } else {
            actionType = "remove";
            updatedState = [state.filter((object) => !(object.distributionChannel === favouriteCustomerSalesOffice[0].distributionChannel && object.customerName === favouriteCustomerSalesOffice[0].customerName && object.salesOrg === favouriteCustomerSalesOffice[0].salesOrg))];
        }
    }

    return fetch(endpoints.favourites.cusoFavourites, {
        method: "PUT",
        body: {
            favouriteCustomerSalesOffice: flattenDeep(updatedState)
        },
    })
        .then(d => {
            dispatch({
                type: getFavUnfavConstants.FAV_CUSO_SUCCESS,
                payload: { selectionType: reducerObject.selectionType, updatedState: flattenDeep(updatedState) }
            });
            if (actionType === "add") {
                dispatch({
                    type: checklistConstants.ADD_CUSO_SUCCESS,
                    payload: reducerObject
                })
            } else if (actionType === "remove") {
                dispatch({
                    type: checklistConstants.REMOVE_CUSO_SUCCESS,
                    payload: reducerObject
                });
            } else if (actionType === "fav") {
                dispatch({
                    type: checklistConstants.ADD_SG_SUCCESS,
                    payload: { reducerObject, shortName }
                });
            } else if (actionType === "unfav") {
                dispatch({
                    type: checklistConstants.REMOVE_SG_SUCCESS,
                    payload: { reducerObject, shortName }
                });
            }
            dispatch({
                type: getFavUnfavConstants.FAV_CUSO_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getFavUnfavConstants.FAV_CUSO_LOADING,
                payload: false
            });
            dispatch({
                type: getFavUnfavConstants.FAV_CUSO_FAILED,
                payload: []
            });
        })
}

export {
    setFavService,
    setUnfavService,
    resetMarkings,
    setBusinessService,
    setBusinessLocalAction,
    setFavCUSOService
}