import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table'
import { Paper, Grid, makeStyles, TablePagination } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import { tableStyles } from 'theme';
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import { getTableData, resetFilters } from 'reduxLib/services';
import { lightTableOptions } from 'helpers/tableStyleOverride';
import { OsmSalesOrderDetailsTable } from "./OsmSalesOrderDetailsTable"
import OsmTableActionsBar from "./OsmTableActionsBar";
import { cloneDeep, isEqual } from 'lodash'
import { saveFilters, activateOSMView } from "reduxLib/services";
import osmFilters, { osmFiltersModifiedObject } from "reduxLib/constdata/osmFilters";
import OsmFilterBox from './OsmFilterBox';
import { generateTablebody, isJsonParsable, createHyperLinkFilterObject2, hideDetailColumns, getFilterArgs } from "helpers";
import { useAppContext } from 'context/AppContext';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from "lodash/get";
import map from 'lodash/map';
import differenceBy from "lodash/differenceBy";
import qs from 'query-string';
import { businessUnits as BU } from "configs/appConstants";

const useStyles = makeStyles(tableStyles);
const useStylesPagination = makeStyles((theme) => ({
  spacer: {
    flex: "1 1 auto"
  },
  selectIcon: {
    color: theme.palette?.text?.primary
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  }
}));

const OsmMainTable = ({ tableName, tableRef, columns, fetchEndPoint, hyperlinkClick, setHyperlinkClick, forceRefresh }) => {
  const classes = useStyles();
  const paginationClasses = useStylesPagination();
  const { t } = useTranslation();
  const { setRef } = useAppContext();
  const osmShipmentData = useSelector(({ tabledata }) => tabledata?.osm?.osmShipments?.data);
  const osmDataLoading = useSelector(({ tabledata }) => tabledata?.osm?.osmShipments?.loading);
  const { osm_sites_loading, regions, countries, sites } = useSelector(({ osmAuth }) => osmAuth);
  const filters = useSelector(({ options }) => options?.filters?.osmShipments);
  const { views, active, loading } = useSelector(({ osm }) => osm);
  let { osmviewid } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = qs.parse(location.search);

  const type = 'osm';
  const subtype = "osmShipments";

  const [global, setGlobal] = React.useState({})
  const [firstLoad, setFirstLoad] = useState(false);
  const [saveFilterLoad, setSaveFilterLoad] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [columnsOrder, setcolumnsOrder] = useState([]);
  const [tabledata, setTableData] = React.useState(osmShipmentData || {})
  const { shipments, totalRecords: totalRecordsData } = tabledata;
  const [selectedRow, setSelectedRow] = useState({});
  // const [filterCount, setFilterCount] = useState();
  const [activeViews, setActiveViews] = useState(() => views?.filter(d => !d.hidden));
  const [selectedSites, setSelectedSites] = useState(null);
  // const newFilters = generateTablebody(filters);
  const [newFilters, setNewFilters] = useState({});
  const [tableFilters, setTableFilters] = useState({});
  const [urlfilters, setUrlFilters] = React.useState(() => isJsonParsable(queryParams.osmfilters) || {})
  const [tableBody, setTableBody] = useState(() => {
    return  {
      region: 'NA',
      searchType: "OSM_SHIPMENT", 
      sites: selectedSites,
      businessUnits: global?.business,
      // frieghtOrderPlanningStatusBucketList: ["PLANNED"], 
      // frieghtOrderLifecycleStatusBucketList:["IN_PROCESS"], 
      // frieghtOrderExecutionStatusBucketList:["EXECUTED"],
      orderBy: [{
        key: "loadingStartDateTimeEcc",
        ordering: "ASC"
      }, {
        key: "osmShipmentExecutionHealth",
        ordering: "DESC" 
      }]
    }
  });
  const [localFilters, setLocalFilters] = useState({});

  const conditionalFilterReplacement = (new_filters) => {
    if (get(new_filters, "osmShipmentExecutionBucket", []).length === 0) {
      const osmShipmentBody = [
        "OSM_LOAD_DIAGRAM_SUBMISSION_PENDING",
        "OSM_LOAD_DIAGRAM_SUBMITTED",
        "OSM_UCC_LABEL_PRINTED",
        "OSM_ADDRESS_LABEL_PRINTED",
        "OSM_DOWNLOADED_TO_WMS",
        "OSM_LOADING_STARTED",
        "OSM_LOADING_ENDED",
        "OSM_SHIPMENT_COMPLETION",
        "OSM_SHIPMENT_START",
        "OSM_SHIPMENT_END"
      ];
      new_filters.osmShipmentExecutionBucket = osmShipmentBody;
    }
    return new_filters
  }

  const changeQueryParams = (params) => {
    history.replace({
      ...location,
      search: qs.stringify({
        ...queryParams,
        ...params
      }, { encode: false })
    });
  }

  const generateURLFilters = (filterobj, convert = true, otherProps) => {
    const args = convert ? getFilterArgs(filterobj) : filterobj;
    history.replace({
      ...location,
      search: qs.stringify({
        ...queryParams,
        hyperlinks: undefined,
        osmfilters: JSON.stringify(args),
        ...otherProps
      }, { encode: false })
    });
  }

  const updateURLFilters = (osmURLFilters) => {
    const filterKeys = Object.keys(osmURLFilters) || [];
      const osmFiltersFinal = createHyperLinkFilterObject2({
        pageDetails: {
          filterParams: {
            args: osmURLFilters
          }
        },
        filterBody: osmFiltersModifiedObject,
        filterKeys
      })?.fullObject;
      let viewFilters = {
        ...osmFiltersModifiedObject,
        ...osmFiltersFinal
      };
      dispatch(saveFilters({
        type: type,
        subtype: subtype,
        filter: viewFilters
      }));
  }

  useDeepCompareEffect(() => {
    if (filters) {
      let updatedNewFilters = generateTablebody(filters);
      updatedNewFilters = conditionalFilterReplacement(updatedNewFilters);
      setNewFilters(updatedNewFilters);
      const tableFilters = isEmpty(updatedNewFilters?.sites) ? { ...updatedNewFilters, sites: global?.sites } : updatedNewFilters;
      setTableFilters(tableFilters);
      setTableBody({
        ...tableBody,
        sites: global?.sites,
        businessUnits: global?.business
      });
    }
  }, [filters, global?.sites, global?.business])


  useDeepCompareEffect(() => {
    const globalFilters = isJsonParsable(qs.parse(location.search)?.osmglobal) || {};
    setGlobal(globalFilters);
  }, [location.search])

  useDeepCompareEffect(() => {
    setActiveViews(views?.filter(d => !d.hidden));
  }, [views]);

  useEffect(() => {
    if(queryParams.newtab) {
      changeQueryParams({
        newtab: undefined
      })
    }
  }, [queryParams.newtab]);

  const resetTableFilters = () => {
    const viewFilters = {
      ...filters,
      ...active.filters,
    }
    setPage(0);
    changeQueryParams({
      page: 0,
      activity: "reset",
      osmfilters: JSON.stringify(getFilterArgs(viewFilters))
    });
  }

  useEffect(() => {
    const hyperlinks = isJsonParsable(queryParams.hyperlinks);
    if (!isEmpty(hyperlinks)) {
      updateURLFilters(hyperlinks);
    }
  }, [queryParams.hyperlinks]);

  useDeepCompareEffect(() => {
    if (!saveFilterLoad) {
      generateURLFilters(filters);
    }
    const updatedFilters = generateTablebody(filters);
    conditionalFilterReplacement(updatedFilters);
    setNewFilters(updatedFilters);
  }, [filters, saveFilterLoad]);

  useDeepCompareEffect(() => {
    if (active.id === osmviewid) {
      const viewFilters = isEmpty(active.filters) ? filters : active.filters
      dispatch(saveFilters({
        type: type,
        subtype: subtype,
        filter: viewFilters
      }));
      setSaveFilterLoad(false);
      // let additionalFilters = generateTablebody(viewFilters);
      // refreshTable({ pageSize: rowsPerPage, page: 0, additionalFilters })
    }
  }, [osmviewid, active.id]);

  useDeepCompareEffect(() => {
    if (osmviewid && active.id === osmviewid) {
      const viewFilters = isEmpty(active.filters) ? osmFilters : active.filters;
      const osmURLFilters = isJsonParsable(queryParams.osmfilters);
      const args = getFilterArgs(viewFilters);
      if (queryParams.activity === "tab" && !isEqual(localFilters, args)) {
        setLocalFilters(args);
        changeQueryParams({
          activity: "filters",
          osmfilters: JSON.stringify(args)
        });
        updateURLFilters(args);
      }

      if (queryParams.activity === "reset") {
        setLocalFilters(args);
        changeQueryParams({
          activity: "filters",
          osmfilters: JSON.stringify(args)
        });
        updateURLFilters(args);
      }

      if (queryParams.activity === "filters" && isEmpty(osmURLFilters)) {
        setLocalFilters(osmURLFilters)
        updateURLFilters(osmURLFilters);
      }

      if (queryParams.activity === "filters" && !isEmpty(osmURLFilters) && !isEqual(localFilters, osmURLFilters)) {
        setLocalFilters(osmURLFilters)
        updateURLFilters(osmURLFilters);
      }
      
      if(!queryParams.activity && !isEmpty(osmURLFilters) && !isEqual(localFilters, osmURLFilters)) {
        setLocalFilters(osmURLFilters);
        const siteids = map(sites, 'st_site_num');

        changeQueryParams({
          activity: "filters",
          osmglobal: JSON.stringify({
            regions,
            sites: siteids,
            business: BU,
            countries
          })
        });
      }
      setSaveFilterLoad(false);
    }
  }, [osmviewid, active.id, queryParams.activity, queryParams.osmfilters, localFilters]);


  useEffect(() => { 
    return () => {
      dispatch(resetFilters({
      type: type,
      subtype: subtype,
    }));}
  }, [])

  const refreshTable = ({ pageSize, page, additionalFilters }) => {
    hideDetailColumns(tableRef);
    const reqBody = {
      body: {
        fetchEndPoint,
        tablebody: {
          ...tableBody,
          ...tableFilters,
          ...additionalFilters
        },
        pageSize,
        page,
        tableName,
        type: type
      },
      language: localStorage.getItem('i18nextLng')
    }
    setPage(page);
    changeQueryParams({
      pageSize,
      page
    });
    dispatch(getTableData(reqBody));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    refreshTable({ pageSize: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    setRowsPerPage(pageSize);
    setPage(0);
    refreshTable({ pageSize: pageSize, page: 0 });
  };

  const onRowSelection = (rowData, rowObj) => {
    rowData = find(shipments, rowObj);

    const tempData = osmShipmentData?.shipments?.map(data => {
      if (rowData?.shipmentNum == data.shipmentNum) {
        return {
          ...data, ...columns.columnConfiguration(data),
          tableData: { ...data.tableData, checked: true }
        }
      } else {
        return {
          ...data, ...columns.columnConfiguration(data),
          tableData: { ...data.tableData, checked: false }
        }
      }
    }) || [];

    setTableData({ ...(osmShipmentData || {}), shipments: tempData });
    setSelectedRow(rowData);
    changeQueryParams({
      shipment: rowData?.shipmentNum
    })
  }

  const shouldCallAPI = ({
    tableFilters,
    osm_sites_loading,
    selectedSites,
    saveFilterLoad    
  }) => {
    let filtersCheck = generateTablebody(filters);
    filtersCheck = conditionalFilterReplacement(filtersCheck);
    filtersCheck = isEmpty(filtersCheck?.sites) ? { ...filtersCheck, sites: global?.sites } : filtersCheck;
    const filterargs = getFilterArgs(filters);
    const condition1 = !isEmpty(tableFilters) && (!osm_sites_loading) && (selectedSites !== null) && (!saveFilterLoad);
    const osmURLFilters = isJsonParsable(queryParams.osmfilters);
    const condition2 = isEqual(filterargs, osmURLFilters);
    const condition3 = isEqual(tableFilters, filtersCheck);
    setUrlFilters({
      ...osmURLFilters,
      sites: global?.sites,
      business: global?.business
    });
    
    return (condition1 && condition2 & condition3) || forceRefresh;
  }

  useDeepCompareEffect(() => {
    setFirstLoad(true);
    hideDetailColumns(tableRef);

    if (!firstLoad) return;
    
    if(shouldCallAPI({
      tableFilters,
      osm_sites_loading,
      selectedSites,
      saveFilterLoad
    })) {
      const urlPageSize = parseInt(queryParams.pageSize) || 50; 
      const filtersChanged = !isEqual({
        ...(isJsonParsable(queryParams.osmfilters) || {}),
        sites: global?.sites,
        business: global?.business
      }, urlfilters);
      const urlPageNo = filtersChanged ? 0 : parseInt(queryParams.page) || 0; 

      setPage(urlPageNo);
      setRowsPerPage(urlPageSize)
      const reqBody = {
        body: {
          fetchEndPoint,
          tablebody: { ...tableBody, ...tableFilters },
          pageSize: urlPageSize,
          page: urlPageNo,
          tableName,
          type: type
        },
        language: localStorage.getItem('i18nextLng')
      };
      changeQueryParams({
        page: urlPageNo,
        pageSize: urlPageSize,
      });
      dispatch(getTableData(reqBody));
      // const count = Object.values(newFilters).every((value) => isEmpty(value));
      // setFilterCount(count);
    }

    // dispatch(saveFilters({ type: type, subtype: subtype, filter: filters }));
  }, [newFilters, localStorage.getItem('i18nextLng'), osm_sites_loading, selectedSites, firstLoad, saveFilterLoad, tableBody]);

  useEffect(() => {
    if (!osm_sites_loading && global?.sites?.length) {
      setSelectedSites(global.sites);
    }
  }, [global?.sites, firstLoad])

  useDeepCompareEffect(() => {
    const finalCols = isEmpty(active.columns) ? columns.columnOrder: active.columns;
    let temp = {} ;
    columns.columnOrder.map(d => {
      temp[d.field] = d;
    });
    let translated = finalCols?.map(item => {
      return {
        ...temp[item.field],
        ...item,
        title: t(item.title),
      }
    });
    const diffColumns = isEmpty(active.columns) ? [] : differenceBy(columns.columnOrder, finalCols, 'field');
    
    if (diffColumns.length) {
      const translated2 = diffColumns.map(item => {
        return {
          ...item,
          title: t(item.title)
        }
      });
      translated = [...translated, ...translated2];
    }
    setcolumnsOrder(translated);
    // dispatch(saveFilters({ type: "osm", subtype: "osmShipments", filter: filters }));
  }, [localStorage.getItem('i18nextLng'), active.columns]);


  useEffect(() => {
    const urlShipment = queryParams.shipment;
    let urlShipIndex = 0
    
    const tempData = osmShipmentData?.shipments?.map((data, index) => {
      if (data?.shipmentNum === urlShipment) {
        urlShipIndex = index;
        return { ...data, ...columns.columnConfiguration(data), tableData: { checked: true } }
      }
      if (index == 0) {
        return { ...data, ...columns.columnConfiguration(data), tableData: { checked: true } }
      }
      return {
        ...data,
        ...columns.columnConfiguration(data)
      }
    }) || [];
    if (urlShipIndex > 0) {
      tempData[0].tableData = undefined;
    }
    setTableData({ ...(osmShipmentData || {}), shipments: tempData });
    setSelectedRow(tempData[urlShipIndex || 0]);
    if (tempData?.length) {
      changeQueryParams({
        shipment: tempData[urlShipIndex || 0]?.shipmentNum
      });
    }
  }, [osmShipmentData, localStorage.getItem('i18nextLng')]);

  const updateColumns = (columns) => {
    dispatch(activateOSMView({
      ...active,
      columns
    }))
    setcolumnsOrder(columns);
  }

  return (
    <Grid className={classes.root}>
      <OsmTableActionsBar
        columns={cloneDeep(columnsOrder)}
        columnsAction={updateColumns}
        selectedRow={selectedRow}
        type={type}
        subtype={subtype}
        tableLoading={osmDataLoading}
        fetchEndPoint={fetchEndPoint}
        refreshOsmTable={() => refreshTable({ pageSize: rowsPerPage, page })}
      />
      
      <div id="scrollIntoView" ref={setRef} />
      
      <OsmFilterBox
        type={type}
        tableName={subtype}
        defaultColumns={columns?.columnOrder}
        columnOrder={columnsOrder}
        filterBody={osmFilters}
        hide={true}
        // filterCount={filterCount}
        resetTableFilters={resetTableFilters}
        hyperlinkClick={hyperlinkClick} 
        setHyperlinkClick={setHyperlinkClick}
      />

      <Grid className={classes.table} data-testid="osmMainTable">
        {
          activeViews.length === 0 && !loading ?
            <Alert severity="warning" color="error">
              {t("no_view_error_message")}
            </Alert> :
            <MaterialTable
              isLoading={osmDataLoading}
              key={shipments?.length}
              localization={{
                body: {
                  emptyDataSourceMessage: osmDataLoading ? t("please_wait_while_the_data_is_loading") : t("no_records_to_display")
                }
              }}
              tableRef={tableRef}
              components={{
                Container: props_ => <Paper {...props_} elevation={0} />,
                OverlayLoading: props_ => <LinearProgress className={paginationClasses.progress} />,
                Pagination: props_ => (<TablePagination
                  showFirstButton
                  showLastButton
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={totalRecordsData || 0}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  classes={paginationClasses}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count} Records`}
                  SelectProps={{ classes: { icon: paginationClasses.selectIcon }, 'data-testid': 'osmRowsPerPageSelect', SelectDisplayProps: { 'data-testid': "osmRowsOption" } }}
                  data-testid="osmPagination"
                />)
              }}
              columns={cloneDeep(columnsOrder)}
              data={shipments}
              options={{
                ...lightTableOptions,
                selectionProps: (row) => ({
                  color: 'none',
                  inputProps: { 'data-testid': row.shipmentNum }
                })
              }}
              onSelectionChange={(row, data) => onRowSelection(row, data)}
              detailPanel={
                [
                  rowData => ({
                    render: rowData_ => (<OsmSalesOrderDetailsTable shipment={rowData_.shipmentNum} sites={selectedSites} filterBody={tableFilters} />)
                  })
                ]
              }
            />
        }
      </Grid>
    </Grid>
  );
}

export default OsmMainTable;