import { emeaFilterByConstants } from "reduxLib/constants/EMEA/emeaFilterByConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const emeaDistChannelAndSalesOrgService = variables => dispatch => {
  dispatch({
    type: emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_LOADING,
    payload: true
  });
  const { body } = variables;

  return fetch(`${endpoints.emea.dcAndSalesOrgs}?countryIds=${body.countryIds}`, {
    method: "GET",
  })
    .then(d => {
      if (d.status === 200) {
        return d.json()
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "failed"
      }
    })
    .then(d => {
      dispatch({
        type: emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_SUCCESS,
        payload: { DC: d?.distributionChannels, SO: d?.salesOrgChannels, BU: body.bu },
        error: null
      });
      dispatch({
        type: emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_LOADING,
        payload: false,
      });
      dispatch({
        type: emeaFilterByConstants.EMEA_FILTER_BY_DC_SO_FAILED,
        payload: { DC: [], SO: [] },
        error: "failed",
      });
    })
}