import { optionsConstants } from '../constants';
import { transportfilters, distoutboundfilters, distinboundfilters, ordersfilters, networkfilters, stockConstraintReportFilter, osmFilters, 
    emeaCustReturnsFilters, emeaOpenOrdersFilters, emeaOrderCutsAndRejectionsFilters, emeaInvoiceSummaryFilters
    } from "../constdata/filters";
import { get, isEmpty, uniqBy } from "lodash";
import { sortOrder } from 'helpers';

const defaultdata = {
    transport: transportfilters,
    distoutbound: distoutboundfilters,
    distinbound: distinboundfilters,
    orders: ordersfilters,
    network: networkfilters,
    stockConstraintReport: stockConstraintReportFilter,
    osmShipments: osmFilters,
    custReturns: emeaCustReturnsFilters,
    openOrders: emeaOpenOrdersFilters,
    cutsAndRejections: emeaOrderCutsAndRejectionsFilters,
    invoiceSummary: emeaInvoiceSummaryFilters
}

const initialState = {
    showfavs: true,
    theme: "dark",
    filters: {}
}

const getUniqColumns = ({ columns, defaultColumns }) => {
    const uniqOrderField = columns?.map(d => d.field);
    let uniqCols = uniqBy([...(defaultColumns || []), ...(columns || [])], "field")
    uniqCols = uniqCols.map(d => {
        const found = columns.find(col => d.field === col.field)
        return {
            hidden: found?.hidden || false,
            ...d
        }
    })
    return sortOrder({ data: uniqCols, order: uniqOrderField, key: "field" });
    // This block preserves all the styling of the columns along with the order , hidden param and order of the columns need to be presered - 
    // Task 38908 
}

const optionsReducer = (state = initialState, action) => {

    const { filter, subtype } = action.payload || {};
    let filters = get(state, `filters.${subtype}`, null);
    const defaultFilters = defaultdata[subtype];

    if (!filters) {
        filters = defaultFilters;
    }
    switch (action.type) {
        case optionsConstants.SHOW_HIDE_FAVOURITES:
            return { ...state, showfavs: action.payload }

        case optionsConstants.SET_FILTERS:
            const { defaultViewsFilters = {} } = action.payload
            const spreadFilters = isEmpty(defaultViewsFilters) ? state.filters : defaultViewsFilters
            return {
                ...state,
                filters: {
                    ...spreadFilters,
                    [subtype]: {
                        ...defaultFilters,
                        ...filters,
                        ...filter
                    }
                }
            }
        case optionsConstants.RESET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [subtype]: {
                        ...defaultFilters,
                        ...(action?.payload?.manualClearFilters || {})
                    }
                }
            }

        case optionsConstants.SAVE_COLUMN_STATE:
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [subtype]: getUniqColumns(action?.payload)
                }
            }
        case optionsConstants.RESET_COLUMN_STATE:
            let tempCols = state?.columns;
            delete tempCols?.[subtype];
            return {
                ...state,
                columns: {
                    ...tempCols
                }
            }

        case optionsConstants.SET_VIEW_BY:
            return {
                ...state,
                showTabsBy: action.payload
            }

        case optionsConstants.SET_TABLE_BODY:
            const { body, tableName } = action.payload;
            return {
                ...state,
                tablebody: {
                    [tableName]: body
                }
            }

        default:
            return state
    }
}

export default optionsReducer;
