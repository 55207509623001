import React from 'react';
import { createBrowserHistory } from 'history';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import configureStore from './store'; //Store Configuration
import Routes from './theme/Routes';
import './assets/scss/index.scss';
import { appTheme, appLightTheme } from '../src/theme';
import ThemeContext from 'context/ThemeContext';

const store = configureStore();
const browserHistory = createBrowserHistory();

const App = () => {
  const [theme, setTheme] = React.useState(appTheme);
  const themeType = React.useMemo(() => ({
    theme, setTheme, light: appLightTheme, dark: appTheme
  }), [theme, setTheme, appLightTheme, appTheme]);

  return (
    <>
      <Provider store={store}>
        <ThemeContext.Provider value={themeType}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ThemeProvider>
        </ThemeContext.Provider>
      </Provider>
    </>
  );
};

export default App;
