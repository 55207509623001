import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";

export const OSMStackErrorColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNum",  
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },{
      title: 'message_num', field: "errorCode",  
      render: rowData => {
        return <CustomCellRender id="errorCode" value={rowData.errorCode} />
      }
    },{
      title: 'message_type', field: "errorType",  
      render: rowData => {
        return <CustomCellRender id="errorType" value={rowData.errorType} />
      }
    },{
      title: 'error_message', field: "errorMessage",  
      render: rowData => {
        return <CustomCellRender id="errorMessage" value={rowData.errorMessage} />
      }
    },{
      title: 'line_content', field: "additionalInfo",  
      render: rowData => {
        return <CustomCellRender id="additionalInfo" value={rowData.additionalInfo} />
      }
    }
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}