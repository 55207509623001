import React from "react";
import { useTranslation } from "react-i18next"
import { IconButton, makeStyles, ClickAwayListener, Typography, Box, Grid, useMediaQuery, useTheme } from "@material-ui/core"
import { Refresh as RefreshIcon, FilterList as FilterListIcon } from '@material-ui/icons';
import DownloadTable from "./DownloadTable";
import FilterViewsMenu from '../FilterViewsMenu';
import { ColumnManager } from "./ColumnManager";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { get } from "lodash";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    buttonPadding: {
        [theme.breakpoints.down("sm")]: {
       paddingLeft:theme.spacing(1.125),
       paddingRight:theme.spacing(1.125)
        }
    },
    inline: {
        display: 'flex'
    },
    justify: {
        justifyContent: 'flex-end',
        "@media (max-width: 599px)": {
            justifyContent: 'flex-start',
        }
    },
    order: {
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0)
        }
    }
}));


const TableActionsFilters = (props) => {

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const tabledata = useSelector(({ tabledata }) => tabledata);

    const { refreshTable, handleClose, handleClick, filters, downloads, filterBody, subtype, type, columns, columnsAction, defaultColumns } = props;
    const tableLoading = get(tabledata, [ type, subtype, "loading"], false);
    
    const classes = useStyles();
    const { t } = useTranslation();

    return (<Grid container className={clsx(classes.inline,classes.justify)} color="primary" justify='flex-start' data-testid="tableactions">
        
        <Grid item className={clsx(classes.root, classes.order)}>
            <Box textAlign='center' aria-label="Manage Columns" color="primary">
                <ColumnManager columns={columns} columnsAction={columnsAction} />
            </Box>
        </Grid>
        <Grid item className={classes.root}>
            <Box textAlign='center' aria-label="Refrsh Table" color="primary">
                <IconButton className={classes.buttonPadding} onClick={refreshTable} aria-label="Refrsh Table" color="primary" disabled={tableLoading}>
                    <RefreshIcon />
                </IconButton>
                { !isMobile && <Typography data-testid="RefrshTable" variant='body2'>{t('refresh')}</Typography>}
            </Box>
        </Grid>
        <DownloadTable downloadConfig={downloads} />
        <Grid item className={classes.root}>
            <Box textAlign='center' aria-label="Table Views" color="primary">
                <FilterViewsMenu defaultColumns={defaultColumns} filterBody={filterBody} type={type} tableName={subtype} />
            </Box>
        </Grid>
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.root}>
                <Box textAlign='center' aria-label="Filters" color="primary">
                    <IconButton className={classes.buttonPadding} onClick={handleClick} aria-label="Filters" color="primary" data-testid="filter-button">
                        <FilterListIcon />
                    </IconButton>
                    { !isMobile && <Typography data-testid="TableFilters" variant='body2'>{t('filters')}</Typography>}
                </Box>
                {
                    filters
                }
            </div>
        </ClickAwayListener>
    </Grid>)
}

export default TableActionsFilters;
