import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import UnexpectedIcon from 'assets/images/unexpected.svg';
import UnauthorizedIcon from 'assets/images/unauthorized.svg';

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: 'block',
    margin: `${theme.spacing(12.5)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
    padding: theme.spacing(6),
    borderRadius: theme.spacing(.75),
    color: theme.palette?.primary?.contrastText,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    backgroundColor: theme.palette?.card?.default,
  },
   errorTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: theme.palette?.text?.primary,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    }
  },
  icon: {
    animation: "beat 2s infinite alternate",
    transformOrigin: 'center',
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
    }
  },
  errorMsg: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    }
  },
  icon2: {
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
    }
  }
}));

const ErrorHandlerPage = ({ errMessage, errTagline }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Box className={classes.errorContainer} data-testid="errorHandlerPage">
      { errTagline ? 
        <img className={classes.icon2} src={UnauthorizedIcon} alt="unauthorized-icon" /> :
        <img className={classes.icon} src={UnexpectedIcon} alt="unexpected-icon" />
      }
      <Typography className={classes.errorTitle}> { errTagline ? errTagline : t('well_this_is_unexpected') } </Typography>
      <p className={classes.errorMsg} data-testid="errorHandlerText"> { errMessage } </p>
    </Box>
  )
}

export default ErrorHandlerPage;