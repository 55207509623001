import laoChartConstants from "reduxLib/constants/LAO/laoChartConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";


export const laoGetCharts = variables => dispatch => {
    const { subtype, body } = variables;

    dispatch({
        type: laoChartConstants.LAO_CHARTS_LOADING,
        payload: true,
        subtype: subtype,
    });

    return fetch(endpoints.lao.summary, {
        method: "POST",
        body: {
            ...body,
            region: "LAO",
            subtype
        },
        signal: variables.controller.signal
    })
        .then(d => {
            if (d.status === 200) {
                return d.json();
            } else {
                throw "lao_charts_fetch_failed";
            }
        })
        .then(d => {
            dispatch({
                type: laoChartConstants.LAO_GET_EXPORT_CHART_SUCCESS,
                payload: d[subtype],
                subtype,
                status: { status: "success", message: "lao_charts_loaded_successfully" }
            });
        })
        .catch(err => {
            if (err?.name === "AbortError") {
                return;
            }
            dispatch({
                type: laoChartConstants.LAO_GET_EXPORT_CHART_FAILED,
                payload: [],
                subtype,
                status: { status: "error", message: "lao_charts_fetch_failed" }
            });
        });
}
