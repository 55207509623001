import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EMEABreadcrumb from "components/EMEA/EMEA_Common/EMEABreadcrumb";
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { defaultOrderEntryDate } from "configs/appConstants";
import EmeaOrderEntryTableByCustomer from "components/EMEA/EmeaOrderEntryTableByCustomer";
import { endpoints } from 'helpers';
import { chartsHeight as height } from 'helpers/styleHelpers';
import EMEAChartCardLarge from "components/EMEA/EMEA_Common/EMEAChartCardLarge";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
    minHeight: theme.spacing(25),
    borderRadius: theme.spacing(1),
    background: theme.palette.card.default,
  },
  title: {
    marginRight: "auto",
    paddingBottom: theme.spacing(2),
    fontSize: theme.spacing(2),
    textTransform: "uppercase",
  },
  listTitle: {
    fontSize: theme.spacing(1.8)
  },
  cardContent: {
    marginBottom: 0,
    paddingTop: 0
  },
  cardheader: {
    marginBottom: 0,
    paddingBottom: 0
  }
}));

const EMEAOrderEntry = () => {
  const classes = useStyles({ height });
  const { t } = useTranslation();
  const setMultiQuery = useMultiSetQuery();
  const [params] = useSetQuery();

  const currency = localStorage.getItem("currency") || "USD";
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    if (params.loadView) {
      setMultiQuery({
        documentDate: JSON.stringify(
          params.loadView ? defaultOrderEntryDate : params.documentDate
        ),
        loadView: undefined
      });
    }
  }, [params.loadView, params.documentDate]);

  const xLabel = `${t("date")}`;

  const chartsList = [
    {
      title: t("by_value"),
      subtype: "SUBTYPE_EMEA_ORDER_ENTRY_DATE_ORDER_VALUE",
      currency,
      innerChartProps: {
        subtype: "SUBTYPE_EMEA_ORDER_ENTRY_MATERIAL_GROUP_ORDER_VALUE",
        xLabel: t('material_hierarchy'),
        xKey: "stateDesc",
        yLabel: `${t('order_value')} (${currency})`,
      },
      showXAxisLabel: !isMobile,
      colorParams: { colorBy: "xKey", colorPalette: "set7" },
      textParams: { viewMore: t("view_more_by_material_group") },
      yLabel: `${t('order_value')} (${currency})`,
      xLabel
    },
    {
      title: t("by_quantity"),
      subtype: "SUBTYPE_EMEA_ORDER_ENTRY_DATE_ORDER_QTY",
      colorParams: { colorBy: "xKey", colorPalette: "set7" },
      showXAxisLabel: !isMobile,
      innerChartProps: {
        subtype: "SUBTYPE_EMEA_ORDER_ENTRY_MATERIAL_GROUP_ORDER_QTY",
        xLabel: t('material_hierarchy'),
        xKey: "stateDesc",
        yLabel: t('cases')
      },
      yLabel: t('cases'),
      textParams: { viewMore: t("view_more_by_material_group") },
      xLabel,
      unit: "CS"
    },
    {
      title: t("by_orders"),
      subtype: "SUBTYPE_EMEA_ORDER_ENTRY_DATE_ORDER_COUNT",
      showXAxisLabel: !isMobile,
      innerChartProps: {
        subtype: "SUBTYPE_EMEA_ORDER_ENTRY_ENTRY_METHOD_ORDER_COUNT",
        xKey: "stateDesc",
        xLabel: t('entry_method'),
        yLabel: `${t('order_count')}`,
      },
      colorParams: { colorBy: "xKey", colorPalette: "set8" },
      yLabel: `${t('order_count')}`,
      xLabel,
      textParams: { viewMore: t("view_more_by_entry_method") },
      unit: "ORDERS"
    }
  ];


  return (
    <>
      <EMEABreadcrumb pageName={"order_entry"} />
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardheader}
          title={<Typography className={classes.title} variant="h3"
            component={"h3"}>{t("order_entry_representations")}</Typography>}
        />
        <CardHeader
          className={classes.cardheader}
          title={<Typography className={classes.listTitle} variant="h3"
            component={"h3"}>{t("daily_order_entry")}</Typography>}
        />
        <CardContent className={classes.cardContent}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
            m={1}
            mb={1}
          >
            {
              chartsList.map(d => {
                return (
                  <Box p={1} m={1}>
                    <EMEAChartCardLarge
                      {...d}
                      chart="trendline"
                      trendline
                    />
                  </Box>
                )
              })
            }
          </Box>
          <EmeaOrderEntryTableByCustomer
            type={"emea"}
            subType={"EMEA_ORDER_ENTRY_SUMMARY_BY_CUSTOMER"}
            tableName={"orderEntry"}
            fetchEndPoint={endpoints.emea.emeaTable}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default EMEAOrderEntry;