export const emeaLocationConstants = {
  EMEA_LOCATIONS_LOADING: 'EMEA_LOCATIONS_LOADING',
  EMEA_LOCATIONS_SUCCESS: 'EMEA_LOCATIONS_SUCCESS',
  EMEA_LOCATIONS_FAILED: 'EMEA_LOCATIONS_FAILED',

  EMEA_MAP_COUNTRY: 'EMEA_MAP_COUNTRY',
  EMEA_MAP_LOCATION: 'EMEA_MAP_LOCATION',

  EMEA_LOCATION_FAV_LOADING: 'EMEA_LOCATION_FAV_LOADING',
  EMEA_LOCATION_FAV_SUCCESS: 'EMEA_LOCATION_FAV_SUCCESS',
  EMEA_LOCATION_FAV_FAILED: 'EMEA_LOCATION_FAV_FAILED',
}