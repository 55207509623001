import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography, Grid, makeStyles, Box, useMediaQuery, useTheme } from '@material-ui/core';
import KCChip from "components/common/Chip";
import { connect } from "react-redux";
import ChipsElements from './ChipsBar';
import Snack from "components/common/Helpers/Snack";
import { get, isEmpty } from "lodash";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import {
    setBusinessLocalAction
} from '../../reduxLib/services';
import { businessUnits as defaultBusinessUnits } from "configs/appConstants"
import { defaultShowTabsBy } from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    item: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(3),
        borderLeft: `2px solid ${theme.palette.white}`,
        [theme.breakpoints.down("sm")]:{
            paddingLeft: 0,
            paddingRight: 0,
            borderLeft: 'none',
            marginTop: theme.spacing(1),
        }
    },
    othertext: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(0.8),
        fontSize: theme.spacing(1.5)
    },
    chip: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("xs")]:{
            maxWidth:theme.spacing(15)
        }
    },
    customSpace: {
        paddingRight: 8,
        [theme.breakpoints.down("sm")]:{
            marginTop: theme.spacing(1),
        }
    },
}));

const ChipFilter = ({ showTabsBy, selections, filter, rootTab, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [snack, setSnack] = React.useState({
        open: false,
        severity: null,
        message: null
    });

    const businessUnits = rest.businessUnits?.length === 0 ? defaultBusinessUnits : rest.businessUnits;

    const deleteBusinessUnit = (value) => {
        if (businessUnits.length === 2) {
            rest.setBusinessLocalAction(businessUnits.filter(d => d !== value))
        } else {
            setSnack({
                open: true,
                severity: "warning",
                message: t('atleast_one_business_unit_needs_to_selected')
            });
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };

    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))
    return (
        <Grid container direction="row" data-testid="pwatestid-test" >
            <Grid item className={classes.customSpace}>
                {
                    <Box alignItems="center">
                        { !isMobile && <Typography className={classes.othertext} variant="h6" color="primary">{t('region')}</Typography>}
                        <KCChip
                            disableRipple
                            className={classes.chip}
                            color="primary"
                            label={"North America"}
                            variant="outlined"
                        />
                    </Box>
                }
            </Grid>
            <Grid item className={classes.item}>
                {
                    !isEmpty(businessUnits) &&
                    <Box alignItems="center">
                        {!isMobile && <Typography className={classes.othertext} variant="h6" color="primary">{t('business')}</Typography>}
                        {
                            businessUnits.map(d => <KCChip id={`filter-${d}`}
                                disableRipple
                                className={classes.chip}
                                color="primary"
                                deleteIcon={<HighlightOffRoundedIcon />}
                                label={t(d)}
                                variant="outlined"
                                onDelete={() => deleteBusinessUnit(d)}
                            />)
                        }
                    </Box>
                }
            </Grid>
            <Grid item className={classes.item}>
                <Box alignItems="center" data-testid="locationFilterChips">
                    {!isMobile && <Typography className={classes.othertext} variant="h6" color="primary">{t('location')}</Typography>}
                    <ChipsElements filter={selections.locations} tabtype={"locations"} />
                </Box>
            </Grid>
            <Grid item className={classes.item}>
                <Box alignItems="center" data-testid="cusoFilterChips">
                    {!isMobile && <Typography className={classes.othertext} variant="h6" color="primary">{t('customers_sales_office')}</Typography>}
                    <ChipsElements filter={selections.customer} tabtype={"customer"} />
                </Box>
            </Grid>
            <Snack {...snack} handleClose={handleClose} />
        </Grid>

    )
}

const mapStatetoProps = (state) => {
    const showTabsBy = defaultShowTabsBy(state);
    return {
        loading: state.sites.loading,
        showhidefavs: state.options.showfavs,
        items: state.items.items || [],
        businessUnits: get(state, "favorites.tempBusinessUnit", []),
        showTabsBy,
        selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
    };
};

const mapDispatchToProps = {
    setBusinessLocalAction,
};

export default connect(mapStatetoProps, mapDispatchToProps)(ChipFilter);