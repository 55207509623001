import React from 'react';
import { useTranslation } from "react-i18next"
import { Grid, Typography, makeStyles } from '@material-ui/core';
import ChartComponent from "components/common/ChartComponent";
import { NetworkOrderChartBuilder } from 'components/common/NetworkOrderChartBuilder';
import Card from "components/common/Card"
import TableComponent from "components/common/TableComponent";
import ItemDetailsTableOutBound, { columns as outBoundDetailsColumns } from "components/common/ItemDetailsTableOutBound";
import { defaultNetworkFilterArgs } from "reduxLib/constdata/filters"
import { tableoptions } from 'helpers/tableStyleOverride';
import { endpoints, defaultShowTabsBy } from "helpers";
import { uniq } from "lodash";
import { defaultNetworkColumns } from "reduxLib/constdata/networkColumns";
import networkfilters from "reduxLib/constdata/networkFilters";
import { viewStyles } from "theme"
import { NetworkChartBuilder } from "components/common/NetworkChartBuilder"
import PerformanceMap from "components/common/PerformanceMap";
import LocationPerformance from "components/common/LocationPerformance";
import ChartCarouselComponent from "components/common/ChartCarouselComponent";
import { get } from "lodash";
import { connect } from "react-redux";
// import { viewByConstants } from "configs/appConstants";
import { generatePath, useHistory,  useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(viewStyles);

export const allowedPlaces = ["network", "mylocation"];

export const conditionalFilterReplacement = (newfilters) => {

    if (newfilters?.orderExecutionBucket?.includes("TRANS_PLAN_PROCESSING")) {
        const transporcessing = ["TRANS_PLAN_PLANNED", "TRANS_PLAN_TENDERED", "TRANS_PLAN_TENDER_REJECTED"]
        newfilters.orderExecutionBucket = uniq([...newfilters.orderExecutionBucket, ...transporcessing]).filter(d => d !== "TRANS_PLAN_PROCESSING");
    }

    if (newfilters?.orderStatusBucket?.includes("ORDER_BLOCKED")) {
        const transporcessing = ["SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION",
            "SO_BLOCKED_TMS_PLANNED_VISIBLE",
            "SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE",
            "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED",
            "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK",
            "SO_BACK_ORDER_BLOCKED"
        ];
        newfilters.orderStatusBucket = uniq([...newfilters.orderStatusBucket, ...transporcessing]).filter(d => d !== "ORDER_BLOCKED");
    }

    if (newfilters?.orderStatusBucket?.includes("ORDER_BLOCK_FREE")) {
        const transporcessing = ["SO_COMPLETELY_CONFIRMED_CUBE", "SO_NOT_COMPLETELY_CONFIRMED_CUBE", "SO_BACK_ORDER_BLOCK_FREE"];
        newfilters.orderStatusBucket = uniq([...newfilters.orderStatusBucket, ...transporcessing]).filter(d => d !== "ORDER_BLOCK_FREE");
    }

    return newfilters
}

export const tableStyleConfig = {
    ...tableoptions,
    rowStyle: (d) => {
        const healthStatusBucket = d.orderHealthAndExecutionHealth ? d.orderHealthAndExecutionHealth : "";
        return {
            ...tableoptions.rowStyle,
            borderLeft: healthStatusBucket === "RED" ? '5px solid #FF4081' : healthStatusBucket === "YELLOW" ? '5px solid #F5B023' : ''
        }
    },
}


const Home = ({ name, prevPageDetails, selections, showTabsBy, ...rest }) => {

    const { type } = rest?.match?.params;

    // const globalFilterSelection = generateHeader({ selections, type, showTabsBy, item: items.filter(d => d["custom"]["id"] === type)?.[0] });

    const height = 160;
    const margins = { top: 10, right: 10, bottom: 40, left: 30, margin: 10 };
    const tableRef = React.createRef();
    const singleLocation = /^\d+$/.test(type);

    const match = useRouteMatch();
    const history = useHistory();

    const classes = useStyles();
    const { t } = useTranslation();

    const perfTableRedirection = (siteNum) => {
        const path = generatePath(match.path, {
            ...match.params,
            page: "distribution",
            tabletype: "outbound",
            showtabsby: "locations",
            type: siteNum
        })
        history.push(path)
    }

    const setDetails = (...args) => {
        const pages = {
            ORDER: 'orders',
            TRANS_PLAN: 'transport',
            DIST: 'distribution',
            TRANS_EXEC: 'transport'
        }
        rest.setDetails(...args, pages[args[1]])
    };

    return (
        <div className={classes.separator} data-testid="networkview" key={type}>
            <Card
                cardtitle={
                    <Typography variant="h1" color="primary" data-testid="liveStatusHome">
                        {t('live_status_text')}
                    </Typography>
                }
            >
                <ChartCarouselComponent chartsArray={[
                    <Grid item xs={12} md={6} lg={3} className={classes.addPadding}>
                        <ChartComponent showLegends
                            showViewDetails
                            {...rest}
                            BuilderComponent={NetworkOrderChartBuilder}
                            name={t('order_management_title')}
                            height={height}
                            margins={margins}
                            setDetails={setDetails}
                            type={type}
                            subtype="ORDER"
                            topage="orders"
                        />
                    </Grid>,
                    <Grid item xs={12} md={6} lg={3} className={classes.addPadding}>
                        <ChartComponent
                            showViewDetails
                            {...rest} height={height}
                            margins={margins}
                            BuilderComponent={NetworkChartBuilder}
                            name={t('transportation_planning_title')}
                            setDetails={setDetails}
                            type={type}
                            subtype="TRANS_PLAN"
                            topage="transport"
                        />
                    </Grid>,
                    <Grid item xs={12} md={6} lg={3} className={classes.addPadding}>
                        <ChartComponent
                            showViewDetails
                            {...rest}
                            height={height}
                            margins={margins}
                            BuilderComponent={NetworkChartBuilder}
                            name={t('distribution_title')}
                            setDetails={setDetails}
                            type={type}
                            subtype="DIST"
                            topage="distribution"
                        />
                    </Grid>,
                    <Grid item xs={12} md={6} lg={3} className={classes.addPadding}>
                        <ChartComponent
                            showViewDetails
                            {...rest}
                            height={height}
                            margins={margins}
                            BuilderComponent={NetworkChartBuilder}
                            name={t('transportation_execution_title')}
                            setDetails={setDetails}
                            type={type}
                            subtype="TRANS_EXEC"
                            charttype="horizontal"
                            topage="transport"
                        />
                    </Grid>]}
                />
            </Card>
            {
                (type === "network" || type === "mylocation") &&
                <PerformanceMap
                    setDetails={setDetails}
                    type={type}
                    perfTableRedirection={perfTableRedirection}
                />
            }
            {
                singleLocation === true && <LocationPerformance 
                perfTableRedirection={perfTableRedirection}
                setDetails={setDetails} type={type} {...rest} />
            }
            <Card>
                <TableComponent {...rest}
                    pageTitle={t('network_breadcrumb_text')}
                    prevPageDetails={prevPageDetails}
                    setDetails={setDetails}
                    pageDetails={{}}
                    tableName="network"
                    title={t('outbound_order_details')}
                    excludeArray={["searchStringList", "guardrailDate"]}
                    searchTextPlaceholder={t('search_helper_text_order_page')}
                    type={type} tableRef={tableRef}
                    ItemDetailSection={ItemDetailsTableOutBound}
                    itemDetailsColumns={outBoundDetailsColumns}
                    defaultFilterArgs={defaultNetworkFilterArgs}
                    columns={defaultNetworkColumns}
                    conditionalFilterReplacement={conditionalFilterReplacement}
                    filterType="networkfilters"
                    fetchEndPoint={endpoints.table.network}
                    tableStyleConfig={tableStyleConfig}
                    filterBody={networkfilters}
                />
            </Card>
        </div>
    );

}

const mapStateToProps = state => {
    const showTabsBy = defaultShowTabsBy(state);
    return {
        stopfavload: state.items.stopfavload,
        selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
        showTabsBy,
        blockOnTabChange: get(state, 'items.blockOnTabChange', false)
    }
}


export default connect(mapStateToProps, null)(Home);
