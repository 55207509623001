import laoFavConstants from "reduxLib/constants/LAO/laoFavConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";

export const laoGetFavourites = variables => dispatch => {
    dispatch({
        type: laoFavConstants.LAO_FAVS_LOADING,
        payload: true
    });

    return fetch(endpoints.lao.favourites, {
        method: "GET",
        signal: variables.controller.signal
    })
        .then(d => {
            if (d.status === 200) {
                return d.json();
            } else {
                throw "lao_fav_fetch_failed";
            }
        })
        .then(d => {
            dispatch({
                type: laoFavConstants.LAO_GET_ALL_FAVS_SUCCESS,
                payload: d,
                error: null
            });
        })
        .catch(err => {
            if (err?.name === "AbortError") {
                return;
            }
            dispatch({
                type: laoFavConstants.LAO_GET_ALL_FAVS_FAILED,
                payload: {},
                status: { status: "error", message: "lao_fav_fetch_failed" }
            });
        });
}

export const laoSetBUFavourite = variables => dispatch => {
    dispatch({
        type: laoFavConstants.LAO_FAVS_LOADING,
        payload: true
    });

    return fetch(endpoints.lao.favBusinessUnits, {
        method: "PUT",
        body: {
            region: "LAO",
            favouriteBusinessUnits: variables
        },
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: laoFavConstants.LAO_FAV_BU_SUCCESS,
                    payload: variables,
                    status: {}
                });
            } else {
                throw "lao_fav_bu_failed";
            }
        })
        .catch(() => {
            dispatch({
                type: laoFavConstants.LAO_FAV_BU_FAILED,
                payload: {},
                status: { status: "error", message: "lao_fav_bu_failed" }
            });
        })
}


export const laoSetSiteFavourite = variables => dispatch => {
    dispatch({
        type: laoFavConstants.LAO_FAVS_LOADING,
        payload: true
    });

    return fetch(endpoints.lao.favSiteNums, {
        method: "PUT",
        body: {
            region: "LAO",
            favouriteSiteNums: variables
        },
    })
        .then(d => {
            if (d.status === 200) {
                dispatch({
                    type: laoFavConstants.LAO_FAV_SITE_SUCCESS,
                    payload: variables,
                    status: {}
                });
            } else {
                throw "lao_fav_sites_failed";
            }
        })
        .catch(() => {
            dispatch({
                type: laoFavConstants.LAO_FAV_SITE_FAILED,
                payload: {},
                status: { status: "error", message: "lao_fav_sites_failed" }
            });
        })
}