import React from 'react';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

export default function PageNotFound() {
    return (
        <div style={{ width: '100%' }} date-testid="not-found-404">
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={1}
                m={1}
                style={{ height: "20vh" }}
            >
                <div style={{ textAlign: "center" }}>
                    <h1>Page Not Found</h1>
                    <h1>
                        <Link to="/">Home</Link>
                    </h1>
                </div>
            </Box>
        </div>
    );
}
