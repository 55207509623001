export default {
    GUARDRAILS_FETCH_SUCCESS: 'GUARDRAILS_FETCH_SUCCESS',
    GUARDRAILS_FETCH_FAILED: 'GUARDRAILS_FETCH_FAILED',
    GUARDRAILS_FETCH_LOADING: 'GUARDRAILS_FETCH_LOADING',
    GUARDRAILS_UPDATE_LOADING: "GUARDRAILS_UPDATE_LOADING",
    GUARDRAILS_UPDATE_FAILED: "GUARDRAILS_UPDATE_FAILED",
    GUARDRAILS_UPDATE_SUCCESS: "GUARDRAILS_UPDATE_SUCCESS",
    GUARDRAILS_REQUEST_ACCESS_SUCCESS: "GUARDRAILS_REQUEST_ACCESS_SUCCESS",
    GUARDRAILS_REQUEST_ACCESS_FAILED: "GUARDRAILS_REQUEST_ACCESS_FAILED",
    GUARDRAILS_GET_REQUESTS_SUCCESS: "GUARDRAILS_GET_REQUESTS_SUCCESS",
    GUARDRAILS_GET_REQUESTS_FAILED: "GUARDRAILS_GET_REQUESTS_FAILED",
    GUARDRAILS_APPROVE_DECLINE_REQUEST_SUCCESS: "GUARDRAILS_APPROVE_DECLINE_REQUEST_SUCCESS",
    GUARDRAILS_APPROVE_DECLINE_REQUEST_FAILED: "GUARDRAILS_APPROVE_DECLINE_REQUEST_FAILED",
    GUARDRAILS_REQUEST_LOADING: "GUARDRAILS_REQUEST_LOADING",
    GUARDRAILS_RESET_REQUEST_ACCESSS: "GUARDRAILS_RESET_REQUEST_ACCESSS"
}