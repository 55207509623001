import React from "react";
import { useTranslation } from "react-i18next"
import { Grid, Typography, makeStyles, Divider } from "@material-ui/core"
import moment from "moment-timezone";
import { calcPercentage } from "helpers";
import { get } from "lodash";
import { tableStyles } from 'theme';
import TransportDetailsTable from '../Table/TransportDetailsTable'

const useStyles = makeStyles(tableStyles);

export const columns = {
    columnOrder: [
        { title: 'equipment_type', field: 'equipmentType', emptyValue: "-" },
        { title: 'trailer_status', field: 'trailerLoadingStatus', emptyValue: "-" },
        { title: 'percent_Of_trailer_loaded', field: 'estimatedLoadTimeMS' },
        { title: 'business_unit', field: 'orderBusinessUnit', emptyValue: "-" },
        { title: 'order_created_in_tms', field: 'tmsOrderCreateDateTime' },
        { title: 'customer_notified', field: 'customerNotifiedDateTime' },
        { title: 'delivery_appt_confirmed_by_customer', field: 'deliveryApptConfirmedDateTimeList' }
    ],
    columnConfiguration: (d) => {
        const e = d.estimatedLoadTimeMS;
        const r = d.remainingLoadTimeMS;
        const trailerStatus = calcPercentage(e, r);

        return {
            equipmentType: get(d, "equipmentType", "-"),
            trailerLoadingStatus: get(d, "trailerLoadingStatus", "-"),
            itemDesc: get(d, "itemDesc", "-"),
            estimatedLoadTimeMS: trailerStatus || "-",
            orderBusinessUnit: get(d, "orderBusinessUnit", "-"),
            tmsOrderCreateDateTime: d?.tmsOrderCreateDateTime ? moment(d.tmsOrderCreateDateTime).tz("US/Central").format(' MMM DD YYYY HH:mm z') : " -",
            customerNotifiedDateTime: d?.customerNotifiedDateTime?.map(d1 => {
                return d1 ? moment(d1).tz("US/Central").format(' MMM DD YYYY HH:mm z') : "-"
            }).join(" | ") || "-",
            deliveryApptConfirmedDateTimeList: d?.deliveryApptConfirmedDateTimeList?.map(d1 => {
                return d1 ? moment(d1).tz("US/Central").format(' MMM DD YYYY HH:mm z') : "-"
            }).join(" | ") || "-"
        }
    }
}

const TransportDetails = ({ data }) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const e = data.estimatedLoadTimeMS;
    const r = data.remainingLoadTimeMS;
    const trailerStatus = calcPercentage(e, r);

    const originArrival = {
        arrivalYMS: data?.originActualArrivalDateTimeYms && moment(data.originActualArrivalDateTimeYms)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalSAP: data?.originActualArrivalDateTimeSap && moment(data.originActualArrivalDateTimeSap)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalFourkites: data?.originActualArrivalDateTime4k && moment(data.originActualArrivalDateTime4k)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalTMS: data?.originActualArrivalDateTimeTms && moment(data.originActualArrivalDateTimeTms)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-'
    }

    const originDeparture = {
        deptYMS: data?.originActualDepartureDateTimeYms && moment(data.originActualDepartureDateTimeYms)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptSAP: data?.originActualDepartureDateTimeSap && moment(data.originActualDepartureDateTimeSap)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptFourkites: data?.originActualDepartureDateTime4k && moment(data.originActualDepartureDateTime4k)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptTMS: data?.originActualDepartureDateTimeTms && moment(data.originActualDepartureDateTimeTms)?.tz(data?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
    }
    const destArrival = {
        arrivalYMS: data?.destnActualArrivalDateTimeYms && moment(data.destnActualArrivalDateTimeYms)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalSAP: data?.destnActualArrivalDateTimeSap && moment(data.destnActualArrivalDateTimeSap)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalFourkites: data?.destnActualArrivalDateTime4k && moment(data.destnActualArrivalDateTime4k)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        arrivalTMS: data?.destnActualArrivalDateTimeTms && moment(data.destnActualArrivalDateTimeTms)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
    }

    const destDeparture = {
        deptYMS: data?.destnActualDepartureDateTimeYms && moment(data.destnActualDepartureDateTimeYms)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptSAP: data?.destnActualDepartureDateTimeSap && moment(data.destnActualDepartureDateTimeSap)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptFourkites: data?.destnActualDepartureDateTime4k && moment(data.destnActualDepartureDateTime4k)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
        deptTMS: data?.destnActualDepartureDateTimeTms && moment(data.destnActualDepartureDateTimeTms)?.tz(data?.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) || '-',
    }


    return (
        <div className={classes.outline} data-testid="transportdetails">
            <div className={classes.expand}>
                <Grid container className={classes.grid} spacing={6}>
                    <Grid item>
                        <div>
                            <Typography data-testid="equipment_type_title" align="left" variant="h5" >{t('equipment_type')}</Typography>
                            <Typography data-testid="equipmentType" align="left" variant="caption" >{data.equipmentType?.toLowerCase() || "-"}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <Typography data-testid="trailer_status" align="left" variant="h5" >{t('trailer_status')}</Typography>
                            <Typography data-testid="trailerLoadingStatus" align="left" variant="caption" >{data.trailerLoadingStatus || "-"}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <Typography data-testid="percent_Of_trailer_loaded" align="left" variant="h5" >{t('percent_Of_trailer_loaded')}</Typography>
                            <Typography data-testid="trailerStatus" align="left" variant="caption" >{t(trailerStatus)}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div>
                            <Typography data-testid="business_unit" align="left" variant="h5" >{t('business_unit')}</Typography>
                            <Typography data-testid="orderBusinessUnit" align="left" variant="caption" >{data.orderBusinessUnit?.toLowerCase() || "-"}</Typography>
                        </div>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.grid} spacing={6}>
                    <Grid item className={classes.item1}>
                        <Typography data-testid="customer_appt_time" align="left" variant="h5" >{`${t('customer_appt_time')}:`}</Typography>
                    </Grid>
                </Grid>
                <Grid container className={classes.grid} spacing={6}>
                    <Grid item className={classes.item}>
                        <div>
                            <Typography data-testid="order_created_in_tms" align="left" variant="h5" >
                                {t('order_created_in_tms')}
                            </Typography>
                            <Typography data-testid="tmsOrderCreateDateTime" align="left" variant="caption" >
                                {
                                    data?.tmsOrderCreateDateTime ? moment(data.tmsOrderCreateDateTime).tz("US/Central").format('MMM DD YYYY HH:mm z') : " -"
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item className={classes.item}>
                        <div>
                            <Typography data-testid="customer_notified" align="left" variant="h5" >
                                {t('customer_notified')}
                            </Typography>
                            <Typography data-testid="customerNotifiedDateTime" align="left" variant="caption" >
                                {
                                    data?.customerNotifiedDateTime?.map(d => {
                                        return d ? moment(d).tz("US/Central").format(' MMM DD YYYY HH:mm z') : "-"
                                    }).join(" , ") || "-"
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item className={classes.item}>
                        <div>
                            <Typography data-testid="delivery_appt_confirmed_by_customer_title" align="left" variant="h5" >
                                {t('delivery_appt_confirmed_by_customer')}
                            </Typography>
                            <Typography data-testid="deliveryApptConfirmedDateTimeList" align="left" variant="caption"  >
                                {
                                    data?.deliveryApptConfirmedDateTimeList?.map(d => {
                                        return d ? moment(d).tz("US/Central").format(' MMM DD YYYY HH:mm z') : "-"
                                    }).join(" , ") || "-"
                                }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container className={classes.grid} spacing={6}>
                    <TransportDetailsTable
                        originArrival={originArrival}
                        originDeparture={originDeparture}
                        destArrival={destArrival}
                        destDeparture={destDeparture}
                    />
                </Grid>
            </div>
        </div>
    )
}

export default TransportDetails;