import { loadDiagramConstants } from "../constants";

const initialState = {
  trailer_dimension_loading: false,
  trailer_dimension: [],
  trailer_dimension_err: null,
  load_diagram_loading: false,
  load_diagram: null,
  send_to_wms_loading: false,
  send_to_wms: null,
  ld_precheck_loading: false,
  ld_precheck: null,
  dlv_comment_loading: false,
  dlv_comment: [],
  dlv_comment_err: null,
}


const loadDiagramReducer = (state = initialState, action) => {
  switch (action.type) {

    case loadDiagramConstants.TRAILER_DIMENSION_LOADING:
      return { ...state, trailer_dimension_loading: action.payload };

    case loadDiagramConstants.TRAILER_DIMENSION_SUCCESS:
    case loadDiagramConstants.TRAILER_DIMENSION_FAILED:
    case loadDiagramConstants.RESET_TRAILER_DIMENSION:
      return { ...state, trailer_dimension: action.payload, trailer_dimension_err: action.error };

    case loadDiagramConstants.LOAD_DIAGRAM_LOADING:
      return { ...state, load_diagram_loading: action.payload };
  
    case loadDiagramConstants.LOAD_DIAGRAM_SUCCESS:
    case loadDiagramConstants.LOAD_DIAGRAM_FAILED:
    case loadDiagramConstants.RESET_SUBMIT_LOAD_DIAGRAM:
      return { ...state, load_diagram: action.payload };
        
    case loadDiagramConstants.SEND_TO_WMS_LOADING:
      return { ...state, send_to_wms_loading: action.payload };
    
    case loadDiagramConstants.SEND_TO_WMS_SUCCESS:
    case loadDiagramConstants.SEND_TO_WMS_FAILED:
    case loadDiagramConstants.RESET_SEND_TO_WMS:
      return { ...state, send_to_wms: action.payload };

    case loadDiagramConstants.PRECHECK_LD_LOADING:
      return { ...state, ld_precheck_loading: action.payload };  

    case loadDiagramConstants.PRECHECK_LD_FAILED:
    case loadDiagramConstants.PRECHECK_LD_SUCCESS:
    case loadDiagramConstants.RESET_PRECHECK_LD:
      return { ...state, ld_precheck: action.payload };
    
    case loadDiagramConstants.DELIVERY_COMMENT_LOADING:
      return { ...state, dlv_comment_loading: action.payload };

    case loadDiagramConstants.DELIVERY_COMMENT_SUCCESS:
    case loadDiagramConstants.DELIVERY_COMMENT_FAILED:
    case loadDiagramConstants.RESET_DELIVERY_COMMENT:
      return { ...state, dlv_comment: action.payload, dlv_comment_err: action.error };
  
    
    default:
      return state;
  }
}

export default loadDiagramReducer;
