import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Grid, Typography, AppBar, Tab, Tabs } from '@material-ui/core';
import { useThemeContext } from 'context/ThemeContext';
import { useTranslation } from "react-i18next";
import { generatePath, useParams, useLocation, useHistory } from "react-router-dom";
import { useRouteContext } from 'context/RouteContext';
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from 'material-table';
import TabPanel from 'components/header/TabPanel';
import { appLightTheme, osmLsrStyles } from 'theme';
import { detailOptions } from 'helpers/tableStyleOverride';
import { viewStackDetailsService, resetLdLogsDownloadService } from 'reduxLib/services/osmLsrStackDetailsService';
import { OSMGenralControlStackColumns } from "../../reduxLib/constdata/osmGeneralControlStackColumns";
import { OSMStopControlStackColumns } from "../../reduxLib/constdata/osmStopControlStackColumns";
import { OSMStackHeaderColumns } from "../../reduxLib/constdata/osmStackHeaderColumns";
import { OSMStackDetailsColumns } from "../../reduxLib/constdata/osmStackDetailsColumns";
import { OSMStackErrorColumns } from "../../reduxLib/constdata/osmStackErrorColumns";
import { OSMLDSubmissionLogColumns } from "../../reduxLib/constdata/osmLDSubmissionLogColumns";
import LoadingPage from "../common/LoadingPage";
import ErrorHandlerPage from "../common/ErrorHandlerPage";
import Snack from "../common/Helpers/Snack";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import get from 'lodash/get';

const useStyles = makeStyles(osmLsrStyles);

const stackTableOptions = {
  ...detailOptions,
  headerStyle: {
    ...detailOptions.headerStyle,
    padding: appLightTheme.spacing(1)
  },
  rowStyle: {
    ...detailOptions.rowStyle,
    backgroundColor: appLightTheme?.palette?.primary?.secondaryBase,
  },
  minBodyHeight: "50vh",
  maxBodyHeight: "70vh"
}

const OsmStackDetails = () => {
  const classes = useStyles();
  const { light, dark, setTheme } = useThemeContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { osmPath } = useRouteContext();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { osmviewid, origin, shipmentNum } = params;
  const { stack, stack_error, stack_loading, ld_download_loading, ld_download } = useSelector(({ osmLsrStackDetail }) => osmLsrStackDetail);

  const stackTables = ["general_control", "stop_control", "stack_header", "stack_detail", "stack_error", "ld_submission_log"];
  const [activeTab, setActiveTab] = useState(stackTables[0]);
  const [columns, setcolumns] = useState([]);
  const [stackType, setStackType] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  })

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();

    // history.push({
    //   ...location,
    //   pathname: generatePath(
    //     osmPath,
    //     {
    //       ...params,
    //       osmviewid: osmviewid
    //     })
    // });
  }

  const handleTabChange = (e, tab) => {
    setActiveTab(tab);
  };

  const handleSnackClose = (event, reason) => {
    setSnack({
      ...snack,
      open: false
    });
  }

  const StackTable = ({ header, rowData }) => {
    return (
      <Box pt={2} className={classes.stackTable} >
        <MaterialTable
          localization={{
            body: { emptyDataSourceMessage: t("no_records_to_display") }
          }}
          columns={header}
          options={stackTableOptions}
          style={{ width: '95vw' }}
          data={rowData}
        />
      </Box>
    )
  }

  const translatedHeaders = (activeStackType, rowMapper) => {
    const activeColumns = activeStackType.columnOrder?.map(data => {
      return {
        ...data,
        title: t(data.title),
      }
    });
    setcolumns(activeColumns);
    setStackType(rowMapper);
  }

  useEffect(() => {
    setRowDetails(get(stack, stackType, []));
  }, [stack, stackType])

  useEffect(() => {
    const body = {
      originRegion: 'NA',
      siteNum: origin,
      shipmentNum: shipmentNum
    }
    dispatch(viewStackDetailsService(body));
  }, [shipmentNum, origin])

  useEffect(() => {
    if (!ld_download_loading && ld_download) {
      setSnack({
        ...snack,
        open: true,
        severity: ld_download === "success" ? "success" : "error",
        message: ld_download === "success" ? t("ld_logs_download_success") : t("ld_logs_download_failed")
      });
      dispatch(resetLdLogsDownloadService());
    } 
  }, [ld_download_loading, ld_download])

  useEffect(() => {
    switch (activeTab) {
      case "general_control":
        return translatedHeaders(OSMGenralControlStackColumns, "stackGCEntries");
      case "stop_control":
        return translatedHeaders(OSMStopControlStackColumns, "stackSCEntries");
      case "stack_header":
        return translatedHeaders(OSMStackHeaderColumns, "stackHdrEntries");
      case "stack_detail":
        return translatedHeaders(OSMStackDetailsColumns, "stackDtlEntries");
      case "stack_error":
        return translatedHeaders(OSMStackErrorColumns, "stackErrorEntries");
      case "ld_submission_log":
        return translatedHeaders(OSMLDSubmissionLogColumns, "submissionLogs");
            
      default:
        return translatedHeaders(OSMGenralControlStackColumns, "stackGCEntries");
    }
  }, [activeTab]);

  useEffect(() => {
    setTheme(light);
    return () => {
      setTheme(dark);
    }
  }, []);

  return (
    <Box mt={6.5} data-testid="osmViewStackInfo">
      <Grid data-testid="viewStackInfoBackNav" className={classes.prevNavigator} onClick={(e) => goBack(e)}>
        <NavigateBeforeIcon />
        <Typography variant='body1'> {t('back_to_docc_results')} </Typography>
      </Grid>

      { !stack_loading ? 
        (!stack_loading && stack_error) ?
          <ErrorHandlerPage errMessage={ t(stack_error, { shipmentNum: shipmentNum }) } errTagline={ stack_error === "osm_restricted" ? t("we_are_sorry") : null } />
        :
          <>
            <Grid className={classes.title}>
              <Typography variant='h2'> {t('view_stack_details_for_shipment_num')} - {shipmentNum} </Typography>
            </Grid>

            <Grid container className={classes.container}>
              <AppBar position="static" color="primary" elevation={0} className={classes.stacksBar}>
              <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  className={classes.tabStackDetails}
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="osm stack tabs"
                >
                  { 
                    stackTables.map((item, index) => {
                      return (
                        <Tab
                          data-testid={`tabid_${item}`}
                          value={item}
                          key={index}
                          label={t(item)}
                        />
                      )
                    })
                  }
                  </Tabs>
              </AppBar>
              { 
                stackTables.map((item) => {
                  return (
                    <TabPanel value={activeTab} index={item} key={item} data-testid={`panel_${item}`} >
                      <StackTable rowData={rowDetails} header={columns} />
                    </TabPanel>
                  )
                })
              }
            </Grid>
          </>
      :
        <LoadingPage />
      }
      <Snack {...snack} handleClose={handleSnackClose}/>
    </Box>
  )

}

export default OsmStackDetails;