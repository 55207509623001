const emeaInvoiceSummaryFilters = {
  searchStringList: {
    type: "text",
    name: "search",
    stringToArray: true,
    data: null
  },
  soldToNumList: {
    type: "text",
    name: "sold_to_party",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeSoldToNumList',
    data: null
  },
  excludeSoldToNumList: {
    type: "text",
    name: "excluded_sold_to_party",
    stringToArray: true,
    data: null
  },
  materialNumberList: {
    type: "text",
    name: "material",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeMaterialNumberList',
    data: null
  },
  excludeMaterialNumberList: {
    type: "text",
    name: "excluded_material",
    stringToArray: true,
    data: null
  },
  documentTypeList: {
    type: "text",
    name: "sales_document_type",
    stringToArray: true,
    data: null
  },
  billingDocTypeList: {
    type: "text",
    name: "billing_document_type",
    stringToArray: true,
    data: null
  },
  documentDateCustom: {
    type: "customdaterange",
    name: "document_date",
    data: null
  },
  documentDateFilter: {
    type: "customdaterange",
    name: "document_date",
    shortName: 'Doc. Date',
    data: null
  },
  originSites: {
    type: "text",
    name: "plant",
    stringToArray: true,
    data: null
  },
  deliveryBlocked: {
    type: "checkboxradio",
    name: "delivery_block",
    defaultValue: null,
    data: [
      { name: "blocked", value: "Y", checked: false },
      { name: "not_blocked", value: "N", checked: false }
    ]
  },
  orderReasonCodeList: {
    type: "text",
    name: "order_reason_code",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeOrderReasonCodeList',
    data: null
  },
  excludeOrderReasonCodeList: {
    type: "text",
    name: "excluded_order_reason_code",
    stringToArray: true,
    data: null
  },
  itemReasonCodeList: {
    type: "text",
    name: "item_reason_code",
    stringToArray: true,
    exclude: true,
    excludeKey: 'excludeItemReasonCodeList',
    data: null
  },
  excludeItemReasonCodeList: {
    type: "text",
    name: "excluded_item_reason_code",
    stringToArray: true,
    data: null
  },
  divisionList: {
    type: "text",
    name: "division",
    stringToArray: true,
    data: null
  },
  corporateCustomerName: {
    type: "text",
    name: "customer",
    data: null
  },
  materialHierarchyDesc: {
    type: "text",
    name: "product_hierarchy",
    data: null
  },
  countryIdList: {
    type: "text",
    name: "country_id",
    stringToArray: true,
    data: null
  },
}

export default emeaInvoiceSummaryFilters;