import React from 'react';
import { makeStyles, Button, Grid, Typography, Divider, IconButton, CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { filterStyles } from "theme";
import { CloseOutlined } from '@material-ui/icons';
import { freqMappings, viewByConstants } from 'configs/appConstants';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getFilterMappings, getTabsData } from 'helpers';
import { isArray } from 'lodash';
import clsx from "clsx";

const useStyles = makeStyles(filterStyles);
const cardStyles = makeStyles(theme => ({
    capitalize: {
        textTransform: "capitalize !important"
    },
    marginLeft: {
        marginLeft: theme.spacing(2)
    },
    break: {
        margin: theme.spacing(2, "auto", 2, "auto"),
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1)
    },
    buttongroup: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function SelfAlertPreview({ open, setOpen, ...props }) {
    const classes = useStyles();
    const classes2 = cardStyles();
    const { t } = useTranslation();
    const { formdata, criterias, saveAlert, customers } = props;
    const { filters, metadata, save_loading } = useSelector(({ selfalerting: { filters, metadata, save_loading } }) => ({ filters, metadata, save_loading }));
    const [mappedTitles, setMapped] = React.useState({});

    React.useEffect(() => {
        const mapped = {};
        let data = metadata?.supportedFields?.map(d => {
            mapped[d.field] = d.fieldText;
        });
        data = metadata?.supportedOperations?.map(d => {
            mapped[d.operation] = d.operationText;
        });
        setMapped(mapped)
    }, [metadata])

    const { alertName, severity, actions } = formdata;

    const customersLabels = React.useMemo(() => {
        const cust = getTabsData(customers, viewByConstants["customer"])
        return cust.map(d => d?.custom?.name);
    }, [customers]);

    const filterMappings = React.useMemo(() => {
        const mappings = getFilterMappings(filters);
        return mappings
    }, [filters]);

    const handleClose = () => {
        setOpen(false);
    };

    const FilterMap = ({ values }) => {
        if (isArray(values)) {
            return <>
                {
                    values.map(d => t(d)).join(", ")
                }
            </>
        }
        return t(values);
    }

    return (
        <div data-testid="previewmodal">
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.modalbody}>
                    <IconButton
                        data-testid="closeicon-preview"
                        onClick={handleClose}
                        className={classes.closeicon}
                        variant="outlined" size="small">
                        <CloseOutlined fontSize="small" />
                    </IconButton>
                    <Typography component="h1">{alertName}</Typography>
                    <Grid container data-testid="selfalertcard">
                        <Grid container className={classes.textcontainer}>
                            <Grid item xs={2}>
                                <Typography component="h4">Frequency of alert:</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography component="h4">
                                    {freqMappings[formdata?.freqOfAlerts?.freqType]}
                                    {
                                        formdata?.freqOfAlerts?.freqType === "CUSTOM" ?
                                            ` ${formdata?.freqOfAlerts?.customTimestamps} ${formdata?.freqOfAlerts?.customTimeZone}`
                                            : null
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.textcontainer}>
                            <Grid item xs={2}><Typography component="h4">Severity of alert:</Typography></Grid>
                            <Grid item xs={10}><Typography component="h4">{severity}</Typography></Grid>
                        </Grid>
                        <Grid container className={classes.textcontainer}>
                            <Grid item xs={2}><Typography component="h4">Send alerts to:</Typography></Grid>
                            <Grid item xs={10}><Typography component="h4">{actions?.[0]?.mailingList.join(", ")}</Typography></Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <Typography component='h2'>Send Alerts when</Typography>
                    {
                        customersLabels?.length > 0 && <Grid container>
                            <Grid item xs={2}>
                                <Typography component="h4">Customers:</Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography component="h4">
                                <FilterMap values={customersLabels} />
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid container className={classes.textcontainer}>
                        {
                            Object.keys(filterMappings).map(d => {
                                return <Grid container>
                                    <Grid item xs={2}><Typography component="h4">{t(d)}:</Typography></Grid>
                                    <Grid item xs={10}>
                                        <Typography component="h4">
                                            <FilterMap values={filterMappings[d]} />
                                        </Typography>
                                    </Grid>
                                </Grid>;
                            })
                        }
                    </Grid>
                    {
                        criterias?.map((d, index) => {
                            return (
                                <>
                                    <Divider className={classes2.break} />
                                    <div style={{ width: '100%' }} className={classes2.capitalize} key={index}>
                                        <Typography component="h3">Criteria {(index + 1)}</Typography>
                                        <Typography component="h3">
                                            <Grid container className={classes.textcontainer}>
                                                <Grid item xs={2}><Typography component="h4">Select Field: </Typography></Grid>
                                                <Grid item xs={10}><Typography component="h4">{mappedTitles[d.field1]}</Typography></Grid>
                                            </Grid>
                                            <Grid container className={classes.textcontainer}>
                                                <Grid item xs={2}><Typography component="h4">Comparison: </Typography></Grid>
                                                <Grid item xs={10}><Typography component="h4">{mappedTitles[d.operation]}</Typography></Grid>
                                            </Grid>
                                            <Grid container className={classes.textcontainer}>
                                                <Grid item xs={2}><Typography component="h4">Comparison With: </Typography></Grid>
                                                <Grid item xs={10}><Typography component="h4">{mappedTitles[d.field2]}</Typography></Grid>
                                            </Grid>
                                            <Grid container className={classes.textcontainer}>
                                                <Grid item xs={2}><Typography component="h4">Additional Time: </Typography></Grid>
                                                <Grid item xs={10}><Typography component="h4">{`${d.timeUnitsToAdd} ${d.timeUnitType}`}</Typography></Grid>
                                            </Grid>
                                            {/* {} | {mappedTitles[d.operation]} | {mappedTitles[d.field2]} | {`${d.timeUnitsToAdd} ${d.timeUnitType}`} */}
                                        </Typography>
                                    </div>
                                </>
                            )
                        })
                    }
                    <div className={classes2.buttongroup}>
                        <Button
                            type="submit"
                            onClick={handleClose}
                            data-testid="selfalert-back"
                            variant="outlined"
                            className={classes.uppercase}
                        >
                            Go back to editing
                        </Button>
                        <div className={classes2.wrapper}>
                            <Button
                                type="submit"
                                onClick={saveAlert}
                                data-testid="selfalert-submit"
                                variant="contained"
                                disabled={save_loading}
                                className={clsx(classes2.marginLeft, classes.uppercase)}
                            >
                                Save Alert
                            </Button>
                            {save_loading && <CircularProgress size={24} className={classes2.buttonProgress} />}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
