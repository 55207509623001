import React, { useRef, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { makeStyles, Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { getChartsService } from "../../reduxLib/services/getChartsService";
import { get, isEmpty } from 'lodash';
import RefreshIcon from '@material-ui/icons/Refresh';
import SkeletonLoader from "./SkeletonLoader";
import Card from "./Card"
import ErrorBox from "./ErrorBox";
import { chartElementStyles } from "../../theme"
import Legend from "./Legend";
import { sum } from 'd3'
import { shouldRefreshRequest } from "helpers";
import { generatePath, NavLink, useRouteMatch } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";
import { useAppContext } from "context/AppContext";

const useStyles = makeStyles(chartElementStyles)

export const getChartSegmentTotal = ({ keys, data }) => {
  let totalSum = 0;
  data.forEach((d) => {
    d.total = sum(keys, k => +d[k]);
    totalSum += d.total;
    return d
  })
  return totalSum
}

export const ChartComponent = ({ businessUnits, sitesKey, showLegends,
  showViewDetails, innerCard, divider,
  BuilderComponent, params, name, type, subtype, dummy, loading,
  getChartsService, charttype = "vertical", topage, ...rest }) => {
  const classes = useStyles();
  const { setDetails, height } = rest;
  const { t } = useTranslation()
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { charts, payload } = rest;

  const { globalFilterSelection, refresh } = useAppContext();

  const body = {}

  if (!isEmpty(globalFilterSelection?.siteNums)) {
    body[sitesKey ? sitesKey : "originSites"] = globalFilterSelection.siteNums
  }

  if (!isEmpty(globalFilterSelection?.customerOrSalesOffice)) {
    body["customerOrSalesOffice"] = globalFilterSelection.customerOrSalesOffice
  }

  const chartRequestBody = {
    body: {
      "region": "NA",
      "subtype": subtype,
      businessUnits,
      ...body,
    }, type, subtype,
    refresh,
    language: localStorage.getItem('i18nextLng'),
    reqtime: new Date()
  }

  const getCharts = () => {
    getChartsService(chartRequestBody);
  }

  const chartsData = charts ? charts.map(d => {
    return {
      ...d,
      blueCount: d.greenCount,
      stateDesc: d.state === 'TRANS_PLAN_CONFIRMED' ? t('carrier_committed') : d.stateDesc,
    }
  }) : [];

  chartsData.totalEntities = chartsData?.reduce((total, { totalCount }) => total + totalCount, 0)

  const [totalCount, setTotalCount] = useState(chartsData.totalEntities)

  const setHeaderTotalCount = (count) => {
    setTotalCount(count)
  }

  const margins = { ...rest.margins, top: 20, left: 50 };
  const horizontalMargins = { ...rest.margins, left: 100 }

  const keys = ["blueCount", "redCount"];

  const colors = ["red", "green"]

  useLayoutEffect(() => {
    if (targetRef.current) {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth - 100,
          height: targetRef.current.offsetHeight - 100
        });
      }
    }
  }, []);

  useDeepCompareEffect(() => {
    if (!rest.blockApi && shouldRefreshRequest(payload, chartRequestBody)) {
      getCharts()
    }
  }, [refresh, globalFilterSelection, localStorage.getItem('i18nextLng')]);

  React.useEffect(() => {
    setTotalCount(chartsData.totalEntities);
  }, [charts]);

  const ActionItems = () => {
    const match = useRouteMatch();

    const gotoPage = React.useMemo(() => {
      return `${generatePath(match.path, {
        ...match.params,
        page: topage,
        tabletype: topage === "distribution" ? "outbound" : undefined
      })}?showDefault=true`;
    }, [topage]);

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
      >
        <Grid item >
          <NavLink className={classes.link} variant="subtitle1" color="primary" data-testid="viewdetails"
            to={gotoPage}
          >
            {t('view_details')}
          </NavLink>
        </Grid>
      </Grid>
    )
  }

  const Title = () => {
    return (
      <Grid data-testid='chartElement'
        container
        justify="space-between"
      >
        <Grid item>
          <Grid data-testid='chartTitle'><Typography variant="h3">{name}</Typography></Grid>
          {chartsData?.totalEntities > 0 && <Typography variant='subtitle1' className={classes.label} data-testid='chartTotalCount' id={subtype}>{`${t('total')}:`} {totalCount}</Typography>}
        </Grid>
        <Grid item className={classes.refreshIcon}>
          <RefreshIcon data-testid="chartRefresh" className={loading ? classes.spining : classes.spin} onClick={() => getCharts()} />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid ref={targetRef} className={classes.root}>
      <Card
        divider
        innercard
        cardtitle={<Title />}
        cardactions={showViewDetails && (!loading && <ActionItems topage loading={loading} dimensions={dimensions} />)}
      >
        {
          loading ? <SkeletonLoader style={{ height: "100%" }} dimensions={dimensions} /> :
            !chartsData ? <ErrorBox message="Unable to Load" dimensions={dimensions} /> :
              <BuilderComponent {...{
                charttype,
                margins,
                horizontalMargins,
                height,
                type,
                subtype,
                chartsData,
                keys,
                colors,
                dimensions,
                setDetails,
                setHeaderTotalCount
              }} />
        }
      </Card>
      {showLegends && <Legend />}
    </Grid>
  );
}
const mapStateToProps = (state, ownProps) => {
  const { subtype, type } = ownProps;

  return {
    charts: get(state.charts.charts, [type, subtype, "data"], []),
    payload: get(state.charts.charts, [type, subtype, "payload"], {}),
    user: state.auth.user,
    loading: get(state.charts.loaders, [type, subtype, "loading"], false),
    businessUnits: get(state, "favorites.tempBusinessUnit", [])
  }
};

const mapDispatchToProps = {
  getChartsService
}



export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);