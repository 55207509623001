import React from "react";
import { useTranslation } from "react-i18next"
import ReactDOM from "react-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from "clsx";
import config from "configs";
import { connect } from "react-redux";
import { capitalizeWord, defaultShowTabsBy } from "helpers";
import ResponsiveWrapper from "../../components/charts/ResponsiveWrapper"
import googleMapStyles from "./GoogelMapStyle";
import { changeTab, setViewBy } from "../../reduxLib/services"
import { appTheme, mapStyles } from "theme";

import behind from "assets/images/icons/behind.svg";
import critical from "assets/images/icons/critical.svg";
import healthy from "assets/images/icons/healthy.svg";
import monitor from "assets/images/icons/monitor.svg";
import nodata from "assets/images/icons/nodata.svg";


const useStyles = makeStyles(mapStyles);


const InfoWindowCustom = (props) => {
    let infoWindowRef = React.createRef();
    const contentElement = document.createElement('div');
    const [prevChild, setPrevChild] = React.useState(null);
    const { children } = props;
    const { t } = useTranslation();

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        setPrevChild(children)
    }, []);

    React.useEffect(() => {
        try {
            if (children !== prevChild) {
                ReactDOM.render(React.Children.only(children), contentElement);
                infoWindowRef.current.infowindow.setContent(contentElement);
            }
        } catch (e) {
            setError(t('map_err_msg'))
        }
    }, [children]);

    return (
        error ?
            <Box justifyContent="center" mt={0}>
                <Typography variant="h2" color="primary" >
                    {error}
                </Typography>
            </Box>
            : <InfoWindow ref={infoWindowRef} {...props} />
    )
}

const MapLegend = ({ loading }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (loading) return null;
    const obj = [{ name: t("healthy"), icon: healthy }, { name: t("monitor"), icon: monitor }, { name: t("behind"), icon: behind }, { name: t("critical"), icon: critical }, { name: t("no_data"), icon: nodata }];
    return (
        <div container id="google-map-legend" className={classes.legend}>
            {
                obj.map(d => {
                    return (
                        <Box
                            display="flex"
                            alignItems="center"
                            p={1}
                            m={1}
                            css={{ height: 50 }}
                        >
                            <Box p={1}>
                                <img src={d.icon} className={classes.image} />
                            </Box>
                            <Box p={0} pr={2}>
                                <Typography variant="subtitle1">{d.name}</Typography>
                            </Box>
                        </Box>
                    )
                })
            }
        </div>
    )
}

const MapContainer = React.memo((props) => {
    const classes = useStyles(props);
    const { t } = useTranslation();
    const { changeTab, loading,
        setDetails, type, locationCoreData,
        showPath, disableMarkerInfo, disableMarkerLegend,
        colorObj, performanceData, setViewBy,
        optionsShowTabsBy, markers, perfTableRedirection
    } = props;
    const [locations, setLocations] = React.useState(props.performanceData || []);
    const [map, setMap] = React.useState(null);

    const [mapState, setMapState] = React.useState({
        selectedPlace: null,
        activeMarker: null,
        showingInfoWindow: false
    });

    const adjustMap = (mapProps, map) => {
        const { google } = props;
        setMap(map);
        const bounds = new google.maps.LatLngBounds();

        const dummyLatLong = [{ "longitude": -122.628625, "latitude": 47.115485 }, { "longitude": -88.133537, "latitude": 30.563955 }];

        locations && [...locations, ...dummyLatLong].forEach(marker => {
            const { latitude, longitude } = marker;
            bounds.extend(new google.maps.LatLng(latitude, longitude));
        });

        map.fitBounds(bounds);
        const legend = document.getElementById("google-map-legend");
        map.controls[props.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
    }

    const closeTooptip = () => {
        setMapState({
            selectedPlace: null,
            activeMarker: null,
            showingInfoWindow: false
        });
    }

    const markerClick = (props, marker, e) => {
        if (!disableMarkerInfo) {
            setMapState({
                selectedPlace: props.place_,
                activeMarker: marker,
                showingInfoWindow: true
            });
        }
    }

    const tooltipClick = async() => {
        if (optionsShowTabsBy === "customer") {
            setViewBy("locations");
        }
        const selectedSiteData = locationCoreData.filter((site) => site.siteNum === mapState.selectedPlace.siteNum)[0]
        await changeTab(selectedSiteData)   
        perfTableRedirection(selectedSiteData.siteNum,type)

    }

    React.useEffect(() => {
        setLocations(props.performanceData);
        if (locations?.length !== props?.performanceData?.length && map) {
            adjustMap(null, map);
        }
    }, [props.performanceData])

    if (loading) {
        return <Box justifyContent="center">
            <Typography variant="h2" color="primary">
                {t('map_loading_msg')}
            </Typography>
        </Box>
    }

    let markerArray = performanceData ? performanceData : locations;

    if (markers) {
        markerArray = markers;
    }

    // if (locations?.length === 0 && !loading) {
    //     return <Box justifyContent="center">
    //         <Typography variant="h2" align='center' color="primary">
    //             No data available
    //         </Typography>
    //     </Box>
    // }

    const svgMarker = (icon) => ({
        url: icon || null,
        fillOpacity: 1,
        strokeWeight: 1.5,
        strokeColor: 'white',
        rotation: 0,
        anchor: new props.google.maps.Point(20, 30),
        scaledSize: new props.google.maps.Size(50, 50),
    });

    const path = locations?.map(({ latitude, longitude }) => { return { lat: latitude, lng: longitude } })

    return (
        <div className={classes.root}>
            <Map google={props.google}
                id="geomap"
                className={classes.map}
                styles={googleMapStyles}
                onReady={adjustMap}
                zoom={15}>
                {showPath && <Polyline
                    path={path}
                    strokeColor={appTheme.palette.legendColors.healthy}
                    strokeOpacity={0.8}
                    strokeWeight={5} />}
                {markerArray.map((d, i) => {
                    const { latitude, longitude, shortName, type, raw_status } = d;
                    return (
                        <Marker
                            key={i}
                            icon={svgMarker(colorObj?.[type]?.[raw_status] || null)}
                            animation={null}
                            title={shortName}
                            onClick={markerClick}
                            place_={d}
                            position={{ lat: latitude, lng: longitude }} />
                    )
                })
                }
                {!disableMarkerInfo && <InfoWindowCustom
                    className={classes.infowindow}
                    marker={mapState.activeMarker}
                    visible={mapState.showingInfoWindow}
                    onClose={closeTooptip}
                >
                    <div className={clsx(classes.tooltip, classes[mapState?.selectedPlace?.status?.toLowerCase()])}>
                        <p className={classes.tooltipname} onClick={tooltipClick}>
                            {
                                `${mapState?.selectedPlace?.shortName || null} ${mapState?.selectedPlace?.siteNum ? `(${mapState.selectedPlace.siteNum})` : ""}`
                            }
                        </p>
                        <p className={clsx(classes.subtitle)}>
                            {
                                `${mapState?.selectedPlace?.hours || 0} ${Math.round(mapState?.selectedPlace?.hours) <= 1 ? t("hour") : t("hours")} ${mapState?.selectedPlace?.actualHours === 0 ? t("no_data") : capitalizeWord(mapState?.selectedPlace?.aheadOrBehind || null)}`
                            }
                        </p>
                    </div>
                </InfoWindowCustom>}
            </Map>
            {!disableMarkerLegend && <MapLegend loading={loading} />}
        </div>

    );
});

const mapStateToProps = state => {
    const showTabsBy = defaultShowTabsBy(state);

    return {
        loading: state.sites.loading || false,
        locationCoreData: state.sites.locations || [],
        optionsShowTabsBy: showTabsBy
    }
}


const mapdispatchtoProps = {
    changeTab: changeTab,
    setViewBy,
}

const MapConenctor = ResponsiveWrapper(connect(mapStateToProps, mapdispatchtoProps)(MapContainer));

export default GoogleApiWrapper({
    apiKey: config.maps.key
})(MapConenctor)

