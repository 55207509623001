import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LightPageLoader from 'assets/images/light_page_loader.png';
import DarkPageLoader from 'assets/images/dark_page_loader.png';

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  },
  loadIcon: {
    width: "25%",
    height: "12vh",
    animation: "animateCloud 10s linear infinite",
    backgroundImage: `url(${theme.type === "dark" ? DarkPageLoader : LightPageLoader})`,
    backgroundRepeat: 'repeat-x',
    backgroundPosition: "0 0",
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '75%',
    }
  },
  loadText: {
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      margin: `0px ${theme.spacing(2)}px`
    }
  }
}));

const LoadingPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  return(
    <Grid container className={classes.loader} data-testid="loadingPage" >
      <div className={classes.loadIcon}></div>
      <Typography className={classes.loadText} data-testid="loadingText"> { t('loading_text') } </Typography>
    </Grid>
  )
}

export default LoadingPage;