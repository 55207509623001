import React, { useState } from 'react';
import { Button, Box, FormControl, Collapse, Select, MenuItem, TextField } from '@material-ui/core';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useTranslation } from "react-i18next"
import moment from "moment-timezone";
import DateRangeIcon from '@material-ui/icons/DateRange';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { formatDate } from "helpers";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { saveFilters } from "reduxLib/services";
import { useStyles } from "./OsmDateRangePicker";

const FormItem = ({ label, updateKey, onChange, rangeValues }) => {
    const classes = useStyles({ filterType: "daterange" });
    const { t } = useTranslation();

    return (
        <Box display="flex" pt={1} width="100%" alignItems="center">
            <Box p={1} flexGrow={1} width={"30%"}>
                {label}
            </Box>
            <Box pr={1}>
                <TextField
                    size="small"
                    variant='outlined'
                    disabled
                    value={t("today")}
                    className={classes.smalltext}
                />
            </Box>
            <Box pr={1} >
                <FormControl variant="outlined" size="small">
                    <Select
                        value={rangeValues?.[updateKey]?.sign}
                        className={classes.select}
                        data-testid={`select-${updateKey}`}
                        onChange={(e) => {
                            onChange({
                                ...rangeValues,
                                [updateKey]: {
                                    ...rangeValues?.[updateKey],
                                    sign: e.target.value,
                                }
                            })
                        }}>
                        <MenuItem value={"+"} data-testid="plus">+</MenuItem>
                        <MenuItem value={"-"} data-testid="minus">-</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box>
                <TextField
                    size="small"
                    InputProps={{
                        inputProps: { 
                            "data-testid": `dayvalues-${updateKey}`,
                            max: 100, 
                            min: 0 
                        }
                    }}
                    variant='outlined'
                    type='number'
                    onBlur={(e) => {
                        if(!e.target.value || e.target.value > 100) {
                            onChange({
                                ...rangeValues,
                                [updateKey]: {
                                    ...rangeValues?.[updateKey],
                                    value: e.target.value > 100 ? 100: 0,
                                }
                            })
                        }
                    }}
                    onChange={(e) => {
                        onChange({
                            ...rangeValues,
                            [updateKey]: {
                                ...rangeValues?.[updateKey],
                                value: parseInt(e.target.value),
                            }
                        })
                    }}
                    value={rangeValues?.[updateKey]?.value} />
            </Box>
        </Box>
    )
}

const defaultRangeValues = {
    from: {
        sign: "+",
        value: 0
    },
    to: {
        sign: "+",
        value: 0
    }
}

const filterKeyAlts = {
    warehouseReadyDateTimeOriginTZ: "warehouseReadyDateTimeOriginTZCustom",
    matAvailDate: "matAvailDateCustom",
    requestedDeliveryDate: "requestedDeliveryDateCustom",
    loadStartDateTimeOriginTZ: "loadStartDateTimeOriginTZCustom",
    documentDateFilter: "documentDateCustom"
}

const OsmCustomDateRangeFilter = ({ type, subtype, filterKey, customSaveFilters }) => {
    const classes = useStyles({ filterType: "daterange" });
    moment.locale(localStorage.getItem('i18nextLng'));
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const filters = useSelector(({ options }) => options?.filters?.[subtype]);

    const storeValue = get(filters, `${filterKey}.data`);
    const storeRangeValue = get(filters, `${[filterKeyAlts[filterKey]]}.data`);

    const [data, setData] = useState(() => storeValue);
    const [open, setOpen] = React.useState(false);
    const [rangeValues, setRangeValues] = React.useState(() => defaultRangeValues);

    const applyDates = (range, updatedKey, nullKey) => {
        setData(range);
        const savePayload = {
            filter: {
                [nullKey]: {
                    ...filters[nullKey],
                    data: null
                },
                [updatedKey]: {
                    ...filters[updatedKey],
                    data: range || null
                }
            }, type, subtype
        };
        if (typeof customSaveFilters === "function") {
            customSaveFilters(savePayload);
            return;
        }
        dispatch(saveFilters(savePayload));
    }

    const openDatePanel = () => {
        setOpen(!open);
    }

    const updateRanges = (values) => {
        setRangeValues({
            ...rangeValues,
            ...values
        })
    }

    React.useEffect(() => {
        if (storeRangeValue) {
            setOpen(true);
            const fromVal = parseInt(storeRangeValue?.from || 0);
            const toVal = parseInt(storeRangeValue?.to || 0);
            updateRanges({
                from: {
                    sign: fromVal < 0 ? "-" : "+",
                    value: fromVal < 0 ? fromVal * -1 : fromVal
                },
                to: {
                    sign: toVal < 0 ? "-" : "+",
                    value: toVal < 0 ? toVal * -1 : toVal
                }
            })
        } else {
            updateRanges(defaultRangeValues);
        }
    }, [storeRangeValue]);

    React.useEffect(() => {
        setData(storeValue);
    }, [storeValue])

    return (
        <>
            <DateRangePicker
                initialSettings={data}
                onApply={(e, item) => {
                    applyDates({
                        startTime: item?.startDate?.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].startTime : ''}`),
                        endTime: item?.endDate?.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].endTime : ''}`)
                    }, filterKey, filterKeyAlts[filterKey]);
                }}
            >
                <Button
                    disabled={open}
                    data-testid='osmCustomDateRangeFilter'
                    disableRipple
                    endIcon={<ExpandMore />}
                    startIcon={<DateRangeIcon />}
                    className={classes.datepicker}
                >
                    {formatDate(data?.startTime) || t("start_date")} to {formatDate(data?.endTime) || t("end_date")}
                </Button>
            </DateRangePicker>
            <Box
                display="flex"
                alignItems="center"
                mt={2}
                className={classes.expander}
                data-testid="expander"
                onClick={openDatePanel}
            >
                <Box mr={1} className={classes.customTitle}>
                    {t("custom_date_filter_in_days")}
                    {
                        !open ? <ExpandMore className={classes.expandButton} /> : <ExpandLess className={classes.expandButton} />
                    }
                </Box>
            </Box>
            <Collapse in={open}>
                <>
                    <FormItem label={t("from")} onChange={updateRanges} updateKey={"from"} rangeValues={rangeValues} />
                    <FormItem label={t("to")} onChange={updateRanges} updateKey={"to"} rangeValues={rangeValues} />
                    <Box justifyContent="flex-end" display={"flex"} pt={1}>
                        <Button variant='outlined' 
                            className={classes.applybutton}
                            size="small"
                            data-testid="applybutton"
                            onClick={() => {
                                applyDates({
                                    from: parseInt(`${rangeValues.from.sign}${rangeValues.from.value}`),
                                    to: parseInt(`${rangeValues.to.sign}${rangeValues.to.value}`)
                                }, filterKeyAlts[filterKey], filterKey);
                            }}
                        >{t("apply")}</Button>
                    </Box>
                </>
            </Collapse>
        </>
    );

}

export default OsmCustomDateRangeFilter;