import { flatten } from "lodash";
import { downloadConstants } from "../constants";
import fetch from "./serviceHelpers";


export const downloadService = variables => async dispatch => {
    const { config, type, subtype, process, detailsProcess, detailsColumns, itemInfoRequired = false, osm = false, emea = false, lao = false } = variables;

    const { totalRecords, body, url, columns } = config || {};

    dispatch({
        type: downloadConstants.LOADING,
        payload: true,
        page: type,
        subtype,
        process,
        columns: columns || [],
        detailsProcess,
        detailsColumns
    });

    const pageSize = 1000;
    let totalPages = Math.ceil((totalRecords || 0) / pageSize);
    const allData = [];

    try {
        for (let i = 1; i <= totalPages; i++) {
            let endpoint = `${url}?pageSize=${pageSize}&pageNum=${i}`;
            const response = await fetch(endpoint, {
                method: "post",
                body: {
                    ...body,
                    itemInfoRequired
                }
            })
            const result = await response?.json();
            if (osm && response.ok && result?.shipments) {
                allData.push([...flatten(result?.shipments)]);
            } else if (emea && response.ok && result?.items) {
                allData.push([...flatten(result?.items)]);
            } else if (lao && response.ok && result?.orders) {
                let tempOrders = result?.orders?.map(d => {
                    let obj = [];
                    if (Object.keys(d)?.includes("orderItems") && d.orderItems?.length > 0 && itemInfoRequired) {
                        d.orderItems.map(orderItem => {
                            obj.push({ ...d, ...orderItem })
                        });
                    } else {
                        obj.push(d)
                    }
                    return obj;
                })
                allData.push([...flatten(tempOrders)]);
            } else if (response.ok && result?.orders) {
                let tempOrders = result?.orders?.map(d => {
                    let obj = [];
                    if (Object.keys(d)?.includes("orderItems") && d.orderItems?.length > 0 && subtype !== "transport") {
                        d.orderItems.map(orderItem => {
                            obj.push({ ...d, ...orderItem })
                        });
                    } else {
                        obj.push(d)
                    }
                    return obj;
                })
                allData.push([...flatten(tempOrders)]);
            } else {
                throw new Error({ message: "Unable to download, Please try again" })
            }
        }
        if (allData.length === totalPages) {
            let data = [];
            allData.map(item => {
                data = [...data, ...item];
                return item;
            });
            dispatch({
                type: downloadConstants.DOWNLOAD_SUCCESS,
                payload: data,
                page: type,
                subtype,
                itemInfoRequired
            });
        } else {
            throw new Error({ message: "Unable to download, Please try again" })
        }
    } catch (err) {
        dispatch({
            type: downloadConstants.DOWNLOAD_FAILURE,
            payload: null,
            page: type,
            subtype
        });
    }
}


export const resetDownload = variables => dispatch => {
    const { type, subtype } = variables;
    dispatch({
        type: downloadConstants.DOWNLOAD_RESET,
        payload: {},
        page: type,
        subtype
    });
}