import { emeaSalesOfficeGroupConstants } from "reduxLib/constants/EMEA/emeaSalesOfficeGroupConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";
import isEmpty from 'lodash/isEmpty';

export const emeaSalesOfficeGroupService = variables => dispatch => {
  dispatch({
    type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_LOADING,
    payload: true
  });
  const { body } = variables;

  return fetch(`${endpoints.emea.salesOfficeGroups}?salesOrg=${body.salesOrg}&distChannel=${body.distChannel}&countryId=${body.countryId}`, {
    method: "GET",
  })
    .then(d => {
      if (d.status === 200) {
        return d.json();
      } else {
        throw "emea_get_sales_office_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_SUCCESS,
        payload: d,
        error: isEmpty(d) ? 'no_data_found' : null
      });
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_FAILED,
        payload: [],
        error: err,
      });
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_LOADING,
        payload: false,
      });
    })
}

export const emeaSalesOfficeGroupReset = variables => dispatch => {
  dispatch({
    type: emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_RESET,
    payload: [],
    error: null
  });
}

export const emeaSOSGFavService = variables => dispatch => {
  dispatch({
    type: emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_LOADING,
    payload: true
  });

  return fetch(endpoints.emea.sosgFavs, {
    method: "PUT",
    body: variables
  })
    .then(d => {
      if (d.status === 200) {
        return d;
      } else if (d.status === 401) {
        throw "emea_restricted";
      } else {
        throw "emea_so_sg_fav_failed";
      }
    })
    .then(d => {
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_SUCCESS,
        payload: variables.favouriteSalesOffices
      });

      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_LOADING,
        payload: false,
      })
    })
    .catch(err => {
      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_FAILED,
        error: err
      });

      dispatch({
        type: emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_LOADING,
        payload: false
      });
    })
}