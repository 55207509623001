import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    top: 65, 
    left: 0, right: 0,
    position: "absolute",
  },
  progress: {
  }
}));

const LinearLoader = () => {
  const classes = useStyles();

  return (
    <div data-testid='linearLoader' className={classes.root}>
      <LinearProgress className={classes.progress} />
    </div>
  );
}

export default LinearLoader;