import React, { useState, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import i18n from '../../i18n/i18n';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SelectRadioDropdown from 'components/common/Elements/SelectRadioDropdown';
import { KCRegions } from "configs/appConstants";
import isEmpty from 'lodash/isEmpty';
import maestroLogo from '../../assets/images/maestro-logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10%',
    textAlign: 'center',
    height: '50vh'
  },
  formControl: {
    border: `1px solid ${theme.palette?.primary?.contrastText}`,
    borderRadius: theme.spacing(1),
    background: theme.palette?.form?.baseLight,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
    width: 300
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette?.form?.baseLight,
    },
  },
  button: {
    backgroundColor: theme.palette?.link?.linkPrimary,
    color: theme.palette?.primary?.contrastText,
    padding: `${theme.spacing(1.25)}px ${theme.spacing(2)}px`,
    '&:focus, &:hover': {
      backgroundColor: theme.palette?.link?.linkPrimary,
    },
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))

const Main = () => {
  const classes = useStyles();
  const history = useHistory();

  const [region, setRegion] = useState('');

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 350,
        minWidth: 300,
      },
      'data-testid': 'dropdownRadioMenu'
    },
    getContentAnchorEl: null,
    autoFocus: false,
    anchorOrigin: {
      vertical: 44,
      horizontal: -12
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  const handleSetRegion = (event) => {
    event.target.value === 'LAO' ? localStorage.setItem('i18nextLng', 'es') : localStorage.setItem('i18nextLng', 'en');
    setRegion(event.target.value);
  }

  // useEffect(() => {
  //   localStorage.setItem('i18nextLng', 'en')
  // }, [])

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('i18nextLng'));
  }, [localStorage.getItem('i18nextLng')]);

  const navigateRegion = () => {
    if (region === 'NA') {
      history.push('/redirect'); //Redirects to NA Home Page
    } else if (region === 'LAO') {
      const path = generatePath("/dashboard/LAO/network/home?loadFav=true&&loadView=true");
      history.push(path);
    } else {
      const path = generatePath("/dashboard/EMEA/network/home");
      history.push(path);
    }
  }

  return (
    <Grid container className={classes.root} data-testid="mainPage">
      <Grid item xs={12}> 
        <img src={maestroLogo} alt="maestro-logo" className={classes.logo} /> 
      </Grid>
      <Grid item xs={12}> 
        <SelectRadioDropdown
          labelId="region-select-label"
          testId="select-region"
          value={region}
          MenuProps={MenuProps}
          title="Region"
          data={KCRegions}
          onChange={handleSetRegion}
          styleClasses={classes}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          {"Selecting the region will give you more personalized experience to your dashboard."}
        </Typography>
      </Grid>
      <Grid item xs={12}> 
        <Button variant="contained" className={classes.button} onClick={navigateRegion} disabled={isEmpty(region)} data-testid="next-button"> {"Next"} </Button>
      </Grid>
    </Grid>
  )
}

export default Main;