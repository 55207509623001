import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { generatePath, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, Grid, Button, Box, IconButton, ClickAwayListener, Card, Popper } from '@material-ui/core';
import clsx from 'clsx';
import { useRouteContext } from 'context/RouteContext';
import SubmitLoadDiagram from "./SubmitLoadDiagram"
import { ColumnManager } from "../common/Table/ColumnManager";
import Filters from "../common/Filters/index";
import Snack from "../common/Helpers/Snack";
import { defaultOSMColumns } from "reduxLib/constdata/osmColumns";
import ReprintModal from "./ReprintModal";
import { sendToWmsService, resetSendToWms } from "../../reduxLib/services/loadDiagramService";
import { downloadService } from 'reduxLib/services';
import { osmFilterExclusionArray } from "configs/appConstants";
// import SubmitLDIcon from "../../assets/images/submit_ld_icon.svg";
import SubmitSimulation from "../../assets/images/submit_simulation.svg";
import SendToWmsIcon from "../../assets/images/send_to_wms_icon.svg";
import SendToWmsIconDisabled from "../../assets/images/send_to_wms_icon_disabled.svg";
import PrintAddrLabelIcon from "../../assets/images/addr_label_icon.svg";
import PrintAddrLabelIconDisabled from "../../assets/images/addr_label_icon_disabled.svg";
import PrintUccLabelIcon from "../../assets/images/ucc_label_icon.svg";
import PrintUccLabelIconDisabled from "../../assets/images/ucc_label_icon_disabled.svg";
import ReprintLSRIcon from "../../assets/images/reprint_lsr_icon.svg";
import ReprintLSRIconDisabled from "../../assets/images/reprint_lsr_icon_disabled.svg";
import RefreshIcon from "../../assets/images/dark-refresh-icon.svg";
import FilterIcon from "../../assets/images/dark-filter-icon.svg";
import ExcelIcon from 'assets/images/excel.png';
import ViewLsrIcon from "../../assets/images/view_lsr.svg";
import GoToIcon from "../../assets/images/go_to.svg";

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    padding: theme.spacing(0.75),
    border: `1px solid ${theme.palette?.border}`,
    borderRadius: theme.spacing(1),
    // height: 95,
    backgroundColor: theme.palette?.primary.primaryBase
  },
  actions: {
    textAlign: 'center',
    display: 'block',
    border: `1px solid transparent`,
    width: 100,
    textTransform: 'capitalize',
    '&:hover, &:active': {
      border: `1px solid ${theme.palette?.border}`,
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette?.link?.hover
    }
  },
  activeButton: {
    outline: `1px solid ${theme.palette?.border}`,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette?.link?.hover
  },
  optionsGrid: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  optionsGridItems: {
    justifyContent: 'flex-end'
  },
  actionIcons: {
    textAlign: 'center',
  },
  popper: {
    zIndex: 100,
    top: `${theme.spacing(1.25)}px !important`
  },
  lsrDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    borderBottom: `0.5px solid ${theme.palette?.border}`,
    color: theme.palette?.text?.secondary
  },
  goToIcon: {
    marginLeft: theme.spacing(1)
  }
}))

const OsmTableActionsBar = ({
  columns,
  columnsAction,
  selectedRow,
  type,
  subtype,
  tableLoading,
  refreshOsmTable,
  fetchEndPoint
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { osmPath } = useRouteContext();
  const params = useParams();
  const envArray = ["development", "test", "local"];

  const { osmviewid } = params;
  const filterType = "osmFilters";
  const { send_to_wms_loading, send_to_wms } = useSelector(({ loadDiagram }) => loadDiagram);
  const osmShipments = useSelector(({ tabledata: { osm } }) => osm?.osmShipments || {});
  const [openFilterbox, setOpenFilterbox] = useState(false);
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const popperId = openPopper ? 'lsr-popper' : undefined;
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  });

  const handleOpenFilterbox = useCallback(() => {
    setOpenFilterbox((prev) => !prev);
  }, [openFilterbox]);

  // const handleCloseFilterbox = useCallback(() => {
  //   setOpenFilterbox(false);
  // }, [openFilterbox]);

  const handleSnackClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  }

  const sendToWms = () => {
    const reqBody = {
      region: 'NA',
      shipmentNum: selectedRow?.shipmentNum,
      originSiteNum: selectedRow?.originSiteNum
    }
    dispatch(sendToWmsService(reqBody));
  }

  useEffect(() => {
    if (!send_to_wms_loading && send_to_wms) {
      setSnack({
        ...snack,
        open: true,
        severity: send_to_wms === "success" ? "success" : "error",
        message: send_to_wms === "success" ? t("sent_to_wms_successfully") : send_to_wms
      });

      dispatch(resetSendToWms());
    }
  }, [send_to_wms_loading, send_to_wms]);

  const downloadOsmTable = () => {
    dispatch(downloadService({
      config: {
        ...osmShipments?.data,
        body: osmShipments?.payload?.body?.tablebody,
        url: fetchEndPoint,
        columns
      },
      osm: true,
      type,
      subtype,
      process: defaultOSMColumns.columnConfiguration,
      detailsProcess: () => { }
    }))
  }

  const reprintLSR = () => {
    setOpen(true);
    setOpenType('LSR');
  }
  
  const printUCL = () => {
    setOpen(true);
    setOpenType('UCL');
  }

  const printADDR = () => {
    setOpen(true);
    setOpenType('ADDR');
  }

  const handleLsrPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const redirectToLsrPage = useCallback(() => {
    return () => {
      return `${generatePath(
        osmPath,
        {
          ...params,
          osmviewid: osmviewid
        }
      )}/viewLsr/${selectedRow?.originSiteNum}/${selectedRow?.shipmentNum}`
    }
  }, [selectedRow?.shipmentNum, selectedRow?.originSiteNum]);

  const redirectToStackDetailsPage = useCallback(() => {
    return () => {
      return `${generatePath(
        osmPath,
        {
          ...params,
          osmviewid: osmviewid
        }
      )}/viewStackInfo/${selectedRow?.originSiteNum}/${selectedRow?.shipmentNum}`
    }
  }, [selectedRow?.shipmentNum, selectedRow?.originSiteNum]);

  return (
    <Grid container data-testid='osmTableActionsBar'>
      <Grid item xs={6} className={classes.main}>
        <SubmitLoadDiagram openLDModal={false} selectedRow={selectedRow} tableLoading={tableLoading} />

        { envArray.includes(process.env.REACT_APP_ENV) ?
          <Button className={classes.actions} data-testid="send_to_wms" onClick={sendToWms} disabled={send_to_wms_loading}>
            <img src={SendToWmsIcon} alt="Send To Wms" />
            <Typography variant='body1'>{t('send_to_wms')}</Typography>
          </Button> :
          <Button className={classes.actions} data-testid="send_to_wms" disabled={true}>
            <img src={SendToWmsIconDisabled} alt="Send To Wms" />
            <Typography variant='body1'>{t('send_to_wms')}</Typography>
          </Button>
        }
        { envArray.includes(process.env.REACT_APP_ENV) ?
          <Button className={classes.actions} data-testid="print_ucl_label" onClick={printUCL} >
            <img src={PrintUccLabelIcon} alt="Print Ucl Label" />
            <Typography variant='body1'>{t('print_ucl_label')}</Typography>
          </Button> :
          <Button className={classes.actions} data-testid="print_ucl_label" disabled={true} >
            <img src={PrintUccLabelIconDisabled} alt="Print Ucl Label" />
            <Typography variant='body1'>{t('print_ucl_label')}</Typography>
          </Button>
        }
        { envArray.includes(process.env.REACT_APP_ENV) ?
          <Button className={classes.actions} data-testid="print_addr_label" onClick={printADDR} >
            <img src={PrintAddrLabelIcon} alt="Print Addr Label" />
            <Typography variant='body1'>{t('print_addr_label')}</Typography>
          </Button> :
          <Button className={classes.actions} data-testid="print_addr_label" disabled={true} >
            <img src={PrintAddrLabelIconDisabled} alt="Print Addr Label" />
            <Typography variant='body1'>{t('print_addr_label')}</Typography>
          </Button>
        }
        { envArray.includes(process.env.REACT_APP_ENV) ?
          <Button className={classes.actions} data-testid="reprint_lsr" onClick={reprintLSR} >
            <img src={ReprintLSRIcon} alt="Reprint LSR" />
            <Typography variant='body1'>{t('reprint_lsr')}</Typography>
          </Button> :
          <Button className={classes.actions} data-testid="reprint_lsr"
            // onClick={reprintLSR} disabled={open}>
            disabled={true}>
            <img src={ReprintLSRIconDisabled} alt="Reprint LSR" />
            <Typography variant='body1'>{t('reprint_lsr')}</Typography>
          </Button>
        }
        <Button className={classes.actions} data-testid="submit_ld_simulation" disabled={true} >
          <img src={SubmitSimulation} alt="Submit LD Simulation" />
          <Typography variant='body1'>{t('submit_ld_simulation')}</Typography>
        </Button>
        <Box>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <div>
              <Button aria-describedby={popperId} className={(anchorEl === null) ? classes.actions : clsx(classes.actions, classes.activeButton)}
                data-testid="view_stack_lsr" onClick={handleLsrPopper}>
                <img src={ViewLsrIcon} alt="View Stack Details and LSR" />
                <Typography variant='body1'>{t('view_stack_details_and_lsr')}</Typography>
              </Button>
              <Popper id={popperId} data-testid={popperId} open={openPopper} anchorEl={anchorEl} className={classes.popper}>
                <Card>
                  <Link className={classes.lsrDropdown} to={redirectToStackDetailsPage()} data-testid="goto_stack_details">
                    <Typography variant='body1'>{t('view_stack_details')}</Typography>
                    <img src={GoToIcon} className={classes.goToIcon} alt="View Stack Details" />
                  </Link>
                  <Link className={classes.lsrDropdown} to={redirectToLsrPage()} data-testid="goto_lsr">
                    <Typography variant='body1'> {t('view_lsr')} </Typography>
                    <img src={GoToIcon} className={classes.goToIcon} alt="View LSR" />
                  </Link>
                </Card>
              </Popper>
            </div>
          </ClickAwayListener>
        </Box>
      </Grid>
      <Grid item xs={6} className={classes.optionsGrid}>
        {/*
          <OsmDateRangePicker title={t('warehouse_ready_date')} type={type} subtype={subtype} filterKey="warehouseReadyDateTimeOriginTZ" /> 
          Moved this filter to filters section
        */}

        <Grid
          container
          spacing={3}
          xs={12}
          justifyContent="flex-end"
          className={classes.optionsGridItems}
        >
          <Grid item >
            <Box textAlign='center' aria-label="Manage Columns">
              <ColumnManager columns={columns} columnsAction={columnsAction} />
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.actionIcons}>
              <IconButton data-testid="downloadOsmTable" onClick={downloadOsmTable}><img src={ExcelIcon} alt="Download" /></IconButton>
              <Typography variant='body1'> {t('export')} </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box className={classes.actionIcons}>
              <IconButton data-testid="refreshOsmTable" onClick={refreshOsmTable}><img src={RefreshIcon} alt="Refresh" /></IconButton>
              <Typography variant='body1'> {t('refresh')} </Typography>
            </Box>
          </Grid>
          <Grid item>
            {/* <ClickAwayListener onClickAway={handleCloseFilterbox}> */}
              <div>
                <Box className={classes.actionIcons}>
                  <IconButton data-testid="osmFilters" 
                    className={openFilterbox ? classes.activeButton: ""}
                    onClick={handleOpenFilterbox}>
                    <img src={FilterIcon} alt="Filter" />
                  </IconButton>
                  <Typography variant='body1'> {t('filters')} </Typography>
                </Box>
                <Filters
                  filtertype={filterType}
                  open={openFilterbox}
                  handleOpen={handleOpenFilterbox}
                  type={type}
                  subtype={subtype}
                  exclude={osmFilterExclusionArray}
                  searchText={t('search_helper_text_network_page')}
                />
              </div>
            {/* </ClickAwayListener> */}
          </Grid>
        </Grid>
      </Grid>
      <ReprintModal open={open} setOpen={setOpen} selectedRow={selectedRow} openType={openType} setOpenType={setOpenType} />
      <Snack {...snack} handleClose={handleSnackClose}/>
    </Grid>
  )
}

export default OsmTableActionsBar;