import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { scaleBand, scaleLinear, max, sum } from 'd3'
import ResponsiveWrapper from 'components/charts/ResponsiveWrapper';
import Axes from 'components/D3Charts/Axes';
import TrendLine from 'components/D3Charts/TrendLine';
import Dots from 'components/D3Charts/Dots';
import Grids from 'components/D3Charts/Grids';

const LineChart = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const {
        xKey, yKey, height, loading,
        themeType, yLabel, legend,
        xLabel, viewDetails, showXAxisLabel,
        ...rest
    } = props;
    const keys = [yKey];
    const [total, setTotal] = useState(() => data.reduce((a, b) => a + b[yKey], 0));

    useEffect(() => {
        setData(rest.data?.map(d => ({ ...d, total: d[yKey] })))
        setTotal(rest.data?.reduce((a, b) => a + b[yKey], 0))
    }, [rest.data]);

    const margins = { top: 10, left: 80, right: 30, bottom: 80 };

    const svgRootDimensions = {
        width: rest.parentWidth,
        height: height - (legend ? 50 : 0) - (viewDetails ? 10 : 0)
    }

    let scaleX = scaleBand()
        .padding(0.4)
        .paddingInner(0.3)
        .domain(data.map(d => d[xKey]))
        .range([margins.left, svgRootDimensions.width - margins.right])

    let scaleY = scaleLinear()
        .domain([0, max(data, d => sum(keys, k => +d[k]))])
        .nice()
        .range([svgRootDimensions.height - margins.bottom, margins.top]);

    const accessorX = d => d[xKey];
    const centerPosition = [svgRootDimensions.width / 2, svgRootDimensions.height / 2];

    const LoadingText = () => {
        const text = loading ? "loading" : "no_data_found"
        return (
            <g transform={`translate(${centerPosition})`} data-testid={"no-data-found"}>
                <text textAnchor="middle" data-testid="noData">{t(text)}</text>
            </g>
        )
    }

    return (
        <div data-testid='lineChart'
            style={{
                minHeight: height,
                maxWidth: svgRootDimensions.width + margins.left
            }}>
            <svg width={svgRootDimensions.width} height={(svgRootDimensions.height + (xLabel ? 10 : 0))}>
                {

                    (!total || loading) ? <LoadingText /> :
                        <g>
                            <Axes
                                wrapit={true}
                                scales={{ xScale: scaleX, yScale: scaleY }}
                                margins={margins}
                                svgDimensions={svgRootDimensions}
                                yLabel={yLabel}
                                xLabel={xLabel}
                                showXAxisLabel={showXAxisLabel}
                                rotateLabel={true}
                                height={svgRootDimensions.height}
                                dx={props.dx}
                            />
                            <Grids
                                svgDimensions={svgRootDimensions}
                                xScale={scaleX}
                                yScale={scaleY}
                                margins={margins}
                                xKey={xKey}
                                data={data}
                                yKey={yKey}
                            />
                            <TrendLine
                                height={svgRootDimensions.height}
                                scales={{ xScale: scaleX, yScale: scaleY }}
                                margins={margins}
                                xKey={xKey}
                                data={data}
                                yKey={yKey}
                            />
                            <Dots
                                scales={{ xScale: scaleX, yScale: scaleY }}
                                margins={margins}
                                xKey={xKey}
                                data={data}
                                xAccessor={accessorX}
                                keys={[yKey]}
                                themeType={themeType}
                                formatRequired
                                hideTopText={true}
                                highlightBarlabel={true}
                            />
                        </g>}

            </svg>
            
            
        </div>
    )

}

export default ResponsiveWrapper(LineChart)