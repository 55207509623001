import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EMEABreadcrumb from 'components/EMEA/EMEA_Common/EMEABreadcrumb';
import EMEAChartCard from 'components/EMEA/EMEA_Common/EMEAChartCard';
import EmeaCustomTable from 'components/EMEA/EMEA_Common/EmeaCustomTable';
import { useStyles } from 'components/EMEA/EMEAInvoiceStatus';
import { emeaInvoiceSummaryDetailsColumns } from "reduxLib/constdata/EMEA/emeaInvoiceSummaryDetailsColumns";
import { pwaSettings } from 'configs/pwaConstants';

const EMEAInvoiceSummaryInvoicesDetails = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const [chartWidth, setChartWidth] = useState(() => window.innerWidth - window.innerWidth / 10 * 1.5);

  let resizeWindow = () => {
    setChartWidth(window.innerWidth - window.innerWidth / 10 * 1.5);
  };

  const additionalFilters = {
    orderType: "ORDER_ENTRY"
  }

  const handleLinkClick = () => {
    let updatedPath = location.pathname;
    updatedPath = updatedPath.replace("/invoiceDetails", "");
    history.push({
      ...location,
      pathname: updatedPath
    })
  }

  const chartInvoiceDetails = {
    subtype: "SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP",
    colorPalette: "set10",
    legend: false,
    colorBy: "yKey",
    customTooltipKey: "state",
    xKey: "stateDescCustom",
    showMonthFilter: false,
    axisSuffix: "%",
    barSuffix: "%",
    maxWidth: chartWidth,
    minWidth: chartWidth,
    legendGroup: true,
    monthFilter: true,
    keys: ["invoicePer", "openPer", "gapPer"],
    keyLabelMaps: {
      invoicePer: t('invoiced'),
      openPer: t('not_invoiced'),
      gapPer: t('gap'),
    },
    chart: "trendline",
    decimals: 2,
    // refresh,
    // setRefresh,
    showEllipsis: true,
    showXAxisLabel: !isMobile,
    showBarLabel: !isMobile,
    testId: "invoice-details-open-gap-chart",
    refreshIcon: false,
    displayKey: "stateDesc",
    tooltipKey: "state",
    legendGroupItems: [
      { type: 'square', color: theme.palette.set10[0], label: t('invoiced'), key: "invoicePer" },
      { type: 'square', color: theme.palette.set10[1], label: t('not_invoiced'), key: "openPer" },
      { type: 'square', color: theme.palette.set10[2], label: t('gap'), key: "gapPer" },
    ],
  }

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <>
      <EMEABreadcrumb 
      pageName = {'invoice_summary'} 
      detailsPageName = {'invoices_details'}
      routerDetailsPageName = {'invoiceDetails'} 
      hasDetailsPage = {true}
      />

      <div className={classes.box}>
        <Grid container justifyContent="flex-end" >
          <Button className={classes.buttonLink} disableRipple data-testid="page-link-btn" onClick={handleLinkClick} >
            {t("goto_invoices")}
          </Button>
        </Grid>
        {
          isMobile ? 
            <Slider {...pwaSettings} className={classes.slider}>
              <div>
                <EMEAChartCard {...chartInvoiceDetails} boxShadow="none" excludedFilters={additionalFilters} />
              </div>
            </Slider>
            :
            <Grid container justifyContent="center" alignItems="center">
              <EMEAChartCard
                {...chartInvoiceDetails}
                excludedFilters={additionalFilters}
                height={500}
              />
            </Grid>
        }
      </div>

      <EmeaCustomTable subType={"SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP_TABULAR"} columnHeaders={emeaInvoiceSummaryDetailsColumns} />
    </>
  )
}

export default EMEAInvoiceSummaryInvoicesDetails