import React, { useState } from 'react'
import { Typography, Grid, makeStyles, TextField, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import KCSelect from 'components/common/Select';
import { isEmpty } from 'lodash';
import { saveGuardrails } from 'reduxLib/services/getAllGuardRailsService';
import Button from 'components/common/Button';
import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import RequestAccessModal from './common/Guardrail/RequestAccessModal';
import Snack from "components/common/Helpers/Snack";

const useStyles = makeStyles(theme => ({
    root: {
        width: "80%",
        background: theme.palette.primary.base,
        padding: theme.spacing(2.5),
        margin: `${theme.spacing(16)}px auto`,
        position: 'relative',
        "&>div:nth-child(3)": {
            margin: `${theme.spacing(2)}px 0px`,
            background: theme.palette.card.base,
            borderRadius: theme.spacing(.5),
            width: "100%",
            padding: theme.spacing(2.5),
        }
    },
    tableHeader: {
        background: theme.palette.secondary.dark,
        borderBottom: `1px solid ${theme.palette.legendColors.greyLight}`,
        padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,

    },
    rowTitle: {
        padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
    },
    tableContainer: {
        margin: `${theme.spacing(2.5)}px 0px`,
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1.5),
        cursor: "pointer",
        zIndex: 10
    }
}))

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        "& input": {
            padding: theme.spacing(1.5),
            background: theme.palette.secondary.base
        },
        "& > div": {
            margin: `${theme.spacing(1.25)}px ${theme.spacing(0.5)}px`
        }
    },
}))(TextField);

const rowDataOrder = ['CONSUMER', 'PROFESSIONAL', 'ALL']
const weekdayIndexArray = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

export const GuardrailDataDisplayGrid = ({ data, title, propertyKey, updateGuardrails, allowedToEdit }) => {
    const classes = useStyles()
    const { t } = useTranslation();
    return (
        <Grid container data-testid="guardraildatadisplaygrid" className={classes.tableContainer}>
            <Grid container item direction='column' justify='space-around' xs={5} >
                <Grid item className={classes.tableHeader}>
                    <Typography variant='h4'>{title}</Typography>
                </Grid>
                <Grid item className={classes.rowTitle} >
                    <Typography variant='h4'>CONSUMER</Typography>
                </Grid>
                <Grid item className={classes.rowTitle}>
                    <Typography variant='h4'>PROFESSIONAL</Typography>
                </Grid>
                <Grid item className={classes.rowTitle}>
                    <Typography variant='h4'>STO</Typography>
                </Grid>
                <Grid className={classes.rowTitle}>
                    <Typography variant='h4'>{t("total")}</Typography>
                </Grid>
            </Grid>
            {!isEmpty(data) && data.weekdays.map((item) => {
                return (
                    <Grid container item direction='column' justify='space-around' xs={1}>
                        <Grid item className={classes.tableHeader}>
                            <Typography variant='h4'>{t(item.day.toLowerCase()).toUpperCase().substring(0, 3)}</Typography>
                        </Grid>
                        {
                            rowDataOrder.map(bu => (
                                <Grid item>
                                    {<CssTextField
                                        value={item.orderDivisions.filter(({ divisionName }) => divisionName === bu)[0]?.[propertyKey]}
                                        title="textbox"
                                        disabled={!allowedToEdit}
                                        type='number'
                                        inputProps={{ "data-testid": `textfilter_${bu}_${propertyKey}`, min: 0, "data-day": item.day }}
                                        onChange={e =>
                                            updateGuardrails({
                                                ...item,
                                                orderDivisions: item.orderDivisions.map(obj => obj.divisionName === bu ? { ...obj, [propertyKey]: e.target.value || 0 } : obj)
                                            })
                                        }
                                        variant="outlined"
                                    />}
                                </Grid>
                            ))
                        }
                        <Grid item align='center'>
                            <Typography component='span' variant='h3'>{item.orderDivisions.reduce((total, obj) => total + parseInt(obj[propertyKey]), 0)}</Typography>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}


export const guardrailsAllSitesandDays = ({ siteNum, guardrailsData }) => {
    const siteGuardrailsArray = !isEmpty(guardrailsData.data) ? guardrailsData.data.filter((site) => site.siteNum === siteNum) : []

    if (isEmpty(siteGuardrailsArray)) {
        return {
            siteNum,
            unit: "LOADS",
            weekdays: weekdayIndexArray.map((value) => ({
                day: value,
                orderDivisions: rowDataOrder.map((value) => ({
                    divisionName: value,
                    guardrailValue: 0,
                }))
            }))
        }

    } else {
        const siteGuardrailsData = siteGuardrailsArray[0]
        return {
            ...siteGuardrailsData,
            weekdays: weekdayIndexArray.map((value) => {
                const findDay = siteGuardrailsData?.weekdays.filter(({ day }) => day.toLowerCase() === value)[0]
                if (!findDay) {
                    return {
                        day: value,
                        orderDivisions: rowDataOrder.map((value) => ({
                            divisionName: value,
                            guardrailValue: 0,
                        }))
                    }
                } else {
                    return {
                        day: value,
                        orderDivisions: rowDataOrder.map((value) => ({
                            divisionName: value,
                            guardrailValue: findDay?.orderDivisions?.filter(({ divisionName }) => value === divisionName)[0]?.guardrailValue || 0,
                        }))
                    }

                }

            })
        }
    }


}

export default ({ onClose }) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const guardrailsData = useSelector(({ guardrails }) => guardrails.allSiteGuardrails)
    const locations = useSelector(({ sites }) => sites.locations)

    const { t } = useTranslation();
    const [selectedSite, setSelectedSite] = useState(locations[0].siteNum)

    const [sortfilterDataBySite, setsortfilterDataBySite] = useState(guardrailsAllSitesandDays({ siteNum: selectedSite, guardrailsData }))

    const handleSiteChange = (value) => {
        setSelectedSite(value)
        setsortfilterDataBySite(guardrailsAllSitesandDays({ siteNum: value, guardrailsData }))
    }

    const handleUnitChange = (value) => setsortfilterDataBySite({ ...sortfilterDataBySite, unit: value })
    const updateGuardrails = (item) => {
        setsortfilterDataBySite({
            ...sortfilterDataBySite,
            weekdays: [...sortfilterDataBySite.weekdays.filter(({ day }) => day !== item.day), item].sort((item1, item2) => weekdayIndexArray.indexOf(item1.day) - weekdayIndexArray.indexOf(item2.day))
        })
    }

    const saveChanges = () => {
        const payloadObjectFrame = sortfilterDataBySite
        dispatch(saveGuardrails(payloadObjectFrame))
    }

    const [open, setOpen] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false,
        severity: "info",
        message: null
    });

    const openRequestModal = () => {
        setOpen(true)
    }

    const handleSnackClose = () => {
        setSnack({
            ...snack,
            open: false
        });
    };

    return (
        <Grid data-testid="updateguardrails" className={classes.root} >
            <Typography component='span' variant='h3'>{t("edit_update_guardrails")}</Typography>
            <span title='closeicon' data-testid='closeicon' className={classes.closeicon} onClick={onClose}><CloseIcon /></span>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant='h4'>{t("location")}</Typography>
                    <KCSelect classes={classes.selectOption} disableLabel='true' color="primary" value={selectedSite}
                        onChange={handleSiteChange}
                        options={locations.map(({ siteNum, shortName }) => ({ name: `${shortName} - ${siteNum}`, value: siteNum }))}
                        testId='update-gaurdrail'
                        data={selectedSite}
                        label={"select-location"}
                    />
                </Grid>
                <Grid item >
                    <Typography variant='h4'>{t("unit")}</Typography>
                    <KCSelect classes={classes.selectOption} disableLabel='true' color="primary" value={t(sortfilterDataBySite?.unit)}
                        onChange={handleUnitChange}
                        label={"select-unit"}
                        testId='update-unit'
                        options={[
                            { name: t("loads"), value: "LOADS" },
                            { name: t("hours"), value: "HOURS" },

                        ]} />
                </Grid>
                <GuardrailDataDisplayGrid
                    title={t('distribution_guardrails')}
                    allowedToEdit={guardrailsData.allowedToEdit}
                    propertyKey="guardrailValue"
                    data={sortfilterDataBySite}
                    updateGuardrails={updateGuardrails}
                />
                <Grid item xs={12} align='center'>
                    <Button onClick={saveChanges} disabled={!guardrailsData.allowedToEdit} color='primary' variant='outlined'> {t("update_changes")} </Button>
                    {
                        !guardrailsData.allowedToEdit &&
                        <Button onClick={openRequestModal}
                        data-testid="request_access_button"
                        disabled={guardrailsData.requestedForAccess} color='primary' variant='outlined'> {t("request_access")} </Button>
                    }
                </Grid>
            </Grid>
            <RequestAccessModal open={open} setOpen={setOpen} snack={snack} setSnack={setSnack} />
            <Snack {...snack} handleClose={handleSnackClose} />
        </Grid>
    )
}