import { downloadConstants } from '../constants';

const downloadReducer = (state={}, action) => {
    const { type, page, payload, subtype, columns, process,detailsProcess,detailsColumns, itemInfoRequired = false } = action;

    const template = (data) => {
        return (
            {
                ...state,
                [page]: {
                    ...state[page],
                    [subtype]: {
                        ...state?.[page]?.[subtype],
                        ...data
                    }
                }
            }
        )
    }

    switch (type) {
        case downloadConstants.LOADING:
            return template({
                status: null,
                downloading: payload,
                columns,
                process,
                detailsProcess,
                detailsColumns
            })
        case downloadConstants.DOWNLOAD_SUCCESS:
            return template({
                status: "Success",
                data: payload,
                downloading: false,
                itemInfoRequired
            })

        case downloadConstants.DOWNLOAD_FAILURE:
            return template({
                status: "Failed",
                data: null,
                downloading: false,
                itemInfoRequired
            })

        case downloadConstants.DOWNLOAD_RESET:
            return {
                ...state,
                [page]: {
                    ...state[page],
                    [subtype]: {}
                }
            }

        default:
            return state
    }
}

export default downloadReducer;
