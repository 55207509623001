import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
  MenuItem,
  Grid,
  ClickAwayListener,
  ListItemText,
  IconButton,
  Typography,
  ListItemSecondaryAction,
  makeStyles,
  useMediaQuery,
  useTheme,
  Button,
  FormGroup
} from '@material-ui/core';
import {
  Favorite,
  FavoriteBorderOutlined,
  DeleteForeverOutlined
} from '@material-ui/icons';
import {
  setFilterLayouts,
  addNewFilterLayout,
  setCurrentView,
  setAsFavorite
} from '../../reduxLib/services';
import { get, isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import FilterViewIcon from '../../assets/images/table-view.svg';
import { saveView, deleteView } from './FilterView';
import Snack from './Helpers/Snack';
import { getFilterArgs } from 'helpers';
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'block',
    borderTop: `1px solid ${theme.palette.common.white}`
  },
  label: {
    wordWrap: 'break-word',
    paddingTop: theme.spacing(1)
  },
  button: {
    margin: `${theme.spacing(1.5)}px 0px`,
    background: theme.palette.secondary.base,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: theme.spacing(1)
  },
  buttonPadding: {
    [theme.breakpoints.down("sm")]: {
   paddingLeft:theme.spacing(1.125),
   paddingRight:theme.spacing(1.125)
    }
},
  filterGrid: {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: theme.palette.secondary.base,
    width: `${theme.spacing(40)}px`,
    right: `${theme.spacing(5)}px`,
    justifyContent: 'left',
    maxHeight: `${theme.spacing(70)}px`,
    borderRadius: `${theme.spacing(0.5)}px`,
    padding: `${theme.spacing(1)}px 0px`,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  menuitem: {
    display: 'block'
  },
  addview: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: theme.spacing(40),
    '&:focus': {
      backgroundColor: theme.palette.secondary.base,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export const StyledTextField = withStyles({
  root: {
    "& fieldset": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }
  }
})(TextField);

export const StyledButton = withStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }
})(Button);

const FilterViewsMenu = ({
  type,
  views,
  tableName,
  setCurrentView,
  filterBody,
  filters,
  initialLayouts,
  setAsFavorite,
  currentView,
  setFilterLayouts,
  defaultColumns,
  defaultView
}) => {

  const location = useLocation();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [viewName, setViewName] = useState('');

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    setAnchorEl((prev) => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateURLParam = (params) => {
    const queryParams = qs.parse(location.search);
    const newQueries = {
      ...queryParams,
      ...params
    };


    history.push({
      ...location,
      search: qs.stringify(newQueries)
    })
  };

  const applyFilterView = ({id, name}) => {
    const view = views.filter((view) => view.id === id)[0];
    updateURLParam({
      viewid: id
    });
    setViewName(name);
    if (!isEmpty(view.filters)) {
      updateURLParam({
        viewid: id,
        filters: JSON.stringify(getFilterArgs(view.filters))
      });
    }
    setAnchorEl(null);
  };

  const markFavorite = ({id, name}) => {
    const actionObject = {
      ...initialLayouts,
      [tableName.toUpperCase()]: {
        tableName: tableName.toLowerCase(),
        views: initialLayouts[tableName.toUpperCase()]?.views.map((view) =>
          id === view.id
            ? { ...view, default: !view.default }
            : { ...view, default: false }
        )
      }
    };
    setViewName(name);
    setAsFavorite({ actionObject });
  };

  const [snack, setSnack] = useState({
    open: false,
    severity: null,
    message: null
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({
      ...snack,
      open: false
    });
  };

  const showFilterViewNotifications = ({
    notificationType,
    viewName: viewName1
  }) => {
    const notificationTypeDescriptionMap = {
      save: `${viewName1} ${t('saved_successfully')}`,
      add: `${viewName1 || t('untitled_view')} ${t('created')}`,
      delete: `${viewName1} ${t('deleted')}`
    };
    setSnack({
      open: true,
      severity: 'info',
      message: notificationTypeDescriptionMap[notificationType]
    });
  };
  useEffect(() => {
    setViewName(currentView?.name || '');
  }, [currentView]);

  const viewLimit = process.env.REACT_APP_VIEWLIMIT;
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div data-testid="filterviewmenu">
        <IconButton  className={classes.buttonPadding}
          data-testid="filterviewmenubutton"
          onClick={handleClick}
          aria-label="Table Views"
          color="primary">
          <img src={FilterViewIcon}></img>
        </IconButton>
        {!isMobile && (
          <Typography data-testid="TableViews" variant="body2">
            {t('views')}
          </Typography>
        )}
        {/* replaced StyleMenu wrapper with Grid below due to textfield focus issues (Bug 39138) */}
        {anchorEl && (
          <Grid
            container
            className={classes.filterGrid}
            id="customized-menu"
            onClose={handleClose}>
            {(!views || views?.length <= viewLimit) && (
              <form onSubmit={((e) => {
                e.preventDefault()
                updateURLParam({
                  filters: JSON.stringify({}),
                });
                saveView({ showFilterViewNotifications, createNewView: true, tableName, views, viewName, filterBody, filters, currentView, initialLayouts, setFilterLayouts, defaultColumns });
              })} >

                <FormGroup row
                  className={classes.addview}>
                  <StyledTextField
                    variant="outlined"
                    required
                    inputProps={{ "data-testid": "savenewviewforminput" }}
                    name="viewname"
                    onChange={(e) => { setViewName(e.target.value) }}
                    placeholder={t('enter_a_view_name')}
                    size="small"
                  />
                  <StyledButton variant="contained" size="small" type="submit">
                    Add View
                  </StyledButton>
                  <Typography variant='body2' className={classes.label}>{t('new_view_name_msg')}</Typography>
                </FormGroup>
              </form>
            )}
            {views &&
              views.map((view, index) => {
                return (
                  <StyledMenuItem
                    className={classes.root}
                    key={view.id}
                    data-testid={`view-${view.id}`}
                    onClick={() => {
                      applyFilterView(view);
                    }}>
                    <ListItemText primary={view.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        data-testid="markfavourite"
                        aria-label="FavUnfav"
                        onClick={() => {
                          markFavorite(view);
                        }}>
                        {view.default === true ? (
                          <Favorite></Favorite>
                        ) : (
                          <FavoriteBorderOutlined />
                        )}
                      </IconButton>
                      <IconButton
                        data-testId="deletecurrentview"
                        onClick={() => {
                          deleteView({
                            showFilterViewNotifications,
                            viewId: view.id,
                            tableName,
                            filterBody,
                            initialLayouts,
                            setFilterLayouts,
                            views,
                            currentView,
                            deleteCurrentView:
                              view?.id === currentView?.id ? true : false
                          });
                        }}
                        color="primary">
                        <DeleteForeverOutlined />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </StyledMenuItem>
                );
              })}
          </Grid>
        )}
        <Snack {...snack} handleClose={handleSnackbarClose} />
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { tableName } = ownProps;
  return {
    views: get(
      state,
      `user.filterLayouts.${tableName.toUpperCase()}.views`,
      []
    ),
    filters: get(state, `options.filters.${tableName}`, {}),
    initialLayouts: get(state, `user.initialLayouts`, []),
    currentView: get(state, `user.currentView.${tableName}`) || {},
    defaultView:
      get(state, `user.filterLayouts.${tableName.toUpperCase()}.views`)?.filter(
        (view) => view.default === true
      )[0] || {}
  };
};
const mapDispatchToProps = {
  addNewFilterLayout,
  setFilterLayouts,
  setCurrentView,
  setAsFavorite
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterViewsMenu);
