import { AppConfigurationClient } from '@azure/app-configuration';
import { useEffect, useMemo, useState } from 'react';
import config from "configs";

const { app_config_string } = config;

const client = new AppConfigurationClient(app_config_string);

const useFeatureFlag = (flagKey = '') => {
  const [values, setValues] = useState({});

  useEffect(() => {
    const getFeatureFlag = async () => {
    if (!flagKey || !flagKey.toString().trim().length) {
      console.error('A feature flag key must be supplied.');
    } else {
      try {
        const result = await client.getConfigurationSetting({
          key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
        });
        if (result && typeof result === 'object') {
          const {id,enabled,description} = JSON.parse(result.value)
          setValues({id,enabled,description: JSON.parse(JSON.parse(description))});
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  getFeatureFlag();
  }, [flagKey]);
  return values;
};

const useConfiguration = (configKey = '') => {
  const [configurations, setconfigurations] = useState('');

  useMemo(async () => {
    if (!configKey || !configKey.toString().trim().length) {
      console.error('A config key must be supplied.');
    } else {
      try {
        const result = await client.getConfigurationSetting({ key: configKey.toString().trim() });
        if (result) {
          console.debug('Config: ' + result.key, result.value);
          setconfigurations(result.value);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [configKey]);

  return { configurations };
};

export { useFeatureFlag, useConfiguration };