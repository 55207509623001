import { authConstants } from '../constants';
import cookie from 'react-cookies';
import {get} from "lodash";

const authReducer = (state = { auth: { user: null } }, action) => {
    switch (action.type) {
        case authConstants.ADD_USER_DETAILS:
        case authConstants.REMOVE_USER_DETAILS:
            cookie.save('email', get(action.payload, "email", "") , { path: '/' })
            return { ...state, user: action.payload }

        default:
            return state
    }
}

export default authReducer;
