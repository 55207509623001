import React from 'react';
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    //    border: `1px solid ${theme.palette.border}`,
        textTransform: "capitalize",
        '&:hover, &:active': {
            backgroundColor: theme.palette?.primary?.active,
            border: `1px solid ${theme.palette?.chip?.borderActive}`
        }
    }
}));

const KCButton = (props) => {
    const classes = useStyles();

    return (
        <Button data-testid='button' variant="outlined" color="primary" className={classes.root}  {...props} >{props.children}</Button>
    )
};

export default KCButton;