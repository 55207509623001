export default {
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    REMOVE_ITEM_SUCCESS: 'REMOVE_ITEM_SUCCESS',
    ADD_CUSO_MULTIPLE_SUCCESS: "ADD_CUSO_MULTIPLE_SUCCESS",
    ADD_CUSO_SUCCESS:"ADD_CUSO_SUCCESS",
    REMOVE_CUSO_SUCCESS:"REMOVE_CUSO_SUCCESS",
    CLEAR_CUSO:"CLEAR_CUSO",
    ADD_SG_SUCCESS:"ADD_SG_SUCCESS",
    REMOVE_SG_SUCCESS:"REMOVE_SG_SUCCESS",
    ADD_ITEM_MULTIPLE_SUCCESS: 'ADD_ITEM_MULTIPLE_SUCCESS',
    CLEAR_ALL: 'CLEAR_ITEMS',
    REMOVE_FAV_ITEMS: 'REMOVE_FAV_ITEMS',
    REMOVE_FAV_CUSO: 'REMOVE_FAV_CUSO',
    CLEAR_SELECTED: 'CLEAR_SELECTED',
    CHANGE_TAB: 'CHANGE_TAB',
    ADD_CUSTOMER_MULTIPLE_SUCCESS: 'ADD_CUSTOMER_MULTIPLE_SUCCESS',
    JOIN_FAVS_AND_SELECTIONS_CUSO: "JOIN_FAVS_AND_SELECTIONS_CUSO",
    SET_ONLOAD_SELECTIONS: "SET_ONLOAD_SELECTIONS",
    SET_BLOCKONTABCHANGE:"SET_BLOCKONTABCHANGE",
    SET_LOAD_FALSE: "SET_LOAD_FALSE",
    UPDATE_LOCATIONS: "UPDATE_LOCATIONS"
}