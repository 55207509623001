import config from "configs";

const { CLIENT_ID, ISSUER } = config.okta;

export default {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: `${window.location.origin}/callback`,
    scopes: ["openid", "profile", "email", "groups", "offline_access"],
    response_type: ["id_token"],
    pkce: false,
    tokenManager: {
        autoRenew: true,
        autoRefresh: true,
        expireEarlySeconds: 10
    }
};
