import React from 'react';
import {
    makeStyles, Button
} from "@material-ui/core"
import { useSelector, useDispatch } from 'react-redux';
import { loadSelfAlertingMetadata } from 'reduxLib/services/selfAlertingService';
import { Link, useRouteMatch } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import SFBreadCrumb from 'components/selfalerting/SFBreadCrumb';
import { Helmet } from 'react-helmet';
import { selfAlertingStyles } from 'theme';
import { isEmpty } from 'lodash';
import AllSelfAlertCards from "components/selfalerting/AllSelfAlertCards";

const useStyles = makeStyles(selfAlertingStyles);

export const SelfAlertingAll = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { metadata } = useSelector(({ selfalerting: { metadata } }) =>
        ({ metadata })
    );

    const [mappedmeta, setMeta] = React.useState({});

    React.useEffect(() => {
        const mapped = {};
        let data = metadata?.supportedFields?.map(d => {
            mapped[d.field] = d.fieldText;
        });
        data = metadata?.supportedOperations?.map(d => {
            mapped[d.operation] = d.operationText;
        });
        setMeta(mapped)
    }, [metadata])


    React.useEffect(() => {
        if (isEmpty(metadata)) {
            dispatch(loadSelfAlertingMetadata());
        }
    }, [metadata]);

    const match = useRouteMatch();

    return (
        <div className={classes.component} data-testid="selfalertall">
            <Helmet>
                <title>{`CT - Saved Alerts`}</title>
            </Helmet>
            <SFBreadCrumb />
            <Button
                component={Link}
                className={classes.margintop}
                variant="contained"
                to={`${match.url}/new`}
                startIcon={<AddIcon />}
            >
                Create New Alert
            </Button>
            <AllSelfAlertCards  />
        </div>
    )

}

export default SelfAlertingAll;
