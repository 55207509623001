import { detailsConstants } from '../constants';
import fetch from './serviceHelpers';
import { endpoints } from 'helpers';

const getShipmentDetailsService = (variables) => (dispatch) => {
  dispatch({
    type: detailsConstants.SHIPMENT_LOADING,
    payload: true
  });

  const { shipmentNum, searchType } = variables;

  return fetch(endpoints.shipmentDetails, {
    method: 'POST',
    body: {
      region: 'NA',
      shipmentNum,
      searchType
    }
  })
    .then((d) => {
      if (d.status === 200) {
        return d.json();
      }
      dispatch({
        type: detailsConstants.SHIPMENT_LOADING,
        payload: false
      });

      if (searchType === detailsConstants.STOPS_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_STOPS_DATA_FETCH_FAILED,
          payload: {}
        });
      }

      if (searchType === detailsConstants.TRACKING_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_TRACKING_DATA_FETCH_FAILED,
          payload: {}
        });
      }

    })
    .then((d) => {
      if (searchType === detailsConstants.STOPS_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_STOPS_DATA_FETCH_SUCCESS,
          payload: d,
        });
      }

      if (searchType === detailsConstants.TRACKING_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_TRACKING_DATA_FETCH_SUCCESS,
          payload: d,
        });
      }

      dispatch({
        type: detailsConstants.SHIPMENT_LOADING,
        payload: false
      });

    })
    .catch((err) => {
      dispatch({
        type: detailsConstants.SHIPMENT_LOADING,
        payload: false
      });

      if (searchType === detailsConstants.STOPS_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_STOPS_DATA_FETCH_FAILED,
          payload: err
        });
      }

      if (searchType === detailsConstants.TRACKING_DETAILS) {
        dispatch({
          type: detailsConstants.SHIPMENT_TRACKING_DATA_FETCH_FAILED,
          payload: err
        });
      }
    });
};

export default getShipmentDetailsService;
