import { laoTableConstants } from "reduxLib/constants/LAO/laoTableConstants";
import fetch from "../serviceHelpers";
import get from "lodash/get";

export const getLaoTableData = dataVariables => async dispatch => {
  const { fetchEndPoint, tablebody, page, pageSize } = get(dataVariables, "body", {});

  dispatch({
    type: laoTableConstants.LAO_TABLE_LOADING,
    payload: true,
    dataVariables
  });
  const tableUrl = `${fetchEndPoint}?pageSize=${pageSize}&pageNum=${page + 1}`;

  return fetch(tableUrl, {
    method: "POST",
    body: tablebody,
    signal: dataVariables?.controller?.signal
  })
    .then(async (response) => {
      if (response.status === 200) {
        return response.json();
      } else if (response.status === 401) {
        throw "lao_restricted";
      } else {
        let d = await response.json();
        if(d?.exceptionCode === 'ct_exec_43') {
          throw d?.exceptionDesc;
        } else {
          throw "lao_sites_failed";
        }       
      }
    })
    .then(response => {
      dispatch({
        type: laoTableConstants.LAO_TABLE_SUCCESS,
        payload: response,
        status: {},
        dataVariables
      });
    })
    .catch(err => {
      dispatch({
        type: laoTableConstants.LAO_TABLE_FAILED,
        dataVariables,
        payload: {},
        status: {
          status: "error",
          message: err || "lao_sites_failed"
        },
      });
    })
}
