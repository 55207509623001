import { profileConstants } from '../constants';
import { transportfilters, distoutboundfilters, distinboundfilters, ordersfilters, networkfilters } from "../constdata/filters";

const defaultdata = {
    transport: transportfilters,
    distoutbound: distoutboundfilters,
    distinbound: distinboundfilters,
    orders: ordersfilters,
    network: networkfilters,
}

const getFilterLayoutsReducer = (state = { loading: false, filterLayouts: {}, initialLayouts: {}, currentView: {}, fetched: false }, action) => {

    switch (action.type) {
        case profileConstants.PROFILE_FETCH_SUCCESS:
            return {
                ...state,
                filterLayouts: action.payload?.layout?.filterLayouts || {},
                initialLayouts: action.payload?.layout?.filterLayouts || {},
                currentView: {},
                defaultViews: {
                    "network": { ...action.payload?.layout?.filterLayouts?.["NETWORK"]?.views?.filter(view => view.default === true)[0] || {} },
                    "transport": { ...action.payload?.layout?.filterLayouts?.["TRANSPORT"]?.views?.filter(view => view.default === true)[0] || {} },
                    "orders": { ...action.payload?.layout?.filterLayouts?.["ORDERS"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distoutbound": { ...action.payload?.layout?.filterLayouts?.["DISTOUTBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distinbound": { ...action.payload?.layout?.filterLayouts?.["DISTINBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                },
                defaultViewsFilters: {
                    "network": { ...action.payload?.layout?.filterLayouts?.["NETWORK"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['network'] },
                    "transport": { ...action.payload?.layout?.filterLayouts?.["TRANSPORT"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['transport'] },
                    "orders": { ...action.payload?.layout?.filterLayouts?.["ORDERS"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['orders'] },
                    "distoutbound": { ...action.payload?.layout?.filterLayouts?.["DISTOUTBOUND"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['distoutbound'] },
                    "distinbound": { ...action.payload?.layout?.filterLayouts?.["DISTINBOUND"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['distinbound'] },
                },
                firstLoad: true
            }

        case profileConstants.PROFILE_FETCH_FAILED:
            return { ...state, filterLayouts: {}, currentView: {} }

        case profileConstants.LOADING:
            return {
                ...state,
                loading: action.payload
            }

        case profileConstants.FETCH_STATUS:
            return {
                ...state,
                fetched: action.payload
            }

        case profileConstants.PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                filterLayouts: action.payload.data.filterLayouts,
                initialLayouts: action.payload.data.filterLayouts,
                defaultViews: {
                    "network": { ...action.payload?.data?.filterLayouts?.["NETWORK"]?.views?.filter(view => view.default === true)[0] || {} },
                    "transport": { ...action.payload?.data?.filterLayouts?.["TRANSPORT"]?.views?.filter(view => view.default === true)[0] || {} },
                    "orders": { ...action.payload?.data?.filterLayouts?.["ORDERS"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distoutbound": { ...action.payload?.data?.filterLayouts?.["DISTOUTBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distinbound": { ...action.payload?.data?.filterLayouts?.["DISTINBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                },
                currentView: { ...state.currentView, [action.payload.tableName]: action.payload.currentView }
            }

        case profileConstants.PROFILE_MARKFAV_SUCCESS:
            return {
                ...state,
                filterLayouts: action.payload.data.filterLayouts, initialLayouts: action.payload.data.filterLayouts,
                defaultViews: {
                    "network": { ...action.payload?.data?.filterLayouts?.["NETWORK"]?.views?.filter(view => view.default === true)[0] || {} },
                    "transport": { ...action.payload?.data?.filterLayouts?.["TRANSPORT"]?.views?.filter(view => view.default === true)[0] || {} },
                    "orders": { ...action.payload?.data?.filterLayouts?.["ORDERS"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distoutbound": { ...action.payload?.data?.filterLayouts?.["DISTOUTBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                    "distinbound": { ...action.payload?.data?.filterLayouts?.["DISTINBOUND"]?.views?.filter(view => view.default === true)[0] || {} },
                },
                defaultViewsFilters: {
                    "network": { ...action.payload?.data.filterLayouts?.["NETWORK"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['network'] },
                    "transport": { ...action.payload?.data.filterLayouts?.["TRANSPORT"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['transport'] },
                    "orders": { ...action.payload?.data.filterLayouts?.["ORDERS"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['orders'] },
                    "distoutbound": { ...action.payload?.data.filterLayouts?.["DISTOUTBOUND"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['distoutbound'] },
                    "distinbound": { ...action.payload?.data.filterLayouts?.["DISTINBOUND"]?.views?.filter(view => view.default === true)[0]?.filters || defaultdata['distinbound'] },
                }
            }

        case profileConstants.PROFILE_SAVE_FAILED:
            return state

        case profileConstants.PROFILE_MARKFAV_FAILED:
            return state

        case profileConstants.PROFILE_SET_CURRENT_VIEW:
            const appliedview = {
                ...action.payload.variables,
            };
            return {
                ...state, currentView: {
                    ...state.currentView,
                    [action.payload.tableName]: appliedview
                }
            }

        case profileConstants.SET_FIRST_LOAD:
            return { ...state, firstLoad: false }

        default:
            return state
    }
}

export default getFilterLayoutsReducer;