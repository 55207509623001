import React from "react";
import { useTranslation } from "react-i18next"
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import KCButton from "components/common/Button";
import {
    ClickAwayListener,
    Typography,
    makeStyles,
    FormGroup,
    Card,
} from '@material-ui/core';
import { generateFilterLayoutTemplate } from "helpers";
import { useDispatch } from "react-redux";
import { updatedOSMView, activateOSMView, hideOSMView, showHideAllOSMViews, updateOSMViewOrder } from "reduxLib/services";
import { generatePath, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { StyledTextField, StyledButton } from "components/common/FilterViewsMenu";
import DNDListOSM from "components/common/Table/TableElements/DNDListOSM";
import osmFilters from "reduxLib/constdata/osmFilters";
import { defaultOSMColumns } from "reduxLib/constdata/osmColumns";
import FilterViewIcon from "assets/images/table-view-dark.svg";
import Snack from 'components/common/Helpers/Snack';
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.down("sm")]: {
            padding: 0
        },
        float: "right",
    },
    addText: {
        width: "70%"
    },
    addButton: {
        width: "30%",
        backgroundColor: theme.palette?.primary.contrastText,
        color: theme.palette.white,
        '&.MuiButton-contained:hover': {
            backgroundColor: theme.palette?.background.primaryBase
        }
    },
    filterGrid: {
        padding: theme.spacing(2),
        flexGrow: 1,
        marginTop: 3,
        zIndex: 100,
        textAlign: 'left',
        right: theme.spacing(3),
        // backgroundColor: theme.palette?.form.baseLight,
        position: 'absolute',
        width: `${theme.spacing(50)}px`,
        maxHeight: `${theme.spacing(70)}px`,
        borderRadius: `${theme.spacing(0.5)}px`,
        // padding: `${theme.spacing(1)}px 0px`,
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    text: {
        pointerEvents: 'none',
        color: theme.palette?.text?.primary
    },
    iconbutton: {
        '&:hover, &:active': {
            backgroundColor: theme.palette?.primary.focus,
        }
    },
    button: {
        flexGrow: 1,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        border: `1px solid ${theme.palette?.primary.contrastText}`,
        textTransform: 'capitalize',
        textAlign: 'left',
        color: theme.palette?.text?.primary,
        '&:hover, &:active': {
            backgroundColor: theme.palette?.secondary.focus,
            border: `1px solid ${theme.palette?.chip.borderActive}`
        }
    },
    buttongroup: {
        '& > *': {
            margin: theme.spacing(1),
        },
        // width: "100%",
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.secondary.border}`
    },
    list: {
    },
    listitem: {
        // height: 45,
        borderBottom: `1px solid ${theme.palette.secondary.border}`,
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.primary.contrastText
    },
    parent: {
    },
    iconcircle: {
        display: 'block',
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
        borderRadius: '50%',
        backgroundColor: theme.palette?.extra.iconInactive,
        color: theme.palette?.text.caption,
        // opacity: 0.1,
        "&:hover": {
            // opacity: 1
            backgroundColor: theme.palette?.primary.contrastText,
            color: theme.palette?.text.main,
        }
    }
}));

const OsmManagerViews = ({ views }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [tabname, setTabName] = React.useState("");
    const [snack, setSnack] = React.useState({
        open: false,
        severity: "error",
        message: null
    });
    const { path, params } = useRouteMatch();

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = qs.parse(location.search);

    const handleButtonClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false)
    }

    const showHideAllViews = (hidden) => {
        dispatch(updateOSMViewOrder(views?.map(d => ({ ...d, hidden }))));
        dispatch(showHideAllOSMViews(hidden))
    }

    const changeOrder = (items) => {
        dispatch(updateOSMViewOrder(items));
    }

    const hideOSMViewAction = (item) => {
        const hiddenViews = views.map(d => ({
            ...d,
            hidden: item?.id === d.id ? !item.hidden : d.hidden
        }));
        dispatch(updateOSMViewOrder(hiddenViews));
        dispatch(hideOSMView(item?.id, !item.hidden));
    }

    const addView = (e) => {
        e.preventDefault();
        const duplicateViewName = views.map(d => d.name).filter(d => d?.toLowerCase() === tabname.toLowerCase())[0];

        if (duplicateViewName) {
            setSnack({
                ...snack,
                open: true,
                message: t("duplicate_view_error", { name: tabname })
            })
            return null
        }

        const newview = generateFilterLayoutTemplate({
            viewName: tabname,
            filters: osmFilters,
            columns: defaultOSMColumns.columnOrder,
            additional: {}
        });
        dispatch(updatedOSMView(newview));
        dispatch(activateOSMView(newview));

        const updatedpath = generatePath(path, {
            ...params,
            osmviewid: newview?.id
        });
        history.push({
            ...location,
            pathname: updatedpath,
            search: qs.stringify({
              ...queryParams,
              newtab: newview?.id
            }, { encode: false })
          });
        setTabName("");
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.root} data-testid="osmmanageviews">
                <Snack {...snack} handleClose={() => setSnack({
                    ...snack,
                    open: false
                })} />
                <KCButton
                    data-testid="osmmanageviews_btn"
                    onClick={handleButtonClick}
                    className={classes.iconbutton}
                >
                    <img src={FilterViewIcon} fontSize='medium' />
                </KCButton>
                {open && <Card
                    data-testid="osmviews_items"
                    className={classes.filterGrid}
                    elevation={10}
                >
                    <form onSubmit={addView} >
                        <FormGroup row >
                            <StyledTextField
                                value={tabname}
                                onChange={(e) => setTabName(e.target.value)}
                                variant="outlined"
                                required
                                className={classes.addText}
                                inputProps={{ "data-testid": "save_new_view_osm" }}
                                name="viewname"
                                placeholder={t('enter_a_view_name')}
                                size="small"
                            />
                            <StyledButton
                                className={classes.addButton}
                                variant="contained"
                                size="small"
                                type="submit"
                                data-testid="addview_btn">
                                {t('add_view')}
                            </StyledButton>
                            <Typography variant='body2' > {t('new_view_tab_msg')} </Typography>
                        </FormGroup>
                    </form>
                    <div className={classes.buttongroup}>
                        <KCButton data-testid="osm_showallbutton"
                            onClick={() => showHideAllViews(false)}
                            className={classes.button} startIcon={<VisibilityIcon />} >{t('show_all')}</KCButton>
                        <KCButton data-testid="osm_hideallbutton"
                            onClick={() => showHideAllViews(true)}
                            className={classes.button} startIcon={<VisibilityOffIcon />}>{t('hide_all')}</KCButton>
                    </div>
                    <DNDListOSM
                        items={views.map(d => ({ ...d, field: d.id, title: d.name }))}
                        changeOrder={changeOrder}
                        hideOSMViewAction={hideOSMViewAction}
                    />
                </Card>}
            </div>
        </ClickAwayListener>
    )
}

export default OsmManagerViews;