import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    table: {
        maxWidth: `${theme.spacing(5.5)}%`,
        margin: `${theme.spacing(0.125)}%`
    },
    tableCellHeader: {
        background: theme?.palette.secondary.base,
        borderBottom: "none",
        width: `${theme.spacing(2.5)}%`
    },
    tableCell: {
        borderBottom: "none",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        width: `${theme.spacing(2.5)}%`
    }
}));

function createData(name, yms, sap, fourkites, tms) {
    return { name, yms, sap, fourkites, tms };
}

const TransportDetailsTable = ({ originArrival, originDeparture, destArrival, destDeparture }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const rows = [
        createData('actual_arrival_at_origin', originArrival.arrivalYMS, originArrival.arrivalSAP, originArrival.arrivalFourkites, originArrival.arrivalTMS),
        createData('actual_departure_from_origin', originDeparture.deptYMS, originDeparture.deptSAP, originDeparture.deptFourkites, originDeparture.deptTMS),
        createData('actual_arrival_at_destination', destArrival.arrivalYMS, destArrival.arrivalSAP, destArrival.arrivalFourkites, destArrival.arrivalTMS),
        createData('actual_departure_from_destnation', destDeparture.deptYMS, destDeparture.deptSAP, destDeparture.deptFourkites, destDeparture.deptTMS),
    ];

    return (
        <TableContainer data-testid="transportDetailsTable">
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableCell}></TableCell>
                        <TableCell className={classes.tableCellHeader} align="left">
                            <Typography data-testid="yms" variant="h5">{t('yms')}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellHeader} align="left">
                            <Typography data-testid="sap" variant="h5">{t('sap')}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellHeader} align="left">
                            <Typography data-testid="fourkites" variant="h5">{t('fourkites')}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCellHeader} align="left">
                            <Typography data-testid="tms" variant="h5">{t('tms')}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={row.name}>
                            <TableCell className={classes.tableCell} size='small' component="th" scope="row">
                                <Typography variant="h5" data-testid={row.name}>{t(row.name)}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                <Typography variant="caption" data-testid={`${index}-yms`}>{row.yms}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                <Typography variant="caption"data-testid={`${index}-sap`}>{row.sap}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                <Typography variant="caption" data-testid={`${index}-fourkites`}>{row.fourkites}</Typography>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="left">
                                <Typography variant="caption" data-testid={`${index}-tms`}>{row.tms}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TransportDetailsTable;
