import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    OutlinedInput,
    MenuItem,
    FormControl,
    ListItemText,
    Select,
    Radio,
    makeStyles
} from '@material-ui/core';
import i18n from '../../i18n/i18n';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& .Mui-focused': {
            boxShadow: 'none',
            background: `${theme.palette.primary.pressed} !important`,
        },
        '& .MuiSelect-select:focus': {
            background: 'none !important',
        }
    },
    select: {
        height: 36,
        color: "#fff",
        transition: '.2s ease-in',
        background: 'transparent',
        '&:hover': {
            background: `${theme.palette.primary.pressed} !important`,
            borderColor: 'none',
        },
        '&:active': {
            background: `${theme.palette.primary.pressed} !important`,
            borderColor: 'none',
        },
        '&:focus': {
            backgroundColor: `red !important`,
            borderColor: 'none',
        },
        '& .MuiSvgIcon-root': {
            color: `${theme.palette.primary.main} !important`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    menu: {
        height: theme.spacing(4.5),
        padding: 0,
        '&:first-child': {
            paddingLeft: theme.spacing(2),
            paddingTop: 0
        },
        '&:focus': {
            backgroundColor: `${theme.palette.menu} !important`,
            color: `${theme.palette.text.primary} !important`,
        },
        '&:hover': {
            backgroundColor: theme.palette.background.selected
        },
        '&.MuiListItem-root.Mui-selected': {
            backgroundColor: theme.palette.background.selected,
            color: theme.palette.text.primary
        }
    },
    radio: {
        color: `${theme.palette.text.primary} !important`,
        '&.Mui-checked': {
            color: `${theme.palette.text.primary} !important`,
        },
    },
}));

export const ITEM_HEIGHT = 38;
export const ITEM_PADDING_TOP = 1;
export const MenuProps = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const namesNA = [
    {
        name: 'English',
        langCode: "en"
    },
    {
        name: 'Français',
        langCode: "fr"
    },
    {
        name: 'Deutsch',
        langCode: "de"
    }
];

const namesEMEA = [
    {
        name: 'English',
        langCode: "en"
    }
]

const namesLAO = [
    {
        name: 'Español',
        langCode: "es"
    }, 
    {
        name: 'English',
        langCode: "en"
    },   
    {
        name: 'Português ',
        langCode: "pt"
    }
];

const regionLanguageMap = {
    "NA": namesNA,
    "EMEA": namesEMEA,
    "LAO": namesLAO
}

const languageMapping = (region, languageObj) => {
    if (region === 'NA') {
        return ['en', 'fr', 'de'].includes(localStorage.getItem('i18nextLng'))
            ? languageObj.find(itm => itm.langCode === localStorage.getItem('i18nextLng'))
            : languageObj.find(itm => itm.langCode === 'en');
    } else if (region === 'LAO') {
        return ['en', 'es', 'pt'].includes(localStorage.getItem('i18nextLng'))
            ? languageObj.find(itm => itm.langCode === localStorage.getItem('i18nextLng'))
            : languageObj.find(itm => itm.langCode === 'en');
    } else {
        return 'en';
    }
}

export default function TranslationDropDown({ region }) {
    const classes = useStyles();
    let languageObj = regionLanguageMap[region];
    let langVal = languageMapping(region, languageObj);

    const [language, setLanguage] = React.useState(langVal.name);
    const { t } = useTranslation();
    const handleChange = (event) => {
        const { target: { value } } = event;
        let lang = languageObj.find(itm => itm.name === value)
        setLanguage(lang.name);
        i18n.changeLanguage(lang.langCode)
        localStorage.setItem('i18nextLng', lang.langCode)
    };

    return (
        <div>
            <FormControl className={classes.root} data-testid="language-dropdown">
                <Select
                    labelId="language-select"
                    className={classes.select}
                    id="language-select"
                    value={language}
                    onChange={handleChange}
                    input={<OutlinedInput sx={{ border: 'none' }} />}
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                    inputProps={{
                        "data-testid": "language-select"
                    }}
                >
                    <MenuItem disabled value="" className={classes.menu} data-testid="select-language">
                        {t("select_language")}
                    </MenuItem>
                    {regionLanguageMap[region].map((item) => (
                        <MenuItem key={item.name} value={item.name} className={classes.menu} data-testid={item.name}>
                            <Radio checked={item.name === language} className={classes.radio} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
