import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from 'react-use';
import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { viewByCuso } from 'configs/appConstants';
import SelectRadioDropdown from 'components/common/Elements/SelectRadioDropdown';
import EMEASalesGroups from './EMEASalesGroups';
import NoResult from 'components/header/NoResult';
import { emeaCustomerSearchService, resetCustomers, emeaCustFavService } from 'reduxLib/services/EmeaServices/emeaCustomerServices';
import { emeaDistChannelAndSalesOrgService } from 'reduxLib/services/EmeaServices/emeaFilterByServices';
import { emeaSalesOfficeGroupService, emeaSalesOfficeGroupReset, emeaSOSGFavService } from 'reduxLib/services/EmeaServices/emeaSalesOfficeGroupServices'; 
import { emeaUpdateFavs } from 'reduxLib/services/EmeaServices/emeaFavoriteService';
import { CssTextField } from './EmeaLocations';
import Snack from 'components/common/Helpers/Snack';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import qs from 'query-string';
import { isJsonParsable } from 'helpers';
import { businessUnits } from "configs/appConstants";
import SearchIcon from '@material-ui/icons/Search';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import FavoriteIcon from 'assets/images/favorite.svg';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import useSetQuery, { useUpdateTabWitOptions } from 'customHooks/useQuery';
import { generateSiteObject } from 'helpers/emeaHelpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    border: `1px solid ${theme.palette?.text?.primary}`,
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
    width: 250
  },
  select: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'none',
    }
  },
  icon: {
    fill: theme.palette?.text?.primary
  },
  menuList: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette?.background?.selected
    },
    '&.MuiListItem-root:hover': {
      backgroundColor: theme.palette?.background?.selected
    }
  },
  radio: {
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette?.secondary?.pressed
    }
  },
  cusoGrid: {
    maxHeight: 285,
    overflowX: 'auto',
    '& >div:not(:nth-child(3n))': {
      borderRight: `1px solid ${theme.palette.border}`
    },
    [theme.breakpoints.down("md")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      },
      '& >div:nth-child(odd)': {
        borderRight: `1px solid ${theme.palette.border}`
      }
    },
    [theme.breakpoints.down("sm")]: {
      '& >div:not(:nth-child(3n))': {
        borderRight: 'none'
      }
    }
  },
  cuso: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${theme.spacing(.5)}px 0px`,
    padding: `0px ${theme.spacing(3)}px`,
  },
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.secondary?.pressed
    }
  },
  office: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    color: theme.palette?.tooltip?.base
  },
  actionButton: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.primary?.button}`,
    color: theme.palette?.primary?.button,
    marginRight: theme.spacing(2),
  },
  noBorder: {
    border: `none !important`
  },
  cusoError: {
    textAlign: 'center',
    paddingTop: theme.spacing(5)
  },
  actionsGrid: {
    width: 'auto',
    border: `none !important`,
    position: 'absolute',
    bottom: theme.spacing(2.5)
  },
  allLocations: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: `${theme.spacing(.5)}px 0px`,
    border: 'none !important',
    marginTop: theme.spacing(2)
  },

}))

const EmeaCUSO = ({ region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const [params] = useSetQuery();

  const { dist_channel, sales_org } = useSelector(({ emeaChannelOrgs }) => emeaChannelOrgs);
  const { customers, cust_no_data, customer_search_err, customer_search_loading, cust_fav_loading, cust_fav_err } = useSelector(({ emeaCustomers }) => emeaCustomers);
  const { sales_offices, sales_ofc_err, sales_ofc_loading, sosg_fav_loading, sosg_fav_err } = useSelector(({ emeaSalesOfc }) => emeaSalesOfc);
  const { countries } = useSelector(({ emeaSites }) => emeaSites);
  const { all_favs } = useSelector(({ emeaGlobalFavs }) => emeaGlobalFavs);
  const SOSGfavs = { salesOfcFavs: [], salesGrpFavs: [] };

  const bu = isJsonParsable(queryParams?.BU) || businessUnits;
  const countryList = isJsonParsable(queryParams?.ctry) || countries;

  const [viewBy, setViewBy] = useState(viewByCuso[0]);
  const [salesOrg, setSalesOrg] = useState('');
  const [DC, setDC] = useState('Distribtn Channel 01');
  const [DCId, setDCId] = useState();
  const [salesOffice, setSalesOffice] = useState({});
  const [searchText, setSearchText] = useState(""); // global search bar for customer and sales office
  const [searchOrg, setSearchOrg] = useState(""); // sales org dropdown search
  const [salesGroupOpen, setSalesGroupOpen] = useState(false);
  const [custSelected, setCustSelected] = useState(isJsonParsable(queryParams?.Cust) || []);
  const [ofcSelected, setOfcSelected] = useState(isJsonParsable(queryParams?.Sofc) || []);
  const [sgSelected, setSGSelected] = useState(isJsonParsable(queryParams?.SG) || {});
  const [custFavs, setCustFavs] = useState(all_favs?.favouriteCustomers || []);
  const [SOFavs, setSOFavs] = useState(SOSGfavs.salesOfcFavs || []);
  const [SGFavs, setSGFavs] = useState(SOSGfavs.salesGrpFavs || []);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  });
  const updateTabWithOptions = useUpdateTabWitOptions();
  const [selectAllCustomers, setSelectAllCustomers] = useState(false);
  const [selectAllSalesOffc, setSelectAllSalesOff] = useState(false);

  const containsText = (text, search) => text.toLowerCase().indexOf(search?.toLowerCase()) > -1;
  const filteredOrgs = useMemo(() => {
    return sales_org?.filter((option) => {
      return containsText(`${option?.salesOrgId} (${option?.countryId})`, searchOrg)
    });
  },
    [searchOrg, sales_org]
  );

  const filteredOfc = useMemo(() => {
    return sales_offices?.filter((option) => {
      return containsText(`${option?.salesOffice} (${option?.countryId})`, searchText)
    });
  },
    [searchText, sales_offices]
  );
  
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        minWidth: 250,
      },
      'data-testid': 'dropdownFilter'
    },
    style: { zIndex: 1500 },
    getContentAnchorEl: null,
    autoFocus: false,
    anchorOrigin: {
      vertical: 42,
      horizontal: -12
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  };

  useEffect(() => {
    if (viewBy === 'Sales Office' && !isEmpty(DCId) && !isEmpty(salesOrg) ) {
      let org = generateSiteObject(salesOrg);
      dispatch(emeaSalesOfficeGroupService({
        body: {
          salesOrg: org?.id,
          distChannel: DCId?.distChannel,
          countryId: org?.code
        }
      }))
    }
  }, [DCId, salesOrg, viewBy])

  const handleSetViewBy = (event) => {
    setViewBy(event.target.value);
    setSearchText("");
  }

  const handleSetSalesOrg = (event) => {
    setSalesOrg(event.target.value);
    setSelectAllCustomers(false)
    setSelectAllSalesOff(false)
  }

  const handleSetDC = (event) => {
    setDC(event.target.value);
    const channelId = find(dist_channel, { distChannelName: event.target.value })
    setDCId(channelId);
  }

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  }

  const handleCustomerSelect = (event, custObj) => {
    if (event.target.checked) {
      setCustSelected([...custSelected, custObj.customerKey])
      const filterCustomers = customers.map(item => {
        return item.customerKey;
      })
      if (isEqual(filterCustomers.sort(), ([...custSelected, custObj.customerKey].sort()))) {
        setSelectAllCustomers(true);
      }
    } else {
      let x = custSelected.filter(cust => cust !== custObj.customerKey);
      setCustSelected(x);
      setSelectAllCustomers(false);
    }
  }

  const handleSalesOfcSelect = (event, soObj) => {
    if (event.target.checked) {
      setOfcSelected([...ofcSelected, soObj.salesOfficeKey])
      const filterSalesOff = filteredOfc.map(item => {
        return item.salesOfficeKey;
      })
      if (isEqual(filterSalesOff.sort(), ([...ofcSelected, soObj.salesOfficeKey].sort()))) {
        setSelectAllSalesOff(true);
      }
    } else {
      let y = ofcSelected.filter(ofc => ofc !== soObj.salesOfficeKey);
      setOfcSelected(y);
      setSelectAllSalesOff(false)
    }
  }

  const handleSalesGroupOpen = (event, SO) => {
    setSalesOffice(SO);
    setSalesGroupOpen(true);
  }

  const handleSnackClose = () => {
    setSnack({
        ...snack,
        open: false
    });
  };

  const handleSearchSubmit = (event) => {
    if (isEmpty(DCId) || isEmpty(salesOrg)) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: t('select_channel_org_err')
      });
    }
    event.preventDefault();
    
    if (viewBy === 'Customer') {
      let org = generateSiteObject(salesOrg);
      const searchPayload = {
        custSearchString: searchText,
        salesOrgList: [org?.id],
        distributionChannelList: [DCId?.distChannel],
        countryId: org?.code
      };
      if (isEmpty(searchText)|| isEmpty(DCId) || isEmpty(salesOrg)) return;
      dispatch(emeaCustomerSearchService(searchPayload));
    }
  }

  const updateSGSelections = (values) => {
    let selections = {
      ...sgSelected,
      ...values
    }
    for (const key in selections) {
      if(isEmpty(selections[key])) {
        delete selections[key]
      }
    }
    setSGSelected(selections)
  }

  const updateFavList = (favs) => {
    setSGFavs(favs);
  }

  const handleCustFav = (event, custKey) => {
    if(custFavs.includes(custKey)) {
      const y = custFavs.filter(x => x !== custKey);
      setCustFavs(y);
      if (custSelected.includes(custKey)) {
        const x = custSelected.filter(d => d !== custKey);
        setCustSelected(x);
      }
    } else {
      setCustFavs([...custFavs, custKey]);
      if (custSelected.includes(custKey) === false) 
        setCustSelected([...custSelected, custKey]);
    }
  }

  const handleSOFav = (event, key) => {
    if(SOFavs.includes(key)) {
      const z = SOFavs.filter(x => x !== key);
      setSOFavs(z);
      if (ofcSelected.includes(key)) {
        const k = ofcSelected.filter(d => d !== key);
        setOfcSelected(k);
      }
    } else {
      setSOFavs([...SOFavs, key]);
      if (ofcSelected.includes(key) === false) 
        setOfcSelected([...ofcSelected, key]);
    }
  }

  const handleAllCustomers = (e) => {
    if (e.target.checked) {
      const filterCustomers = customers.map(item => {
        return item.customerKey;
      })
      setCustSelected(filterCustomers);
      setSelectAllCustomers(true);
    } else {
      setCustSelected([]);
      setSelectAllCustomers(false);
    }
  }

  const handleAllSalesOffice = (e) => {
    if (e.target.checked) {
      const filterSalesOff = filteredOfc.map(item => {
        return item.salesOfficeKey;
      })
      setOfcSelected(filterSalesOff);
      setSelectAllSalesOff(true)
    } else {
      setOfcSelected([])
      setSelectAllSalesOff(false)
    }
  }

  const saveAllSelections = () => {
    if(viewBy === "Customer") {
      updateTabWithOptions({ key: "Cust", value: custSelected }, {}, params.sites, custSelected, params.sofcNSG);
      if(!isEqual(all_favs?.favouriteCustomers, custFavs)) {
        dispatch(emeaCustFavService({ region: region, favouriteCustomers: custFavs }));
      }
    } else {
      let sofcNSG = [];
      const ofcUpdated = ofcSelected || [];
      const k = Object.keys(sgSelected)?.map(d => {
        if(!ofcUpdated.includes(d)) {
          ofcUpdated.push(d)
        }
        if(sgSelected[d]?.length) {
          sgSelected[d].map(e => {
            sofcNSG.push(`${d}_${e}`);
            return e;
          })
        } else {
          sofcNSG.push(d);
        }
      });
      sofcNSG.push(...ofcUpdated);
      const sgKeys =  Object.keys(sgSelected);
      let x = sofcNSG?.map(d => {
        if(!isEmpty(sgSelected[d]) && sgKeys?.includes(d)) {
          sofcNSG = sofcNSG.filter(item => item !== d);
        } 
      });
      const org = generateSiteObject(salesOrg);
      updateTabWithOptions({ key: "Sofc", value: sofcNSG } , {
        Sofc: JSON.stringify(ofcUpdated),
        SG: JSON.stringify(sgSelected),
        sofcNSG: JSON.stringify(sofcNSG),
        selectAllOrgs: selectAllSalesOffc ? `${org.id}_${DCId.distChannel}_${org.code}` : undefined,
        Cust: JSON.stringify(params.Cust)
      }, params.sites, params.Cust, sofcNSG);

      const z = [...SOFavs, ...SGFavs];
      if(!isEqual(all_favs?.favouriteSalesOffices, z)) {
        dispatch(emeaSOSGFavService({ region: region, favouriteSalesOffices: z }));
      }  
    }
  }

  const clearAllSelections = () => {
    if(viewBy === "Customer") {
      setCustSelected([]);
      setSelectAllCustomers(false);
      updateTabWithOptions({ key: "Cust", value: [] }, {}, params.sites, [], params.sofcNSG);
    } else {
      setOfcSelected([]);
      setSGSelected({});
      setSelectAllSalesOff(false);
      updateTabWithOptions({ key: "Sofc", value: [] } , {
        Sofc: JSON.stringify([]),
        SG: JSON.stringify({}),
        sofcNSG: JSON.stringify([]),
        selectAllOrgs: undefined,
        Cust: JSON.stringify(params.Cust)
      }, {}, params.sites, params.Cust, []);
    }
  }

  useDeepCompareEffect(() => {
    const x = all_favs?.favouriteSalesOffices?.map(d => {
      const name = d?.split('_');
      name?.length > 3 ? SOSGfavs.salesGrpFavs.push(d) : SOSGfavs.salesOfcFavs.push(d);
    })  
  }, [all_favs?.favouriteSalesOffices])

  useEffect(() => {
    if(!cust_fav_loading && cust_fav_err && cust_fav_err !== 'success') {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: t(cust_fav_err)
      });
    }
    if(!cust_fav_loading && cust_fav_err === 'success') {
      const body = {...all_favs, favouriteCustomers: custFavs }
      dispatch(emeaUpdateFavs(body));
    }
  }, [cust_fav_err, cust_fav_loading])

  useEffect(() => {
    if(!sosg_fav_loading && sosg_fav_err && sosg_fav_err !== 'success') {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        message: t(sosg_fav_err)
      });
    }
    if(!sosg_fav_loading && sosg_fav_err === 'success') {
      const body = {...all_favs, favouriteSalesOffices: [...SOFavs, ...SGFavs] }
      dispatch(emeaUpdateFavs(body));
    }
  }, [sosg_fav_err, sosg_fav_loading])

  useDeepCompareEffect(() => {
    if(!isEmpty(dist_channel)) {
      setDC(dist_channel[0]?.distChannelName);
      setDCId(dist_channel[0]);
    }
  }, [dist_channel])

  useEffect(() => {
    dispatch(emeaDistChannelAndSalesOrgService({
      body: {
        countryIds: countryList?.length ? countryList.join(',') : countries.join(','),
        bu
      }
    }))
    return () => {
      dispatch(resetCustomers());
      dispatch(emeaSalesOfficeGroupReset());
    }
  }, []);

  return (
    <>
    { 
    !salesGroupOpen ?
      <Grid container data-testid='cuso'>
        <Snack {...snack} handleClose={handleSnackClose} />
        <Grid container item spacing={2}>
          <Grid item sm={6} md={4} lg={3}>
            <Typography variant="h6" > {t('view_by')} </Typography>
            <SelectRadioDropdown labelId="viewBy-select-label" testId="select-viewBy" value={viewBy} MenuProps={MenuProps} title={t('view_by')} data={viewByCuso}
              onChange={handleSetViewBy} styleClasses={classes} inputProps={{ classes: { icon: classes.icon } }} />
          </Grid>

          <Grid item sm={6} md={4} lg={3}>
            <Typography variant="h6"> {t('sales_org')} * </Typography>
            <SelectRadioDropdown labelId="salesOrg-select-label" testId="select-salesOrg" value={salesOrg} MenuProps={MenuProps} title={t('sales_org')} data={filteredOrgs} keyType="siteNumCountryCode"
              onChange={handleSetSalesOrg} styleClasses={classes} inputProps={{ classes: { icon: classes.icon } }} searchBar={true} searchValue={searchOrg} searchFunction={setSearchOrg} />
          </Grid>

          <Grid item sm={6} md={4} lg={3}>
            <Typography variant="h6"> {t('distribution_channel')} * </Typography>
            <SelectRadioDropdown labelId="dc-select-label" testId="select-dc" value={DC} MenuProps={MenuProps} title={t('distribution_channel')} data={dist_channel} keyType="distChannelName"
              onChange={handleSetDC} styleClasses={classes} inputProps={{ classes: { icon: classes.icon } }} />
          </Grid>

          <Grid item sm={6} md={4} lg={3}>
            <Typography variant="h6"> {t('search')} </Typography>
            <form className={classes.root} noValidate autoComplete="off" data-testid="searchfield" onSubmit={(e) => handleSearchSubmit(e)}>
              <CssTextField id="id-cuso-search" placeholder={viewBy === 'Customer' ? 'Ex: IKEA*' : 'Ex: CS01'} value={searchText} onChange={(e) => handleSearch(e)} variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                inputProps={{ 'data-testid': 'cusoSearch' }}
              />
            </form>
          </Grid>
        </Grid>

        {viewBy === 'Customer' ? 
        <>
        {customers?.length ? 
        <Grid container sm={12} md={12} lg={12} className={classes.allLocations} data-testid="select-all-customers">
          <Grid item>
            <Checkbox name='selectAllCustomer' checked={selectAllCustomers} onChange={handleAllCustomers} className={classes.checkbox}
              inputProps={{'data-testid': 'select-all-customer'}} />                              
          </Grid>          
          <Typography variant='body1'>{t('select_all')}</Typography> 
        </Grid> : null}
          <Grid container item className={classes.cusoGrid}>
            { customer_search_loading ?
            <Grid xs={12} className={classes.cusoError} data-testid="customers-loading">
              <Typography variant='body1'> { t('please_wait_while_the_data_is_loading') } </Typography>
            </Grid>
            : 
            customers?.length ? customers.map(cust => {
            return (
              <Grid container item sm={12} md={6} lg={4} className={classes.cuso} data-testid="customer-container">
                <Typography variant='body1'> {cust?.customerName} </Typography>
                <Grid item >
                  <Checkbox name={cust?.customerName} checked={custSelected.indexOf(cust?.customerKey) > -1} onChange={(e) => handleCustomerSelect(e, cust)} className={classes.checkbox}
                    inputProps={{ 'data-testid': `select_${cust?.customerKey}` }} />
                  <IconButton aria-label="fav-button" onClick={(e) => handleCustFav(e, cust?.customerKey)} disabled={cust_fav_loading} data-testid={`fav-${cust?.customerKey}`} >
                    <img alt="fav-icon" src={custFavs.includes(cust?.customerKey) ? FavoriteIcon : NotFavoriteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            )
            }) : cust_no_data ?
            <Grid xs={12} className={classes.noBorder} >
              <NoResult value={searchText} searchType={"Customers"} />
            </Grid>
            : customer_search_err ?
            <Grid xs={12} className={classes.cusoError}>
              <Typography variant='body1'> { t(customer_search_err) } </Typography>
            </Grid>
            : null
            }
          </Grid>
          </>
        :
        <>
        {sales_offices?.length ? 
        <Grid container sm={12} md={12} lg={12} className={classes.allLocations} data-testid="select-all-sales-offices">
                <Grid item>
                  <Checkbox name='selectAllCustomer' checked={selectAllSalesOffc} onChange={handleAllSalesOffice} className={classes.checkbox}
                    inputProps={{'data-testid': 'select-all-sales-office'}} />                              
                </Grid>          
                <Typography variant='body1'>{t('select_all')}</Typography> 
              </Grid> : null }           
          <Grid container item className={classes.cusoGrid}>
            {sales_ofc_loading ?
              <Grid xs={12} className={classes.cusoError} data-testid="salesOfc-loading">
                <Typography variant='body1'> { t('please_wait_while_the_data_is_loading') } </Typography>
              </Grid>
              : 
              sales_offices?.length ? 
              filteredOfc?.map(ofc => {
              return (
                <Grid container item sm={12} md={6} lg={4} className={classes.cuso} data-testid="sales-office-container">
                  <Typography variant='body1' className={classes.office} onClick={(e) => handleSalesGroupOpen(e, ofc)} data-testid={`SO_${ofc?.salesOffice}`}>
                    {ofc?.salesOffice} 
                    {sgSelected?.[ofc?.salesOfficeKey]?.length ? <DoubleArrowIcon fontSize='small' />: null}
                  </Typography>
                  <Grid item>
                    <Checkbox name={ofc?.salesOffice} checked={ofcSelected.indexOf(ofc?.salesOfficeKey) > -1} onChange={(e) => handleSalesOfcSelect(e, ofc)} className={classes.checkbox}
                      inputProps={{ 'data-testid': `select_${ofc?.salesOffice}` }} />
                    <IconButton aria-label="fav-button" onClick={(e) => handleSOFav(e, ofc?.salesOfficeKey)} disabled={sosg_fav_loading} data-testid={`fav-${ofc?.salesOfficeKey}`} >
                      <img alt="fav-icon" src={SOFavs.includes(ofc?.salesOfficeKey) ? FavoriteIcon : NotFavoriteIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            }): sales_ofc_err ? 
              <Grid xs={12} className={classes.cusoError}>
                <Typography variant='body1'> { t(sales_ofc_err) } </Typography>
              </Grid>
            : null
            }
          </Grid> </>
        }
      </Grid>
      :
      <EMEASalesGroups 
        setSalesGroupOpen={setSalesGroupOpen} 
        salesOrg={salesOrg} 
        salesOffice={salesOffice} 
        selected={sgSelected?.[salesOffice?.salesOfficeKey] || []}
        updateSelections={updateSGSelections}
        favList={SGFavs}
        updateFavList={updateFavList}
        loading={sosg_fav_loading}
      />
      }
      {
        [...(viewBy === "Customer" ? customers: sales_offices)].length ? 
        <Grid container item className={classes.actionsGrid}>
          <Button variant="outlined" className={classes.actionButton} data-testid="save-selections" onClick={saveAllSelections} > {t('apply_name_changes', { name: viewBy })} </Button>
          <Button variant="outlined" data-testid="clear-selections" className={classes.actionButton} onClick={clearAllSelections}> {t('clear_name_selections', { name: viewBy })} </Button>
        </Grid> : null
      }
    </>
  )
}

export default EmeaCUSO;