import React from "react";
import { Grid, Button, makeStyles, Typography } from "@material-ui/core";
import { viewStyles } from "theme";
import ExpandLess from '@material-ui/icons/ExpandLessRounded';
import ExpandMore from '@material-ui/icons/ExpandMoreRounded';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(viewStyles);

export default ({ expand, change }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container justify="center">
      <Button data-testid='expandbutton' className={classes.expand} onClick={() => change(!expand)}>
        {
          !expand ? <ExpandMore className={classes.icon} /> : <ExpandLess className={classes.icon} />
        }
        <Typography variant="h3" style={{ pointerEvents: 'none' }}>{t("view")} {!expand ? t('more'): t('less')}</Typography>
      </Button>
    </Grid>

  )
}