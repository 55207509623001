import React from 'react';
import D3Tooltip from "./D3Tooltip";
import { scaleOrdinal, pie as d3Pie, arc } from 'd3';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { formatLocaleNumber, truncateText } from 'helpers/emeaHelpers';

const useStyles = makeStyles((theme) => ({
    circle: {
        textTransform: "capitalize",
        cursor: "pointer",
        '&:before': {
            display: "inline-block",
            content: '""',
            marginRight: theme.spacing(.5),
            borderRadius: "50%",
            backgroundColor: ({ color }) => color,
            height: theme.spacing(1.5),
            width: theme.spacing(1.5),
        },
    },
    text: {
        pointerEvents: 'none',
        ...theme.typography.body2
    },
    hyperlink: {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.secondary?.accent,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
        letterSpacing: '1px',
        padding: theme.spacing(.4),
        '&:hover': {
            color: theme.palette.link.hover
        }
    },
}));


const Pies = ({ data, radius = 40, setDetails, xKey, tooltipEvent }) => {

    const colors = scaleOrdinal()
        .domain(data.map(d => d[xKey]))
        .range(useTheme().palette['colorPanelEMEA']);

    const pie = d3Pie()
        .value(d => d.value);

    const pieData = pie(data);

    const arcPath = arc()
        .innerRadius(radius - 25)
        .outerRadius(radius)


    return pieData.map((item, i) => {
        const tooltipGenerator = typeof tooltipEvent === "function" ? tooltipEvent(item) :
            formatLocaleNumber(item.value);
        return (
            <D3Tooltip
                key={i}
                placement={"top"}
                color={colors(item.data[xKey])}
                title={<div
                    data-testid="pie-tooltip"
                    onClick={() => {
                        if (typeof setDetails === "function") {
                            setDetails(item, data)
                        }
                    }}>
                    {item.data[xKey]} : {tooltipGenerator}
                </div>}>
                <path
                    key={i}
                    d={arcPath(item)}
                    fill={colors(item.data[xKey])}
                    data-testid={`id-${item.data[xKey]}`}
                    value={item.value}
                />
            </D3Tooltip>
        )
    })
}

export const LegendText = ({
    label,
    color,
    style = "circle",
    setDetails,
    data,
    isHyperlink,
    tooltipLabel,
    tooltipExclusion
}) => {
    const classes = useStyles({ color });
    return (
        <Grid item data-testid="legend-text">
            <D3Tooltip title={<Typography
                component='p'
                color={color}
                className={classes[style]}
                data-color={color}>
                {label}
            </Typography>}
                placement="top" themeType={"light"}>
                <Typography
                    component='p'
                    variant='body2'
                    color={color}
                    data-testid={`linkid-${label}`}
                    onClick={() => {
                        if (isHyperlink && !tooltipExclusion.includes(data?.state)) {
                            setDetails(data)
                        }
                    }}
                    className={{
                        [classes[style]]: true,
                        [classes.hyperlink]: !tooltipExclusion.includes(data?.state) && isHyperlink,
                    }}
                    data-color={color}>
                    {truncateText(tooltipLabel)}
                </Typography>
            </D3Tooltip>
        </Grid>
    )
}

export const Legends = ({
    data,
    xKey,
    colorScale,
    setDetails,
    tooltipKey = null,
    displayKey = null,
    tooltipExclusion = [],
    viewDetails
}) => {
    const colorRange = useTheme().palette['colorPanelEMEA'];

    let colorPanel = scaleOrdinal().domain(data.map(d => d[xKey])).range(colorRange);
    const isHyperlink = typeof setDetails === "function";

    if (typeof colorScale === "function") {
        colorPanel = colorScale
    }

    return (<Box p={2} m={viewDetails ? 0 : 1}>
        <Grid
            container
            alignItems="center"
            justify="center"
            spacing={2}
            data-testid="legend-wrapper"
        >
            {
                data?.map(d => {
                    return <LegendText
                        key={d[xKey]}
                        label={d[displayKey || xKey]}
                        tooltipLabel={d[tooltipKey || xKey]}
                        color={colorPanel(d[xKey])}
                        xs={4}
                        isHyperlink={isHyperlink}
                        setDetails={setDetails}
                        data={d}
                        tooltipExclusion={tooltipExclusion}
                    />
                })
            }
        </Grid>

    </Box>)
}

export const PieLabel = ({ title, subtitle, labelWidth = 50 }) => {
    const labelHeight = 40;
    return (
        <g data-testid={`pielabel-${title}`}>
            <foreignObject height={labelHeight} width={labelWidth} transform={`translate(${-labelWidth / 2}, ${-labelHeight / 2})`} >
                <Typography style={{ textAlign: "center" }} variant="body2" color="textSecondary">{title}</Typography>
                <Typography style={{ textAlign: "center" }} variant="body2" color="textSecondary">{subtitle}</Typography>
            </foreignObject>
        </g>
    )
}

export default Pies;