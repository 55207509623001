import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { emeaEditCommentService, resetComments } from 'reduxLib/services/EmeaServices/emeaEditCommentService';
import Snack from 'components/common/Helpers/Snack';
import isEqual from 'lodash/isEqual';
import EditIcon from 'assets/images/edit-dark.svg';
import SaveIcon from 'assets/images/save-dark.svg';
import SaveDisabledIcon from 'assets/images/save-disabled.svg';

const useStyles = makeStyles((theme) => ({
  comment: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    cursor: 'pointer',
    padding: `0px ${theme.spacing(.5)}px`
  },
  disabledIcon: {
    padding: `0px ${theme.spacing(.5)}px`
  },
  value: {
    whiteSpace: 'pre-line'
  }
}));

const EmeaTableComments = ({ id, value, rowId, siteNum }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { edit_comment_loading, edit_comment_status, edit_comment_error } = useSelector(({ emeaTableComment }) => emeaTableComment);

  const [edit, setEdit] = useState(false);
  const [comment, setComment] = useState(value);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });

  const handleSaveComment = () => {
    const body = {
      region: 'EMEA',
      itemRowId: rowId,
      userComment: comment,
      originSiteNum: siteNum
    }
    dispatch(emeaEditCommentService(body));
    setEdit(false);
  }

  const handleClose = (event, reason) => {
    setSnack({
      ...snack,
      open: false
    });
  };

  useEffect(() => {
    if(!edit_comment_loading && edit_comment_error) {
      setSnack({
        ...snack,
        open: true,
        severity: edit_comment_status === 'success' ? "success" : "error",
        message: t(edit_comment_error)
      });
      setEdit(false);
      dispatch(resetComments());
    }
  }, [edit_comment_loading, edit_comment_status, edit_comment_error])

  return (
    <div data-testid={id} className={classes.comment} >
      {edit ? <input name="comments" value={comment} onChange={e => setComment(e.target.value)} data-testid="edit-input" />
        : <span className={classes.value} > { value || "-" } </span>
      }
      <img src={EditIcon} alt="edit" className={classes.icon} onClick={() => setEdit(!edit)} data-testid="edit-comment" />
      {isEqual(comment, value) ? <img src={ SaveDisabledIcon } alt="save-disabled" className={classes.disabledIcon} /> 
        : <img src={ SaveIcon } alt="save" className={classes.icon} onClick={() => handleSaveComment()} data-testid="save-comment" />
      }
      <Snack {...snack} handleClose={handleClose} />
    </div>
  );
}

export default EmeaTableComments;