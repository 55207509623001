import React from 'react'
import truncate from 'lodash/truncate'
import * as d3Axis from 'd3-axis'
import { select as d3Select, format, selectAll } from 'd3'

const wrap = (text, width, color, showXAxisLabel, rotateLabel, showEllipsis) => {
  text.each(function (d, i) {
    const element = d3Select(this);
    const words = element.html(d).text();
    const transform = `translate(${-width / 2}, ${0})`;

    const foreign = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject");
    foreign.setAttribute('width', width);
    foreign.setAttribute('height', 80);
    foreign.setAttribute("transform", transform)

    const iDiv = document.createElement('div');
    iDiv.setAttribute("class", "chartlabel")
    iDiv.setAttribute('style', `width: ${width}px; cursor: pointer;`)

    if (rotateLabel) {
      iDiv.setAttribute('style', `position: absolute;white-space: nowrap; transform: rotate(-45deg); top: 10px;cursor: pointer;`)
    }
    const wordContent = document.createTextNode(words);
    const iText = document.createElement('p');
    iText.setAttribute("class", "chartlabeltext"); 
    iText.setAttribute("data-testid", "chart-axis-text"); 
    if(showEllipsis) {
      iText.setAttribute("class", "chartlabeltext text-with-ellipsis");
    }
    iText.setAttribute("title", words);
    iText.appendChild(wordContent)
    iDiv.appendChild(iText);
    foreign.appendChild(iDiv);
    if (!showXAxisLabel) {
      return null;
    }
    d3Select(this).node().appendChild(foreign);
  });
}

const Axis = (props) => {
  const { wrapit, horizontal, diverging, divergingScales,
     yLabel, showXAxisLabel = true, rotateLabel = false, 
     dx = "-50%", xLabel, showEllipsis, suffix="",
    } = props;

  const axisElement = React.createRef();

  const renderAxis = (ref) => {
    const axisType = `axis${props.orient}`
    const axis = diverging ? (props.orient === "Left" ? divergingScales.divergingY : divergingScales.divergingX) : d3Axis[axisType]()
      .scale(props.scale)
      // .tickSize(-props.tickSize)
      .tickPadding([3])
      .ticks([4])
      .tickFormat(d => `${format(".0s")(d)}${suffix}`)



    d3Select(ref.current).call(axis);
    if (wrapit && !horizontal && !diverging) {
      selectAll(ref.current.querySelectorAll('g'))
      .call(wrap, props.scale.bandwidth() + 20, 'white', showXAxisLabel, rotateLabel, showEllipsis);
    } else if (diverging && divergingScales.divergingY) {
      selectAll(ref.current.querySelectorAll('.Axis-Left .tick text')).attr("text-anchor", "start")
      .attr("x", 6).attr("display","none")
    }
    if (horizontal) {
      selectAll(ref.current.querySelectorAll('.Axis-Left>.tick>text'))
        .text(d => truncate(d, { 'length': 15, 'omission': '...' }))
        .append("svg:title")
        .text(d => d)
    }

    
  }

  React.useEffect(() => {
    renderAxis(axisElement);
  }, [props.svgDimensions]);

  return (
    <>
      {
        yLabel &&
        <g transform={`translate(${30}, ${props.svgDimensions.height/2 - props.margins?.top})`} width="100%">
            <text
              transform={"rotate(-90)"} data-testid="y-axis-label"
              textAnchor="middle">
              {yLabel}
            </text>
        </g>
      }
      <g
        data-testid='d3axis'
        className={`Axis Axis-${props.orient}`}
        ref={axisElement}
        transform={props.translate}
      />
      {
        xLabel &&
        <g transform={`translate(${props.margins?.left - 20}, ${props.svgDimensions.height + 10})`} width="100%">
          <text
            dx={"50%"} data-testid="x-axis-label"
            textAnchor="middle">
            {xLabel}
          </text>
        </g>
      }
    </>
  )
}

export default Axis;