import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";
import OsmLDLogsDownload from "../../components/osm/OsmLDLogsDownload";

export const OSMLDSubmissionLogColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNum",  
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },{
      title: 'submitted_date_time', field: "submittedDateTime",  
      render: rowData => {
        return <CustomCellRender id="submittedDateTime" value={rowData.submittedDateTime} />
      }
    },{
      title: 'submitted_by', field: "submittedBy",  
      render: rowData => {
        return <CustomCellRender id="submittedBy" value={rowData.submittedBy} />
      }
    },{
      title: 'error_message', field: "errorMessage",  
      render: rowData => {
        return <CustomCellRender id="errorMessage" value={rowData.errorMessage} />
      }
    },{
      title: 'ld_submission_status', field: "ldSubmissionStatus",  
      render: rowData => {
        return <CustomCellRender id="ldSubmissionStatus" value={rowData.ldSubmissionStatus} />
      }
    },{
      title: 'inbound_file', field: "inbound",  
      render: rowData => {
        return <OsmLDLogsDownload id="inbound" value={rowData} type="request" />
      }
    },{
      title: 'outbound_file', field: "outbound",  
      render: rowData => {
        return <OsmLDLogsDownload id="outbound" value={rowData} type="response" />
      }
    }
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}