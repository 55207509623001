import React from "react";
import { withOktaAuth } from '@okta/okta-react';
import { useHistory, useLocation } from "react-router-dom";
import { batch, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import {
    setBusinessLocalAction,
    addItemsCuso,
    addItemsLocations,
    showhideFavourites,
    getLocationsService
} from "reduxLib/services";
import { useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import { isJsonParsable } from "helpers";
import LoadingPage from "components/common/LoadingPage";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => {
        return new URLSearchParams(search.replace("search=?", ""));
    }, [search]);
}

function RedirectManager(props) {
    const { authState, authService } = props;

    global.authService = authService;

    const dispatch = useDispatch();
    const history = useHistory();

    const { locations } = useSelector(({ sites: { locations } }) => ({ locations }))

    let query = useQuery();

    useDeepCompareEffect(() => { 
        const page = query.get("page");
        if (authState.isAuthenticated && page === "EMEA") {
            const exclude = ["page", "path"];
            const params = [];
            query.forEach((_, key) => {
                if (exclude.includes(key)) return;
                params.push(
                    query.get(key) ? `${key}=${encodeURIComponent(query.get(key))}` : undefined
                )
            });
            history.replace({
                pathname: query.get("path") || "/dashboard/EMEA/network/home",
                search: params.filter(d=>d).join("&")
            });
        } else if (authState.isAuthenticated && page === "LAO") {
            const exclude = ["page", "path"];
            const params = [];
            query.forEach((_, key) => {
                if (exclude.includes(key)) return;
                params.push(
                    query.get(key) ? `${key}=${query.get(key)}` : undefined
                )
            });
            history.replace({
                pathname: query.get("path") || "/dashboard/LAO/network/home",
                search: params.filter(d=>d).join("&")
            });
        } else if (authState.isAuthenticated) {
            const global = isJsonParsable(query.get("global")) || { bu: [], cuso: [], sites: [] }
            const isOSMPage = query.get("path")?.search("\/osm\/") > 0;

            const params = [
                query.get("search"),
                query.get("isHyperlink") ? `isHyperlink=${query.get("isHyperlink")}` : undefined,
                query.get("viewid") ? `viewid=${query.get("viewid")}` : undefined,
                query.get("name") ? `name=${query.get("name")}` : undefined,
                query.get("hyperlinks") ? `hyperlinks=${query.get("hyperlinks")}` : undefined,
                query.get("evaluate") ? `evaluate=${query.get("evaluate")}` : undefined,
                // query.get("filters") ? `filters=${query.get("filters")}` : "filters={}",
                // query.get("global") ? `global=${query.get("global")}` : "global={bu:[],cuso:[],sites:[]}",
                isOSMPage ? undefined: query.get("filters") ? `filters=${query.get("filters")}` : "filters={}",
                query.get("osmfilters") ? `osmfilters=${query.get("osmfilters")}` : "osmfilters={}",
                query.get("osmglobal") ? `osmglobal=${query.get("osmglobal")}` : "osmglobal={}",
                query.get("activity") ? `activity=${query.get("activity")}` : undefined,
                query.get("page") ? `page=${query.get("page")}` : "page=0",
                query.get("pageSize") ? `pageSize=${query.get("pageSize")}` : "pageSize=50",
                query.get("shipment") ? `shipment=${query.get("shipment")}` : undefined,
                isOSMPage ? undefined: query.get("global") ? `global=${query.get("global")}` : "global={bu:[],cuso:[],sites:[]}",
            ].filter(d => d);

            const redirect = () => {
                history.replace({
                    pathname: query.get("path") || "/dashboard/mylocation/locations/home",
                    search: params.join("&")
                });
            }
            if (locations?.length === 0) {
                dispatch(getLocationsService());
            }
            if (!isEmpty(global)) {
                if (locations.length > 0) {
                    const runBatch = async () => {
                        await batch(() => {
                            const CUSOpayload = !isEmpty(global?.cuso) ? global?.cuso.reduce((acc, item) => item.selectionType === "CUST" ?
                                { ...acc, CUST: [...acc.CUST, item] } : { ...acc, SALES_OFFICE: [...acc.SALES_OFFICE, item] }, { CUST: [], SALES_OFFICE: [] }) : { CUST: [], SALES_OFFICE: [] }
                            const sites = global?.sites?.length > 0 ? locations.filter(d => global.sites.includes(d.siteNum)) : []
                            dispatch(showhideFavourites(false));
                            dispatch(addItemsCuso(CUSOpayload));
                            dispatch(addItemsLocations(sites))
                            dispatch(setBusinessLocalAction(global?.bu));
                        });

                    }
                    runBatch();
                    redirect();
                }
            } else {
                redirect();
            }
        }
    }, [authState.isAuthenticated, locations])

    return (<LoadingPage />);
}



export default withOktaAuth(RedirectManager);