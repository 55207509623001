import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { saveFilters, saveSelfAlertFilters } from "reduxLib/services";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    numContainer: {
        display: 'flex'
    },
    minmaxTitle: {
        margin: theme.spacing(0.625),
    },
    toStyle: {
        margin: `${theme.spacing(6.25)}px ${theme.spacing(2)}px ${theme.spacing(0)}px`
    },
    errorTextStyle: {
        marginTop: theme.spacing(1.25),
        color: theme.palette?.error?.main
    }

}));

const NumRangeElement = (props) => {

    const { filterKey, type, subtype, selfalert, customSaveFilters } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const filter = useSelector(({ options }) => options?.filters?.[subtype]);
    const saveFiltersSF = useSelector(({ selfalerting }) => selfalerting?.filters);
    const filters = selfalert ? saveFiltersSF?.[filterKey] : filter?.[filterKey];

    const saveFiltersActionDispatch = selfalert ? saveSelfAlertFilters : saveFilters;

    const [input, setInput] = useState(filters || {});

    const handleSubmit = (e) => {
        e.preventDefault();
        let gte = input.data.gte ? Number(input.data.gte) : null;
        let lte = input.data.lte ? Number(input.data.lte) : null;

        if ((gte && lte && ((gte < lte) || (gte === lte))) || (gte && !lte && lte !== 0) || (!gte && lte && (gte < lte)) || (gte === 0 && lte && (gte < lte))) {
            const savePayload = {
                filter: {
                    [filterKey]: {
                        ...filters,
                        data: {
                            gte: gte,
                            lte: lte
                        }
                    }
                }, type, subtype
            };
            if (typeof customSaveFilters === "function") {
                customSaveFilters(savePayload);
                return;
            }
            dispatch(saveFiltersActionDispatch(savePayload))
        }
    }

    useDeepCompareEffect(() => {
        if (filters?.data) {
            setInput(filters);
        } else {
            setInput({
                ...filters,
                data: {
                    gte: null,
                    lte: null
                }
            })
        }
    }, [filters?.data]);

    return (
        <>
            <div className={classes.numContainer} data-testid="numRangeElement">
                <form onSubmit={handleSubmit}>
                    <Typography className={classes.minmaxTitle}>{t('min')}</Typography>
                    <TextField
                        value={input?.data?.gte || ''}
                        inputProps={{ "data-testid": "minRange", min: 0 }}
                        onChange={e => setInput({
                            ...input,
                            data: {
                                ...input.data,
                                gte: e.target.value
                            }
                        })}
                        variant="outlined"
                        placeholder={t('enter_value')}
                        type='number'
                    />
                </form>
                <Typography className={classes.toStyle}>{t('to')}</Typography>
                <form onSubmit={handleSubmit}>
                    <Typography className={classes.minmaxTitle}>{t('max')}</Typography>
                    <TextField
                        value={input?.data?.lte || ''}
                        inputProps={{ "data-testid": "maxRange", min: 0 }}
                        onChange={e => setInput({
                            ...input,
                            data: {
                                ...input.data,
                                lte: e.target.value
                            }
                        })}
                        variant="outlined"
                        placeholder={t('enter_value')}
                        type='number'
                    />
                </form>
            </div>
            {(input?.data?.gte && input?.data?.lte && input?.data?.lte < input?.data?.gte) && <Typography className={classes.errorTextStyle}>{t('min_max_error_text')}</Typography>}
        </>
    )
}

export default NumRangeElement