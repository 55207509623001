import React from 'react'
import {
    Grid, Typography, makeStyles,
    Divider, TextField,
    MenuItem, Select, FormControl, FormGroup,
    IconButton
} from "@material-ui/core"
import { useSelector } from 'react-redux';
import BootstrapInput from 'components/common/Elements/BootstrapInput';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
    },
    title: {
        fontSize: 18,
        textTransform: "uppercase"
    },
    uppercase: {
        textTransform: "uppercase",
        fontSize: 14
    },
    capitalize: {
        textTransform: "capitalize"
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    text: {
        width: 300
    },
    grid: {
        padding: theme.spacing(2)
    },
    formgroup: {
        marginRight: theme.spacing(1),
    },
    formelement: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    closeicon: {
        position: "absolute",
        right: 5,
        top: 0
    }
}));

const Criteria = (props) => {
    const classes = useStyles();
    const { metadata } = useSelector(({ selfalerting: { metadata, loading } }) => ({ metadata, loading }));

    const { formdata, updateCriteria, index, deleteCriteria } = props;

    const [unit, setUnit] = React.useState('');
    const [filterUnit, setFilterUnit] = React.useState(null);

    React.useEffect(() => {
        setUnit(formdata.timeUnitsToAdd < 0 ? "-" : "+");
    }, [formdata.timeUnitsToAdd])

    React.useEffect(() => {
        let menudata = metadata?.supportedFields || [];
        const { field1 } = formdata;
        const valobj = menudata?.filter(d => d.field === field1)?.[0];
        setFilterUnit(valobj?.fieldType);
        if(valobj?.fieldType === "date") {
            updateFormData({
                timeUnitType: "Day"
            })
        }
    }, [formdata.field1])

    const updateFormData = (formelement) => {
        updateCriteria({
            ...formdata,
            ...formelement
        }, index)
    }

    const Comparision = ({ field, title, placeholder, filter }) => {

        const [menudata, setMenu] = React.useState(metadata?.supportedFields || [])

        React.useEffect(() => {
            if(filter) {
                setMenu(metadata?.supportedFields?.filter(d => d.fieldType === filter));
            } else {
                setMenu(metadata?.supportedFields);
            }
        }, [filter])

        return (
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.formelement}
            >
                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                    <Typography className={classes.uppercase}>{title}</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            value={formdata[field]}
                            onChange={({ target: { value } }) => {
                                if (!filter) {
                                    const valobj = menudata?.filter(d => d.field === value)?.[0];
                                    setFilterUnit(valobj?.fieldType);
                                    updateFormData({
                                        field2: "",
                                        [field]: value,
                                    })
                                } else {
                                    updateFormData({
                                        [field]: value,
                                    })
                                }
                            }}
                            displayEmpty
                            className={classes.capitalize}
                            input={<BootstrapInput inputProps={{
                                "data-testid": `criteria-selct-${field}`,
                                "placeholder": "Select Value"
                            }} />}
                        >
                            <MenuItem value="">{placeholder}</MenuItem>
                            {
                                menudata?.map(d => {
                                    return <MenuItem inputProps={{
                                        "data-testid": `option-${d?.field}-1`
                                    }} className={classes.capitalize} value={d?.field} key={d?.field}>
                                        {d.fieldText}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>)
    }

    return (
        <div className={classes.root} data-testid="criteria-test">
            <Divider className={classes.divider} />
            <Typography component={"h1"} className={classes.title}>Criteria {index + 1}</Typography>
            {/* Select Field */}
            <Comparision field={"field1"} title="Select Field *" placeholder={"Select Field"} />
            {/* Select Comparison */}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.formelement}
            >
                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                    <Typography className={classes.uppercase}>Select Comparision *</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Select
                            data-testid="comaprision-select"
                            value={formdata.operation}
                            onChange={({ target: { value } }) => updateFormData({
                                operation: value
                            })}
                            displayEmpty
                            className={classes.capitalize}
                            input={<BootstrapInput inputProps={{
                                "data-testid": "criteria-selct-2"
                            }} />}
                        >
                            <MenuItem value="">Select Comparison </MenuItem>
                            {
                                metadata?.supportedOperations?.map(d => {
                                    return <MenuItem
                                        inputProps={{
                                            "data-testid": `option-${d?.operation}-2`
                                        }}
                                        className={classes.capitalize} key={d?.operation} value={d?.operation}>
                                        {d.operationText}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Comparison with */}
            <Comparision field={"field2"} title="Comparison With *" placeholder={"Select Comparison Field"} filter={filterUnit} />
            {/* Additional Time */}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.formelement}
            >
                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                    <Typography className={classes.uppercase}>Additional Time </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                    <FormGroup row>
                        <FormControl variant="outlined" className={classes.formgroup}>
                            <TextField
                                size="small"
                                label="+/-"
                                variant="outlined"
                                select
                                value={unit}
                                displayEmpty
                                inputProps={{
                                    "data-testid": "text-criteria1"
                                }}
                                onChange={({ target: { value } }) => {
                                    setUnit(value);
                                    updateFormData({
                                        timeUnitsToAdd: value === "-" ? -Math.abs(formdata.timeUnitsToAdd) : Math.abs(formdata.timeUnitsToAdd)
                                    });
                                }}
                            >
                                <MenuItem value="">
                                    <em>+/-</em>
                                </MenuItem>
                                <MenuItem value={"+"}>+</MenuItem>
                                <MenuItem value={"-"}>-</MenuItem>
                            </TextField>
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formgroup}>
                            <TextField
                                required
                                className={classes.text}
                                id="outlined-basic"
                                size="small"
                                type="text"
                                label="Enter Number"
                                variant="outlined"
                                inputProps={{
                                    "data-testid": "text-criteria2"
                                }}
                                value={formdata.timeUnitsToAdd}
                                onChange={({ target: { value } }) => {
                                    const parsed = Math.floor(value);
                                    updateFormData({
                                        timeUnitsToAdd: (unit === "-" ? -Math.abs(parsed) : Math.abs(parsed)) || 0
                                    });
                                }
                                }
                            />
                        </FormControl>
                        <FormControl variant="outlined" className={classes.formgroup}>
                            <TextField
                                size="small"
                                variant="outlined"
                                select
                                width={200}
                                inputProps={{
                                    "data-testid": "text-criteria3"
                                }}
                                placeholder="Select Duration Unit"
                                displayEmpty
                                value={formdata.timeUnitType}
                                onChange={({ target: { value } }) => updateFormData({
                                    timeUnitType: value
                                })}
                            >
                                <MenuItem value="">
                                    <em>Select Duration Unit</em>
                                </MenuItem>
                                {filterUnit !== "date" && <MenuItem value={"Hour"}>Hours</MenuItem>}
                                {filterUnit !== "date" && <MenuItem value={"Minute"}>Minutes</MenuItem>}
                                <MenuItem value={"Day"}>Days</MenuItem>
                            </TextField>
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>
            <IconButton
                data-testid="deletecriteria"
                onClick={() => {
                    deleteCriteria(index)
                }} className={classes.closeicon} color="primary" aria-label="upload picture" component="span">
                <HighlightOffIcon />
            </IconButton>
        </div>
    )
}

export default Criteria;
