import React, { useState } from 'react';
import { generatePath, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
// import { useDeepCompareEffect } from 'react-use';
// import useSetQuery from 'customHooks/useQuery';
import EMEATabsBar from './EMEATabsBar';
// import NetworkSvg from '../../assets/images/network.svg';
// import MillSvg from '../../assets/images/mill.svg';

const EMEATabsWrapper = ({ region }) => {
    // const [params] = useSetQuery();
    // const viewby = "sites";
    const { tab } = useParams();

    const [subtabs, setSubTabs] = useState(null);
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();


    const onTabChange = (e, value) => {
        history.replace({
            ...location,
            pathname: generatePath(match.path, { tab: value })
        })
    }

    // useDeepCompareEffect(() => {
    //     switch (viewby) {
    //         case "sites": {
    //             const tabs = params.sites?.map(d => {
    //                 return {
    //                     icon: MillSvg,
    //                     name: d,
    //                     id: d
    //                 }
    //             });
    //             setSubTabs(tabs);
    //             return;
    //         }
    //         case "Cust": {
    //             const tabs = params.Cust?.map(d => {
    //                 return {
    //                     name: `${d.split("_")?.[2]} (${d.split("_")?.[0]})`,
    //                     icon: NetworkSvg,
    //                     id: d
    //                 }
    //             });
    //             setSubTabs(tabs);
    //             return;
    //         }
    //         case "Sofc": {
    //             const tabs = params.sofcNSG?.map(d => {
    //                 let splits = d.split("_");
    //                 return {
    //                     name: `${splits[2]} (${splits[0]}) ${splits[3] || ""}`,
    //                     icon: NetworkSvg,
    //                     id: d
    //                 }
    //             });
    //             setSubTabs(tabs);
    //             return;
    //         }
    //     }
    // }, [params.sites, params.Cust, params.Sofc, params.sofcNSG, viewby]);

    return (
        <div data-testid="emeatabs-wrapper">
            <EMEATabsBar
                mainTabs={[]}
                subTabs={subtabs}
                tab={tab}
                onTabChange={onTabChange}
                region={region}
            />
        </div>
    );
};

export default EMEATabsWrapper;
