import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 50,
        height: 30,
        padding: 0,
        margin: theme.spacing(1),
        border: `1px solid #7a8ce4`,
        borderRadius: 30 / 2,
    },
    switchBase: {
        padding: 1,
        backgroundColor: theme.palette.menu.base,
        color: "#9da4c8",
        "& + $track": {
            backgroundColor: "#465083",
            opacity: 1,
            border: "none"
        },
        "&$checked": {
            transform: "translateX(21px)",
            color: "#279cff !important",
            "& + $track": {
                backgroundColor: "#465083",
                opacity: 1,
                border: "none"
            }
        }
    },
    thumb: {
        height: 27,
        width: 27
    },
    track: {
        borderRadius: 30 / 2,
        backgroundColor: "#465083",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
}))(({ classes, ...props }) => {
    return (
        <Switch
            data-testid="switch"
            focusVisibleClassName={classes.focusVisible}
            classes={{
                root: classes.root,
                thumb: classes.thumb,
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked
            }}
            {...props}
        />
    );
});

const useStyles = makeStyles(theme => {
    return {
        root: {
            marginRight: 15,
        },
        text: {
            color: 'white'
        },
        title: {
            width: 50
        }
    }
})

const SwitchCustom = ({ switchEvent, checked, titles = {} }) => {
    const [state, setState] = React.useState(checked);
    const { left, right } = titles;
    const classes = useStyles();

    const handleChange = (event) => {
        setState(event.target.checked);
        switchEvent(event.target.checked)
    };

    React.useEffect(() => {
        setState(checked)
    }, [checked])

    return (
        <div className={classes.root} data-testid="iosswitch">
            <Grid component="label" container alignItems="center">
                <Grid className={classes.title} item>
                    <Typography color="primary" className={classes.text}>{left}</Typography>
                </Grid>
                <Grid item>
                    <IOSSwitch
                        checked={state} onChange={handleChange}
                        name="checkedB"
                    />
                </Grid>
                <Grid className={classes.title} item>
                    <Typography color="primary" className={classes.text}>{right}</Typography>
                </Grid>
            </Grid>
        </div>

    );
}
export default SwitchCustom;

