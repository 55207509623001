import React from "react";
import { Helmet } from "react-helmet";
import { capitalizeWord } from "helpers";

const Title = ({ page, tab1, tab2, mainpage }) => {

    // const base = "Logistics";
    const [title, setTitle] = React.useState("");

    React.useMemo(() => {
        if (tab2) setTitle(page)
        if (tab1 === 0) setTitle(`Network`)
        if (tab1 === 1) setTitle(`My Selections`)
    }, [tab1, tab2, page])

    return (
        <Helmet>
            <title>{`${capitalizeWord(mainpage)} - ${title}`}</title>
        </Helmet>
    )
}

export default Title;