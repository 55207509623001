import React from "react";
import { useTranslation } from "react-i18next"
import { makeStyles, Typography, ClickAwayListener } from "@material-ui/core"
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import KCButton from "components/common/Button";
import { ColumnsView } from "./TableElements/ColumnsView";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        [theme.breakpoints.down("sm")]:{
            padding:0
        }
    },
    text: {
        pointerEvents: 'none',
        color: theme.palette?.primary?.contrastText
    },
    button: {
        marginTop: theme.spacing(1),
        width: 250,
        height: theme.spacing(5),
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: `1px solid ${theme.palette?.background?.secondaryBase}`,
        textTransform: 'capitalize',
        color: theme.palette?.primary?.contrastText,
        backgroundColor: theme.palette?.text?.main,
        '&:hover, &:active': {
            backgroundColor: theme.palette?.text?.main,
            border: `1px solid ${theme.palette?.chip?.borderActive}`
        },
        [theme.breakpoints.down("md")]:{
            maxWidth: theme.spacing(22.5),
        },
        [theme.breakpoints.down("xs")]:{
            maxWidth: theme.spacing(20),
        }
    },
}));

export const ColumnManager = ({ columns, columnsAction, columnsOpenAction }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleButtonClick = () => {
        if (!open && typeof columnsOpenAction === "function") {
            columnsOpenAction();
        }
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <div className={classes.root} data-testid="columnmanager">
                <KCButton
                    endIcon={!open ? <ExpandMore /> : <ExpandLess />}
                    className={classes.button}
                    onClick={handleButtonClick}
                >
                    <Typography data-testid="manageColumnsBtn" className={classes.text}>{t('manage_columns')}</Typography>
                </KCButton>
                <ColumnsView openManager={open} columns={columns} columnsAction={columnsAction} />
            </div>
        </ClickAwayListener>
    )
}

