import React from "react";
import { useTranslation } from "react-i18next"
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Grid,
    Typography,
    IconButton
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';

import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clsx from "clsx";
import { filterStyles } from 'theme';

export const filterStyle = makeStyles(filterStyles);

export const useStyles = makeStyles(theme => ({
    root: {
        maxHeight: 435,
        overflow: 'auto'
    },
    list: {
        backgroundColor: theme.palette?.form?.baseLight
    },
    listitem: {
        // height: 45,
        borderBottom: `1px solid ${theme.palette?.secondary?.border}`,
    },
    icon: {
        cursor: 'pointer',
        color: theme.palette.primary.contrastText
    },
    parent: {
    },
    iconcircle: {
        display: 'block',
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
        borderRadius: '50%',
        padding: 3,
        backgroundColor: theme.palette?.extra?.iconInactive,
        color: theme.palette?.primary.contrastText,
        border: `1px solid ${theme.palette?.primary.contrastText}`,
        // opacity: 0.1,
        "&:hover": {
            // opacity: 1
            backgroundColor: theme.palette?.primary.contrastText,
            color: theme.palette?.text.main,    
        }
    }
}));


export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    /*...(isDragging && {
        background: '#1c2340'
    }), */
    ...(!isDragging && {
        transition: 'background 2000ms linear'
    }),
});

const DNDList = (props) => {

    const { setHiddenCols, changeOrder } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const filterClasses = filterStyle();

    const [items, setItems] = React.useState(props.items);

    React.useEffect(() => {
        setItems(props.items);
    }, [props.items])

    const onDragEnd = (result) => {
        if (!result || !result.source || !result.destination) return;
        const itemsTemp = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        changeOrder(itemsTemp, result);
        setItems(itemsTemp);
    }

    const clickChange = (i, type) => {
        if (i === 0 && type === "up") {
            return null;
        }
        let tempOrder = items;
        if (type === "up" && i > 0) {
            const temp = tempOrder[i - 1];
            tempOrder[i - 1] = tempOrder[i];
            tempOrder[i] = temp;
            changeOrder(tempOrder, { source: { index: i }, destination: { index: i - 1 } });
            setItems(tempOrder);
        }

        if (type === "down" && i < (tempOrder.length - 1)) {
            const temp = tempOrder[i + 1];
            tempOrder[i + 1] = tempOrder[i];
            tempOrder[i] = temp;
            changeOrder(tempOrder, { source: { index: i }, destination: { index: i + 1 } });
            setItems(tempOrder);
        }
    }

    return (
        <div className={classes.root} data-testid="dndlist">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided_, snapshot_) => (
                        <RootRef rootRef={provided_.innerRef}>
                            <List className={classes.list}>
                                {items.map((item, index) => (
                                    <Draggable key={item.field} draggableId={item.field} index={index}>
                                        {(provided, snapshot) => (
                                            <ListItem
                                                className={classes.listitem}
                                                ContainerComponent="li"
                                                ContainerProps={{ ref: provided.innerRef }}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Grid key={item.field} className={classes.parent} container spacing={2} alignItems="center">
                                                    <Grid item xs={8}>
                                                        <Typography
                                                            className={clsx(filterClasses.button, filterClasses.contentText)}
                                                        >{t(item.title)} </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>{
                                                        <IconButton
                                                            aria-label="visible icon"
                                                            className={classes.margin}
                                                            onClick={() => {
                                                                setHiddenCols({
                                                                    target: {
                                                                        checked: !item.hidden,
                                                                        value: item.field
                                                                    }
                                                                })
                                                            }}
                                                            size="medium">
                                                            {
                                                                item.hidden ?
                                                                    <VisibilityOffIcon fontSize="small" data-testid='togglevisibilityon' name="visibilityoff" className={classes.icon} /> :
                                                                    <VisibilityIcon fontSize="small" name="visibilityon" data-testid='togglevisibilityoff' className={classes.icon} />
                                                            }
                                                        </IconButton>
                                                    }</Grid>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={2}>
                                                        <div style={{ display: 'inline-flex' }}>
                                                            <ArrowUpwardIcon data-testid='shuffleuparrow' className={classes.iconcircle} onClick={() => clickChange(index, 'up')} />
                                                            <ArrowDownwardIcon data-testid='shuffledownarrow' className={classes.iconcircle} onClick={() => clickChange(index, 'down')} />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <ListItemSecondaryAction />
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided_.placeholder}
                            </List>
                        </RootRef>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );

}

export default DNDList;
