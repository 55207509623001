import { tableoptions } from "./tableStyleOverride"
import { endpoints } from "../configs/endpoints"
import * as _ from "lodash";
import { v4 as uuid } from 'uuid';
import { startCase, get, uniq, flatten, sortBy, isEmpty, isArray, isEqual, find, isObject } from "lodash";
import { appTheme } from "../theme";
import { performanceConstants } from "components/common/PerformanceFilters"
import moment from "moment-timezone";


// const multiSearchKeys = [
//     'searchStringList', 
//     'vendorNumberList', 
//     'vendorPlantList',
//     'finalOrderDestinationIdList', 
//     'exportContainerIdList', 
//     'materialNumList', 
//     'orderOriginCountryList',
//     'finalOrderDestinationCountryList',
//     'exportBillOfLadingList',
//     'materialTypeList',

//     // Import
//     'orderDestinationIdList',
//     // 'importContainerIdList',
//     'materialNumList',
//     'importOrderOriginCountryList',
//     'importOrderDestinationCountryList',
//     'importBillOfLadingList',
//     'importShipmentModeList',
//     'importCustomsChannelList'
// ]


const calcPercentage = (a, b) => {
    let percent = ((Number(a) - Number(b)) / Number(a) * 100);
    if (!isNaN(percent) && isFinite(percent)) {
        return parseFloat(percent.toFixed(0))
    } else {
        return 0;
    }
}

const generateDefaultData = (columns) => {
    let defaultData = {};
    columns.map(d => {
        defaultData[d.field] = "-";
    });
    return defaultData;
}

const msToHMS = (ms) => {
    if (!isNaN(ms)) {
        const seconds = ms / 1000;
        const hours = parseFloat(seconds / 3600).toFixed(2);
        return `${hours} Hours`;
    } else {
        return "-"
    }
}

const refreshTable = (tableRef) => {
    tableRef.current && tableRef.current.onQueryChange();
}

export const sortOrder = ({ order, key, data }) => {
    return _.sortBy(data, (obj) => {
        return _.indexOf(order, obj[key]);
    });
}

const generateTablebody = (filters, sites = null) => {
    let tablebody = {};
    try {
        Object.keys(filters).map(key => {
            const filter = filters[key];
            const checkvalues = [];
            const generic = ["text", "radio", "date", "sites", "checkboxradio", "ordercheckbox", "textcheck", "daterange", "customdaterange", "numrange"];

            if (generic.includes(filter.type)) {
                tablebody[key] = filter.data;
                if (filter.type === "sites") {

                    const sitesFromFilter = filter.data || []
                    const sitesFromFavorites = sites || []
                    let commonSites = []

                    if (sitesFromFavorites.length > 0) {
                        commonSites = sitesFromFilter?.filter((site) => sitesFromFavorites.includes(site))
                    }
                    const consolidateFilters = sitesFromFavorites.length > 0 ? commonSites.length > 0 ? commonSites : sitesFromFavorites : sitesFromFilter;

                    tablebody[key] = consolidateFilters.length > 0 ? consolidateFilters : null

                }
            }
            if (filter.type === "checkbox" || filter.type === "ordercheckbox") {
                filter.data.map(d => {
                    if (d.checked) {
                        checkvalues.push(d.value);
                    }
                });
                tablebody[key] = checkvalues;
            }

            if (filter.type === "checkboxradio") {
                const defaultValue = filter.defaultValue === null ? null : {}
                let crValue = defaultValue;
                filter.data.map(d => {
                    if (d.checked) {
                        crValue = d.value;
                    }
                });
                tablebody[key] = crValue;
            }
        });
    }
    catch (e) {
        tablebody = {};
    }

    return tablebody || {};
}

const formatToSentenceCase = (inputString) => {
    const regexFunction = (string) => { return string.replace(/[a-z]/i, function (letter) { return letter.toUpperCase(); }).trim() }
    const splitString = inputString.toLowerCase().split(" ").map((string) => {
        if (string === 'dc') {
            return string.toUpperCase();
        } else {
            return string.split('-').map(regexFunction).join('-')
        }
    });
    return splitString.join(" ");
}

const generateFilterLayoutTemplate = ({ viewName, filters = {}, columns = [], additional = {} }) => {
    return {
        id: uuid(),
        name: viewName ? viewName : `Untitled View`,
        default: false,
        filters,
        columns,
        ...additional
    }
}

const findActive = ({ views, active }, hiddenids, urlViewId) => {
    let recentHidden = {};
    const viewsActive = views?.filter(d => !d.hidden).map(d => d.id);
    
    if (viewsActive?.includes(urlViewId)) {
        return views?.filter(d => d.id === urlViewId)[0]
    }

    const viewsHidden = views?.filter((d, i) => {
        if(d?.recent) recentHidden = {
            ...d,
            pos: i
        };
        return !d.hidden
    });
    if (!hiddenids?.includes(active?.id)) return active;

    if(recentHidden?.id) {
        let nextActive = null;
        let x = views?.some((d, i) => {
            if (i > recentHidden?.pos && !d?.hidden) {
                nextActive = d;
            }
            return (i > recentHidden?.pos && !d?.hidden)
        });
        if (nextActive) return nextActive;
        else return viewsHidden[0]
    }
    return viewsHidden[0]
  }

const getStatusData = (data, key = "hours") => {

    if (data[key] > 3) {
        return {
            ...data,
            raw_status: performanceConstants.HEALTHY,
            status: performanceConstants.HEALTHY,
            color: `${appTheme.palette.legendColors.healthyGreen}`
        }
    } else if (data[key] > 0 && data[key] <= 3) {
        return {
            ...data,
            raw_status: performanceConstants.MONITOR,
            status: performanceConstants.MONITOR, color: `${appTheme?.palette?.legendColors.risk}`
        }
    } else if (data[key] < 0 && data[key] > -6) {
        return {
            ...data,
            raw_status: performanceConstants.BEHIND,
            status: performanceConstants.BEHIND,
            color: `${appTheme.palette.legendColors.pink}`
        }
    } else if (data[key] <= -6) {
        return {
            ...data,
            raw_status: performanceConstants.CRITICAL,
            status: performanceConstants.CRITICAL,
            color: `${appTheme.palette.legendColors.criticalRed}`
        }
    } else
        return {
            ...data,
            raw_status: performanceConstants.NODATA,
            status: performanceConstants.NODATA,
            color: `${appTheme.palette.legendColors.greyLight}`
        }
}


const capitalizeWord = (value) => startCase(value?.toLowerCase())

const getId = (obj, type, showSG = true) => {
    const datatype = obj[type];

    if (datatype === "DC" || datatype === "MILL") {
        return obj.siteNum
    }
    if (datatype === "SALES_OFFICE") {
        return `${obj.selectionType}_${obj.salesOrg}_${obj.distributionChannel}_${obj.salesOffice}_${showSG ? obj.salesGroup || '' : ''}`
    }
    if (datatype === "CUST") {
        return `${obj.selectionType}_${obj.salesOrg}_${obj.distributionChannel}_${obj.customerName}`
    }
}

const getTabsData = (data, viewbyValues) => {
    const { name, type } = viewbyValues;
    const items = [];
    const tempData = data?.forEach(d => {
        if ("salesGroup" in d) {
            return d.salesGroup?.map(item => {
                if (item) {
                    items.push({
                        ...d,
                        customUniqObjectId: getId(d, type, false),
                        custom: {
                            name: `${d[name]}${d?.salesOrg && `(${d.salesOrg}) `}${item || ''}`,
                            id: getId({ ...d, salesGroup: item }, type),
                            salesGroup: d.salesGroup
                        },
                        salesGroup: [item]
                    })
                }
            })
        } else {
            return items.push({
                ...d,
                customUniqObjectId: getId(d, type, false),
                custom: {
                    name: !d.customerName ? `${d[name]}${d?.salesOrg ? `(${d.salesOrg})` : ''}` : `${d.customerName}${d?.salesOrg ? `(${d.salesOrg})` : ''}`,
                    id: getId(d, type, false)
                }
            })
        }
    });
    return items;
}


const generateHeader = ({ selections, type, showTabsBy, item }) => {

    let siteNums = [];
    let customerOrSalesOffice = [];

    if (type === "network") {
        return {};
    }

    if (type === "mylocation") {
        siteNums = selections?.locations?.map(d => d?.siteNum);
        customerOrSalesOffice = selections?.customer;
    } else {
        if (showTabsBy === "customer") {
            siteNums = selections?.locations?.map(d => d?.siteNum);
            customerOrSalesOffice = [item]?.map(d => {
                delete d?.custom;
                delete d?.customUniqObjectId;
                return d;
            });
        } else {
            siteNums = [item]?.map(d => d?.siteNum);
            customerOrSalesOffice = selections?.customer;
        }
    }

    siteNums = (siteNums?.length > 0 && siteNums[0]) ? siteNums : null;
    customerOrSalesOffice = (customerOrSalesOffice?.length > 0 && customerOrSalesOffice[0]) ? customerOrSalesOffice : null;

    return {
        siteNums,
        customerOrSalesOffice
    }
}

const defaultShowTabsBy = (state) => {
    const { locations, customer } = { locations: get(state, "items.items", []), customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] }

    let showTabsBy = locations.length > 0 && customer.length > 0 ? "customer" : locations.length > 0 && customer.length === 0 ? "locations" : customer.length > 0 ? "customer" : "locations";

    let stateShowTabsBy = get(state, "options.showTabsBy", showTabsBy);

    if (!(locations.length > 0 && customer.length > 0)) {
        if (stateShowTabsBy === "locations" && locations.length === 0) {
            stateShowTabsBy = "customer"
        }

        if (stateShowTabsBy === "customer" && customer.length === 0) {
            stateShowTabsBy = "locations"
        }
    }

    return stateShowTabsBy;
}

const mergeSalesGroups = (inputArray) => {

    const map = new Map(inputArray.map(({ customUniqObjectId, ...rest }) => [customUniqObjectId, { ...rest, salesGroup: [] }]));
    for (let { customUniqObjectId, salesGroup } of inputArray) {
        // map.get(customUniqObjectId).salesGroup.push(...[salesGroup].flat());
        map.get(customUniqObjectId).salesGroup.push(...flatten([salesGroup]));
    }

    let resultantArray = [...map.values()];

    resultantArray = resultantArray.map(d => {
        if (d?.salesGroup && d.salesGroup[0] === undefined && d.salesGroup.length === 1) {
            delete d?.salesGroup;
        } else {
            d.salesGroup = uniq(d?.salesGroup)
        }
        delete d.custom;
        delete d?.customUniqObjectId;
        return d
    });
    return resultantArray;
}

const stringToArrayV2 = (input, filterKey) => {
    let makeArray = [];
    if (input) {
        // if (multiSearchKeys.includes(filterKey)) {
            makeArray = input.split(/,| |;/).filter(el => el !== '').map(item => item.trim())
        // } else {
        //     makeArray = input.split(/,/).filter(el => el !== '').map(item => item.trim())
        // }
    }
    return makeArray;
}

const stringToArray = (input) => {
    // https://regex101.com/  -> To validate the regex
    if (isArray(input)) return input;
    const makeArray = input?.trim().split(/,| /).filter(item => item.match(/^[a-z0-9-_()"\/]+$/i)) || []
    return isEmpty(makeArray) ? null : makeArray
}

const getUpdatedColumns = (viewColumns = [], defaultColumns = []) => {


    let viewFieldsObject = {};
    viewColumns.map(d => {
        viewFieldsObject[d.field] = d;
    });
    const viewFieldKeys = Object.keys(viewFieldsObject);
    let updatedColumns = [];
    let changedCols = [];

    defaultColumns.map(d => {
        if (viewFieldKeys.includes(d.field)) {
            updatedColumns.push(viewFieldsObject[d.field]);
        } else {
            changedCols.push({
                ...d,
                hidden: true
            });
        }
    });

    let sortedCollection = sortBy(updatedColumns, (item) => viewFieldKeys.indexOf(item.field));

    return [...sortedCollection, ...changedCols]
}

const createHyperLinkFilterObject = ({ pageDetails, tableName, filterBody }) => {


    let preSetHealthFilter = {}

    const preSetStatusFilter = pageDetails?.filterParams?.args ? Object.keys(pageDetails.filterParams.args).reduce((res, key) => {
        return {
            ...res, [key]: {
                ...filterBody[key],
                data: isArray(filterBody[key]?.data) ? filterBody[key]?.data.map(filterOption => {
                    return pageDetails.filterParams.args[key].includes(filterOption.value) ? { ...filterOption, checked: true } : filterOption
                }) : pageDetails.filterParams.args[key]
            }
        }
    }, {}) : {};

    if (tableName === 'orders') {

        preSetHealthFilter = pageDetails?.filterParams?.health ? {
            orderHealth: {
                ...filterBody.orderHealth,
                data: filterBody.orderHealth.data.map(status => { return status.value === pageDetails?.filterParams?.health ? { ...status, checked: true } : status })
            }
        } : {}

        var skipDefaultDateFilter = pageDetails?.filterParams?.args ? { matAvailDate: { ...filterBody.matAvailDate, data: null } } : {}

        var skipDefaultOrderTypeFilter = pageDetails?.filterParams?.args?.orderTypes ? {
            orderTypes: {
                ...filterBody.orderTypes,
                data: filterBody.orderTypes.data.map(type1 => {
                    return pageDetails.filterParams.args.orderTypes.includes(type1.value) ? { ...type1, checked: true } : { ...type1, checked: false }
                })
            }
        } : {}

    }

    if (tableName === 'transport') {

        preSetHealthFilter = {
            orderExecutionHealth: {
                ...filterBody.orderExecutionHealth,
                data: filterBody.orderExecutionHealth.data.map(status => { return status.value === pageDetails?.filterParams?.health ? { ...status, checked: true } : status })
            }
        }
        skipDefaultDateFilter = pageDetails?.filterParams?.args ? { loadReadyDateTimeOriginTZ: { ...filterBody.loadReadyDateTimeOriginTZ, data: null } } : {}

    }

    if (tableName === 'distoutbound') {

        preSetHealthFilter = {
            orderExecutionHealth: {
                ...filterBody.orderExecutionHealth,
                data: filterBody.orderExecutionHealth.data.map(status => { return status.value === pageDetails?.filterParams?.health ? { ...status, checked: true } : status })
            }
        }

    }

    if (tableName === 'distinbound') {
        preSetHealthFilter = pageDetails?.filterParams?.health ? {
            inboundOrderExecutionHealth: {
                ...filterBody.inboundOrderExecutionHealth,
                data: filterBody.inboundOrderExecutionHealth.data.map(status => { return status.value === pageDetails?.filterParams?.health ? { ...status, checked: true } : status })
            }
        } : {}
    }

    return { fullObject: { ...preSetStatusFilter, ...preSetHealthFilter, ...skipDefaultDateFilter, ...skipDefaultOrderTypeFilter }, preSetStatusFilter }

}

const searchKey = (key, d) => {
    key = key?.toLowerCase();
    return (d?.shortName?.toLowerCase().indexOf(key) > -1)
        || (d?.siteNum?.toLowerCase().indexOf(key) > -1)
        || (d?.alias?.toLowerCase().indexOf(key) > -1)
}

export const processGuardRailData = (data = [], optionalName = false) => {
    const processed = [];
    data.map((d, i) => {
        let barname = `${moment(d.dateTime).format("MM/DD")} ${moment(d.dateTime).format("ddd")}`;

        if (d.today == "Y") {
            barname = `${barname} Today`
        }
        let values = {
            ...d,
            name: barname
        };

        const rowDataOrder = ['CONSUMER', 'PROFESSIONAL', 'ALL']

        rowDataOrder.forEach((value) => {
            const findBU = d?.orderDivisions?.filter(({ divisionName }) => divisionName === value)
            if (isEmpty(findBU)) {
                values[`${value}_guardrailValue`] = .0011
                values[`${value}_shipmentCount`] = .0011
            } else {
                values[`${value}_guardrailValue`] = findBU[0].guardrailValue + .0011
                values[`${value}_shipmentCount`] = findBU[0].shipmentCount + .0011
            }
        })
        processed.push(values);

    });
    return processed;
}

const differenceInDates = (startDate, endDate) => {
    const sDate = new Date(startDate);
    const eDate = new Date(endDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = eDate.getTime() - sDate.getTime();
    const diffInDays = Math.floor(diffInTime / oneDay);

    return diffInDays;
}

const shouldRefreshRequest = (statePayload, requestPayload) => {
    const isPayloadEqual = isEqual(statePayload?.body, requestPayload?.body);
    const isRefresh = isEqual(statePayload?.refresh, requestPayload?.refresh);
    const isLanguageChanged = isEqual(statePayload?.language, requestPayload?.language);
    const isTimeout = moment(new Date()).diff(moment(statePayload?.reqtime), "seconds") > 300;
    const isPayloadEmpty = isEmpty(requestPayload?.body);

    return ((!isPayloadEqual || !isRefresh || isTimeout || !isLanguageChanged) && (!isPayloadEmpty && typeof requestPayload?.body === "object"))
    /*
    Conditions: 
    Payloads of Saved and Cureent Request Should not be same
    If external refresh  or hard refresh is happning
    is 5 min Timeout
    is Language Changed
    and Payload Should Not be empty when we are doing the requests
    */
}

const isJsonParsable = string => {
    let parsed = null;
    try {
        parsed = JSON.parse(string);
    } catch (e) {
        return false;
    }
    return parsed;
}

const getFilterArgs = (filters) => {
    if (!isEmpty(filters)) {
        let data = {}
        for (const [key, value] of Object.entries(filters)) {
            if (value?.data) {
                if (Array.isArray(value.data) && value.type !== "sites" && !value.stringToArray) {
                    const mappedvals = value.data.filter(({ checked }) => checked).map(({ value }) => value);
                    if (!isEmpty(mappedvals)) {
                        data[key] = mappedvals
                    }
                } else {
                    data[key] = value.data;
                }
            }
        }
        return data;
    }
    return {}
}

const createHyperLinkFilterObject2 = ({ pageDetails, tableName, filterBody, filterKeys = null }) => {

    let preSetHealthFilter = {}
    let skipDefaultDateFilter = {};
    let skipDefaultOrderTypeFilter = {};

    const preSetStatusFilter = pageDetails?.filterParams?.args ? Object.keys(pageDetails.filterParams.args).reduce((res, key) => {
        if (filterKeys && !filterKeys.includes(key)) return {
            ...res
        };
        return {
            ...res, [key]: {
                ...filterBody[key],
                data: isArray(filterBody[key]?.data) ? filterBody[key]?.data.map(filterOption => {
                    if(isObject(filterOption.value)) {
                        return find(pageDetails.filterParams.args[key], filterOption.value) ? { ...filterOption, checked: true } : filterOption
                    }
                    return pageDetails.filterParams.args[key]?.includes(filterOption.value) ? { ...filterOption, checked: true } : filterOption
                }) : pageDetails.filterParams.args[key]
            }
        }
    }, {}) : {};

    return { fullObject: { ...preSetStatusFilter, ...preSetHealthFilter, ...skipDefaultDateFilter, ...skipDefaultOrderTypeFilter }, preSetStatusFilter }

}

const getHealth = (filterParams, topage) => {
    const health = {
        orders: "orderHealth",
        transport: "orderExecutionHealth",
        DIST_OUTBOUND_SHIPMENTS: "orderExecutionHealth",
        DIST_INBOUND_SHIPMENTS: "inboundOrderExecutionHealth",
        DIST: "orderExecutionHealth"
    }

    if (topage === "distribution") {
        return {
            [health[filterParams.state]]: [filterParams.health]
        };
    }

    if(!filterParams.health) return {
        [health[topage]]: undefined
    }
    
    return {
        [health[topage]]: [filterParams.health]
    };
}

const getTabletype = (topage, filterParams) => {
    const tabtypes = {
        DIST_OUTBOUND_SHIPMENTS: 'outbound',
        DIST_INBOUND_SHIPMENTS: 'inbound',
        DIST: "outbound"
    }
    return topage === "distribution" ? tabtypes[filterParams?.state] || "outbound" : undefined
}

const chartDataIdentifierForAPI = { 
    network: 'NETWORK', 
    order: 'ORDER', 
    transPlan: 'TRANS_PLAN', 
    transExec: 'TRANS_EXEC', 
    dist: 'DIST', 
    shipmentdetails: 'SHIPMENTDETAILS', 
    analytics: 'ANALYTICS', 
    stockConstraintReport: 'STOCKCONSTRAINTREPORT',
    osm: "OSM"
}
const allowedPlaces = ["network", "mylocation"];




export const generateSelfAlertData = ({
    formdata,
    filterargs,
    criterias,
    customerOrSalesOffice
}) => {
    let dateComparisons = criterias?.filter(d => d.field1 && d.field2)
    return {
        ...formdata,
        "targetEndpoint": {
            "targetType": "REST",
            "restURL": "CONTROL_TOWER_SERVER_ORDER_SEARCH",  // CONTROL_TOWER_SERVER_ORDER_SEARCH
            "restMethod": "POST",
            "restJsonObjectBody": {                      /* name correction from restBody */
                "region": "NA",
                "searchType": "HOME_PAGE",
                ...filterargs,
                customerOrSalesOffice,
                "dateComparisons": dateComparisons
            },
            "restHeaders": { "Content-Type": "application/json" }   /* change from list to object*/
        }
    }
}

export const valiadateForm = (formdata, criterias) => {
    const emailEmpty = formdata?.actions?.[0].mailingList?.length === 0;
    const timeEmpty = formdata?.freqOfAlerts?.freqType === "CUSTOM" ? formdata?.freqOfAlerts?.customTimestamps?.length === 0 : false;

    const criteriasEmpty = criterias?.map(d => {
        const { field1, operation, field2 } = d;
        return (field1 && operation && field2);
    }).filter(d => d).length === criterias?.length;

    return (emailEmpty || timeEmpty || !criteriasEmpty)
}


export const getFilterMappings = (filters) => {
    if (!isEmpty(filters)) {
        let data = {}
        for (const [key, value] of Object.entries(filters)) {
            if (value?.data) {
                if (Array.isArray(value.data) && value.type !== "sites" && !value.stringToArray) {
                    const mappedvals = value.data.filter(({ checked }) => checked).map(({ name }) => name);
                    if (!isEmpty(mappedvals)) {
                        data[value.name] = mappedvals
                    }
                } else {
                    if (value.type === "date") {
                        data[value.name] = `${value.data.startTime}, ${value.data.endTime}`
                    } else {
                        data[value.name] = value.data;
                    }
                }
            }
        }
        return data;
    }
    return {}
}

export const multiPresent = (base, source, key) => {
    const present = base.every(value => {
        return source?.indexOf(value) !== -1;
    });
    
    if(present) {
        return [...(source.filter(d => !base.includes(d))), key]
    } 
    return source;
}

export const reprocessSlefAlertFilters = (filters) => {
    
    const trans_processing_array = ["TRANS_PLAN_PLANNED", "TRANS_PLAN_TENDERED", "TRANS_PLAN_TENDER_REJECTED"];
    const order_blocked = ["SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION",
            "SO_BLOCKED_TMS_PLANNED_VISIBLE",
            "SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE",
            "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED",
            "SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK",
            "SO_BACK_ORDER_BLOCKED"
        ];

    const order_block_free = [
        "SO_COMPLETELY_CONFIRMED_CUBE", 
        "SO_NOT_COMPLETELY_CONFIRMED_CUBE", 
        "SO_BACK_ORDER_BLOCK_FREE"
    ];

    let orderExecutionBucket = filters?.orderExecutionBucket || [];
    let orderStatusBucket = filters?.orderStatusBucket || [];

    orderExecutionBucket = multiPresent(trans_processing_array, orderExecutionBucket, "TRANS_PLAN_PROCESSING");
    orderStatusBucket = multiPresent(order_blocked, orderStatusBucket, "ORDER_BLOCKED");
    orderStatusBucket = multiPresent(order_block_free, orderStatusBucket, "ORDER_BLOCK_FREE");

    let processed = {
        ...filters,
        orderExecutionBucket,
        orderStatusBucket
    }

    return processed
}

const formatDate = (date) => {
    if (date) {
        return moment(date, "YYYY-MM-DD").format("DD-MMM-YYYY");
    } else return null;
}

const moveItem = (arr, fromIndex, toIndex) => {
    let itemRemoved = arr.splice(fromIndex, 1)
    arr.splice(toIndex, 0, itemRemoved[0])
    return arr
}

const msToHrs = (ms) => {
    if (!isNaN(ms)) {   
        let minutes = Math.floor((ms / (1000 * 60)) % 60);
        minutes = (minutes < 10) ? ("0" + minutes) : minutes;
        let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        hours = (hours < 10) ? ("0" + hours) : hours;
        return hours + ":" + minutes;
    } else {
        return "-"
    }
}

const hideDetailColumns = (tableRef) => {
    if (tableRef?.current?.props?.data?.length > 0) {
        tableRef.current.props.data.map((d) => {
            if (d?.tableData?.showDetailPanel) {
                d.tableData.showDetailPanel = "";
            }
        })
    }
}

export const isSubset = (parentArray, childArray) => {
    const resultArray = [];
    const subset = childArray.every(d => {
        const isIncludes = parentArray.includes(d);
        if(isIncludes) {
            resultArray.push(d)
        }
        return isIncludes;
    });

    return {subset, resultArray};
}

export const getTextColorByBG = (bgColor) => {
    if (!bgColor) { 
        return '#000'; 
    }
    return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 3) ? '#000' : '#fff';
}

export {
    getUpdatedColumns,
    formatToSentenceCase,
    generateTablebody,
    msToHMS,
    tableoptions,
    refreshTable,
    calcPercentage,
    generateDefaultData,
    endpoints,
    generateFilterLayoutTemplate,
    getStatusData,
    capitalizeWord,
    getId,
    getTabsData,
    generateHeader,
    defaultShowTabsBy,
    mergeSalesGroups,
    stringToArray,
    createHyperLinkFilterObject,
    searchKey,
    differenceInDates,
    shouldRefreshRequest,
    isJsonParsable,
    getFilterArgs,
    createHyperLinkFilterObject2,
    chartDataIdentifierForAPI,
    allowedPlaces,
    getHealth,
    getTabletype,
    formatDate,
    findActive,
    moveItem,
    msToHrs,
    hideDetailColumns,
    stringToArrayV2
}