import React from "react";
import { useTranslation } from "react-i18next"
import {
    List as OSMList,
    ListItem as OSMListItem,
    ListItemSecondaryAction,
    makeStyles,
    Grid,
    Typography,
    IconButton
} from "@material-ui/core";
import {
    ArrowUpward as ArrowUpwardIcon,
    ArrowDownward as ArrowDownwardIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    DeleteOutlineOutlined,
    FavoriteBorderOutlined,
    Favorite
} from '@material-ui/icons';
import RootRef from "@material-ui/core/RootRef";
import { useDispatch } from "react-redux";
import { 
    DragDropContext as OSMDragDropContext, 
    Droppable as OSMDroppable, 
    Draggable as OSMDraggable 
} from "react-beautiful-dnd";
import clsx from "clsx";
import { filterStyles } from 'theme';
import { deleteOSMView, favoriteOSMView, updateOSMViewOrder } from "reduxLib/services";
import { cloneDeep } from "lodash";
import { useDeepCompareEffect } from "react-use";
import { useStyles, reorder, getItemStyle } from "./DNDList";
import { moveItem } from "helpers";
import CustomDialog from "components/common/Elements/CustomDialog";

const filterStyle = makeStyles(filterStyles);


const DNDListOSM = (props) => {

    const {
        hideOSMViewAction,
        changeOrder
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const filterClasses = filterStyle();

    const dispatch = useDispatch();

    const [dialog, setDialog] = React.useState({
        open: false,
        id: null,
        name: null
    });
    const [items, setItems] = React.useState([]);

    useDeepCompareEffect(() => {
        setItems(cloneDeep(props.items));
    }, [props.items])


    const onDragEnd = (result) => {
        if (!result || !result.source || !result.destination) return;
        const itemsTemp = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        changeOrder(itemsTemp);
        setItems(itemsTemp);
    }

    const clickChange = (i, type) => {
        if (i === 0 && type === "up") {
            return null;
        }
        let tempOrder = items;
        if (type === "up" && i > 0) {
            const temp = tempOrder[i - 1];
            tempOrder[i - 1] = tempOrder[i];
            tempOrder[i] = temp;
            changeOrder(tempOrder);
            setItems(tempOrder);
        }

        if (type === "down" && i < (tempOrder.length - 1)) {
            const temp = tempOrder[i + 1];
            tempOrder[i + 1] = tempOrder[i];
            tempOrder[i] = temp;
            changeOrder(tempOrder);
            setItems(tempOrder);
        }
    }

    const deleteView = (item) => {
        setDialog({
            ...dialog,
            open: true,
            id: item.id,
            name: item.name
        });
    }

    const textSize = 7;
    const iconSize = 1;

    return (
        <div className={classes.root} data-testid="dndlist-osm">
            <CustomDialog 
                open={dialog.open}
                setOpen={(e) => setDialog({
                    ...dialog,
                    open: e
                })}
                description={t("delete_view_error", {name: dialog.name})}
                event={() => {
                    dispatch(deleteOSMView(dialog.id));
                    setDialog({
                        ...dialog,
                        open: false
                    })
                }}
            />
            <OSMDragDropContext onDragEnd={onDragEnd}>
                <OSMDroppable droppableId="droppable">
                    {(provided_, snapshot_) => (
                        <RootRef rootRef={provided_.innerRef}>
                            <OSMList className={classes.list}>
                                {items.map((item, index) => (
                                    <OSMDraggable key={item.field} draggableId={item.field} index={index}>
                                        {(provided, snapshot) => (
                                            <OSMListItem
                                                className={classes.listitem}
                                                ContainerComponent="li"
                                                ContainerProps={{ ref: provided.innerRef }}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <Grid key={item.field} className={classes.parent} container spacing={3} alignItems="center">
                                                    <Grid item xs={textSize}>
                                                        <Typography
                                                            className={clsx(filterClasses.button, filterClasses.contentText, filterClasses.breakWord)}
                                                        >{t(item.title)} </Typography>
                                                    </Grid>
                                                    <Grid item xs={iconSize}>
                                                        <IconButton
                                                            aria-label="visible icon"
                                                            data-testid="osm_fav_icon"
                                                            className={classes.margin}
                                                            onClick={() => {
                                                                let currentFavIndex = 0
                                                                items.map((d, i) => {
                                                                    if (d.id === item.id) {
                                                                        currentFavIndex = i;
                                                                    }
                                                                });
                                                                const viewsUpdated = moveItem(items, currentFavIndex, 0);
                                                                dispatch(updateOSMViewOrder(viewsUpdated));
                                                                dispatch(favoriteOSMView(item.id))
                                                            }}
                                                            data-id={`id-${item?.isFav ? "fav": "unfav"}`}
                                                            size="small">
                                                            {
                                                                !item.isFav ?
                                                                    <FavoriteBorderOutlined fontSize="small" data-testid='osm_shuffledownarrow' className={classes.icon} /> :
                                                                    <Favorite fontSize="small" data-testid='osm_shuffledownarrow' className={classes.icon} />
                                                            }
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={iconSize}>{
                                                        <IconButton
                                                            aria-label="visible icon"
                                                            data-testid="osm_hide_icon"
                                                            className={classes.margin}
                                                            onClick={() => {
                                                                hideOSMViewAction(item)
                                                            }}
                                                            data-id={`id-${item?.hidden ? "hidden": "unhidden"}`}
                                                            size="small">
                                                            {
                                                                item.hidden ?
                                                                    <VisibilityOffIcon fontSize="small" data-testid='togglevisibilityon' name="visibilityoff" className={classes.icon} /> :
                                                                    <VisibilityIcon fontSize="small" name="visibilityon" data-testid='togglevisibilityoff' className={classes.icon} />
                                                            }
                                                        </IconButton>
                                                    }</Grid>
                                                    <Grid item xs={iconSize}>
                                                        <IconButton
                                                            onClick={() => clickChange(index, 'up')}
                                                            aria-label="visible icon"
                                                            data-testid="osm_up_icon"
                                                            role={`osm_up_icon_${index}`}
                                                            className={classes.margin}
                                                            size="small">
                                                            <ArrowUpwardIcon fontSize="small" data-testid='shuffleuparrow' />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={iconSize}>
                                                        <IconButton
                                                            onClick={() => clickChange(index, 'down')}
                                                            aria-label="visible icon"
                                                            data-testid="osm_down_icon"
                                                            role={`osm_down_icon_${index}`}
                                                            className={classes.margin}
                                                            size="small">
                                                            <ArrowDownwardIcon fontSize="small" data-testid='shuffledownarrow' />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={iconSize}>
                                                        <IconButton
                                                            onClick={() => deleteView(item)}
                                                            aria-label="visible icon"
                                                            data-testid="osm_del_icon"
                                                            role={item.public ? 'osm_shuffledownarrow_pub' : 'osm_shuffledownarrow_no_pub'}
                                                            disabled={item.public}
                                                            className={classes.margin}
                                                            size="small">
                                                            <DeleteOutlineOutlined
                                                                fontSize="small"
                                                                data-testid='osm_shuffledownarrow_no_pub'
                                                                className={!item.public ? classes.icon : classes.disabledicon}
                                                            />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <ListItemSecondaryAction />
                                            </OSMListItem>
                                        )}
                                    </OSMDraggable>
                                ))}
                                {provided_.placeholder}
                            </OSMList>
                        </RootRef>
                    )}
                </OSMDroppable>
            </OSMDragDropContext>
        </div>
    );

}

export default DNDListOSM;
