import {authConstants} from "../constants"


const addUser = variables => dispatch => {
    dispatch({
        type: authConstants.ADD_USER_DETAILS,
        payload: variables
    })
}

const removeUser = variables => dispatch => {
    dispatch({
        type: authConstants.REMOVE_USER_DETAILS,
        payload: variables
    })
}


export {
    addUser,
    removeUser
}