import React, {useState} from 'react';
import {
    OutlinedInput,
    MenuItem,
    FormControl,
    ListItemText,
    Select,
    Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useSetQuery from "customHooks/useQuery";
import { useStyles, MenuProps } from 'components/translation/TranslationDropDown';
import currencyMappings from 'theme/currencies';

export default function CurrencyDropDown() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [,setParams] = useSetQuery();

    const [currency, setCurrency] = useState(() => {
        return localStorage.getItem("currency") || "USD";
    });

    const handleChange = (e) => {
        setCurrency(e.target.value)
        localStorage.setItem("currency", e.target.value);
        setParams({ key: "currency", value: e.target.value })
    }

    return (
        <div>
            <FormControl className={classes.root} data-testid="currency-dropdown">
                <Select
                    labelId="currency-select"
                    className={classes.select}
                    id="currency-select"
                    value={currency}
                    onChange={handleChange}
                    input={<OutlinedInput sx={{ border: 'none' }} />}
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                    inputProps={{
                        "data-testid": "currency-select"
                    }}
                >
                    <MenuItem disabled value="" className={classes.menu} data-testid="select-currency">
                        {t("select_currency")}
                    </MenuItem>
                    {currencyMappings.map((item) => (
                        <MenuItem key={item.symbol} value={item.symbol} className={classes.menu} data-testid={item.symbol}>
                            <Radio checked={item.symbol === currency} className={classes.radio} />
                            <ListItemText primary={`${item.symbol} (${item.name})`} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
