import { emeaSummaryConstants } from "reduxLib/constants/EMEA/emeaSummaryConstants";
import fetch from "../serviceHelpers";
import { endpoints } from "helpers";
import moment from "moment-timezone";
import { emeaCountryCodes } from "configs/appConstants";

const customStatePaylod = ["SUBTYPE_EMEA_OPEN_ORDERS_INVOCIE_OPEN_GAP", "SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP", "SUBTYPE_EMEA_INVOICES_DETAILS_INVOICED_OPEN_GAP_TABULAR"];

const fillDates = (data, dates) => {
    const temp = {};
    const defaultObj = {
        ...data[0],
        totalCount: 0,
        totalCount2: 0
    }
    data.map(d => {
        temp[d.state] = d;
    });
    const startDate = moment(dates.startTime, 'YYYY-MM-DD');
    const endDate = moment(dates.endTime, 'YYYY-MM-DD');

    const dateValueMap = [];

    let currentDate = moment(startDate);
    while (currentDate.isSameOrBefore(endDate)) {
        const formatted = currentDate.format('YYYY-MM-DD');
        const formattedDesc = currentDate.format("MM/DD/YYYY");
        dateValueMap.push(temp[formatted] || {
            ...defaultObj,
            state: formatted,
            stateDesc: formattedDesc
        });
        currentDate.add(1, 'day');
    }
    // dateValueMap.sort((a, b) => {
    //     const dateA = new Date(a.stateDesc);
    //     const dateB = new Date(b.stateDesc);
    //     return dateB - dateA;
    // });
    return dateValueMap;
}

export const emeaSummaryService = variables => dispatch => {
    const { payload, controller } = variables;
    dispatch({
        type: emeaSummaryConstants.EMEA_SUMMARY_LOADING,
        payload: true,
        subtype: payload.subtype,
        error: null
    });

    return fetch(endpoints.emea.summary, {
        method: "POST",
        body: payload,
        signal: controller.signal
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            throw { name : "emea_api_failed" };
        })
        .then(response => {
            let processedData = payload.fillDates ?
                fillDates(response[payload.subtype], payload.documentDate) :
                response[payload.subtype];
            if (customStatePaylod.includes(payload.subtype)) {
                processedData = processedData?.map(d => {
                    return {
                        ...d,
                        stateDescCustom: `${emeaCountryCodes[d?.state]} (${d?.state})`
                    }
                })
            }

            dispatch({
                type: emeaSummaryConstants.EMEA_SUMMARY_SUCCESS,
                payload: processedData,
                subtype: payload.subtype,
                error: null
            });

        })
        .catch(error => {
            if (error?.name === "AbortError") {
                return;
            }
            dispatch({
                type: emeaSummaryConstants.EMEA_SUMMARY_FAILED,
                payload: [],
                subtype: payload.subtype,
                error: error.name
            });
        })
}

