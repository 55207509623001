const transportfilters = {
    searchStringList: {
        type: "text",
        name: "search",
        stringToArray: true,
        data: null
    },
    sites: {
        type: "sites",
        name: "origin",
        data: null
    },
    destName: {
        type: "text",
        name: "customer",
        data: null
    },
    collectiveNumList: {
        type: "text",
        name: "collectiveNumber",
        stringToArray: true,
        data: null
    },
    promotionalOrder: {
        type: "checkboxradio",
        name: "promotionalOrder",
        defaultValue: null,
        data: [
            { name: "show_only_promotional_orders", value: "Yes", checked: false },
            { name: "hide_promotional_orders", value: "No", checked: false },
        ]
    },
    orderExecutionBucket: {
        type: "checkbox",
        name: "shipment_status",
        data: [
            { name: "transportation_to_be_planned", value: "TRANS_PLAN_UNASSIGNED", checked: false },
            { name: "transportation_planning", value: "TRANS_PLAN_OPEN", checked: false },
            { name: "transportation_processing", value: "TRANS_PLAN_PROCESSING", checked: false },
            { name: "transportation_carrier_committed", value: "TRANS_PLAN_TENDER_ACCEPTED", checked: false },
            { name: "ready_for_pickup", value: "TRANS_EXEC_READY_PICK_UP", checked: false },
            { name: "in_transit", value: "TRANS_EXEC_IN_TRANSIT", checked: false },
            { name: "delivery_confirmed", value: "TRANS_EXEC_DELIVERY_CONFIRMED", checked: false }
        ]
    },
    fourkitesShipmentStatus: {
        type: "checkbox",
        name: "fourkites_status",
        data: [
            { name: "in_transit", value: "TRANS_EXEC_IN_TRANSIT", checked: false },
            { name: "delivery_confirmed", value: "TRANS_EXEC_DELIVERY_CONFIRMED", checked: false }
        ]
    },
    tmsShipmentStatus: {
        type: "checkbox",
        name: "tm_operational_status",
        data: [
            { name: "unassigned", value: "Unassigned", checked: false },
            { name: "open", value: "Open", checked: false },
            { name: "planned", value: "Planned", checked: false },
            { name: "tendered", value: "Tendered", checked: false },
            { name: "tender_rejected", value: "Tender Rejected", checked: false },
            { name: "tender_accepted", value: "Tender Accepted", checked: false },
            { name: "confirming", value: "Confirming", checked: false },
            { name: "in_transit", value: "In Transit", checked: false },
            { name: "completed", value: "Completed", checked: false }
        ]
    },
    appointmentType: {
        type: "checkbox",
        name: "appointment_type",
        data: [
            { name: "manual", value: "MANUAL", checked: false },
            { name: "automated", value: "AUTOMATED", checked: false }
        ]
    },
    destState: {
        type: "text",
        name: "destination_state",
        data: null
    },
    destCity: {
        type: "text",
        name: "destination_city",
        data: null
    },
    appointmentRequired: {
        type: "radio",
        name: "appointment_required",
        data: null
    },
    appointmentStatus: {
        type: "checkbox",
        name: "appointment_status",
        data: [
            { name: "notified", value: "NOTIFIED", checked: false },
            { name: "planned", value: "PLANNED", checked: false },
            { name: "suggested", value: "SUGGESTED", checked: false },
            { name: "confirmed", value: "CONFIRMED", checked: false },
            { name: "not_yet_contacted", value: "NOT_YET_CONTACTED", checked: false },
            { name: "appt_not_made_by_transport", value: "NOT_MADE_BY_TRANSPORT", checked: false },
        ]
    },
    liveLoad: {
        type: "radio",
        name: "live_load",
        data: null
    },
    equipmentTypeList: {
        type: "checkbox",
        name: "equipment_type",
        data: [
            { name: "full_truck_load", value: "FULL_TRUCK_LOAD", checked: false },
            { name: "less_than_truck_load", value: "LESS_THAN_TRUCK_LOAD", checked: false }
        ]
    },
    shippingConditionList: {
        type: "checkbox",
        name: "ship_condition",
        data: [
            { name: "truck_load_tl", value: "TL", checked: false },
            { name: "intermodal_tf", value: "TF", checked: false },
            { name: "open_op", value: "OP", checked: false },
            { name: "package_pg", value: "PG", checked: false },
            { name: "customer_pickup_pu", value: "PU", checked: false }
        ]
    },
    onHold: {
        type: "radio",
        name: "order_on_hold",
        data: null
    },
    deliveryApptDateTimeDestTZ: {
        type: "date",
        name: "delivery_date",
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        shortName: 'Del. Date',
        data: null
    },
    loadReadyDateTimeOriginTZ: {
        type: "date",
        name: "carrier_ready_date",
        shortName: 'Car.Ready Date',
        dummytime: true,
        timeformat: "T",
        startTime: "[00:00:00.000]",
        endTime: "[23:59:59.999]",
        data: null
    },
    tariffServiceCodeList : {
        type: "text",
        name: "carrier_service_code",
        stringToArray: true,
        data: null
    },
    orderExecutionHealth: {
        type: "checkbox",
        name: "health",
        data: [
            { name: "unhealthy", value: "RED", checked: false },
            { name: "needs_attention", value: "YELLOW", checked: false },
            { name: "healthy", value: "GREEN", checked: false }

        ]
    },
    loadingCompletedOnTime: {
        type: "radio",
        name: "loading_completed_on_time",
        data: null
    },
    actualDeliveredDateTime: {
        type: "date",
        name: "delivered_date_time",
        shortName: 'Delivered Date/Time ',
        data: null
    },
    confirmedDeliveryApptRescheduleCount: {
        type: "numrange",
        name: "reschedules",
        data: null
    }
}

export default transportfilters;