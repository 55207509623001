import React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { saveFilters } from "../../reduxLib/services";
import {
    Typography,
    makeStyles,
    Button,
    //useMediaQuery,
    //useTheme
} from "@material-ui/core";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { ExpandMore } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { formatDate } from "helpers";

const useStyles = makeStyles(theme => ({
    root: {
    },
    location: {
        height: theme.spacing(5),
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        paddingTop: theme.spacing(2),
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]:{
            paddingTop: theme.spacing(.5),
            marginTop: theme.spacing(1),
            "& > span" :{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }
        }
    },
    align: {
        display: 'flex',
        alignContent: 'left',
        justifyContent: 'middle',
        marginRight: theme.spacing(2),
    },
    dateFilter:
    {
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        fontSize: 10,
        position: "absolute",
        top: "-10px",
        left: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.text.underline,
        paddingRight: theme.spacing(0.5),
        color:theme.palette.white,
        paddingLeft: theme.spacing(0.5),
    }
}));


const DateRangeFilter = (props) => {

    const classes = useStyles();
    const { filterKey, filters, saveFilters, type, subtype, title } = props;

    const filter = get(filters, `${filterKey}.data`, { startTime: null, endTime: null });

    const [dates, setDates] = React.useState(filter);

    //const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const changeDates = (dates1) => {
        setDates(dates1);
        saveFilters({
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: dates1 || null
                }
            }, type, subtype
        });
    }

    React.useEffect(() => {
        setDates(filter);
    }, [filter])

    return (
        <DateRangePicker
            initialSettings={dates}
            onApply={(e, p) => {
                changeDates({
                    startTime: p.startDate.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].startTime : ''}`),
                    endTime: p.endDate.format(`YYYY-MM-DD ${filters[filterKey].dummytime ? filters[filterKey].endTime : ''}`)
                });
            }}
        >
            <Button
                data-testid='daterangefilter'
                disableRipple
                endIcon={<ExpandMore />}
                className={classes.location}
                color="primary">
               <Typography className={classes.dateFilter}> {title}</Typography>
                <DateRangeIcon /> <p>&nbsp; &nbsp;{formatDate(dates?.startTime) || "Start Date"} to {formatDate(dates?.endTime) || "End Date"}</p>
            </Button>
        </DateRangePicker>
    );
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype } = ownProps;
    return {
        filters: get(state, `options.filters.${subtype}`, {})
    }
};

const mapDispatchToProps = {
    saveFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(DateRangeFilter);
