import { laoLocationConstants } from "reduxLib/constants/LAO/laoLocationConstants";

const initialState = {
  locations_loading: false,
  locations: [],
  locations_err: null,
  locations_fav_loading: false,
  locations_fav: [],
  locations_fav_err: null
}

const laoLocationsReducer = (state = initialState, action) => {
  switch (action.type) {

    case laoLocationConstants.LAO_LOCATIONS_LOADING:
      return { ...state, locations_loading: action.payload };

    case laoLocationConstants.LAO_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload?.hasOwnProperty('authorizedSites') ? action.payload['authorizedSites'] : [],
        locations_err: action.error
      };

    case laoLocationConstants.LAO_LOCATIONS_FAILED:
      return { ...state, locations: [], locations_err: action.error };

    // case laoLocationConstants.LAO_LOCATION_FAV_LOADING:
    //   return { ...state, locations_fav_loading: action.payload };

    // case laoLocationConstants.LAO_LOCATION_FAV_SUCCESS:
    //   return { ...state, locations_fav: action.payload, locations_fav_err: 'success' };

    // case laoLocationConstants.LAO_LOCATION_FAV_FAILED:
    //   return { ...state, locations_fav_err: action.error };

    default:
      return state;
  }
}

export default laoLocationsReducer;  