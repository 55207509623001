export const TRANSLATIONS_PT = {
    "select_language": "SELECIONAR IDIOMA",
    "location": "Localização",
    "business": "Negócio",
    "region": "Região",
    "filter_by": "Filtrar por",
    "menu": "Menu",
    "favourites": "Favoritos",
    "you_do_not_have_any_favourites": "Você não tem nenhum favorito.",
    "favourites_message": "As opções favoritas aparecerão como seleções padrão sempre que você visitar o Logistics MAESTRO. Para agradar o cliente basta clicar no ícone fav",
    "rows_per_page": "Linhas por página",
    "no_records_to_display": "Nenhum registro a ser exibido",
    "records": "Arquivo",
    "loading": "Carregamento",
    "location_search": "Pesquisa de Localização",
    "search": "Procurar",
    "locations": "Locais",
    "daily": "Diário",
    "weekly": "Semanalmente",
    "exportVolume": "Volume de Exportação",
    "importVolume": "Volume de importação",
    "exportOrder": "Detalhes do pedido de exportação",
    "importOrder": "Detalhes do pedido de importação",
    "lao_restricted": "A página que você está tentando acessar é restrita.\n Se você quiser solicitar um acesso, entre em contato com nosso suporte.",
    "lao_sites_failed": "Não conseguimos processar os dados relacionados ao LAO no momento.\n Tente atualizar a página depois de algum tempo. Se ainda assim não ajudar, entre em contato com nosso suporte.",
    "views_loading_failed": "Falha no carregamento de exibições",
    "feedback": "Realimentação",
    "feedback_sucessfully_posted": "Feedback postado com sucesso!",
    "something_went_wrong_please_try_again": "Algo deu errado!, por favor, tente novamente",
    "send_us_feedback": "Envie-nos comentários",
    "thank_you_for_taking_the_time_to_give_us_feedback": "Obrigado por tomar o tempo para nos dar feedback.",
    "are_you_satisfied_with_your_experience": "Você está satisfeito com sua experiência?",
    "required": "Necessário",
    "submit_feedback": "Enviar comentários",
    "tell_us_about_your_experience": "Conte-nos sobre sua experiência",
    "network": "Rede",
    "my_selections": "Minha Seleção",
    "clear_selections": "Limpar seleções",
    "save_selections": "Salvar seleções",
    "view_more": "Saiba Mais",
    "view_less": "Ver menos",
    "filters": "Filtros",
    "views": "Modos de exibição",
    "add_view": "Adicionar Vista",
    "reset": "Repor",
    "new_view": "Nova Vista",
    "save": "Salvar",
    "delete": "Excluir",
    "new_view_name_msg": "Cria um novo modo de exibição com configurações padrão",
    "enter_a_view_name": "Insira um nome de exibição",
    "saved_successfully": "salvo com êxito",
    "untitled_view": "Exibição sem título",
    "created": "Criado",
    "deleted": "deletado",
    "filter_search": "PESQUISA DE FILTROS",
    "clear_all": "Apagar tudo",
    "select_all": "Selecionar tudo",
    "enter": "Entrar",
    "expand": "Expandir",
    "collapse": "Colapso",
    "all": "Todo",
    "manage_columns": "Gerenciar colunas",
    "show_all": "Mostrar tudo",
    "hide_all": "Ocultar tudo",
    "refresh": "Atualizar",
    "export": "Exportação",
    "apply_selections": "Aplicar seleções",
    "more": "mais",
    "loading_text": "Sente-se e relaxe, enquanto recebemos os dados carregados para você...",
    "we_are_sorry": "Lamentamos...",

    //LAO Table
    "purchase_order_origin": "PO",
    "purchase_order_trade": "PO FTZ",
    "invoice_num": "Número da fatura",
    "invoicing_date": "Data de faturamento",
    "vendor_number": "Número do fornecedor",
    "vendor_plant": "Planta do Fornecedor",
    "origin_plant": "Planta (Planta de Origem)",
    "free_trade_destination_plant": "Código FTZ da planta",
    "free_trade_destination_description": "Nome da ZFM da fábrica",
    "destination_plant": "Código do Cliente/Planta",
    "destination_description": "Nome do Cliente",
    "origin_port": "Porto de Origem",
    "international_shipper": "Expedidor Internacional",
    "booking_number": "Número da Reserva",
    "shipment_number": "Número de remessa de exportação",
    "load_number": "Número da Carga",
    "delivery_number": "Número de Entrega",
    "export_shipment_status": "Status da exportação",
    "origin_country": "País de Origem",
    "destination_country": "País de Destino",
    "bill_lading": "Conhecimento de embarque",
    "bid_lane_number": "Número da pista BID",
    "lao_order_details_search_helper_text": "Insira PO#, fatura#, Carga#, Entrega#.",
    "vendor_name_plant": "Nome do Fornecedor/Planta (Orgin Plant)",
    "customer_code_plant": "Código do Cliente/Planta",
    "customer_name": "Nome do Cliente",
    "container_id": "ID do contêiner",
    "sku_id": "SKU (Material)",
    "material_description": "Descrição do Material",
    "material_type": "Tipo de material",
    "import_shipment_number": "Número de remessa de importação",
    "vendor_name": "Nome do fornecedor",
    "import_pre_shipment_status": "Status pré-embarque",
    "import_post_shipment_status": "Status Pós-Embarque",
    "import_destination_port": "Nome da porta de destino",
    "import_license_number": "Número de licença de importação",
    "import_license_date": "Data da licença de importação",
    "delivery_date": "Data de Entrega",
    "shipment_mode": "Modo de Envio",
    "import_container_type": "Tipo de contêiner",
    "incoterms": "Incoterms",
    "export_shipment_etd_date": "KC ETD",
    "export_shipment_eta_date": "KC ETA",
    "export_kc_documental_cutoff_date": "Corte Documental",
    "import_etd_date": "KC ETD",
    "import_eta_date": "KC ETA",
    "import_id_date": "Data de identificação",
    "import_declaration": "Declaração de Importação",
    "import_customs_channel": "Canal da Alfândega",
    "import_free_period_end_date": "Fim do Período Grátis",
    "import_kc_dock_time": "Tempo de doca KC",
    "import_req_date_cont_at_plant": "Data Req Cont na Fábrica",
    "import_time_of_arrival_in_plant": "Tempo De Chegada Na Fábrica",
    "import_date_cont_at_plant": "Data Cont Na Fábrica",
    "import_hour_cont_at_plant": "Hour Cont na Fábrica",
    "import_transhipment_etd": "Transbordo ETD",
    "import_tentative_eta": "Previsão de Chegada Provisória",
    "import_tentative_etd": "ETD provisório",
    "import_customs_leave": "Licença Aduaneira",
    "import_container_return_date": "Data de devolução do contêiner",
    "delivery_completion_ind": "Conclusão da entrega",

    "po_item": "PO Item",
    "sku_material": "SKU/Material",
    "po_quantity": "Quantidade PO",
    "quantity_packed": "Quantidade embalada",
    "volume_packed": "Volume Embalado",
    "order_unit": "Unidade de Pedidos",

    "no_data_found": "Nenhum dado encontrado",
    "download_started_msg": "O download do arquivo foi iniciado. Isso pode levar alguns segundos.",
    "download_progress_msg": "O download do arquivo já está em andamento",
    "export_view": "MODO DE EXIBIÇÃO DE EXPORTAÇÃO",
    "with_product_details": "COM DETALHES DO PRODUTO",
    "without_product_details": "SEM DETALHES DO PRODUTO",
    "with_managed_columns_and_filters_applied": "Com colunas e filtros gerenciados aplicados",
    "well_this_is_unexpected": "Bem, isso é inesperado...",
    "please_wait": "Aguarde...",
    "download_successful": "Download Sucesso!",
    "download_failed_msg": "Desculpa! Falha no download do arquivo. Tente novamente.",
    "view_deleted_success": "Exibir excluído com êxito",
    "view_deleted_failed": "Falha na exclusão de exibição",
    "view_fav_unfav_success": "Ver Sucesso Favorito/Não Favorito",
    "view_fav_unfav_failed": "Exibir Favorito/Não Favorito Falhou",
    "start_date": "Data de início",
    "end_date": "Data de término",
    "please_wait_while_the_data_is_loading": "Aguarde enquanto os dados estão carregando...",
    "view_created_updated_success": "Exibir criado/atualizado com êxito",
    "view_creation_updation_failed": "Falha na criação/updatation da exibição",
    "list_of_favourited_selections": "Lista de Seleções Favoritas",
    "business_unit": "Unidade de negócios",
    "delivery_eta": "Entrega ETA",
    "atleast_one_business_unit_needs_to_selected": "Pelo menos uma Unidade de Negócios precisa ser selecionada",
    "exportOrderMobile": "Exportação",
    "importOrderMobile": "Importação",
    "lao_order_details_search_helper_text_import": "PO#, Invoice#, BL#, Número de Remessa de Importação.",
    "yes": "Sim",
    "no": "Não",
    "helperTextWithSeparator": "Para Pesquisa Múltipla, use apenas Ponto-e-vírgula como Separador. Ex: 111; 222 A pesquisa curinga(*) só está habilitada para Pesquisa Única. Ex: 111* ou 222*",
    "helperText": "Para pesquisa múltipla, use separadores como vírgula, espaço ou ponto-e-vírgula. Ex: 111.222 ou 111 222 ou 111; 222 A pesquisa curinga(*) só está habilitada para Pesquisa Única. Ex: 111* ou 222*"
}