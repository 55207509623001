import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from "lodash/isEmpty";
import CustomCellRender from "components/common/CustomCellRender";

const useStyles = makeStyles((theme) => ({
  hyperlink: {
    color: theme.palette?.extra?.info,
    textDecoration: `underline`
  }
}));

const EmeaSalesDocHyperlink = ({ salesDoc, id }) => {
  const classes = useStyles();
  const uri = `${process.env.REACT_APP_EMEA_SALES_DOC_URL}${salesDoc}`;

  if (salesDoc === '-' || !salesDoc) {
    return "-";
  }

  return (
    isEmpty(process.env.REACT_APP_EMEA_SALES_DOC_URL) ? 
      <CustomCellRender id={id} value={salesDoc} /> :
      <Link 
        data-testid={id}
        to={{ pathname: uri }}
        target="_blank"
        className={classes.hyperlink}
      >
        { salesDoc }
      </Link>
  )
}

export default EmeaSalesDocHyperlink;