export const TRANSLATIONS_DE = {
    "stock_constraint_report_title": "Bestandseinschränkungsbericht",
    "plant": "Pflanze",
    "load_date": "LADEDATUM",
    "search": "Suchen",
    "shipment": "Versand",
    "material": "Material",
    "suggested_inbound_shipments": "Vorgeschlagene eingehende Sendungen",
    "recommended_inbound_details": "Empfohlene eingehende Details",
    "outbound_title": "Auslaufend",
    "inbound_title": "Eingehend",
    "load_start_date_time": "Laden Startdatum und -uhrzeit",
    "incoming_stock": "Eingehender Lagerbestand",
    "unrestricted_quantity": "Uneingeschränkte Menge",
    "demand_quantity": "Bedarfsmenge",
    "no_record_found": "Kein Datensatz gefunden",
    "transportation_to_be_planned": "Transport soll geplant werden",
    "transportation_planning": "Verkehrsplanung",
    "transportation_processing": "Transportabwicklung",
    "transportation_carrier_committed": "Transportunternehmen verpflichtet",
    "checked_in_by_DC": "Eingecheckt von DC",
    "loading_started": "Laden gestartet",
    "ready_for_pickup": "Bereit zur Abholung",
    "in_transit": "Unterwegs",
    "delivery_confirmed": "Lieferung bestätigt",
    "in_yard": "Im Hof",
    "unloading": "Entladen",
    "unloading_completed": "Entladen abgeschlossen",
    "material_#": "Material #",
    "total_demand_quantity": "Gesamtbedarfsmenge",
    "total_unrestricted_quantity": "Uneingeschränkte Gesamtmenge",
    "delivery_quantity": "Liefermenge",
    "stock_balance": "Bestandsaldo",
    "shortage_quantity": "Mangelmenge",
    "actively_tracked_by_fourkites_eta": "Aktiv verfolgt von Fourkites eta",
    "trailer": "Anhänger",
    "carrier": "Träger",
    "shipment_#": "Versand #",
    "destination_eta": "Zielort ETA",
    "origin_eta": "Herkunft ETA",
    "shipment_end_date_&_time": "Enddatum und -uhrzeit des Versands",
    "estimated_unloading_time": "Geschätzte Entladezeit (in Stunden)",
    "yes": "Ja",
    "no": "Nein",
    "tracking_#": "Verfolgung #",
    "trailer_#": "Anhänger #",
    "yard_arrival_date_&_time": "Yard Ankunftsdatum & -zeit",
    "unloading_start_date_&_time": "Startdatum und -uhrzeit des Entladens",
    "distribution_breadcrumb_text": "Verteilung",
    "network_breadcrumb_text": "Netz",
    "order_management_breadcrumb_text": "Auftragsverwaltung",
    "transportation_breadcrumb_text": "Transport",
    "go_to_stock_constraint_report": "Zum Lagerbestandsbeschränkungsbericht",
    "live_status_text": "LIVE-STATUS",
    "shipment_created": "Sendung erstellt",
    "shipment_status": "Sendungsstatus",
    "order_management_title": "AUFTRAGSVERWALTUNG",
    "distribution_title": "VERTEILUNG",
    "transportation_planning_title": "TRANSPORTPLANUNG",
    "transportation_execution_title": "TRANSPORTAUSFÜHRUNG",
    "transport_title": "Transport",
    "outbound_order_details": "DETAILS FÜR AUSGEHENDE BESTELLUNGEN",
    "search_helper_text_network_page": "Geben Sie Sendung# Bestellung# Lieferung ein #",
    "blocked_tms_planned": "BLOCKIERTE TMS GEPLANT",
    "blocked_non_tms_planned": "BLOCKIERTE NICHT-TMS GEPLANT",
    "orders_block_free": "BESTELLUNGEN BLOCK FREE",
    "back_orders": "AUFTRAGSRÜCKSTAND",
    "material_availability_date": "Datum der Materialverfügbarkeit",
    "ship_outside_2_days": "Versand außerhalb von 2 Tagen",
    "healthy": "Gesund",
    "ship_within_2_days": "Versand innerhalb von 2 Tagen",
    "critical_risk": "Kritisches Risiko",
    "potential_risk": "Potenzielles Risiko",
    "planning_status": "PLANUNGSSTAND",
    "shipment_processing": "VERSANDABWICKLUNG",
    "carrier_committed": "Carrier engagiert sich",
    "transportation_execution_status": "STATUS DER TRANSPORTAUSFÜHRUNG",
    "suspended_status": "STATUS ANGEHALTEN",
    "appointment_status": "TERMINSTATUS",
    "transportation_details": "Details zum Transport",
    "search_helper_text_transportation_page": "Geben Sie Sendung# Bestellung# Trailer ID Delivery ein #",
    "carrier_ready_date": "Carrier Ready Datum",
    "business": "Geschäft",
    "atleast_one_business_unit_needs_to_selected": "Mindestens ein Geschäftsbereich muss ausgewählt werden",
    "business_unit": "Geshäftsbereich",
    "customer_sales_office": "KUNDEN-/VERTRIEBSBÜRO",
    "results": "Befund",
    "filter_by": "Filtern nach",
    "view_by": "Anzeigen nach",
    "customer": "Kunde",
    "sales_org": "Vertriebsorganisation",
    "search_customer": "KUNDEN SUCHEN",
    "results_found": "Ergebnisse gefunden",
    "save_selections": "Auswahl speichern",
    "clear_selections": "Auswahl aufheben",
    "favourites_message": "Bevorzugte Optionen werden bei jedem Besuch des Logistics MAESTRO als Standardauswahl angezeigt. Um den Kunden zu favorisieren klicken Sie einfach auf das Fav-Symbol",
    "location": "Ort",
    "sales_office_group": "VERTRIEBSBÜRO/GRUPPE",
    "you_can_favourite_locations!!": "Sie können Lieblingsorte !!",
    "favourited_message": "Bevorzugte Standorte werden bei jedem Besuch des Logistics MAESTRO als ausgewählter Standardstandort angezeigt.",
    "favourite_location_message_": "Um die Standorte zu favorisieren klicken Sie einfach auf das Fav-Symbol",
    "next_to_the_location_name": "neben dem Namen des Speicherorts.",
    "no_text": "Nein",
    "found_matching_the_search_term": "passend zum Suchbegriff gefunden",
    "sales_office": "Verkaufsbüro",
    "sales_groups": "Vertriebsgruppen",
    "list_of_sales_group_for": "Liste der Vertriebsgruppen für",
    "search_text": "SUCHEN",
    "list_of_sales_offices_for_sales_org": "Liste der Vertriebsbüros für Sales Org",
    "sales_office_customer": "Vertriebsbüro / Kunde",
    "menu": "Menü",
    "favourites": "Favoriten",
    "start_date": "Startdatum",
    "end_date": "Enddatum",
    "100_%_confirmed_cube": "100 % bestätigt Cube",
    "less_than_100%_confirmed_cube": "Weniger als 100% bestätigt Cube",
    "visible_in_tms": "Sichtbar in tms",
    "not_visible_in_tms": "In tms nicht sichtbar",
    "pickup_not_scheduled": "Abholung nicht geplant",
    "pickup_package_multi_block": "Pickup/Package Multiblock",
    "immediate_action": "Sofortiges Handeln",
    "delivery_#": "Lieferung #",
    "inbound_shipment_status": "Status der eingehenden Sendung",
    "order_#": "Bestellung #",
    "order_status": "Bestellstatus",
    "order_type": "Art der Bestellung",
    "origin_id": "Herkunfts-ID",
    "origin": "Ursprung",
    "dest_city": "Dest Stadt",
    "dest_state": "Dest-Staat",
    "original_rdd": "Ursprüngliche RDD",
    "rdd": "RDD",
    "pickup_appt_date_&_time": "Abholtermin Datum & Uhrzeit",
    "ship_condition": "Zustand des Schiffes",
    "reason_for_alert": "Grund für die Warnung",
    "distribution_channel": "Vertriebskanal",
    "sales_group": "Vertriebsgruppe",
    "mad": "MAD",
    "confirmed_delivery_appt_reschedule_count": "#Reschedules",
    "delivery_appt_date_&_time": "Datum und Uhrzeit des aktuellen Liefertermins",
    "confirmed_original_delivery_appt_date_time": "Ursprüngliche Lieferung: Termin, Datum &; Uhrzeit",
    "delivered_date_&_time": "Lieferdatum & -uhrzeit",
    "fourkites_status": "Fourkites Status",
    "late_reason_code": "Später Ursachencode",
    "actual_arrival_at_origin" : "Tatsächliche Ankunft am Ursprung",
    "actual_departure_at_origin" : "Tatsächliche Abfahrt am Ursprung",
    "actual_arrival_at_destination" : "Tatsächliche Ankunft am Zielort",
    "actual_departure_at_destination" : "Tatsächliche Abfahrt am Zielort",
    "records": "Aufzeichnungen",
    
    "sl_no": "Sl.No.",
    "material_id": "Material-ID",
    "customer_material_id": "Material-ID des Kunden",
    "material_description": "Materialbeschreibung",
    "ordered_qty": "Bestellte Menge",
    "confirmed_qty": "Bestätigte Menge",
    "load_qty": "Lademenge",
    "difference_qty": "Differenz Menge",
    "delivery_block": "Lieferblock",
    "product_details": "Produktdetails",
    "location_performance": "STANDORT-PERFORMANCE",
    "mill_dc_name": "MÜHLE/DC-NAME",
    "status": "Status",
    "net_loading_hours": "Nettoladestunden (zurück/voraus)",
    "no_data_found": "Keine Daten gefunden",
    "monitor": "Monitor",
    "behind": "Hinter",
    "critical": "Kritisch",
    "no_data": "Keine Daten",
    "performance_map_title": "SITE PERFORMANCE MAP – ZEITPLAN FÜR AUSGEHENDES LADEN (STUNDEN VORAUS/ZURÜCK)",
    "location_search": "Standortsuche",
    "refresh": "Auffrischen",
    "map_view": "Kartenansicht",
    "table_view": "Tabellenansicht",
    "filters": "Filter",
    "map_err_msg": "Tut mir leid dass etwas schief gelaufen ist.Bitte versuchen Sie es erneut oder versuchen Sie es mit einem anderen Browser",
    "map_loading_msg": "Karte wird geladen...",
    "ahead_more_than_3_hours": "(Voraus >3 Stunden)",
    "ahead_below_3_hours": "(Voraus 0.1-3 Stunden)",
    "behind_less_than_6_hours": "(Hinter >0 bis 6 Stunden)",
    "behind_more_than_6_hours": "(Hinter >6 Stunden)",
    "no_data_0_hour": "(Keine Daten 0 Stunde)",
    "filter_search": "FILTER SUCHE",
    "clear_all": "Alle löschen",
    "select_all": "Wählen Sie Alle",
    "enter": "Eintreten",
    "expand": "Erweitern",
    "collapse": "Zusammenbruch",
    "all": "Alle",
    "enter_a_view_name": "Eingeben eines Ansichtsnamens",
    "saved_successfully": "erfolgreich gespeichert",
    "untitled_view": "Unbenannte Ansicht",
    "created": "erstellt",
    "deleted": "gelöscht",
    "views": "Ansichten",
    "new_view_name_msg": "Erstellt eine neue Ansicht mit Standardeinstellungen",
    "add_view": "Ansicht hinzufügen",
    "reset": "Zurücksetzen",
    "new_view": "Neue Ansicht",
    "save": "Retten",
    "delete": "Löschen",
    "all_dc_and_mill": "Alle DC und Mühlen",
    "dc": "GLEICHSTROM",
    "mill": "MÜHLE",
    "destination_state": "Zielland",
    "destination_city": "Zielort",
    "carrier_service_code": "Servicecode des Mobilfunkanbieters",
    "requested_delivery_date": "Gewünschter Liefertermin",
    "delivered_date_time": "Lieferdatum/-uhrzeit",
    "customer_confirmed_delivery_date": "Vom Kunden bestätigter Liefertermin",
    "unhealthy": "Ungesund",
    "needs_attention": "Braucht Aufmerksamkeit",
    "order_block_free": "Block kostenlos bestellen",
    "order_blocked": "Bestellung blockiert",
    "manage_columns": "Spalten verwalten",
    "download_successful": "Download erfolgreich!",
    "download_failed_msg": "Tut mir Leid! Dateidownload fehlgeschlagen. Bitte versuchen Sie es erneut.",
    "network_table": "Network_Table",
    "order_management": "Order_Management",
    "transportation": "Transport",
    "distribution_inbound": "Distribution_Inbound",
    "distribution_outbound": "Distribution_Outbound",
    "customer_po_#": "Kunden-PO #",
    "leq": "LEQ",
    "confirmed_leq": "Bestätigte LEQ",
    "allocated_leq": "Zugewiesene LEQ",
    "order_sub_status": "Bestellunterstatus",
    "delivery_credit_block": "Lieferung/Gutschriftsblock",
    "enter_delivery_block_code": "Lieferblockcode eingeben",
    "show_only_credit_hold_orders": "Nur Guthabenaufträge anzeigen",
    "confirmed_cube": "Bestätigter Würfel",
    "100%_confirmed_cube": "100% bestätigter Würfel",
    "back_orders_block_free": "Back Orders Block Free",
    "back_orders_blocked": "Zurück Bestellungen blockiert",
    "all_customer_orders": "Alle Kundenbestellungen",
    "document_type": "Dokumenttyp",
    "standard_orders": "Standardbestellungen (ZSTD)",
    "multi_plant_orders": "Multi-Plant-Aufträge (ZMPF)",
    "vmi_orders": "VMI-Aufträge (ZVMI)",
    "merged_orders": "Zusammengeführte Aufträge (ZMER)",
    "foc_orders": "FOC Orders (ZFOC)",
    "shipping_condition": "Lieferbedingungen",
    "tm_operational_status": "TM Betriebsstatus",
    "unassigned": "Unbestimmt",
    "open": "Offen",
    "planned": "Geplant",
    "tendered": "Angeboten",
    "tender_rejected": "Angebot abgelehnt",
    "tender_accepted": "Angebot angenommen",
    "confirming": "Bestätigend",
    "completed": "Abgeschlossen",
    "appointment_type": "Art des Termins",
    "manual": "Manuell",
    "automated": "Automatisiert",
    "appointment_required": "Termin erforderlich",
    "notified": "Verständigt",
    "suggested": "Vorgeschlagen",
    "confirmed": "Bestätigt",
    "not_yet_contacted": "Noch nicht kontaktiert",
    "appt_not_made_by_transport": "Nicht von Transport",
    "live_load": "Verkehrslast",
    "equipment_type": "Gerätetyp",
    "full_truck_load": "Volle LKW-Ladung",
    "less_than_truck_load": "Weniger als LKW-Ladung",
    "truck_load_tl": "LKW-Ladung (TL)",
    "intermodal_tf": "Intermodal (TF)",
    "open_op": "Offen (OP)",
    "package_pg": "Paket (PG)",
    "customer_pickup_pu": "Kundenabholung (PU)",
    "order_on_hold": "Bestellung in der Warteschleife",
    "delivery_date": "Termin",
    "loading_completed_on_time": "Laden rechtzeitig abgeschlossen",
    "tm_status": "TM-Status",
    "appt_type": "Appt-Typ",
    "appt_req_d": "Appt Req'd",
    "appt_status": "Appt-Status",
    "short_lead": "Kurzer Vorlauf",
    "loading_completion_date_time": "Datum/Uhrzeit der Fertigstellung beim Laden",
    "hours_in_ready_for_pickup": "Stundenlang abholbereit",
    "load_#": "Last #",
    "dest": "Dest",
    "date_and_time_arrived_in_yard": "Datum und Uhrzeit im Yard angekommen",
    "duration_in_yard": "Dauer im Hof",
    "less_than_24_hours": "< 24 Stunden",
    "between_24_and_48_hours": "24 - 48 Stunden",
    "greater_than_72_hours": "> 72 Stunden",
    "date_arrived_in_yard": "Datum angekommen im Hof",
    "load_start_date": "Startdatum laden",
    "estd_load_hrs_rem": "Estd Load Hrs Rem",
    "total_load_hrs": "Gesamtlaststunden",
    "customer_appt_time": "KUNDENTERMIN",
    "stock_transfer_orders": "Umlagerungsaufträge",
    "total": "Gesamt",
    "blocked": "Gesperrt",
    "blocked_free": "Blockiert Kostenlos",
    "view_details": "Details anzeigen",
    "order_blocked_and_shipping_soon": "Bestellung gesperrt und Versand bald",
    "order_blocked_and_ship_date_past_due": "Bestellung blockiert und Versanddatum überfällig",
    "pickup_not_scheduled_and_shipping_soon": "Abholung nicht geplant und Versand bald",
    "pickup_not_scheduled_and_ship_date_past_due": "Abholung nicht geplant und Versanddatum überfällig",
    "order_blocked_and_immediate_action_needed": "Bestellung blockiert und Sofortiges Handeln erforderlich",
    "greater_than_1000_confirmed_cube_&_shipping_soon": "< 1000 bestätigte Würfel & Versand bald",
    "greater_than_1000_confirmed_cube_&_ship_date_past_due": "< 1000 bestätigte Würfel & Versanddatum überfällig",
    "zero_confirmed_cube_&_shipping_soon": "Null bestätigter Würfel & Versand bald",
    "zero_confirmed_cube_&_ship_date_past_due": "Null bestätigter Würfel & Versanddatum überfällig",
    "not_100%_confirmed": "Nicht 100% bestätigt",
    "short_lead_time": "Kurze Vorlaufzeit",
    "can_put_more_product_on_trailer": "Kann mehr Produkt auf Anhänger legen",
    "load_too_heavy": "Last zu schwer",
    "order_too_big_cannot_fit_on_trailer": "Zu groß bestellen - kann nicht auf Anhänger passen",
    "past_due_for_planning": "Überfällig für die Planung",
    "short_lead_or_no_customer_appointment": "Kurzer Vorlauf oder kein Kundentermin",
    "not_on_track_to_meet_standard_leadtime": "Nicht auf dem richtigen Weg um die Standardvorlaufzeit einzuhalten",
    "shipping_today_or_in_the_past_and_carrier_not_confirmed": "Versand heute oder in der Vergangenheit und Spediteur nicht bestätigt",
    "shipping_soon_and_carrier_not_confirmed": "Versand bald und Spediteur nicht bestätigt",
    "delivery_document_not_created_and_shipping_today_or_in_the_past": "Lieferschein nicht erstellt und Versand heute oder in der Vergangenheit",
    "delivery_document_not_created_and_shipping_soon": "Lieferschein nicht erstellt und Versand bald",
    "past_due_load_not_picked_up": "Überfällig - Ladung nicht abgeholt",
    "past_due_delivery_not_confirmed": "Überfällig - Lieferung nicht bestätigt",
    "shipment_not_started_and_LRTC_is_within_12_hours": "Versand nicht gestartet und LRTC ist innerhalb von 12 Stunden",
    "not_on_track_to_achieve_LRTC": "Nicht auf dem richtigen Weg um LRTC zu erreichen",
    "past_due_late_for_arrival_time": "Überfällig - verspätet zur Ankunftszeit",
    "in_yard_more_than_72_hrs": "Im Hof mehr als 72 Stunden",
    "unloading_taking_more_than_24_hrs": "Entladung dauert mehr als 24 Stunden",
    "export": "Exportieren",
    "export_view": "EXPORT-ANSICHT",
    "with_product_details": "MIT PRODUKTDETAILS",
    "without_product_details": "OHNE PRODUKTDETAILS",
    "region": "Region",
    "customers_sales_office": "Kunden / Vertriebsbüro",
    "show_all": "Alle anzeigen",
    "hide_all": "Alle ausblenden",
    "consumer": "Verbraucher",
    "professional": "Professionell",
    "above_guard_rail": "Über Leitplanke",
    "below_guard_rail": "Unterhalb der Leitplanke",
    "shipment_load_number": "Sendungs-/Ladenummer",
    "latest_location": "Aktueller Standort",
    "destination": "Bestimmungsort",
    "mode": "Modus",
    "stops": "Haltestellen",
    "tracking_details": "Tracking-Details",
    "planned_arrival": "Geplante Anreise",
    "appointment_time": "Termin",
    "detected_near_facility": "In der Nähe der Anlage erkannt",
    "departed_facility": "Verlassene Einrichtung",
    "network_view": "Netzwerkansicht",
    "my_selections": "Meine Auswahl",
    "view_more": "Mehr anzeigen",
    "view_less": "Weniger anzeigen",
    "you_do_not_have_any_favourites": "Sie haben keine Favoriten.",
    "list_of_favourited_selections": "Liste der favoritenten Auswahlen",
    "home": "Heim",
    "glossary": "Glossar",
    "close_menu": "Menü schließen",
    "supply_chain_analytics": "Supply-Chain-Analysen",
    "last_updated": "Letzte Aktualisierung",
    "feedback": "Feedback",
    "feedback_sucessfully_posted": "Feedback erfolgreich gepostet!",
    "something_went_wrong_please_try_again": "Etwas ist schief gelaufen! Bitte versuchen Sie es erneut",
    "send_us_feedback": "Senden Sie uns Feedback",
    "thank_you_for_taking_the_time_to_give_us_feedback": "Vielen Dank dass Sie sich die Zeit genommen haben uns Feedback zu geben.",
    "are_you_satisfied_with_your_experience": "Sind Sie mit Ihrer Erfahrung zufrieden?",
    "required": "Erforderlich",
    "submit_feedback": "Feedback senden",
    "hide": "Verstecken",
    "show": "Zeigen",
    "site_type": "Typ der Website",
    "domestic": "Häuslich",
    "international": "International",
    "access_sales_group_by_clicking_on_the_sales_office": "Greifen Sie auf die Vertriebsgruppe zu indem Sie auf das Verkaufsbüro klicken",
    "found": "Gründen",
    "to_be_planned": "Noch zu planen",
    "planning": "Planung",
    "something_went_wrong": "Etwas ist schief gelaufen",
    "please_wait": "Bitte warten...",
    "location_volume_per_day": "STANDORTVOLUMEN PRO TAG",
    "in_hours": "IN STUNDEN",
    "in_truck_loads": "IN LKW-LADUNGEN",
    "edit_update_guardrails": "Leitplanken bearbeiten/aktualisieren",
    "unable_to_load": "Laden nicht möglich",
    "time_zone": "ZEITZONE",
    "select_language": "SPRACHE AUSWÄHLEN",
    "search_helper_text_order_page": "Geben Sie Sendung# Bestellung# Kunde PO# Lieferung ein #",
    "origin_sites": "Herkunftsorte",
    "view": "Ansehen",
    "more": "Mehr",
    "less": "Weniger",
    "loads": "Lädt",
    "orders": "Aufträge",
    "unit": "Einheit",
    "hours": "Stunden",
    "hour": "Stunde",
    "update_changes": "Änderungen aktualisieren",
    "distribution_guardrails": "Verteilergeländer",
    "download_started_msg": "Dateidownload gestartet. Dies kann einige Sekunden dauern.",
    "download_progress_msg": "Dateidownload wird bereits ausgeführt",
    "rows_per_page": "Zeilen pro Seite",
    "no_records_to_display": "Keine Anzuzeigenden Datensätze",
    "track_trace": "Sendungsverfolgung",
    "with_managed_columns_and_filters_applied": "Mit angewendeten verwalteten Spalten und Filtern",
    "pickup": "Schalldose",
    "delivery": "Lieferung",
    "just_now": "gerade",
    "promotional_material": "Werbematerial",
    "indicates_promotional_orders": "Zeigt Werbebestellungen an",
    "promotionalOrder": "Aktionsbestellung",
    "collectiveNumber": "Sammelnummer",
    "tell_us_about_your_experience": "Erzählen Sie uns von Ihren Erfahrungen",
    "and": "und",
    "show_tabs_by": "Tabs anzeigen nach",
    "monday": "Montag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "thursday": "Donnerstag",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag",
    "of": "von",
    "health": "Gesundheit",
    "show_only_promotional_orders": "Nur Werbebestellungen anzeigen",
    "hide_promotional_orders": "Werbeaufträge ausblenden",
    "above_current_guard_rail": "Über der aktuellen Leitplanke",
    "below_current_guard_rail": "Unterhalb der aktuellen Leitplanke",
    "tutorials_&_resources": "Tutorials & Ressourcen",
    "fourkites_back_button": "Zurück zu den Suchergebnissen",

    //Guadrail Page
    "accept": "Annehmen",
    "decline": "Ablehnen",
    "enter_reason_here": "Grund hier eingeben",
    "close": "Schließen",
    "name": "Name",
    "reason": "Grund",
    "please_mention_reason_for_decline": "Bitte geben Sie den Grund für die Ablehnung des Zugriffs auf Bearbeitungs-/Aktualisierungsleitplanken an",
    "your_request_submitted_wait_for_approval": "Ihre Anfrage wurde übermittelt. Bitte warten Sie auf die Appproval. Sie werden per E-Mail benachrichtigt",
    "request_access": "Zugang anfordern",
    "please_mention_reason_for_request": "Bitte erwähnen Siewarum Sie Zugriff auf Bearbeitungs-/Aktualisierungsgeländer benötigen*",
    "edit_update_access_req_for_guardrail": "Zugriff bearbeiten/aktualisieren Anforderung für Leitplankendiagramm",
    "pending_requests": "Ausstehende Anfragen",
    "no_pending_requests": "Keine ausstehenden Anfragen",

    // Transport - Item details table
    "actual_departure_from_destnation": "Tatsächliche Abkehr von der Destnation",
    "sap": "SAP",
    "fourkites": "Fourkites",
    "tms": "TMS",
    "actual_departure_from_origin": "tatsächliche Abweichung vom Ursprung,",
    "yms": "YMS",

    //Transport Details
    "trailer_status": "Trailer-Status",
    "percent_Of_trailer_loaded": "% der beladenen Anhänger",
    "customer_notified": "Kunde benachrichtigt",
    "delivery_appt_confirmed_by_customer": "Vom Kunden bestätigte Lieferappt",
    "order_created_in_tms": "In TMS erstellte Bestellung",

    "min": "Min",
    "max": "Max",
    "reschedules": "Verschiebungen",
    "min_max_error_text": "Der Maximalwert sollte größer oder gleich dem Minimalwert sein",
    "enter_value": "Wert eingeben",   
    "daily": "täglich",
    "weekly": "wöchentlich",
    "exportVolume:": "Exportvolumen",
    "importVolume": "Importvolumen"
}