import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { osmTrailerVisualStyles } from "theme";
// import { mockTrailerData } from "components/dummydata/osmMockData";
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import TrailerHeadSVG from "assets/images/red_trailer.svg";
import PalletSVG from "assets/images/pallet.svg";
// import PreBuiltPalletSVG from "assets/images/pre_built_pallet.svg";
import SlipsheetSVG from "assets/images/slipsheet.svg";
import FloorLoadSVG from "assets/images/floor_load.svg";
import HazardSVG from "assets/images/hazardgoods_legend.svg";
import HazardIcon from "assets/images/hazard_icon.svg";
import RotateIcon from "assets/images/rotate-stack.svg";

const useStyles = makeStyles(osmTrailerVisualStyles);

const useStyles2 = makeStyles((theme) => ({
  pallet: {
    backgroundImage: `url(${PalletSVG})`,
  },
  // builtInPallet: {
  //   backgroundImage: `url(${PreBuiltPalletSVG})`
  // },
  slipsheet: {
    background: theme.palette?.background?.tertiaryBase,
  },
  floor: {
    background: theme.palette?.white,
    border: `1px solid ${theme.palette?.black}`
  }
}));

const Osm2DTrailerVisual = ({lsrData, leftStackArray, rightStackArray}) => {
  const trailerClasses = useStyles();
  const classes = useStyles2();
  const { t } = useTranslation();
  const [selectedStack, setSelectedStack] = useState(null);
  const compartments = lsrData?.trailerDimension || [];
  const rows = Math.max(rightStackArray?.length, leftStackArray?.length);
  const rowList = rows ? Array(rows).fill(0) : [];

  const legends = [{
    name: t('pallet'),
    icon: PalletSVG,
    alt: 'pallet'
  },{
  //   name: t('pre_built_pallet'),
  //   icon: PreBuiltPalletSVG,
  //   alt: 'pre-built pallet'
  // },{
    name: t('slipsheet'),
    icon: SlipsheetSVG,
    alt: 'slipsheet'
  },{
    name: t('floor_loaded'),
    icon: FloorLoadSVG,
    alt: 'floor load'
  }]

  useEffect(() => {
    if (leftStackArray?.length) {
      setSelectedStack(leftStackArray[0]);
    } else if (rightStackArray?.length) {
      setSelectedStack(rightStackArray[0]);
    } else return;
  }, [])

  const handleSelectedStack = (stack) => {
    setSelectedStack(stack);
  }

  const ShipWeightInfo = () => {
    return (
      <Card elevation={0} className={trailerClasses.cardRoot}>
        <CardHeader className={trailerClasses.cardHeader} title={ <Typography variant='h3'> { t('ship_weight_info') } </Typography> }/>
        <CardContent >
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('status') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.loadingInstruction || 'N/A'} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('net_weight') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.netWeight || 'N/A'} </Typography>
            </Grid>
          </Grid>
          <Divider className={trailerClasses.divider}/>
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('steer_axle_weight') }(LB) </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.steerAxleWegiht || 'N/A'} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('drive_axle_weight') }(LB) </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.driveAxleWeight || 'N/A' } </Typography>
            </Grid>
          </Grid>
          <Divider className={trailerClasses.divider}/>
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('rear_axle_min_max_setting') }(IN) </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> { lsrData?.rearAxleMinSetting || 'N/A' } : { lsrData?.rearAxleMaxSetting || 'N/A' } </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('rear_axle_min_max_weight') }(LB) </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.rearAxleMinWeight || 'N/A' } : { lsrData?.rearAxleMaxWeight || 'N/A'} </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
)
  }

  const ShipVolumeInfo = () => {
    return (
      <Card elevation={0} className={trailerClasses.cardRoot}>
        <CardHeader className={trailerClasses.cardHeader} title={ <Typography variant='h3'> { t('ship_volume_info_of_stack') } </Typography> }/>
        <CardContent >
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('trailer_stack') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {selectedStack?.rowID || 'N/A'} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('pallet_size') }(IN) </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {selectedStack?.palletSize || 'N/A'} </Typography>
            </Grid>
          </Grid>
          <Divider className={trailerClasses.divider}/>
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('ideal_volume') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {selectedStack?.idealVolume || 'N/A'} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('actual_volume') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {selectedStack?.actualVolume || 'N/A'} </Typography>
            </Grid>
          </Grid>
          <Divider className={trailerClasses.divider}/>
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('occupancy') } % </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {selectedStack?.occupancyPercent || 'N/A'} </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const CompartmentInfo = () => {
    return (
      <Card elevation={0} className={trailerClasses.cardRoot}>
        <CardHeader className={trailerClasses.cardHeader} title={ <Typography variant='h3'> { t('Compartments') } (IN) </Typography> }/>
        <CardContent >
          <Grid container item>
            <Grid item xs={3} className={trailerClasses.compartmentGrid}>
              <Typography variant='h4' className={trailerClasses.primaryText}> { t('compartmentID') } </Typography>
              { compartments.map(item => item?.LOADABLE_LENGTH != '0' && <Typography variant='body1' className={trailerClasses.dimension}> {item?.id} </Typography> )}
            </Grid>
            <Grid item xs={3} className={trailerClasses.compartmentGrid}>
              <Typography variant='h4' className={trailerClasses.primaryText}> { t('length') } </Typography>
              { compartments.map(item => item?.LOADABLE_LENGTH != '0' && <Typography variant='body1' className={trailerClasses.dimension}> {item?.LOADABLE_LENGTH} </Typography> )}
            </Grid>
            <Grid item xs={3} className={trailerClasses.compartmentGrid}>
              <Typography variant='h4' className={trailerClasses.primaryText}> { t('frontHeight') } </Typography>
              { compartments.map(item => item?.LOADABLE_LENGTH != '0' && <Typography variant='body1' className={trailerClasses.dimension}> {item?.LOADABLE_FRONT_HEIGHT} </Typography> )}
            </Grid>
            <Grid item xs={3} className={trailerClasses.compartmentGrid}>
              <Typography variant='h4' className={trailerClasses.primaryText}> { t('width') } </Typography>
              { compartments.map(item => item?.LOADABLE_LENGTH != '0' && <Typography variant='body1' className={trailerClasses.dimension}> {item?.LOADABLE_WIDTH} </Typography> )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const RemainingGapInfo = () => {
    return (
      <Card elevation={0} className={trailerClasses.cardRoot}>
        <CardHeader className={trailerClasses.cardHeader} title={ <Typography variant='h3'> { t('remaining_gap') } (IN) </Typography> }/>
        <CardContent >
          <Grid container item>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('left') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.remainingGapLeft || 'N/A'} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h6' className={trailerClasses.primaryText}> { t('right') } </Typography>
              <Typography variant='body1' className={trailerClasses.secondaryText}> {lsrData?.remainingGapRight || 'N/A'} </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const Layout = ({data}) => {
    return (
      <>
        {data?.length ? data.map(row => 
          <Grid container item className={selectedStack?.rowID === row.rowID ? trailerClasses.activePackage : trailerClasses.package} onClick={() => handleSelectedStack(row) }>
            <div className={trailerClasses.packageInfo}>
              {/* <Typography variant='body2' className={trailerClasses.secondaryText}>
                { t('load_seq') } : <strong>{row?.loadSequence}</strong>
              </Typography> */}
              <span>
                { row?.hazIndForStack === "Y" && <img src={HazardIcon} alt="hazardous" className={trailerClasses.hazIcon} /> }
                { row?.rotateInd === "Y" && <img src={RotateIcon} alt="Rotate icon" /> }
              </span>
            </div>
            <Grid container item className={trailerClasses.packageInfo2}>
              <Grid item xs={1}>
                <Typography variant='body2' className={trailerClasses.secondaryText}> { 'L.S' } </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body2' className={trailerClasses.secondaryText}> { 'CODE' } </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='body2' className={trailerClasses.secondaryText}> { 'QTY' } </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='body2' className={trailerClasses.secondaryText}> { 'PLCMNT' } </Typography>
              </Grid>
            </Grid>
            {row.materialsInfo.map(pkg => 
              <>
                <Grid container item className={pkg?.slipsheetPalletInd === 'P' ? clsx(trailerClasses.packageInfo2, classes.pallet) : 
                  pkg?.slipsheetPalletInd === 'S' ? clsx(trailerClasses.packageInfo2, classes.slipsheet) : clsx(trailerClasses.packageInfo2, classes.floor)}>
                  <Grid item xs={1}>
                    <Typography variant='body2' className={trailerClasses.primaryText}> { pkg?.wmsSequence } </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body2' className={trailerClasses.primaryText}>
                     {pkg?.hazIndForMaterial === "Y" ? <img src={HazardIcon} alt="hazardous" /> : null} { pkg?.materialNumber } 
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body2' className={trailerClasses.primaryText}> { pkg?.orderQty } </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='body2' className={trailerClasses.primaryText}> { pkg?.placement } </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        ) : null}
      </>
    )
  }

  return (
    <Box data-testid="osmTrailerVisual" className={trailerClasses.main}>
      <Grid container>
        <Grid container item md={12} lg={8} className={trailerClasses.trailerGrid}>
          <img alt="trailer head" src={TrailerHeadSVG} />
          <Grid container item className={trailerClasses.trailerBody}>
          <Grid container item className={trailerClasses.rowSpanGrid}>
            {rows ? rowList.map((val, ind) => 
                <span className={trailerClasses.rowSpan}>{`ROW ${ind+1}`}</span>
            ) : null}
            </Grid>
            <Grid container item xs={6} className={trailerClasses.trailerLayoutLeft}>
              <Typography variant='h3' className={trailerClasses.layoutTitle}> { t('left') } </Typography>
              <Layout data={leftStackArray} />
              {!isEmpty(lsrData.remainingGapLeft) &&
                <Typography variant='body1' className={trailerClasses.layoutTitle}> { t('gap') }: { lsrData.remainingGapLeft } (IN) </Typography>
              }
            </Grid>
            <Grid container item xs={6} className={trailerClasses.trailerLayoutRight}> 
              <Typography variant='h3' className={trailerClasses.layoutTitle}> { t('right') } </Typography>
              <Layout data={rightStackArray} />
              {!isEmpty(lsrData.remainingGapRight) &&
                <Typography variant='body1' className={trailerClasses.layoutTitle}> { t('gap') }: { lsrData.remainingGapRight } (IN) </Typography>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={12} lg={4} className={trailerClasses.infoGrid}>
          {legends.map(legend => 
            <Grid item xs={4} className={trailerClasses.legendGrid}>
              <Typography variant='body1'> {legend.name} </Typography>
              <img alt={legend.alt} src={legend.icon} />
            </Grid>
          )}
          <Grid item xs={12} className={trailerClasses.legendGrid2}>
            <img alt="hazardous" src={HazardSVG} />&nbsp;&nbsp;
            <Typography variant='body1'> { t('hazardous_goods') } </Typography>
          </Grid>
          <ShipWeightInfo />
          <ShipVolumeInfo />
          <CompartmentInfo />
          <RemainingGapInfo />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Osm2DTrailerVisual;