import React from 'react'
import { Grid, Typography } from "@material-ui/core"
import TableActionsFilters from "./Table/TableActions";
import Filters from "./Filters";
import DateRangeFilter from "./DateRangeFilter";
import "assets/css/datepicker.css"

const TableActionsWrapper = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleClick = React.useCallback(() => {
        setOpen((prev) => !prev);
    }, [open]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, [open]);


    const { title,
        topDateFilter,
        type,
        tableName,
        preSetStatusFilter,
        fetchAllData,
        columnOrder,
        columns,
        setColumnsCustom,
        filterType,
        excludeArray,
        searchTextPlaceholder,
        filterBody,
        refreshTable,
        tableRef } = props;

    return (
        <>
            <Grid
                data-testid='tablecomponent'
                container spacing={3}
                direction="row"
                justify="space-between"
                alignItems="baseline">
                <Grid container item xs={12} sm={4} md={6} lg={6} justify="flex-start" spacing={2} >
                    {title && <Grid item ><Typography color="primary" variant="h3" data-testid={`${tableName}_tablename`} >{title}</Typography></Grid>}
                    {topDateFilter && <Grid item xs={12} sm={12} md={9} lg={10}><DateRangeFilter
                        type={type}
                        subtype={tableName}
                        preSetFilter={preSetStatusFilter ? true : false}
                        title={topDateFilter.title}
                        filterKey={topDateFilter.key}
                        /></Grid>
                    }  
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={6} justify="flex-end" >
                    <TableActionsFilters
                        type={type}
                        downloads={fetchAllData}
                        columns={columnOrder}
                        defaultColumns={columns?.columnOrder}
                        columnsAction={setColumnsCustom}
                        filters={
                            <Filters
                                filtertype={filterType}
                                handleOpen={handleClose}
                                open={open}
                                type={type}
                                subtype={tableName}
                                exclude={excludeArray}
                                searchText={searchTextPlaceholder}
                            />
                        }
                        handleClose={handleClose}
                        filterBody={filterBody}
                        handleClick={handleClick}
                        subtype={tableName}
                        refreshTable={() => refreshTable(tableRef)}
                    />
                </Grid>
            </Grid>
        </>
    )

}

export default TableActionsWrapper;