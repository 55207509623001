import React, { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MaterialTable from 'material-table'
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import EmeaTableActionsWrapper from './EmeaTableActionsWrapper';
import EmeaFilterBox from './EmeaFilterBox';
import { lightTableOptions } from 'helpers/tableStyleOverride';
import { getEmeaTableData } from 'reduxLib/services/EmeaServices/emeaTableServices';
import { tableStyles } from 'theme';
import cloneDeep from 'lodash/cloneDeep';
import useSetQuery from 'customHooks/useQuery';
import useScrollInfo from 'theme/layouts/ScrollHelper';
import { useRefreshTable } from 'customHooks/useTableQuery';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy'
import Snack from "components/common/Helpers/Snack";
import { generateFilterPayload } from "helpers/emeaHelpers";

const useStylesTable = makeStyles(tableStyles);
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTablePagination-selectIcon': {
      color: "inherit"
    }
  },
  spacer: {
    flex: "1 1 auto"
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  },
  noData: {
    textAlign: 'left',
    position: 'relative',
    paddingLeft: `${theme.spacing(2.5)}%`
  }
}));

const EmeaTable = (props) => {
  const {
    tableName,
    tableRef,
    columns,
    defaultColumns,
    subType,
    orderType,
    orderBy,
    fetchEndPoint,
    filterBody,
    filterType,
    filterExclusionArray,
    searchText,
    fixedColumn
  } = props;
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const { t } = useTranslation();
  const [params, setParams] = useSetQuery();

  const type = 'emea';

  const emeaTableData = useSelector(({ emeaTable }) => emeaTable?.[type]?.[tableName] || {});
  const { activeView } = useSelector(({ emeaViews: { activeView } }) => ({ activeView }));

  const { data, status, loading } = emeaTableData;

  const [controller] = useState(() => new AbortController());
  const [columnsOrder, setcolumnsOrder] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [tableData, setTableData] = useState({});
  const { items, totalRecords } = tableData;
  const [, setRef, ref] = useScrollInfo();
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });
  
  const tableBody = {
    region: 'EMEA',
    searchType: subType,
    orderType: orderType,
    orderBy: orderBy,
    ...(generateFilterPayload(filterBody)),
  };
  const [updatePageParams, refreshTable] = useRefreshTable({
    fetchEndPoint,
    tableName,
    type,
    controller,
    tableBody,
    setPage,
    getTableData: getEmeaTableData
  });

  const executeScroll = () => {
    return ref?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (status?.status === "error") {
      setSnack({
        open: true,
        severity: status.status,
        message: t(status.message)
      });
    }
  }, [status])

  useEffect(() => {
    if (params.isHyperlink) {
      executeScroll();
      setParams({ key: "isHyperlink", value: undefined })
    }
  }, [params.isHyperlink]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    updatePageParams({ pageSize: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPage = (e) => {
    const pageSize = parseInt(e.target.value, 10)
    setRowsPerPage(pageSize);
    setPage(0);
    updatePageParams({ pageSize: pageSize, page: 0 });
  };

  const updateColumns = (columns) => {
    setcolumnsOrder(columns);
  }

  const clearAllFilters = () => {
    setParams({ key: "emeaFilters", value: {} })
  };

  useDeepCompareEffect(() => {
    if (!isEmpty(activeView.columns)) {
      const actualColumns = defaultColumns.columnOrder;
      let temp = {};
      actualColumns.map(d => {
        temp[d.field] = d;
      });
      let viewColumnObject = activeView.columns;
      viewColumnObject = viewColumnObject.map(d => {
        return {
          ...d,
          ...(temp[d.field]),
          title: t(d.title),
        }
      })
      const newDiffColumns = differenceBy(defaultColumns.columnOrder, activeView.columns, 'field');

      if (newDiffColumns.length) {
        const translated = newDiffColumns.map(item => {
          return {
            ...item,
            title: t(item.title)
          }
        });
        viewColumnObject = [...viewColumnObject, ...translated];
      }
      setcolumnsOrder(viewColumnObject);
    }
  }, [activeView.columns]);

  useDeepCompareEffect(() => {
    const finalColumns = columns.columnOrder;
    let temp = {};
    columns.columnOrder.map(d => {
      temp[d.field] = d;
    });
    let translated = finalColumns?.map(item => {
      return {
        ...temp[item.field],
        ...item,
        title: t(item.title),
      }
    });
    setcolumnsOrder(translated);
  }, [localStorage.getItem('i18nextLng'), columns.columnOrder]);


  useDeepCompareEffect(() => {
    const tempData = data?.items?.map(d => {
      return {
        ...d,
        ...columns.columnConfiguration(d)
      }
    }) || [];
    setTableData({ ...(data || {}), items: tempData });
  }, [data])

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <Grid className={{
      [tableClasses.root]: true,
      [classes.root]: true
    }}>
      <EmeaTableActionsWrapper
        title={t('order_details')}
        tableName={tableName}
        tableRef={tableRef}
        refreshTable={() => refreshTable({ pageSize: rowsPerPage, page })}
        filterType={filterType}
        type={type}
        subtype={tableName}
        filterExclusionArray={filterExclusionArray}
        searchText={t(searchText)}
        columns={columnsOrder}
        totalRecords={totalRecords}
        fetchEndPoint={fetchEndPoint}
        columnsAction={updateColumns}
        clearAllFilters={clearAllFilters}
        defaultColumns={defaultColumns}
      />
      <div id="scrollIntoView" ref={setRef} />
      <EmeaFilterBox
        tableName={tableName}
        type={type}
        filterBody={filterBody}
        clearAllFilters={clearAllFilters}
        columns={columnsOrder}
      />
      <Snack {...snack} handleClose={() => setSnack({ open: false })} />
      <Grid className={tableClasses.table} data-testid="emeaTable">
        <MaterialTable
          isLoading={loading}
          key={items?.length}
          localization={{
            body: {
              emptyDataSourceMessage: <div className={classes.noData}>
                {
                  loading ?
                    t("please_wait_while_the_data_is_loading") :
                    t("no_records_to_display")
                }
              </div>
            }
          }}
          tableRef={tableRef}
          components={{
            Container: props_ => <Paper {...props_} elevation={0} />,
            OverlayLoading: props_ => <LinearProgress />,
            Pagination: props_ => (<TablePagination
              showFirstButton
              showLastButton
              rowsPerPageOptions={[15, 30, 50]}
              component="div"
              count={totalRecords || 0}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              classes={classes}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t("rows_per_page")}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count} ${t('records')}`}
              SelectProps={{ 'data-testid': 'rowsPerPageSelect', SelectDisplayProps: { 'data-testid': "rowsOption" } }}
              data-testid="pagination"
            />)
          }}
          columns={cloneDeep([fixedColumn].concat(columnsOrder))}
          data={items}
          options={{ ...lightTableOptions, selection: false, pageSize: rowsPerPage }}
        />
      </Grid>
    </Grid>
  );

}

export default EmeaTable;