import config from "configs";
import { version } from "../../lib/version";

const fetchService = async (url, options, timeout = 30000) => {

    try {
        const authService = global.authService;

        let token = await authService.getIdToken();
        let authState = await authService.getAuthState();

        if (!authState?.isAuthenticated || !token) {
            await authService.getTokenManager().renew("idToken")
            authState = await authService.getAuthState();
            const tokenObject = await authService.getTokenManager().get('idToken');
            token = tokenObject?.idToken;
            if (!token || !authState?.isAuthenticated) {
                authService.logout();
            }
        }

        const tokenData = `Bearer ${token}`;
        const tabId = sessionStorage.getItem("Tab-Id");
        const deviceType = sessionStorage.getItem("Device-Type");
        const { api_base_path } = config;
        let optionsModified = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': tokenData,
                // "accessKey": "abcd",
                // "userName": "",
                // "roles": "",
                "X-Tab-Id": tabId,
                "X-Device-Type": deviceType,
                "X-App-Version": version,
                // Below code to be uncommented when implementing language change
                "Accept-Language": ['en', 'fr', 'de', 'es', 'pt'].includes(localStorage.getItem('i18nextLng'))
                    ? localStorage.getItem('i18nextLng')
                    : 'en',
            }
        }
        if (options) {
            optionsModified = { ...options, ...optionsModified };
            if (options.body) {
                let body = options.body;
                if (body?.businessUnits && body?.businessUnits?.length === 0) {
                    body = {
                        ...body,
                        businessUnits: ["CONSUMER", "PROFESSIONAL"]
                    }
                }
                let finalBody = JSON.stringify({
                    ...body
                });

                if (Array.isArray(body)) {
                    finalBody = JSON.stringify(body)
                }
                

                optionsModified = {
                    ...optionsModified, 
                    body: finalBody
                };
            }
        }

        return Promise.race([
            fetch(`${api_base_path}${url}`, optionsModified),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('timeout')), timeout)
            )
        ]);
    } catch (e) {
        window.location.reload();
        return new Promise((_, reject) => reject(new Error('Unable to Process Request')))
    }
}

export default fetchService;
