import { getLocationsConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";
import {salesOrgsbyBU, distributionChannel} from "../../configs/appConstants"


export const getLocationsService = () => dispatch => {
    dispatch({
        type: getLocationsConstants.LOADING,
        payload: true
    });

    return fetch(endpoints.globalFilters.locations, {})
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: getLocationsConstants.LOCATION_FETCH_SUCCESS,
                payload: d
            });
            dispatch({
                type: getLocationsConstants.LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getLocationsConstants.LOADING,
                payload: false
            });
            dispatch({
                type: getLocationsConstants.LOCATION_FETCH_FAILED,
                payload: err
            });
        })
}

export const getPerformanceMapService = variables => dispatch => {
    dispatch({
        type: getLocationsConstants.PEFRORMANCE_LOADING,
        payload: true
    });

    return fetch(endpoints.globalFilters.sitePerformance, {
        method: "POST",
        body: variables,
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: getLocationsConstants.PERFORMANCE_FETCH_SUCCESS,
                payload: d
            });
            dispatch({
                type: getLocationsConstants.PEFRORMANCE_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getLocationsConstants.PEFRORMANCE_LOADING,
                payload: false
            });
            dispatch({
                type: getLocationsConstants.PERFORMANCE_FETCH_FAILED,
                payload: []
            });
        })
}

export const getCustomersService = variables => dispatch => {
    
    dispatch({
        type: getLocationsConstants.LOADING,
        payload: true
    });

    return fetch(endpoints.globalFilters.customerSearch, {
        method: "POST",
        body: variables,
    })
        .then(d => d.json())
        .then(d => {

            dispatch({
                type: getLocationsConstants.LOADING,
                payload: false
            })
            dispatch({
                type: getLocationsConstants.CUSTOMER_FETCH_SUCCESS,
                payload: d
            });
        })
        .catch(err => {
            dispatch({
                type: getLocationsConstants.LOADING,
                payload: false
            });
            dispatch({
                type: getLocationsConstants.CUSTOMER_FETCH_FAILED,
                payload: []
            });
        })
}

export const getSalesOfficeService = () => dispatch => {

    //Fetching All sales offices on page load

    const body = {
        salesOrg: salesOrgsbyBU.reduce((allSalesOrgs, {salesOrgs}) => [...allSalesOrgs, ...salesOrgs.map(({value}) => value)],[]),
        distributionChannel:distributionChannel.map(({value}) => value),
    }
    dispatch({
        type: getLocationsConstants.PEFRORMANCE_LOADING,
        payload: true
    });

    return fetch(endpoints.globalFilters.saleOfficeSearch, {
        method: "POST",
        body
    })
        .then(d => d.json())
        .then(d => {
            dispatch({
                type: getLocationsConstants.SALESOFC_FETCH_SUCCESS,
                payload: d
            });
            dispatch({
                type: getLocationsConstants.PEFRORMANCE_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: getLocationsConstants.PEFRORMANCE_LOADING,
                payload: false
            });
            dispatch({
                type: getLocationsConstants.SALESOFC_FETCH_FAILED,
                payload: []
            });
        })
}

