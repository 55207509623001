const { REACT_APP_ENV, REACT_APP_API_BASE_PATH, REACT_APP_GMAPS_KEY, REACT_APP_OKTA_CLIENT_ID, REACT_APP_OKTA_ISSUER, REACT_APP_CONFIG } = process.env;

const config = {
    env: REACT_APP_ENV || "local",
    api_base_path: REACT_APP_API_BASE_PATH ,
    app_config_string: REACT_APP_CONFIG, 
    maps: {
        key: REACT_APP_GMAPS_KEY || null
    },
    okta: {
        CLIENT_ID: REACT_APP_OKTA_CLIENT_ID,
        ISSUER: REACT_APP_OKTA_ISSUER
    }
};


export default config;