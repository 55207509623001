import React, { useEffect } from 'react';
import { makeStyles, Box, AppBar, Tabs, Tab, Toolbar, LinearProgress } from '@material-ui/core';
import { generatePath, Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useThemeContext } from 'context/ThemeContext';
import { useTranslation } from "react-i18next";
import OsmGlobalFilters from 'components/osm/OsmGlobalFilters';
import OsmKpiCharts from 'components/osm/OsmKpiCharts';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TabPanel from 'components/header/TabPanel';
import OsmMainTable from "components/osm/OsmMainTable";
import { defaultOSMColumns } from "reduxLib/constdata/osmColumns";
import { endpoints, findActive } from "helpers";
import { getOSMViews, getOSMView, resetViewStatus, activateOSMView } from "reduxLib/services/osmService"
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useDeepCompareEffect } from 'react-use';
import OsmManageViews from "components/osm/OsmManageViews";
import Snack from 'components/common/Helpers/Snack';
import { osmAuthorizedSitesService } from 'reduxLib/services';
import OSMBreadCrumb from 'components/common/Elements/OSMBreadCrumb';
import LoadingPage from "../common/LoadingPage";
import ErrorHandlerPage from "../common/ErrorHandlerPage";
import qs from 'query-string';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(6.5)
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  },
  root: {
    flexGrow: 1,
    width: '100%',
    paddingTop: theme.spacing(3)
  },
  toolbar: {
    minHeight: "auto"
  },
  tabpanel: {
    backgroundColor: theme.palette?.primary?.main
  },
  viewsBar: {
    borderBottom: `0.5px solid ${theme.palette?.text?.primary}`,
    borderRadius: `${theme.spacing(0.75)}px ${theme.spacing(0.75)}px 0px 0px`,
    paddingTop: theme.spacing(1.5)
  },
  tabViews: {
    flexGrow: 1,
    "& .MuiTab-root": {
      margin: `${theme.spacing(0.5)}px 0px`,
      minHeight: theme.spacing(5.25),
      borderRadius: `${theme.spacing(1.25)}px ${theme.spacing(1.25)}px 0px 0px`
    },
    "& .MuiTabs-indicator": {
      borderBottom: `${theme.spacing(0.5)}px solid ${theme.palette?.link?.active}`
    },
    "& .MuiTab-root.Mui-selected": {
      backgroundColor: theme.palette?.secondary?.secondary
    },
    "& .MuiTab-wrapper": {
      flexDirection: 'row',
      color: theme.palette?.text?.primary
    },
    "& .MuiTab-labelIcon": {
      paddingTop: '0px'
    }
  },
  viewName: {
    textTransform: 'capitalize'
  }
})); 

const OSM = () => {
  const classes = useStyles();
  const { light, dark, setTheme } = useThemeContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { params: { osmviewid }, path, params } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const [activeTab, setActiveTab] = React.useState(null);
  const [hyperlinkClick, setHyperlinkClick] = React.useState(false);
  const [tabevent, setTabEvent] = React.useState(null);

  const [snack, setSnack] = React.useState({
    open: false,
    severity: "info",
    message: null
  });

  const tableRef = React.createRef();

  const { views, active, status, loading, deleted } = useSelector(({ osm }) => osm);
  const { osm_sites_loading, osm_sites_error } = useSelector(({ osmAuth }) => osmAuth);
  const { precheckLoading } = useSelector(({ osmReprint }) => osmReprint);
  const { ld_precheck_loading, send_to_wms_loading } = useSelector(({ loadDiagram }) => loadDiagram);

  const findVIew = () => {
    const active = views?.filter(d => !d.hidden && d.isFav)[0];
    return active?.id || views[0]?.id
  }

  useEffect(() => {
    if(active?.id && osmviewid && active?.id !== osmviewid) {
      dispatch(getOSMView(osmviewid));
    }
  }, [osmviewid, active?.id])

  useDeepCompareEffect(() => {
    setActiveTab(osmviewid);
    if (!osmviewid) {
      const updatedpath = generatePath(path, {
        ...params,
        osmviewid: queryParams.newtab && queryParams.newtab === osmviewid ? osmviewid: findVIew()
      });
      history.push({
        ...location,
        pathname: updatedpath
      });
    }
    const activeViews = views?.filter(d => !d.hidden).map(d => d.id);
    if (osmviewid && activeViews?.length > 0 && !activeViews.includes(osmviewid)) {
      const updatedpath = generatePath(path, {
        ...params,
        osmviewid: queryParams?.newtab === osmviewid ? osmviewid: findVIew()
      });
      history.push({
        ...location,
        pathname: updatedpath
      });
    }
    const hiddenids = views?.filter(d => d.hidden).map(d => d.id);
    let activeId = findActive({ views, active }, hiddenids, osmviewid);
    
    if (deleted.includes(activeId?.id)) {
      activeId = views[0];
    }

    if (activeId?.id !== active?.id && activeId?.id) {
      setActiveTab(activeId.id);
      const updatedpath = generatePath(path, {
        ...params,
        osmviewid: activeId.id || views[0]?.id
      });
      history.push({
        ...location,
        pathname: updatedpath
      });
    }
  }, [osmviewid, active, views, queryParams?.newtab]);

  React.useEffect(() => {
    if (tabevent) {
      history.replace({
        ...location,
        search: qs.stringify({
          ...queryParams,
          activity: "tab",
          osmfilters: "null",
          page: 0,
          pageSize: 50
        }, { encode: false })
      });
    }
  }, [tabevent])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setTabEvent(newValue);
  };

  const handleSnackClose = () => {
    setSnack({
      ...snack,
      open: false
    });
  };

  React.useEffect(() => {
    if (status.status && status.message) {
      setSnack({
        ...snack,
        open: true,
        message: status.message,
        severity: status.status
      })
    }
    dispatch(resetViewStatus())
  }, [status.status, status.message]);

  React.useEffect(() => {
    setTheme(light);
    dispatch(osmAuthorizedSitesService());
    dispatch(getOSMViews());
    return () => {
      setTheme(dark);
      dispatch(activateOSMView({}));
    }
  }, []);

  return (
    <Box className={classes.main} data-testid="osmView">
      { !osm_sites_loading && !isEmpty(views) && activeTab ? 
        (!osm_sites_loading && osm_sites_error) ?
          <ErrorHandlerPage errTagline={ osm_sites_error === "osm_restricted" ? t("we_are_sorry") : null } errMessage={ t(osm_sites_error, { shipmentNum: null }) } />
          :
          <>
            <OsmGlobalFilters />
            <OSMBreadCrumb />
            <OsmKpiCharts type="osm" setHyperlinkClick={setHyperlinkClick} />
            <div className={classes.root} data-testid='osmTabs'>
              {(loading || precheckLoading || ld_precheck_loading || send_to_wms_loading) && <LinearProgress className={classes.progress} /> }
              <AppBar position="static" color="primary" elevation={2} className={classes.viewsBar}>
                <Toolbar className={classes.toolbar}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    className={classes.tabViews}
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="osm tabs"
                  >
                    { 
                      cloneDeep(views)?.map((item) => {
                        if (item.hidden) return null;
                        return (
                          <Tab
                            component={Link}
                            data-testid={`tabid-${item.id}`}
                            to={`${item.id}${location.search}`}
                            value={item.id}
                            key={item.id}
                            label={item.name}
                            icon={item.public ? <PeopleAltIcon /> : null}
                            className={classes.viewName}
                          />
                        )
                      })
                    }
                  </Tabs>
                  <OsmManageViews views={views} />
                </Toolbar>
              </AppBar>
              {
                views?.map((item) => {
                  return (
                    <TabPanel value={activeTab} index={item.id} key={item.id} className={classes.tabpanel}>
                      <Box p={3} boxShadow={2} borderRadius="6px">
                        <OsmMainTable
                          tableName="osmShipments"
                          tableRef={tableRef}
                          columns={defaultOSMColumns}
                          fetchEndPoint={endpoints.table.osm}
                          hyperlinkClick={hyperlinkClick} 
                          setHyperlinkClick={setHyperlinkClick}
                        />
                      </Box>
                    </TabPanel>
                  )
                })
              }
            </div>
            <Snack {...snack} handleClose={handleSnackClose} />
          </>
        :
        <LoadingPage />
      }
    </Box>
  );
};

export default OSM;
