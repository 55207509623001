import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { get } from "lodash";
import { filterStyles } from "theme";
import { FormControlLabel, Typography } from '@material-ui/core';
import clsx from "clsx";
import { saveFilters } from "reduxLib/services";
import TextField from '@material-ui/core/TextField';
// import FilterComponentContainer from "./Filters/FilterComponentContainer";
import KCCheckBox from "components/common/Elements/KCCheckbox";
import { stringToArray } from 'helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(filterStyles);

const CssTextField = withStyles(theme => ({
    root: {
        width: '100%',
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        }
    },
}))(TextField);

const TextCheckBoxFilterElement = (props) => {

    const { title, filters, filterKey, saveFilters, type, subtype, placeholder, filter: filterBase, customSaveFilters } = props;

    const filterKey2 = 'creditOnHold';
    const { t } = useTranslation();
    const filter = get(filters, `${filterKey}.data`, null);
    const filter2 = get(filters, `${filterKey2}.data`, false);

    const filter2Details = get(filters, `${filterKey2}`, {});

    const [input, setInput] = React.useState(filter);
    const [input2, setInput2] = React.useState(filter2);

    const classes = useStyles();


    const submitChange = (e) => {
        e.preventDefault();
        const value = input?.trim()
        setInput(filterBase?.stringToArray ? stringToArray(value): value)
        const savePayload = {
            filter: {
                [filterKey]: {
                    ...filters[filterKey],
                    data: filterBase?.stringToArray ? stringToArray(value): value || null
                }
            }, type, subtype
        };
        if (typeof customSaveFilters === "function") {
            customSaveFilters(savePayload);
            return;
        }
        saveFilters(savePayload);
    }

    const addonChange = (value) => {
        const savePayload = {
            filter: {
                [filterKey2]: {
                    ...filters[filterKey2],
                    data: value || null
                }
            }, type, subtype
        };
        if (typeof customSaveFilters === "function") {
            customSaveFilters(savePayload);
            return;
        }
        saveFilters(savePayload);
    }

    React.useEffect(() => {
        setInput(filter);
        setInput2(filter2);
    }, [filter, filter2]);

    return (
        // <FilterComponentContainer testId="textCheckFilterElement" {...props}>
            <form onSubmit={submitChange} data-testid="textCheckFilterElement" >
                <CssTextField
                    value={input || ""}
                    name="textcheckfilter"
                    inputProps={{ "data-testid": "textcheckfilter" }}
                    onChange={e => setInput(e.target.value)}
                    label={placeholder || `Enter ${title.toLowerCase()}`}
                    variant="outlined"
                />
                <FormControlLabel
                    onChange={(e) => {
                        setInput2(e.target.checked)
                        if (e.target.checked) {
                            addonChange("Y");
                        } else {
                            addonChange(null);
                        }
                    }
                    }
                    control={<KCCheckBox name="checkboxtest" color="primary" checked={input2 || false} />}
                    label={<Typography color="primary" className={clsx(classes.button, classes.contentText)}>{t(filter2Details.name)}</Typography>}
                />
            </form>
        // </FilterComponentContainer>
    )
}

const mapStatetoProps = (state, ownProps) => {
    const { subtype } = ownProps;
    return {
        filters: get(state, `options.filters.${subtype}`, {})
    }
};

const mapDispatchToProps = {
    saveFilters
};

export default connect(mapStatetoProps, mapDispatchToProps)(TextCheckBoxFilterElement);
