import React, { useMemo, useState } from 'react';
import ResponsiveWrapper from 'components/charts/ResponsiveWrapper';
import { treemap, hierarchy, scaleOrdinal } from 'd3';
import { useDeepCompareEffect } from 'react-use';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { formatNumber } from 'helpers/emeaHelpers';
import D3Tooltip from "components/D3Charts/D3Tooltip";

const useStyles = makeStyles((theme) => ({
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      letterSpacing: '1px',
      padding: theme.spacing(.4),
      '&:hover': {
        color: theme.palette.link.hover
      }
    }
  }));

const TreeMap = ({ xKey, yKey, data, loading, setDetails, ...props }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles();

    const margin = 10;
    const margins = { top: margin, right: margin, bottom: margin, left: margin };

    const width = props.parentWidth - margin * 1.8;
    const height = props.height + margin * 1.5;

    const [leaves, setLeaves] = useState([]);

    useDeepCompareEffect(() => {
        if (data.length === 0) {
            setLeaves([]);
            return;
        }

        const sortedData = data.sort((a, b) => b[yKey] - a[yKey]);
        const keys = sortedData.map(d => d[xKey]);
        const colors = scaleOrdinal().domain(keys).range(theme.palette.treemapColors);

        const children = sortedData.map(d => ({
            ...d,
            fill: colors(d[xKey]),
        }));

        const tree = treemap().size([width, height]).padding(2);
        const root = hierarchy({ children }).sum(d => d[yKey]);
        tree(root);
        setLeaves(root.leaves());
    }, [data, xKey, yKey, width, height, theme.palette.treemapColors]);

    const isLink = useMemo(() => typeof (setDetails) === "function", [setDetails]);

    return (
        <div data-testid='treemap' >
            <svg
                height={height + margins.top + margins.bottom}
                width={width + margins.left + margins.right}
                transform={`translate(${margins.left},${margins.top})`}
            >
                <g>
                    {loading ? (
                        <text
                            x={width / 2}
                            y={height / 2}
                            data-testid="loading"
                            textAnchor="middle"
                        >
                            {t('loading')}
                        </text>
                    ) : leaves.length === 0 ? (
                        <text
                            x={width / 2}
                            y={height / 2}
                            textAnchor="middle"
                        >
                            {t('no_data_found')}
                        </text>
                    ) : (
                        leaves.map((d, i) => (
                            <g key={i}>
                                <D3Tooltip
                                    placement={"top"}
                                    interactive
                                    color={d.data.fill}
                                    themeType="light"
                                    title={
                                        <div
                                            data-testid="tooltip-test-id"
                                            className={clsx(isLink ? classes.link : {})}
                                            onClick={() => {
                                                if (typeof (setDetails) === "function") {
                                                    setDetails(d.data[xKey])
                                                }
                                            }}>
                                            {d.data[xKey]} : {formatNumber(d.data[yKey])}
                                        </div>
                                    }
                                >
                                    <rect
                                        data-testid="tree-rect"
                                        x={d.x0}
                                        y={d.y0}
                                        rx={5}
                                        width={d.x1 - d.x0}
                                        height={d.y1 - d.y0}
                                        fill={d.data.fill}
                                    />
                                </D3Tooltip>
                                <text
                                    x={d.x0 + 5}
                                    y={d.y0 + 15}
                                    fontSize={12}
                                    fill="white"
                                    cursor="pointer"
                                    textDecoration="underline"
                                    data-testid="clickable-text"
                                    onClick={() => setDetails(d.data[xKey])}
                                >
                                    {d.data[xKey]}
                                </text>
                                <text
                                    x={d.x0 + 5}
                                    y={d.y1 - 10}
                                    fontSize={12}
                                    fill="white"
                                >
                                    {formatNumber(d.data[yKey])}
                                </text>
                            </g>
                        ))
                    )}
                </g>
            </svg>
        </div>
    );
};

export default ResponsiveWrapper(TreeMap);
