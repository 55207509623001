import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { scaleBand, scaleLinear, scaleOrdinal, max, sum } from 'd3'
import { laoChartColorPanel } from 'helpers/styleHelpers';
import ResponsiveWrapper from 'components/charts/ResponsiveWrapper';
import Axes from 'components/D3Charts/Axes';
import Bars from 'components/D3Charts/Bars';

const ExportVolumeChart = (props) => {
    const { t } = useTranslation();
    const {
        xKey, yKey, height, loading,
        themeType, yLabel,
        legend, showXAxisLabel, displayKey, setDetails, ...rest
    } = props;
    const [data, setData] = useState([]);
    const [totalSum, setTotalSum] = useState(() => data.reduce((a, b) => a + b[yKey], 0));
    const keys = [yKey];

    const colorPanel = laoChartColorPanel;

    useEffect(() => {
        setData(rest.data.map(d => ({ ...d, total: d[yKey] })))
        setTotalSum(rest.data.reduce((a, b) => a + b[yKey], 0))
    }, [rest.data]);

    const elementMargins = { top: 40, left: 80, right: 30, bottom: 30 };

    const svgBoundaries = {
        width: rest.parentWidth,
        height: height
    }

    let xScale = scaleBand()
        .padding(0.4)
        .paddingInner(0.3)
        .domain(data.map(d => d[xKey]))
        .range([elementMargins.left, svgBoundaries.width - elementMargins.right])

    let yScale = scaleLinear()
        .domain([0, max(data, d => sum(keys, k => +d[k]))])
        .nice()
        .range([svgBoundaries.height - elementMargins.bottom, elementMargins.top]);

    const xAccess = d => d[xKey];
    const centerPos = [svgBoundaries.width / 2, svgBoundaries.height / 2];

    const colors = scaleOrdinal().domain(keys).range(colorPanel);

    const NoDataText = () => {
        const text = loading ? "loading" : "no_data_found"
        return (
            <g transform={`translate(${centerPos})`} data-testid={"no-data-found"} >
                <text textAnchor="middle" data-testid="noData"
                    fill={themeType === "dark" ? "white" : "black"}>
                    {t(text)}
                </text>
            </g>
        )
    }

    return (
        <div data-testid='barChart'
            style={{
                minHeight: height + elementMargins.bottom,
                maxWidth: svgBoundaries.width + elementMargins.left
            }}>
            <svg width={svgBoundaries.width} height={svgBoundaries.height}>
                {

                    (!totalSum || loading) ? <NoDataText /> :
                        <g>
                            <Axes
                                wrapit={true}
                                scales={{ xScale, yScale }}
                                margins={elementMargins}
                                svgDimensions={svgBoundaries}
                                yLabel={yLabel}
                                showXAxisLabel={showXAxisLabel}
                            />
                            <Bars
                                scales={{ xScale, yScale }}
                                margins={elementMargins}
                                xKey={xKey}
                                data={data}
                                xAccessor={xAccess}
                                colors={colors}
                                keys={[yKey]}
                                themeType={themeType}
                                hideTopText={true}
                                setDetails={setDetails}
                                setDetailsData={true}
                            />
                        </g>}
            </svg>
        </div>
    )

}

export default ResponsiveWrapper(ExportVolumeChart)