import React from 'react'
import {
    Grid, Typography, makeStyles,
    Divider, TextField, Chip,
    FormControl, Button, FormGroup, LinearProgress, Snackbar
} from "@material-ui/core"
import { useSelector, useDispatch } from 'react-redux';
import clsx from "clsx";
import BootstrapInput from 'components/common/Elements/BootstrapInput';
import Criteria from "components/selfalerting/Criteria";
import SelfAlertDropMenu from "components/selfalerting/SelfAlertDropMenu";
import SelfAlertingFilters from "components/selfalerting/SelfAlertingFilters";
import {
    addItemsCusMultipleSF,
    loadSelfAlertingMetadata, saveAlertService, saveSelfAlertFilters,
    resetSavedState,
    resetSelfAlertState,
    removeItemCusoSF,
    loadAllAlerts
} from 'reduxLib/services/selfAlertingService';
import qs from 'query-string';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { cloneDeep, isEmpty } from 'lodash';
import TimeMulti from 'components/common/Elements/TimeMulti';
import EmailMulti from 'components/common/Elements/EmailMulti';
import FilterChips from 'components/common/FIlterChips';
import { getSalesOfficeService } from "reduxLib/services";
import SFSelect from 'components/selfalerting/SFSelect';
import {
    getFilterArgs, generateSelfAlertData,
    createHyperLinkFilterObject2,
    valiadateForm, reprocessSlefAlertFilters
} from 'helpers';
import { useLocation, useRouteMatch, generatePath, useHistory } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import selfalertingFilters from 'reduxLib/constdata/selfalertingFilters';
import SFBreadCrumb from 'components/selfalerting/SFBreadCrumb';
import MuiAlert from '@material-ui/lab/Alert';
import { Helmet } from 'react-helmet';
import { selfAlertingStyles } from 'theme';
import CustomersChipComponent from "components/header/CustomersChipComponent"
import CloseIcon from '@material-ui/icons/CancelOutlined';
import SelfAlertPreview from 'components/selfalerting/SeflAlertPreview';
import { conditionalFilterReplacement } from './Home';
import SelfAlertTable from 'components/selfalerting/SelfAlertTable';
import { useRouteContext } from 'context/RouteContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(selfAlertingStyles);

const ChipsHTML = ({ chips, updateChips }) => {
    const classes = useStyles();

    return <span>
        {
            chips?.map((option) => {
                const label = option?.title || option;
                return (
                    <Chip
                        key={label}
                        label={label}
                        className={classes.chip}
                        variant="outlined"
                        deleteIcon={<CloseIcon data-testid="deleteicon" />}
                        onDelete={() => {
                            const updatedvals = chips.filter((entry) => entry !== option);
                            updateChips(updatedvals)
                        }}
                    />
                );
            })
        }
    </span>
}


export const SelfAlerting = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { alerts, savedAlertId, loading, filters, salesOffice, cuso, alertsloading, alertstatus, metadata } = useSelector(({
        sites:{
            salesOffice
        },
        selfalerting: {
            alerts,
            savedAlertId,
            loading,
            alertsloading,
            filters,
            cuso,
            alertstatus,
            metadata
        }
    }) =>
        ({ alerts, loading, alertsloading, salesOffice, filters, cuso, alertstatus, savedAlertId, metadata })
    );

    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const queryParams = qs.parse(location.search);
    const { mainPath } = useRouteContext();

    const { alertid: queryAlertId } = match.params;

    const [preview, setPreview] = React.useState(false);
    const [evaluateObj, setEvaluateObj] = React.useState({
        evaluate: false,
        body: {}
    });
    const [formdata, setFormData] = React.useState({
        alertName: `Alert ${alerts?.length + 1}`,
        isEditableByCurrentUser: true,
        freqOfAlerts: {
            freqType: "EVERY_15_MINS",
            customTimeZone: "US/Eastern",
            customTimestamps: []
        },
        severity: "HIGH",
        actions: [
            {
                actionType: "EMAIL",
                mailingList: []
            }
        ],
    });

    useDeepCompareEffect(() => {
        if (queryAlertId === "new") {
            updateFormData({
                alertName: `Alert ${alerts?.length + 1}`
            })
        }
        if (queryAlertId === "new" || alertsloading) return;
        const found = alerts?.filter(d => d.alertId === queryAlertId)[0];

        const filterKeys = Object.keys(filters) || [];

        const modifiedFilters = createHyperLinkFilterObject2({
            pageDetails: {
                filterParams: {
                    args: reprocessSlefAlertFilters(found?.targetEndpoint?.restJsonObjectBody || {})
                }
            },
            filterBody: selfalertingFilters,
            filterKeys
        });
        dispatch(saveSelfAlertFilters({
            filter: modifiedFilters?.fullObject || {}
        }));
        setCriterias(found?.targetEndpoint?.restJsonObjectBody?.dateComparisons);
        const customerOrSalesOffice = found?.targetEndpoint?.restJsonObjectBody?.customerOrSalesOffice;
        const CUSOpayload = !isEmpty(customerOrSalesOffice) ? customerOrSalesOffice?.reduce((acc, item) => item.selectionType === "CUST" ?
            { ...acc, CUST: [...acc.CUST, item] } : { ...acc, SALES_OFFICE: [...acc.SALES_OFFICE, item] }, { CUST: [], SALES_OFFICE: [] }) : { CUST: [], SALES_OFFICE: [] }

        dispatch(addItemsCusMultipleSF(CUSOpayload));
        setFormData({
            ...found,
            freqOfAlerts: {
                ...found?.freqOfAlerts,
                customTimeZone: found?.freqOfAlerts?.customTimeZone || "US/Eastern",
            }
        });
    }, [queryAlertId, alertsloading]);


    const defaultCriteria = {
        field1: "",
        operation: "",
        field2: "",
        timeUnitsToAdd: 0,
        timeUnitType: "Day"
    };

    const [criterias, setCriterias] = React.useState([defaultCriteria]);

    React.useEffect(() => {
        if(salesOffice?.length === 0) {
            dispatch(getSalesOfficeService());
        }
    }, [])

    React.useEffect(() => {
        if (isEmpty(metadata)) {
            dispatch(loadSelfAlertingMetadata());
        }
    }, [metadata])

    const updateFormData = (formelement) => {
        setFormData({
            ...formdata,
            ...formelement
        })
    }

    const updatedTime = (timedata) => {
        const mailform = {
            ...formdata,
            freqOfAlerts: {
                ...formdata.freqOfAlerts,
                customTimestamps: timedata
            }
        }
        updateFormData(cloneDeep(mailform));
    }

    const updatedMails = (emails) => {
        const mailform = {
            ...formdata,
            actions: [{
                ...formdata.actions[0],
                mailingList: emails
            }]
        }
        updateFormData(cloneDeep(mailform));
    }


    const deleteCriteria = (index) => {
        const newcriterias = criterias.filter((_, i) => i !== index)
        setCriterias(cloneDeep(newcriterias))
    }

    const addNewCriteria = () => {
        const newcriterias = [
            ...(criterias || []),
            defaultCriteria
        ];
        setCriterias(cloneDeep(newcriterias))
    }

    const updateCriteria = (criteria, index) => {
        const newcriterias = criterias;
        newcriterias[index] = criteria;
        setCriterias(cloneDeep(newcriterias))
    }

    const previewAlert = () => {
        setPreview(true)
    }

    const customers = React.useMemo(() => {
        return [
            ...cuso?.CUST,
            ...cuso?.SALES_OFFICE
        ];
    }, [cuso])

    const saveAlert = (evaluateTable=false) => {
        const allAlertIds = alerts.map(d => d.alertId);
        let filterargs = conditionalFilterReplacement(getFilterArgs(filters));
        filterargs = {
            ...filterargs,
            promotionalOrder: filterargs?.promotionalOrder?.[0] || null
        }
        
        let customerOrSalesOffice = customers;
        customerOrSalesOffice = customerOrSalesOffice.map(d => ({
            ...d,
            customUniqObjectId: undefined,
            custom: undefined
        }));
        const finalObject = generateSelfAlertData({
            formdata: {
                ...formdata,
                alertId: !allAlertIds.includes(formdata?.alertId) ? undefined : formdata?.alertId
            },
            filterargs,
            criterias,
            customerOrSalesOffice
        });
        if (evaluateTable === true) {
            setEvaluateObj({
                evaluate: true,
                body: finalObject
            });
        } else {
            dispatch(saveAlertService(finalObject))
        }
    }

    const setEvaluateParam = () => {
        const newQueries = {
            evaluate: true,
        };
        if (queryParams?.evaluate === "true") {
            evaluteAlert();
        } else {
            history.replace({
                ...location,
                search: qs.stringify(newQueries)
            }, { encode: true });
            evaluteAlert();
        }
    }

    const evaluteAlert = () => {
        saveAlert(true);
    }

    React.useEffect(() => {
        if (queryParams?.evaluate === "true" && alerts?.length > 0 && formdata?.alertId) {
            evaluteAlert();
        }
    }, [queryParams?.evaluate, alerts, formdata])

    const [firstrender, setFirstRender] = React.useState(false);
    const [snack, setSnack] = React.useState({
        open: false,
        severity: "success",
        message: null
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };

    React.useEffect(() => {
        setFirstRender(true);
        if (!firstrender) return;
        if (alertstatus) {
            setSnack({
                open: true,
                severity: alertstatus,
                message: alertstatus === "success" ? "Alert Saved Successfully" : "Please Validate Alert & Try Again!"
            })
            dispatch(resetSavedState());
        }
    }, [alertstatus, savedAlertId]);

    React.useEffect(() => {
        return () => {
            dispatch(resetSelfAlertState())
        };
    }, []);

    const alertsHomePath = React.useMemo(() => {
        return `${generatePath(mainPath, {
            ...match.params
        })}/alerts`;
    }, [match.url]);

    useDeepCompareEffect(() => {
        if (alerts?.length === 0 && alertsloading) {
            dispatch(loadAllAlerts());
        }
        if(!alertsloading && queryAlertId !== "new") {
            const validId = alerts?.map(d => d.alertId)?.includes(queryAlertId);
            if(!validId) {
                history.push(alertsHomePath);
            }
        }
    }, [alerts, alertsloading]);

    const enableSubmit = React.useMemo(() => {
        return valiadateForm(formdata, criterias)
    }, [formdata, criterias]);

    const dataloading = !loading && !alertsloading;

    return (
        <>
        <form onSubmit={(e) => e.preventDefault()} data-testid="selfalerting">
            <Helmet>
                <title>{`CT - ${formdata.alertId ? "Update" : "Create"} Alert - ${formdata.alertName}`}</title>
            </Helmet>
            <SelfAlertPreview open={preview} formdata={formdata} criterias={criterias} setOpen={setPreview} saveAlert={saveAlert} customers={customers} />
            <Grid className={classes.component}>
                <Snackbar
                    open={snack.open}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleClose}>
                    <Alert onClose={handleClose} severity={snack.severity}>
                        {snack.message}
                    </Alert>
                </Snackbar>
                <SFBreadCrumb title={formdata.alertId ? formdata.alertName : 'New Alert'} />
                <Typography component={"h1"} className={clsx(classes.title, classes.margintop)}>
                    {formdata.alertId ? "update" : "create new"} alert
                </Typography>
                {
                    !dataloading ? <LinearProgress className={clsx(classes.linearprogress, classes.divider)} /> : <Divider className={classes.divider} />
                }
                {
                    dataloading && <>
                        <div className={classes.grid}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.formelement}
                            >
                                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                                    <Typography className={classes.uppercase}>Enter alert name *</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                                    <TextField
                                        required
                                        className={classes.text}
                                        size="small"
                                        inputProps={{
                                            "data-testid": "textfield-freq"
                                        }}
                                        label="Enter Alert Name"
                                        variant="outlined"
                                        value={formdata.alertName}
                                        onChange={({ target: { value } }) => updateFormData({
                                            alertName: value
                                        })}
                                    />
                                </Grid>
                            </Grid>
                            {/* Frequesncy of Alert */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.formelement}
                            >
                                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                                    <Typography className={classes.uppercase}>Frequency of alert *</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                                    <FormGroup row>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <SFSelect
                                                props={{
                                                    size: "small",
                                                    variant: "outlined",
                                                    select: true,
                                                    value: formdata.freqOfAlerts.freqType,
                                                    onChange: ({ target: { value } }) => updateFormData({
                                                        freqOfAlerts: {
                                                            ...formdata.freqOfAlerts,
                                                            freqType: value,
                                                        }
                                                    })
                                                }}
                                                menu={[
                                                    { name: "Every 15 mins", value: "EVERY_15_MINS" },
                                                    { name: "Every 30 mins", value: "EVERY_30_MINS" },
                                                    { name: "Every 1 hour", value: "EVERY_60_MINS" },
                                                    { name: "Custom", value: "CUSTOM" },
                                                ]}
                                            />
                                        </FormControl>
                                        {
                                            formdata.freqOfAlerts.freqType === "CUSTOM" &&
                                            <>
                                                <FormControl variant="outlined" className={classes.marginleft}>
                                                    <TimeMulti updatedTime={updatedTime} timedata={formdata?.freqOfAlerts?.customTimestamps} />
                                                    <Typography variant='caption'>You can only select 00 or 30 in minutes</Typography>
                                                </FormControl>
                                                <FormControl variant="outlined" className={classes.marginleft}>
                                                    <SFSelect
                                                        props={{
                                                            size: "small",
                                                            variant: "outlined",
                                                            select: true,
                                                            value: formdata.freqOfAlerts.customTimeZone,
                                                            onChange: ({ target: { value } }) => updateFormData({
                                                                freqOfAlerts: {
                                                                    ...formdata.freqOfAlerts,
                                                                    customTimeZone: value,
                                                                }
                                                            })
                                                        }}
                                                        menu={[
                                                            { name: "EST", value: "US/Eastern" },
                                                            { name: "CST", value: "US/Central" },
                                                            { name: "MST", value: "US/Mountain" },
                                                            { name: "PST", value: "US/Pacific" },
                                                        ]}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <ChipsHTML chips={formdata?.freqOfAlerts?.customTimestamps} updateChips={updatedTime} />
                                                </FormControl>
                                            </>
                                        }
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            {/* Severity of Alert * */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.formelement}
                            >
                                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                                    <Typography className={classes.uppercase}>Severity of Alert *</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <SFSelect
                                            props={{
                                                select: true,
                                                size: "small",
                                                variant: "outlined",
                                                value: formdata.severity,
                                                onChange: ({ target: { value } }) => updateFormData({
                                                    severity: value
                                                }),
                                                input: <BootstrapInput />
                                            }}
                                            menu={[
                                                { name: "Critical", value: "CRITICAL" },
                                                { name: "High", value: "HIGH" },
                                                { name: "Medium", value: "MEDIUM" },
                                                { name: "Low", value: "LOW" },
                                            ]}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* Send alerts to */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.formelement}
                            >
                                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                                    <Typography className={classes.uppercase}>Send alerts to *</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                                    <span>
                                        <EmailMulti updatedMails={updatedMails} emaildata={formdata?.actions?.[0]?.mailingList} />
                                        <FormControl>
                                            <ChipsHTML chips={formdata?.actions?.[0]?.mailingList} updateChips={updatedMails} />
                                        </FormControl>
                                    </span>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Typography component={"h1"} className={classes.uppercase}>SEND ALERTS WHEN</Typography>
                            {/* Select Filters */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.formelement}
                            >
                                <Grid item xs={12} md={12} sm={12} lg={2} xl={2}>
                                    <Typography className={classes.uppercase}>Select Filters</Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={10} xl={10}>
                                    <FilterChips selfalert={true}
                                        checkFilterChipsLength={() => { }}
                                    />
                                    <CustomersChipComponent action={removeItemCusoSF} chipsObjectArray={[...cuso.CUST, ...cuso.SALES_OFFICE]} />
                                    <SelfAlertDropMenu
                                        title={"Select A Filter"}
                                    >
                                        <SelfAlertingFilters />
                                    </SelfAlertDropMenu>
                                </Grid>
                            </Grid>
                            {
                                criterias?.map((data, index) => {
                                    return <Criteria
                                        formdata={data}
                                        updateCriteria={updateCriteria}
                                        index={index}
                                        deleteCriteria={deleteCriteria}
                                    />
                                })
                            }
                            <Button
                                onClick={addNewCriteria}
                                size="small"
                                startIcon={<ControlPointIcon />}
                                data-testid="selfalert-addcomp"
                                className={classes.uppercase}
                            >
                                Add {criterias?.length > 0 ? 'another' : ''} comparison
                            </Button>
                            <Divider />
                        </div>
                        <div className={classes.buttongroup}>
                            {
                                formdata?.isEditableByCurrentUser && <Button
                                    type="submit"
                                    onClick={previewAlert}
                                    data-testid="selfalert-submit-preview"
                                    variant="contained"
                                    className={classes.uppercase}
                                    disabled={enableSubmit}
                                >
                                    Save Alert
                                </Button>
                            }
                            <Button
                                type="submit"
                                onClick={setEvaluateParam}
                                data-testid="selfalert-submit-evaluate"
                                variant="contained"
                                className={classes.uppercase}
                            >
                                Evaluate
                            </Button>
                        </div>
                    </>}
            </Grid>
        </form>
        {
            evaluateObj.evaluate ? <SelfAlertTable body={evaluateObj.body} /> : null
        }
        </>
    )
}

export default SelfAlerting;
