import { checklistConstants } from '../constants';
import { unionWith, remove, uniqBy, isEqual, isEmpty, uniq } from 'lodash';
import { getTabsData, mergeSalesGroups } from "helpers";
import { viewByConstants } from "configs/appConstants";

const trimmedData = (items, payload) => {
    const itemsLocal = [...items]; 
    remove(itemsLocal, payload);
    return itemsLocal || [];
}

const removeSelected = (items, payload) => {
    const ids = payload.map(d => d.siteNum);

    return items.filter(d => !ids.includes(d.siteNum))
}

const removeDups = (data) => {
    return uniqBy(data, "siteNum");
}

const removeFavCuso = (favs, cusoObject) => {
    const favsUniqData = getTabsData(favs, viewByConstants.customer);
    let cusoUniqData = getTabsData([...cusoObject.CUST, ...cusoObject.SALES_OFFICE], viewByConstants.customer);

    const favidslist = favsUniqData.map(d => d?.custom?.id);

    const finalObject = {
        CUST: [],
        SALES_OFFICE: []
    };

    cusoUniqData.filter(d => {
        const isFav = !favidslist.includes(d?.custom?.id);
        if (isFav) {
            delete d.custom;
            delete d?.customUniqObjectId;
            finalObject[d.selectionType].push(d)
        }
        return isFav
    });
    return finalObject;
}

const joinFavstoCuso = (favs, cusoObject) => {
    let finalObject = {
        CUST: [],
        SALES_OFFICE: []
    };
    const favsUniqData = getTabsData(favs, viewByConstants.customer);
    let cusoUniqData = getTabsData([...cusoObject.CUST, ...cusoObject.SALES_OFFICE], viewByConstants.customer);

    let joinedobject = uniqBy([...favsUniqData, ...cusoUniqData], "custom.id")

    joinedobject.forEach(d => {
        finalObject[d.selectionType] && finalObject[d.selectionType].push(d);
        if (d.selectionType !== "SALES_OFFICE") {
            delete d?.custom;
            delete d?.customUniqObjectId;
        }
    });
    finalObject.SALES_OFFICE = mergeSalesGroups(finalObject.SALES_OFFICE);

    return finalObject;
}

export const getRemovedSG = ({ action, state }) => {
    var { shortName, reducerObject } = action.payload

    const filterSGArray = (SO) => {
        return SO.salesGroup?.filter((value) => value !== shortName)
    }

    const deleteSG = (SO) => {
        const { salesGroup, ...rest } = SO
        return rest
    }

    const removedSGArray = state.cuso.SALES_OFFICE.map((SO) =>
        SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice ?
            !isEmpty(filterSGArray(SO)) ? { ...SO, salesGroup: SO.salesGroup.filter((value) => value !== shortName) } : deleteSG(SO)
            : SO
    )
    return { ...state, cuso: { ...state.cuso, SALES_OFFICE: removedSGArray } }
}

const selectionsReducer = (state = { items: [], cuso: { CUST: [], SALES_OFFICE: [] }, stopfavload: true, blockOnTabChange: false }, action) => {
    switch (action.type) {

        case checklistConstants.SET_LOAD_FALSE:
            return {
                ...state,
                stopfavload: false
            }

        case checklistConstants.SET_ONLOAD_SELECTIONS:
            const { locations, cuso } = action.payload
            return { ...state, items: [...removeDups([...state.items, ...locations])], cuso, stopfavload: false }

        case checklistConstants.ADD_ITEM_SUCCESS:
            return { ...state, items: [...removeDups([...state.items, action.payload])] }

        case checklistConstants.UPDATE_LOCATIONS:
            return { ...state, items: action.payload }

        case checklistConstants.ADD_CUSO_MULTIPLE_SUCCESS:
            return { ...state, cuso: action.payload }

        case checklistConstants.ADD_ITEM_MULTIPLE_SUCCESS:
            return { ...state, items: [...removeDups([...state.items, ...action.payload])] }

        case checklistConstants.REMOVE_ITEM_SUCCESS:
            return { ...state, items: [...trimmedData(state.items, { siteNum: action.payload.siteNum || null })] };

        case checklistConstants.REMOVE_FAV_ITEMS:
            return { ...state, items: [...trimmedData(state.items, { isFav: true })] };


        case checklistConstants.REMOVE_FAV_CUSO:
            return { ...state, cuso: removeFavCuso(action.payload, state.cuso) };

        case checklistConstants.JOIN_FAVS_AND_SELECTIONS_CUSO:
            return { ...state, cuso: joinFavstoCuso(action.payload, state.cuso) };


        case checklistConstants.ADD_CUSO_SUCCESS:

            if (action.payload.selectionType === "CUST") {
                const spreadPayload = action.payload.salesOrg.map((value) => ({ ...action.payload, salesOrg: value }))
                return {
                    ...state, cuso: {
                        ...state.cuso,
                        CUST: unionWith(spreadPayload, state.cuso.CUST, isEqual),
                    }
                }

            } else if (action.payload.selectionType === "SALES_OFFICE") {

                const findSO = state.cuso.SALES_OFFICE.filter(({ salesOffice, salesOrg }) => action.payload.salesOffice === salesOffice && action.payload.salesOrg === salesOrg).length > 0
                return {
                    ...state, cuso: {
                        ...state.cuso,
                        SALES_OFFICE: findSO ? state.cuso.SALES_OFFICE.map((SO) =>
                            action.payload.salesOffice === SO.salesOffice && action.payload.salesOrg === SO.salesOrg ? { ...SO, distributionChannel: action.payload.distributionChannel } : SO
                        ) : [...state.cuso.SALES_OFFICE, action.payload],
                    }
                }
            } else {
                return state
            }

        case checklistConstants.REMOVE_CUSO_SUCCESS:

            if (action.payload.selectionType === "CUST") {
                const { distributionChannel, salesOrg, customerName } = action.payload
                return {
                    ...state,
                    cuso: {
                        ...state.cuso,
                        CUST: state.cuso.CUST.filter((object) => !(object.distributionChannel === distributionChannel && object.customerName === customerName && salesOrg.includes(object.salesOrg)))
                    }
                }
            } else if (action.payload.selectionType === "SALES_OFFICE") {
                var { distributionChannel, salesOrg, salesOffice } = action.payload
                return {
                    ...state,
                    cuso: {
                        ...state.cuso,
                        SALES_OFFICE: state.cuso.SALES_OFFICE.filter((object) => !(object.distributionChannel === distributionChannel && object.salesOffice === salesOffice && object.salesOrg === salesOrg)),
                    }
                }
            } else {
                return state
            }

        case checklistConstants.ADD_SG_SUCCESS:

            var { shortName, reducerObject } = action.payload

            const findSO = state.cuso.SALES_OFFICE.filter((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice)
            const updatedSO = state.cuso.SALES_OFFICE.map((SO) => SO.salesOrg === reducerObject.salesOrg && SO.salesOffice === reducerObject.salesOffice ?
                { ...SO, distributionChannel: reducerObject.distributionChannel, salesGroup: SO.salesGroup ? uniq([...SO?.salesGroup, shortName]) : [shortName] } : SO)

            return { ...state, cuso: { ...state.cuso, SALES_OFFICE: isEmpty(findSO) ? [...state.cuso.SALES_OFFICE, { ...reducerObject, salesGroup: [shortName] }] : updatedSO } }

        case checklistConstants.REMOVE_SG_SUCCESS:

            return getRemovedSG({state, action});

        case checklistConstants.CLEAR_CUSO:
            return { ...state, cuso: { CUST: [], SALES_OFFICE: [] } }

        case checklistConstants.CLEAR_ALL:
            return { ...state, items: [] }

        case checklistConstants.CHANGE_TAB:
            return { ...state, items: [...removeDups([...state.items, action.payload])], blockOnTabChange: true }
        
        case checklistConstants.SET_BLOCKONTABCHANGE:
            return { ...state, blockOnTabChange: false }

        case checklistConstants.CLEAR_SELECTED:
            return { ...state, items: [...removeSelected(state.items, action.payload)] }

        default:
            return state
    }
}

export default selectionsReducer;
