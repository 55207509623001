import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import GeoMap from "./GeoMap";
import dcHealthy from "assets/images/icons/dc-healthy.svg";
import dcMonitor from "assets/images/icons/dc-monitor.svg";
import dcBehind from "assets/images/icons/dc-behind.svg";
import dcCritical from "assets/images/icons/dc-critical.svg";
import dcNodata from "assets/images/icons/dc-nodata.svg";


import millHealthy from "assets/images/icons/mill-healthy.svg";
import millMonitor from "assets/images/icons/mill-monitor.svg";
import millBehind from "assets/images/icons/mill-behind.svg";
import millCritical from "assets/images/icons/mill-critical.svg";
import millNodata from "assets/images/icons/mill-nodata.svg";


const useStyles = makeStyles(theme => ({
    // root: {
    //     padding: theme.spacing(2),
    //     backgroundColor: theme.palette.custom.card_level2,
    // },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },

    pos: {
        marginBottom: 12,
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
        textAlign: 'left',
    },
    grid: {
        textAlign: 'center'

    }
}));


export const GeoCharts = ({data, setDetails, type, perfTableRedirection }) => {
    const classes = useStyles();

    const colorObj = {
        DC: {
            "healthy": dcHealthy,
            "monitor": dcMonitor,
            "behind": dcBehind,
            "critical": dcCritical,
            "no_data": dcNodata,
        },
        MILL: {
            "healthy": millHealthy,
            "monitor": millMonitor,
            "behind": millBehind,
            "critical": millCritical,
            "no_data": millNodata,
        },
    }
    
    return (
        <>
            <Grid data-testid='geoChart' item xs={12} className={classes.grid}>
                <div style={{ height: 550, width: "100%" }}>
                <GeoMap colorObj={colorObj} parentHeight={550} performanceData={data} setDetails={setDetails} perfTableRedirection={perfTableRedirection} type={type} />
                </div>
            </Grid>
        </>
    );
}




export default GeoCharts;