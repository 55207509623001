import { emeaSalesOfficeGroupConstants } from "reduxLib/constants/EMEA/emeaSalesOfficeGroupConstants";

const initialState = {
  sales_ofc_loading: false,
  sales_offices: [],
  sales_ofc_err: null,
  sosg_fav_loading: false,
  sosg_fav: [],
  sosg_fav_err: null
}

const emeaSalesOfficeGroupReducer = (state = initialState, action) => {
  switch (action.type) {

    case emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_LOADING:
      return { ...state, sales_ofc_loading: action.payload };

    case emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_SUCCESS:
    case emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_FAILED:
    case emeaSalesOfficeGroupConstants.EMEA_FILTER_BY_SO_SG_RESET:
      return { ...state, sales_offices: action.payload, sales_ofc_err: action.error };

    case emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_LOADING:
      return { ...state, sosg_fav_loading: action.payload };

    case emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_SUCCESS:
      return { ...state, sosg_fav: action.payload, sosg_fav_err: 'success' };

    case emeaSalesOfficeGroupConstants.EMEA_SO_SG_FAV_FAILED:
      return { ...state, sosg_fav_err: action.error };
      
    default:
      return state;
  }
}

export default emeaSalesOfficeGroupReducer;  