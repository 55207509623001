import React from "react";
import { useTranslation } from "react-i18next"
import { Grid, Divider, makeStyles } from '@material-ui/core';
import BarChart from "components/D3Charts/BarChart";
import PieChart from "components/D3Charts/PieChart";
import {chartElementStyles} from "../../theme"
import { sortOrder } from "helpers";


const useStyles = makeStyles(chartElementStyles)
export const NetworkOrderChartBuilder = (props) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const { margins, height, type, subtype, chartsData, dimensions, setDetails } = props

  const chartKeys = ["blueCount","yellowCount","redCount"];

  const barkeys = ["SO_BLOCKED", "SO_BLOCK_FREE"];
  const piekeys = ["STO"];
  const pieData = [];
  let barDonutData = [];

  chartsData && chartsData.map(d => {
    if (barkeys.includes(d.state)) {
      barDonutData.push(
        { ...d, 
          blueCount:(d.greenCount || 0), 
          stateDesc : d.state === barkeys[0] ? t('blocked') : d.state === barkeys[1] ? t('blocked_free') : d.stateDesc,
        }
        )
    }

    if (piekeys.includes(d.state)) {
      pieData.push({
        ...d,
        value: d.redCount,
        color: "red",
        name: d.stateDesc,
      })
      pieData.push({
        ...d,
        name: d.stateDesc,
        color: "blue",
        value: (d.totalCount - d.redCount) || 0
      })
    }
    return null;
  });

  barDonutData = sortOrder({data: barDonutData, order: barkeys, key: "state"});
  return (
    <Grid container wrap='nowrap' data-testid="networkorderchartbuilder">
      <Grid item xs={7}>
        <BarChart id={"networkorderchartbuilder"}
          wrapit
          header='Cust. Orders'
          margins={margins}
          height={height}
          type={type}
          subtype={subtype}
          data={barDonutData}
          xKey={"stateDesc"}
          keys={chartKeys}
          total
          dimensions={dimensions}
          setDetails={setDetails}
        />
      </Grid>
      <Grid item className={classes.verticalDivider} ><Divider orientation='vertical'/></Grid>
      <Grid item xs={5}>
        <PieChart labels center={t("total")} footer={t("stock_transfer_orders")} data={pieData} xKey="color" margins={margins} height={height - 20} type={type} subtype={subtype} setDetails={setDetails} />
      </Grid>
    </Grid>
  );
}

