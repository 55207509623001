import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSetQuery, { useUpdateTabWitOptions } from 'customHooks/useQuery';
import { useHistory, useLocation } from 'react-router-dom';
import qs from "query-string";
import { useDeepCompareEffect } from 'react-use';
import { makeStyles, withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import NotFavoriteIcon from 'assets/images/not_favorite.svg';
import FavoriteIcon from 'assets/images/favorite.svg';
import { isJsonParsable } from 'helpers';
import { locationsMapping } from 'helpers/laoHelpers';
import { laoSetSiteFavourite } from 'reduxLib/services/LaoServices/laoFavService';

const useStyles = makeStyles((theme) => ({
  textField: {
    minWidth: theme.spacing(25.5),
    background: theme.palette.text.underline,
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      '& fieldset': {
        borderColor: theme.palette.white,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.white,
      },
    },
  },
  locationItems: {
    lineHeight: `${theme.spacing(5)}px`,
    alignSelf: 'center',
  },
  favIcon: {
    padding: '2px',
    filter: 'invert(100%) sepia(7%) saturate(2%) hue-rotate(309deg) brightness(110%) contrast(101%)'
  },
  locationLabel: {
    display: 'inline-flex'
  },
  locationContainer: {
    maxHeight: '320px',
    overflow: 'auto',
    marginTop: theme.spacing(3)
  },
  sites: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0px ${theme.spacing(3)}px`,
    margin: `${theme.spacing(1)}px 0px`,
  },
  checkbox: {
    padding: theme.spacing(0.25),
    color: theme.palette.white,
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette?.white
    }
  },
  icon: {
    fill: theme.palette?.text?.primary
  },
  locationGrid: {
    // maxHeight: 300,
    // overflowX: 'auto',
    // '& >div:not(:nth-child(4n))': {
    //   borderRight: `1px solid ${theme.palette.white}`
    // },
    // [theme.breakpoints.down("md")]: {
    //   '& >div:not(:nth-child(3n))': {
    //     borderRight: 'none'
    //   },
    //   '& >div:nth-child(odd)': {
    //     borderRight: `1px solid ${theme.palette.white}`
    //   }
    // },
    // [theme.breakpoints.down("sm")]: {
    //   '& >div:not(:nth-child(3n))': {
    //     borderRight: 'none'
    //   }
    // }
  },
  actionGrid: {
    border: `none !important`,
    position: 'absolute',
    bottom: theme.spacing(2.5),
    width: 'auto'
  },
  actionsButton: {
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.primary?.button}`,
    color: theme.palette?.primary?.button,
    marginRight: theme.spacing(2),
  }
}));

export const CssTextField = withStyles(theme => ({
  root: {
    minWidth: theme.spacing(25.5),
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.white,
    },
    '& .MuiOutlinedInput-root': {
      height: theme.spacing(5),
      background: theme.palette.text.underline,
      '& fieldset': {
        borderColor: theme.palette.white,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.white,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.white,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(2.25)}px ${theme.spacing(2.5)}px`
    }
  }
}))(TextField);

const LaoLocations = ({ region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = qs.parse(location.search);
  const [params] = useSetQuery();
  const updateTabWithOptions = useUpdateTabWitOptions();

  const { locations: laoLocations, favouriteSiteNums } = useSelector(({
    laoSites: { locations },
    laoFavorites: {
      favouriteSiteNums
    }
  }) => ({ locations, favouriteSiteNums }));

  const [siteSelected, setLaoSiteSelected] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState(() => (laoLocations.length && locationsMapping(laoLocations, params?.BU)) || []);
  const [searchText, setSearchText] = useState("");

  const containsText = (text, searchtext) => {
    return text.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  }

  useEffect(() => {
    laoLocations.filter(d => {
      if (searchText !== '') {
        let filteredLoc = laoLocations.filter(item => {
          return containsText(`${item?.shortName} (${item?.siteNum})`, searchText)
        });
        setFilteredLocations(locationsMapping(filteredLoc, params?.BU));
      }
    })
  }, [searchText]);

  const handleLocationSearch = (event) => {
    setSearchText(event.target.value);
  }

  const handleLocationSelect = (checked, siteNum) => {
    if (checked) {
      setLaoSiteSelected([...siteSelected, siteNum])
    } else {
      let updatedSitesList = siteSelected.filter(site => site !== siteNum);
      setLaoSiteSelected(updatedSitesList);
    }
  }

  const handleClearSelections = () => {
    setLaoSiteSelected([]);
    updateTabWithOptions({ key: "sites", value: [] }, {}, [])
  }

  const handleSaveSelections = () => {
    updateTabWithOptions({ key: "sites", value: siteSelected }, {}, siteSelected);
    // if(!isEqual(all_favs?.favouriteSiteNums, siteFavs)) {
    //   dispatch(emeaLocationFavService({ region: region, favouriteSiteNums: siteFavs }))
    // }
  }

  const handleSiteFav = (isFav, siteNum) => {
    const favSites = isFav ? favouriteSiteNums.filter(d => d !== siteNum) :
      [...favouriteSiteNums, siteNum];
    dispatch(laoSetSiteFavourite(favSites));
    handleLocationSelect(!isFav, siteNum)
  }

  useDeepCompareEffect(() => {
    const sites = isJsonParsable(queryParams?.sites) || [];
    setLaoSiteSelected(sites);
  }, [queryParams?.sites]);

  return (
    <Grid container data-testid='filterLocations'>
      <Grid container item spacing={2}>
        <Grid item sm={6} md={4} lg={3}>
          <Typography variant="h6" >{t("region")}</Typography>
          <TextField
            className={classes.textField}
            variant="outlined"
            value="LAO"
          />
        </Grid>

        <Grid item sm={6} md={4} lg={3}>
          <Typography variant="h6"> {t('search')} </Typography>
          <CssTextField
            id="id-location-search"
            placeholder={t("location_search")}
            value={searchText}
            variant="outlined"
            inputProps={{ 'data-testid': 'location-search' }}
            onChange={(e) => handleLocationSearch(e)}
          />
        </Grid>
      </Grid>

      <Grid container item data-testid="location-list" className={classes.locationContainer}>
        {laoLocations?.length ? filteredLocations.map((subRegion) => {
          let subRegionKey = Object.keys(subRegion)[0];
          return (
            <Grid container item data-testid={`${subRegionKey}-section`} className={classes.locationGrid}>
              <Grid item sm={12} md={12} lg={12}><Typography variant="h6" >{subRegionKey}</Typography></Grid>
              {subRegion[subRegionKey]?.map((site, index) => {
                const isFav = favouriteSiteNums?.includes(site.siteNum);
                return (
                  <Grid container item sm={12} md={6} lg={4} className={classes.sites}>
                    <Typography variant='body1' className={classes.locationLabel}>{`${site.shortName} (${site.siteNum})`}</Typography>
                    <Grid item data-testid={`${site.siteNum}-icons`}>
                      <Checkbox name={site.shortName} checked={siteSelected.indexOf(site?.siteNum) > -1}
                        onChange={(e) => handleLocationSelect(e?.target?.checked, site.siteNum)} className={classes.checkbox}
                        inputProps={{ 'data-testid': `select_${site.siteNum}` }} />
                      <IconButton aria-label="fav-button" onClick={() => handleSiteFav(isFav, site.siteNum)}
                        data-testid={`fav-${site.siteNum}`} className={classes.favIcon}>
                        <img alt="fav-icon" src={isFav ? FavoriteIcon : NotFavoriteIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          )
        })
          : null
        }
      </Grid>
      {laoLocations?.length ?
        <Grid container item className={classes.actionGrid}>
          <Button variant="outlined" className={classes.actionsButton} data-testid="save-sites" onClick={handleSaveSelections}> {t('apply_selections')} </Button>
          <Button variant="outlined" className={classes.actionsButton} data-testid="clear-sites" onClick={handleClearSelections}> {t('clear_selections')} </Button>
        </Grid>
        : null
      }

      {/* <Snack {...snack} handleClose={handleClose} /> */}
    </Grid>
  )
}

export default LaoLocations;