import React from "react";
import { useTheme } from '@material-ui/core';
import BarChart from "components/D3Charts/BarChart";

export const DistributionInboundChartBuilder = (props) => {

    const {
        margins, height, type, subtype, chartsData, keys,
        setDetails } = props

    //const body = siteNums ? { "destSites": siteNums } : {};
    // React.useEffect(() => {
    //     if (rest.expand) {
    //         getCharts();
    //     }
    // }, [rest.expand]);

    const chartColors = Object.values(useTheme().palette.chartColors);

    const xKey = "stateDesc";

    const barData = chartsData ? chartsData.map(d => {
        return {
            ...d,
            name: d[xKey],
            value: d.totalCount,
            blueCount: d.greenCount
        }
    }) : [];

    return (
        <div data-testid="distributionInbound" >
            <BarChart id={`${subtype}_distchart`} data={barData} keys={keys} colors={chartColors} xKey={xKey} margins={margins} wrapit height={height} type={type} subtype={subtype} setDetails={setDetails} />
        </div>
    );
}

