import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography , useMediaQuery, useTheme, makeStyles, IconButton} from '@material-ui/core';
import {Favorite,CheckCircleOutline,CheckCircle,FavoriteBorder, ArrowBack} from "@material-ui/icons";

import clsx from "clsx";
import {
    setBusinessService,
    setBusinessLocalAction
} from "reduxLib/services";
import Snack from "components/common/Helpers/Snack";
import CustomizedTooltip from "components/common/CustomizedTooltip";
import { businessUnits as businessUnitsArray } from "configs/appConstants";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        align: 'left',
        size: theme.spacing(2),
    },
    item: {
        padding: theme.spacing(2)
    },
    icons: {
        cursor: 'pointer',
        color: theme.palette?.text?.primary,
        marginRight: theme.spacing(2)
    },
    iconSet: {
        color: 'black'
    },
    iconUnset: {
        color: 'grey',
    },
    actionButton: {
        textTransform: 'capitalize',
        position: 'absolute',
        bottom: theme.spacing(2.5),
        color: theme.palette.white,
        border: `1px solid ${theme.palette.white}`
    },
    text: {
        color: theme.palette?.text?.primary
    }
}));


const BusinessFilter = (props) => {

    const { handleTabHideShow } = props
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

    const [snack, setSnack] = React.useState({
        open: false,
        severity: null,
        message: null
    });

    const { businessUnits, tempBusinessUnit } = useSelector(({ favorites }) => favorites);

    const businessUnitsLocal = tempBusinessUnit?.length === 0 ? businessUnitsArray : tempBusinessUnit;

    const setBusinessValue = (value, type) => {
        let business = [...(businessUnitsLocal ? businessUnitsLocal: []) ];
        if (type === "add") {
            business.push(value);
        } else {
            business = business.filter(d => d !== value)
        }

        if (business.length === 0) {
            setSnack({
                open: true,
                severity: "warning",
                message: t("atleast_one_business_unit_needs_to_selected")
            });
        } else {
            dispatch(setBusinessLocalAction([...business]));
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };
    return (
        <Grid className={classes.root} container xs={12} sm={12} md={6} lg={6} data-testid='businessfilter' >
            {isMobile && <IconButton data-testid='bumobiletab' onClick={()=>handleTabHideShow(true)} className={classes.text} >
                    <ArrowBack/><Typography>{t("business_unit")}</Typography>
                </IconButton>
            }
            {
                businessUnitsArray.map(d => {
                    const selected = businessUnitsLocal?.includes(d) || businessUnitsLocal?.length === 2;
                    return (
                        <Grid container className={classes.item}>
                            <Grid item xs={8} sm={2} md={4} lg={4} >
                                <Typography variant="body1" component="div" className={classes.text}>
                                    <CustomizedTooltip >
                                        <span>
                                            {d}
                                        </span>
                                    </CustomizedTooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={1} className={clsx(classes.iconSet, classes.icons)}>
                                {
                                    selected ? <CheckCircle data-testid='uncheckicon' onClick={() => setBusinessValue(d, "remove")} name="uncheck" id={d} className={classes.text} /> :
                                        <CheckCircleOutline data-testid='checkicon' onClick={() => setBusinessValue(d, "add")} name="check" id={d} className={classes.text} />
                                }
                            </Grid>
                            <Grid item xs={1} className={clsx(classes.iconSet, classes.icons)}>
                                {
                                    businessUnits?.length === 2 || businessUnits?.includes(d) ?
                                        <Favorite name="unfav" id={d} onClick={(e) => {
                                            dispatch(setBusinessService({ value: { value: d }, type: "unfav", bunits: businessUnits, lbunits:businessUnitsLocal  }));
                                        }} className={classes.icons} /> :
                                        <FavoriteBorder name="fav" id={d} onClick={(e) => {
                                            dispatch(setBusinessService({ value: { value: d }, type: "fav", bunits: businessUnits, lbunits:businessUnitsLocal }));
                                        }} className={classes.icons} />
                                }
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Snack {...snack} handleClose={handleClose} />
        </Grid>
    )
}


export default BusinessFilter;
