import React from "react";
import moment from "moment-timezone";
//Do not remove these unused Locale imports
import frLocale from "moment/locale/fr";
import deLocale from "moment/locale/de";
import { useTranslation } from "react-i18next";


moment.fn.fromJustNow = function (a) {
    moment.locale(localStorage.getItem('i18nextLng'))
    if (Math.abs(moment().diff(this)) < 15000) {
        return 'just now';
    }
    return this.fromNow(a);
}

const Timer = ({date}) => {   
    const [timer, setTimer] = React.useState(moment(date).fromJustNow());
    const { t } = useTranslation();

    React.useEffect(() => {
        moment.locale(localStorage.getItem('i18nextLng'))
        setTimer(moment(new Date()).fromJustNow())
    }, [localStorage.getItem('i18nextLng')])

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimer(moment(date).fromJustNow())
          }, 30000);
          return () => clearInterval(interval);    
    }, [timer]);

    React.useEffect(() => {
        setTimer(moment(date).fromJustNow())
    }, [date])

    return (
        <span data-testid="timer">
            {t("last_updated")} {timer === 'just now' ? t('just_now') : timer}
        </span>
    )
}

export default Timer;