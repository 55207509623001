const currencyMappings = [
    {
        symbol: "AED",
        name: "UAE Dirham"
    },
    {
        symbol: "BHD",
        name: "Bahraini Dinar"
    },
    {
        symbol: "CHF",
        name: "Swiss Franc"
    },
    {
        symbol: "CZK",
        name: "Czech Koruna"
    },
    {
        symbol: "DKK",
        name: "Danish Krone"
    },
    {
        symbol: "EUR",
        name: "Euro"
    },
    {
        symbol: "GBP",
        name: "Pound Sterling"
    },
    {
        symbol: "HUF",
        name: "Hungarian Forint"
    },
    {
        symbol: "KES",
        name: "Kenyan Shillings"
    },
    {
        symbol: "KZT",
        name: "Kazakhstani Tenge"
    },
    {
        symbol: "MAD",
        name: "Moroccan Dirham"
    },
    {
        symbol: "NGN",
        name: "Nigerian Naira"
    },
    {
        symbol: "PLN",
        name: "Polish Zloty"
    },
    {
        symbol: "RUB",
        name: "Russian Ruble"
    },
    {
        symbol: "SAR",
        name: "Saudi Riyal"
    },
    {
        symbol: "UAH",
        name: "Ukrainian hryvnia"
    },
    {
        symbol: "USD",
        name: "US Dollar"
    },
    {
        symbol: "ZAR",
        name: "South African Rand"
    }
];

export default currencyMappings;