import React from 'react';
import moment from "moment-timezone";
import CustomCellRender from "components/common/CustomCellRender";
import EmeaSalesDocHyperlink from 'components/EMEA/EmeaSalesDocHyperlink';
import AlertIcon from 'assets/images/alert-circle.svg';

const statusBucket = {
    A: "Not Yet Processed",
    B: "Partially Processed",
    C: "Completely Processed"
}
export const emeaOrderCutsAndRejectionsFixedColumn = {
    title: '',
    field: "itemStatusHealth",
    render: rowData => {
        return <div data-testid="itemStatusHealth">
            {rowData?.itemStatusHealth === '10000' ? <img src={AlertIcon} alt="RED" /> : null}
        </div>
    }
};

export const emeaOrderCutsAndRejectionsColumns = {
    columnOrder: [
        {
            title: 'sales_document', field: "salesDocument",
            render: rowData => {
                return <EmeaSalesDocHyperlink id="salesDocument" salesDoc={rowData.salesDocument} />
            }
        },
        {
            title: 'sold_to_party', field: "soldToNum",
            render: rowData => {
                return <CustomCellRender id="soldToNum" value={rowData.soldToNum} />
            }
        },
        {
            title: 'sold_to_name', field: "soldToName",
            render: rowData => {
                return <CustomCellRender id="soldToName" value={rowData.soldToName} />
            }
        },
        {
            title: 'ship_to_party', field: "shipToNum",
            render: rowData => {
                return <CustomCellRender id="shipToNum" value={rowData.shipToNum} />
            }
        },
        {
            title: 'ship_to_name', field: "shipToName",
            render: rowData => {
                return <CustomCellRender id="shipToName" value={rowData.shipToName} />
            }
        },
        {
            title: 'material', field: "materialNum",
            render: rowData => {
                return <CustomCellRender id="materialNum" value={rowData.materialNum} />
            }
        },
        {
            title: 'description', field: "itemDesc",
            render: rowData => {
                return <CustomCellRender id="itemDesc" value={rowData.itemDesc} />
            }
        },
        {
            title: 'sales_document_type', field: "docType",
            render: rowData => {
                return <CustomCellRender id="docType" value={rowData.docType} />
            }
        },
        {
            title: 'document_date', field: "documentDate",
            render: rowData => {
                return <CustomCellRender id="documentDate" value={rowData.documentDate} />
            }
        },
        {
            title: 'order_qty', field: "orderQty",
            render: rowData => {
                return <CustomCellRender id="orderQty" value={rowData.orderQty} />
            }
        },
        {
            title: 'confirmed_qty', field: "confirmedQty",
            render: rowData => {
                return <CustomCellRender id="confirmedQty" value={rowData.confirmedQty} />
            }
        },
        {
            title: 'order_QTY_UOM', field: "qtyUom",
            render: rowData => {
                return <CustomCellRender id="qtyUom" value={rowData.qtyUom} />
            }
        },
        {
            title: 'order_QTY_CS', field: "orderQtyStd",
            render: rowData => {
                return <CustomCellRender id="orderQtyStd" value={rowData.orderQtyStd} />
            }
        },
        {
            title: 'requested_delivery_date', field: "requestedDeliveryDate",
            render: rowData => {
                return <CustomCellRender id="requestedDeliveryDate" value={rowData.requestedDeliveryDate} />
            }
        },
        {
            title: 'plant', field: "originSiteNum",
            render: rowData => {
                return <CustomCellRender id="originSiteNum" value={rowData.originSiteNum} />
            }
        },
        {
            title: 'delivery_block', field: "deliveryBlockCodes",
            render: rowData => {
                return <CustomCellRender id="deliveryBlockCodes" value={rowData.deliveryBlockCodes} />
            }
        },
        {
            title: 'line_value', field: "netLineValue",
            render: rowData => {
                return <CustomCellRender id="netLineValue" value={rowData.netLineValue} />
            }
        },
        {
            title: 'order_value', field: "netOrderValue",
            render: rowData => {
                return <CustomCellRender id="netOrderValue" value={rowData.netOrderValue} />
            }
        },
        {
            title: 'order_currency', field: "orderCurrency",
            render: rowData => {
                return <CustomCellRender id="orderCurrency" value={rowData.orderCurrency} />
            }
        },
        {
            title: 'invoice_value', field: "invoiceValue",
            render: rowData => {
                return <CustomCellRender id="invoiceValue" value={rowData.invoiceValue} />
            }
        },
        {
            title: 'material_group_num', field: "materialGroupNum",
            render: rowData => {
                return <CustomCellRender id="materialGroupNum" value={rowData.materialGroupNum} />
            }
        },
        {
            title: 'purchase_order_num', field: "customerPoNum",
            render: rowData => {
                return <CustomCellRender id="customerPoNum" value={rowData.customerPoNum} />
            }
        },
        {
            title: 'order_reason_code', field: "orderReasonCode",
            render: rowData => {
                return <CustomCellRender id="orderReasonCode" value={rowData.orderReasonCode} />
            }
        },
        {
            title: 'order_reason_description', field: "orderReasonDesc",
            render: rowData => {
                return <CustomCellRender id="orderReasonDesc" value={rowData.orderReasonDesc} />
            }
        },
        {
            title: 'item_reason_code', field: "itemReasonCode",
            render: rowData => {
                return <CustomCellRender id="itemReasonCode" value={rowData.itemReasonCode} />
            }
        },
        {
            title: 'item_reason_description', field: "itemReasonDesc",
            render: rowData => {
                return <CustomCellRender id="itemReasonDesc" value={rowData.itemReasonDesc} />
            }
        },
        {
            title: 'batch', field: "batchNum",
            render: rowData => {
                return <CustomCellRender id="batchNum" value={rowData.batchNum} />
            }
        },
        {
            title: 'sales_unit', field: "salesUnit",
            render: rowData => {
                return <CustomCellRender id="salesUnit" value={rowData.salesUnit} />
            }
        },
        {
            title: 'division', field: "divisionName",
            render: rowData => {
                return <CustomCellRender id="divisionName" value={rowData.divisionName} />
            }
        },
        // {
        //   title: 'status', field: "itemStatusBucketDesc",
        //   render: rowData => {
        //     return <CustomCellRender id="itemStatusBucketDesc" value={'rejected'} />
        //   }
        // },
        {
            title: 'sales_office', field: "salesOffice",
            render: rowData => {
                return <CustomCellRender id="salesOffice" value={rowData.salesOffice} />
            }
        },
        {
            title: 'sales_group', field: "salesGroup",
            render: rowData => {
                return <CustomCellRender id="salesGroup" value={rowData.salesGroup} />
            }
        },
        {
            title: 'sales_rep_code', field: "csrId",
            render: rowData => {
                return <CustomCellRender id="csrId" value={rowData.csrId} />
            }
        },
        {
            title: 'sales_rep_name', field: "csrName",
            render: rowData => {
                return <CustomCellRender id="csrName" value={rowData.csrName} />
            }
        },
        {
            title: 'distribution_channel', field: "distributionChannel",
            render: rowData => {
                return <CustomCellRender id="distributionChannel" value={rowData.distributionChannel} />
            }
        },
        {
            title: 'item_delivery_status', field: "itemDeliveryStatus",
            render: rowData => {
                return <CustomCellRender id="itemDeliveryStatus" value={rowData.itemDeliveryStatus} />
            }
        },
        {
            title: 'header_delivery_status', field: "orderDeliveryStatus",
            render: rowData => {
                return <CustomCellRender id="orderDeliveryStatus" value={rowData.orderDeliveryStatus} />
            }
        },
        {
            title: 'delivery_#', field: "deliveryNum",
            render: rowData => {
                return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
            }
        },
        {
            title: 'goods_issue_date', field: "goodsIssueDate",
            render: rowData => {
                return <CustomCellRender id="goodsIssueDate" value={rowData.goodsIssueDate} />
            }
        },
        {
            title: 'customer', field: "corporateCustomerName",
            render: rowData => {
                return <CustomCellRender id="corporateCustomerName" value={rowData.corporateCustomerName} />
            }
        },
        // {
        //   title: 'rdd_bucket', field: "reqDelDateBucketDesc", 
        //   render: rowData => {
        //     return <CustomCellRender id="reqDelDateBucketDesc" value={rowData.reqDelDateBucketDesc} />
        //   }
        // },
        {
            title: 'delivery_date', field: "deliveryDate",
            render: rowData => {
                return <CustomCellRender id="deliveryDate" value={rowData.deliveryDate} />
            }
        },
        {
            title: 'invoice_date', field: "invoiceDate",
            render: rowData => {
                return <CustomCellRender id="invoiceDate" value={rowData.invoiceDate} />
            }
        },
        {
            title: 'order_block_code', field: "orderBlockCodes",
            render: rowData => {
                return <CustomCellRender id="orderBlockCodes" value={rowData.orderBlockCodes} />
            }
        },
        {
            title: 'product_hierarchy', field: "materialHierarchyDesc",
            render: rowData => {
                return <CustomCellRender id="materialHierarchyDesc" value={rowData.materialHierarchyDesc} />
            }
        },
    ],
    columnConfiguration: (d) => {
        return {
            itemDeliveryStatus: statusBucket[d?.itemDeliveryStatus] || '-',
            orderDeliveryStatus: statusBucket[d?.orderDeliveryStatus] || '-',
            documentDate: d?.documentDate ? moment(d.documentDate).format(`DD-MM-YYYY`) : '-',
            requestedDeliveryDate: d?.requestedDeliveryDate ? moment(d.requestedDeliveryDate).format(`DD-MM-YYYY`) : '-',
            goodsIssueDate: d?.goodsIssueDate ? moment(d.goodsIssueDate).format(`DD-MM-YYYY`) : '-',
            deliveryDate: d?.deliveryDate ? moment(d.deliveryDate).format(`DD-MM-YYYY`) : '-',
            invoiceDate: d?.invoiceDate ? moment(d.invoiceDate).format(`DD-MM-YYYY`) : '-',
        }
    }
}