import React from 'react'
import { useTranslation } from "react-i18next"
import { scaleBand, scaleLinear, scaleOrdinal, max, sum } from 'd3'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Axes from './Axes'
import Bars from './Bars'
import ResponsiveWrapper from '../charts/ResponsiveWrapper'
import { Typography, Grid } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  label: {
    color:theme.palette.menu.base
  }
}));

const BarChart = ({setDetails, wrapit, horizontal, keys, xKey, data, header, total,type, subtype,id, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  let margins = props.margins;
  if (horizontal) {
    margins = { ...margins, right: 50 }
  }
  margins = {...margins, bottom: 50}

  const themeColors = keys.length > 2 ? Object.values(theme.palette.chartColors) : [theme.palette.chartColors.healthy,theme.palette.chartColors.unhealthy];

  const svgDimensions = {
    width: props.parentWidth,
    height: props.height + 20 || 250
  }
  
  const colors = scaleOrdinal().domain(keys).range(themeColors);

  let totalSum = 0;
  data.forEach((d) => {
    d.total = sum(keys, k => +d[k]);
    totalSum += d.total;
    return d
  })

  let xScale = scaleBand()
    .padding(0.5)
    .paddingInner(0.5)
    .domain(data.map(d => d[xKey]))
    .range([margins.left, svgDimensions.width - margins.right])

  let yScale = scaleLinear()
    .domain([0, max(data, d => sum(keys, k => +d[k]))])
    .nice()
    .range([svgDimensions.height - margins.bottom, margins.top]);


  if (horizontal) {
    yScale = scaleBand()
      .padding(0.4)
      .paddingOuter(0.2)
      .paddingInner(0.2)
      .domain(data.map(d => d[xKey]))
      .range([margins.top, svgDimensions.height - margins.bottom])

    xScale = scaleLinear()
      .range([margins.left, svgDimensions.width - margins.right])
      .domain([0, max(data, d => d.total)]).nice();
  }
  const xAccessor = d => d[xKey];
  const centerPos = [svgDimensions.width / 2, svgDimensions.height / 2]

  const NoDataFound = ({ text }) => {
    return (
      <g transform={`translate(${centerPos})`} data-testid={id}>
        <text textAnchor="middle" fill="white" data-testid="noData">{t('no_data_found')}</text>
      </g>
    )
  }
  return (
    <span data-testid='barChart' style={{ textAlign: 'center' }}>     
      <Grid container justify="space-around">
            <Grid item ><Typography variant="subtitle1" >{header}</Typography></Grid>
            {total && total>0 ? <Grid item><Typography className={classes.label} variant="body2">{`${t('total')}: ${totalSum}`}</Typography></Grid> : null}
      </Grid>      
      <svg width={svgDimensions.width} height={svgDimensions.height}>
        {
          !totalSum ? <NoDataFound /> :
            <g>
              
              <Bars id={id}
                horizontal={horizontal}
                scales={{ xScale, yScale }}
                margins={margins}
                xKey={xKey}
                data={data}
                xAccessor={xAccessor}
                colors={colors}
                keys={keys}
                setDetails={setDetails}
                type={type}
                subtype={subtype}
              />
              <Axes
                horizontal={horizontal}
                wrapit={wrapit}
                scales={{ xScale, yScale }}
                margins={margins}
                svgDimensions={svgDimensions}
              />
            </g>}

      </svg>
    </span>
  )
}

export default ResponsiveWrapper(BarChart)
