import { loadDiagramConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";

export const getTrailerDimensionService = variables => dispatch => {
  dispatch({
      type: loadDiagramConstants.TRAILER_DIMENSION_LOADING,
      payload: true
  });
  const { body } = variables;

  return fetch(`${endpoints.osm.trailerDimensions}?carrierCode=${body.scac}`, {
      method: "GET",
  })
      .then(d => {
          if (d.status === 200) {
              return d.json()
          } else {
            throw "failed"
          }
      })
      .then(d => {
          dispatch({
              type: loadDiagramConstants.TRAILER_DIMENSION_SUCCESS,
              payload: d,
              error: null
          });
          dispatch({
              type: loadDiagramConstants.TRAILER_DIMENSION_LOADING,
              payload: false,
          })
      })
      .catch(err => {
          dispatch({
              type: loadDiagramConstants.TRAILER_DIMENSION_LOADING,
              payload: false,
          });
          dispatch({
              type: loadDiagramConstants.TRAILER_DIMENSION_FAILED,
              payload: [],
              error: "failed",
          });
      })
}

export const submitLDPreCheckService = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.PRECHECK_LD_LOADING,
        payload: true,
        variables
    });
    let response = {};
  
    return fetch(`${endpoints.osm.submitLdPreCheck}?shipmentNum=${variables.shipmentNum}&&region=${variables.region}`, {
        method: "GET",
    })
        .then(async d => {
            if (d.status === 200) {
                return await d.json();
            } else {
                response = await d.json();
                throw (response?.exceptionCode === "ct_exec_32") ? "invalid_shipment" : (response?.exceptionCode === "ct_exec_33") ? "seal_empty" : "failed";
            }
            
        })
        .then(d => {
            dispatch({
                type: loadDiagramConstants.PRECHECK_LD_SUCCESS,
                payload: { ...d, status: "success" },
            });
            dispatch({
                type: loadDiagramConstants.PRECHECK_LD_LOADING,
                payload: false,
            })
        })
        .catch(err => {
            dispatch({
                type: loadDiagramConstants.PRECHECK_LD_FAILED,
                payload: { status: err },
            });
            dispatch({
                type: loadDiagramConstants.PRECHECK_LD_LOADING,
                payload: false,
            });
        })
}  

export const loadDiagramService = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.LOAD_DIAGRAM_LOADING,
        payload: true
    });
    let response = {};

    return fetch(endpoints.osm.ldSubmission, {
        method: "POST",
        body: variables,
    })
        .then(async (d) => {
            if (d.status === 200) {
                return d;
            } else {
                response = await d.json();
                // throw response?.exceptionCode === "ct_exec_31" ? "invalid_printer" : "failed"
                throw "failed"
            }
        })
        .then(d => {
            dispatch({
                type: loadDiagramConstants.LOAD_DIAGRAM_SUCCESS,
                payload: "success"
            });
            
            dispatch({
                type: loadDiagramConstants.LOAD_DIAGRAM_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: loadDiagramConstants.LOAD_DIAGRAM_FAILED,
                payload: err  
            });
            dispatch({
                type: loadDiagramConstants.LOAD_DIAGRAM_LOADING,
                payload: false
            });
        })
}

export const resetSubmitLD = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.RESET_SUBMIT_LOAD_DIAGRAM,
        payload: null
    });
}

export const sendToWmsService = variables => dispatch => {
    const { region, shipmentNum, originSiteNum } = variables;

    dispatch({
        type: loadDiagramConstants.SEND_TO_WMS_LOADING,
        payload: true
    });

    const url = `${endpoints.osm.sendToWms}/${region}/${shipmentNum}?originSiteNum=${originSiteNum}`;
    let response = {};

    return fetch(url, {
        method: "POST",
        body: {},
    })
        .then(async (d) => {
            if (d.status === 200) {
                dispatch({
                    type: loadDiagramConstants.SEND_TO_WMS_SUCCESS,
                    payload: "success"
                });
            } else {
                response = await d.json();
                throw response?.exceptionDesc || "Action Send to WMS failed due to interface issue. Please try again after some time"
            }
            
            dispatch({
                type: loadDiagramConstants.SEND_TO_WMS_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: loadDiagramConstants.SEND_TO_WMS_FAILED,
                payload: err
            });
            dispatch({
                type: loadDiagramConstants.SEND_TO_WMS_LOADING,
                payload: false
            });
        })
}

export const resetSendToWms = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.RESET_SEND_TO_WMS,
        payload: null
    });
}

export const resetSubmitLDPreCheck = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.RESET_PRECHECK_LD,
        payload: null
    });
}

export const getDeliveryCommentService = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.DELIVERY_COMMENT_LOADING,
        payload: true,
        variables
    });
  
    return fetch(`${endpoints.osm.deliveryComments}?frieghtOrder=${variables.freightOrder}`, {
        method: "GET",
    })
        .then(d => {
            if (d.status === 200) {
                return d.json();
            } else {
                throw "failed";
            }
        })
        .then(d => {
            dispatch({
                type: loadDiagramConstants.DELIVERY_COMMENT_SUCCESS,
                payload: d,
                error: null
            });
            dispatch({
                type: loadDiagramConstants.DELIVERY_COMMENT_LOADING,
                payload: false,
            })
        })
        .catch(err => {
            dispatch({
                type: loadDiagramConstants.DELIVERY_COMMENT_LOADING,
                payload: false,
            });
            dispatch({
                type: loadDiagramConstants.DELIVERY_COMMENT_FAILED,
                payload: [],
                error: "failed",
            });
        })
}

export const resetDeliveryComment = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.RESET_DELIVERY_COMMENT,
        payload: [],
        error: null
    });
}

export const resetTrailerDimensions = variables => dispatch => {
    dispatch({
        type: loadDiagramConstants.RESET_TRAILER_DIMENSION,
        payload: [],
        error: null
    });
}