import React from 'react';
import { useTranslation } from "react-i18next"
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import { connect } from 'react-redux';
import clsx from 'clsx';
import {
  addItem,
  removeItem,
  clearAll,
  getLocationsService,
  removeItemCuso,
  clearAllCuso,
  removeItemSalesGroup
} from '../../reduxLib/services';
import { get } from "lodash";
import { Grid, Typography, ClickAwayListener } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import KCChip from "../../components/common/Chip";
import { viewByConstants } from "configs/appConstants";
import { getTabsData } from 'helpers';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '380px',
    maxWidth: '380px',
    maxHeight: '253px',
    zIndex: 100,
    alignItems: 'center',
    overflowY: 'auto',
    position: 'absolute',
    // top: 123,
    margin: 0,
    borderRadius: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.base,
    border: `1px, solid ${theme.palette.border}`,
  },
  margin: {
    margin: '5px 21px 5px',
    flex: 1
  },
  chip: {
    border: `1px solid white`,
    // backgroundColor: theme.palette.secondary.dark,
    marginBottom: theme.spacing(0.5),
  },
  chipHeader: {
    // minWidth: '150px',
    // height: '40px',
    [theme.breakpoints.down("xs")]: {
      maxWidth: theme.spacing(15)
    }
  },
  closeicon: {
    position: "absolute",
    right: 15,
    top: 15,
    cursor: "pointer",
    floart: 'right'
  },
  clearAll: {
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    paddingLeft: theme.spacing(2)
  }
}));

const removeSalesObjectLocal = (item, rest) => {
  const { selectionType } = item;
  if (selectionType === "CUST") {
    rest.removeItemCuso({ reducerObject: item });
  }
  if (selectionType === "SALES_OFFICE") {
    const shortName = get(item, "salesGroup", null)?.[0];
    if (!shortName) {
      rest.removeItemCuso({ reducerObject: item });
    } else {
      rest.removeItemSalesGroup({ reducerObject: item, shortName });
    }
  }
}

const ChipsBar = (props) => {
  const {
    removeItem,
    handleClose,
    showTabsBy,
    filter,
    tabtype,
    ...rest
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  
  const HandleClearAll = () => {
    if (tabtype === "customer") {
      rest.clearAllCuso()
    } else {
      rest.clearAll({});
    }
  }

  const handleDelete = (chipToDelete) => () => {
    if (tabtype === "customer") {
      removeSalesObjectLocal(chipToDelete, props)
    } else removeItem(chipToDelete);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Grid
        className={classes.root}
        container
        elevation={2}
      >
        <span className={classes.closeicon} onClick={handleClose}><CloseIcon color="primary" /></span>
        {filter.map((data, i) => {
          return (
            <Grid
              item data-testid="chipItems"
              key={data?.custom?.id || i}
            >
              <KCChip id={data?.custom?.name}
                disableRipple
                className={classes.chip}
                color="primary"
                deleteIcon={<HighlightOffRoundedIcon />}
                label={data?.custom?.name || '--'}
                onDelete={handleDelete(data)}
                variant="outlined"
              />
            </Grid>
          );
        })}
        {filter?.length > 0 ? (
          <span
            color="primary" data-testid="clearAllChips"
            onClick={HandleClearAll}
          >
            <Typography variant="subtitle1" className={classes.clearAll}>{t("clear_all")}</Typography>
          </span>
        ) : null}
      </Grid>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state) => {
  return {
    selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
    cuso: state.items.cuso || [],
    user: state.auth.user || null,
    favsLoading: state.favorites.loading,
    showfavs: state.options.showfavs
  };
};

const mapDispatchToProps = {
  addItem,
  removeItem,
  clearAll,
  getLocationsService,
  removeItemCuso,
  removeItemSalesGroup,
  clearAllCuso
};

const WithConnect = connect(mapStateToProps, mapDispatchToProps)(ChipsBar);

const ChipsItem = ({ filter, tabtype, ...rest }) => {

  const { type, showtabsby } = useParams();

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  let items = getTabsData(filter, viewByConstants[tabtype]);

  items = items.filter(d => {
    if (type === "mylocation") return true;
    if (tabtype === showtabsby) {
      return d.custom.id === type
    } else return true;
  });

  if (filter === null || type === "network") {
    items = []
  }

  const HandleClearAll = () => {
    if (items.length > 1) {
      if (tabtype === "customer") {
        rest.clearAllCuso()
      } else {
        rest.clearAll({});
      }
    } else {
      if (tabtype === "customer") {
        removeSalesObjectLocal(items[0], rest);
      } else rest.removeItem(items[0]);
    }
  }

  const getLabel = () => {
    if (items?.length > 1) {
      return `${items[0]?.custom?.name} and ${items?.length - 1} more`;
    }
    return items[0]?.custom?.name;
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  if (items.length === 0) {
    return <KCChip
      className={clsx(classes.chip, classes.chipHeader)}
      deleteIcon={<HighlightOffRoundedIcon />}
      label={t("all")}
      color="primary"
      variant="outlined" />
  }

  return (
    <>
      <KCChip id={getLabel()}
        className={clsx(classes.chip, classes.chipHeader)}
        deleteIcon={<HighlightOffRoundedIcon />}
        label={getLabel()}
        color="primary"
        onClick={() => setOpen(!open)}
        onDelete={HandleClearAll}
        variant="outlined"
      />
      {open ? <WithConnect
        handleClose={handleClick}
        filter={items}
        showTabsBy={showtabsby}
        tabtype={tabtype}
        type={type}
      /> : null}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChipsItem);
