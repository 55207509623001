import React from 'react';
import { makeStyles, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
        height: 35,
        margin: theme.spacing(0.5),
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        fontSize: 12,
        [theme.breakpoints.down("sm")]:{
            marginBottom: theme.spacing(.5),
            padding: 0,
            height: 28,
            fontSize: 10
        }
    },
    deleteIconOutlinedColorPrimary: {
        color: theme.palette.primary.contrastText,
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    }
}));

const KCChip = (props) => {
    const classes = useStyles();

    return (
        <Chip
            data-testid='chip'
            classes={classes}
            variant="outlined"
            {...props}
        />
    )
};

export default  KCChip;