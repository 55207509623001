export const emeaInvoiceSummaryDetailsColumns = [
  { header: "country", apiKey: "stateDescCustom" },
  { header: "forecast", apiKey: "forecastVal" },
  { header: "invoiced", apiKey: "invoicedVal" },
  { header: "percent_invoiced_vs_forecast", apiKey: "invoicePer", suffix: '%' },
  { header: "total_open_orders", apiKey: "openOrdersVal" },
  { header: "percent_open_orders_vs_forecast", apiKey: "openPer", suffix: '%' },
  { header: "blocked_orders", apiKey: "blockedOrdersVal" },
  { header: "pending_credit_release", apiKey: "pendingCreditReleaseVal" },
  { header: "gap_percent", apiKey: "gapPer", suffix: '%' }
]