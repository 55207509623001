import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, IconButton, Link, Typography, FormGroup, TextField, Button } from "@material-ui/core"
import { Save as SaveIcon, DeleteForeverOutlined as DeleteForeverOutlinedIcon } from '@material-ui/icons';
import FilterViewIcon from "../../assets/images/new_view.svg";
import FilterChips from "../common/FIlterChips";
import clsx from 'clsx'
import { useParams, useRouteMatch } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import { useSelector } from "react-redux";
import { deleteOSMView, updatedOSMView, activateOSMView } from "reduxLib/services";
import { useDispatch } from "react-redux";
import { resetFilters } from 'reduxLib/services';
import { generateFilterLayoutTemplate } from "helpers";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import isEmpty from 'lodash/isEmpty'
import CustomDialog from "components/common/Elements/CustomDialog";
import Snack from 'components/common/Helpers/Snack';
import qs from 'query-string';
import { osmShipmentExecutionBucket } from "reduxLib/constdata/osmFilters";

const useStyles = makeStyles((theme) => ({
  showGrid: {
    justifyContent: 'flex-end',
    padding: `${theme.spacing(.5)}px ${theme.spacing(7)}px`
  },
  showSpan: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  showText: {
    textDecoration: 'underline'
  },
  main: {
    border: `1px solid ${theme.palette.border}`,
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    borderRadius: theme.spacing(3),
    background: theme.palette.primary.main,
    margin: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    }
  },
  addview: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(3)
  },
  hideFilterComponent: {
    display: 'none'
  },
  link: {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  },
  divider: {
    borderRight: `2px solid ${theme.palette.extra.filterBorder}`,
    margin: `${theme.spacing(2)}px 0px`
  },
  icon: {
    color: theme.palette.text.primary
  },
  saveButton: {
    backgroundColor: theme.palette?.primary.contrastText,
    color: theme.palette.white,
    '&.MuiButton-contained:hover': {
      backgroundColor: theme.palette?.background.primaryBase
    }
  },
  addText: {
    border: `1px solid ${theme.palette?.background.secondaryBase}`,
    borderRadius: theme.spacing(0.5),
    '& .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    }
  }
}));

const OsmFilterBox = ({ tableName, type, hyperlinkClick, filterBody, resetTableFilters, setHyperlinkClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let { osmviewid } = useParams();
  const dispatch = useDispatch();

  const [hideFilterComponent, setHideFilterComponent] = useState(false);
  // const [showFilters, setshowFilters] = useState(true);
  const [activeView, setActive] = useState({});
  const [newview, setNewView] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [tabname, setTabName] = React.useState("");
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });

  const { views, active } = useSelector(({ osm }) => osm);
  const filters = useSelector(({ options }) => options?.filters?.osmShipments);

  const { path, params } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);

  const checkFiltersLength = (count) => {
    // setHideFilterComponent(count > 0 ? true : false)
  }

  const handleShowHideFilters = (hide) => {
    // setshowFilters(!showFilters);
    setHideFilterComponent(hide);
  }

  // useEffect(() => {
  //   filterCount ? setshowFilters(true) : setshowFilters(false);
  // }, [filterCount])

  useEffect(() => {
    if (hyperlinkClick) {
      setHideFilterComponent(true);
      setHyperlinkClick(false);
    }
  }, [hyperlinkClick])

  useEffect(() => {
    const active = views.filter(d => d.id === osmviewid)[0];
    setActive(active);
    // setTabName(active?.name);
  }, [osmviewid, views])

  const updateOSMViewEvent = () => {
    dispatch(activateOSMView({
      ...active,
      filters
    }));
    dispatch(updatedOSMView({
      ...active,
      filters
    }));
  }

  const deleteActiveView = () => {
    dispatch(deleteOSMView(activeView?.id));
  }

  const clearAllFilters = () => {
    dispatch(resetFilters({
      type: type,
      subtype: tableName,
      manualClearFilters: {
        osmShipmentExecutionBucket
      }
    }));
  }

  const addViewFromActive = (e) => {
    e.preventDefault();

    const duplicateViewName = views.map(d => d.name).filter(d => d?.toLowerCase() === tabname.toLowerCase())[0];
    if (duplicateViewName) {
      setSnack({
        ...snack,
        open: true,
        message: t("duplicate_view_error", { name: tabname })
      })
      return null
    }

    const newview = generateFilterLayoutTemplate({
      viewName: tabname,
      ...active
    });
    dispatch(updatedOSMView(newview));
    dispatch(activateOSMView(newview));

    const updatedpath = generatePath(path, {
      ...params,
      osmviewid: newview.id
    });
    history.push({
      ...location,
      pathname: updatedpath,
      search: qs.stringify({
        ...queryParams,
        newtab: newview.id
      }, { encode: false })
    });
    setNewView(false);
  }


  return (
    <div data-testid="osmFilterBar">
      <CustomDialog
        open={dialog}
        setOpen={(e) => setDialog(e)}
        description={t("delete_view_error", { name: activeView?.name })}
        event={() => {
          deleteActiveView();
          setDialog(false)
        }}
      />
      <Snack {...snack} handleClose={() => setSnack({
        ...snack,
        open: false
      })} />
      {/* {(!hideFilterComponent) && */}
      <Grid container className={classes.showGrid} data-id={`id-filterbox-${hideFilterComponent ? "shown" : "hidden"}`}>
        {!hideFilterComponent && <span className={classes.showSpan} onClick={() => handleShowHideFilters(true)} >
          <Typography data-testid="showOsmFilterBar" className={classes.showText}> {t('show_filters')} </Typography>
          <KeyboardArrowDownOutlinedIcon />
        </span>
        }
      </Grid>
      {/* } */}
      <Grid data-testid='osmFilterBox' container className={clsx(classes.main, !hideFilterComponent ? classes.hideFilterComponent : "")} >
        <Grid container className={classes.showGrid}>
          <span className={classes.showSpan} onClick={() => handleShowHideFilters(false)}>
            <Typography data-testid="hideOsmFilterBar" className={classes.showText}> {t('hide')} </Typography>
            <KeyboardArrowUpOutlinedIcon />
          </span>
        </Grid>
        <Grid item container alignItems="center" xs={12} sm={12} md={9} lg={8}>
          <FilterChips
            type={type}
            subtype={tableName}
            filterBody={filterBody}
            checkFilterChipsLength={checkFiltersLength}
          />
        </Grid>
        <Grid item container xs={3} sm={3} md={1} lg={1} alignItems="center" justify="space-around" className={classes.divider}>
          <Grid item>
            <Link onClick={clearAllFilters} data-testid="clearOsmfilters" className={classes.link} >{t('clear_all')}</Link>
          </Grid>
          <Grid item>
            <Link data-testid="resetOsmfilters" onClick={resetTableFilters} className={classes.link} >{t('reset')}</Link>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={3} >
          <Grid container spacing={3} alignItems="flex-end" justify="space-evenly" >
            <Grid item xs={12}>
              <Typography className={classes.label} align='center' variant='h4'> {activeView?.name} </Typography>
            </Grid>
            {
              !newview &&
              <>
                <Grid item >
                  <IconButton
                    data-testid="newViewIcon"
                    onClick={() => setNewView(true)}
                  >
                    <img src={FilterViewIcon} fontSize='medium' className={classes.icon} ></img>
                  </IconButton>
                  <Typography variant='subtitle1'> {t('new_view')} </Typography>
                </Grid>
                <Grid item>
                  <IconButton data-testid="saveOsmview" onClick={updateOSMViewEvent} disabled={activeView?.public}>
                    <SaveIcon
                      fontSize="medium"
                      className={activeView?.public ? null : classes.icon}
                    />
                  </IconButton>
                  <Typography align='center' variant='subtitle1'> {t('save')} </Typography>
                </Grid>
                <Grid item>
                  <IconButton data-testid="deleteOsmview" onClick={() => setDialog(true)} disabled={activeView?.public}>
                    <DeleteForeverOutlinedIcon
                      fontSize="medium" className={activeView?.public ? null : classes.icon}
                    />
                  </IconButton>
                  <Typography align='center' variant='subtitle1'>{t('delete')}</Typography>
                </Grid>
              </>
            }
          </Grid>
          {newview &&
            <Grid item >
              <form onSubmit={addViewFromActive} >
                <FormGroup row
                  className={classes.addview}>
                  <TextField
                    value={tabname}
                    onChange={(e) => setTabName(e.target.value)}
                    variant="outlined"
                    required
                    className={classes.addText}
                    inputProps={{ "data-testid": "save_new_from_view_osm" }}
                    name="viewname"
                    placeholder={t('enter_a_view_name')}
                    size="small"
                  />
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    size="small"
                    type="submit"
                    disabled={isEmpty(tabname)}
                    data-testid="addviewosm_btn">
                    {t('save')}
                  </Button>
                </FormGroup>
              </form>
            </Grid>
          }
        </Grid>
      </Grid>
    </div>
  )

}

export default OsmFilterBox;