import React from "react";
import { makeStyles, Card, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { filterStyles } from "theme";
import FilterComponentContainer from '../common/Filters/FilterComponentContainer';
import { useSelector } from "react-redux";
import { Component } from "components/common/Filters";
import CustomerModal from 'components/selfalerting/CustomerModal';

const useFilterStyles = makeStyles(filterStyles);
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'absolute',
        marginRight: theme.spacing(3),
        zIndex: 100,
        width: 350,
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.base,
    },
}));

const SelfAlertingFilters = () => {
    const classes = useFilterStyles();
    const classes2 = useStyles();
    const { t } = useTranslation();

    const alertFilters = useSelector(({ selfalerting: { filters } }) => filters);

    const type = "network";
    const subtype = "network";

    return (
        <div data-testid="filterMainContanerSF" className={classes.show}>
            <Card className={classes2.root} elevation={10}>
                <div className={classes.title}>
                    <Typography variant="h2" component="div" color="primary">{t('filter_search')}</Typography>
                </div>
                <CustomerModal />
                {
                    Object.keys(alertFilters)?.map((filterKey, index) => {
                        const filter = alertFilters[filterKey];
                        return (<FilterComponentContainer
                            key={index}
                            type={type}
                            subtype={subtype}
                            filter={filter}
                            filterKey={filterKey}
                            title={t(filter.name)}
                            testid={filter.name}
                        >
                            <Component
                                filterobj={filter}
                                alertFilters={alertFilters}
                                selfalert={true}
                                key={index}
                                type={type}
                                subtype={subtype}
                                filter={filter}
                                filterKey={filterKey}
                                testid={filter.name}
                            />
                        </FilterComponentContainer>
                        )
                    })
                }
            </Card>

        </div>
    )
}

export default SelfAlertingFilters;