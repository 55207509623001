import React from 'react';
import {
    Typography, makeStyles, Divider, LinearProgress
} from "@material-ui/core"
import { useSelector, useDispatch } from 'react-redux';
import { loadAllAlerts, loadSelfAlertingMetadata } from 'reduxLib/services/selfAlertingService';
import SelfAlertCard from 'components/selfalerting/SelfAlertCard';
import { selfAlertingStyles } from 'theme';
import { isEmpty, isArray } from 'lodash';
import clsx from "clsx";
import { useDeepCompareEffect } from 'react-use';

const useStyles = makeStyles(selfAlertingStyles);

export const SelfAlerting = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { alerts, alertsloading, metadata } = useSelector(({ selfalerting: { alerts, metadata, loading, alertsloading, filters, cuso } }) =>
        ({ alerts, loading, alertsloading, filters, cuso, metadata })
    );

    const [mappedmeta, setMeta] = React.useState({});

    React.useEffect(() => {
        const mappedVal = {};
        let dataVal = metadata?.supportedFields?.map(d => {
            mappedVal[d.field] = d.fieldText;
        });
        dataVal = metadata?.supportedOperations?.map(d => {
            mappedVal[d.operation] = d.operationText;
        });
        setMeta(mappedVal)
    }, [metadata])

    useDeepCompareEffect(() => {
        if(alerts?.length === 0) {
            dispatch(loadAllAlerts());
        }
    }, [alerts]);

    return (
        <div data-testid="allSelfAlertCards">
            <Typography component={"h1"} className={clsx(classes.title, classes.margintop)}>
                Saved Alerts
            </Typography>
            {
                alertsloading ?
                    <LinearProgress className={clsx(classes.linearprogress, classes.divider)} /> : <Divider classname={classes.divider} />
            }
            {
                (isArray(alerts) && alerts?.length === 0 && !alertsloading) &&
                <Typography component={"h1"} className={classes.title}>
                    No Saved Alerts Found!
                </Typography>
            }
            {
                isArray(alerts) ? alerts?.map(d => {
                    return (
                        <SelfAlertCard alert={d} mappedTitles={mappedmeta} />
                    )
                }) : null
            }
        </div>
    )

}

export default SelfAlerting;
