import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Hidden, makeStyles, Collapse } from '@material-ui/core';
import Card from "components/common/Card";
import Expander from 'components/common/Elements/Expander';
import TableComponent from "components/common/TableComponent";
import ItemDetailsTableOutBound, { columns as outBoundDetailsColumns } from "components/common/ItemDetailsTableOutBound";
import { defaultOrderFilterArgs } from "reduxLib/constdata/filters"
import { tableoptions } from 'helpers/tableStyleOverride';
import { endpoints, defaultShowTabsBy } from "helpers";
import { uniq, isEmpty } from "lodash";
import ordersFilters from 'reduxLib/constdata/ordersFilters';
import { viewStyles } from "theme"
import ChartComponent from "components/common/ChartComponent";
import ChartCarouselComponent from "components/common/ChartCarouselComponent";
import { BlockedFreeOrderTreeMapBuilder } from "components/common/BlockedFreeOrderTreeMapBuilder";
import { BackOrderDetailsChartBuilder } from "components/common/BackOrderDetailsChartBuilder";
import { BlockedNonTmsPlannedChartBuilder } from 'components/common/BlockedNonTmsPlannedChartBuilder';
import { BlockedTmsPlannedChartBuilder } from 'components/common/BlockedTmsPlannedChartBuilder';
import { defaultOrderColumns } from "reduxLib/constdata/orderColumns";
import { useSelector, connect } from 'react-redux'
import { get } from "lodash";
import BreadCrumb from 'components/common/Elements/BreadCrumb';

const useStyles = makeStyles(viewStyles);

export const conditionalFilterReplacement = (newfilters) => {
    if (newfilters?.orderExecutionBucket?.includes("TRANS_PLAN_PROCESSING")) {
        const transporcessing = ["TRANS_PLAN_PLANNED", "TRANS_PLAN_TENDERED", "TRANS_PLAN_TENDER_REJECTED"]
        newfilters.orderExecutionBucket = uniq([...newfilters.orderExecutionBucket, ...transporcessing]).filter(d => d !== "TRANS_PLAN_PROCESSING");
    }
}

export const tableStyleConfig = {
    ...tableoptions,
    rowStyle: (d) => {
        const healthStatusBucket = d.orderHealth ? d.orderHealth : "";
        return {
            ...tableoptions.rowStyle,
            borderLeft: healthStatusBucket === "RED" ? '5px solid #FF4081' : healthStatusBucket === "YELLOW" ? '5px solid #F5B023' : ''
        }
    },
}

const Orders = ({ name, prevPageDetails, ...rest }) => {
    const { t } = useTranslation();

    const { type } = rest?.match?.params;
    

    // const globalFilterSelection = generateHeader({ selections, type, showTabsBy, item: items.filter(d => d["custom"]["id"] === type)?.[0] });

    const pageTitle = t('order_management_breadcrumb_text')
    const classes = useStyles();
    const height = 160;
    const margins = { top: 30, right: 10, bottom: 40, left: 50, margin: 10 };
    const tableRef = React.createRef();

    const favBusinessUnits = useSelector(({ favorites }) => favorites?.tempBusinessUnit || [])
    const [showBackOrderStatusCard, setShowBackOrderStatusCard] = useState(isEmpty(favBusinessUnits) || favBusinessUnits.includes("PROFESSIONAL") ? true : false)
    const [expand, setExpand] = React.useState(false);

    const blockedOrderChartComponentDetails = [
        {
            name: t('blocked_tms_planned'),
            componentName: BlockedTmsPlannedChartBuilder,
            subtype: "SO_BLOCKED",
            showLegends: false,
        },
        {
            name: t('blocked_non_tms_planned'),
            componentName: BlockedNonTmsPlannedChartBuilder,
            subtype: "SO_BLOCKED",
            showLegends: false,
            blockApi: true
        }
    ]
    useEffect(() => {
        setShowBackOrderStatusCard(isEmpty(favBusinessUnits) || favBusinessUnits.includes("PROFESSIONAL") ? true : false)
    }, [favBusinessUnits])

    const setDetails = (...args) => {
        rest.setDetails(...args, "orders")
     };

    return (
        <div className={classes.separator} data-testid="ordersview" key={type}>
            <BreadCrumb />
            <Card
                cardtitle={
                    <Typography variant="h1" color="primary">
                        {t('live_status_text')}
                    </Typography>
                }
            >
                <Hidden smDown>
                    <Grid container className={classes.grid} spacing={2}>
                        <Grid className={classes.grid} item md={6} lg={6}>
                            <ChartComponent
                                showLegends
                                innercard
                                divider
                                BuilderComponent={BlockedFreeOrderTreeMapBuilder}
                                {...rest}
                                name={t('orders_block_free')}
                                setDetails={setDetails}
                                type={type}
                                subtype="SO_BLOCK_FREE"
                                height={height}
                                margins={margins}
                                topage={"orders"}
                            />
                        </Grid>
                        {blockedOrderChartComponentDetails && blockedOrderChartComponentDetails.map((component) =>
                            <Grid className={classes.grid} item md={6} lg={3} >
                                <ChartComponent
                                    blockApi={component.blockApi}
                                    innercard BuilderComponent={component.componentName}
                                    {...rest} name={component.name}
                                    setDetails={setDetails}
                                    type={type}
                                    subtype={component.subtype}
                                    height={height}
                                    margins={margins}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {showBackOrderStatusCard && <><Collapse in={expand}>
                        <Grid className={classes.grid} container spacing={2}>
                            <Grid className={classes.marginTop} item md={4} lg={4}>
                                <ChartComponent
                                    innercard
                                    divider
                                    BuilderComponent={BackOrderDetailsChartBuilder}
                                    {...rest}
                                    name={t('back_orders')}
                                    setDetails={setDetails}
                                    type={type}
                                    subtype="SO_BACK_ORDER"
                                    height={height}
                                    margins={margins}
                                />
                            </Grid>
                        </Grid>
                    </Collapse>
                        <Expander expand={expand} change={setExpand} /></>}
                </Hidden>

                {/* Mobile view */}
                <Hidden mdUp>
                    <ChartCarouselComponent chartsArray={[
                        <Grid item xs={12} sm={12}>
                            <ChartComponent innercard
                                divider
                                BuilderComponent={BlockedFreeOrderTreeMapBuilder}
                                {...rest}
                                name={t('orders_block_free')}
                                setDetails={setDetails}
                                type={type} subtype="SO_BLOCK_FREE"
                                height={height}
                                margins={margins}
                            />
                        </Grid>,
                        blockedOrderChartComponentDetails && blockedOrderChartComponentDetails.map((component) =>
                            <Grid item xs={12} sm={12}>
                                <ChartComponent
                                    blockApi={component.blockApi}
                                    innercard
                                    BuilderComponent={component.componentName}
                                    {...rest} name={component.name}
                                    setDetails={setDetails}
                                    type={type}
                                    subtype={component.subtype}
                                    height={height}
                                    margins={margins}

                                />
                            </Grid>
                        ),
                        showBackOrderStatusCard &&
                        <Grid item xs={12} sm={12} >
                            <ChartComponent
                                innercard divider
                                BuilderComponent={BackOrderDetailsChartBuilder}
                                {...rest} name={t('back_orders')}
                                setDetails={setDetails} type={type}
                                subtype="SO_BACK_ORDER"
                                height={height} margins={margins}
                            />
                        </Grid>
                    ]}
                    />
                </Hidden>
            </Card>
            <Card >
                <TableComponent {...rest}
                    pageTitle={pageTitle}
                    setDetails={setDetails}
                    pageDetails={{}}
                    prevPageDetails={prevPageDetails}
                    tableName="orders"
                    title={t('order_management_title')}
                    excludeArray={["searchStringList", "matAvailDate", "creditOnHold"]}
                    searchTextPlaceholder={t("search_helper_text_order_page")}
                    type={type}
                    tableRef={tableRef}
                    ItemDetailSection={ItemDetailsTableOutBound}
                    itemDetailsColumns={outBoundDetailsColumns}
                    defaultFilterArgs={defaultOrderFilterArgs}
                    columns={defaultOrderColumns}
                    conditionalFilterReplacement={conditionalFilterReplacement}
                    filterBody={ordersFilters}
                    filterType="ordersfilters"
                    topDateFilter={{
                        title: t('material_availability_date'),
                        key: "matAvailDate"
                    }}
                    fetchEndPoint={endpoints.table.orders}
                    tableStyleConfig={tableStyleConfig}
                />
            </Card>
        </div>
    );
}

const mapStateToProps = state => {
    const showTabsBy = defaultShowTabsBy(state);
    return {
        stopfavload: state.items.stopfavload,
        selections: { locations: state.items.items, customer: [...(get(state, "items.cuso.CUST", [])), ...(get(state, "items.cuso.SALES_OFFICE", []))] },
        showTabsBy,
        blockOnTabChange: get(state, 'items.blockOnTabChange', false)
    }
}


export default connect(mapStateToProps, null)(Orders);