import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { useDeepCompareEffect } from 'react-use';
import find from 'lodash/find';
import { findStatusObject } from "./EMEAOpenOrdersStatus";

export const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette?.card?.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  buttonLink: {
    color: theme.palette?.link?.primary,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  paper: {
    width: '30%',
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette?.card?.miniCard,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 85
  },
  slider: {
    "& .slick-current": {
      display: "flex",
      justifyContent: "center"
    }
  }
}));

const EMEAInvoiceStatus = ({ pageName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [controller] = useState(() => new AbortController());
  const [invoicedMtdCount, setInvoicedMtdCount] = useState();
  const [invoicedTodayCount, setInvoicedTodayCount] = useState();
  const [invoicedMtdMonth, setInvoicedMtdMonth] = useState();
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "USD");

  const invoices_loading = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_INVOICES_KEY_INFO"]?.loading);
  const invoices_data = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_INVOICES_KEY_INFO"]?.data || []);
  const {
    locations,
    customers = [],
    sofcs = [],
    BU,
    documentDate
  } = useEMEAGlobalContext();

  const getStatusKPI = ({ subType }) => {
    const payload = {
      region: "EMEA",
      subtype: subType,
      businessUnits: BU,
      sites: locations,
      documentDate: documentDate,
      customerOrSalesOffice: [...customers, ...sofcs],
      currency: localStorage.getItem("currency") || "USD",
      orderType: "ORDER_ENTRY"
    }
    dispatch(emeaSummaryService({ payload, controller }));
  }


  const handleLinkClick = () => { 
    let updatedPath = location.pathname;
    updatedPath =  `${updatedPath}/invoiceDetails`;
    history.push({
      ...location,
      pathname: updatedPath
    })
  };
  const blocks = [
    {
      totalCountMtd: invoicedMtdCount,
      totalCountToday: invoicedTodayCount,
      month: invoicedMtdMonth,
      currency:currency,
    },
  ];

  const displayMonth = (list, key) => {
    const obj1 = find(list, ['state', key]);
    const month = obj1.month;
    return month;
  }
  
  useDeepCompareEffect(() => {
    const totalCountMtd = invoices_loading ? "..." : invoices_data?.length ? findStatusObject(invoices_data,"INVOICED_MTD") : '-'
    const totalCountToday = invoices_loading ? "..." : invoices_data?.length ? findStatusObject(invoices_data,"INVOICED_TODAY") : '-';
    const month = invoices_loading ? "..." : invoices_data?.length ? displayMonth(invoices_data,"INVOICED_MTD") : '-';
    setInvoicedMtdCount(totalCountMtd);
    setInvoicedTodayCount(totalCountToday);
    setInvoicedMtdMonth(month);
  }, [invoices_data, invoices_loading])


  useDeepCompareEffect(() => {
    getStatusKPI({ subType: "SUBTYPE_EMEA_INVOICES_KEY_INFO" });
  }, [locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency")]);

  useEffect(() => {
    setCurrency(localStorage.getItem("currency"));
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <Box className={classes.box} data-testid="invoice-status-kpi">
      <Grid container justifyContent="space-between" >
        <Typography variant="h6"> {t('invoices')} </Typography>
        <Button className={classes.buttonLink} disableRipple data-testid="page-link-btn" size='small' onClick={handleLinkClick} >
          {t("go_to_invoice_details")}
        </Button>
      </Grid>
      {blocks.map(item =>
        <Grid container justifyContent="space-around">
          <Paper className={classes.paper}>
            <Typography variant="h3"> <strong> {item.totalCountMtd} {item.currency} </strong> </Typography>
            <Typography variant="body1"> {t('invoiced_mtd')} - <em>{item.month}</em> </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h3"> <strong> {item.totalCountToday} {item.currency}</strong> </Typography>
            <Typography variant="body1">{t('invoiced_today')} </Typography>
          </Paper>
        </Grid>
      )}
    </Box>
  )
}

export default EMEAInvoiceStatus;