import { sortOrder, moveItem } from "helpers";
import { osmConstants } from "../constants";

export const updateViews = (view, views) => {
  const viewids = views.map(d => d.id);
  if (view) {
    if (viewids?.includes(view?.id)) {
      return views?.map(d => {
        if (d.id === view.id) {
          return view
        }
        return d;
      })
    } else {
      return [...views, view]
    }
  }
  return views;
}

const osmReducer = (state = {
  loading: false,
  views: [],
  active: {},
  viewOrder: [],
  deleted: [],
  status: {
    status: null,
    message: null
  }

}, action) => {

  switch (action.type) {
    case osmConstants.OSM_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case osmConstants.FETCH_OSM_VIEWS_SUCCESS:
    case osmConstants.FETCH_OSM_VIEWS_FAILED:
      return {
        ...state,
        viewOrder: action.payload.viewOrder,
        views: action.payload.views,
        active: action.payload.active,
        loading: false
      };

    case osmConstants.UPDATE_VIEW_ORDER_SUCCESS:
    case osmConstants.UPDATE_VIEW_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        views: sortOrder({ data: state.views, order: action.payload.views?.map(d => d.id), key: "id" }),
        viewOrder: action.payload.views,
      };

    case osmConstants.GET_OSM_VIEW_SUCCESS:
    case osmConstants.GET_OSM_VIEW_FAILED:
      return {
        ...state,
        active: action.payload.active ? action.payload.active : state?.active,
        loading: false
      }

    case osmConstants.ACTIVATE_OSM_TAB:
      return {
        ...state,
        active: action.payload
      }
    case osmConstants.FAV_OSM_VIEW_SUCCESS:
    case osmConstants.FAV_OSM_VIEW_FAILED:
      let index = 0;
      let currentFavIndex = 0
      state.views.map((d, i) => {
        if (d.isFav) {
          currentFavIndex = i;
        }
      });
      const reOrder = moveItem(state.views, currentFavIndex, state.views.length - 1);
      const updated = reOrder.map((d, i) => {
        if (action.payload === d.id) {
          index = i;
        }
        return {
          ...d,
          isFav: action.payload === d.id ? true : false
        }
      });
      const viewsUpdated = moveItem(updated, index, 0);
      return {
        ...state,
        loading: false,
        views: viewsUpdated
      }

    case osmConstants.SHOW_ALL_HIDE_ALL_OSM_VIEWS:
      return {
        ...state,
        views: state.views.map(d => {
          return {
            ...d,
            hidden: action.payload
          }
        })
      }

    case osmConstants.HIDE_OSM_VIEW:
      return {
        ...state,
        views: state.views.map(d => {
          return {
            ...d,
            recent: action.payload === d.id && action.hide,
            hidden: action.payload === d.id ? action.hide : d.hidden
          }
        })
      }

    case osmConstants.UPDATE_OSM_VIEW_SUCCESS:
    case osmConstants.UPDATE_OSM_VIEW_FAILED:
      const views = updateViews(action.payload.view, state.views);
      return {
        ...state,
        views,
        status: action.payload.status,
        loading: false
      }

    case osmConstants.DELETE_OSM_VIEW_SUCCESS:
    case osmConstants.DELETE_OSM_VIEW_FAILED:
      return {
        ...state,
        views: state?.views?.filter(d => d.id !== action.payload.id),
        status: action.payload.status,
        deleted: [...state.deleted, action.payload.id],
        loading: false
      }

    case osmConstants.RESET_OSM_STATUS:
      return {
        ...state,
        status: {
          status: null,
          message: null
        }
      }
    default:
      return state
  }
}

export default osmReducer;