import React, { useState, useEffect } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";
import MaterialTable from 'material-table';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from '@material-ui/core/LinearProgress';
import { tableStyles, appLightTheme } from 'theme';
import { lightTableOptions } from 'helpers/tableStyleOverride';
import { emeaOrderEntryByCustomerColumns } from 'reduxLib/constdata/EMEA/emeaOrderEntryByCustomerColumns';
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import { getEmeaTableData } from 'reduxLib/services/EmeaServices/emeaTableServices';
import { useRefreshTable } from 'customHooks/useTableQuery';
import Snack from "components/common/Helpers/Snack";
import EmeaDetailsTable from './EMEA_Common/EmeaDetailsTable';
import { emeaOrderEntryByProductDetailsColumns } from 'reduxLib/constdata/EMEA/emeaOrderEntryByProductDetailsColumns';
import { endpoints } from "../../configs/endpoints"
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';

const useStyles = makeStyles(tableStyles);

const useStyles2 = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2)
  },
  noData: {
    textAlign: 'left',
    position: 'relative',
    paddingLeft: `${theme.spacing(2.5)}%`
  },
  spacer: {
    flex: "1 1 auto"
  },
  root: {
    '& .MuiTablePagination-selectIcon': {
      color: "inherit"
    }
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  },
}));

export const EmeaOrderEntryTableByCustomer = ({ tableName, type, subType, fetchEndPoint }) => {
  const tableClasses = useStyles();
  const classes = useStyles2();
  const { t } = useTranslation();

  const {
    locations,
    customers,
    sofcs,
    BU,
    documentDate
  } = useEMEAGlobalContext();

  const { data, status, loading } = useSelector(({ emeaTable }) => emeaTable?.[type]?.[tableName] || {});
  const [controller] = useState(() => new AbortController());
  const [columnsOrder, setcolumnsOrder] = useState([]);
  const [tableData, setTableData] = useState({});
  const [pageNum, setPageNum] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [curr, setCurr] = useState(localStorage.getItem("currency") || "USD");
  const [firstLoad, setFirstLoad] = useState(true);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });

  const tableBody = {
    region: 'EMEA',
    searchType: subType,
    orderType: "ORDER_ENTRY",
    currency: curr,
  };

  const [updatePageParams, refreshTable] = useRefreshTable({
    fetchEndPoint,
    tableName,
    type,
    controller,
    tableBody,
    setPage: setPageNum,
    getTableData: getEmeaTableData
  });

  const detailsTableBody = {
    region: 'EMEA',
    searchType: "EMEA_ORDER_ENTRY_SUMMARY_BY_CUSTOMER_PRODUCT_HIERARCHY",
    orderType: "ORDER_ENTRY",
    currency: curr,
    documentDate,
    originSites: locations,
    businessUnits: BU,
    customerOrSalesOffice: [...customers, ...sofcs]
  };

  const tableOptions = {
    ...lightTableOptions,
    pageSize: recordsPerPage, 
    selection: false,
    rowStyle: {
      ...lightTableOptions.rowStyle,
      backgroundColor: appLightTheme?.palette?.card?.base,
    }
  }

  const handlePageChange = (event, newPage) => {
    setPageNum(newPage);
    updatePageParams({ pageSize: recordsPerPage, page: newPage });
  };

  const handleRowsPerPageChange = (e) => {
    const pageSize = parseInt(e.target.value, 10)
    setRecordsPerPage(pageSize);
    setPageNum(0);
    updatePageParams({ pageSize: pageSize, page: 0 });
  };

  useEffect(() => {
    const translated = emeaOrderEntryByCustomerColumns.columnOrder?.map(item => {
      return {
        ...item,
        title: t(item.title),
      }
    });
    setcolumnsOrder(translated);
  }, [localStorage.getItem('i18nextLng')]);

  useDeepCompareEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (status?.status === "error") {
      setSnack({
        open: true,
        severity: status.status,
        message: t(status.message)
      });
    }
  }, [status]);

  useEffect(() => {
    setCurr(localStorage.getItem("currency"));
  }, [localStorage.getItem("currency")]);

  useEffect(() => {
    setFirstLoad(false);
    if (firstLoad) return;
    refreshTable({ pageSize: recordsPerPage, page: pageNum })
  }, [curr]);

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <Box data-testid="emeaOrderEntryTableByCustomer" justifyContent="center">
      <Typography variant="h3" className={classes.title}> { t('order_entry_summary_by_customer') } </Typography>
      <Grid className={tableClasses.table}>
        <MaterialTable
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: loading ? t("please_wait_while_the_data_is_loading") : t("no_records_to_display")
            }
          }}
          key={tableData?.items?.length}
          columns={columnsOrder}
          options={tableOptions}
          components={{
            Container: props_ => <Paper {...props_} elevation={0} />,
            OverlayLoading: props_ => <LinearProgress className={classes.progress} />,
            Pagination: props_ => (<TablePagination
              component="div"
              rowsPerPageOptions={[15, 30, 50]}
              showFirstButton
              showLastButton
              page={pageNum}
              count={tableData?.totalRecords || 0}
              rowsPerPage={recordsPerPage}
              onChangePage={handlePageChange}
              classes={classes}
              ActionsComponent={TablePaginationActions}
              onChangeRowsPerPage={handleRowsPerPageChange}
              labelRowsPerPage={t("rows_per_page")}
              data-testid="pagination"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count} ${t('records')}`}
              SelectProps={{ 'data-testid': 'selectRowsPerPage', SelectDisplayProps: { 'data-testid': "rowsSelect" } }}
            />)
          }}
          data={tableData?.items}
          detailPanel={[
            rowData => ({
              render: rowData_ => (<EmeaDetailsTable columns={emeaOrderEntryByProductDetailsColumns} fetchEndpoint={endpoints.emea.emeaDetailsTable} 
                tablePayload={detailsTableBody} rowIDKey={"corporateCustomerName"} rowIDValue={rowData_?.corporateCustomerName} />)
            })
          ]}        
        />
      </Grid>
      <Snack {...snack} handleClose={() => setSnack({ open: false })} />
    </Box>
    )
  }
  
export default EmeaOrderEntryTableByCustomer;