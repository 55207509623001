import { checklistConstants } from "../constants"

const changeTab = variables => dispatch => {
    dispatch({
        type: checklistConstants.CHANGE_TAB,
        payload: variables
    })
}

const setBlockOnTabChange = () => dispatch => {
    dispatch({
        type: checklistConstants.SET_BLOCKONTABCHANGE,
    })
}

const addItem = variables => dispatch => {
    dispatch({
        type: checklistConstants.ADD_ITEM_SUCCESS,
        payload: variables
    })
}


const updateLocations = variables => dispatch => {
    dispatch({
        type: checklistConstants.UPDATE_LOCATIONS,
        payload: variables
    })
}

const addItemsLocations = variables => dispatch => {
    dispatch({
        type: checklistConstants.ADD_ITEM_MULTIPLE_SUCCESS,
        payload: variables
    })
}

const addItemsCustomer = variables => dispatch => {
    dispatch({
        type: checklistConstants.ADD_CUSTOMER_MULTIPLE_SUCCESS,
        payload: variables
    })
}


const removeItem = variables => dispatch => {
    dispatch({
        type: checklistConstants.REMOVE_ITEM_SUCCESS,
        payload: variables
    })
}

const clearSelected = variables => dispatch => {
    dispatch({
        type: checklistConstants.CLEAR_SELECTED,
        payload: variables
    });
}

const clearAll = variables => dispatch => {
    dispatch({
        type: checklistConstants.CLEAR_ALL,
        payload: variables.data
    });
}

const removeFavs = variables => dispatch => {
    dispatch({
        type: checklistConstants.REMOVE_FAV_ITEMS,
        payload: { isFav: true }
    })
}

const addItemCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.ADD_CUSO_SUCCESS,
        payload: variables.reducerObject
    })
}

const addItemsCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.ADD_CUSO_MULTIPLE_SUCCESS,
        payload: variables
    })
}

const removeItemCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.REMOVE_CUSO_SUCCESS,
        payload: variables.reducerObject
    })
}

const clearAllCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.CLEAR_CUSO,
        payload: []
    });
}

const addItemSalesGroup = ({shortName,reducerObject}) => dispatch => {
    dispatch({
        type: checklistConstants.ADD_SG_SUCCESS,
        payload: {shortName,reducerObject}
    })
}

const removeItemSalesGroup = ({shortName,reducerObject}) => dispatch => {
    dispatch({
        type: checklistConstants.REMOVE_SG_SUCCESS,
        payload: {shortName,reducerObject}
    })
}

const removeFavsCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.REMOVE_FAV_CUSO,
        payload: variables
    })
}

const joinFavstoCuso = variables => dispatch => {
    dispatch({
        type: checklistConstants.JOIN_FAVS_AND_SELECTIONS_CUSO,
        payload: variables
    })
}

export {
    addItem,
    removeItem,
    clearAll,
    addItemCuso,
    removeItemCuso,
    clearAllCuso,
    removeFavs,
    clearSelected,
    changeTab,
    addItemSalesGroup,
    removeItemSalesGroup,
    addItemsCuso,
    addItemsLocations,
    addItemsCustomer,
    joinFavstoCuso,
    removeFavsCuso,
    setBlockOnTabChange,
    updateLocations
}