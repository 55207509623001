import React from "react";
import { Grid } from '@material-ui/core';
import BarChart from "components/D3Charts/BarChart";
import { getChartSegmentTotal } from "./ChartComponent";
import { formatColumnData, columnKeys } from "./DistributionHelpers";

export const DistributionOutboundCDChartBuilder = (props) => {

  // React.useEffect(() => {
  //   rest.setRefresh({
  //     refresh: !rest.refresh,
  //     siteNums,
  //     time: new Date()
  //   })
  // }, []);

  const { margins, height, type, subtype, chartsData, keys, setDetails, setHeaderTotalCount } = props

  // const barData = chartsData ? chartsData.map(d => {
  //   return { ...d, blueCount: d.greenCount }
  // }) : [];

  const data = formatColumnData(chartsData, columnKeys, "CHECK_IN")

  setHeaderTotalCount(getChartSegmentTotal({keys,data}))

  return (
      <Grid container data-testid="distributionOutbound">
        <Grid item xs={12}><BarChart id="checkedByDcChart" wrapit keys={keys} data={data} xKey="name" margins={margins} height={height} type={type} subtype={subtype} setDetails={setDetails} /></Grid>
      </Grid>
  );
}
