import React from 'react';
import moment from "moment-timezone";
import { shortTitleCellStyle, longTitleCellStyle } from 'helpers/tableStyleOverride';
import { formatToSentenceCase } from "helpers";
import { get, isEmpty } from "lodash";
import { orderHealthReasonCodeMap } from "../../theme/orderHealthReasonCodeMap";
import CustomRender from "../../components/common/CustomRender";
import CustomCellRender from "../../components/common/CustomCellRender";
import PromotionalOrder from "components/common/Elements/PromotionalOrder";
import FourKitesLink from 'components/common/Elements/FourKitesLink';

const orderStatusBucket = {
  SO_COMPLETELY_CONFIRED_CUBE: "100_%_confirmed_cube",
  SO_NOT_COMPLETELY_CONFIRMED_CUBE: "less_than_100%_confirmed_cube",
  SO_BLOCKED_TMS_PLANNED_VISIBLE: "visible_in_tms",
  SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE: "not_visible_in_tms",
  SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED: "pickup_not_scheduled",
  SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK: "pickup_package_multi_block",
  SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION: "immediate_action",
  SO_BACK_ORDER_BLOCK_FREE: "back_orders",
  SO_BACK_ORDER_BLOCKED: "back_orders"
}

export const defaultNetworkColumns = {
  columnOrder: [
    {
      title: 'shipment_#', field: "shipmentNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },
    {
      title: 'delivery_#', field: "deliveryNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="deliveryNum" value={rowData.deliveryNum} />
      }
    },
    {
      title: 'tracking_#', field: "trackingNumber", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="trackingNumber" value={rowData.trackingNumber} />
      }
    },
    {
      title: 'shipment_status', field: "orderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderExecutionBucketDesc" value={rowData.orderExecutionBucketDesc} />
      }
    },
    {
      title: 'fourkites_status', field: "fourkitesShipmentStatus", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="fourkitesShipmentStatus" value={rowData.fourkitesShipmentStatus} />
      }
    },
    {
      title: 'late_reason_code', field: "lateReasonCode", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="lateReasonCode" value={rowData.lateReasonCode} />
      }
    },
    {
      title: 'inbound_shipment_status', field: "inboundOrderExecutionBucketDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="inboundOrderExecutionBucketDesc" value={rowData.inboundOrderExecutionBucketDesc} />
      }
    },
    {
      title: 'order_#',
      field: "orderNum",
      cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <PromotionalOrder data={rowData} />
      }
    },
    {
      title: 'customer_po_#', field: "customerPoNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="customerPoNum" value={rowData.customerPoNum} />
      }
    },
    {
      title: 'promotionalOrder', field: "promotionalOrder", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="promotionalOrder" value={rowData.promotionalOrder} />
      }
    },
    {
      title: 'collectiveNumber', field: "collectiveNum", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="collectiveNum" value={rowData.collectiveNum} />
      }
    },
    {
      title: 'order_status', field: "orderStatus", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="orderStatus" value={rowData.orderStatus} />
      }
    },
    {
      title: 'order_type', field: "orderType",
      render: rowData => {
        return <CustomCellRender id="orderType" value={rowData.orderType} />
      }
    },
    {
      title: 'customer', field: "customer",
      render: rowData => {
        return <CustomCellRender id="customer" value={rowData.customer} />
      }
    },
    {
      title: 'origin_id', field: "originId",
      render: rowData => {
        return <CustomCellRender id="originId" value={rowData.originId} />
      }
    },
    {
      title: 'origin', field: "origin", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="origin" value={rowData.origin} />
      }
    },
    {
      title: 'dest_city', field: "destinationCity",
      render: rowData => {
        return <CustomCellRender id="destinationCity" value={rowData.destinationCity} />
      }
    },
    {
      title: 'dest_state', field: "destinationState",
      render: rowData => {
        return <CustomCellRender id="destinationState" value={rowData.destinationState} />
      }
    },
    {
      title: 'original_rdd', field: "originalRequestedDeliveryDatetime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="originalRequestedDeliveryDatetime" value={rowData.originalRequestedDeliveryDatetime} />
      }
    },
    {
      title: 'rdd', field: "requestedDeliveryDate", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="requestedDeliveryDate" value={rowData.requestedDeliveryDate} />
      }
    },
    {
      title: 'appt_status', field: "appointmentStatusDesc", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="appointmentStatusDesc" value={rowData.appointmentStatusDesc} />
      }
    },
    {
      title: 'pickup_appt_date_&_time', field: "loadReadyDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="loadReadyDateTime" value={rowData.loadReadyDateTime} />
      }
    },
    {
      title: 'carrier', field: "tariffServiceCode",
      render: rowData => {
        return <CustomCellRender id="tariffServiceCode" value={rowData.tariffServiceCode} />
      }
    },
    {
      title: 'ship_condition', field: "shippingCondition",
      render: rowData => {
        return <CustomCellRender id="shippingCondition" value={rowData.shippingCondition} />
      }
    },
    {
      title: 'reason_for_alert',
      field: "reasonCodeString",
      cellStyle: longTitleCellStyle,
      render: rowData => {
        const codeString = rowData?.orderStatusAndExecutionStatusReasonCodes?.map((code) => <div>{<CustomRender value={orderHealthReasonCodeMap[code]} />}</div>);
        return <div data-testid="reasonCodeString">{!isEmpty(codeString) ? codeString : "-"}</div>;
      }
    },
    // { title: 'destination_eta', field: "expectedDeliveryDateTime", cellStyle: shortTitleCellStyle, disableClick: true }
    {
      title: 'destination_eta',
      field: "expectedDeliveryDateTime",
      cellStyle: shortTitleCellStyle,
      disableClick: true,
      render: rowData => <FourKitesLink data={rowData} />
    },
    {
      title: 'sales_org', field: "salesOrg", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesOrg" value={rowData.salesOrg} />
      }
    },
    {
      title: 'distribution_channel', field: "distributionChannel", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="distributionChannel" value={rowData.distributionChannel} />
      }
    },
    {
      title: 'sales_office', field: "salesOffice", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesOffice" value={rowData.salesOffice} />
      }
    },
    {
      title: 'sales_group', field: "salesGroup", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="salesGroup" value={rowData.salesGroup} />
      }
    },
    {
      title: 'mad', field: "matAvailDate", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="matAvailDate" value={rowData.matAvailDate} />
      }
    },
    {
			title: 'confirmed_delivery_appt_reschedule_count', field: "confirmedDeliveryApptRescheduleCount", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="confirmedDeliveryApptRescheduleCount" value={rowData.confirmedDeliveryApptRescheduleCount} />
			}
		},
		{
			title: 'delivery_appt_date_&_time', field: "deliverApptDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="deliverApptDateTime" value={rowData.deliverApptDateTime} />
			}
		},
		{
			title: 'confirmed_original_delivery_appt_date_time', field: "confirmedOriginalDeliveryApptDateTime", cellStyle: shortTitleCellStyle,
			render: rowData => {
				return <CustomCellRender id="confirmedOriginalDeliveryApptDateTime" value={rowData.confirmedOriginalDeliveryApptDateTime} />
			}
		},
    {
      title: 'delivered_date_&_time', field: "actualDeliveredDateTime", cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="actualDeliveredDateTime" value={rowData.actualDeliveredDateTime} />
      }
    },
  ],
  columnConfiguration: (d) => {

    return {
      // liveLoadInd: d.liveLoadInd ? d.liveLoadInd === "Y" ? "yes" : "no" : "-",
      // appointmentRequired: d.appointmentRequired ? d.appointmentRequired === "Y" ? "yes" : "no" : "-",
      // orderOnHoldInd: d.orderOnHoldInd ? d.orderOnHoldInd === "Y" ? "yes" : "no" : "-",
      deliverApptDateTime: (d?.deliverApptDateTime && d?.destinationTimeZone) ? moment(d.deliverApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      confirmedOriginalDeliveryApptDateTime: (d?.confirmedOriginalDeliveryApptDateTime && d?.destinationTimeZone) ? moment(d.confirmedOriginalDeliveryApptDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      expectedDeliveryDateTime: (d?.expectedDeliveryDateTime && d?.destinationTimeZone) ? moment(d.expectedDeliveryDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      loadReadyDateTime: (d?.loadReadyDateTime && d?.originTimeZone) ? moment(d?.loadReadyDateTime)?.tz(d?.originTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      actualDeliveredDateTime: (d?.actualDeliveredDateTime && d?.destinationTimeZone) ? moment(d.actualDeliveredDateTime)?.tz(d.destinationTimeZone)?.format(`MMM DD YYYY HH:mm z`) : "-",
      customer: formatToSentenceCase(get(d, "orderDestination.name", "-")),
      origin: d?.orderOrigin?.name ? get(d, "orderOrigin.name", "-") : "-",
      originId: get(d, "orderOrigin.id", "-"),
      destinationCity: formatToSentenceCase(get(d, "orderDestination.city", "-")),
      destinationState: get(d, "orderDestination.state", "-"),
      orderStatusBucket: orderStatusBucket[d.orderStatusBucket] || "-",
      shippingCondition: get(d, "shippingCondition", "-"),
      reasonCodeString: d?.orderStatusAndExecutionStatusReasonCodes?.reduce((codeString, code, index) => {
        return index === (d.orderStatusAndExecutionStatusReasonCodes.length - 1)
          ? `${codeString} ${orderHealthReasonCodeMap[code]}`
          : `${codeString} ${orderHealthReasonCodeMap[code]}|`
      }, "") || "-",
      salesOrg: get(d, "salesOrg", "-"),
      distributionChannel: get(d, "distributionChannel", "-"),
      salesOffice: get(d, "salesOffice", "-"),
      salesGroup: get(d, "salesGroup", "-")

    }
  }
}