import React from "react";
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles } from "@material-ui/core";
import MapNoResult from "../../assets/images/map-not-found.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '500px',
        margin: "auto"

    },
    icon: {
        width: '40%'
    },
    text1: {
        textAlign: "center",
        paddingTop: theme.spacing(1.25),
        color: theme.palette.primary.contrastText
    },
    text2: {
        textAlign: "center",
        paddingTop: theme.spacing(1.25),
        color: theme.palette.primary.contrastText
    }
}));

const NoResult = ({ value, searchType }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div data-testid='noresult' className={classes.root}>
            <img src={MapNoResult} className={classes.icon} alt="No Results"/>
            <Typography variant="h2" className={classes.text1} component="div">{t("no")} {searchType} {t("found")}</Typography>
            <Typography variant="subtitle1" component="div" className={classes.text2} data-testid='no-result-text'>
                {`${t('no_text')} ${searchType} ${t('found_matching_the_search_term')} “${value}”`}
            </Typography>
        </div>
    )
}

export default NoResult;