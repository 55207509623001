import React from "react";
import { Grid } from '@material-ui/core';
import PieChart from "components/D3Charts/PieChart";
import { sum } from "d3"
import { useTranslation } from "react-i18next";

export const PlanningDetailsChartBuilder = (props) => {

  const { margins, height, type, subtype, chartsData, setDetails, setHeaderTotalCount } = props

  const xKey = "stateDesc";
  const { t } = useTranslation();

  const pie1Data = chartsData.length > 0 ? [
    {
      name: chartsData[0][xKey],
      value: chartsData[0].redCount,
      color: 'RED',
      state: chartsData[0].state
    },
    {
      name: chartsData[0][xKey],
      value: chartsData[0].blueCount,
      color: 'BLUE',
      state: chartsData[0].state
    },
  ] : [];

  const pie2Data = chartsData.length > 1 ? [
    {
      name: chartsData[1][xKey],
      value: chartsData[1].redCount,
      color: 'RED',
      state: chartsData[1].state
    },
    {
      name: chartsData[1][xKey],
      value: chartsData[1].blueCount,
      color: 'BLUE',
      state: chartsData[1].state
    },
  ] : [];


  setHeaderTotalCount(sum(pie1Data, d => d.value) + sum(pie2Data, d => d.value))

  return (
      <Grid item xs={12} data-testid="transportPlanningStatus" >
        <Grid container spacing={1}>
          <Grid item xs={6}><PieChart center={t("orders")} header={t("to_be_planned")} footer={t("unassigned")} data={pie1Data} xKey="color" margins={margins} height={height} type={type} subtype={subtype} setDetails={setDetails} /></Grid>
          <Grid item xs={6}><PieChart center={t("loads")} header={t("planning")} footer={t("open")} data={pie2Data} xKey="color" margins={margins} height={height} type={type} subtype={subtype} setDetails={setDetails} /></Grid>
        </Grid>
      </Grid>
  );
}
