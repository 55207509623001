export default {
    PROFILE_FETCH_SUCCESS: 'PROFILE_FETCH_SUCCESS',
    PROFILE_FETCH_FAILED: 'PROFILE_FETCH_FAILED',
    LOADING: 'PROFILE_LOADING',
    PROFILE_SAVE_SUCCESS: 'PROFILE_SAVE_SUCCESS',
    PROFILE_SAVE_FAILED: 'PROFILE_SAVE_FAILED',
    PROFILE_CREATE_FILTER_LAYOUT:'PROFILE_CREATE_FILTER_LAYOUT',
    PROFILE_SET_CURRENT_VIEW:'PROFILE_SET_CURRENT_VIEW',
    PROFILE_MARKFAV_FAILED:"PROFILE_MARKFAV_FAILED",
    PROFILE_MARKFAV_SUCCESS:"PROFILE_MARKFAV_SUCCESS",
    SET_FIRST_LOAD: "SET_FIRST_LOAD",
    FETCH_STATUS: "FETCH_STATUS"
}