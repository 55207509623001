import { guardrailsConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";

export const getAllGuardrails = () => dispatch => {
    dispatch({
        type: guardrailsConstants.GUARDRAILS_FETCH_LOADING,
        payload: true
    });

    let allowedToEditFlag = false

    return fetch(endpoints.guardrails.allData, {})
        .then(d => {
            if (d.status === 200 || d.status === 401) {
                allowedToEditFlag = d.status === 200 ? true : false
                return d.json()
            }
            else throw Error(d)
        }
        )
        .then(d => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_FETCH_SUCCESS,
                payload: { data: d, allowedToEdit: allowedToEditFlag }
            });
            dispatch({
                type: guardrailsConstants.GUARDRAILS_FETCH_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_FETCH_FAILED,
                payload: err
            });
            dispatch({
                type: guardrailsConstants.GUARDRAILS_FETCH_LOADING,
                payload: false
            });
        }).finally(() => allowedToEditFlag = false)
}



export const saveGuardrails = (variables) => dispatch => {
    dispatch({
        type: guardrailsConstants.GUARDRAILS_UPDATE_LOADING,
        payload: true
    });

    //Snapshot API

    return fetch(endpoints.guardrails.update, {
        method: "PUT",
        body: variables,
    })
        .then(d => {
            if (d.status === 200) return d.statusText
            else throw Error(d)
        }
        )
        .then(d => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_UPDATE_SUCCESS,
                payload: variables
            });
            dispatch({
                type: guardrailsConstants.GUARDRAILS_UPDATE_LOADING,
                payload: false
            })
        })
        .catch(err => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_UPDATE_FAILED,
                payload: err
            });
            dispatch({
                type: guardrailsConstants.GUARDRAILS_UPDATE_LOADING,
                payload: false
            });
        })
}

export const resetRequeststatus = () => dispatch => {
    dispatch({
        type: guardrailsConstants.GUARDRAILS_RESET_REQUEST_ACCESSS,
        payload: {}
    });
}

export const requestForAccess = (variables) => dispatch => {
    const { requestedUser, reason } = variables;
    return fetch(endpoints.guardrails.updateUserRequests, {
        method: "POST",
        body: {
            "requestedUser": requestedUser,
            "featureName": "UPDATE_GUARDRAIL",
            "requestStatus": "Requested",
            "reason": reason
        },
    })
        .then(({ status, ...rest }) => {
            if (status === 200) {
                dispatch({
                    type: guardrailsConstants.GUARDRAILS_REQUEST_ACCESS_SUCCESS,
                    payload: { status: "success", reason: "Request Successful", disable: true }
                });
            } else if (status === 400) {
                dispatch({
                    type: guardrailsConstants.GUARDRAILS_REQUEST_ACCESS_FAILED,
                    payload: { status: "failed", reason: "Already Requested Access", disable: true }
                });
            } else {
                throw new Error();
            }

        })
        .catch(err => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_REQUEST_ACCESS_FAILED,
                payload: { status: "failed", reason: "Unable to process your request", disable: false }
            });
        })
}

export const getAllRequests = (variables) => dispatch => {
    return fetch(endpoints.guardrails.userRequests, {
        method: "GET"
    })
        .then(d => d.json())
        .then((d) => {
            const adminUser = d?.adminUser === "Y";
            const requests = d?.requests?.find(r => r?.featureName === "UPDATE_GUARDRAIL")?.userRequests || [];
            dispatch({
                type: guardrailsConstants.GUARDRAILS_GET_REQUESTS_SUCCESS,
                payload: { requests, adminUser }
            });

        })
        .catch(err => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_GET_REQUESTS_FAILED,
                payload: { requests: [], adminUser: false }
            });
        })
}

export const updateRequestStatus = (variables) => dispatch => {
    const { requestID, requestedUserEmailID, reason, requestStatus } = variables;
    const body = {
        "featureName": "UPDATE_GUARDRAIL",
        "requestID": requestID,
        "requestedUserEmailID": requestedUserEmailID,
        "requestStatus": requestStatus,
        "reason": reason,
    };
    dispatch({
        type: guardrailsConstants.GUARDRAILS_REQUEST_LOADING,
        payload: true
    });

    return fetch(endpoints.guardrails.updateUserRequests, {
        method: "POST",
        body,
    })
        .then(({ status, ...rest }) => {
            if (status === 200) {
                dispatch({
                    type: guardrailsConstants.GUARDRAILS_APPROVE_DECLINE_REQUEST_SUCCESS,
                    payload: body
                });
            } else {
                throw new Error();
            }

        })
        .catch(err => {
            dispatch({
                type: guardrailsConstants.GUARDRAILS_APPROVE_DECLINE_REQUEST_FAILED,
                payload: {}
            });
        })
}