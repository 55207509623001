import React from 'react';
import CustomCellRender from "../../components/common/CustomCellRender";
import { shortTitleCellStyle } from 'helpers/tableStyleOverride';

export const OSMStopControlStackColumns = {
  columnOrder: [
    {
      title: 'shipment_num_freight_order', field: "shipmentNum",  
      render: rowData => {
        return <CustomCellRender id="shipmentNum" value={rowData.shipmentNum} />
      }
    },{
      title: 'load_sequence', field: "loadSequenceNum",  
      render: rowData => {
        return <CustomCellRender id="loadSequenceNum" value={rowData.loadSequenceNum} />
      }
    },{
      title: 'physical_stop_sequence_num', field: "stopSequenceNum",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="stopSequenceNum" value={rowData.stopSequenceNum} />
      }
    },{
      title: 'pallet_slipsheet_ind', field: "palletSlipsheetInd",  
      render: rowData => {
        return <CustomCellRender id="palletSlipsheetInd" value={rowData.palletSlipsheetInd} />
      }
    },{
      title: 'packaging_material', field: "packagingMaterial",  
      render: rowData => {
        return <CustomCellRender id="packagingMaterial" value={rowData.packagingMaterial} />  
      }
    },{
      title: 'packaging_material_delivery_qty', field: "packagingMaterialDeliveryQuantity",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialDeliveryQuantity" value={rowData.packagingMaterialDeliveryQuantity} />
      }
    },{
      title: 'packaging_material_expected_qty', field: "packagingMaterialExpectedQuantity",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialExpectedQuantity" value={rowData.packagingMaterialExpectedQuantity} />
      }
    },{
      title: 'packaging_material_actual_qty', field: "packagingMaterialActualQuantity",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialActualQuantity" value={rowData.packagingMaterialActualQuantity} />
      }
    },{
      title: 'target_qty_uom', field: "unit",  
      render: rowData => {
        return <CustomCellRender id="unit" value={rowData.unit} />
      }
    },{
      title: 'load_code_one', field: "loadCode1",  
      render: rowData => {
        return <CustomCellRender id="loadCode1" value={rowData.loadCode1} />
      }
    },{
      title: 'load_code_two', field: "loadCode2",  
      render: rowData => {
        return <CustomCellRender id="loadCode2" value={rowData.loadCode2} />
      }
    },{
      title: 'load_code_three', field: "loadCode3",  
      render: rowData => {
        return <CustomCellRender id="loadCode3" value={rowData.loadCode3} />    
      }
    },{
      title: 'load_code_four', field: "loadCode4",  
      render: rowData => {
        return <CustomCellRender id="loadCode4" value={rowData.loadCode4} />
      }
    },{
      title: 'created_on', field: "createdon",  
      render: rowData => {
        return <CustomCellRender id="createdon" value={rowData.createdon} />
      }
    // },{
    //   title: 'time_created', field: "createdAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="createdAt" value={rowData.createdAt} />
    //   }
    },{
      title: 'changed_on', field: "changedOn",  
      render: rowData => {
        return <CustomCellRender id="changedOn" value={rowData.changedOn} />
      }
    // },{
    //   title: 'last_changed_at', field: "lastchangedAt",  
    //   render: rowData => {
    //     return <CustomCellRender id="lastchangedAt" value={rowData.lastchangedAt} />
    //   }
    },{
      title: 'packaging_material_two', field: "packagingMaterial2",  
      render: rowData => {
        return <CustomCellRender id="packagingMaterial2" value={rowData.packagingMaterial2} />
      }
    },{
      title: 'packaging_material_two_delivery_qty', field: "packagingMaterialDeliveryQuantity2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialDeliveryQuantity2" value={rowData.packagingMaterialDeliveryQuantity2} />
      }
    },{
      title: 'packaging_material_two_expected_qty', field: "packagingMaterialExpectedQuantity2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialExpectedQuantity2" value={rowData.packagingMaterialExpectedQuantity2} />
      }
    },{
      title: 'packaging_material_two_actual_qty', field: "packagingMaterialActualQuantity2",  cellStyle: shortTitleCellStyle,
      render: rowData => {
        return <CustomCellRender id="packagingMaterialActualQuantity2" value={rowData.packagingMaterialActualQuantity2} />
      }
    },{
      title: 'multiple_platform_ind', field: "multPlatformInd",  
      render: rowData => {
        return <CustomCellRender id="multPlatformInd" value={rowData.multPlatformInd} />
      }
    },
  ],
  columnConfiguration: (d) => {
    return {
    }
  }
}