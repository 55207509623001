import { chartsConstants } from '../constants';
import get from 'lodash/get';



const getChartsReducer = (state = { loaders: {}, charts: [] }, action) => {
    const { variables } = action;
    if (!variables) return state;
    const { type, subtype } = variables;
    switch (action.type) {
        // We are storing the charts data in corrosponding order Type/Subtype/Chart Name to retrieve later , 
        // even loaders are also stored in the same fashion
        case chartsConstants.CHART_DATA_FETCH_SUCCESS:
            return {
                ...state, charts: {
                    ...state.charts,
                    [type]: {
                        ...state.charts[type],
                        [subtype]: {
                            data: get(action.payload, [subtype], []),
                            payload: variables
                        }
                    }
                }
            };

        case chartsConstants.CHART_DATA_FETCH_FAILED:
            return { ...state, charts: { ...state.charts, [type]: { ...state.charts[type], [subtype]: { data: [] } } } }

        case chartsConstants.LOADING:
            return { ...state, loaders: { [type]: { ...state.loaders[type], [subtype]: { loading: action.payload } } } }

        case chartsConstants.GUARDRAIL_DATA_FETCH_SUCCESS:
            return { ...state, charts: { ...state.charts, guardrails: action.payload } };

        case chartsConstants.GUARDRAIL_DATA_FETCH_FAILED:
            return { ...state, charts: { ...state.charts, guardrails: null } }

        case chartsConstants.GUARDRAIL_LOADING:
            return { ...state, loaders: { ...state.loaders, guardrails: { loading: action.payload } } }


        default:
            return state
    }
}

export default getChartsReducer;