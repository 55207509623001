import { chartsConstants } from "../constants";
import fetch from "./serviceHelpers";
import { endpoints } from "helpers";
// import { mockGuardrailsDetails } from "components/dummydata/mock";

const getChartsService = variables => dispatch => {
    dispatch({
        type: chartsConstants.LOADING,
        payload: true,
        variables
    });
    const { body, } = variables;

    return fetch(endpoints.charts, {
        method: "POST",
        body: body,
    })
        .then(d => {
            if (d.status === 200) {
                return d.json()
            }
            dispatch({
                type: chartsConstants.LOADING,
                payload: false,
                variables
            });
            dispatch({
                type: chartsConstants.CHART_DATA_FETCH_FAILED,
                payload: null,
                error: {},
                variables
            });
        })
        .then(d => {
            dispatch({
                type: chartsConstants.CHART_DATA_FETCH_SUCCESS,
                payload: d,
                variables

            });
            dispatch({
                type: chartsConstants.LOADING,
                payload: false,
                variables
            })
        })
        .catch(err => {
            dispatch({
                type: chartsConstants.LOADING,
                payload: false,
                variables
            });
            dispatch({
                type: chartsConstants.CHART_DATA_FETCH_FAILED,
                payload: [],
                error: err,
                variables
            });
        })
}

const getGuardrailsService = (variables, type) => dispatch => {
    dispatch({
        type: chartsConstants.GUARDRAIL_LOADING,
        payload: true,
        variables
    });
    const { body } = variables;
    const endpoint = type === "inbound" ? 
        endpoints.guardrails.siteInboundData :
            endpoints.guardrails.siteData;

    return fetch(`${endpoint}?siteNum=${body.siteNum}`, {
        method: "GET",
    })
        .then(d => {
            if (d.status === 200) {
                return d.json()
            }
            dispatch({
                type: chartsConstants.GUARDRAIL_LOADING,
                payload: false,
                variables
            });
            dispatch({
                type: chartsConstants.GUARDRAIL_DATA_FETCH_FAILED,
                payload: null,
                error: {},
                variables
            });
        })
        .then(d => {
            dispatch({
                type: chartsConstants.GUARDRAIL_DATA_FETCH_SUCCESS,
                payload: d,
                variables

            });
            dispatch({
                type: chartsConstants.GUARDRAIL_LOADING,
                payload: false,
                variables
            })
        })
        .catch(err => {
            dispatch({
                type: chartsConstants.GUARDRAIL_LOADING,
                payload: false,
                variables
            });
            dispatch({
                type: chartsConstants.GUARDRAIL_DATA_FETCH_FAILED,
                payload: [],
                error: err,
                variables
            });
        })
}

export { getChartsService, getGuardrailsService };